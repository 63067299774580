import { axiosAuthInstance, axiosInstance } from '../../../common/axiosInstance';
import { LoanRoutes } from '../../../common/routes';

const axios = axiosInstance;
const axisAuth = axiosAuthInstance
const fetchAddMoneyData = (data: any) =>
	axios.request({ ...LoanRoutes.addMoney, data });
const fetchData = (data: any) =>
	axios.request({ ...LoanRoutes.addMoney, data });
const fetchMyOptions = (data: any) =>
	axios.request({ ...LoanRoutes.myOptions, data });
const fetchSelectedCombination = (data: any) =>
	axios.request({ ...LoanRoutes.selectedCombination, data });
const fetchSaveSelectedCombination = (data: any) =>
	axios.request({ ...LoanRoutes.saveSelectedCombination, data });
const fetchTrackTransaction = (data: any) =>
	axios.request({ ...LoanRoutes.trackTransaction, data });

const fetchPayoutList = async (data: any) => {
	try {
		const response = await axios.request({
			...LoanRoutes.fetchPayoutList,
			data,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchDashboardTracker = (data: any) =>
	axios.request({ ...LoanRoutes.dashboardTracker, data });

const fetchSignatureReminder = (data: any) =>
	axios.request({ ...LoanRoutes.signatureReminder, data })

export {
	fetchAddMoneyData,
	fetchData,
	fetchMyOptions,
	fetchSelectedCombination,
	fetchTrackTransaction,
	fetchDashboardTracker,
	fetchSaveSelectedCombination,
	fetchPayoutList,
	fetchSignatureReminder
};
