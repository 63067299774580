/* eslint-disable react/require-default-props */
import { Popover } from '@mui/material';
import React from 'react';

interface PopoverInterface {
	isOpen?: boolean;
	className?: string | undefined;
	anchorEl?: null | HTMLElement;
	onClose?: () => void;
	anchorOrigin?: {
		vertical: 'top' | 'center' | 'bottom';
		horizontal: 'left' | 'center' | 'right';
	};
	transformOrigin?: {
		vertical: 'top' | 'center' | 'bottom';
		horizontal: 'left' | 'center' | 'right';
	};
	children?: React.ReactNode;
}

const CustomPopOver = ({
	isOpen,
	className,
	anchorEl,
	anchorOrigin,
	transformOrigin,
	children,
	onClose,
}: // ...rest
PopoverInterface) => (
	<Popover
		anchorEl={anchorEl}
		open={isOpen ?? false}
		onClose={onClose}
		anchorOrigin={anchorOrigin}
		transformOrigin={transformOrigin}
		className={`${className}`}
	>
		{children}
	</Popover>
);

export default CustomPopOver;
