import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SvgConverter from '../../../common/SvgConverter';
import { APP_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as OrangeAlert } from '../../../common/icons/ekyc_detailsone_alert_icon.svg';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import { ReactComponent as RupeeIcon1 } from '../../../common/icons/orange_rupee.svg';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import { selectedCombination } from '../LoanModule/Loan.slice';
import BankDetails from '../LoanModule/BankDetails';
import {
	fetchInitiateSurrender,
	fetchSurrender,
	selectIsSurrenderLoading,
	selectLoadedIntiatedSurrenders,
	selectLoadedSurrenders,
} from './Surrender.slice';

const ContinueSurrender = ({
	open,
	setOpen,
	onSubmit,
	bankDetails,
	policyDetails,
}: any) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const surrendervalues_response = useSelector(selectLoadedIntiatedSurrenders);
	// const surrendervalues = useSelector(selectLoadedSurrenders);

	const valueloader = useSelector(selectIsSurrenderLoading);

	const surrendervalues = useSelector(selectLoadedSurrenders);
	useEffect(() => {
		if (Object.keys(surrendervalues).length == 0) {
			dispatch(fetchSurrender(policyDetails));
		}
	}, [dispatch]);
	console.log(surrendervalues);

	const submitSurenderPolicyClick = () => {
		const payload: any = {
			policyNo: policyDetails?.policyNo,
			companyCode: policyDetails?.companyCode,
			maturityValue: surrendervalues?.maturityValue,
			currentSurrenderValue: surrendervalues?.currentSurrenderValue,
			payeeAccNo: bankDetails?.bankAcNo,
			payeeAccName: bankDetails?.accountHolderName,
			payeeIfscCode: bankDetails?.bankIfscCode,
			bankName: bankDetails?.bankName,
			branchName: bankDetails?.bankBranch,
			bankVerified: bankDetails?.bankVerified,
			navigate,
		};

		payload.handleSuccess = () => {
			navigate(APP_ROUTES.POLICY_DETAILS_LIFE, { state: policyDetails });
		};
		dispatch(fetchInitiateSurrender(payload));
	};
const iconIMG = "https://files.axshare.com/gsc/7909S7/f5/bd/55/f5bd5572cecb46799ed0c28fc12ff79d/images/phase_1/xmlid_383__u6692.svg?pageId=ae9c3ed2-4b1e-429d-92b1-bd6912510088"



	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName="px-0 py-20"
			boxProps={{ width: 315 }}
			
		>
			<Grid
				container
				justifyContent="center"
				// style={{ backgroundColor: theme.palette.grey[500] }}
				
			>
				{/* <Box
					marginBottom={5}
					width={350}
					display="flex"
					justifyContent="end"
					flexDirection="column"
				> */}
				<Grid container item xs={12}>
					<Grid item xs={12} >
						<Grid  className='card-icon' >
						<Grid>
							<img src={iconIMG} className='ml-10 icon-img'/>
						</Grid>
						<Typography
							className="ml-10 f-16 fw-600 mb-20"
							color={theme.palette.error.main}
							mt={1}
						>
							Initiate Policy Surrender
						</Typography>
						</Grid>
						<Separator
							className="mt-0 ml-0 mr-0 "
							color={theme.palette.grey[300]}
						/>
						<Typography
							className="mt-15 f-14 fw-600 ml-20 mb-0 mt-0"
							color={theme.palette.primary.main}
							
						>
							{t('Maturity Value')}
						</Typography>
						<Grid display="flex" className="ml-20" >
							<Grid className="f-20 mt-8 rupeeicon-custom-maturity ">
								<SvgConverter Icon={RupeeIcon1} />
							</Grid>
							<Grid >
								<Typography
									color="black"
									// className="ml-5 mb-0 mt-0 f-16 fw-400 align-items-center"
									className="f-18 fw-400 align-items-center mb-8"
								>
									{surrendervalues?.maturityValue}
								</Typography>
							</Grid>
						</Grid>
						<Separator color={theme.palette.grey[300]} />
						<Grid style={{ backgroundColor: theme.palette.grey[100] }}>
							<Typography
								className="ml-20 f-14 fw-600 pt-10"
								color={theme.palette.error.main}
							>
								Current Surrender Value
							</Typography>

							<Grid display="flex" className="ml-20">
								<Grid className="f-20 mt-0 rupeeicon-custom">
									<SvgConverter Icon={RupeeIcon1} />
								</Grid>
								<Grid className='mb-8'>
									<Typography
										color={theme.palette.error.main}
										className="f-18 fw-400 mt-5 align-items-center"
									>
										{surrendervalues?.currentSurrenderValue}
										{/* {t('xxxxxxx')} */}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Separator color={theme.palette.grey[300]} />

						{bankDetails ? (
							<Grid item xs={12} container className="px-10">
								<Grid
									item
									xs={12}
									display="flex"
									justifyContent="space-between"
									mt={1}
								>
									<Typography
										className="f-14"
										color={theme.palette.common.black}
									>
										{t('BANK_DETAILS')}
									</Typography>
									<Link
										to={APP_ROUTES.SELECTBANKDETAILS}
										state={{ surrender: policyDetails }}
									>
										<CustomButton
											text={t('CHANGE_BANK')}
											variant="text"
											color="primary"
											showIcon={false}
											fontSize={14}
										/>
									</Link>
								</Grid>
								<Grid item xs={12}>
									<Separator color=" #d7d7d7" />
								</Grid>
								<Grid item xs={12}>
									<BankDetails
										verifyBank={bankDetails?.bankVerified}
										bankName={bankDetails?.bankName}
										branch={bankDetails?.bankBranch}
										ifscCode={bankDetails?.bankIfscCode}
										accountNum={bankDetails?.bankAcNo}
										bankLogo={bankDetails?.bankLogo}
										accountHolder={bankDetails?.accountHolderName}
									/>
								</Grid>

								<Separator color={theme.palette.grey[300]} />

								<Grid display="flex" item xs={12} className="fw-400 f-16 pt-20">
									<Grid>
										<CustomButton
											text={t('CANCEL')}
											variant="text"
											color="inherit"
											className="fw-400 f-16 ml-20"
											fontSize={14}
											textAlign="start"
											showIcon={false}
											onClick={() => {
												setOpen(false);
											}}
										/>
									</Grid>
									<Grid>
										<CustomButton
											text={t('SUBMIT REQUEST')}
											variant="text"
											color="primary"
											className="fw-600 f-16 ml-20 "
											showIcon
											fontSize={14}
											textAlign="end"
											onClick={() => submitSurenderPolicyClick()}
											endIcon={rightArrow}
										/>
									</Grid>
								</Grid>
							</Grid>
						) : (
							<Grid
								item
								xs={10}
								container
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								py={1}
								className="ml-20 mt-17	"
							>
								<SvgConverter Icon={OrangeAlert} />
								<Link
									to={APP_ROUTES.SELECTBANKDETAILS}
									state={{ surrender: policyDetails }}
								>
									<CustomButton
										text={t('SELECT_A_BANK')}
										variant="text"
										color="primary"
										showIcon
										fontSize={16}
										fontWeight={600}
									/>
								</Link>
							</Grid>
						)}
					</Grid>
				</Grid>
				{/* </Box> */}
			</Grid>
		</CommonModal>
	);
};
export default ContinueSurrender;
