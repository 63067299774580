import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Separator from '../../../common/ui/Separator';
import MobilePolicyHeader from '../MobileNumber/common/MobilePolicyHeader';

const UnassignedEmail = ({
	emails,
	handleAssignPolicy,
	handleRemoveForUnAssignedEmails,
	togglePrimaryChangeModal,
}: any) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<>
			<Grid item xs={12}>
				<Typography className="f-16  mt-10" color={theme.palette.error.main}>
					{t('UNASSIGNED_EMAIL')}
				</Typography>
			</Grid>
			<Grid item className="my-10">
				<Separator color={theme.palette.primary.main} borderWidth={1} />
			</Grid>
			<Grid container spacing={5}>
				{emails.map((data: any, index: number) => (
					<MobilePolicyHeader
						key={data?.email}
						index={index}
						mobileNo={data?.email}
						verified={data.emailVerified === 'Y'}
						primary={data.primaryFlag}
						type={data?.primaryFlag}
						onRemove={() =>
							handleRemoveForUnAssignedEmails(data?.email, data?.primaryFlag)
						}
						handleAssignPolicy={() =>
							handleAssignPolicy({
								email: data?.email,
								emailVerified: data.emailVerified,
								primaryFlag: data?.primaryFlag,
							})
						}
						onChangePrimary={() => {
							togglePrimaryChangeModal({ email: data?.email });
						}}
						showAssignPolicyButton
						isEmail
					/>
				))}
			</Grid>
		</>
	);
};

export default UnassignedEmail;
