import { Grid, Typography } from '@mui/material';
import React from 'react';
import '../../Policy.scss';

import theme from '../../../../../common/theme';
import ActiveLifeCard from '../ActiveContent/ActiveLifeCard';

interface UserProps {
	contentType?: string;
	filteredLife?: any;
}

const ActiveLife: React.FC<UserProps> = ({ contentType, filteredLife }) => (
	<Grid container justifyContent="row">
		{filteredLife.map((data: any, index: any) => (
			<Grid
				item
				xs={12}
				sm={6}
				lg={4}
				key={index}
				justifyContent="space-between"
			>
				<Grid item xs={12}>
					<Typography
						className="f-12 fw-600 py-4"
						color={theme.palette.primary.main}
					>
						{data?.heading?.length === 0 ? 'ppp' : data?.heading}
					</Typography>
				</Grid>
				<Grid item xs={12} className="flex-container">
					<ActiveLifeCard contentType={contentType} items={data} />
				</Grid>
			</Grid>
		))}
	</Grid>
);

export default ActiveLife;
