import { Typography } from '@mui/material';
import React from 'react';

const AddressDetails = ({
	address,
}: {
	address?: {
		address1: string;
		address2: string;
		address3: string;
		city: string;
		state: string;
		district: string;
		country: string;
		pincode: string;
	};
}) => (
	<>
		<Typography className="f-18 personal-email" color="textPrimary">
			{address?.address1}
		</Typography>
		<Typography className="f-18 personal-email" color="textPrimary">
			{address?.address2}
		</Typography>

		<Typography className="f-18 personal-email" color="textPrimary">
			{address?.address3}
		</Typography>

		<Typography className="f-18 personal-email" color="textPrimary">
			{address?.city}
		</Typography>
		<Typography className="f-18 personal-email" color="textPrimary">
			{address?.district}
		</Typography>

		<Typography className="f-18 personal-email" color="textPrimary">
			{address?.state}
		</Typography>

		<Typography className="f-18 personal-email" color="textPrimary">
			{address?.country}
		</Typography>

		<Typography className="f-18 personal-email" color="textPrimary">
			{address?.pincode}
		</Typography>
	</>
);
export default AddressDetails;
