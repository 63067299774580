/* eslint-disable react/require-default-props */
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProfileAvatar from './ProfileAvatar';
import ImagePickerModal from './Modal/ImagePickerModal';
import {
	selectUploadImageIsLoading,
	uploadProfilePhotoRequest,
	uploadSignaturePhotoRequest,
} from '../../features/App/ImageUpload/Upload.Slice';
import { getFormatDate } from '../utils';

const UserProfileCard = ({
	// userName = 'Customer Name',

	// profilePercent = 70,
	profileImg,
	customerName,
	eiaNum,
	profileReadiness,
	lastUpdateDate,
}: {
	customerName?: string | any;
	eiaNum?: string;
	profileReadiness?: string;
	lastUpdateDate: string | undefined;
	profileImg?: string;
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [uploadType, setUploadType] = React.useState<string | null>(null);
	const { t } = useTranslation();
	const profileImageLoading = useSelector(selectUploadImageIsLoading);
	const uploadProfile = () => {
		setOpen(true);
		setUploadType('PROFILE_PHOTO');
	};
	const processImage = (data: any, file: any) => {
		try {
			const imgType = uploadType;
			const imageBase64 = data;
			const fileName = file?.target?.files?.[0]?.name;

			const generateUniqueFileName = () => {
				const timestamp = Date.now();
				const randomId = Math.random().toString(36).substring(2, 8);
				return `image_${timestamp}_${randomId}.jpg`;
			};
			const fileNames = generateUniqueFileName();

			if (imageBase64 || fileName) {
				if (imgType === 'PROFILE_PHOTO') {
					dispatch(
						uploadProfilePhotoRequest({
							imgType,
							imageBase64,
							addRemoveFlag: "ADD",
							fileName: fileName || fileNames,
						} as any)
					);
				} else if (imgType === 'SIGNATURE') {
					dispatch(
						uploadSignaturePhotoRequest({
							imgType,
							imageBase64,
							addRemoveFlag: "ADD",
							fileName: fileName || fileNames,
						} as any)
					);
				}

				setOpen(false);
			}
		} catch (e) {
			// console.log(e);
		}
	};
	return (
		<Grid container alignItems="center" spacing={1}>
			<Grid item>
				<ProfileAvatar
					profileImg={profileImg}
					profileReadiness={profileReadiness}
					uploadProfile={uploadProfile}
					profileLoading={profileImageLoading}
				/>
				<ImagePickerModal
					open={open}
					setOpen={setOpen}
					handleImage={processImage}
					enableCropping
				/>
			</Grid>

			<Grid item className="ml-10">
				<Typography
					textAlign="left"
					color="primary"
					fontSize={20}
					lineHeight="20px"
				>
					{customerName}
				</Typography>
				<Typography
					textAlign="left"
					color={theme.palette.common.black}
					fontSize={16}
					lineHeight="16px"
					className="mt-3"
				>
					{t('EIA#')}: {eiaNum?.length === 0 ? '-' : eiaNum}
				</Typography>
				<Typography
					textAlign="left"
					color={theme.palette.grey.A700}
					fontSize={14}
					whiteSpace="nowrap"
					className="mt-3"
				>
					{t('PROFILE_READINESS')}:&nbsp;
					<Typography
						color={theme.palette.secondary.main}
						fontSize={14}
						component="span"
					>
						{profileReadiness}
					</Typography>
				</Typography>{' '}
				<Typography
					textAlign="left"
					color={theme.palette.grey.A700}
					fontSize={14}
					whiteSpace="nowrap"
				>
					{t('LAST_UPDATED_ON')}
					<Typography
						color={theme.palette.grey.A700}
						fontSize={14}
						component="span"
						className="ml-3 fw-600"
					>
						{getFormatDate(`${lastUpdateDate}`, 'YYYY-MM-DD')}
					</Typography>
				</Typography>
			</Grid>
		</Grid>
	);
};

export default UserProfileCard;
