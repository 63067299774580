import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as OrangeRuppee } from '../../../../common/icons/profile/orange_ruppee_icon.svg';
import Separator from '../../../../common/ui/Separator';
import CustomButton from '../../../../common/ui/CustomButton';
import { convertAmount } from '../../../../common/utils';
import { PolicyAlternateCodes } from '../../../../common/types/PolicyTypes';
import Currency from '../../Common/Currency';

const UnAssignedPoliciesForBank = ({
	data = {},
	onAssignClick,
	assignButtonText = 'BANK',
}: any) => {
	const theme = useTheme();
	const isAsset = data.alternateCode === PolicyAlternateCodes.ASSET;
	const { t } = useTranslation();
	return (
		<Grid item>
			<Grid item>
				<Grid
					flexWrap="nowrap"
					container
					justifyContent="space-between"
					className="mb-15"
				>
					<Grid item>
						<Grid item>
							<Typography
								className="f-16 fw-600"
								color={theme.palette.common.black}
							>
								{data.planName}
							</Typography>
						</Grid>
						{isAsset ? (
							<>
								<Grid item>
									<Typography
										className="f-14"
										color={theme.palette.common.black}
									>
										{t('VEHICLE_NUMBER')}
									</Typography>
									<Typography
										className="f-16 fw-600"
										color={theme.palette.common.black}
									>
										{data.vehicleNo}
									</Typography>
								</Grid>
								<Grid item container spacing={2}>
									<Grid item>
										<Typography
											className="f-14"
											color={theme.palette.common.black}
										>
											{t('IDV')}:
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											className="f-16 fw-600"
											color={theme.palette.common.black}
										>
											<Grid container spacing={0.5} alignItems="center">
												<Currency amount={data?.idv} policyDetails={data} />
											</Grid>
										</Typography>
									</Grid>
								</Grid>
							</>
						) : (
							<Grid item>
								<Typography className="f-14" color={theme.palette.common.black}>
									{t('LIFE_ASSURED')}
								</Typography>
								<Typography
									className="f-16 fw-600"
									color={theme.palette.common.black}
								>
									{data.customerName}
								</Typography>
							</Grid>
						)}
					</Grid>
					<Grid item className="mt-10">
						<img
							src={data.companyLogo}
							alt="logo"
							width={60}
							height={60}
							style={{ objectFit: 'contain', border: '1px solid #d7d7d7', backgroundColor: 'white' }}
						/>
					</Grid>
				</Grid>
				<Grid container justifyContent="space-between" className="mt-n15">
					<Grid item sm={6}>
						<Grid item>
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('SUM_ASSURED')}
							</Typography>
							<Grid container spacing={0.5} alignItems="center">
								<Currency amount={data?.sumAssured} policyDetails={data} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item sm={6}>
						<Grid item className="">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('PREMIUM')}
							</Typography>
							<Grid container spacing={0.5} alignItems="center">
								<Currency amount={data?.premiumAmount} policyDetails={data} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item className="mt-5">
				<Separator color={theme.palette.grey[300]} />
			</Grid>
			<Grid item className="mb-10">
				<CustomButton
					text={`ASSIGN ${assignButtonText}`}
					fontSize={14}
					color="primary"
					variant="text"
					showIcon={false}
					onClick={onAssignClick}
					disabled={data?.insIntegFlag?.toUpperCase() !== 'Y' && (assignButtonText === 'EMAIL' ? data?.emailEndoFlag?.toUpperCase() !== 'Y' : assignButtonText === 'NUMBER' ? data?.mobileEndoFlag?.toUpperCase() !== 'Y' : data.bankEndoFlag.toUpperCase() !== 'Y')}
				/>
			</Grid>
		</Grid>
	);
};

export default UnAssignedPoliciesForBank;
