import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import './PersonalProfileScreen.css';
import { useDispatch, useSelector } from 'react-redux';

import {
	fetchUserFamilyListAction,
	fetchUserProfile,
	selectUserFamilyList,
	selectUserProfile,
	selectUserProfileLoading,
} from './Profile.slice';
import PersonalAddress from './PersonalAddress';
import Spinner from '../../../common/ui/Spinner';
import PersonalDetails from './PersonalDetails';
import PersonalBankDetails from './PersonalBankDetails';
import PersonalBankDocuments from './PersonalBankDocuments';
import ApiModal from '../../../common/ui/Modal/ApiModal';

const PersonalProfileScreen = () => {
	const dispatch = useDispatch();
	const userProfile = useSelector(selectUserProfile);
	const familyList = useSelector(selectUserFamilyList);

	useEffect(() => {
		if (!familyList.length) {
			dispatch(fetchUserFamilyListAction());
		}
		if (!userProfile) {
			dispatch(fetchUserProfile());
		}
	}, []);
	const isLoading = useSelector(selectUserProfileLoading);

	return (
		<Box>
			{isLoading ? (
				<Grid item>
					<Spinner />
				</Grid>
			) : (
				<>
					<Grid container spacing={1} justifyContent="space-between">
						<Grid item xs={12}>
							<PersonalDetails
								dob={userProfile?.Customer?.dob}
								fatherName={userProfile?.Customer?.fatherName}
								aadhar={userProfile?.Customer?.aadhar}
								gender={userProfile?.Customer?.gender}
								pan={userProfile?.Customer?.pan}
								passport={userProfile?.Customer?.passport}
								voterId={userProfile?.Customer?.voterId}
								drivingLicense={userProfile?.Customer?.drivingLicense}
								mobileNumberCode={userProfile?.Customer?.mobileCountryCode}
								mobileNumber={userProfile?.Customer?.mobileNo}
								policyMobileNo={userProfile?.PolicyMobile.filter(
									(policyMobile) =>
										policyMobile.mobileNo !== userProfile?.Customer?.mobileNo
								)}
								policyEmail={userProfile?.PolicyEmail.filter(
									(policyEmail) =>
										policyEmail.email !== userProfile?.Customer?.email
								)}
								email={userProfile?.Customer?.email}
								Images={userProfile?.Images}
							/>
						</Grid>
						<Grid item xs={12}>
							<PersonalAddress
								PermanentAddress={userProfile?.Customer?.PermanentAddress}
								PolicyAddress={userProfile?.PolicyAddress}
							/>
						</Grid>
						<Grid item xs={12}>
							<PersonalBankDetails profileResponse={userProfile?.Bank} />
						</Grid>
						<Grid item xs={12} display="none">
							<PersonalBankDocuments profileIds={userProfile?.Images} />
						</Grid>
					</Grid>
					<ApiModal /></>
			)}
		</Box>
	);
};

export default PersonalProfileScreen;
