import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NeedMoneyHeader from './NeedMoneyHeader';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/LoanModule/left_arrow_icon.svg';
import theme from '../../../common/theme';
import Separator from '../../../common/ui/Separator';
import { ReactComponent as WithdrawalIcon } from '../../../common/icons/LoanModule/withdrawalIcon.svg';
import { ReactComponent as loanIcon } from '../../../common/icons/LoanModule/loanIcon.svg';
import { ReactComponent as SurrenderIcon } from '../../../common/icons/LoanModule/surrender.svg';
// import HDFC from '../../../common/icons/LIC.png';

import LenderDetailsCard from './LenderDetailsCard';
import { APP_ROUTES } from '../../../common/constants/Routes';
import TotalAmountAvailable from './TotalAmountAvailable';

import {
	financierStatus,
	witnessStatus,
	bankStatus,
	selectedCombination,
	myOptions,
	saveSelectedCombinationRequest,
	saveSelectedCombinationLoading,
	// currentEligibleLoanValue,
	// currentEligibleWithdrawalValue,
} from './Loan.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { convertAmount } from '../../../common/utils';
import cryptoEncryptionDecryption from '../../../common/crypto';

const LenderScreen = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const financierDetail = useSelector(financierStatus);
	const witnessDetail = useSelector(witnessStatus);
	const bankDetail = useSelector(bankStatus);
	const userData: any = useSelector(selectIsUserLoggedIn);
	const Combinations: any = useSelector(selectedCombination);
	const loading: any = useSelector(saveSelectedCombinationLoading);
	const Combination: any = Combinations?.Combination;

	useEffect(() => {
		if (!Combination) {
			navigate(APP_ROUTES.NEED_MONEY);
		}
	}, [Combination]);
	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
	} = useForm();
	const widgetNos = Combination?.Widgets?.map(
		(widget: { widgetNo: any }) => widget?.widgetNo
	);
	const bankWidgetNos = bankDetail?.map(
		(data: { widgetNo: any }) => data?.widgetNo
	);
	const allWidgetNosPresent = widgetNos?.every((widgetNo: any) =>
		bankWidgetNos.includes(widgetNo)
	);
	const combinations: any = useSelector(myOptions);

	// const currentLoanPolicyCount = useSelector(currentEligibleLoanValue);
	// const eligibleLoanPolicyCount = combinations?.eligibleLoanPolicyCount;
	// const currentWithdrawalPolicyCount = useSelector(
	// 	currentEligibleWithdrawalValue
	// );
	// const eligibleWithdrawalPolicyCount =
	// 	combinations?.eligibleWithdrawalPolicyCount;

	const onSubmit = (data: any) => {
		const modifiedData: any = {
			eligiblePolicyCount: Combination?.eligiblePolicyCount || '',
			CurrentlyUsingPolicyCount: Combination?.currentlyUsingPolicyCount || '',
			combinationName: Combination?.combinationName,
			totalCharges: Combination?.totalCharges,
			totalChargesDetails: Combination?.totalChargesDetails,
			totalFundsAvailed: Combination?.totalFundsAvailed,
			lossOfCover: Combination?.lossOfCover,
			Widgets: Combination?.Widgets?.map((widget: any) => {
				const bankDetails: any = bankDetail?.find((data: any) => {
					if (data?.widgetNo === widget?.widgetNo) {
						return data?.bankDetails;
					}
				});
				const commonProperties: any = {
					sessionId: userData?.sessionId,
					panNo: userData?.pan,
					camsRefNo: '',
					BankDetails: bankDetails?.bankDetails,
				};
				// if (widget?.widgetType === 'W') {
				// 	commonProperties.eligiblePolicyCount = eligibleWithdrawalPolicyCount;
				// 	commonProperties.CurrentlyUsingPolicyCount =
				// 		currentWithdrawalPolicyCount;
				// }
				// if (widget?.widgetType === 'L') {
				// 	commonProperties.eligiblePolicyCount = eligibleLoanPolicyCount;
				// 	commonProperties.CurrentlyUsingPolicyCount = currentLoanPolicyCount;
				// }
				const clonedWidget: any = {
					...widget,
					...commonProperties,
				};
				if (clonedWidget?.widgetType === 'W') {
					clonedWidget.Withdrawal = {
						...widget.Withdrawal,
						partialWithdrawalAmount: data[widget.widgetNo],
					};
				}
				if (clonedWidget?.widgetType === 'L') {
					clonedWidget.Loan = {
						...widget.Loan,
						loanAmount: data[widget.widgetNo],
					};
				}
				if (clonedWidget?.Loan) {
					const FindWitnessLoan: any = witnessDetail.find(
						(data: { widgetNo: any }) =>
							data?.widgetNo === clonedWidget?.widgetNo
					);
					if (FindWitnessLoan) {
						const matchedWitnessDetails: any = [];
						for (const data of witnessDetail) {
							if (data.widgetNo === FindWitnessLoan.widgetNo) {
								matchedWitnessDetails.push(data?.witness);
							}
						}
						for (const witnessDetails of matchedWitnessDetails) {
							clonedWidget.Loan = {
								...clonedWidget.Loan,
								Witness: {
									witnessFirstName: witnessDetails.witnessFirstName,
									witnessLastName: witnessDetails.witnessLastName,
									witnessPan: witnessDetails.witnessPan,
									witnessMobileNo: witnessDetails.witnessMobileNo,
									witnessAddress: '',
									witnessEmailId: witnessDetails.witnessEmailId,
									witnessDOB: witnessDetails.dob,
								},
							};
						}
					}
				}
				if (clonedWidget?.Loan) {
					const FindFinancierLoan: any = financierDetail.find(
						(data: { widgetNo: any }) =>
							data?.widgetNo === clonedWidget?.widgetNo
					);
					if (FindFinancierLoan) {
						const matchedLenderDetails: any = [];
						for (const data of financierDetail) {
							if (data.widgetNo === FindFinancierLoan.widgetNo) {
								matchedLenderDetails.push(data.lenderDetails);
							}
						}

						for (const lenderDetails of matchedLenderDetails) {
							clonedWidget.Loan = {
								...clonedWidget.Loan,
								LenderDetails: {
									repaymentOption: lenderDetails?.repaymentCode?.repaymentCode,
									repaymentOptionDescription:
										lenderDetails?.repaymentCode?.repaymentOptionDescription,

									charges: lenderDetails?.lenderCode?.charges,
									interestPercentage:
										lenderDetails?.lenderCode?.interestPercentage,
									lenderCode: lenderDetails?.lenderCode?.lenderCode,
									lenderLogo: lenderDetails?.lenderCode?.lenderLogo,
									lenderName: lenderDetails?.lenderCode?.lenderName,
									loanAmount: lenderDetails?.lenderCode?.loanAmount,
								},
							};
						}
					}
				}
				return clonedWidget;
			}),
		};
		const payload: any = {
			modifiedData,
			requiredAmount: Combinations?.requiredAmount,
			requiredDate: Combinations?.requiredDate,
			redirectUrl: '',
		};
		if (
			Combination?.Widgets?.some((widget: any) => widget.widgetType === 'L')
		) {
			const jsonStringModifiedData =
				cryptoEncryptionDecryption.Encrypt(modifiedData);

			localStorage.setItem('lenderdata', jsonStringModifiedData);
			navigate(APP_ROUTES.SUBMIT_LOAN_APPLICATION, { state: modifiedData });
		} else {
			dispatch(saveSelectedCombinationRequest(payload as any));
			navigate(APP_ROUTES.TRACK_TRANSACTION);
		}
	};

	useEffect(() => {
		// Scroll to the top of the page when the route changes
		window.scrollTo(0, 0);
	}, []);
	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			className="lender-details"
		>
			{' '}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container>
					<Grid container className="mb-30 pr-10">
						<NeedMoneyHeader />
					</Grid>
					<Grid item className="mb-14">
						<CustomButton
							className="f-14 fw-400 "
							variant="text"
							color="primary"
							text={t('GO_BACK')}
							showIcon={false}
							startIcon={ArrowLeftIcon}
							startIconClassName="go-back-icon"
							onClick={() => {
								navigate(APP_ROUTES.Best_Fit_Combinations);
							}}
						/>
					</Grid>
					<Grid container alignItems="start">
						<Typography
							color={theme.palette.primary.dark}
							className="fw-600 "
							flexWrap="nowrap"
							display="flex"
							fontSize={18}
						>
							{t('REVIEW_EDIT_CUSTOMIZE')}
						</Typography>
					</Grid>
					<Grid container alignItems="start">
						<Typography color={theme.palette.common.black} className="f-14">
							[Currently using
							<b>
								{' '}
								{Combination?.currentlyUsingPolicyCount || 0} of{' '}
								{Combination?.eligiblePolicyCount || 0}{' '}
							</b>
							eligible policies]
						</Typography>
					</Grid>
					<Grid item className="mt-3 ml-n3" mb={3}>
						<CustomButton
							text={t('ADD_MORE_POLICIES_HERE')}
							variant="text"
							color="primary"
							fontSize={14}
							showIcon={false}
							fontWeight={400}
						/>
					</Grid>
				</Grid>

				<Grid
					container
					// className="all-product-box"
					// style={{ border: '2px solid black' }}

					justifyContent="flex-start"
				>
					{Combination?.Widgets?.some(
						(widget: any) => widget.widgetType === 'W'
					) && (
							<>
								<Grid container alignItems="start" mb={5}>
									<Typography
										color={theme.palette.primary.dark}
										className="fw-600 mt-25"
										flexWrap="nowrap"
										display="flex"
										fontSize={18}
									>
										{t('PARTIAL_WITHDRAWALS')}
									</Typography>
								</Grid>
							</>
						)}
					{Combination?.Widgets?.map((widget: any) => (
						<>
							{widget?.widgetType === 'W' && (
								<Grid className=" my-20">
									<LenderDetailsCard
										head={t('PARTIAL_WITHDRAWAL')}
										header="Partial Withdrawal"
										lenderType="Partial Withdrawal"
										icon={WithdrawalIcon}
										productLogo={widget?.insurerLogo}
										productName={`${widget?.policyType} ${widget?.planName}`}
										productNum={widget?.widgetNo}
										control={control}
										errors={errors}
										setValue={setValue}
										PartialWithdrawal="true"
										bankDetailsData={bankDetail.find(
											(item: any) => item.widgetNo === widget?.widgetNo
										)}
										inputValue={widget?.Withdrawal?.partialWithdrawalAmount}
										maturityValue={convertAmount(widget?.maturityValue)}
										currentFundValue={convertAmount(widget?.fundValue)}
										payerName={widget?.payerName}
										eligibleAmount={
											widget?.Withdrawal?.eligiblePartialWithdrawalValue
										}
									/>
								</Grid>
							)}
						</>
					))}
				</Grid>

				<Grid
					container
					// className="all-product-box"

					justifyContent="flex-start"
				>
					{Combination?.Widgets?.some(
						(widget: any) => widget.widgetType === 'L'
					) && (
							<>
								<Grid container alignItems="start" mb={5}>
									<Typography
										color={theme.palette.primary.dark}
										className="fw-600 mt-25"
										flexWrap="nowrap"
										display="flex"
										fontSize={18}
									>
										{t('LOAN_AGAINST_POLICY')}
									</Typography>
								</Grid>
							</>
						)}
					{Combination?.Widgets?.map((widget: any) => (
						<>
							{widget?.widgetType === 'L' && (
								<Grid className="my-20">
									<LenderDetailsCard
										inputValue={widget?.Loan?.loanAmount}
										icon={loanIcon}
										head={t('LOAN_AGAINST_POLICY')}
										header="Loan against Policy"
										productLogo={widget?.insurerLogo}
										productName={`${widget?.policyType} ${widget?.planName}`}
										productNum={widget?.widgetNo}
										lenderType="Financier"
										lender={widget?.Loan?.LenderDetails}
										control={control}
										errors={errors}
										setValue={setValue}
										witnessDetails={witnessDetail.find(
											(item: any) => item.widgetNo === widget?.widgetNo
										)}
										financierDetails={financierDetail.find(
											(item: any) => item.widgetNo === widget?.widgetNo
										)}
										bankDetails={bankDetail.find(
											(item: any) => item.widgetNo === widget?.widgetNo
										)}
										maturityValue={convertAmount(widget?.maturityValue)}
										currentFundValue={convertAmount(widget?.fundValue)}
										payerName={widget?.payerName}
										eligibleAmount={widget?.Loan?.eligibleLoanValue}
										eligibleType="loan"
									/>
								</Grid>
							)}
						</>
					))}
				</Grid>
				<Grid
					container
					// className="all-product-box"
					// style={{ border: '2px solid black' }}

					justifyContent="flex-start"
				>
					{Combination?.Widgets?.some(
						(widget: any) => widget.widgetType === 'S'
					) && (
							<>
								<Grid container alignItems="start" mb={5}>
									<Typography
										color={theme.palette.primary.dark}
										className="fw-600 mt-25"
										flexWrap="nowrap"
										display="flex"
										fontSize={18}
									>
										Policy Surrender
									</Typography>
								</Grid>
							</>
						)}
					{Combination?.Widgets?.map((widget: any) => (
						<>
							{widget?.widgetType === 'S' && (
								<Grid className=" my-20">
									<LenderDetailsCard
										head="Surrender Policy"
										header="Surrender"
										lenderType="Surrender"
										icon={SurrenderIcon}
										productLogo={widget?.insurerLogo}
										productName={`${widget?.policyType} ${widget?.planName}`}
										productNum={widget?.widgetNo}
										control={control}
										errors={errors}
										setValue={setValue}
										PartialWithdrawal="true"
										bankDetailsData={bankDetail.find(
											(item: any) => item.widgetNo === widget?.widgetNo
										)}
										inputValue={widget?.Surrender?.surrenderValue}
										maturityValue={convertAmount(widget?.maturityValue)}
										payerName={widget?.payerName}
										eligibleAmount={widget?.Surrender?.surrenderValue}
										eligibleType="Surrender"
									/>
								</Grid>
							)}
						</>
					))}
				</Grid>
				<Grid container className="mb-10 mt-10">
					<Separator color={theme.palette.grey[300]} />
				</Grid>
				<Grid container>
					<TotalAmountAvailable
						lender="lender"
						TotalNo={`(${Array.from(
							{
								length: Combination?.Widgets?.length,
							},
							(_, i) => i + 1
						).join('+')})`}
						totalFunds={convertAmount(Combination?.totalFundsAvailed)}
						lossOfCover={convertAmount(Combination?.lossOfCover)}
						charges={convertAmount(Combination?.totalCharges)}
						approxShow={false}
					/>
				</Grid>
				<Grid container className="mt-10">
					<Separator color={theme.palette.grey[300]} />
				</Grid>
				<Grid
					container
					className="mb-5 mt-5"
					display="flex"
					justifyContent="end"
				>
					<CustomButton
						fontSize={16}
						fontWeight={600}
						textAlign="right"
						text={t('PROCEED')}
						showIcon
						variant="text"
						color="primary"
						type="submit"
						disabled={!allWidgetNosPresent}
						loading={loading}
					/>
				</Grid>
				<Grid container className="mb-10">
					<Separator color={theme.palette.grey[300]} />
				</Grid>
				<Typography
					className="f-12 mb-10"
					color={theme.palette.grey[500]}
					textAlign="right"
				>
					{t('TERMS_AND_CONDITIONS_APPLY')}
				</Typography>
				<Grid />
			</form>
		</Grid>
	);
};
export default LenderScreen;
