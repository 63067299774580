import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { PolicyFooter } from '../PolicyFooter/PolicyFooter';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import Separator from '../../../../../common/ui/Separator';
import LifeHeader from '../PolicyCard/LifeHeader';
import PremiumDue from '../PolicyCard/PremiumDue';
import PayNow from '../PolicyCard/PayNow';
import { ReactComponent as InProgress } from '../../../../../common/icons/small-inprogress.svg';
import SvgConverter from '../../../../../common/SvgConverter';
import { ReactComponent as AlertIconRed } from '../../../../../common/icons/warningRed.svg.svg';

interface UserProps {
	contentType?: string;
	items?: any;
}
const ActiveLifeCard: React.FC<UserProps> = ({ contentType, items }) => {
	const { t } = useTranslation();

	return (
		<Box className="policy-card">
			{items.daFlag === 'S' ? (
				<Typography className="surrender-custom">
					{/* Policy Under Surrender */}
					{t('POLICY_UNDER_SURRENDER')}
				</Typography>
			) : (
				<Typography></Typography>
				// <Typography className='surrender-custom'></Typography>
			)}
			{items.verifiedFlag === 'Y' ? (
				<Grid container>
					<LifeHeader items={items} contentType={contentType} />
					<Separator color={theme.palette.primary.main} />
					<PremiumDue items={items} contentType={contentType} />

					{items.paymentFlag === 'Y' && (
						<>
							<PayNow items={items} />
						</>
					)}
					<Separator color={theme.palette.primary.main} />
					<PolicyFooter
						footerType={contentType}
						items={items}
						policyType="life"
					/>
				</Grid>
			) : items.verifiedFlag === 'R' ? (
				<Grid item xs={12} className="flex-container inProgress-card  my-10">
					<Grid container item xs={12} className="px-10 py-15" spacing={2}>
						<Grid item xs={3}>
							<img src={items.companyLogo} alt="logo" className="bank-image" />
						</Grid>
						<Grid item xs={8} className="ml-6">
							<Typography className="f-14  " color={theme.palette.primary.main}>
								{t('POLICY_REJECTION')}
							</Typography>
							<Typography
								className="f-16 fw-600 mt-3 wrap-text"
								color={theme.palette.primary.main}
							>
								{items?.policyNo}
							</Typography>
						</Grid>

						<Grid container item xs={12} display="flex" flexDirection="row">
							<Grid
								item
								xs={1}
								display="flex"
								justifyContent="center"
								className="mb-10"
							>
								<SvgConverter
									Icon={AlertIconRed}
									className="d-flex align-items-end mt-5"
								/>
							</Grid>
							<Grid item xs={10} justifyContent="flex-start" className="mb-10">
								<Typography
									className="f-16 fw-400 ml-10 mt-5 "
									color={theme.palette.error.main}
								>
									{t('REJECTED')}
								</Typography>
							</Grid>
							<Grid display="flex" alignItems="center">
								{/* <Grid item display="flex" justifyContent="center">
									<Typography
										className="f-16 f-600"
										color={theme.palette.secondary.main}
									>
										{t('REJECTED_DUE_TO')}
									</Typography>
								</Grid> */}
								<Grid display="flex" alignItems="center">
									<Grid item display="flex" justifyContent="center">
										<Typography
											className="f-16 f-600"
											color={theme.palette.secondary.main}
										>
											{t('REJECTED_DUE_TO')}{' '}
											<span
												className="f-16 fw-600"
												color={theme.palette.secondary.main}
											>
												{items?.rejectionDesc}
											</span>
										</Typography>
									</Grid>

									{/* <Grid item className="ml-5" justifyContent="flex-start">
										<Typography
											className="f-16 fw-600"
											color={theme.palette.secondary.main}
										>
										{items?.rejectionDesc}
										</Typography>
									</Grid> */}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			) : items.verifiedFlag === 'N' ? (
				// daFlag
				<>
					<Grid item xs={12} className="flex-container inProgress-card  my-10">
						<Grid container item xs={12} className="px-10 py-15" spacing={2}>
							<Grid item xs={3}>
								<img
									src={items.companyLogo}
									alt="logo"
									className="bank-image"
								/>
							</Grid>
							<Grid item xs={8} className="ml-6">
								<Typography
									className="f-14  "
									color={theme.palette.primary.main}
								>
									{t('POLICY_NUMBER')}
								</Typography>
								<Typography
									className="f-16 fw-600 mt-3 wrap-text"
									color={theme.palette.primary.main}
								>
									{items?.policyNo}
								</Typography>
							</Grid>

							<Grid container item xs={12} display="flex" flexDirection="row">
								<Grid
									item
									xs={1}
									display="flex"
									justifyContent="center"
									className="mb-10"
								>
									<SvgConverter
										Icon={InProgress}
										className="d-flex align-items-end mt-5"
									/>
								</Grid>
								<Grid
									item
									xs={10}
									justifyContent="flex-start"
									className="mb-10"
								>
									<Typography
										className="f-16"
										color={theme.palette.secondary.main}
									>
										{t('POLICY_DETAILS_AWAITED')}
									</Typography>
								</Grid>
								<Grid display="flex" alignItems="center">
									<Grid item display="flex" justifyContent="center">
										<Typography
											className="f-14"
											color={theme.palette.common.black}
										>
											{t('ESTIMATED_TIME_TAKEN')}
										</Typography>
									</Grid>
									<Grid item className="ml-4" justifyContent="flex-start">
										<Typography
											className="f-16 fw-600"
											color={theme.palette.secondary.main}
										>
											{'48'}
										</Typography>
									</Grid>
									<Grid item className="ml-5" justifyContent="flex-start">
										<Typography
											className="f-14"
											color={theme.palette.secondary.main}
										>
											{t('HRS')}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			) : (
				<></>
			)}
		</Box>
	);
};

export default ActiveLifeCard;
