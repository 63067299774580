import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';

interface LoanSlice {
	isLoading: LoadingState;
	data: any | null;
	errors: any | null;
	myOptions: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};
	payoutList: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};
	selectedCombination: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};

	saveSelectedCombination: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};
	trackTransaction: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};
	dashboardTracker: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};
	signatureReminder: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};
	loanWitness: any;
	loanFinances: any;
	loanBank: any;
	currentLoanPolicyCount: any;
	currentWithdrawalPolicyCount: any;
}
const initialState: LoanSlice = {
	isLoading: 'idle',
	data: null,
	errors: null,
	loanWitness: [],
	loanFinances: [],
	loanBank: [],
	currentLoanPolicyCount: null,
	currentWithdrawalPolicyCount: null,
	payoutList: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	},

	myOptions: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	},
	selectedCombination: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	},

	saveSelectedCombination: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	},
	trackTransaction: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	},
	dashboardTracker: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	},
	signatureReminder: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	}
};
const loanSlice = createSlice({
	name: 'loan',
	initialState,
	reducers: {
		addMoneyRequest: (state) => ({
			...state,
			isLoading: 'loading',
			errors: null,
			data: null,
		}),
		addMoneySuccess: (state, action) => ({
			...state,
			data: action.payload,
			errors: null,
			isLoading: 'idle',
		}),
		addMoneyError: (state, action) => ({
			...state,
			errors: action.payload,
			data: null,
		}),

		payoutRequest: (state) => {
			state.payoutList.status = 'loading';
			state.payoutList.requestInfo = undefined;
			state.payoutList.error = undefined;
		},
		payoutSuccess: (state, { payload }) => {
			state.payoutList.status = 'done';
			state.payoutList.requestInfo = payload;
			state.payoutList.error = undefined;
		},
		payoutError: (state, { payload }) => {
			state.payoutList.status = 'error';
			state.payoutList.requestInfo = undefined;
			state.payoutList.error = payload;
		},

		myOptionsRequest: (state) => {
			state.myOptions.status = 'loading';
			state.myOptions.requestInfo = undefined;
			state.myOptions.error = undefined;
		},
		myOptionsSuccess: (state, { payload }) => {
			state.myOptions.status = 'done';
			state.myOptions.requestInfo = payload;
			state.myOptions.error = undefined;
		},
		myOptionsError: (state, { payload }) => {
			state.myOptions.status = 'error';
			state.myOptions.requestInfo = undefined;
			state.myOptions.error = payload;
		},
		selectedCombinationRequest: (state) => {
			state.selectedCombination.status = 'loading';
			state.selectedCombination.requestInfo = undefined;
			state.selectedCombination.error = undefined;
			state.loanBank = [];
			state.loanWitness = [];
			state.loanFinances = [];
		},
		selectedCombinationSuccess: (state, { payload }) => {
			state.selectedCombination.status = 'done';
			state.selectedCombination.requestInfo = payload;
			state.selectedCombination.error = undefined;
		},
		selectedCombinationError: (state, { payload }) => {
			state.selectedCombination.status = 'error';
			state.selectedCombination.requestInfo = undefined;
			state.selectedCombination.error = payload;
		},
		saveSelectedCombinationRequest: (state) => {
			state.saveSelectedCombination.status = 'loading';
			state.saveSelectedCombination.requestInfo = undefined;
			state.saveSelectedCombination.error = undefined;
		},
		saveSelectedCombinationSuccess: (state, { payload }) => {
			state.saveSelectedCombination.status = 'done';
			state.saveSelectedCombination.requestInfo = payload;
			state.saveSelectedCombination.error = undefined;
			state.loanBank = [];
		},
		saveSelectedCombinationError: (state, { payload }) => {
			state.saveSelectedCombination.status = 'error';
			state.saveSelectedCombination.requestInfo = undefined;
			state.saveSelectedCombination.error = payload;
		},
		trackTransactionRequest: (state) => {
			state.trackTransaction.status = 'loading';
			state.trackTransaction.requestInfo = undefined;
			state.trackTransaction.error = undefined;
		},
		trackTransactionSuccess: (state, { payload }) => {
			state.trackTransaction.status = 'done';
			state.trackTransaction.requestInfo = payload;
			state.trackTransaction.error = undefined;
		},
		trackTransactionError: (state, { payload }) => {
			state.trackTransaction.status = 'error';
			state.trackTransaction.requestInfo = undefined;
			state.trackTransaction.error = payload;
		},
		dashboardTrackerRequest: (state) => {
			state.dashboardTracker.status = 'loading';
			state.dashboardTracker.requestInfo = undefined;
			state.dashboardTracker.error = undefined;
		},
		dashboardTrackerSuccess: (state, { payload }) => {
			state.dashboardTracker.status = 'done';
			state.dashboardTracker.requestInfo = payload;
			state.dashboardTracker.error = undefined;
		},
		dashboardTrackerError: (state, { payload }) => {
			state.dashboardTracker.status = 'error';
			state.dashboardTracker.requestInfo = undefined;
			state.dashboardTracker.error = payload;
		},
		getLoanData: (state, action) => {
			if (action.payload.lenderDetails) {
				const existingItemIndex = state.loanFinances.findIndex(
					(item: any) => item.widgetNo === action.payload.lenderDetails.widgetNo
				);
				if (existingItemIndex !== -1) {
					const updatedLoanFinances = [...state.loanFinances];
					updatedLoanFinances[existingItemIndex] = {
						...updatedLoanFinances[existingItemIndex],
						lenderDetails: action.payload.lenderDetails.lenderDetails,
					};
					state.loanFinances = updatedLoanFinances;
				} else {
					state.loanFinances = [
						action.payload.lenderDetails,
						...state.loanFinances,
					];
				}
			}
			if (action.payload.witnessData) {
				const existingItemIndex = state.loanWitness.findIndex(
					(item: any) => item.widgetNo === action.payload.witnessData.widgetNo
				);
				if (existingItemIndex !== -1) {
					const updatedLoanWitness = [...state.loanWitness];
					updatedLoanWitness.splice(existingItemIndex, 1);
					state.loanWitness = [
						action.payload.witnessData,
						...updatedLoanWitness,
					];
				} else {
					state.loanWitness = [
						action.payload.witnessData,
						...state.loanWitness,
					];
				}
			}
			if (action.payload.bankDetails) {
				const existingItemIndex = state.loanBank.findIndex(
					(item: any) => item.widgetNo === action.payload.bankDetails.widgetNo
				);
				if (existingItemIndex !== -1) {
					const updatedLoanBank = [...state.loanBank];
					updatedLoanBank[existingItemIndex] = {
						...updatedLoanBank[existingItemIndex],
						bankDetails: action.payload.bankDetails.bankDetails,
					};
					state.loanBank = updatedLoanBank;
				} else {
					state.loanBank = [action.payload.bankDetails, ...state.loanBank];
				}
			}
		},
		currentEligibleValue: (state, action) => {
			state.currentLoanPolicyCount = action.payload?.currentLoanPolicyCount;
			state.currentWithdrawalPolicyCount =
				action.payload?.currentWithdrawalPolicyCount;
		},
		signatureReminderRequest: (state) => {
			state.signatureReminder.status = 'loading';
			state.signatureReminder.requestInfo = undefined;
			state.signatureReminder.error = undefined;
		},
		signatureReminderSuccess: (state, { payload }) => {
			state.signatureReminder.status = 'done';
			state.signatureReminder.requestInfo = payload;
			state.signatureReminder.error = undefined;
		},
		signatureReminderError: (state, { payload }) => {
			state.signatureReminder.status = 'error';
			state.signatureReminder.requestInfo = undefined;
			state.signatureReminder.error = payload;
		},
	},
});

export const {
	addMoneyRequest,
	addMoneySuccess,
	addMoneyError,
	payoutError,
	payoutRequest,
	payoutSuccess,
	myOptionsRequest,
	myOptionsSuccess,
	myOptionsError,
	selectedCombinationRequest,
	selectedCombinationSuccess,
	selectedCombinationError,
	saveSelectedCombinationRequest,
	saveSelectedCombinationSuccess,
	saveSelectedCombinationError,
	trackTransactionRequest,
	trackTransactionSuccess,
	trackTransactionError,
	dashboardTrackerRequest,
	dashboardTrackerSuccess,
	dashboardTrackerError,
	getLoanData,
	currentEligibleValue,
	signatureReminderRequest,
	signatureReminderSuccess,
	signatureReminderError
} = loanSlice.actions;
export default loanSlice.reducer;

export const selectMyOptionIsLoading = (state: RootState) =>
	state.loan.myOptions.status === 'loading';
export const myOptions = (state: RootState) => state.loan.myOptions.requestInfo;
export const selectedCombination = (state: RootState) =>
	state.loan.selectedCombination.requestInfo;
export const saveSelectedCombinationLoading = (state: RootState) =>
	state.loan.saveSelectedCombination.status === 'loading';
export const saveSelectedCombination = (state: RootState) =>
	state.loan.saveSelectedCombination.requestInfo;

export const selectedCombinationLoading = (state: RootState) =>
	state.loan.selectedCombination.status === 'loading';

export const selectedCombinationstatus = (state: RootState) =>
	state.loan.selectedCombination.status;

export const selectTrackTransactionLoading = (state: RootState) =>
	state.loan.trackTransaction.status === 'loading';
export const trackTransactions = (state: RootState) =>
	state.loan.trackTransaction.requestInfo;
export const witnessStatus = (state: RootState) => state.loan.loanWitness;
export const financierStatus = (state: RootState) => state.loan.loanFinances;
export const bankStatus = (state: RootState) => state.loan.loanBank;
export const payoutDetails = (state: RootState) =>
	state.loan.payoutList.requestInfo;
export const payoutDetailsLoading = (state: RootState) =>
	state.loan.payoutList.status === 'loading';
export const currentEligibleLoanValue = (state: RootState) =>
	state.loan.currentLoanPolicyCount;
export const currentEligibleWithdrawalValue = (state: RootState) =>
	state.loan.currentWithdrawalPolicyCount;
