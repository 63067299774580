import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../../common/utils';
import { useTranslation } from 'react-i18next';

interface ProductCardPolicyProps {
	product?: string;
	lifeAssured?: string;
	logoSrc?: any;
	maturityValue?: any;
}

const ProductCardPolicy = ({
	product,
	lifeAssured,
	logoSrc,
	maturityValue,
}: ProductCardPolicyProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Grid container className="mt-5 mb-5 ">
			<Grid item xs={3}>
				<img src={logoSrc} alt="logo" className="bank_logo" />
			</Grid>
			<Grid xs={9}>
				<Grid item xs={12}>
					<Typography className="f-18 fw-600" color="primary">
						{product}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className="f-14" color={theme.palette.primary.main}>
						{t('LIFE_ASSURED')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						className="f-16 fw-600"
						color={theme.palette.common.black}
					>
						{lifeAssured}
					</Typography>
				</Grid>
				<Grid item xs={12} className="mt-5">
					<Typography className="f-14" color={theme.palette.primary.main}>
						{t('MATURITY_VALUE')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={0.5}>
						<LabelWithIcon
							className="px-6"
							svgClassName=""
							justifyContent="flex-start"
							Icon={RupeeIcon}
							label={convertAmount(maturityValue)}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 16,
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProductCardPolicy;
