import { Grid, Typography, useTheme } from '@mui/material';

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as needMoney } from '../../../common/icons/dashboard/need_money.svg';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import { ReactComponent as pay } from '../../../common/icons/dashboard/pay_premiums-icon.svg';
import { ReactComponent as home } from '../../../common/icons/dashboard/home-icon.svg';
import { ReactComponent as bank } from '../../../common/icons/dashboard/bank-icon.svg';
import { ReactComponent as group } from '../../../common/icons/dashboard/group-icon.svg';
import { ReactComponent as email } from '../../../common/icons/email-small.svg';
import { ReactComponent as mobile } from '../../../common/icons/mobile-small.svg';
import { ReactComponent as add } from '../../../common/icons/dashboard/small_add.svg';
import SvgConverter from '../../../common/SvgConverter';
import DashboardCardLayout from './common/DashboardCardLayout';
import Separator from '../../../common/ui/Separator';
import { APP_ROUTES } from '../../../common/constants/Routes';

const HelpComponent = () => {
	const theme = useTheme();
	const loanModule = [
		{
			icon: needMoney,

			title: 'I_AM_IN_NEED_OF_MONEY?',
			link: '/needmoney',
			state: "NeedMoney"
		},
		{
			icon: pay,
			title: 'FINANCE_MY_PREMIUMS',
			link: APP_ROUTES.FINANCEPREMIUM,
		},
	];
	let HealthContent: any = [
		{
			icon: home,
			title: 'UPDATE_ADDRESS',
			link: APP_ROUTES.MANAGE_ADDRESS,
		},
		{
			icon: mobile,
			title: 'UPDATE_MOBILE_NUMBER',
			link: APP_ROUTES.UPDATE_MOBILE,
		},
		{
			icon: email,
			title: 'UPDATE_EMAIL',
			link: APP_ROUTES.EMAIL,
		},
		{
			icon: bank,
			title: 'UPDATE_BANK_DETAILS',
			link: APP_ROUTES.BANK,
		},
		{
			icon: group,
			title: 'SEE/UPDATE_NOMINEES',
			link: APP_ROUTES.NOMINEE,
		},
		{
			icon: add,
			title: 'ADD_POLICIESS',
			link: APP_ROUTES.ADD_POLICY_INSTRUCTION,
		},
	];
	HealthContent =
		process.env.REACT_APP_MODE !== 'PROD'
			? loanModule.concat(HealthContent)
			: HealthContent;
	const { t } = useTranslation();
	return (
		<DashboardCardLayout>
			<div className="w-280px">
				<div className="my-20">
					<Separator color={theme.palette.primary.main} />
				</div>
				<Typography
					fontSize={16}
					color="primary"
					className="mb-25"
					fontWeight={600}
				>
					{`${t('HOW_MAY_WE_HELP')}?`}
				</Typography>
				<div className="mb-10">
					<Separator color={theme.palette.grey[300]} />
				</div>
				{HealthContent.map((data: any, key: any) => (
					<div key={key} style={{ padding: 5 }}>
						<Link to={data.link ? data.link : '#'}>
							<Grid item xs={12} container alignItems="center">
								{/* <Grid item sx={{ width: 40 }}> */}
								<Grid item xs={2} justifyContent="center" alignItems="center">
									<Grid item>
										<SvgConverter Icon={data.icon} className="d-flex" />
									</Grid>
									{/* </Grid> */}
								</Grid>
								{/* <Grid> */}
								<Grid item xs={8} container spacing={1} justifyContent="space-between">
									{/* <Grid item> */}
									<Typography color="primary" fontSize={16} style={{ wordWrap: 'break-word' }}  >
										{t(data.title)}
									</Typography>
								</Grid>
								{/* </Grid> */}
								<Grid item xs={2}>
									<SvgConverter
										Icon={rightArrow}
										className="right-arrow d-flex"
									/>
								</Grid>

								{/* </Grid> */}
							</Grid>
						</Link>
						<div className="my-10">
							<Separator color={theme.palette.grey[300]} />
						</div>
					</div>
				))}
			</div>

		</DashboardCardLayout>
	);
};

export default HelpComponent;
