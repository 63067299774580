import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useTheme } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';

interface labelProps {
	color?: string | undefined;
	fontWeight?: number | undefined;
}
interface fundAndcoverLoss {
	TotalNo?: string | number;
	totalFunds?: any;
	lossOfCover?: any;
	labelProps?: labelProps | undefined;
	approxShow?: boolean;
	combination?: any;
}
const TotalFundAndLossCoverDetails = ({
	TotalNo,
	totalFunds,
	lossOfCover,
	combination,
	labelProps,
	approxShow = true,
}: fundAndcoverLoss) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<>
			<Grid container justifyContent="space-between" className="mb-3">
				<Grid item>
					<Typography
						className="f-14 fw-600"
						color={theme.palette.common.black}
					>
						{t('Total Funds Available')}
					</Typography>
					<Grid item>
						<Typography className="f-14 mt-n2">{TotalNo}</Typography>
					</Grid>
				</Grid>
				<Grid item xs={5}>
					<Grid>
						<Grid container alignItems="center">
							<Grid item className="mr-5">
								<SvgConverter Icon={RupeeIcon} width={8} height={12} />
							</Grid>
							<Grid item>
								<Typography
									className="f-16 fw-600"
									color={theme.palette.common.black}
								>
									{totalFunds && totalFunds?.length === 0 ? '0' : totalFunds}
								</Typography>
							</Grid>
						</Grid>
						{approxShow && (
							<Grid item xs textAlign="right">
								<Typography className="f-12" color={theme.palette.common.black}>
									{`(${t('approx')})`}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
			{combination?.Widgets.some(
				(widget: any) => widget.widgetType === 'S'
			) && (
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography
							className="f-14 fw-600"
							color={theme.palette.common.black}
						>
							{t('Loss of Cover')}
						</Typography>
					</Grid>
					<Grid item xs={5}>
						<Grid container spacing={0.5} alignItems="center">
							<Grid item>
								<SvgConverter Icon={RupeeIcon} width={8} height={12} />
							</Grid>
							<Grid item>
								<Typography
									className="f-16 fw-600"
									color={labelProps?.color || theme.palette.common.black}
									fontWeight={labelProps?.fontWeight}
								>
									{lossOfCover && lossOfCover.length === 0 ? '0' : lossOfCover}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default TotalFundAndLossCoverDetails;
