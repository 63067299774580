import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import theme from '../../../common/theme';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as Verify } from '../../../common/icons/update-mobile/verify_orange.svg';
import { ReactComponent as Cancel } from '../../../common/icons/update-mobile/cancel_orange.svg';
import { ReactComponent as EmailIcon } from '../../../common/icons/login_email-icon.svg';
import { ReactComponent as rightIcon } from '../../../common/icons/right_arrow-icon.svg';

const PersonalDetailModal = ({
	open,
	setOpen,
	onSubmit,
	loading,
	type,
	addType,
}: any) => {
	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		setValue,
		trigger,
		register,
	} = useForm({ defaultValues: { Aadhaar: '', PASSPORT: '' } });
	const { t } = useTranslation();
	useEffect(() => {
		if (!open) {
			reset();
		}
	}, [open, reset]);
	const handleAadhaarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const aadhaarValue = e.target.value.replace(/[^\d]/g, '').slice(0, 12);
		setValue('Aadhaar', aadhaarValue, { shouldValidate: true });
		trigger('Aadhaar');
	};
	const handlePassportChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const passportValue = e.target.value.replace(
			/[^(?!^0+$)[A-Z]{1}[0-9]{7,11}$]/gi,
			''
		);
		const limitedPassportValue = passportValue.slice(0, 12).toUpperCase();
		setValue('PASSPORT', limitedPassportValue, { shouldValidate: true });
		trigger('PASSPORT');
	};
	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 350 }}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				{addType === 'passportAdd' ? (
					<Typography
						className="f-16 fw-6 px-20"
						color={theme.palette.primary.main}
					>
						{t('PLS_ENTER_PASSPORT_NUMBER')}
					</Typography>
				) : (
					<Typography
						className="f-16 fw-6 px-20"
						color={theme.palette.primary.main}
					>
						{t('PLS_ENTER_AADHAR_NUMBER')}
					</Typography>
				)}
				<Grid container flexWrap="nowrap" spacing={1} className="py-10">
					<Grid item flex={1}>
						{addType === 'passportAdd' ? (
							<FormInput
								type=""
								placeholder=""
								name="PASSPORT"
								control={control}
								inputProps={{
									className: 'px-10',
									...register('PASSPORT', {}),
									maxLength: 12,
									autoCapitalize: 'characters',
								}}
								rules={{
									required: {
										value: true,
										message: t('PASSPORT_REQUIRED'),
									},
									pattern: {
										value: /^(?!^0+$)[A-Z]{1}[0-9]{7,11}$/,
										message: t('ENTER_VALID_PASSPORT_NUMBER'),
									},
									onChange: handlePassportChange,
								}}
								errors={errors}
							/>
						) : (
							<FormInput
								type=""
								placeholder=""
								name="Aadhaar"
								control={control}
								inputProps={{
									className: 'px-10',
									...register('Aadhaar', {}),
								}}
								rules={{
									required: {
										value: true,
										message: t('AADHAR_REQUIRED'),
									},
									minLength: {
										value: 12,
										message: t('AADHAR_12_DIGIT'),
									},
									onChange: handleAadhaarChange,
								}}
								errors={errors}
							/>
						)}
					</Grid>
				</Grid>
				<Grid textAlign="center" className="mt-10">
					<Grid display="flex">
						<Grid item xs={6}>
							<CustomButton
								text={t('SUBMIT')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Verify}
								type="submit"
								loading={loading}
								className="f-16 fw-600"
							/>
						</Grid>
						<Grid item xs={6}>
							<CustomButton
								text={t('CANCEL')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Cancel}
								onClick={() => setOpen(false)}
								className="f-16"
							/>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</CommonModal>
	);
};
export default PersonalDetailModal;
