import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import SvgConverter from '../../../../../common/SvgConverter';
import { ReactComponent as Premium } from '../../../../../common/icons/dashboard/pay_premiums-icon.svg';
import { ReactComponent as OrangeRuppee } from '../../../../../common/icons/small-orange-rupee.svg';
import Separator from '../../../../../common/ui/Separator';
import CustomButton from '../../../../../common/ui/CustomButton';
import { ReactComponent as Circle } from '../../../../../common/icons/dashboard/circle_arrow-icon.svg';
import { convertAmount } from '../../../../../common/utils';
import Currency from '../../../Common/Currency';

interface UserProps {
	contentType?: string;
	items?: any;
	PolicyType?: string;
	type?: any;
}
const PremiumDue: React.FC<UserProps> = ({
	contentType,
	items,
	PolicyType,
	type,
}) => {
	const dateValue = items && items?.premuimDueDate;
	const formattedDate = dayjs(dateValue).format('DD/MM/YYYY');

	const { t } = useTranslation();

	return (
		<>
			{type !== 'asset' && type !== 'health' && (
				<>
					{items.paymentFlag === 'Y' && (
						<Grid container className="p-5">
							{items.policyType === 'ULIP' ? (
								<Grid item xs={5} className="py-10">
									<Typography
										className="f-14  px-5 nowrap-text"
										color={theme.palette.primary.main}
									>
										{t('FUND_VALUE')}
									</Typography>
									<Grid
										container
										alignItems="center"
									>
										<Currency policyDetails={items} amount={items.fundValue} amountClass={"f-16 ml-5 fw-600"} />
									</Grid>
									{/* <LabelWithIcon
										className="px-6"
										svgClassName=""
										justifyContent="flex-start"
										Icon={OrangeRuppee}
										label={convertAmount(items.fundValue)}
										labelProps={{
											color: theme.palette.common.black,
											fontSize: 16,
											fontWeight: 600,
										}}
									/> */}
								</Grid>
							) : (
								<Grid item xs={5} className="py-10">
									<Typography
										className="f-14  px-5 nowrap-text"
										color={theme.palette.primary.main}
									>
										{t('MATURITY_VALUE')}
									</Typography>
									<Grid
										container
										alignItems="center"
									>
										<Currency policyDetails={items} amount={items.maturityValue} amountClass={"f-16 ml-3 fw-600"} />
									</Grid>

									{/* <LabelWithIcon
										className="px-6"
										svgClassName=""
										justifyContent="flex-start"
										Icon={OrangeRuppee}
										label={convertAmount(items.maturityValue)}
										labelProps={{
											color: theme.palette.common.black,
											fontSize: 16,
											fontWeight: 600,
										}}
									/> */}
								</Grid>
							)}

							<Grid item xs={1} className="px-5 py-10  ml-5 fw-800 separator">
								<Separator
									orientation="vertical"
									color={theme.palette.secondary.main}
									borderWidth={1}
								/>
							</Grid>
							<Grid item xs={5} className="py-10">
								<Typography
									className="f-14 px-8 nowrap-text"
									color={theme.palette.primary.main}
								>
									{t('SURRENDER_VALUE')}
								</Typography>
								<Grid
									container
									alignItems="center"
								>
									<Currency policyDetails={items} amount={items.surrenderValue} amountClass={"f-16 ml-3 fw-600"} />
								</Grid>
								{/* <LabelWithIcon
									className="px-6 "
									justifyContent="flex-start"
									svgClassName=""
									Icon={OrangeRuppee}
									label={convertAmount(items.surrenderValue)}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 16,
										fontWeight: 600,
									}}
								/> */}
							</Grid>

							<Grid item xs={12}>
								<Typography
									className="f-12 px-10 py-5 mb-5 "
									color={theme.palette.grey[400]}
								>
									{/* [Last Updated on DDMMYYYY] */}
								</Typography>
							</Grid>
							{/* <Grid
								item
								container
								xs={12}
								className="px-10"
								flexDirection="row-reverse"
							>
								<Separator color={theme.palette.primary.dark} />
								<Grid item>
									<CustomButton
										text={t('REFRESH_NOW')}
										variant="text"
										color="primary"
										fontWeight={600}
										fontSize={14}
										endIcon={Circle}
										className="py-6"
									/>
								</Grid>
							</Grid> */}
						</Grid>
					)}
				</>
			)}

			{contentType === 'active' && (
				<Separator color={theme.palette.primary.main} />
			)}

			<Grid container className="p-5 premium-container">
				<Grid item xs={1}>
					<SvgConverter Icon={Premium} className="ml-2 mt-5" />
				</Grid>
				<Grid item xs={4} className="ml-5">
					<Typography className="f-14" color={theme.palette.primary.main}>
						{t('PREMIUM')}
					</Typography>

					<Grid
						container
						alignItems="center"
					>
						<Currency policyDetails={items} amount={items?.premiumAmount.length === 0 ? '-' : items?.premiumAmount} amountClass={"f-16 ml-3 fw-600"} />
					</Grid>
					{/* <LabelWithIcon
						justifyContent="flex-start"
						Icon={OrangeRuppee}
						label={convertAmount(
							items?.premiumAmount.length === 0 ? '-' : items?.premiumAmount
						)}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 16,
							fontWeight: 600,
						}}
					/> */}
				</Grid>

				<Grid item xs={6} className="mt-8">
					{contentType === 'archive' && PolicyType === 'life' ? (
						<>
							<Typography
								className="f-14 "
								color={theme.palette.secondary.main}
								style={{ wordWrap: 'break-word' }}
							>
								{t('TERM_ENDED')}
								{formattedDate === 'Invalid Date' ? '-' : formattedDate}
							</Typography>
						</>
					) : contentType === 'archive' &&
						(PolicyType === 'health' || PolicyType === 'assets') ? (
						<Typography className="f-14 " color={theme.palette.secondary.main}>
							{t('POLICY_TERM_OVER_ON')}
							{formattedDate === 'Invalid Date' ? '-' : formattedDate}
						</Typography>
					) : (
						<Typography className="f-14 " color={theme.palette.primary.main}>
							{t('DUE_BY')}{' '}
							{formattedDate === 'Invalid Date' ? '-' : formattedDate}
						</Typography>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default PremiumDue;
