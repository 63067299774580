import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import '../Nominee.scss';
import { useTranslation } from 'react-i18next';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as Add } from '../../../../common/icons/plus-icon.svg';
import {
	fetchFamilyNameListAction,
	selectNomineeIsLoading,
	selectUserFamilyNameList,
} from '../Nominee.slice';
import Spinner from '../../../../common/ui/Spinner';
import CommonModal from '../../../../common/ui/Modal/CommonModal';

const NomineeListModal = ({
	open,
	setOpen,
	addNomineeCloseModal,
	addNomineeModal,
}: any) => {
	const dispatch = useDispatch();
	const nomineeNames = useSelector(selectUserFamilyNameList);
	const listLoading = useSelector(selectNomineeIsLoading);

	useEffect(() => {
		dispatch(fetchFamilyNameListAction());
	}, []);
	const { t } = useTranslation();
	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			boxProps={{ width: 400, height: 449 }}
			modalClassName="nominee-list-modal"
		>
			{listLoading ? (
				<Grid
					justifyContent="center"
					display="flex"
					alignItems="center"
					style={{ backgroundColor: 'white', height: 100, borderRadius: 6 }}
				>
					<Spinner />
				</Grid>
			) : (
				<Grid
					container
					style={{
						maxHeight: '350px',
						overflowY: 'auto',
						backgroundColor: 'white',
						borderRadius: 6,
					}}
				>
					{nomineeNames.map((item, index) => (
						<Grid item xs={12} key={index}>
							<Grid container justifyContent="space-between">
								<Grid item xs={9} key={index}>
									<CustomButton
										text={`${item.firstName} ${item.middleName} ${item.lastName}`}
										variant="text"
										color="inherit"
										showIcon={false}
										className="f-14 fw-600 px-15 py-13"
										onClick={() => addNomineeCloseModal(item)}
									/>
								</Grid>
								<Grid item xs={3} key={index}>
									<Typography
										className="f-14 fw-400 px-15 py-13"
										color={theme.palette.common.black}
									>
										{item.relationName}
									</Typography>
								</Grid>
							</Grid>
							{index !== nomineeNames.length - 1 && (
								<Separator color={theme.palette.primary.main} />
							)}
						</Grid>
					))}
				</Grid>
			)}
			<Grid item xs={12}>
				<Grid
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '1rem',
					}}
					className="nominee-list-modal"
				>
					<Typography
						className="f-16 fw-600 "
						color={theme.palette.primary.main}
						style={{
							backgroundColor: 'white',
							borderRadius: '50%',
							width: 'fit-content',
							padding: '0.5rem',
							border: '1px solid blue',
						}}
					>
						{t('OR')}
					</Typography>
				</Grid>
				<Typography
					className="f-16 fw-600 px-12 py-10"
					color={theme.palette.primary.main}
					textAlign="center"
					style={{
						backgroundColor: 'white',
						borderRadius: '7px',
						marginTop: '1rem',
						border: '1px solid blue',
					}}
				>
					<CustomButton
						className="f-16 fw-600 add-btn"
						text={t('ADD_A_NEW_NOMINEE')}
						variant="text"
						color="primary"
						showIcon={false}
						startIcon={Add}
						onClick={addNomineeModal}
					/>
				</Typography>
			</Grid>
		</CommonModal>
	);
};

export default NomineeListModal;
