import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid/Grid';

import { useDispatch, useSelector } from 'react-redux';
import { Typography, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { groupBy } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
	changeEmail,
	fetchPoliciesForEmails,
	generateOtpForEmailRequest,
	isGenerateOtpForEmailLoading,
	isUpdateEmailLoading,
	removeEmail,
	selectEmailChangesList,
	selectIsEmailVerifyLoading,
	selectIsPolicyEmailsLoading,
	selectPoliciesForEmails,
	setEmailChangesList,
	updateEmailChangesRequest,
	verifyEmailRequest,
	changePrimaryEmail,
	assignEmail,
} from './Emails.slice';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import { ReactComponent as EmailIcon } from '../../../common/icons/email-small.svg';
import ManageEmailHeader from './ManageEmailHeader';
import { setAlertInfo } from '../Common/Common.slice';
import config from '../../../common/config';
import OtpEntryModal from '../Common/OtpEntryModal';
import EmailEntryModal from '../Common/EmailEntryModal';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { SELECT_POLICY_TYPES_FOR_MODULES } from '../../../common/Enums';
import {
	fetchOtpRequest,
	selectIsOtpRequestLoading,
	selectIsUserLoggedIn,
} from '../../Auth/auth.slice';
import DoneModal from '../Common/DoneModal';
import Spinner from '../../../common/ui/Spinner';
import ChangeEmailModal from './Modals/ChangeEmailModal';
import RemoveEmailModal from './Modals/RemoveEmailModal';
import ConfirmationModal from '../../../common/ui/Modal/ConfirmationModal';
import './Email.scss';
import Separator from '../../../common/ui/Separator';
import ApiModal from '../../../common/ui/Modal/ApiModal';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';


const ManageEmailScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { t } = useTranslation();

	const theme = useTheme();
	const [showAddModal, setShowAddModal] = useState(false);
	const [showDoneModal, setShowDoneModal] = useState(false);
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [showRemoveNumberModal, setShowRemoveNumberModal] = useState(false);
	const [showChangeNumberModal, setShowChangeNumberModal] = useState(false);
	const [selectedPolicyDetails, setSelectedPolicyDetails] = useState<any>({});
	const [selectedEmail, setSelectedEmail] = useState({});
	const [verifyEmailDuration, setVerifyEmailDuration] = useState(0);
	const [newEmailData, setNewEmailData] = useState<any>({});
	const [isInstantVerifyRunning, setIsInstantVerifyRunning] = useState(false);
	const [removeUnAssignedEmail, setRemoveUnAssignedEmail] = useState<any>({
		email: null,
		primaryFlag: null,
	});
	const [isPreSelectedPolicyType, setIsPreSelectedPolicyType] =
		useState<any>(false);
	const [isPrimaryChange, setIsPrimaryChange] = useState<boolean>(false);
	const policiesForEmail = useSelector(selectPoliciesForEmails);
	const isGenerateOtpIsLoading = useSelector(isGenerateOtpForEmailLoading);
	const isResendOtpLoading = useSelector(selectIsOtpRequestLoading);
	const isVerifyEmailLoading = useSelector(selectIsEmailVerifyLoading);
	const emailChangesList = useSelector(selectEmailChangesList);
	const isUpdateEmailIsLoading = useSelector(isUpdateEmailLoading);
	const loggedUser = useSelector(selectIsUserLoggedIn);
	const Location = useLocation();
	const email = Location.state?.email;

	useEffect(() => {
		if (Location.state === 'Profile') {
			setShowAddModal(true);
		} else {
			setShowAddModal(false);
		}
	}, [Location.state]);

	const toggleAddNumberModal = (
		isPoliciesPreSelected: boolean,
		isChange: boolean,
		primary: any
	) => {
		if (isPoliciesPreSelected === true) {
			setIsPreSelectedPolicyType(
				primary ? 'CHANGEPRIMARY' : isChange ? 'CHANGE' : 'REMOVE'
			);
		} else {
			setIsPreSelectedPolicyType(false);
		}
		setShowAddModal(!showAddModal);
		setShowRemoveNumberModal(false);
		setShowChangeNumberModal(false);
	};

	const toggleDoneModal = () => {
		if (emailChangesList?.length > 0) {
			setShowDoneModal(!showDoneModal);
		} else {
			navigate(-1);
		}
		return true;
	};

	const toggleRemoveNumberModal = (email: string) => {
		setSelectedEmail(email);
		setShowRemoveNumberModal(!showRemoveNumberModal);
	};
	const toggleChangeEmailModal = (data: any) => {
		setSelectedPolicyDetails(data);
		setShowChangeNumberModal(!showChangeNumberModal);
		setIsPrimaryChange(false);
	};
	const togglePrimaryChangeModal = (data: any) => {
		setIsPrimaryChange(true);
		setSelectedPolicyDetails(data);
		setShowChangeNumberModal(!showChangeNumberModal);
	};
	const isPoliciesForEmailsLoading = useSelector(selectIsPolicyEmailsLoading);

	useEffect(() => {
		if (emailChangesList?.length === 0) dispatch(fetchPoliciesForEmails());
	}, [dispatch, emailChangesList]);

	const anyIntegPolicies = useMemo(() =>
		policiesForEmail?.emailBasedPolicies?.some((item: any) => item.insIntegFlag === 'Y')
		, [policiesForEmail]);

	const handleOtpVerify = (otpCode: any) => {
		const data: any = { otpCode, email: newEmailData?.email };
		data.handleSuccess = () => {
			if (isPreSelectedPolicyType === 'CHANGE') {
				setShowOtpModal(false);
				setIsPreSelectedPolicyType(false);
				dispatch(
					changeEmail({
						selectedPolicy: selectedPolicyDetails,
						targetEmail: newEmailData?.email,
					})
				);
			} else if (isPreSelectedPolicyType === 'REMOVE') {
				setShowOtpModal(false);
				dispatch(
					removeEmail({
						newValue: newEmailData?.email,
						oldValue: selectedEmail,
					})
				);
			} else if (isPreSelectedPolicyType === 'CHANGEPRIMARY') {
				setShowOtpModal(false);
				dispatch(
					changePrimaryEmail({
						selectedEmail: selectedPolicyDetails?.email,
						targetedEmail: newEmailData?.email,
						isNew: true,
					})
				);
			} else {
				if (
					policiesForEmail.emailBasedPolicies.length === 0 &&
					policiesForEmail.unAssignedEmails.length === 0
				) {
					dispatch(
						changePrimaryEmail({
							selectedEmail: newEmailData?.email,
							targetedEmail: newEmailData?.email,
							isNew: true,
							newEmail: true,
						})
					);
				}

				setShowAddModal(false);
				const { handleSuccess, ...newEmailDataLocal }: any = newEmailData;
				if (isInstantVerifyRunning !== true) {
					if (anyIntegPolicies) {
						navigate(APP_ROUTES.ASSIGN_POLICY, {
							state: {
								newEmailData: newEmailDataLocal,
								MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.EMAIL,
							},
						});
					}
					else {
						const payload = {
							selectedPolicies: [],
							newEmail: newEmailDataLocal?.email,
							isOld: false,
							primaryFlag: newEmailDataLocal?.primaryFlag ?? 'Z',
							purpose: '',
						};
						dispatch(assignEmail(payload));
					}
					setIsInstantVerifyRunning(false);
				}
				setShowOtpModal(false);
			}
		};
		dispatch(verifyEmailRequest(data));
	};

	const handleEmailChangesSubmit = () => {
		const handleSuccess: any = () => {
			setShowDoneModal(false);
			navigate(-1);
		};
		dispatch(updateEmailChangesRequest({ handleSuccess } as any));
	};

	const handleNewEmailSubmit = (data: any) => {
		const groupByEmail: any = groupBy<any>(
			(item: any) => item?.email.toLowerCase(),
			policiesForEmail?.emailBasedPolicies?.map((policy: any) => ({
				...policy,
				email: policy?.email.toLowerCase(),
			}))
		);
		const inputEmail = data?.email.toLowerCase();
		const isEmailIdDuplicate =
			inputEmail in groupByEmail && groupByEmail[inputEmail].length > 0;

		const isEmailIdAssigned = policiesForEmail?.unAssignedEmails?.some(
			(item: any) => item?.email.toLowerCase() === inputEmail
		);

		if (isEmailIdDuplicate || isEmailIdAssigned) {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('Email is already exist.'),
				})
			);
			setShowAddModal(false);
		} else if (policiesForEmail?.unAssignedEmails?.length <= 5) {
			setNewEmailData(data);
			data.handleSuccess = () => {
				setShowAddModal(false);
				setShowOtpModal(true);
				setVerifyEmailDuration(config.otpDuration);
			};
			dispatch(generateOtpForEmailRequest(data));
		} else {
			setShowAddModal(false);
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('Only 5 unassigned Emails are allowed.'),
				})
			);
		}
	};

	const handleResendOtp = () => {
		const handleSuccess = () => {
			setVerifyEmailDuration(config.otpDuration);
		};
		const data: any = {
			eiaNo: loggedUser?.eiaNo,
			value: newEmailData?.email,
			otpPurpose: 'UPDATE_EMAIL',
			flag: 'EMAIL',
		};
		dispatch(
			fetchOtpRequest({
				Customer: data,
				handleSuccess,
			} as any)
		);
	};

	const handleCancel = () => {
		navigate(-1);
		dispatch(setEmailChangesList([]));
	};

	const handleRemoveForUnAssignedMobiles = () => {
		if (removeUnAssignedEmail.primaryFlag === 'Y') {
			setShowChangeNumberModal(true);
			setSelectedPolicyDetails({
				type: 'primaryEmailChange',
				email: removeUnAssignedEmail.email,
			});
			setRemoveUnAssignedEmail({ email: null, primaryFlag: null });
			return;
		}
		const handleDone = () => {
			setRemoveUnAssignedEmail(false);
		};
		const payload: any = {
			oldValue: removeUnAssignedEmail.email,
			isUnAssignedEmail: true,
			handleDone,
		};
		dispatch(removeEmail(payload));
	};

	const handleAssignPolicy = (data: any) => {
		navigate(APP_ROUTES.ASSIGN_POLICY, {
			state: {
				newEmailData: data,
				MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.EMAIL,
				isOld: true,
			},
		});
	};
	useEffect(() => {
		if (email) {
			togglePrimaryChangeModal({ email });
			history.replaceState(null, Location?.pathname);
		}
	}, [email]);

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Manage Emails Screen',
		});
	}, []);


	return (
		<Grid item className="ml-50 mr-50 mb-50 email-style">
			<div className="fixed-policy-header">
				<Grid item xs={12} justifyContent="center">
					<Grid container justifyContent="space-between">
						<Typography
							className="f-16 fw-600"
							color={theme.palette.primary.main}
						>
							{t('MANAGE_EMAILS')}
						</Typography>

						<CustomButton
							text={t('DONE')}
							variant="text"
							color="primary"
							showIcon
							fontSize={14}
							onClick={toggleDoneModal}
							endIcon={rightArrow}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} className="mt-5 ml-5">
					<CustomButton
						text={t('ADD_A_EMAIL')}
						variant="text"
						color="primary"
						showIcon={false}
						onClick={() => setShowAddModal(true)}
						startIcon={EmailIcon}
						className="fw-600 f-14"
					/>
				</Grid>
				<Grid item className="mt-7">
					<Separator color={theme.palette.primary.main} borderWidth={1} />
				</Grid>
			</div>
			<div
				style={{
					marginTop: 80,
				}}
			/>
			{isPoliciesForEmailsLoading ? (
				<Grid container justifyContent="center">
					<Grid item>
						<Spinner />
					</Grid>
				</Grid>
			) : (
				<ManageEmailHeader
					toggleRemoveNumberModal={toggleRemoveNumberModal}
					toggleChangeEmailModal={toggleChangeEmailModal}
					handleAssignPolicy={handleAssignPolicy}
					handleRemoveForUnAssignedEmails={(email: any, primaryFlag: any) =>
						setRemoveUnAssignedEmail({ email, primaryFlag })
					}
					togglePrimaryChangeModal={togglePrimaryChangeModal}
				/>
			)}

			<EmailEntryModal
				open={showAddModal}
				setOpen={setShowAddModal}
				onSubmit={handleNewEmailSubmit}
				loading={isGenerateOtpIsLoading}
			/>
			<OtpEntryModal
				isEmail
				value={newEmailData.email}
				open={showOtpModal}
				setOpen={setShowOtpModal}
				onSubmit={handleOtpVerify}
				loading={isVerifyEmailLoading}
				onFinishDuration={() => setVerifyEmailDuration(0)}
				duration={verifyEmailDuration}
				resendLoading={isResendOtpLoading}
				handleResendOtp={handleResendOtp}
			/>
			<DoneModal
				open={showDoneModal}
				setOpen={setShowDoneModal}
				onSubmit={handleEmailChangesSubmit}
				loading={isUpdateEmailIsLoading}
				onCancel={handleCancel}
				contentType="EMAIL"
			/>
			<ChangeEmailModal
				open={showChangeNumberModal}
				setOpen={setShowChangeNumberModal}
				selectedPolicyDetails={selectedPolicyDetails}
				toggleAddNumberModal={toggleAddNumberModal}
				showAddButton={selectedPolicyDetails?.type !== 'primaryEmailChange'}
				isPrimaryChange={isPrimaryChange}
			/>
			<RemoveEmailModal
				open={showRemoveNumberModal}
				setOpen={setShowRemoveNumberModal}
				selectedEmail={selectedEmail}
				toggleAddNumberModal={toggleAddNumberModal}
			/>
			<ConfirmationModal
				show={Boolean(
					!!removeUnAssignedEmail.email && !!removeUnAssignedEmail.primaryFlag
				)}
				setShow={setRemoveUnAssignedEmail}
				description={`${t(
					'ARE_YOU_SURE_TO_REMOVE'
				)} ${removeUnAssignedEmail?.email} 
				${`${t('FROM_UNASSIGNED_EMAIL')}?`}`}
				confirmText="Remove"
				cancelText="Cancel"
				onConfirm={handleRemoveForUnAssignedMobiles}
				onCancel={() =>
					setRemoveUnAssignedEmail({ email: null, primaryFlag: null })
				}
			/>
			<ApiModal />
		</Grid>
	);
};
export default ManageEmailScreen;
