import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import { getFormatDate } from '../../../../../../common/utils';

interface BranchCodeProps {
	branchDetails?: any;
}
const BranchCode: React.FC<BranchCodeProps> = ({ branchDetails }) => {
	const formattedPolicyIssuanceDate = getFormatDate(
		branchDetails && branchDetails?.policyIssuanceDate,
		'YYYY-MM-DD'
	);
	const formattedRiskCommencementDate = getFormatDate(
		branchDetails && branchDetails?.riskCommencement
	);
	const formattedRiskCessationtDate = getFormatDate(
		branchDetails && branchDetails?.riskCessation
	);
	const formattedEndorsementCommencementTime = dayjs(
		(branchDetails && branchDetails?.endorsementCommencement) || '-'
	).format('HH:mm');
	const formattedEndorsementCommencementDate = getFormatDate(
		branchDetails && branchDetails?.endorsementCommencement
	);
	const formattedEndorsementCessationTime = dayjs(
		(branchDetails && branchDetails?.endorsementCessation) || '-'
	).format('HH:mm');
	const formattedEndorsementCessationDate = getFormatDate(
		branchDetails && branchDetails?.endorsementCessation
	);
	const formattedRiskCommencementTime = dayjs(
		(branchDetails && branchDetails?.riskCommencement) || '-'
	).format('HH:mm');
	const formattedRiskCessationTime = dayjs(
		(branchDetails && branchDetails?.riskCessation) || '-'
	).format('HH:mm');
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid item xs={6} className="">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('BRANCH_CODE')}
				</Typography>{' '}
				<Typography color={theme.palette.common.black} fontSize={18}>
					{branchDetails?.branchCode ? branchDetails?.branchCode : '-'}
				</Typography>
			</Grid>
			<Grid item xs={6} className="">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('APPLICATION')} #
				</Typography>{' '}
				<Typography color={theme.palette.common.black} fontSize={16}>
					{branchDetails?.application ? branchDetails?.application : '-'}
				</Typography>
			</Grid>
			<Grid item xs={12} className="py-10 mt-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('COVER_NOTE_CERTIFICATION')} #
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{branchDetails?.coverNoteCertification
						? branchDetails?.coverNoteCertification
						: '-'}
				</Typography>
			</Grid>
			<Grid item xs={12} className="py-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('POLICY_ISSUANCE_DATE')}
				</Typography>{' '}
				<Typography color={theme.palette.common.black} fontSize={18}>
					{formattedPolicyIssuanceDate === 'Invalid Date'
						? '-'
						: formattedPolicyIssuanceDate}
				</Typography>
			</Grid>
			<Grid item xs={12} className="py-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('RISK_COMMENCEMENT')}
				</Typography>{' '}
				<Typography color={theme.palette.common.black} fontSize={18}>
					{formattedRiskCommencementDate === 'Invalid Date'
						? '-'
						: formattedRiskCommencementDate}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{formattedRiskCommencementTime === 'Invalid Date'
						? '-'
						: formattedRiskCommencementTime}
				</Typography>
			</Grid>
			<Grid item xs={12} className="py-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('RISK_CESSATION')}
				</Typography>{' '}
				<Typography color={theme.palette.common.black} fontSize={18}>
					{formattedRiskCessationtDate === 'Invalid Date'
						? '-'
						: formattedRiskCessationtDate}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{formattedRiskCessationTime === 'Invalid Date'
						? '-'
						: formattedRiskCessationTime}
				</Typography>
			</Grid>{' '}
			<Grid item xs={12} className="py-10">
				{(formattedEndorsementCommencementDate !== 'Invalid Date' ||
					formattedEndorsementCommencementTime !== 'Invalid Date') &&
					formattedEndorsementCommencementTime !== '-' &&
					formattedEndorsementCommencementDate !== '-' &&
					formattedEndorsementCommencementTime !== ' ' &&
					formattedEndorsementCommencementDate !== ' ' && (
						<>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('ENDORSEMENT_COMMENCEMENT')}
							</Typography>{' '}
							<Typography color={theme.palette.common.black} fontSize={18}>
								{formattedEndorsementCommencementDate === 'Invalid Date'
									? '-'
									: formattedEndorsementCommencementDate}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{formattedEndorsementCommencementTime === 'Invalid Date'
									? '-'
									: formattedEndorsementCommencementTime}
							</Typography>
						</>
					)}
			</Grid>
			<Grid item xs={12} className="py-10">
				{(formattedEndorsementCessationDate !== 'Invalid Date' ||
					formattedEndorsementCessationTime !== 'Invalid Date') &&
					formattedEndorsementCessationDate !== '-' &&
					formattedEndorsementCessationTime !== '-' &&
					formattedEndorsementCessationDate !== ' ' &&
					formattedEndorsementCessationTime !== ' ' && (
						<>
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('ENDORSEMENT_CESSATION')}
							</Typography>{' '}
							<Typography color={theme.palette.common.black} fontSize={18}>
								{formattedEndorsementCessationDate === 'Invalid Date'
									? '-'
									: formattedEndorsementCessationDate}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{formattedEndorsementCessationTime === 'Invalid Date'
									? '-'
									: formattedEndorsementCessationTime}
							</Typography>
						</>
					)}
			</Grid>
		</Grid>
	);
};

export default BranchCode;
