import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Grid, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as MobileIcon } from '../../../common/icons/mobile-icon.svg';
import { ReactComponent as PanCardIcon } from '../../../common/icons/pan_card_icon.svg';
import { ReactComponent as LabelCalendarIcon } from '../../../common/icons/login_calendar_label-icon.svg';
import { ReactComponent as WitnessPerson } from '../../../common/icons/LoanModule/witness-icon.svg';
import { ReactComponent as WitnessEmail } from '../../../common/icons/LoanModule/witness_email_icon.svg';
import { ReactComponent as cancel } from '../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../common/icons/LoanModule/right-arrow-icon.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { APP_ROUTES } from '../../../common/constants/Routes';
import DatePickerModal from '../../../common/ui/DatePickerModal';
import CustomButton from '../../../common/ui/CustomButton';
import './LoanModule.scss';
import data from '../../../common/constants/FormConstants.json';
import config from '../../../common/config';
import { ageCalculator, countryCode } from '../../../common/utils';
import { getLoanData, selectedCombination, witnessStatus } from './Loan.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import cryptoEncryptionDecryption from '../../../common/crypto';

const AddWitnessDetails = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const [isChecked, setISChecked] = useState(false);
	const [emailError, setEmailError] = useState({});
	const [mobileError, setMobileError] = useState({});
	const [nameError, setNameError] = useState({});
	const location = useLocation();
	const dispatch = useDispatch();
	const isEditFromTrackTransaction = location.state?.isEditFromTrackTransaction;
	const Combinations: any = isEditFromTrackTransaction ? location.state?.combinations?.Combination : useSelector(selectedCombination);
	const witnessDetail: any = useSelector(witnessStatus);
	const bankDetails = location.state?.bankDetails;
	const lenderDetails = location.state?.lenderDetails;
	const widgetNo = location.state?.widgetNo;
	const userData: any = useSelector(selectIsUserLoggedIn);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		control,
	} = useForm({
		defaultValues: {
			mobileCountryCode: '91',
			witnessPan: '',
			witnessMobileNo: '',
			dob: '',
			witnessEmailId: '',
			witnessFirstName: '',
			witnessLastName: '',
		},
	});
	const mobileNo = watch('witnessMobileNo');
	const email = watch('witnessEmailId');
	const firstName = watch('witnessFirstName');
	const lastName = watch('witnessLastName');
	useEffect(() => {
		if (((firstName.trim().concat(" ", lastName.trim()))).toLowerCase() === userData.customerName.toLowerCase()) {
			setNameError({
				witnessFirstName: {
					type: 'required',
					message: 'Witness Name cannot be the same as policyholders',
				},
				witnessLastName: {
					type: 'required',
					message: 'Witness Name cannot be the same as policyholders',
				},
			});
		} else {
			setNameError(errors);
		}
	}, [firstName, lastName, errors]);

	function isEmpty(obj: any) {
		return Object.keys(obj).length === 0;
	}

	useEffect(() => {
		if (email.toLowerCase() === userData.email.toLowerCase()) {
			setEmailError({
				witnessEmailId: {
					type: 'required',
					message: 'Witness Email cannot be the same as policyholders',
				},
			});
		} else {
			setEmailError(errors);
		}
	}, [email, errors]);

	useEffect(() => {
		if (mobileNo === userData.mobileNo) {
			setMobileError({
				witnessMobileNo: {
					type: 'required',
					message: 'Witness Mobile number cannot be the same as policyholders',
				},
			});
		} else {
			setMobileError(errors);
		}
	}, [mobileNo, errors]);

	const navigate = useNavigate();
	const handleDateChange = (date: any) => {
		setValue('dob', date?.format('DD/MM/YYYY'), { shouldValidate: true });
	};
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('witnessMobileNo', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);
	const dobValue = String(watch('dob')).split('/');

	const panCardRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
	const mobileNumberRegex = /^[6789]\d{9,}$/;

	const isMinor: any = useMemo(
		() =>
			(ageCalculator(`${dobValue[1]}/${dobValue[0]}/${dobValue[2]}`) as any) <
			18,
		[dobValue]
	);

	const handlePanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const panValue = event.target.value
			.toUpperCase()
			.replace(/[^\w\s]/gi, '')
			.slice(0, 10);
		setValue('witnessPan', panValue, { shouldValidate: true });
	};
	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>, name: any) => {
		const nameValue = event.target.value
			.replace(/[^\w\s]/gi, '')
			.replace(/[^A-Za-z\s]/gi, '');
		setValue(name, nameValue, { shouldValidate: true });
	};
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('witnessEmailId', newValue, { shouldValidate: true });
	};

	const onSubmit = (data: any) => {
		console.log(data)
		if (isEditFromTrackTransaction) {
			const modifiedData: any = {
				eligiblePolicyCount: Combinations?.eligiblePolicyCount || '',
				CurrentlyUsingPolicyCount: Combinations?.currentlyUsingPolicyCount || '',
				combinationName: Combinations?.combinationName,
				totalCharges: Combinations?.totalCharges,
				totalChargesDetails: Combinations?.totalChargesDetails,
				sessionId: Combinations?.sessionId,
				totalFundsAvailed: Combinations?.totalFundsAvailed,
				lossOfCover: Combinations?.lossOfCover,
				Widgets: Combinations?.Widgets?.map((widget: any) => {
					const commonProperties: any = {
						panNo: userData?.pan,
						camsRefNo: '',
					};
					const clonedWidget: any = {
						...widget,
						...commonProperties,
					};

					if (data && clonedWidget?.widgetType === 'L' && clonedWidget.widgetNo == widgetNo) {
						clonedWidget.Loan = {
							...clonedWidget.Loan,
							Witness: {
								witnessFirstName: data?.witnessFirstName,
								witnessLastName: data?.witnessLastName,
								witnessPan: data?.witnessPan,
								witnessMobileNo: data?.witnessMobileNo,
								witnessAddress: '',
								witnessEmailId: data?.witnessEmailId,
								witnessDOB: data?.dob,
							},
						};
					}

					return clonedWidget;
				}),
			};
			const payload: any = {
				modifiedData,
				requiredAmount: Combinations?.requiredAmount,
				requiredDate: Combinations?.requiredDate,
				redirectUrl: '',
			};

			const jsonStringModifiedData =
				cryptoEncryptionDecryption.Encrypt(modifiedData);

			localStorage.setItem('lenderdata', jsonStringModifiedData);
			navigate(APP_ROUTES.SUBMIT_LOAN_APPLICATION, { state: { modifiedData, isEditFromTrackTransaction } });
		}

		else {
			navigate(APP_ROUTES.Lender_Screen, {
				state: { witnessData: data, bankDetails, lenderDetails, widgetNo },
			});
			dispatch(
				getLoanData({
					witnessData: { witness: data, widgetNo },
					bankDetails,
					lenderDetails,
				})
			);
		}
	};

	const handleCheckIsWitness = (event: any) => {
		const isCheck = event.target.checked;
		const withnessdetails = witnessDetail[0]?.witness;
		setISChecked(isCheck);
		if (!isChecked) {
			setValue('witnessFirstName', withnessdetails?.witnessFirstName);
			setValue('witnessLastName', withnessdetails?.witnessLastName);
			setValue('witnessEmailId', withnessdetails?.witnessEmailId);
			setValue('witnessMobileNo', withnessdetails?.witnessMobileNo);
			setValue('witnessPan', withnessdetails?.witnessPan);
			setValue('dob', withnessdetails?.dob);
		} else {
			setValue('witnessFirstName', '');
			setValue('witnessLastName', '');
			setValue('witnessEmailId', '');
			setValue('witnessMobileNo', '');
			setValue('witnessPan', '');
			setValue('dob', '');
		}
	};
	useEffect(() => {
		if (!Combinations) {
			navigate(APP_ROUTES.NEED_MONEY);
		}
	}, [Combinations]);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const datePicker = (e: any) => {
		setAnchorEl(e.target);
	};
	const combinationLength = Combinations?.Combination?.Widgets?.filter(
		(item: any) => item.widgetType === 'L'
	).length;

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				justifyContent="center"
				className="need-money"
				margin="0 auto"
			>
				<Grid container className="my-20 ">
					<Typography
						className="f-16 fw-600"
						color={theme.palette.primary.main}
					>
						{t('Add_Witness_Details')}
					</Typography>
				</Grid>
				<Grid container className="mb-5 ">
					<Grid
						container
						spacing={2}
						justifyContent="space-between"
						className="mb-14"
					>
						<Grid item>
							<Link to={APP_ROUTES.Lender_Screen}>
								<LabelWithIcon
									className="f-14"
									justifyContent="flex-start"
									Icon={cancel}
									label={t('CANCEL')}
									labelProps={{
										fontSize: 14,
										color: 'primary',
									}}
									iconProps={{ width: 18, height: 18 }}
								/>
							</Link>
						</Grid>
						<Grid item>
							<CustomButton
								className="f-14 fw-600 "
								text={t('PROCEED')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={correct}
								type="submit"
								disabled={
									(isMinor && dobValue?.length > 1) ||
									!isEmpty(nameError) ||
									!isEmpty(emailError) ||
									!isEmpty(mobileError)
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid justifyContent="center">
					<Grid
						container
					//  xs={12} md={8} margin={'0 auto'}
					>
						{combinationLength > 1 && (
							<Typography>
								{' '}
								<Checkbox
									sx={{
										color: 'primary.main',
										'&.Mui-checked': {
											color: 'primary',
										},
									}}
									disabled={witnessDetail?.length === 0}
									size="small"
									onChange={handleCheckIsWitness}
								/>
								Use previous witness details
							</Typography>
						)}
					</Grid>
					<Grid
						container
						// xs={12}
						// md={8}
						className="w-100"
						justifyContent="space-between"
						margin="0 auto"
					>
						<Grid
							item
							// xs={12} sm={5} md={5}
							className="mb-15 w-100"
						>
							<LabelWithIcon
								Icon={WitnessPerson}
								label={t('FIRST_NAME_OF_WITNESS')}
								justifyContent="flex-start"
								className="mb-6 ml-3"
							/>
							<FormInput
								name="witnessFirstName"
								type="text"
								placeholder="Type here.."
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Witness First Name is mandatory!',
									},
									validate: (value: any) => {
										if (!config.alphabetsOnlyRegex.test(value)) {
											return `${t(data.INVALID_NAME)}`;
										}
										return true;
									},
									onChange: (event: any) => handleNameChange(event, 'witnessFirstName'),
								}}
								// disabled={isChecked}
								errors={nameError}
								inputProps={{
									maxLength: config.nameLength,
								}}
							/>
						</Grid>

						<Grid
							item
							// xs={12} sm={5} md={5}
							className="mb-15 w-100"
						>
							<LabelWithIcon
								Icon={WitnessPerson}
								label={t('LAST_NAME_OF_WITNESS')}
								justifyContent="flex-start"
								className="mb-6 ml-3"
							/>
							<FormInput
								name="witnessLastName"
								type="text"
								placeholder="Type here.."
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Witness Last Name is mandatory!',
									},
									validate: (value: any) => {
										if (!config.alphabetsOnlyRegex.test(value)) {
											return `${t(data.INVALID_NAME)}`;
										}
										return true;
									},
									onChange: (event: any) => handleNameChange(event, 'witnessLastName'),
								}}
								// disabled={isChecked}
								errors={nameError}
								inputProps={{
									maxLength: config.nameLength,
								}}
							/>
						</Grid>

						<Grid
							item
							// xs={12} sm={5} md={5}
							className="mb-15 w-100"
						>
							<LabelWithIcon
								Icon={PanCardIcon}
								label={t('WITNESS_PAN_NUMBER')}
								justifyContent="flex-start"
								className="mb-6 ml-3 "
							/>

							<FormInput
								type="text"
								className="pan-input"
								placeholder="ABCDF2340X"
								name="witnessPan"
								control={control}
								rules={{
									required: {
										value: true,
										message: `${t(data.PANCARD)}`,
									},
									minLength: {
										value: 10,
										message: `${t(data.INVALID_PAN)}`,
									},
									maxLength: {
										value: 10,
										message: `${t(data.INVALID_PAN)}`,
									},
									validate: (value: any) => {
										if (!panCardRegex.test(value)) {
											return `${t(data.INVALID_PAN)}`;
										}
										return true;
									},
									onChange: handlePanChange,
								}}
								// inputProps={{
								// 	...register('witnessPan', {}),
								// }}
								// disabled={isChecked}
								errors={errors}
							/>
						</Grid>
						<Grid
							item
							// xs={12} sm={5} md={5}
							className="mb-15 w-100"
						>
							<LabelWithIcon
								Icon={MobileIcon}
								label={t('MOBILE_NUMBER_OF_WITNESS')}
								justifyContent="flex-start"
								className="mb-6 ml-3"
							/>
							<Grid container flexWrap="nowrap" spacing={1}>
								<Grid item xs={3}>
									<FormInput
										inputProps={{
											maxLength: 4,
											...register('mobileCountryCode', {
												required: {
													value: true,
													message: 'CountryCode is required',
												},

												onChange: (e: any) => {
													setValue(
														'mobileCountryCode',
														countryCode(e.target.value)
													);
												},
											}),
										}}
										name="mobileCountryCode"
										placeholder="91"
										hideCountButton
										className="mobile-pin"
										readOnly
										errors={errors}
									/>
								</Grid>
								<Grid item flex={1}>
									<FormInput
										// type="number"
										name="witnessMobileNo"
										placeholder="1234567890"
										hideCountButton
										className="mobile-number"
										control={control}
										rules={{
											required: `${t(data.MOBILENUMBER)}`,
											// maxLength: {
											// 	value: 10,
											// 	message: data.MOBILEDIGIT,
											// },
											pattern: {
												value: mobileNumberRegex,
												message: t('INVALID_MOBILE_NUMBER'),
											},
											onChange: (e: any) => {
												setValue('witnessMobileNo', e.target.value, {
													shouldValidate: true,
												});
											},
										}}
										// disabled={isChecked}
										errors={mobileError}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							// xs={12} sm={5} md={5}
							className="mb-15 w-100"
						>
							<LabelWithIcon
								Icon={WitnessEmail}
								label={t('WITNESS_EMAIL')}
								justifyContent="flex-start"
								className="mb-6 ml-3"
							/>

							<FormInput
								type="text"
								placeholder="email@website.com"
								name="witnessEmailId"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Email is required',
									},
									pattern: {
										value: config.emailRegex,
										message: 'Please enter valid email',
									},

									onChange: handleEmailChange,
								}}
								inputProps={{
									maxLength: config.emailLength,
									...register('witnessEmailId', {
										required: {
											value: userData?.email,
											message: 'Please enter a valid email',
										},
									}),
								}}
								// disabled={isChecked}
								errors={emailError}
							/>
						</Grid>

						<Grid
							container
							item // xs={12} sm={5} md={5}
							flexDirection="column"
						>
							<Grid item className="my-3">
								<LabelWithIcon
									Icon={LabelCalendarIcon}
									label={t('WITNESS_DATE_OF_BIRTH')}
									iconProps={{ height: 33 }}
									justifyContent="flex-start"
									className="ml-3"
								/>
							</Grid>
							<Grid
								item
								container
								flexDirection="row"
								spacing={2}
								alignItems="center"
								justifyContent="space-between"
								flexWrap="nowrap"
							>
								<Grid item sm={3}>
									<FormInput
										name="date"
										inputProps={{
											maxLength: 2,
											style: { textAlign: 'center' },
											value: dobValue?.[0],
										}}
										placeholder="DD"
										readOnly
										// disabled={isChecked}
										onClick={datePicker}
										className="date-month-input"
									/>
								</Grid>
								<Grid item sm={3}>
									<FormInput
										name="month"
										inputProps={{
											maxLength: 2,
											style: { textAlign: 'center' },
											value: dobValue?.[1] ? dobValue?.[1] : '',
										}}
										placeholder="MM"
										readOnly
										className="date-month-input"
										onClick={datePicker}
									// disabled={isChecked}
									/>
								</Grid>
								<Grid item sm={4}>
									<FormInput
										name="year"
										inputProps={{
											maxLength: 4,
											style: { textAlign: 'center' },
											value: dobValue?.[2] ? dobValue?.[2] : '',
										}}
										placeholder="YYYY"
										readOnly
										className="signup-year-input"
										onClick={datePicker}
									// disabled={isChecked}
									/>
								</Grid>
								<Grid item>
									<DatePickerModal
										iconType="primary"
										disableFuture
										onChange={handleDateChange}
										value={
											dobValue.length === 3
												? `${dobValue[0]}/${dobValue[1]}/${dobValue[2]}`
												: false
										}
										modalOpen={anchorEl}
										setModalOpen={setAnchorEl}
									/>
								</Grid>
							</Grid>{' '}
							<FormInput
								hidden
								name="dob"
								inputProps={{
									...register('dob', {
										required: {
											value: true,
											message: `${t(data.DOB)}`,
										},
									}),
								}}
								errors={
									isMinor && dobValue?.length > 1
										? { dob: { message: 'Age is below 18 years' } }
										: errors
								}
							// disabled={isChecked}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

export default AddWitnessDetails;
