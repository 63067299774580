import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import './legal.scss';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';



const DataProtectionScreen = () => {
	const { t } = useTranslation();

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Data Protection Screen',
		});
	}, []);

	return (
		<Grid container spacing={3} justifyContent="center" className="mb-30 mt-15">
			<span className="arrow-span">
				<Link to={APP_ROUTES.DASHBOARD}>
					<SvgConverter Icon={ArrowLeftIcon} className="left-arrow-style" />
				</Link>
			</span>
			<Grid item xs={10} sm={10}>
				<Grid item>
					<Typography
						fontSize={18}
						color="black"
						fontWeight={800}
						fontFamily="sans-serif"
						className="mb-5"
					>
						{t('DATA_PROTECTION_POLICY')}
					</Typography>
				</Grid>
				<Grid item>
					<Typography fontSize={14}>{t('CAMSREP_COLLECTS_RECORDS')}</Typography>
				</Grid>
			</Grid>
			<Grid container xs={10} sm={10}>
				<ul>
					<li>
						<Typography>{t('INVESTOR_BANK_ACCOUNT_DETAILS')}</Typography>
					</li>
					<li>
						<Typography className="mt-10">
							{t('PAYMENT_INSTRUMENT_DETAILS_PROVIDED')}
						</Typography>
					</li>
					<li>
						<Typography className="mt-10">
							{t('GENERATION_AND_STORING_PASSWORD')}
						</Typography>
					</li>
					<li>
						<Typography className="mt-10">
							{t('BIOMETRIC_INFORMATION')}
						</Typography>
					</li>
					<li>
						<Typography className="mt-10">
							{t('PHYSICAL_PHYSIOLOGICAL_AND_MENTAL_HEALTH')}
						</Typography>
					</li>
				</ul>
			</Grid>
			<Grid item xs={10} sm={10}>
				<Grid container className="content-box p-35">
					<Grid item>
						<Typography fontSize={14} fontFamily="sans-serif">
							{t('THE_ABOVE_INFORMATION_SHALL_BE_RECEIVED')}
						</Typography>
						<Typography
							fontSize={14}
							fontFamily="sans-serif"
							className="mt-10 text-color"
							fontWeight={800}
						>
							{t('THE_INFORMATION_COLLECTED_IS_STORED')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={10} sm={10}>
				<Grid container>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							{t('WE_ASPIRE_TO_ADHERE')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							fontSize={14}
							color="black"
							fontWeight={600}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							{t('THESE_GENERAL_PRINCIPLES')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid container xs={10} sm={10}>
				<ul>
					<li>
						<Typography>{t('FAIRLY_AND_LAWFULLY')}</Typography>
					</li>
					<li>
						<Typography className="mt-10">
							{t('PROCESSED_FOR_LIMITED_PURPOSES')}
						</Typography>
					</li>
					<li>
						<Typography className="mt-10">
							{t('ACCURATE_AND_KEPT_UP_TO_DATE.')}
						</Typography>
					</li>
					<li>
						<Typography className="mt-10">
							{t('NOT_KEPT_FOR_LONGER_THAN_IS_NECESSARY.')}
						</Typography>
					</li>
					<li>
						<Typography className="mt-10">{t('KEPT_SECURE')}.</Typography>
					</li>
				</ul>
			</Grid>
			<Grid item xs={10} sm={10}>
				<Grid container>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
						>
							{t('IN_ORDER_TO_MEET_THE_REQUIREMENTS')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography fontSize={14} fontFamily="sans-serif" className="mt-20">
							{t('WE_HAVE_A_RESPONSIBLE_DATA_SECURITY_POLICY')}
						</Typography>
						<Typography fontSize={14} fontFamily="sans-serif" className="mt-10">
							{t('WE_PROVIDE_DETAILS_OF_THE_POLICY_HOLDERS')}
						</Typography>
						<Typography fontSize={14} fontFamily="sans-serif" className="mt-10">
							{t('WE_MAY_BE_REQUIRED_FROM_TIME')}
						</Typography>
						<Typography fontSize={14} fontFamily="sans-serif" className="mt-10">
							{t('WE_WILL_PERMIT_ONLY_AUTHORIZED_EMPLOYEES')}
						</Typography>
					</Grid>
				</Grid>
				<Grid container className="content-box p-20 mt-10">
					<Grid item>
						<Typography fontSize={16} fontWeight={800} fontFamily="sans-serif">
							{t('GRIEVANCE_OFFICER')}{' '}
							<span className="info">– {t('INFORMATION_SECURITY')}</span>
						</Typography>
						<Typography fontSize={14} fontFamily="sans-serif" className="mt-10">
							{t('R.SANJAY_JAIN')}
						</Typography>
						<Typography fontSize={14} fontFamily="sans-serif" className="mt-5">
							{t('RAYALA_TOWERS_ANNA_SALAI_CHENNAI')}
						</Typography>
						<Typography fontSize={14} fontFamily="sans-serif" className="mt-5">
							{t('PH')}.044-30212555
						</Typography>
						<Typography
							fontSize={14}
							fontFamily="sans-serif"
							className="text-alignment mt-5 email-text-res"
						>
							{t('EMAIL')}:
							<Typography
								fontSize={14}
								fontWeight={500}
								fontFamily="sans-serif"
								className="ml-2 email-text-color"
							>
								ITgrievance@camsrepository.com
							</Typography>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={10} sm={10}>
				<Typography fontSize={15} className="mt-10 text-color">
					{t('THE_USERS_OF_THE_COMPUTER_RESOURCES')}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default DataProtectionScreen;
