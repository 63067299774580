/* eslint-disable no-unused-vars */
export enum PolicyTypes {
	ASSET = 'asset',
	LIFE = 'life',
	HEALTH = 'health',
}

export const PolicyTypesLabel = {
	[PolicyTypes.ASSET]: 'Asset - Motors',
	[PolicyTypes.LIFE]: 'Life',
	[PolicyTypes.HEALTH]: 'Health',
};

export enum PolicyAlternateCodes {
	HEALTH = 'H',
	LIFE = 'L',
	ASSET = 'G',
}

export type Policy = PolicyTypes.ASSET | PolicyTypes.HEALTH | PolicyTypes.LIFE;
