import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import theme from '../../../common/theme';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as Verify } from '../../../common/icons/update-mobile/verify_orange.svg';
import { ReactComponent as Cancel } from '../../../common/icons/update-mobile/cancel_orange.svg';
// import { parseMobileNo } from '../../../common/utils';
import data from '../../../common/constants/FormConstants.json';
import { ReactComponent as rightIcon } from '../../../common/icons/right_arrow-icon.svg';
import { countryCode } from '../../../common/utils';

const MobileEntryModal = ({
	open,
	setOpen,
	onSubmit,
	handleOtp,
	loading,
	type,
	mobile
}: any) => {
	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		setValue,
		watch,
	} = useForm({ defaultValues: { mobileCountryCode: '91', mobileNo: '' } });
	const mobileNo = watch('mobileNo');
	const { t } = useTranslation();
	const mobileNumberRegex = /^[6789]\d{9,}$/;
	useEffect(() => {
		if (!open) {
			reset();
		}
	}, [open, reset]);

	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}

		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('mobileNo', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);

	useEffect(() => {
		reset({ mobileCountryCode: '91', mobileNo: mobile });
	}, [mobile])
	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 340 }}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Typography
					className="f-16 fw-600 px-35 "
					color={theme.palette.primary.main}
					align="center"
				>
					{t('ENTER_MOBILE_NUMBER')}
				</Typography>

				<Grid container flexWrap="nowrap" spacing={1} className="py-10">
					<Grid item sm={3} xs={3}>
						<FormInput
							placeholder="91"
							hideCountButton
							name="mobileCountryCode"
							control={control}
							rules={{
								required: 'Required',
								onChange: (e: any) => {
									setValue('mobileCountryCode', countryCode(e.target.value));
								},
							}}
							inputProps={{
								style: { textAlign: 'center' },
							}}
							readOnly
							errors={errors}
						/>
					</Grid>
					<Grid item>
						<FormInput
							// type="number"
							name="mobileNo"
							placeholder="1234567890"
							hideCountButton
							control={control}
							rules={{
								required: t(data.MOBILENUMBER),
								pattern: {
									value: mobileNumberRegex,
									message: t('INVALID_MOBILE_NUMBER'),
								},
								onChange: (e: any) => {
									setValue('mobileNo', e.target.value, {
										shouldValidate: true,
									});
								},
							}}
							errors={errors}
						/>
					</Grid>
				</Grid>

				{type === 'signup' ? (
					<>
						<Grid display="flex" justifyContent="space-between">
							<Grid item>
								<CustomButton
									text={t('CLOSE')}
									variant="text"
									color="primary"
									showIcon={false}
									// startIcon={Verify}
									// type="submit"
									// loading={loading}
									onClick={() => setOpen(false)}
									className="f-16 fw-600"
								/>
							</Grid>
							<Grid item>
								<CustomButton
									text={t('ADD_MOBILE')}
									variant="text"
									color="primary"
									showIcon
									type="submit"
									// startIcon={Cancel}
									// onClick={() => setOpen(false)}
									endIcon={rightIcon}
									className="f-16"
								/>
							</Grid>
						</Grid>
					</>
				) : (
					<>
						<Grid container textAlign="center" className="mt-10">
							<Grid item xs={6}>
								<CustomButton
									text={t('VERIFY')}
									variant="text"
									color="primary"
									showIcon={false}
									startIcon={Verify}
									onClick={handleOtp}
									type="submit"
									loading={loading}
									className="f-16 fw-600"
								/>
							</Grid>
							<Grid item xs={6}>
								<CustomButton
									text={t('CANCEL')}
									variant="text"
									color="primary"
									showIcon={false}
									startIcon={Cancel}
									onClick={() => setOpen(false)}
									className="f-16"
								/>
							</Grid>
						</Grid>
					</>
				)}
			</form>
		</CommonModal>
	);
};

export default MobileEntryModal;
