import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { ReactComponent as RupeeIconSmall } from '../../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { useTranslation } from 'react-i18next';

interface SubmitLoanFinanceProps {
	product?: string;
	lifeAssured?: string;
	selfName?: string;
	charges?: string;
	rupeeTwo?: string | number;
	repaymentOptions?: string;
	decideLater?: string;
	loanAmount?: any;
	chargesAmount?: any;
}

const SubmitLoanFinance = ({
	product,
	lifeAssured,
	selfName,
	charges,
	rupeeTwo,
	repaymentOptions,
	decideLater,
	chargesAmount,
	loanAmount,
}: SubmitLoanFinanceProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography className="f-14 fw-600" color="primary">
					{product}
				</Typography>
			</Grid>
			<Grid item xs={12} color={theme.palette.primary.main}>
				<Typography className="f-12">{lifeAssured}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className="f-12 fw-600">{selfName}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid item>
						<LabelWithIcon
							className="px-3"
							svgClassName=""
							justifyContent="flex-start"
							Icon={RupeeIconSmall}
							label={loanAmount}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
					</Grid>

					<Grid item>
						<Typography className="f-12" color={theme.palette.grey.A700}>
							@ &nbsp;
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							className="f-12 fw-600"
							color={theme.palette.common.black}
						>
							{rupeeTwo}
						</Typography>
					</Grid>
					<Grid item>
						<Typography className="f-12" color={theme.palette.grey.A700}>
							&nbsp; {t('INTEREST')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container flexWrap="nowrap">
					<Grid item>
						<Typography className="f-12" color={theme.palette.primary.main}>
							{charges}
						</Typography>
					</Grid>
					<Grid item>
						<LabelWithIcon
							className="px-6"
							svgClassName=""
							justifyContent="flex-start"
							Icon={RupeeIconSmall}
							label={chargesAmount}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} className="mt-10">
				<Typography className="f-12" color={theme.palette.primary.main}>
					{repaymentOptions}
				</Typography>
			</Grid>
			<Grid item xs={12} container>
				<Typography className="f-14 fw-600">{decideLater}</Typography>
				{/* <RupeeIconSmall className="mt-6 ml-6 mr-5" />
				<Typography className="f-14 fw-600">{decideLater} per month</Typography> */}
			</Grid>
		</Grid>
	);
};

export default SubmitLoanFinance;
