import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import Separator from '../../../../../common/ui/Separator';

import LifeHeader from '../PolicyCard/LifeHeader';
import PremiumDue from '../PolicyCard/PremiumDue';
import { PolicyFooter } from '../PolicyFooter/PolicyFooter';

interface UserProps {
	contentType?: string;
	items?: any;
}
const ArchiveLifeCard: React.FC<UserProps> = ({ contentType, items }) => (
	<Box className="policy-card">
		<Grid container>
			<LifeHeader items={items} contentType={contentType} />
			<Separator color={theme.palette.primary.main} />
			<PremiumDue items={items} contentType={contentType} PolicyType="life" />

			<Separator color={theme.palette.primary.main} />
			<PolicyFooter footerType={contentType} items={items} policyType="life" />
		</Grid>
	</Box>
);

export default ArchiveLifeCard;
