import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import '../../Dashboard.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RupeeIcon } from '../../../../../common/icons/dashboard/calendar_orange_ruppee_icon.svg';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import Spinner from '../../../../../common/ui/Spinner';
import { convertAmount } from '../../../../../common/utils';

interface UserProps {
	sessionDetails?: any;
	dashboardLoading?: any;
}
const SessionCard = ({ sessionDetails, dashboardLoading }: UserProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<>
			{dashboardLoading ? (
				<Grid item display="flex" justifyContent="center" className="py-10">
					<Spinner />
				</Grid>
			) : (
				<Grid
					container
					spacing={1}
					className="py-25 session-tracker-card"
					pl={2}
					ml={0}
					my={1}
					mb={0}
					style={{
						borderTop: '1px solid black',
						background: '#f2f2f2',
						borderRadius: '0px 0px 8px 8px',
					}}
				>
					{sessionDetails?.SessionTrackerDataList?.map(
						(widget: any, index: any) => (
							<>
								{widget?.widgetType === 'W' && (
									<>
										<Grid container my={2}>
											<Grid mt={-0.5}>
												<div className="session-round-icon">
													<Typography>{widget?.widgetNo}</Typography>
												</div>
											</Grid>
											<Grid item ml={2} flex={1}>
												<Grid>
													<Grid item>
														<Typography
															className="f-14"
															color={theme.palette.common.black}
														>
															{t('PARTIAL_WITHDRAWAL_OF')}
														</Typography>
													</Grid>
													<Grid container>
														<Grid item>
															<LabelWithIcon
																className="pr-5"
																svgClassName=""
																justifyContent="flex-start"
																Icon={RupeeIcon}
																label={convertAmount(
																	widget?.Withdrawal?.partialWithdrawalAmount
																)}
																labelProps={{
																	color: theme.palette.common.black,
																	fontSize: 14,
																	fontWeight: 600,
																}}
															/>
														</Grid>
														<Typography className="f-14">
															{t('INITIATED')}.
														</Typography>
													</Grid>
													<Grid
														item
														bgcolor="#f2f2f2"
														className="mt-5 pt-5  top-border"
													>
														<Grid container>
															<Typography className="f-14">
																{t('ESTIMATED_PROCESSING_TIME')}:
															</Typography>
															<Typography
																className="f-14"
																color={theme.palette.warning.dark}
																px={0.5}
															>
																{widget?.estimatedProcessingTimeHrs === ''
																	? 0
																	: widget?.estimatedProcessingTimeHrs}
															</Typography>
															<Typography className="f-14">
																{t('HOURS')}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</>
								)}
								{widget.widgetType === 'S' && (
									<Grid container my={2}>
										<Grid mt={-0.5}>
											<div className="session-round-icon">
												<Typography>{widget?.widgetNo}</Typography>
											</div>
										</Grid>
										<Grid item ml={2} flex={1}>
											<Grid>
												<Grid container spacing={0.5}>
													<Grid item>
														<Typography
															className="f-14"
															color={theme.palette.common.black}
														>
															{t('SURRENDER')}
														</Typography>
													</Grid>
													<Grid item>
														<LabelWithIcon
															className="px-2"
															svgClassName=""
															justifyContent="flex-start"
															Icon={RupeeIcon}
															label={convertAmount(
																widget?.Surrender?.surrenderValue
															)}
															labelProps={{
																color: theme.palette.primary.main,
																fontSize: 16,
																fontWeight: 600,
															}}
														/>
													</Grid>
												</Grid>
												{/* <Typography className="f-14">
												to be recieved on DDMMYYYY
											</Typography> */}
												{/* <Grid
												item
												bgcolor="#f2f2f2"
												className="mt-5 pt-5  top-border"
											>
												<Grid container>
													<Typography
														className="f-14"
														pr={0.5}
														color={theme.palette.warning.dark}
													>
														{' '}
														5{' '}
													</Typography>{' '}
													<Typography className="f-14"> days left</Typography>
												</Grid>
											</Grid> */}
											</Grid>
										</Grid>
									</Grid>
								)}
								{/* payout design hide below */}
								{/* {widget.widgetType === 'P' && (  
								<Grid container my={2}>
									<Grid mt={-0.5}>
										<div className="session-round-icon">
											<Typography>{index + 1}</Typography>
										</div>
									</Grid>
									<Grid item ml={2} flex={1}>
										<Grid>
											<Grid container spacing={0.5}>
												<Grid item>
													<Typography
														className="f-14"
														color={theme.palette.common.black}
													>
														Payout of
													</Typography>
												</Grid>
												<Grid item>
													<LabelWithIcon
														className="px-2"
														svgClassName=""
														justifyContent="flex-start"
														Icon={RupeeIcon}
														label={convertAmount(
															widget?.Surrender?.surrenderValue
														)}
														labelProps={{
															color: theme.palette.primary.main,
															fontSize: 16,
															fontWeight: 600,
														}}
													/>
												</Grid>
											</Grid>
											<Typography className="f-14">
												to be recieved on DDMMYYYY
											</Typography>
											<Grid
												item
												bgcolor="#f2f2f2"
												className="mt-5 pt-5  top-border"
											>
												<Grid container>
													<Typography
														className="f-14"
														pr={0.5}
														color={theme.palette.warning.dark}
													>
														{' '}
														5{' '}
													</Typography>{' '}
													<Typography className="f-14"> days left</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)} */}
								{widget.widgetType === 'L' && (
									<Grid container my={2}>
										<Grid mt={-0.5}>
											<div className="session-round-icon">
												<Typography>{widget?.widgetNo}</Typography>
											</div>
										</Grid>
										<Grid item ml={2} flex={1}>
											<Grid>
												<Grid item>
													<Typography
														className="f-14"
														color={theme.palette.common.black}
													>
														{t('LOAN_AGAINST_SURRENDER_VALUE_INITIATED')}
													</Typography>
												</Grid>
												<Grid container>
													<Grid item>
														<LabelWithIcon
															className="pr-3"
															svgClassName=""
															justifyContent="flex-start"
															Icon={RupeeIcon}
															label={convertAmount(widget?.Loan?.loanAmount)}
															labelProps={{
																color: theme.palette.common.black,
																fontSize: 14,
																fontWeight: 600,
															}}
														/>
													</Grid>
													<Typography className="f-14">
														@ {widget?.Loan?.loanPercentage} {t('INTEREST')}
													</Typography>
												</Grid>
												<Grid
													item
													bgcolor="#f2f2f2"
													className="mt-5 pt-5  top-border"
												>
													<Typography className="f-14">
														{t('CURRENT_STATUS')}
													</Typography>
													<Typography
														className="f-14 session-status"
														color={theme.palette.primary.dark}
													>
														{widget?.transactionStatus}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								)}
							</>
						)
					)}
				</Grid>
			)}
		</>
	);
};

export default SessionCard;
