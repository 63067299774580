/* eslint-disable no-nested-ternary */
import React from 'react';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import defaultAvatar from '../icons/default_avatar.svg';

const DashboardProfileAvatar = ({
	profileImg,
	profileReadiness,
	uploadProfile = () => false,
}: {
	profileReadiness: any;
	profileImg: any;
	// eslint-disable-next-line react/require-default-props
	uploadProfile?: any;
}) => {
	const profileReadinessValue = parseInt(profileReadiness, 10);

	return (
		<div className="user-avatar-container m-5" onClick={uploadProfile}>
			<Avatar
				alt="user profile avatar"
				src={profileImg || defaultAvatar}
				sx={{ width: 52, height: 52 }}
			/>
			<CircularProgress
				variant="determinate"
				value={profileReadinessValue}
				size={58}
				className="avatar-progress-bar"
				color={
					profileReadinessValue <= 40
						? 'error'
						: profileReadinessValue <= 70
						? 'secondary'
						: 'success'
				}
			/>
		</div>
	);
};

export default DashboardProfileAvatar;
