import { Box, Grid, Typography, useTheme } from "@mui/material";
import LabelWithIcon from "../../../common/ui/LabelWithIcon";
import OtpDesign from "../../Auth/SignUp/OtpDesign";
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import { useTranslation } from "react-i18next";
import SvgConverter from "../../../common/SvgConverter";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../common/constants/Routes";



const FindPolicyOTPScreen = ({ PolicyNonIntegrated }: any) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (<>
        <Grid>
            <OtpDesign HideSupport={true} onVerifyOtpClick={() => { PolicyNonIntegrated(true) }} />
        </Grid>



        <Grid container justifyContent="center" >
            <Box width={295}>
                <Grid container alignItems='center' justifyContent="center" columnSpacing={2} >

                    <Grid item xs={6} >
                        <Link to={APP_ROUTES.ADD_POLICY_SUBMITTION_PREVIEW}>
                            <Typography
                                className="f-14 fw-400 "
                                color={theme.palette.grey[400]}
                                textAlign={"right"}
                            >
                                {t('SKIP_NON_INTEGRATED_INSURERS')}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <SvgConverter Icon={ArrowLeftIcon} />
                    </Grid>





                </Grid>
            </Box>
        </Grid>

    </>)
}

export default FindPolicyOTPScreen;