import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import CustomButton from '../../../common/ui/CustomButton';
import {
	fetchOtpRequest,
	fetchOtpVerify,
	selectIsOtpRequestLoading,
	selectIsOtpValidationLoading,
	selectLoginRequestInfo,
	setUser,
} from '../auth.slice';
import './OtpScreen.css';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import { OTP_PURPOSE, SIGNUP_EIA_STATUS } from '../../../common/Enums';
import { AuthUser, maskEmail, maskMobileNumber } from '../../../common/utils';
import config from '../../../common/config';
import { AUTH_ROUTES } from '../../../common/constants/Routes';
import NeedSupport from './NeedSupport';
import { useTranslation } from 'react-i18next';
import cryptoEncryptionDecryption from '../../../common/crypto';
import {
	dashboardListRequest,
	fetchMissingInfo,
} from '../../App/DashboardScreen/Dashboard.slice';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';
import analytics from '../../../Layout/components/analytics';

const OtpScreen = () => {
	const [enterOtp, setEnterOtp] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [seconds, setSeconds] = useState(config.otpDuration);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const isRequestLoading = useSelector(selectIsOtpValidationLoading);
	const loginInfo = useSelector(selectLoginRequestInfo);
	const isOtpRequestLoading = useSelector(selectIsOtpRequestLoading);
	const [otp, setOtp] = useState('');
	const location = useLocation();
	const Flag = location?.state?.requestPayload?.Customer?.flag;
	const Value = location?.state?.requestPayload?.Customer?.value;
	const isNumberEmpty = !loginInfo?.Customer?.mobileNo?.length;
	const isEmailEmpty = !loginInfo?.Customer?.email?.length;

	const handleVerify = () => {
		const handleSuccess = () => {
			if (
				String(loginInfo?.Customer?.firstTimeLoginFlag).toUpperCase() === 'Y'
			) {
				navigate(AUTH_ROUTES.SIGNUP_EKYC_DETAILS_ONE, {
					state: {
						flowType: SIGNUP_EIA_STATUS.EIA_CAMS,
						userData: loginInfo?.Customer,
					},
				});
			} else {
				sessionStorage.setItem(
					'userInfo',
					cryptoEncryptionDecryption.Encrypt(loginInfo)
				);
				sessionStorage.setItem(
					'sessionId',
					cryptoEncryptionDecryption.Encrypt(loginInfo?.Customer?.sessionId)
				);
				dispatch(setUser(loginInfo.Customer as AuthUser));
			}
		};

		setEnterOtp(true);
		setIsValid(otp.length === 6);
		if (isValid) {
			dispatch(
				fetchOtpVerify({
					Customer: {
						eiaNo: loginInfo?.Customer?.eiaNo,
						value:
							Flag === 'EMAIL'
								? loginInfo?.Customer?.email
								: loginInfo?.Customer?.mobileNo
									? loginInfo?.Customer?.mobileNo
									: loginInfo?.Customer?.email,
						otp,
						otpPurpose: OTP_PURPOSE.SIGNIN,
						flag:
							Flag === 'EMAIL'
								? 'EMAIL'
								: loginInfo?.Customer?.mobileNo
									? 'MOBILE'
									: 'EMAIL',
					},
					handleSuccess,
				} as any)
			);
		}
	};

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'OTP Screen',
		});
	}, []);

	
	

	useEffect(() => {
		if (loginInfo?.Customer?.eiaNo) {

			dispatch(dashboardListRequest({
				Customer: {
					eiaNo: loginInfo?.Customer?.eiaNo,
				},
			}));
			dispatch(fetchMissingInfo({
				Customer: {
					eiaNo: loginInfo?.Customer?.eiaNo,
				},
			}));

		}
	}, [loginInfo])

	const handleOtpChange = (otpValue: string) => {
		setOtp(otpValue);
		setIsValid(otpValue.length === 6);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				clearInterval(interval);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds]);

	useEffect(() => {
		if (!loginInfo?.Customer?.eiaNo) {
			navigate('/', { replace: true });
		}
	}, [loginInfo]);

	const handleResendOtp = () => {
		setOtp('');
		const handleSuccess = () => {
			setSeconds(config.otpDuration);
		};
		dispatch(
			fetchOtpRequest({
				Customer: {
					eiaNo: loginInfo?.Customer?.eiaNo,
					value: isNumberEmpty ? loginInfo?.Customer?.email : loginInfo?.Customer?.mobileNo,
					// Flag === 'EMAIL'
					// 	? loginInfo?.Customer?.email
					// 	: loginInfo?.Customer?.mobileNo
					// 		? loginInfo?.Customer?.mobileNo
					// 		: loginInfo?.Customer?.email,
					otpPurpose: 'SIGNIN',
					flag: isNumberEmpty ? 'EMAIL' : isEmailEmpty ? 'MOBILE' : 'MOBILEEMAIL'
					//flag: 'MOBILEEMAIL'
					// Flag === 'EMAIL'
					// 	? 'EMAIL'
					// 	: loginInfo?.Customer?.mobileNo
					// 		? 'MOBILE'
					// 		: 'EMAIL',
				},
				handleSuccess,
			} as any)
		);
		
		const analytics = getAnalytics();
			logEvent(analytics, 'Resendotp_button_click', {
				button_name: 'Resendotp_button',
				Status: 'Success',
				Devices_type: 'Web',
			});
	};
	const { t } = useTranslation();
	return (
		<Grid
			container
			flexDirection="column"
			className="h-100"
			flexWrap="nowrap"
			alignItems="center"
		>
			<Grid
				item
				lg={3}
				md={6}
				xs={10}
				alignItems="center"
				justifyContent="center"
				className="mt-25"
			>
				<Grid item className="mt-30 mb-5">
					<IconButton onClick={() => navigate(-1)} className="p-0">
						<SvgConverter Icon={ArrowLeftIcon} className="go-back-icon" />
					</IconButton>
				</Grid>
				<Grid container item flex={1} alignItems="center" className="mt-20">
					<Grid item className="w-100">
						<Grid
							container
							spacing={0.5}
							flexDirection="column"
							className="mt-15"
						>
							<Grid item justifyContent={'center'} container>
								<Typography textAlign="center" className="f-16 px-20">
									{t('PLEASE_ENTER_6_DIGIT_OTP_SENT')}
								</Typography>

								{/* {Flag === 'EMAIL' && (
									<Typography
										textAlign="center"
										color={theme.palette.secondary.main}
										lineHeight="unset"
										fontSize={28}
										fontWeight={600}
										className="mt-10  word-break"
									>
										{maskEmail(Value)}
									</Typography>
								)}
								{Flag === 'MOBILE' && (
									<Typography
										textAlign="center"
										color={theme.palette.secondary.main}
										lineHeight="unset"
										fontSize={28}
										fontWeight={600}
										className="mt-10  word-break"
									>
										{maskMobileNumber(Value)}
									</Typography>
								)}
								{Flag === 'EIA' && (
									<Typography
										textAlign="center"
										color={theme.palette.secondary.main}
										lineHeight="unset"
										fontSize={28}
										fontWeight={600}
										className="mt-10  word-break"
									>
										{loginInfo?.Customer?.mobileNo
											? maskMobileNumber(loginInfo?.Customer?.mobileNo)
											: maskEmail(loginInfo?.Customer?.email)}
									</Typography>
								)}
								{Flag === 'PAN' && (
									<Typography
										textAlign="center"
										color={theme.palette.secondary.main}
										lineHeight="unset"
										fontSize={28}
										fontWeight={600}
										className="mt-10  word-break"
									>
										{loginInfo?.Customer?.mobileNo
											? maskMobileNumber(loginInfo?.Customer?.mobileNo)
											: maskEmail(loginInfo?.Customer?.email)}
									</Typography>
								)} */}



							</Grid>

							<Grid item justifyContent={'center'} container>
								<Typography textAlign="center" className="f-16 px-20  ">
									{t('TO_THE')} {t('REGISTERED')}{' '}
									{isNumberEmpty
										? 'Email ID'
										: isEmailEmpty
											? `${t('MOBILE_NUMBER')}`
											: `${t('MOBILE_NUMBER_EMAIL')}`}
								</Typography>
							</Grid>
							<Grid item justifyContent={'center'} container>	<Typography
								textAlign="center"
								color={theme.palette.secondary.main}
								lineHeight="unset"
								fontSize={20}
								fontWeight={600}
								className="mt-10  word-break"
							>
								{loginInfo?.Customer?.mobileNo
									? maskMobileNumber(loginInfo?.Customer?.mobileNo) : ''}
							</Typography></Grid>
							<Grid item justifyContent={'center'} container>
								<Typography
									textAlign="center"
									color={theme.palette.secondary.main}
									lineHeight="unset"
									fontSize={20}
									fontWeight={600}
									className="mt-10  word-break"
								>
									{loginInfo?.Customer?.email
										? maskEmail(loginInfo?.Customer?.email) : ''}
								</Typography>
							</Grid>

							<Grid
								item
								className="my-21"
								display={'flex'}
								justifyContent={'center'}
							>
								<OtpInput
									numInputs={6}
									placeholder="XXXXXX"
									value={otp}
									onChange={handleOtpChange}
									containerStyle="otp-input-container"
									isInputSecure
									isInputNum
								/>
							</Grid>
							{/* {isValid === false && enterOtp === true && (
								<Typography color="#d9001b" className="">
									Enter valid OTP
								</Typography>
							)} */}

							{seconds > 0 ? (
								<Grid
									item
									container
									justifyContent="center"
									spacing={0.6}
									flexWrap="nowrap"
								>
									<Grid item>
										<Typography
											className="f-14 fw-400"
											color={theme.palette.primary.main}
										>
											{t('RESEND_OTP')}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											className="f-13 fw-400"
											color={theme.palette.common.black}
										>
											{t('IN')}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											className="f-13 fw-400"
											color={theme.palette.secondary.main}
										>
											{seconds} {t('SECONDS')}
										</Typography>
									</Grid>
								</Grid>
							) : (
								<Grid
									item
									container
									justifyContent="center"
									spacing={0.6}
									flexWrap="nowrap"
								>
									<Grid item>
										<CustomButton
											text={t('RESEND_OTP')}
											variant="text"
											color="primary"
											showIcon={false}
											// startIcon={Cancel}
											onClick={handleResendOtp}
											loading={isOtpRequestLoading}
										/>
									</Grid>
								</Grid>
							)}
							<Grid item container justifyContent="center" className="mt-50">
								<CustomButton
									onClick={handleVerify}
									text={t('PROCEED')}
									variant="text"
									color="primary"
									className="fw-500 f-20 mt-30"
									loading={isRequestLoading}
									disabled={otp?.length !== 6}

								// disabled={isOtpRequestLoading}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid mt={5}>
				<NeedSupport type="black" />
			</Grid>
		</Grid>
	);
};

export default OtpScreen;
