/* eslint-disable react/require-default-props */
import { IconButton, Popover } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SvgConverter from '../SvgConverter';
import { ReactComponent as CalendarIcon } from '../icons/login_calendar_input-icon.svg';
import { ReactComponent as CalendarIconPrimary } from '../icons/normal_u119.svg';

const Icons = {
	primary: CalendarIconPrimary,
	secondary: CalendarIcon,
};
const DatePickerModal = ({
	iconType = 'secondary',
	disableFuture = false,
	disablePast = false,
	disableToday = false,
	onChange = () => {},
	formate = 'DD/MM/YYYY',
	value,
	disabledDates = [],
	setModalOpen = () => false,
	modalOpen,
	disableBelow18,
}: {
	iconType?: 'primary' | 'secondary' | undefined;
	disableFuture?: boolean | undefined;
	disablePast?: boolean | undefined;
	disableToday?: boolean;
	onChange?: any;
	formate?: any;
	value?: any;
	disabledDates?: string[];
	setModalOpen?: any;
	modalOpen?: any;

	disableBelow18?: boolean;
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	return (
		<>
			<IconButton
				sx={{ padding: 0 }}
				onClick={(event) => setAnchorEl(event.currentTarget)}
			>
				<SvgConverter Icon={Icons[iconType]} />
			</IconButton>
			<Popover
				open={Boolean(modalOpen || anchorEl)}
				anchorEl={modalOpen || anchorEl}
				onClose={() => {
					setAnchorEl(null);
					setModalOpen(null);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<StaticDatePicker
						value={value ? dayjs(value, formate) : undefined}
						defaultValue={dayjs(new Date())}
						disableFuture={disableFuture}
						disablePast={disablePast}
						// onChange={onChange}
						onAccept={(date) => {
							setAnchorEl(null);
							setModalOpen(null);
							onChange(date);
						}}
						onClose={() => {
							setAnchorEl(null);
							setModalOpen(null);
						}}
						views={['year', 'month', 'day']}
						shouldDisableDate={(date) => {
							const isToday: any = dayjs(date).isSame(dayjs(), 'day');
							const currentDate = dayjs();
							const date18YearsAgo = currentDate.subtract(18, 'year');
							return (
								(disableToday && isToday) ||
								disabledDates.some((disabledDate) =>
									dayjs(date).isSame(dayjs(disabledDate), 'day')
								) ||
								(disableBelow18 && dayjs(date).isAfter(date18YearsAgo, 'day'))
							);
						}}
					/>
				</LocalizationProvider>
			</Popover>
		</>
	);
};

export default DatePickerModal;
