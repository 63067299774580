export const POA: any = {
	'01': 'Regd. Lease and License agreement',
	'02': 'Aadhaar',
	'03': 'Ration Card',
	'04': 'Driving license',
	'05': 'Passport',
	'06': 'Voter ID',
	'07': 'Bank Passbook',
	'08': 'Electricity bills',
	'09': 'Residence Telephone bills',
	'10': 'Self-declaration of High Court and Supreme Court judges',
	'11': 'Central/State Government department ID card',
	'12': 'Statutory/Regulatory Authorities ID card with Address',
	'13': 'Public Sector Undertaking ID card with Address',
	'14': 'Scheduled Commercial Banks ID card with Address',
	'15': 'Public Financial Institutions ID card with Address',
	'16': 'Affiliated college ID card with Address',
	'17': 'ICAI, ICWAI, Bar Council etc. Member card with Address',
	'18': 'Identity card/document with address, issued by Central/State Government and its Departments',
};

export const POB: any = {
	'01': 'PAN',
	'02': 'Domicile Certificate',
	'03': 'Ration Card',
	'04': 'Driving License',
	'05': 'Passpor',
	'06': 'Voter ID',
	'07': 'Municipal Birth Certificate',
	'08': 'Notarized Birth Certificate',
	'09': 'Baptism Certificate',
	'10': 'Marriage Certificate issued by Church',
	'11': 'Identity card/document with address, issued by Central/State Government',
	'12': 'Gram Panchayat certificate',
	'13': 'Public Sector Undertaking ID card with Address',
	'14': 'Defense ID including Ex-serviceman Card',
	'15': 'Affiliated college ID card with Address',
	'16': 'Central Govt. Health scheme Certificate for their dependent',
	'17': 'Govt. service registers extract/Certificates issued',
	'18': "Employer's PF statement",
	'19': 'ESIS Card (Employees State Insurance Scheme)',
	'20': "Employer's Certificate from Govt, Semi Govt., MNC,",
	'21': 'School or College cerificates with DOB of the eIA',
	'22': 'Policy Document of other private insurers',
	'23': 'LIC Policy',
	'24': 'Islander Cards for Residents of Andaman & Nicobar',
	'25': 'Pilgrim pass issued for Haj Pilgrimage',
	'26': 'Aadhaar',
};
