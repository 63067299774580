import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import config from "../../../common/config";
import CustomButton from "../../../common/ui/CustomButton";

const NonIntegratedOTPCard = ({ item }: any) => {

    const theme = useTheme();
    const { t } = useTranslation();

    const [isValid, setIsValid] = useState(false);
    const [otp, setOtp] = useState('');
    const [enterOtp, setEnterOtp] = useState(false);
    const [seconds, setSeconds] = useState(config.otpDuration);

    const handleOtpChange = (otpValue: string) => {
        setEnterOtp(true);
        setOtp(otpValue);
        setIsValid(otpValue.length === 6);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    return (
        <Grid className="mt-20 mb-40">
            <Grid container xs={12} alignItems={'center'} className="mb-10">
                <Grid item xs={2} className="mt-10 ">
                    {item?.Logo && (
                        <img
                            src={item?.Logo}
                            className="policy-image"
                            alt="logo"
                        />
                    )}
                </Grid>
                <Grid item xs={9}>
                    <Typography
                        className="f-14 fw-600"
                        color={theme.palette.common.black}
                    >
                        {item?.Description}
                    </Typography>
                </Grid>

            </Grid>
            <Typography className="f-16 " color="primary">
                {t('PLEASE_ENTER_OTP_RECEIVED_FROM')}
            </Typography>
            <Typography className="f-16 fw-600" color="primary">
                {item?.Description}
            </Typography>
            <Grid
                item
                className="my-21"
            // display={'flex'}
            // justifyContent={'center'}
            >
                <OtpInput
                    numInputs={6}
                    placeholder="XXXXXX"
                    value={otp}
                    onChange={handleOtpChange}
                    containerStyle="otp-input-container"
                    isInputSecure
                    isInputNum
                />
            </Grid>
            {isValid === false && enterOtp === true && (
                <Typography color="#d9001b" className="">
                    {t('ENTER_THE_VALID_OTP')}
                </Typography>
            )}

            {seconds > 0 ? (
                <Grid
                    item
                    container
                    justifyContent="center"
                    spacing={0.6}
                    flexWrap="nowrap"
                >
                    <Grid item>
                        <Typography
                            className="f-14 fw-400"
                            color={theme.palette.primary.main}
                        >
                            {t('RESEND_OTP')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            className="f-13 fw-400"
                            color={theme.palette.common.black}
                        >
                            {t('IN')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            className="f-13 fw-400"
                            color={theme.palette.secondary.main}
                        >
                            {seconds} {t('SECONDS')}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    item
                    container
                    justifyContent="center"
                    spacing={0.6}
                    flexWrap="nowrap"
                >
                    <Grid item>
                        <CustomButton
                            text={t('RESEND_OTP')}
                            variant="text"
                            color="primary"
                            showIcon={false}
                        // onClick={handleResendOtp}
                        // loading={isOtpRequestLoading}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}


export default NonIntegratedOTPCard;