import { Box, Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { ReactComponent as LineIcon } from '../icons/normal_line.svg';
import CameraIcon from '../icons/camera.png';
import UploadIcon from '../icons/upload-img.png';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAlertInfo } from "../../features/App/Common/Common.slice";
import CustomButton from "./CustomButton";
import SvgConverter from "../SvgConverter";
import ImagePickerModal from "./Modal/ImagePickerModal";

interface uploadCardProps {
    isPDFUpload?: boolean
    tapText?: string
    setUpload: any
    uploadedIMG: any
}


const UploadCard = ({ isPDFUpload, tapText, setUpload, uploadedIMG }: uploadCardProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)

    const processImage = (data: any, file: any) => {
        try {
            const maxSize = 5 * 1024 * 1024;

            if (file?.target?.files?.[0]?.size > maxSize) {
                dispatch(
                    setAlertInfo({
                        open: true,
                        type: 'FAILED',
                        description: t('File size exceeds 5MB'),
                    })
                );
            } else {
                setUpload({ base64: data, fileName: file?.target?.files[0]?.name || "capture.jpg"});

            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Card
            sx={{
                width: 295,
                height: 160,
                py: 0,
                backgroundColor: theme.palette.grey[100],
                outline: '1px solid #797979',
            }}
            variant="outlined"
        >
            <CardContent>
                <Box className="upload-rectangle">
                    {uploadedIMG.base64 != null ? (
                        <Grid container justifyContent={'center'}>
                            {isPDFUpload ? (<>

                                <embed
                                    src={`data:application/pdf;base64,${uploadedIMG.base64}`}
                                    width="150"
                                    height="100"
                                />
                                <CustomButton
                                    text="TAP TO RE UPLOAD"
                                    variant="text"
                                    color="primary"
                                    showIcon={false}
                                    className="mt-5"
                                    fontSize={14}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                /></>) : (<>

                                    <img
                                        src={`data:image/png/jpg/jpeg;base64,${uploadedIMG.base64}`}
                                        width="150"
                                        height="100"
                                    />
                                    <CustomButton
                                        text="TAP TO RE UPLOAD"
                                        variant="text"
                                        color="primary"
                                        showIcon={false}
                                        className="mt-5"
                                        fontSize={14}
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    /></>)}

                        </Grid>

                    ) :

                        (isPDFUpload ? (
                            <Grid container xs={12} sx={{ justifyContent: "center", alignItems: 'center', mt: 2 }}>


                                <Grid container justifyContent={"center"} alignItems='center'>

                                    <Grid container justifyContent={"center"} >
                                        <Typography
                                            className="pointer"
                                            onClick={() => {
                                                // handleImageUploadMore();
                                                // setUploadedImage('appointee');
                                                setOpen(true);
                                            }}
                                        >
                                            <img src={UploadIcon} width={35} />
                                        </Typography>
                                    </Grid>
                                    <Grid container xs={8} justifyContent={"center"}>
                                        <CustomButton
                                            text={t(tapText ? tapText : 'TAP_TO_UPLOAD_PDF')}
                                            variant="text"
                                            showIcon={false}
                                            color="inherit"
                                            className="f-14 fw-400"
                                            onClick={() => { setOpen(true); }}
                                        /></Grid>

                                </Grid>
                            </Grid>

                        ) : (

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <Grid
                                    container
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography
                                            className="pointer"
                                            onClick={() => {
                                                // handleImageUploadMore();
                                                // setUploadedImage('appointee');
                                                setOpen(true);
                                            }}
                                        >
                                            <img src={CameraIcon} width={60} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomButton
                                            text={t('TAP TO OPEN CAMERA')}
                                            variant="text"
                                            showIcon={false}
                                            color="inherit"
                                            className="f-14 fw-400"
                                            onClick={() => setOpen(true)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    xs={1}
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    <SvgConverter Icon={LineIcon} />
                                </Grid>

                                {/* {uploadedImage && ( */}
                                <Grid
                                    container
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    <Typography
                                        className="pointer"
                                        onClick={() => {
                                            // handleImageUploadMore();
                                            // setUploadedImage('appointee');
                                            setOpen(true);
                                        }}
                                    >
                                        <img src={UploadIcon} width={35} />
                                    </Typography>
                                    <Grid item xs={12}>
                                        <CustomButton
                                            text={t('TAP TO UPLOAD IMAGE')}
                                            variant="text"
                                            showIcon={false}
                                            color="inherit"
                                            className="f-14 fw-400"
                                            onClick={() => {
                                                setOpen(true);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {/* )} */}
                            </Grid>
                        ))

                    }

                    <ImagePickerModal
                        open={open}
                        setOpen={setOpen}
                        handleImage={(data: any, file: any) => processImage(data, file)}
                        isPdf={isPDFUpload}


                    />
                </Box>
            </CardContent>
        </Card >
    )
}


export default UploadCard;