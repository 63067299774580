import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BankIcon } from '../../../common/icons/dashboard/bank-icon.svg';
import theme from '../../../common/theme';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as Cancel } from '../../../common/icons/cancel-icon.svg';
import BankDetails from './BankDetails';
import {
	clearBankEntries,
	combinePoliciesAccount,
	fetchPoliciesForBanks,
	getBankDetailsByIfscRequest,
	getBankInfoByAcNo,
	handleInstantBankFlow,
	selectGetBankDetails,
	selectGetBankDetailsLoading,
	selectGroupedPoliciesByBanks,
	selectIsPolicyBanksLoading,
	selectTotalPolices,
	selectUnVerifiedBanks,
	selectUnassignedBanks,
	selectUpdateBankChangeDone,
	selectUpdateBankChangeError,
	selectUploadCanceledChequeDetails,
	selectUploadCanceledChequeLoading,
	selectUploadCanceledChequePayloadDetails,
	skipBank,
	updateBankChangesRequest,
	uploadCanceledChequeRequest,
} from '../BankModule/Bank.slice';
import BankEntryModal from '../BankModule/BankModals/BankEntryModal';
import UploadImageBankModal from '../BankModule/BankModals/UploadImageBankModal';
import { CHEQUE_VERIFICATION_TYPES } from '../../../common/Enums';
import { setAlertInfo } from '../Common/Common.slice';
import { APP_ROUTES } from '../../../common/constants/Routes';
import Spinner from '../../../common/ui/Spinner';
import UploadModal from '../BankModule/BankModals/UploadModal';
import { selectedCombination } from './Loan.slice';
import ApiModal from '../../../common/ui/Modal/ApiModal';
import LoanAgainstHeader from '../PolicyScreen/LoanAgainstPolicy/LoanAgainstHeader';

interface UserProps {
	productLogo?: any;
}
const SelectBankScreen = ({ productLogo }: UserProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [instantAddAccountData, setInstantAddAccountData] = useState(false);
	const [addAccount, setAddAccount] = useState(false);
	const [verifyNow, setVerifyNow] = useState(false);
	const [updateBank, setUpdateBank] = useState(false);
	const [newAccountData, setNewAccountData] = useState<any>(null);
	const [imagePickerModalVisible, setImagePickerModalVisible] = useState(false);
	const uploadChequePayload = useSelector(
		selectUploadCanceledChequePayloadDetails
	);
	const policiesByAccountNumber: any = useSelector(
		selectGroupedPoliciesByBanks
	);
	const unAssignedBanks: any = useSelector(selectUnassignedBanks);
	const totalPolicies: any = useSelector(selectTotalPolices);
	const unVerifiedBanks: any = useSelector(selectUnVerifiedBanks);
	const isGetBankDetailsLoading = useSelector(selectGetBankDetailsLoading);
	const bankDetailsByIfsc = useSelector(selectGetBankDetails);
	const bankOCRDetails: any = useSelector(selectUploadCanceledChequeDetails);
	const isPoliciesForBanksLoading = useSelector(selectIsPolicyBanksLoading);
	const isUploadCheckLoadingLoading = useSelector(
		selectUploadCanceledChequeLoading
	);

	const updateBankChangesDone: any = useSelector(selectUpdateBankChangeDone);
	const updateBankChangeError: any = useSelector(selectUpdateBankChangeError);

	const widgetNo = location.state?.widgetNo;
	const singlePolicyLoan = location.state?.Lender;
	const Combinations: any = useSelector(selectedCombination);
	useEffect(() => {
		if (!Combinations) {
			if (singlePolicyLoan) {
				navigate(APP_ROUTES.POLICY);
			} else if (widgetNo) {
				navigate(APP_ROUTES.NEED_MONEY);
			}
		}
	}, [Combinations]);
	const bank = Object.keys(policiesByAccountNumber).map((acNo: any) =>
		getBankInfoByAcNo(acNo)(
			combinePoliciesAccount(totalPolicies, unAssignedBanks)
		)
	);

	let bankLists: any = bank
		.concat(unVerifiedBanks, unAssignedBanks)
		.sort((a: any, b: any) => {
			const order: any = { Y: 1, P: 2, N: 3 };
			return order[a.bankVerified] - order[b.bankVerified];
		});

	const addBankAccount = (primaryValue: string, isChange: any) => {
		setInstantAddAccountData(false);
		if (primaryValue) {
			setInstantAddAccountData({
				[isChange ? 'selectedPolicyNo' : 'selectedBankAcNo']: primaryValue,
				isChange: Boolean(isChange),
			} as any);
		}

		dispatch(clearBankEntries);
		setAddAccount(true);
		setUpdateBank(true);
	};

	const handleSearch = (data: any) => {
		const payload: any = { ifsc: data?.ifscCode, t };
		payload.handleSuccess = () => {
			setNewAccountData(data);
			setAddAccount(false);
			setVerifyNow(true);
		};
		dispatch(getBankDetailsByIfscRequest(payload));
	};
	const handleUploadModal = (chequeImage: any) => {
		const payload: any = {
			payeeAccNo: newAccountData?.accountNumber,
			payeeAccName: newAccountData?.accountHolderName,
			payeeIfscCode: bankDetailsByIfsc?.IFSC,
			chequeImage,
		};

		payload.handleSuccess = (responseData: any) => {
			if (
				responseData?.uploadStatus === CHEQUE_VERIFICATION_TYPES.CHEQUE_VERIFIED
			) {
				if (instantAddAccountData) {
					dispatch(handleInstantBankFlow(instantAddAccountData));
					setVerifyNow(false);
				}
				setUpdateBank(true);
			} else if (
				responseData?.uploadStatus ===
				CHEQUE_VERIFICATION_TYPES.CHEQUE_MANUAL_VERIFICATION
			) {
				setVerifyNow(false);
				dispatch(
					setAlertInfo({
						open: true,
						type: 'FAILED',
						description: responseData?.displayMessage
							? responseData?.displayMessage
							: 'Problem with network,  Please try Again later.',
					})
				);
				const skipPayload: any = {
					isVerified: false,
					bankDetailsByIfsc,
					uploadChequePayload,
					bankOCRDetails,
					newAccountData,
					skipApiChanges: true,
				};
				dispatch(skipBank(skipPayload));
			}
			setImagePickerModalVisible(false);
		};
		dispatch(uploadCanceledChequeRequest(payload));
	};
	const handleSkip = (isVerified: boolean) => {
		const payload: any = {
			isVerified,
			bankDetailsByIfsc,
			uploadChequePayload,
			bankOCRDetails,
			newAccountData,
		};
		payload.handleDone = () => {
			setVerifyNow(false);
		};
		dispatch(skipBank(payload));

		if (updateBank) {
			dispatch(updateBankChangesRequest({ t } as any));
		}
	};
	const handleVerifyNow = (bankInfo: any) => {
		const payload: any = { ifsc: bankInfo?.ifscCode };
		payload.handleSuccess = () => {
			setNewAccountData({
				accountHolderName: bankInfo?.accountHolderName,
				accountNumber: bankInfo?.accountNo,
				ifscCode: bankInfo?.ifscCode,
				retypeAccountNo: bankInfo?.accountNo,
			} as any);
			setAddAccount(false);
		};
		dispatch(getBankDetailsByIfscRequest(payload));
		setVerifyNow(true);
		setUpdateBank(false);
	};

	useEffect(() => {
		dispatch(fetchPoliciesForBanks());
	}, [updateBankChangesDone, updateBankChangeError]);

	const handleCancle = () => {
		// if (bankChanges?.length !== 0) {
		// 	dispatch(updateBankChangesRequest({ t } as any));
		// }
		navigate(-1);
	};
	return (
		<>
			{singlePolicyLoan && (
				<Grid item>
					<LoanAgainstHeader />
					<Grid
						container
						justifyContent="flex-start"
						alignItems="center"
						className="lender-details px-2"
					>
						<Typography
							component="span"
							className="f-14 fw-600"
							color={theme.palette.common.black}
						>
							{t('STEP')} 1
							<Typography
								component="span"
								className="f-14 fw-400"
								color={theme.palette.common.black}
							>
								/3
							</Typography>
						</Typography>
					</Grid>
				</Grid>
			)}
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className="lender-details px-2"
			>
				<Grid
					item
					xs={12}
					container
					display="flex"
					justifyContent="space-between"
					className="py-10"
				>
					<Typography
						fontSize={16}
						color={theme.palette.primary.main}
						fontWeight={600}
					>
						{t('SELECT_A_BANK')}
					</Typography>{' '}
					<CustomButton
						text={t('CANCEL')}
						variant="text"
						color="primary"
						showIcon={false}
						fontSize={14}
						startIcon={Cancel}
						onClick={handleCancle}
					/>
				</Grid>
				{isPoliciesForBanksLoading ? (
					<Grid container justifyContent="center">
						<Grid item>
							<Spinner />
						</Grid>
					</Grid>
				) : (
					<>
						<Grid item xs={12} className="mt-30 py-30">
							{bankLists.map((data: any) => (
								<>
									<BankDetails
										bankList="true"
										notSelect
										verifyBank={data?.bankVerified}
										bankName={data?.bankName}
										branch={data?.bankBranch}
										ifscCode={data?.bankIfscCode || data?.ifscCode}
										accountHolder={data?.accountHolderName}
										accountNum={data?.bankAcNo || data?.accountNo}
										bankLogo={data?.bankLogo}
										chequeImage={data?.chequeImage}
										onVerifyNow={() => {
											handleVerifyNow(data);
										}}
									/>
								</>
							))}
						</Grid>
						<Grid item xs={12} className="pb-30">
							<Typography
								fontSize={16}
								color={theme.palette.primary.main}
								fontWeight={600}
								pb={1}
							>
								OR
							</Typography>
							<CustomButton
								text={t('ADD_A_NEW_BANK_ACCOUNT')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={BankIcon}
								fontSize={16}
								fontWeight={600}
								onClick={() => addBankAccount('', '')}
							/>
						</Grid>
					</>
				)}
			</Grid>
			<BankEntryModal
				open={addAccount}
				setOpen={setAddAccount}
				onSubmit={handleSearch}
				loading={isGetBankDetailsLoading}
			/>
			{bankDetailsByIfsc !== false && bankDetailsByIfsc !== undefined && (
				<UploadImageBankModal
					skipforNowButton={true}
					open={verifyNow}
					setOpen={setVerifyNow}
					bankDetails={bankDetailsByIfsc}
					accountDetails={newAccountData}
					handleUploadModal={handleUploadModal}
					uploadChequeIsDone={Boolean(uploadChequePayload?.chequeImage)}
					uploadChequeData={uploadChequePayload}
					handleSkip={handleSkip}
					imagePickerModalVisible={imagePickerModalVisible}
					setImagePickerModalVisible={setImagePickerModalVisible}
					showAssignPolicies
				/>
			)}

			{/* <CommonModal
				onClose={() => false}
				open={updateBankChangesLoading}
				modalClassName="px-20 py-20"
			>
				<Spinner />
			</CommonModal> */}

			<UploadModal open={isUploadCheckLoadingLoading} />
			<ApiModal />
		</>
	);
};

export default SelectBankScreen;
