import React from 'react';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as GroupIcon } from '../../../../common/icons/dashboard/group_certified-icon.svg';
import { ReactComponent as HealthIcon } from '../../../../common/icons/dashboard/health-icon.svg';
import { ReactComponent as AssetsIcon } from '../../../../common/icons/dashboard/assets-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../../../common/icons/right_arrow-icon.svg';
import SvgConverter from '../../../../common/SvgConverter';
import { APP_ROUTES } from '../../../../common/constants/Routes';

const PolicyCardHeader = ({
	type = 'life',
}: {
	type?: 'life' | 'health' | 'assets' | undefined;
}) => {
	const theme = useTheme();

	const { t } = useTranslation();
	const Icons: any = {
		life: GroupIcon,
		health: HealthIcon,
		assets: AssetsIcon,
	};
	const Labels: any = { life: 'LIFE', health: 'HEALTH', assets: 'ASSETS' };

	return (
		<Grid
			container
			justifyContent="space-between"
			spacing={1}
			flexWrap="nowrap"
			alignItems="flex-end"
			className="dashboard-policy-card-header"
		>
			<Grid
				item
				container
				spacing={2}
				flexWrap="nowrap"
				alignItems="flex-end"
				className="h-100"
			>
				<Grid item className="h-100">
					<SvgConverter Icon={Icons[type]} className="d-flex align-items-end" />
				</Grid>
				<Grid item>
					<Typography
						className="pb-1"
						fontSize={28}
						color={theme.palette.primary.main}
						borderBottom={`2px solid ${theme.palette.secondary.main}`}
					>
						{t(Labels[type])}
					</Typography>
				</Grid>
			</Grid>
			<Grid item className="pb-5">
				<Link to={APP_ROUTES.POLICY} state={type}>
					<IconButton className="p-0">
						<SvgConverter
							Icon={RightArrowIcon}
							className="d-flex align-items-end next-arrow"
						/>
					</IconButton>
				</Link>
			</Grid>
		</Grid>
	);
};

export default PolicyCardHeader;
