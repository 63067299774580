/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import Typography from '@mui/material/Typography';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TimelineItem from '@mui/lab/TimelineItem';
import theme from '../../../../../common/theme';
import { ReactComponent as GreenTick } from '../../../../../common/icons/green_tick.svg';
import { ReactComponent as OrangeDot } from '../../../../../common/icons/orange_dot.svg';
import SvgConverter from '../../../../../common/SvgConverter';
import { getFormatDate } from '../../../../../common/utils';
import CustomButton from '../../../../../common/ui/CustomButton';
import { useDispatch } from 'react-redux';
import { signatureReminderRequest } from '../../Loan.slice';
import { useState } from 'react';
import { APP_ROUTES } from '../../../../../common/constants/Routes';
import { useNavigate } from 'react-router-dom';

interface UserProps {
	status?: string;
	title?: string;
	eta?: string;
	index?: any;
	loanStatus?: any;
	wholeData?: any;
	mobileNumber?: any;
	widget?: any;
	isEnd?: boolean;
}
const TrackerTransactionTimeline: React.FC<UserProps> = ({
	status,
	title,
	eta,
	index,
	wholeData,
	mobileNumber,
	widget,
	isEnd
}) => {
	const statusLowerCase = status?.toLocaleLowerCase();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const sessionId = localStorage.getItem('sessionId');
	const sessionDate = localStorage.getItem('sessionDate');
	const [showMobileNumber, setshowMobileNumber] = useState(false);

	const navigate = useNavigate();

	const handleSendReminder = () => {
		if (sessionId && sessionDate) {
			const payload: any = {
				sessionDate: sessionDate,
				sessionId: sessionId,
				loanFlag: widget?.Loan?.loanFlag
			};

			dispatch(signatureReminderRequest(payload));
		}
	}

	const handleMobileNumber = () => {
		if (!showMobileNumber) {
			setshowMobileNumber(true);
		} else {
			setshowMobileNumber(false);
		}
	}

	const handleEditWitness = () => {

		const widgetNo = widget.widgetNo;
		const lenderDetails = widget.Loan.LenderDetails;
		const bankDetails = widget.BankDetails;
		const combinations = wholeData;
		const isEditFromTrackTransaction = true;


		navigate(APP_ROUTES.ADD_WITNESS_DETAILS, {
			state: { widgetNo, lenderDetails, bankDetails, combinations, isEditFromTrackTransaction },
		});

	}

	return (
		<TimelineItem>
			<TimelineSeparator>
				{statusLowerCase === 'completed' || statusLowerCase === 'signed' ? (
					<SvgConverter Icon={GreenTick} className="greentick" />
				) : statusLowerCase === 'pending' || statusLowerCase === 'initiated' ? (
					<SvgConverter Icon={OrangeDot} className="orangedot" />
				) : (
					''
				)}

				<RadioButtonUncheckedIcon
					color="primary"
					className="timelineIcon"
					fontSize="small"
				/>

				{isEnd ? <></> : <TimelineConnector />}
				{/* {title === 'Surrender Request Initiated' ?
					(<>{index + 1 === 3 ? <></> : <TimelineConnector />}</>) :
					(<>{index + 1 === 5 ? <></> : <TimelineConnector />}</>)} */}
			</TimelineSeparator>
			<TimelineContent className="content ml-10">
				<Typography
					className="f-14 fw-600 mb-0"
					color={
						statusLowerCase === 'completed' ||
							statusLowerCase === 'signed' ||
							statusLowerCase === 'pending' ||
							statusLowerCase === 'initiated'
							? theme.palette.primary.main
							: theme.palette.grey[400]
					}
				>

					{index + 1} .{title}
				</Typography>
				{statusLowerCase === 'completed' || statusLowerCase === 'signed' ? (
					<Typography className="f-12 ml-10" color={theme.palette.common.black}>
						Completed on {getFormatDate(eta as any, 'YYYY-MM-DD')}
					</Typography>
				) : statusLowerCase === 'pending' || statusLowerCase === 'initiated' || statusLowerCase === 'rejected' ? (
					<>
						<Typography className="f-10 ml-10" color="#7f7f7f">
							{t('ETA: PolicyCards per actual')}
						</Typography>
						{title === 'Witness e-sign' && (
							<>
								<Typography
									className="f-12 ml-10"
									color={theme.palette.common.black}
								>
									{t('InProgress-Link shared')}
								</Typography>
								<CustomButton
									text={t('SHARE LINK AGAIN')}
									variant="text"
									color="primary"
									showIcon={false}
									className="ml-10"
									fontSize={12}
									onClick={handleSendReminder}
								/>
								<Typography>
									<CustomButton
										text='Call'
										variant="text"
										color="primary"
										showIcon={false}
										className=""
										fontSize={12}
										onClick={handleMobileNumber}
									/>
									{showMobileNumber ? (
										<span
											className="f-14"
											color={theme.palette.common.black}
										>
											{mobileNumber}
										</span>
									) : (<span
										className="f-12"
										color={theme.palette.common.black}>
									</span>)}
								</Typography>



								<CustomButton
									text={t('CHANGE_WITHNESS')}
									variant="text"
									color="primary"
									showIcon={false}
									fontSize={12}
									className="ml-10"
									onClick={handleEditWitness}
								/>
							</>
						)}
						{title === 'Insurer Processing' && (
							<>
								<Typography
									className="f-12 ml-10"
									color={theme.palette.common.black}
								>
									In-progress - Request Under Review
								</Typography>
								<CustomButton
									text={'REFRESH STATUS'}
									variant="text"
									color="primary"
									showIcon={false}
									className="ml-10"
									fontSize={12}
								// onClick={handleSendReminder}
								/>
								<CustomButton
									text={'CALL INSURER'}
									variant="text"
									color="primary"
									showIcon={false}
									className="ml-10"
									fontSize={12}
								// onClick={handleSendReminder}
								/>
							</>
						)}
					</>
				) : (
					<>
						{eta && eta.length !== 0 && eta !== undefined ? (
							<Typography className="f-10 ml-10" color="#7f7f7f">
								{t('ETA')}: {eta} {t('days')}
							</Typography>
						) : (
							''
						)}
					</>
				)}
			</TimelineContent>
		</TimelineItem>
	);
};
export default TrackerTransactionTimeline;
