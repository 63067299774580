import React from 'react';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import SelectFinancierDetails from './SelectFinancierDetails';
import { convertAmount } from '../../../common/utils';
// import HdfcIcon from '../../../assets/images/HDFC.png';
// import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';

interface FinancierBankContentProps {
	financierDetails?: any;
	isSelected?: any;
	onSelect?: any;
}

const FinancierBankContent = ({
	financierDetails,
	isSelected,
	onSelect,
}: FinancierBankContentProps) => (
	<Grid
		container
		flexWrap="nowrap"
		// alignItems="center"
		justifyContent="space-between"
		spacing={1}
		className="my-5"
	>
		<Grid item>
			<Grid container flexWrap="nowrap" alignItems="center">
				<Grid item className="mt-n10">
					<Checkbox
						sx={{
							color: 'primary.main',
							'&.Mui-checked': {
								color: 'primary',
							},
						}}
						checked={isSelected}
						onChange={onSelect}
					/>
				</Grid>
				<Grid item className="mt-2 ml-7">
					<img
						src={financierDetails?.lenderLogo}
						alt="logo"
						className="bank_logo"
					/>
				</Grid>
			</Grid>
		</Grid>
		<Grid item flexWrap="nowrap">
			<SelectFinancierDetails
				product={financierDetails?.lenderName}
				// lifeAssured={financierDetails?.lenderName}
				chargeAmount={convertAmount(financierDetails?.charges)}
				loanAmount={convertAmount(financierDetails?.loanAmount)}
				interestPercentage={financierDetails?.interestPercentage}
			/>
		</Grid>
	</Grid>
);
export default FinancierBankContent;
