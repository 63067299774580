/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { PolicyMobile } from '../Profile/types/Profile.model';
import { LoadingState } from '../../../common';
import { MOBILE_EMAIL_CHANGE_ACTIONS } from '../../../common/Enums';
import {
	removeDuplicatedPoliciesForAssign,
	swapToTop,
} from '../../../common/utils';
import { RootState } from '../../../common/store';

interface MobileNumberState {
	policies: any;
	isLoading: LoadingState;
	errors: any | null;
	mobileNumbers: any[];
	isMobileNumbersLoading: LoadingState;
	errorMobileNumbers: any;
	removeMobileChanges: any;
	updateMobileNoChanges: { status: LoadingState; data: any; error: any };
	generateOtpForMobile: { status: LoadingState; data: any; error: any };
	verifyMobileNo: { status: LoadingState; data: any; error: any };
	mobileChangesList: any[];
}

const initialState: MobileNumberState = {
	isLoading: 'idle',
	policies: { mobileBasedPolicies: [], unAssignedMobiles: [] },
	errors: null,
	isMobileNumbersLoading: 'idle',
	mobileNumbers: [],
	errorMobileNumbers: null,
	removeMobileChanges: null,
	updateMobileNoChanges: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	generateOtpForMobile: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	verifyMobileNo: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	mobileChangesList: [],
};

const mobileNumberSlice = createSlice({
	name: 'mobileNumber',
	initialState,
	reducers: {
		fetchPoliciesForMobileNumber: (state) => ({
			...state,
			isLoading: 'loading',
			errors: null,
			policies: { mobileBasedPolicies: [], unAssignedMobiles: [] },
		}),
		loadPoliciesForMobileNumber: (state, { payload }) => ({
			...state,
			isLoading: 'done',
			errors: null,
			policies: {
				mobileBasedPolicies: payload.MobileBasedPolicies,
				unAssignedMobiles: payload.UnassignedMobiles,
			},
		}),
		errorLoadingPoliciesForMobileNumber: (state, action) => ({
			...state,
			isLoading: 'done',
			errors: action.payload,
			policies: { mobileBasedPolicies: [], unAssignedMobiles: [] },
		}),
		fetchMobileNumbers: (state) => ({
			...state,
			isMobileNumbersLoading: 'loading',
			mobileNumbers: [],
			errorMobileNumbers: null,
		}),
		loadMobileNumbers: (state, action) => ({
			...state,
			isMobileNumbersLoading: 'done',
			mobileNumbers: action.payload,
			errorMobileNumbers: null,
		}),
		errorLoadingMobileNumbers: (state, action) => ({
			...state,
			isMobileNumbersLoading: 'done',
			mobileNumbers: [],
			errorMobileNumbers: action.payload,
		}),

		removeMobileNumber: (state, action) => {
			const { newValue, oldValue, handleDone, isUnAssignedMobile } =
				action.payload;
			let changes: any = [];
			if (!isUnAssignedMobile) {
				const selectedOldNumber = state.policies.mobileBasedPolicies.find(
					({ mobileNo }: any) => mobileNo === oldValue
				);
				const affectedPolicies = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) => mobileNo === oldValue
				);
				const isUnAssignedMobileSelected =
					state.policies.unAssignedMobiles.find(
						({ mobileNo }: any) => mobileNo === newValue
					);

				let policies = [];
				if (!isUnAssignedMobileSelected) {
					policies = state.policies.mobileBasedPolicies.map((policy: any) => {
						if (policy.mobileNo === oldValue) {
							policy.mobileNo = newValue;
							policy.primaryFlag = selectedOldNumber?.primaryFlag ?? 'Z';
						}
						return policy;
					});
				} else {
					policies = state.policies.mobileBasedPolicies.map((policy: any) => {
						if (policy.mobileNo === oldValue) {
							policy.mobileNo = newValue;
							policy.primaryFlag =
								selectedOldNumber?.primaryFlag === 'Y'
									? 'Y'
									: isUnAssignedMobileSelected?.primaryFlag ?? 'Z';
						}
						return policy;
					});
				}
				state.policies.mobileBasedPolicies = policies;
				changes = generateMobileChangesForAPI(
					affectedPolicies,
					newValue,
					oldValue,
					MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
				);
				state.policies.unAssignedMobiles = [
					...state.policies.unAssignedMobiles,
					{
						mobileCountryCode: '+91',
						mobileNo: oldValue,
						mobileNoVerified: 'Y',
						primaryFlag: 'Z'
					},
				];
			} else {
				changes = generateMobileChangesForAPI(
					[],
					newValue,
					oldValue,
					MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
				);
				state.policies.unAssignedMobiles = removeMobileFromUnAssignedMobiles(
					state.policies.unAssignedMobiles,
					oldValue
				);
			}

			state.mobileChangesList = [...state.mobileChangesList, changes];
			if (handleDone) {
				handleDone();
			}
		},

		changePrimaryNumber: (state, action) => {
			const { selectedNumber, targetedNumber, isNew = false } = action.payload;
			const isSelectedUnAssigned = state.policies.unAssignedMobiles.some(
				({ mobileNo }: any) => mobileNo === selectedNumber
			);

			const isTargetUnAssigned = state.policies.unAssignedMobiles.some(
				({ mobileNo }: any) => mobileNo === targetedNumber
			);
			const unAssignedMobiles = state.policies.unAssignedMobiles;
			let changes: any = [];

			if (isNew && isSelectedUnAssigned) {
				changes = generateMobileChangesForAPI(
					[],
					targetedNumber,
					selectedNumber,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const unAssignedUpdated = state.policies.unAssignedMobiles.map(
					(mobile: { mobileNo: any; primaryFlag: string }) => {
						if (mobile.mobileNo === selectedNumber) {
							mobile.primaryFlag = 'N';
						}
						return mobile;
					}
				);
				state.policies.unAssignedMobiles = [
					...unAssignedUpdated,
					{
						mobileCountryCode: '+91',
						mobileNo: targetedNumber,
						primaryFlag: 'Y',
						mobileNoVerified: 'Y',
					},
				];
			}
			if (isNew && !isSelectedUnAssigned) {
				const selectedPolicies = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) => mobileNo === selectedNumber
				);
				const targetedPolices = selectedPolicies.map((policy: any) => ({
					...policy,
					primaryFlag: 'Y',
					mobileNo: targetedNumber,
				}));
				changes = generateMobileChangesForAPI(
					targetedPolices,
					targetedNumber,
					selectedNumber,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const otherPolicies = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) => mobileNo !== selectedNumber
				);
				state.policies.mobileBasedPolicies = [
					...targetedPolices,
					...otherPolicies,
				];
				const unAssignedUpdated = state.policies.unAssignedMobiles.filter(
					({ mobileNo }: any) => mobileNo !== targetedNumber
				);
				state.policies.unAssignedMobiles = [
					...unAssignedUpdated,
					{
						mobileCountryCode: '+91',
						mobileNo: selectedNumber,
						primaryFlag: 'N',
						mobileNoVerified: 'Y',
					},
				];
			}

			if (isSelectedUnAssigned && isTargetUnAssigned && !isNew) {
				unAssignedMobiles.map(
					(policy: { mobileNo: any; primaryFlag: string }) => {
						if (policy.mobileNo === targetedNumber) {
							policy.primaryFlag = 'Y';
						}
						if (policy.mobileNo === selectedNumber) {
							policy.primaryFlag = 'N';
						}
						return policy;
					}
				);
				changes = generateMobileChangesForAPI(
					[],
					targetedNumber,
					selectedNumber,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const unAssignedUpdated = state.policies.unAssignedMobiles.map(
					(mobile: { mobileNo: any; primaryFlag: string }) => {
						if (mobile.mobileNo === targetedNumber) {
							mobile.primaryFlag = 'Y';
						}
						if (mobile.mobileNo === selectedNumber) {
							mobile.primaryFlag = 'N';
						}
						return mobile;
					}
				);
				state.policies.unAssignedMobiles = unAssignedUpdated;
			}
			if (!isSelectedUnAssigned && isTargetUnAssigned && !isNew) {
				const selectedPolicies = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) => mobileNo === selectedNumber
				);
				const targetedPolices = selectedPolicies.map((policy: any) => ({
					...policy,
					primaryFlag: 'Y',
					mobileNo: targetedNumber,
				}));
				changes = generateMobileChangesForAPI(
					targetedPolices,
					targetedNumber,
					selectedNumber,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const otherPolicies = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) => mobileNo !== selectedNumber
				);
				state.policies.mobileBasedPolicies = [
					...targetedPolices,
					...otherPolicies,
				];
				const unAssignedUpdated = state.policies.unAssignedMobiles.filter(
					({ mobileNo }: any) => mobileNo !== targetedNumber
				);
				state.policies.unAssignedMobiles = [
					...unAssignedUpdated,
					{
						mobileCountryCode: '+91',
						mobileNo: selectedNumber,
						primaryFlag: 'N',
						mobileNoVerified: 'Y',
					},
				];
			}
			if (isSelectedUnAssigned && !isTargetUnAssigned && !isNew) {
				const targetedNumberPolicies =
					state.policies.mobileBasedPolicies.filter(
						({ mobileNo }: any) => mobileNo === targetedNumber
					);

				const targetedPolicies = targetedNumberPolicies.map((policy: any) => ({
					...policy,
					primaryFlag: 'Y',
				}));
				changes = generateMobileChangesForAPI(
					targetedPolicies,
					targetedNumber,
					selectedNumber,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const otherPolicies = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) => mobileNo !== targetedNumber
				);
				state.policies.mobileBasedPolicies = [
					...targetedPolicies,
					...otherPolicies,
				];
				const unAssignedUpdated = state.policies.unAssignedMobiles.map(
					(mobile: { mobileNo: any }) => {
						if (mobile.mobileNo === selectedNumber) {
							return {
								...mobile,
								primaryFlag: 'N',
							};
						}
						return mobile;
					}
				);
				state.policies.unAssignedMobiles = unAssignedUpdated;
			}
			if (!isSelectedUnAssigned && !isTargetUnAssigned && !isNew) {
				const selectedPolicies = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) => mobileNo === selectedNumber
				);
				const targetedPolices = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) => mobileNo === targetedNumber
				);
				const totalTargetedPolicies = [
					...targetedPolices,
					...selectedPolicies,
				].map((policy) => ({
					...policy,
					primaryFlag: 'Y',
					mobileNo: targetedNumber,
				}));
				const otherPolices = state.policies.mobileBasedPolicies.filter(
					({ mobileNo }: any) =>
						![selectedNumber, targetedNumber].includes(mobileNo)
				);
				state.policies.mobileBasedPolicies = [
					...totalTargetedPolicies,
					...otherPolices,
				];
				changes = generateMobileChangesForAPI(
					selectedPolicies,
					targetedNumber,
					selectedNumber,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const unAssignedMobiles = state.policies.unAssignedMobiles;
				state.policies.unAssignedMobiles = [
					...unAssignedMobiles,
					{
						mobileCountryCode: '+91',
						mobileNo: selectedNumber,
						primaryFlag: 'N',
						mobileNoVerified: 'Y',
					},
				];
			}
			state.mobileChangesList = [...state.mobileChangesList, changes];
		},
		// changePrimaryNumber: (state, action) => {
		// 	const { selectedNumber, targetedNumber } = action.payload;
		// 	const isSelectedUnAssigned = state.policies.unAssignedMobiles.find(
		// 		({ mobileNo }: any) => mobileNo === selectedNumber
		// 	);
		// 	const isTargetUnAssigned = state.policies.unAssignedMobiles.find(
		// 		({ mobileNo }: any) => mobileNo === targetedNumber
		// 	);
		// 	const {unAssignedMobiles} = state.policies;
		// 	let changes: any = [];
		// 	if (isSelectedUnAssigned && isTargetUnAssigned) {
		// 		unAssignedMobiles.map((policy: any) => {
		// 			if (policy.mobileNo === targetedNumber) {
		// 				policy.primaryFlag = 'Y';
		// 			}
		// 			if (policy.mobileNo === selectedNumber) {
		// 				policy.primaryFlag = 'N';
		// 			}
		// 			return policy;
		// 		});
		// 		changes = generateMobileChangesForAPI(
		// 			[],
		// 			targetedNumber,
		// 			selectedNumber,
		// 			MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
		// 			'Y'
		// 		);
		// 		const unAssignedUpdated = state.policies.unAssignedMobiles.map(
		// 			(mobile: any) => {
		// 				if (mobile.mobileNo === targetedNumber) {
		// 					mobile.primaryFlag = 'Y';
		// 				}
		// 				if (mobile.mobileNo === selectedNumber) {
		// 					mobile.primaryFlag = 'N';
		// 				}
		// 				return mobile;
		// 			}
		// 		);
		// 		state.policies.unAssignedMobiles = unAssignedUpdated;
		// 	}
		// 	if (!isSelectedUnAssigned && isTargetUnAssigned) {
		// 		const selectedPolicies = state.policies.mobileBasedPolicies.filter(
		// 			({ mobileNo }: any) => mobileNo === selectedNumber
		// 		);
		// 		const targetedPolices = selectedPolicies.map((policy: any) => ({
		// 			...policy,
		// 			primaryFlag: 'Y',
		// 			mobileNo: targetedNumber,
		// 		}));
		// 		changes = generateMobileChangesForAPI(
		// 			targetedPolices,
		// 			targetedNumber,
		// 			selectedNumber,
		// 			MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
		// 			'Y'
		// 		);
		// 		const otherPolicies = state.policies.mobileBasedPolicies.filter(
		// 			({ mobileNo }: any) => mobileNo !== selectedNumber
		// 		);
		// 		state.policies.mobileBasedPolicies = [
		// 			...targetedPolices,
		// 			...otherPolicies,
		// 		];
		// 		const unAssignedUpdated = state.policies.unAssignedMobiles.filter(
		// 			({ mobileNo }: any) => mobileNo !== targetedNumber
		// 		);
		// 		state.policies.unAssignedMobiles = [
		// 			...unAssignedUpdated,
		// 			{
		// 				mobileCountryCode: '+91',
		// 				mobileNo: selectedNumber,
		// 				primaryFlag: 'N',
		// 				mobileNoVerified: 'Y',
		// 			},
		// 		];
		// 	}
		// 	if (isSelectedUnAssigned && !isTargetUnAssigned) {
		// 		const targetedNumberPolicies =
		// 			state.policies.mobileBasedPolicies.filter(
		// 				({ mobileNo }: any) => mobileNo === targetedNumber
		// 			);

		// 		const targetedPolicies = targetedNumberPolicies.map((policy: any) => ({
		// 			...policy,
		// 			primaryFlag: 'Y',
		// 		}));
		// 		changes = generateMobileChangesForAPI(
		// 			targetedPolicies,
		// 			targetedNumber,
		// 			targetedNumber,
		// 			MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
		// 			'Y'
		// 		);
		// 		const otherPolicies = state.policies.mobileBasedPolicies.filter(
		// 			({ mobileNo }: any) => mobileNo !== targetedNumber
		// 		);
		// 		state.policies.mobileBasedPolicies = [
		// 			...targetedPolicies,
		// 			...otherPolicies,
		// 		];
		// 		const unAssignedUpdated = state.policies.unAssignedMobiles.map(
		// 			(mobile: any) => {
		// 				if (mobile.mobileNo === selectedNumber) {
		// 					return {
		// 						...mobile,
		// 						primaryFlag: 'N',
		// 					};
		// 				}
		// 				return mobile;
		// 			}
		// 		);
		// 		state.policies.unAssignedMobiles = unAssignedUpdated;
		// 	}
		// 	if (!isSelectedUnAssigned && !isTargetUnAssigned) {
		// 		const selectedPolicies = state.policies.mobileBasedPolicies.filter(
		// 			({ mobileNo }: any) => mobileNo === selectedNumber
		// 		);
		// 		const targetedPolices = state.policies.mobileBasedPolicies.filter(
		// 			({ mobileNo }: any) => mobileNo === targetedNumber
		// 		);
		// 		const totalTargetedPolicies = [
		// 			...targetedPolices,
		// 			...selectedPolicies,
		// 		].map((policy) => ({
		// 			...policy,
		// 			primaryFlag: 'Y',
		// 			mobileNo: targetedNumber,
		// 		}));
		// 		const otherPolices = state.policies.mobileBasedPolicies.filter(
		// 			({ mobileNo }: any) =>
		// 				![selectedNumber, targetedNumber].includes(mobileNo)
		// 		);
		// 		state.policies.mobileBasedPolicies = [
		// 			...totalTargetedPolicies,
		// 			...otherPolices,
		// 		];
		// 		changes = generateMobileChangesForAPI(
		// 			targetedPolices,
		// 			targetedNumber,
		// 			selectedNumber,
		// 			MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
		// 			'Y'
		// 		);
		// 		const {unAssignedMobiles} = state.policies;
		// 		state.policies.unAssignedMobiles = [
		// 			...unAssignedMobiles,
		// 			{
		// 				mobileCountryCode: '+91',
		// 				mobileNo: selectedNumber,
		// 				primaryFlag: 'N',
		// 				mobileNoVerified: 'Y',
		// 			},
		// 		];
		// 	}
		// 	state.mobileChangesList = [...state.mobileChangesList, changes];
		// },

		changeMobileNumber: (state, action) => {
			const { selectedPolicy, targetMobileNo } = action.payload;
			const targetedMobilePolicy = state.policies.mobileBasedPolicies.find(
				(policy: any) => policy.mobileNo === targetMobileNo
			);
			let policies = [];
			let processedSelectedPolicy = null;
			let apiPolicyDetails = [];
			if (!targetMobileNo) {
				return;
			}
			if (selectedPolicy.type === 'primaryMobileChange') {
				const otherPolicies = state.policies.mobileBasedPolicies.filter(
					(policy: any) => policy.mobileNo !== targetMobileNo
				);
				const targetMobileBasedPolicies = state.policies.mobileBasedPolicies
					.filter(({ mobileNo }: any) => mobileNo === targetMobileNo)
					.map((policy: any) => ({ ...policy, primaryFlag: 'Y' }));

				let unassignedUpdated = state.policies.unAssignedMobiles.filter(
					({ mobileNo }: any) => mobileNo !== selectedPolicy.mobileNo
				);

				if (!targetMobileBasedPolicies.length) {
					unassignedUpdated = unassignedUpdated.map((mobile: any): any => {
						if (mobile.mobileNo === targetMobileNo) {
							return {
								...mobile,
								primaryFlag: 'Y',
							};
						}
						return mobile;
					});
				}

				state.policies.unAssignedMobiles = unassignedUpdated;
				policies = [...targetMobileBasedPolicies, ...otherPolicies];
				apiPolicyDetails = targetMobileBasedPolicies;
			} else {
				let primaryFlag = 'Z';

				if (!targetedMobilePolicy) {
					// if the target number have no policies it is unassigned.
					const unAssignedNumber = state.policies.unAssignedMobiles.find(
						(unAssignedMobile: any) =>
							unAssignedMobile.mobileNo === targetMobileNo
					);
					primaryFlag = unAssignedNumber?.primaryFlag ?? 'Z';
				} else {
					primaryFlag = targetedMobilePolicy?.primaryFlag ?? 'Z';
				}

				processedSelectedPolicy = {
					...selectedPolicy,
					primaryFlag,
				};
				policies = changePolicyMobileNo(
					state.policies.mobileBasedPolicies,
					processedSelectedPolicy,
					targetMobileNo
				);
				const { mobileNo } = processedSelectedPolicy;
				const isMobileNoHavePolicy = policies.some(
					(policy) => policy.mobileNo === mobileNo
				);
				if (
					!isMobileNoHavePolicy &&
					processedSelectedPolicy?.mobileNo != 'null' &&
					!state.policies.unAssignedMobiles.find(
						(unassignedMobile: any) => unassignedMobile.mobileNo === mobileNo
					)
				) {
					state.policies.unAssignedMobiles = [
						...state.policies.unAssignedMobiles,
						{
							mobileCountryCode: '+91',
							mobileNo,
							mobileNoVerified: 'Y',
							primaryFlag: selectedPolicy.primaryFlag,
						},
					];
				}
				apiPolicyDetails = [processedSelectedPolicy];
			}

			state.policies.mobileBasedPolicies = policies;

			if (selectedPolicy?.type !== 'primaryMobileChange') {
				const changes = generateMobileChangesForAPI(
					apiPolicyDetails,
					targetMobileNo,
					selectedPolicy.mobileNo,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
				);

				state.mobileChangesList = [...state.mobileChangesList, changes];
				state.policies.mobileBasedPolicies = swapToTop(
					state.policies.mobileBasedPolicies,
					changes.Policies.map(({ policyNo }) => policyNo),
					'policyNo'
				);
			} else {
				const removeChanges = generateMobileChangesForAPI(
					apiPolicyDetails,
					targetMobileNo,
					selectedPolicy.mobileNo,
					MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE,
					apiPolicyDetails.length ? undefined : 'Y'
				);
				state.mobileChangesList = [...state.mobileChangesList, removeChanges];
			}
		},

		assignMobileNumber: (state, action) => {
			const {
				newMobileNo,
				selectedPolicies,
				handleDone,
				isOld,
				primaryFlag,
				purpose,
			} = action.payload;
			let policies = [];
			if (selectedPolicies?.length > 0) {
				const getOldMobileNumbers = R.pipe(
					R.uniqBy(R.prop('mobileNo')),
					R.map(({ mobileNo, verifiedFlag }: any) => ({
						mobileNo,
						mobileNoVerified: verifiedFlag,
					}))
				);
				const oldMobileNumbers = getOldMobileNumbers(
					selectedPolicies.filter((policy: any) => policy.mobileNo)
				);
				const oldMobileNumberPolicyInfo = oldMobileNumbers.map(
					(mobileNumber) => {
						const existingPolicy = state.policies.mobileBasedPolicies.find(
							(policy: any) => policy.mobileNo === mobileNumber.mobileNo
						);

						const existingUnAssigned = state.policies.unassignedMobiles?.find(
							(mobile: any) => mobile.mobileNo === mobileNumber.mobileNo
						);
						return {
							...mobileNumber,
							primaryFlag: existingPolicy
								? existingPolicy.primaryFlag
								: existingUnAssigned?.primaryFlag ?? 'Z',
						};
					}
				);
				policies = assignPolicyMobileNo(
					selectedPolicies,
					newMobileNo,
					primaryFlag
				);
				const duplicateRemovedCurrentList = removeDuplicatedPoliciesForAssign(
					state.policies.mobileBasedPolicies,
					selectedPolicies
				);
				state.policies.mobileBasedPolicies = [
					...duplicateRemovedCurrentList,
					...policies,
				];
				const unassignedMobiles = oldMobileNumberPolicyInfo
					.filter(
						(mobile) =>
							!state.policies.mobileBasedPolicies.some(
								({ mobileNo }: any) => mobileNo === mobile.mobileNo
							)
					)
					.map((mobile) => ({ ...mobile, mobileCountryCode: '+91' }));

				if (unassignedMobiles.length > 0) {
					state.policies.unAssignedMobiles = R.pipe(
						R.uniqBy(R.prop('mobileNo'))
					)([...state.policies.unAssignedMobiles, ...unassignedMobiles]);
				}
			} else if (!isOld) {
				state.policies.unAssignedMobiles = [
					...state.policies.unAssignedMobiles,
					{
						mobileNo: newMobileNo,
						mobileNoVerified: 'Y',
						mobileCountryCode: '+91',
					},
				];
				state.policies.unAssignedMobiles = swapToTop(
					state.policies.unAssignedMobiles,
					[newMobileNo],
					'mobileNo'
				);
			} else {
				if (handleDone) {
					handleDone();
				}
				return state;
			}

			if (policies?.length !== 0) {
				const changes: any = [];
				policies.map((values: any) => {
					const policiesChange = generateMobileChangesForAPI(
						[values],
						newMobileNo,
						'null',
						MOBILE_EMAIL_CHANGE_ACTIONS.ADD,
						undefined,
						purpose
					);
					changes.push(policiesChange);
				});

				state.mobileChangesList = [...state.mobileChangesList, ...changes];
				state.policies.mobileBasedPolicies = swapToTop(
					state.policies.mobileBasedPolicies,
					changes.map(({ Policies }: any) => {
						return Policies[0].policyNo;
					}),
					'policyNo'
				);
			} else {
				const changes = generateMobileChangesForAPI(
					policies,
					newMobileNo,
					'null',
					MOBILE_EMAIL_CHANGE_ACTIONS.ADD
				);

				state.mobileChangesList = [...state.mobileChangesList, changes];
				state.policies.mobileBasedPolicies = swapToTop(
					state.policies.mobileBasedPolicies,
					changes.Policies.map(({ policyNo }) => policyNo),
					'policyNo'
				);
			}

			if (handleDone) {
				handleDone();
			}
		},
		updateMobileNoRequest: (state) => {
			state.updateMobileNoChanges.status = 'loading';
			state.updateMobileNoChanges.data = undefined;
			state.updateMobileNoChanges.error = undefined;
		},
		updateMobileNoSuccess: (state, { payload }) => {
			state.updateMobileNoChanges.status = 'done';
			state.updateMobileNoChanges.data = payload;
			state.updateMobileNoChanges.error = undefined;
			state.mobileChangesList = [];
		},
		updateMobileNoError: (state, { payload }) => {
			state.updateMobileNoChanges.status = 'error';
			state.updateMobileNoChanges.data = undefined;
			state.updateMobileNoChanges.error = payload;
		},

		generateOtpForMobileRequest: (state) => {
			state.generateOtpForMobile.status = 'loading';
			state.generateOtpForMobile.data = undefined;
			state.generateOtpForMobile.error = undefined;
		},
		generateOtpForMobileSuccess: (state, { payload }) => {
			state.generateOtpForMobile.status = 'done';
			state.generateOtpForMobile.data = payload;
			state.generateOtpForMobile.error = undefined;
		},
		generateOtpForMobileError: (state, { payload }) => {
			state.generateOtpForMobile.status = 'error';
			state.generateOtpForMobile.data = undefined;
			state.generateOtpForMobile.error = payload;
		},

		verifyMobileNoRequest: (state) => {
			state.verifyMobileNo.status = 'loading';
			state.verifyMobileNo.data = undefined;
			state.verifyMobileNo.error = undefined;
		},
		verifyMobileNoSuccess: (state, { payload }) => {
			state.verifyMobileNo.status = 'done';
			state.verifyMobileNo.data = payload;
			state.verifyMobileNo.error = undefined;
		},
		verifyMobileNoError: (state, { payload }) => {
			state.verifyMobileNo.status = 'error';
			state.verifyMobileNo.data = undefined;
			state.verifyMobileNo.error = payload;
		},
		setMobileNoChangesList: (state, { payload }) => {
			state.mobileChangesList = payload || [];
		},
	},
});

export const {
	fetchMobileNumbers,
	loadMobileNumbers,
	errorLoadingMobileNumbers,
	fetchPoliciesForMobileNumber,
	loadPoliciesForMobileNumber,
	errorLoadingPoliciesForMobileNumber,
	removeMobileNumber,
	changeMobileNumber,
	changePrimaryNumber,
	updateMobileNoRequest,
	updateMobileNoSuccess,
	updateMobileNoError,
	generateOtpForMobileRequest,
	generateOtpForMobileSuccess,
	generateOtpForMobileError,
	verifyMobileNoRequest,
	verifyMobileNoSuccess,
	verifyMobileNoError,
	assignMobileNumber,
	setMobileNoChangesList,
} = mobileNumberSlice.actions;

// const mapAssignPolicy =
// 	(newValue: string, oldValue: string) => (policy: PolicyMobile) => {
// 		if (policy.mobileNo === oldValue) {
// 			policy.mobileNo = newValue;
// 		}
// 		return policy;
// 	};

// const assignPolicyToOtherNumber = (
// 	policies: PolicyMobile[],
// 	newValue: string,
// 	oldValue: string
// ) => policies.map(mapAssignPolicy(newValue, oldValue));

const generateMobileChangesForAPI = (
	policies: PolicyMobile[],
	newValue: string,
	oldValue: string,
	action: string,
	primaryFlag: undefined | 'Y' = undefined,
	purpose?: string | undefined
) => {
	const policiesForPayload: any[] = policies.map(
		({ companyCode, policyNo, alternateCode, oldMobileNo }: any) => ({
			insurerCode: companyCode,
			policyNo,
			alternateCode,
			oldMobileNo,
		})
	);

	return {
		action:
			oldValue == 'null'
				? policiesForPayload.length === 0
					? MOBILE_EMAIL_CHANGE_ACTIONS.ADD
					: policiesForPayload?.[0]?.oldMobileNo === '' ||
						policiesForPayload?.[0]?.oldMobileNo === undefined ||
						policiesForPayload?.[0]?.oldMobileNo === null
						? MOBILE_EMAIL_CHANGE_ACTIONS.ADD
						: MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
				: action, // For unsigned policies have to send as add action type
		mobileCountryCode: '+91',
		newValue: newValue || '',
		oldValue:
			oldValue === 'null'
				? policiesForPayload?.[0]?.oldMobileNo
					? policiesForPayload?.[0]?.oldMobileNo
					: ''
				: oldValue,
		Policies: policiesForPayload,
		primaryFlag: primaryFlag || 'Z',
		purpose: purpose || '',
	};
};

const changePolicyMobileNo = (
	policies: any[],
	selectedPolicy: any,
	targetMobileNo: string
) => {
	if (Array.isArray(policies)) {
		const selectedPolicyIndex = policies.findIndex(
			({ mobileNo, policyNo }: any) =>
				mobileNo === selectedPolicy.mobileNo &&
				policyNo === selectedPolicy.policyNo
		);

		if (selectedPolicyIndex >= 0) {
			policies[selectedPolicyIndex].mobileNo = targetMobileNo;
			policies[selectedPolicyIndex].mobileNoVerified = 'Y';
			policies[selectedPolicyIndex].primaryFlag = selectedPolicy.primaryFlag;
		}
	}
	return policies;
};

const assignPolicyMobileNo = (
	selectedPolicies: any[],
	newMobileNo: string,
	primaryFlag = 'Z'
) => {
	selectedPolicies = selectedPolicies.map((row: any) => ({
		...row,
		mobileNo: newMobileNo,
		mobileNoVerified: 'Y',
		primaryFlag,
		oldMobileNo: row.mobileNo,
	}));
	return selectedPolicies;
};

const removeMobileFromUnAssignedMobiles = (
	policies: any[],
	targetMobileNo: string
) => R.reject(({ mobileNo }) => mobileNo === targetMobileNo, policies);

export const selectIsPolicyMobileNumbersLoading = (state: RootState) =>
	state.mobileNumber.isLoading === 'loading';

export const selectPoliciesForMobileNumbers = (state: RootState) =>
	state.mobileNumber.policies;

export const selectErrorLoadingPolicies = (state: RootState) =>
	state.mobileNumber.errors;

export const selectIsMobileNumbersLoading = (state: RootState) =>
	state.mobileNumber.isMobileNumbersLoading === 'loading';

export const selectMobileNumbers = (state: RootState) =>
	state.mobileNumber.mobileNumbers;

export const selectErrorLoadingMobileNumbers = (state: RootState) =>
	state.mobileNumber.errorMobileNumbers;

export const isUpdateMobileNumbersLoading = (state: RootState) =>
	state.mobileNumber.updateMobileNoChanges.status === 'loading';

export const isGenerateOtpForMobileLoading = (state: RootState) =>
	state.mobileNumber.generateOtpForMobile.status === 'loading';

export const selectIsMobileNoVerifyLoading = (state: RootState) =>
	state.mobileNumber.verifyMobileNo.status === 'loading';

export const selectMobileNoChangesList = (state: RootState) =>
	state.mobileNumber.mobileChangesList;

export const selectUnAssignedMobileNumbers = (state: RootState) =>
	state.mobileNumber.policies.unAssignedMobiles;

export default mobileNumberSlice.reducer;

export const getMobileNoList = (
	selectedPolicyDetails: any,
	mobilePolicies: any
) => {
	let mobileNumbersLocal = [
		...mobilePolicies.mobileBasedPolicies,
		...mobilePolicies.unAssignedMobiles,
	];
	mobileNumbersLocal = mobileNumbersLocal.map(({ mobileNo }) => mobileNo);

	mobileNumbersLocal = R.uniq(mobileNumbersLocal);

	mobileNumbersLocal = R.reject(
		(val) => val == 'null' || val === selectedPolicyDetails?.mobileNo,
		mobileNumbersLocal
	);

	mobileNumbersLocal = mobileNumbersLocal.map((mNo: any) => {
		const currentMobile = [
			...mobilePolicies.mobileBasedPolicies,
			...mobilePolicies.unAssignedMobiles,
		].filter((item) => item.mobileNo === mNo)?.[0];
		if (currentMobile) { return { mobileNo: currentMobile.mobileNo, mobileNoVerified: currentMobile.mobileNoVerified } }
	})
	return mobileNumbersLocal;
};
