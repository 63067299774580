import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { ReactComponent as CancelledCheque } from '../../../common/icons/cancelled-cheque.svg';
import theme from '../../../common/theme';
// import HDFC from '../../../common/icons/update-mobile/hdfc_bank_icon.png';
import CustomButton from '../../../common/ui/CustomButton';
// import SvgConverter from '../../../common/SvgConverter';
import TapToSelectModel from './TapToSelectModel';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { getLoanData } from './Loan.slice';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as ErrorIcon } from '../../../common/icons/Red-alert-bank.svg';
import { ReactComponent as WarningIcon } from '../../../common/icons/orange-alert-icon.svg';
import { ReactComponent as TickIcon } from '../../../common/icons/Green-tick-bank.svg';
import {
	selectBankChangesList,
	updateBankChangesRequest,
} from '../BankModule/Bank.slice';

interface UserProps {
	bankList?: any;
	notSelect?: boolean;
	bankName?: any;
	accountNum?: any;
	accountHolder?: any;
	ifscCode?: any;
	branch?: any;
	verifyBank?: any;
	bankLogo?: any;
	chequeImage?: any;
	onVerifyNow?: any;
	selectButton?: any;
	widgetNos?: any;
}

const BankDetails = ({
	bankList,
	notSelect = false,
	verifyBank,
	bankName,
	branch,
	ifscCode,
	accountHolder,
	accountNum,
	bankLogo,
	chequeImage,
	onVerifyNow,
	selectButton,
	widgetNos,
}: UserProps) => {
	const [showAddModal, setShowAddModal] = useState(false);
	const location = useLocation();

	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const bankChanges: any = useSelector(selectBankChangesList);
	const bankScreen = location.state?.bankScreenNavigate;
	const lenderDetails = location.state?.lenderDetails;
	const widgetNo = location.state?.widgetNo || location.state?.widgetNos;
	const singlePolicyLoan = location.state?.Lender;
	const surrenderPolicy = location.state?.surrender;
	const handleNavigate = () => {
		const bankDetails = {
			widgetNo,
			bankDetails: {
				bankAcNo: accountNum,
				bankName,
				accountHolderName: accountHolder,
				bankBranch: branch,
				bankIfscCode: ifscCode,
				bankVerified: verifyBank,
				bankLogo,
				chequeImage,
			},
		};
		if (bankScreen) {
			navigate(APP_ROUTES.ADD_WITNESS_DETAILS, {
				state: { widgetNo, lenderDetails, bankDetails },
			});
		} else if (singlePolicyLoan) {
			navigate(APP_ROUTES.Policy_Witness_Details, { state: singlePolicyLoan });
			dispatch(getLoanData({ bankDetails }));
		} else if (location.state?.widgetNos) {
			dispatch(getLoanData({ bankDetails }));
			navigate(APP_ROUTES.TRACK_TRANSACTION);
		} else if (surrenderPolicy) {
			navigate(APP_ROUTES.SURRENDER_POLICY, { state: { bankDetails, surrenderPolicy } });
		} else {
			dispatch(getLoanData({ bankDetails }));
			navigate(APP_ROUTES.Lender_Screen, { state: { widgetNo, bankDetails } });
		}

		if (bankChanges?.length !== 0) {
			dispatch(updateBankChangesRequest({ t } as any));
		}
	};

	return (
		<Grid container className="mt-10 ">
			<Grid item xs={8} container>
				<Grid item xs={12}>
					<Typography
						fontSize={18}
						color={theme.palette.primary.main}
						fontWeight={600}
					>
						{bankName || '-'}{' '}
					</Typography>
				</Grid>

				<Grid item xs={12} className="mt-10">
					{verifyBank && (
						<>
							{verifyBank === 'N' ? (
								<LabelWithIcon
									svgClassName=""
									justifyContent="flex-start"
									Icon={ErrorIcon}
									label={selectButton ? 'Verification Failed' : 'Unverified'}
									labelProps={{
										color: '#d9001b',
										fontSize: 18,
										fontWeight: 600,
									}}
								/>
							) : verifyBank === 'Y' ? (
								<LabelWithIcon
									svgClassName="bank-verified"
									justifyContent="flex-start"
									Icon={TickIcon}
									label="Verified"
									labelProps={{
										color: theme.palette.success.dark,
										fontSize: 14,
									}}
								/>
							) : verifyBank === 'P' ? (
								<LabelWithIcon
									svgClassName="bank-verified"
									justifyContent="flex-start"
									Icon={WarningIcon}
									label="Under Verification"
									labelProps={{
										color: '#f99117',
										fontSize: 14,
									}}
								/>
							) : null}
						</>
					)}

					<Typography fontSize={16} color={theme.palette.common.black}>
						{accountHolder}
					</Typography>
					<Typography fontSize={16} color={theme.palette.common.black}>
						{t('ACC')}# {accountNum || '-'}
					</Typography>
					<Typography fontSize={16} color={theme.palette.common.black}>
						{bankName || '-'}
					</Typography>
					<Typography fontSize={16} color={theme.palette.common.black}>
						{branch || '-'}
					</Typography>
					<Typography fontSize={16} color={theme.palette.common.black}>
						{ifscCode || '-'}
					</Typography>
				</Grid>
			</Grid>

			<Grid item xs={3}>
				<Grid item xs={12}>
					<img src={bankLogo} alt="Logo" className="bank_logo" />
				</Grid>
				{bankList === 'true' && (
					<Grid item xs={12}>
						{chequeImage && (
							<img
								src={`data:image/png/jpg/jpeg;base64,${chequeImage}`}
								alt="Canceled Cheque"
								width="51"
								height="32"
							/>
						)}
					</Grid>
				)}{' '}
			</Grid>
			{notSelect && verifyBank && (
				<>
					<Grid item xs={12} className="py-20 mt-10">
						<CustomButton
							text={
								verifyBank === 'N'
									? t('VERIFY_NOW')
									: verifyBank === 'Y'
										? t('TAP_TO_SELECT')
										: verifyBank === 'P'
											? t('TAP_TO_SELECT')
											: null
							}
							variant="text"
							showIcon={false}
							onClick={
								verifyBank === 'N'
									? onVerifyNow
									: verifyBank === 'Y'
										? () => {
											setShowAddModal(true);
										}
										: verifyBank === 'P'
											? () => {
												setShowAddModal(true);
											}
											: null
							}
							color="primary"
							fontSize={18}
						/>
					</Grid>
				</>
			)}
			{selectButton && verifyBank === 'N' && (
				<>
					<Grid item xs={12} className="py-20 mt-10 mr-25">
						<CustomButton
							text={'SELECT A BANK TO PROCEED'}
							variant="text"
							onClick={() => {
								navigate(APP_ROUTES.SELECTBANKDETAILS, {
									state: { widgetNos },
								});
							}}
							color="primary"
							fontSize={18}
							className="tracker-bank-button"
						/>
					</Grid>
				</>
			)}
			<TapToSelectModel
				handleNavigate={handleNavigate}
				icon={bankLogo}
				open={showAddModal}
				setOpen={setShowAddModal}
				accountNum={accountNum}
				bankName={bankName}
				branch={branch}
				ifscCode={ifscCode}
				accountHolder={accountHolder}
			/>
		</Grid>
	);
};

export default BankDetails;
