import React, { useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Timeline, timelineItemClasses } from '@mui/lab';
// import HdfcIcon from '../../../../assets/images/HDFC.png';
import { useDispatch, useSelector } from 'react-redux';
import Separator from '../../../../common/ui/Separator';
import ProductCardPolicy from './ProductCardPolicy';
import SubmitLoanFinance from './SumbitLoanFinance';
import LoanAgainstHeader from './LoanAgainstHeader';
import TrackerTransactionTimeline from '../../LoanModule/TrackTransaction/TrackerTansactionLayout/TrackerTransactionTimeLine';
import '../Policy.scss';
import {
	trackTransactionRequest,
	trackTransactions,
} from '../../LoanModule/Loan.slice';
import { convertAmount, getFormatDate } from '../../../../common/utils';
import { useTranslation } from 'react-i18next';
import BankDetails from '../../LoanModule/BankDetails';

const ApplicationProgress = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const sessionId = localStorage.getItem('sessionId');
	const sessionDate = localStorage.getItem('sessionDate');
	useEffect(() => {
		if (sessionId && sessionDate) {
			const payload: any = {
				sessionDate: sessionDate,
				sessionId: sessionId,
			};
			dispatch(trackTransactionRequest(payload));
		}
	}, [sessionId && sessionDate]);
	const trackTransaction: any = useSelector(trackTransactions);
	const theme = useTheme();



	return (
		<Grid container justifyContent="center" className="mb-50">
			<LoanAgainstHeader />
			<Grid
				item
				className="need-money mt-25"
				xs={12}
				lg={3}
				md={4}
				xl={2.2}
				sm={5}
			>
				<Typography
					className="f-16 fw-600 mb-10"
					color={theme.palette.primary.dark}
				>
					Application Progress
				</Typography>

				{trackTransaction?.Widgets?.map((widget: any) => {

					const data = widget?.Loan?.loanFlag === 'R' ? 'ReAssignmentStatus' : 'AssignmentStatus'

					return (<>
						<Grid className="progress_card">
							<Timeline
								className="timeline"
								sx={{
									[`& .${timelineItemClasses.root}:before`]: {
										flex: 0,
										padding: 0,
									},
								}}
							>
								{widget?.Loan?.[data]?.Customer && (
									<TrackerTransactionTimeline
										status={widget?.Loan?.[data]?.Customer?.status}
										index={0}
										title="Policyholder e-sign"
										eta={widget?.Loan?.[data]?.Customer?.signedDate}
									/>
								)}
								<>
									{widget?.Loan?.[data]?.Witness && (
										<TrackerTransactionTimeline
											status={
												widget?.Loan?.[data]?.Customer?.status === 'Pending'
													? ''
													: widget?.Loan?.[data]?.Witness?.status
											}
											index={1}
											title="Witness e-sign"
											eta={widget?.Loan?.[data]?.Witness?.signedDate}
										/>
									)}
								</>

								{(widget?.Loan?.[data]?.Lender ||
									widget?.Loan?.[data]?.LenderDetails) && (
										<TrackerTransactionTimeline
											status={
												widget?.Loan?.[data]?.Witness?.status === 'Pending'
													? ''
													: widget?.Loan?.[data]?.LenderDetails?.status
											}
											index={2}
											title="Financier e-sign"
											eta={
												widget?.Loan?.[data]?.LenderDetails?.signedDate ||
												widget?.Loan?.[data]?.LenderDetails?.eta
											}
										/>
									)}

								{widget?.Loan?.[data]?.InsurerProcess && (
									<TrackerTransactionTimeline
										status={
											widget?.Loan?.[data]?.LenderDetails?.status ===
												'Pending'
												? ''
												: widget?.Loan?.[data]?.InsurerProcess?.status
										}
										index={3}
										title="Insurer Process"
										eta={
											widget?.Loan?.[data]?.InsurerProcess?.completedDate ||
											widget?.Loan?.[data]?.InsurerProcess?.eta
										}
										isEnd={Object.keys(widget?.Loan?.[data]).length === 4}
									/>
								)}
								{widget?.Loan?.[data]?.FundsDisbursal && (
									<TrackerTransactionTimeline
										status={
											widget?.Loan?.[data]?.InsurerProcess?.status ===
												'Pending'
												? ''
												: widget?.Loan?.[data]?.FundsDisbursal?.status
										}
										index={4}
										title="Fund Disbursal"
										eta={
											widget?.Loan?.[data]?.FundsDisbursal?.completedDate ||
											widget?.Loan?.[data]?.FundsDisbursal?.eta
										}
										isEnd={Object.keys(widget?.Loan?.[data]).length === 5}
									/>
								)}
							</Timeline>
						</Grid>
						<Typography className="f-12 mb-35" color={theme.palette.grey[400]}>
							Terms & Conditions Apply
						</Typography>

						<Grid item>
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('POLICY_DETAILS')}
							</Typography>
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<Grid item>
							<Grid
								container
								flexWrap="nowrap"
								justifyContent="space-between"
								spacing={1}
								className="p-10 "
							>
								<Grid item xs={12}>
									<ProductCardPolicy
										product={widget?.productType}
										lifeAssured={widget?.payerName}
										logoSrc={widget?.insurerLogo}
										maturityValue={widget?.maturityValue}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Typography className="f-14" color={theme.palette.common.black}>
								Loan Details
							</Typography>
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<Grid item>
							<Grid
								container
								flexWrap="nowrap"
								justifyContent="space-between"
								spacing={1}
								className="p-10"
							>
								<Grid item className="mt-4">
									<img
										src={widget?.Loan?.LenderDetails?.lenderLogo}
										alt="logo"
										className="bank_logo"
									/>
								</Grid>
								<Grid item>
									<SubmitLoanFinance
										product={widget?.Loan?.LenderDetails?.lenderName}
										lifeAssured={t('LOAN_AMOUNT')}
										charges={`${t('CHARGES')}:`}
										repaymentOptions={t('SELECT_REPAYMENT_OPTIONS')}
										chargesAmount={convertAmount(
											widget?.Loan?.LenderDetails?.charges
										)}
										rupeeTwo={widget?.Loan?.LenderDetails?.interestPercentage}
										loanAmount={convertAmount(widget?.Loan?.loanAmount)}
										decideLater={widget?.Loan?.LenderDetails?.repaymentOptionDescription}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid pl={2} mt={2}>
							<Grid item xs={12} display="flex" justifyContent="space-between">
								<Typography className="f-14" color={theme.palette.common.black}>
									{t('BANK_DETAILS')}
								</Typography>
							</Grid>
							<Grid item xs={11}>
								<Separator color={theme.palette.grey[300]} />
							</Grid>

							<BankDetails
								verifyBank={widget?.BankDetails?.bankVerified}
								bankName={widget?.BankDetails?.bankName}
								branch={widget?.BankDetails?.bankBranch}
								ifscCode={widget?.BankDetails?.bankIfscCode}
								accountNum={widget?.BankDetails?.bankAcNo}
								bankLogo={widget?.BankDetails?.bankLogo}
								accountHolder={widget?.BankDetails?.AcHolderName}
							// notSelect={widget?.notSelect}
							// selectButton={widgetNo}
							/>
							<Grid />
						</Grid>
						<Grid item className="mt-30">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('WITNESS_DETAILS')}
							</Typography>
							<Separator color={theme.palette.grey[300]} />
						</Grid>

						<Grid item className="mb-25">
							<Grid item className="my-2">
								<Typography
									className="f-14 fw-600"
									color={theme.palette.primary.main}
								>
									{(widget?.Loan?.[data]?.Witness?.witnessFirstName || '-') + ' ' + (widget?.Loan?.[data]?.Witness?.witnessLastName || '-')}
								</Typography>
							</Grid>
							<Grid container spacing={0.5} justifyContent="space-between">
								<Grid item xs={5} flexWrap="nowrap">
									<Typography
										className="f-12"
										color={theme.palette.primary.main}
									>
										{t('PAN')}# {widget?.Loan?.[data]?.Witness?.pan || '-'}
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography
										className="f-12 "
										color={theme.palette.primary.main}
									>
										{widget?.Loan?.[data]?.Witness?.witnessMobileNo || '-'}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={0.5} justifyContent="space-between">
								<Grid item xs={5}>
									<Typography
										className="f-12"
										color={theme.palette.primary.main}
										flexWrap="nowrap"
									>
										DOB:{' '}
										{getFormatDate(
											widget?.Loan?.[data]?.Witness?.witnessDob,
											'YYYY-MM-DD'
										) || '-'}
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography
										className="f-12 "
										color={theme.palette.primary.main}
										style={{ overflowWrap: 'break-word' }}
									>
										{widget?.Loan?.[data]?.Witness?.emailID || '-'}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</>)
				})}
			</Grid>
		</Grid>
	);
};

export default ApplicationProgress;
