import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './VerificationStatusScreen.css';
import { useSelector } from 'react-redux';
// import Checkbox from '../../../common/icons/Check_Verification.svg';
// import Refresh from '../../../common/icons/dashboard/circle_arrow-icon.svg';

import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../common/theme';
import PDFViewer from '../../../common/ui/PDFViewer';
import { selectSaveEkycResponse } from '../auth.slice';

import { POA, POB } from '../../../common/constants/SignUpContents';
import NeedSupport from '../common/NeedSupport';
import { APP_ROUTES, AUTH_ROUTES } from '../../../common/constants/Routes';
import AdhaarIcon from '../../../common/icons/Adhaar_Icon.png';
import liscence from '../../../common/icons/DrivingLicense_icon.svg';
import VoterId_Icon from '../../../common/icons/VoterId_Icon.svg';
import Passport_icon from '../../../common/icons/Passport_icon.svg';
import { log } from 'console';

const VerificationInProgressScreen = () => {
	const location = useLocation();
	const navigate = useNavigate();

	// const eiaData = useMemo(
	//  () => (location?.state?.eiaData ? location?.state?.eiaData : {}),
	//  [location?.state]
	// );
	const [flagovd, setFlagovd] = useState('');
	const [flagovdproof, setFlagovdproof] = useState('');

	const KYCResponse = useSelector(selectSaveEkycResponse)
		? useSelector(selectSaveEkycResponse)
		: location?.state;
	const screenflag = location?.state?.screenflag;

	const { t } = useTranslation();

	useEffect(() => {
		if (KYCResponse?.responseData?.POA_CODE) {
			const data = KYCResponse?.responseData?.POA_CODE.replace(/\//g, '');
			const data1 = KYCResponse?.responseData?.POB_CODE.replace(/\//g, '');

			const dataValue = data;
			const dataValue1 = data1;

			setFlagovd(dataValue);
			setFlagovdproof(dataValue1);
			console.log(dataValue, dataValue1);
		}
		if (KYCResponse?.responseData?.POB_CODE) {
			const data = KYCResponse?.responseData?.POA_CODE.replace(/\//g, '');
			const data1 = KYCResponse?.responseData?.POB_CODE.replace(/\//g, '');
			const dataValue = data;
			const dataValue1 = data1;
			setFlagovd(dataValue);
			setFlagovdproof(dataValue1);
			console.log(dataValue, dataValue1);
		}
	}, []);
	const handleNavigation = () => {
		if (screenflag === 'LOGIN' || screenflag === 'SIGNUP') {
			navigate(-1);
		} else {
			navigate(AUTH_ROUTES.LOGIN);
		}
	};
	return (
		<Grid container justifyContent="center">
			<Grid item xs={3} container>
				<Grid
					item
					xs={12}
					className="my-30"
					display="flex"
					justifyContent="space-between"
				>
					<Typography className="f-14 fw-600"> {t('STEP')} 1/3</Typography>
					<div onClick={handleNavigation} style={{ cursor: 'pointer' }}>
						<Typography
							className="f-14 fw-600"
							color={theme.palette.secondary.main}
						>
							{t('CLOSE')}
						</Typography>
					</div>
				</Grid>
				<Grid item container>
					<Grid item>
						<Typography
							className="f-20 fw-600 "
							color={theme.palette.secondary.main}
						>
							{t('VERIFICATION_INPROGRESS')}
						</Typography>
						<Typography className="f-14  mb-30" color={theme.palette.grey.A700}>
							{t('ESTIMATED_TIME_24_BUSINESS_HOURS')}
						</Typography>

						<>
							<Typography
								className="f-18  mb-15  fw-600"
								color={theme.palette.common.black}
							>
								{KYCResponse?.responseData?.flag === 'TAP_TO_SEE'
									? t('PROOF_OF_BIRTH')
									: t('ADDRESS_PROOF')}
							</Typography>

							{/* <Grid item container className="mb-15">
                            //  <Grid item>
                            //      <img
                            //          src={AdhaarIcon}
                            //          alt="Adhaar logo"
                            //          width={40}
                            //          height={27}
                            //      />
                            //  </Grid>
 
                            //  <Typography
                            //      className="f-18  ml-20"
                            //      color={theme.palette.common.black}
                            //  >
                            //      Aadhaar
                            //  </Typography>
                            // </Grid> */}
							<Grid display="flex" alignItems="center">
								<Grid>
									{KYCResponse?.responseData?.POA_CODE === 'driving' && (
										<img
											src={liscence}
											alt="liscence logo"
											width={39}
											height={26}
											className="mr-10"
										/>
									)}
									{KYCResponse?.responseData?.POA_CODE === 'aadhaar' && (
										<img
											src={AdhaarIcon}
											alt="AdhaarIcon"
											width={39}
											height={26}
											className="mr-10"
										/>
									)}
									{KYCResponse?.responseData?.POA_CODE === 'voterId' && (
										<img
											src={VoterId_Icon}
											alt="VoterId_Icon"
											width={39}
											height={26}
											className="mr-10"
										/>
									)}
									{KYCResponse?.responseData?.POA_CODE === 'passport' && (
										<img
											src={Passport_icon}
											alt="Passport_icon"
											width={39}
											height={26}
											className="mr-10"
										/>
									)}
								</Grid>
								<Grid>
									<Typography
										className="f-18"
										color={theme.palette.common.black}
										textTransform="capitalize"
									>
										{KYCResponse?.responseData?.flag === 'TAP_TO_SEE'
											? flagovd === ''
												? POA[KYCResponse?.responseData?.POA_CODE]
												: POA[flagovd]
											: KYCResponse?.responseData?.POA_CODE === 'aadhaar'
											? 'Aadhaar'
											: KYCResponse?.responseData?.POA_CODE === 'driving'
											? 'Driving License'
											: KYCResponse?.responseData?.POA_CODE === 'passport'
											? 'Passport'
											: KYCResponse?.responseData?.POA_CODE === 'voterId'
											? 'Voter ID'
											: ''}
									</Typography>
								</Grid>
							</Grid>
							{/* <Grid className="image-container mb-20 py-10">
                                    <img
                                        src={Checkbox}
                                        alt="Adhaar logo"
                                        width="274"
                                        height="160"
                                        className="image-1"
                                    />
                                    <img
                                        src={Refresh}
                                        alt="Adhaar logo"
                                        width="39"
                                        height="33"
                                        className="image-2"
                                    />
                                </Grid>
                                <Grid className="image-container mb-20 py-10">
                                    <img
                                        src={Checkbox}
                                        alt="Adhaar logo"
                                        width="274"
                                        height="160"
                                        className="image-1"
                                    />
                                    <img
                                        src={Refresh}
                                        alt="Adhaar logo"
                                        width="39"
                                        height="33"
                                        className="image-2"
                                    />
                                </Grid>
                                 */}
							<div
								style={{
									display: 'flex',
									margin: 0,
									flexDirection: 'column',
								}}
							>
								<PDFViewer
									base64={
										KYCResponse?.responseData?.flag === 'TAP_TO_SEE'
											? KYCResponse?.responseData?.POA_DOCUMENT
											: KYCResponse?.responseData?.PROOF_DOCUMENT
									}
								/>
							</div>
						</>

						{/* <iframe
                            width="300"
                            height="300"
                            src={`data:application/pdf;base64,${eiaData?.PROOF_DOCUMENT}`}
                            title="KYC document"
                        /> */}
					</Grid>
					{KYCResponse?.responseData?.flag === 'TAP_TO_SEE' && (
						<>
							<Grid mt={10}>
								<Typography
									className="f-18  mb-15 fw-600"
									color={theme.palette.common.black}
								>
									{t('ADDRESS_PROOF_OVD')}
								</Typography>
							</Grid>
							<Grid>
								<Typography className="f-18" color={theme.palette.common.black}>
									{KYCResponse?.responseData?.flag === 'TAP_TO_SEE'
										? flagovdproof === ''
											? POB[KYCResponse?.responseData?.POB_CODE]
											: POB[flagovdproof]
										: KYCResponse?.responseData?.POB_CODE}
								</Typography>
								<div
									style={{
										display: 'flex',
										margin: 0,
										flexDirection: 'column',
									}}
								>
									<PDFViewer base64={KYCResponse?.responseData?.POB_DOCUMENT} />
								</div>
							</Grid>
						</>
					)}
				</Grid>
				{/* <Grid mt={10} mb={10} justifyContent="center">
                    <NeedSupport type="black" />
                </Grid> */}
				<Grid mt={10} mb={10} justifyContent="center" xs={12}>
					<NeedSupport type="black" />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default VerificationInProgressScreen;
