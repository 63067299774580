import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';

interface PaymentState {
	paymentDetails: {
		status: LoadingState;
		data: any;
		error: any;
	};
	uploadPaymentData: {
		status: LoadingState;
		data: any;
		error: any;
	};
	policyRenewal: {
		status: LoadingState;
		data: any;
		error: any;
	};

	uploadRenewalPaymentData: {
		status: LoadingState;
		data: any;
		error: any;
	};
}

const initialState: PaymentState = {
	paymentDetails: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	uploadPaymentData: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	policyRenewal: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},

	uploadRenewalPaymentData: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
};

const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		fetchPaymentDetailsRequest: (state) => {
			state.paymentDetails.status = 'loading';
			state.paymentDetails.data = undefined;
			state.paymentDetails.error = undefined;
		},
		paymentDetailsSuccess: (state, { payload }) => {
			state.paymentDetails.status = 'done';
			state.paymentDetails.data = payload;
			state.paymentDetails.error = undefined;
		},
		paymentDetailsError: (state, { payload }) => {
			state.paymentDetails.status = 'error';
			state.paymentDetails.data = undefined;
			state.paymentDetails.error = payload;
		},
		resetPaymentDetailsRequest: (state) => {
			state.paymentDetails.status = 'idle';
			state.paymentDetails.data = undefined;
			state.paymentDetails.error = undefined;
		},
		resetPaymentDetails: (state) => {
			state.paymentDetails.status = 'idle';
			state.paymentDetails.data = undefined;
			state.paymentDetails.error = undefined;
		},

		uploadPaymentDetailsRequest: (state) => {
			state.uploadPaymentData.status = 'loading';
			state.uploadPaymentData.data = undefined;
			state.uploadPaymentData.error = undefined;
		},
		uploadPaymentDetailsSuccess: (state, { payload }) => {
			state.uploadPaymentData.status = 'done';
			state.uploadPaymentData.data = payload;
			state.uploadPaymentData.error = undefined;
		},
		uploadPaymentDetailsError: (state, { payload }) => {
			state.uploadPaymentData.status = 'error';
			state.uploadPaymentData.data = undefined;
			state.uploadPaymentData.error = payload;
		},
		uploadRenewalPaymentDetailsRequest: (state) => {
			state.uploadRenewalPaymentData.status = 'loading';
			state.uploadRenewalPaymentData.data = undefined;
			state.uploadRenewalPaymentData.error = undefined;
		},
		uploadRenewalPaymentDetailsSuccess: (state, { payload }) => {
			state.uploadRenewalPaymentData.status = 'done';
			state.uploadRenewalPaymentData.data = payload;
			state.uploadRenewalPaymentData.error = undefined;
		},
		uploadRenewalPaymentDetailsError: (state, { payload }) => {
			state.uploadRenewalPaymentData.status = 'error';
			state.uploadRenewalPaymentData.data = undefined;
			state.uploadRenewalPaymentData.error = payload;
		},
		resetUploadPaymentDetails: (state) => {
			state.uploadPaymentData.status = 'idle';
			state.uploadPaymentData.data = undefined;
			state.uploadPaymentData.error = undefined;
		},
		policyRenewalRequest: (state) => {
			state.policyRenewal.status = 'loading';
			state.policyRenewal.data = undefined;
			state.policyRenewal.error = undefined;
		},
		policyRenewalSuccess: (state, { payload }) => {
			state.policyRenewal.status = 'done';
			state.policyRenewal.data = payload;
			state.policyRenewal.error = undefined;
		},
		policyRenewalError: (state, { payload }) => {
			state.policyRenewal.status = 'error';
			state.policyRenewal.data = undefined;
			state.policyRenewal.error = payload;
		},
	},
});

export const {
	fetchPaymentDetailsRequest,
	paymentDetailsSuccess,
	paymentDetailsError,
	resetPaymentDetails,
	uploadPaymentDetailsRequest,
	uploadPaymentDetailsSuccess,
	uploadPaymentDetailsError,
	resetUploadPaymentDetails,
	resetPaymentDetailsRequest,
	policyRenewalRequest,
	policyRenewalSuccess,
	policyRenewalError,
	uploadRenewalPaymentDetailsRequest,
	uploadRenewalPaymentDetailsError,
	uploadRenewalPaymentDetailsSuccess,
} = paymentSlice.actions;
export default paymentSlice.reducer;

export const selectIsPaymentDetailsLoading = (state: RootState) =>
	state.payment.paymentDetails.status === 'loading';

export const selectIsPaymentDetailsFailed = (state: RootState) =>
	state.payment.paymentDetails.status === 'error';

export const selectPaymentDetails = (state: RootState) =>
	state.payment.paymentDetails.data;

export const selectIsUploadPaymentDataLoading = (state: RootState) =>
	state.payment.uploadPaymentData.status === 'loading';

export const selectUploadPaymentData = (state: RootState) =>
	state.payment.uploadPaymentData.data;

export const selectIsUploadRenewalPaymentDataLoading = (state: RootState) =>
	state.payment.uploadRenewalPaymentData.status === 'loading';

export const selectUploadRenewalPaymentDataError = (state: RootState) =>
	state.payment.uploadRenewalPaymentData.status === 'error';

export const selectUploadRenewalPaymentData = (state: RootState) =>
	state.payment.uploadRenewalPaymentData.data;
export const selectPolicyRenewalLoading = (state: RootState) =>
	state.payment.policyRenewal.status === 'loading';
export const selectPolicyRenewalError = (state: RootState) =>
	state.payment.policyRenewal.status === 'error';
export const selectPolicyRenewal = (state: RootState) =>
	state.payment.policyRenewal.data;
