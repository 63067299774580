import { Grid, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ReactComponent as rightArrow } from '../../../common/icons/cancel-icon.svg';
import Separator from '../../../common/ui/Separator';
import CustomButton from '../../../common/ui/CustomButton';
// import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import theme from '../../../common/theme';
import './support.scss';
import { APP_ROUTES } from '../../../common/constants/Routes';
import {
	fetchSupportOptions,
	reportIsSubmittingRequest,
	selectIsSubmitSupportUpdating,
	selectIsSupportOptionsLoading,
	selectSupportOptions,
} from '../DashboardScreen/Dashboard.slice';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import NeedSupport from '../../Auth/common/NeedSupport';
import { useLocation } from 'react-router-dom';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const SupportMain = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const {
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		watch,
	} = useForm({
		defaultValues: {
			supportCategoryCode: '',
			description: '',
		},
	});
	const Location = useLocation();
	const submitSupportLoading = useSelector(selectIsSubmitSupportUpdating);
	const supportOptionsLoading = useSelector(selectIsSupportOptionsLoading);
	const supportType = Location.state;
	const supportOptions = useSelector(selectSupportOptions);
	const supportOptionsList: any[] = useMemo(
		() =>
			supportOptions?.data
				? supportOptions.data.map((item: any) => ({
						Description: item.description,
						Code: item.code,
				  }))
				: [],
		[supportOptions]
	);
	const handleOption = (value: any) => {
		setValue('supportCategoryCode', value, {
			shouldValidate: true,
		});
	};
	const handleConfirm = (data: any) => {
		const payload = {
			supportCategoryCode: data.supportCategoryCode,
			issueDescription: data.issueDescription,
			flag: supportType,
			onComplete: (data: any) =>
				navigate(APP_ROUTES.SUPPORT_SUBMITTED, {
					state: { data, supportType },
				}),
		};
		dispatch(reportIsSubmittingRequest(payload as any));
	};

	useEffect(() => {
		if (supportOptions?.isLoading !== 'done') dispatch(fetchSupportOptions());
	}, []);

	const supportCategoryCode = watch('supportCategoryCode');

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Support Screen',
		});
	}, []);

	return (
		<Box marginBottom={5} className="px-20">
			<Grid>
				<Grid container justifyContent="space-between" className="mt-30 px-5">
					<Grid item>
						<Typography className="f-16 fw-600 " color="primary">
							{supportType === 'Support' ? t('SUPPORT') : t('COMPLAINTS')}
						</Typography>
					</Grid>
					<Grid item className="">
						<CustomButton
							text={t('CLOSE')}
							variant="text"
							color="primary"
							showIcon
							fontSize={14}
							onClick={() => {
								navigate(-1);
							}}
							endIcon={rightArrow}
							className="text_case fw-400"
						/>
					</Grid>
				</Grid>
				<Grid item className="mt-20">
					<Separator color="#d7d7d7" borderWidth={1} />
				</Grid>
				<Grid container display="flex" justifyContent="center">
					<Grid className="mt-50" xs={12} sm={12} lg={3} xl={3} md={6}>
						<Typography className="f-18" color="GrayText">
							{t('PLEASE_SELECT_ISSUE')}
						</Typography>
						{/* <CustomSelectBox
							ClassName="w-100 selectbox-width selectbox-border"
							options={supportOptionsList}
							inputProps={register('supportCategoryCode', {
								required: {
									value: true,
									message: 'select the issue',
								},
							})}
							defaultValue={supportCategoryCode}
							setSelectedOption={handleOption}
							label="Select"
							loading={supportOptionsLoading}
						/> */}
						<Controller
							control={control}
							rules={{
								required: {
									value: true,
									message: t('PLEASE_SELECT_ISSUE'),
								},
							}}
							name="supportCategoryCode"
							render={({ field }) => (
								<Select
									options={supportOptionsList}
									isSearchable
									placeholder={t('SELECT_SUPPORT_CATAGORY')}
									value={
										supportOptionsList.find(
											(option) => option.Code === supportCategoryCode
										) || null
									}
									getOptionLabel={(option) => option.Description}
									getOptionValue={(option) => option.Code}
									onChange={(selectedOption) => {
										handleOption(selectedOption?.Code || '');
										field.onChange(selectedOption?.Code || '');
									}}
									onBlur={() => field.onBlur()}
									styles={{
										control: (base) => ({
											...base,
											height: '50px',
											borderColor: '#0051ba',
											'&:hover': {
												borderColor: '#f99117',
											},
										}),
									}}
								/>
							)}
						/>

						{errors.supportCategoryCode ? (
							<Typography color={theme.palette.error.main} fontSize={12}>
								{errors.supportCategoryCode?.message}
							</Typography>
						) : null}
						<Typography className="f-18 mt-40" color="GrayText">
							{t('PLEASE_PROVIDE_DESCRIPTION')}
						</Typography>
						<Grid>
							<Grid>
								<FormInput
									hideCountButton
									multipleLine
									placeholder={`${t('TYPE_HERE')}...`}
									name="issueDescription"
									control={control}
									rules={{
										required: {
											value: true,
											message: t('PLS_ENTER_DESCRIPTION'),
										},
										maxLength: {
											value: 250,
											message: t('PLEASE_ENTER_THE_DESCRIPTION'),
										},
									}}
									inputProps={{
										className: 'px-5 ',
										maxLength: 250,
									}}
									errors={errors}
								/>
								{errors.description ? (
									<Typography color={theme.palette.error.main} fontSize={12}>
										{errors.description?.message}
									</Typography>
								) : null}
							</Grid>
						</Grid>
						<Grid item container justifyContent="flex-end" className="mt-50">
							<CustomButton
								text={t('SUBMIT')}
								variant="text"
								color="primary"
								className="fw-600 f-16"
								onClick={handleSubmit(handleConfirm)}
								loading={submitSupportLoading}
								disabled={supportOptionsLoading}
							/>
						</Grid>
						<Grid mt={10} container justifyContent="flex-start">
							<NeedSupport type="black" page="dashboard" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SupportMain;
