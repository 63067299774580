import React from 'react';
import { ReactComponent as WithdrawalIcon } from '../../../../../../common/icons/LoanModule/loanIcon.svg';
import TrackerCards from '../TrackerCards';
import { convertAmount } from '../../../../../../common/utils';
import { useTranslation } from 'react-i18next';

interface UserProps {
	step?: any;
	widgetNo: any;
	trackTransactionWholeData: any;
}
const StepThree = ({ step, widgetNo, trackTransactionWholeData }: UserProps) => {
	const { t } = useTranslation();

	const data = step?.Loan?.loanFlag === 'R' ? 'ReAssignmentStatus' : 'AssignmentStatus';

	return (
		<>
			<TrackerCards
				step={step}
				trackTransactionWholeData={trackTransactionWholeData}
				header="Loan against Policy"
				head={t('LOAN_AGAINST_POLICY')}
				icon={WithdrawalIcon}
				productLogo={step?.insurerLogo}
				widgetNo={widgetNo}
				productName={step?.planName}
				label1={t('MATURITY_VALUE')}
				label2={t('CURRENT_FUND_VALUE')}
				label6={t('PARTIAL_WITHDRAWAL_OF')}
				label7={t('ELIGIBLE_WITHDRAWAL_VALUE')}
				loan
				selfName={step?.payerName}
				label1Value={convertAmount(step?.maturityValue)}
				label2Value={convertAmount(step?.fundValue)}
				loanof={convertAmount(step?.Loan?.loanAmount)}
				witnessDetails={step?.Loan?.[data]}
			/>
		</>
	);
};
export default StepThree;
