import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
	fetchDashboardPoliciesList,
	fetchMissingInfoList,
	fetchPolciyRejection,
	fetchSupportOptionsList,
	submitSupportQuery,
} from './Dashboard.service';
import {
	dashboardListError,
	dashboardListRequest,
	dashboardListSuccess,
	fetchMissingInfo,
	fetchPolicyRejection,
	fetchSupportOptions,
	missingInfoError,
	missingInfoSuccess,
	policyRejectionError,
	policyRejectionSuccess,
	reportFetchSupportOptionsError,
	reportFetchSupportOptionsSuccess,
	reportIsSubmittingRequest,
	reportSubmitSupportRequestError,
	reportSubmitSupportRequestSuccess,
} from './Dashboard.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { getAnalytics, logEvent } from '@firebase/analytics';

const dest = localStorage.getItem('language');

function* handleFetchDashboardList({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user?.eiaNo,
			},
		};
		const { data }: any = yield* call(
			fetchDashboardPoliciesList,
			Object.keys(payload).length ? payload : requestPayload
		);
		yield* put(dashboardListSuccess(data));
	} catch (e: any) {
		yield* put(dashboardListError(e.message));
	}
}
function* handleUpdateSubmitSupport({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const { supportCategoryCode, issueDescription, onComplete, flag } = payload;
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				supportCategoryCode,
				issueDescription,
				flag
			},
		};
		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Support_Submit_button_click', {
				button_name: 'Support_Submit_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}

		const { data }: any = yield* call(submitSupportQuery, requestPayload);
		if (onComplete) onComplete(data);
		yield* put(reportSubmitSupportRequestSuccess(data));
	} catch (e: any) {
		yield* put(reportSubmitSupportRequestError(e.message));
		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Support_Submit_button_click', {
				button_name: 'Support_Submit_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}
function* handleFetchSupportOptions() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchSupportOptionsList, requestPayload);
		yield* put(reportFetchSupportOptionsSuccess(data?.SupportCategory ?? []));
	} catch (e: any) {
		yield* put(reportFetchSupportOptionsError(e.message));
	}
}

function* handleFetchMissingInfo({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user?.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchMissingInfoList, Object.keys(payload).length ? payload : requestPayload);
		yield* put(missingInfoSuccess(data));
	} catch (e: any) {
		yield* put(missingInfoError(e.message));
	}
}


function* handleFetchPolicyRejection({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user?.eiaNo,
				...payload
			},
		};
		const { data }: any = yield* call(fetchPolciyRejection, requestPayload);
		yield* put(policyRejectionSuccess(data));
		yield* put(fetchMissingInfo({}));
	} catch (e: any) {
		yield* put(policyRejectionError(e.message));
	}
}

function* watchDashboard() {
	yield* takeEvery(dashboardListRequest.type, handleFetchDashboardList);
	yield* takeEvery(reportIsSubmittingRequest.type, handleUpdateSubmitSupport);
	yield* takeEvery(fetchSupportOptions.type, handleFetchSupportOptions);
	yield* takeEvery(fetchMissingInfo.type, handleFetchMissingInfo);
	yield* takeEvery(fetchPolicyRejection.type, handleFetchPolicyRejection);
}

// eslint-disable-next-line import/prefer-default-export
export { watchDashboard };
