import { Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Cancel } from '../../../common/icons/update-mobile/cancel_orange.svg';
import SvgConverter from "../../../common/SvgConverter";


interface PolicyListProps {
    title?: string;
    list?: any;
    insurerCode?: any;
    showRemove?: boolean;
    removePolicyFunc?: any;
}

const PolicyList: React.FC<PolicyListProps> = ({ title, list, insurerCode, showRemove = false, removePolicyFunc }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            {list && list.length > 0 &&
                <Grid item>
                    <Grid item xs={12} md={6} sm={6} className="py-5 mb-10">
                        <Typography
                            className="f-16 fw-600"
                            color={theme.palette.primary.main}
                        >
                            {title}
                        </Typography>
                    </Grid>

                    {list?.map((mapvalue: any, index: any) => (
                        <Grid container className="mb-10" key={index}>
                            <Grid item xs={12}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={1} className="mt-10 ">
                                        <Typography
                                            className="f-14"
                                            color={theme.palette.common.black}
                                        >
                                            {index + 1}.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="mt-10 ">
                                        {mapvalue?.companyLogo && (
                                            <img
                                                src={mapvalue?.companyLogo}
                                                className="policy-image"
                                                alt="logo"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item container xs={showRemove ? 7 : 9} className="mt-5" alignItems='center' style={{ wordWrap: 'break-word' }}>
                                        <Grid item xs={12} >
                                            <Typography
                                                className="f-14 fw-600"
                                                color={theme.palette.common.black}
                                            >
                                                {mapvalue?.companyName}
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography
                                                style={{ wordWrap: 'break-word' }}
                                                className="f-14 fw-400"
                                                color={theme.palette.common.black}
                                            >
                                                {t('POLICY#')}  {mapvalue?.policyNo}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography
                                                className="f-14 fw-400"
                                                color={theme.palette.common.black}
                                            >
                                                {insurerCode == 'G' && mapvalue?.vehicleNo && (mapvalue?.vehicleModel + " " + mapvalue?.vehicleNo)}
                                                {(insurerCode == 'H' || insurerCode == 'L') && mapvalue?.customerName && (t('LIFE_ASSURED1') +  mapvalue?.customerName)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {showRemove && <Grid item container xs={2} className="mt-10 pr-5" alignItems={'start'} justifyContent={'end'} >
                                        <IconButton className="p-0" onClick={() => removePolicyFunc(mapvalue)}>
                                            <SvgConverter Icon={Cancel} />
                                        </IconButton>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            }
        </>
    );

};


export default PolicyList;