import React from 'react';
import { Checkbox, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HdfcIcon from '../../../assets/images/HDFC.png';
import { ReactComponent as OrangeRupee } from '../../../common/icons/medium_rupee_icon.svg';
import NeedMoneyHeader from '../LoanModule/NeedMoneyHeader';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import theme from '../../../common/theme';
import Separator from '../../../common/ui/Separator';
import CustomButton from '../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../common/constants/Routes';

const FinancePremium = () => {
	const { t } = useTranslation();

	return (
		<Grid container display="flex" justifyContent="center">
			<Grid item xs={11} xl={3} lg={3} md={10} sm={10}>
				<Grid container className="mb-40">
					<Grid item xs={12}>
						<NeedMoneyHeader />
					</Grid>
				</Grid>
			</Grid>

			<Grid
				item
				xs={12}
				lg={12}
				xl={11}
				md={7}
				sm={8}
				display="flex"
				justifyContent="center"
			>
				<Grid
					container
					item
					className="my-10"
					xs={11}
					xl={3}
					lg={3}
					md={10}
					sm={10}
				>
					<Grid
						item
						xs={12}
						display="flex"
						justifyContent="center"
						className="mb-20"
					>
						<Typography className="f-18">
							{t('SELECT_UPCOMING_PREMIUM')}
						</Typography>
					</Grid>

					<Grid item xs={5} sm={3} lg={4} md={3} xl={3}>
						<Grid container flexWrap="nowrap" spacing={1} alignItems="center">
							<Grid item className="mt-n10">
								<Checkbox
									sx={{
										color: 'primary.main',
										'&.Mui-checked': {
											color: 'primary',
										},
									}}
								/>
							</Grid>
							<Grid item>
								<img src={HdfcIcon} alt="logo" className="finance_bank_logo" />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} sm={6}>
						<Typography
							className="f-16 fw-600"
							color={theme.palette.primary.dark}
						>
							{t('POLICY_NAME')}
						</Typography>
						<Typography className="f-14 ">{t('ANNUAL_PREMIUM')}</Typography>
						<LabelWithIcon
							svgClassName="mb-2 ml-2 mt-2"
							justifyContent="flex-start"
							Icon={OrangeRupee}
							label="XXX"
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 16,
								fontWeight: 600,
							}}
						/>
						<Typography className="f-14 " color={theme.palette.secondary.main}>
							{t('DUE_ON')}{" "} DDMMYYYY
						</Typography>
					</Grid>
					<Grid item xs={12} className="mt-15">
						<Separator color={theme.palette.grey[300]} />
					</Grid>
				</Grid>
			</Grid>

			<Grid
				item
				xs={10}
				sm={6.5}
				md={5.8}
				lg={3}
				xl={2.6}
				display="flex"
				justifyContent="end"
			>
				<Link to={APP_ROUTES.MY_OPTIONS}>
					<CustomButton
						text={t('SEE_MY_OPTIONS')}
						variant="text"
						iconClassName="right-arrow"
						type="submit"
						color="primary"
						className="f-16 mt-12 fw-600"
					/>
				</Link>
			</Grid>
		</Grid>
	);
};

export default FinancePremium;
