import { Box, Grid, Typography } from '@mui/material';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import { useTranslation } from 'react-i18next';

const RemoveMemberModal = ({
	open,
	setOpen,
	removeMemeber,
	removefamily,
}: any) => {
	const { t } = useTranslation();
	return (
		<CommonModal
			open={open}
			modalClassName="px-0 py-20"
			boxProps={{ width: 395 }}
		>
			<Grid container justifyContent="center">
				<Box
					marginBottom={5}
					width={395}
					display="flex"
					justifyContent="end"
					flexDirection="column"
				>
					<Grid className="p-20" textAlign={'center'}>
						{removefamily?.nomineeFlag === 'N' &&
							removefamily?.rnomineeFlag === 'Y' &&
							removefamily?.authorizedPerson === 'Y' ||
							removefamily?.nomineeFlag === 'Y' &&
							removefamily?.rnomineeFlag === 'N' &&
							removefamily?.authorizedPerson === 'Y' ||
							removefamily?.nomineeFlag === 'Y' &&
							removefamily?.rnomineeFlag === 'Y' &&
							removefamily?.authorizedPerson === 'Y' ? (
							<Typography className="f-16 fw-600"  style={{ textAlign: 'left' }}>
								{
									'Are you sure you want to remove this family member? They are currently listed as your AR, Nominee on one or more of your policies. Would you like to remove them?'
								}
							</Typography>
						) : removefamily?.nomineeFlag === 'N' &&
							removefamily?.rnomineeFlag === 'N' &&
							removefamily?.authorizedPerson === 'Y' ? (
							<Typography className="f-16 fw-600" style={{ textAlign: 'left' }}>
								{
									'Are you sure you want to remove this family member? They are currently listed as your Authorized Representative (AR). Would you like to remove them?'
								}
							</Typography>
						) : removefamily?.nomineeFlag === 'N' &&
							removefamily?.rnomineeFlag === 'Y' &&
							removefamily?.authorizedPerson === 'N' ||
							removefamily?.nomineeFlag === 'Y' &&
							removefamily?.rnomineeFlag === 'N' &&
							removefamily?.authorizedPerson === 'N' ||
							removefamily?.nomineeFlag === 'Y' &&
							removefamily?.rnomineeFlag === 'Y' &&
							removefamily?.authorizedPerson === 'N' ? (
							<Typography className="f-16 fw-600" style={{ textAlign: 'left' }}>
								{
									'Are you sure you want to remove this family member? They are currently listed as a Nominee on one or more of your policies. Would you like to remove them?'
								}
							</Typography>
						) : removefamily?.nomineeFlag === 'N' &&
							removefamily?.rnomineeFlag === 'N' &&
							removefamily?.authorizedPerson === 'N' ? (
							<Typography className="f-16 fw-600">
								{'Do you want to remove the family member?'}
							</Typography>
						) : (
							''
						)}

						<Grid
							className="pt-30"
							container
							xs={12}
							justifyContent={'space-between'}
						>
							<Grid item>
								<CustomButton
									text={t('CANCEL')}
									variant="text"
									color="inherit"
									showIcon={false}
									className="f-16 fw-400"
									onClick={() => {
										setOpen(false);
									}}
								/>
							</Grid>
							<Grid item>
								<CustomButton
									text={t('REMOVE')}
									variant="text"
									type="submit"
									color="primary"
									className="f-16 fw-600"
									onClick={removeMemeber}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</CommonModal>
	);
};

export default RemoveMemberModal;
