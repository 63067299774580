import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as BemaCentral } from '../../../assets/images/BimaLogo.svg';
import SvgConverter from "../../../common/SvgConverter";
import CustomButton from "../../../common/ui/CustomButton";
import { useTranslation } from "react-i18next";
import { APP_ROUTES } from "../../../common/constants/Routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LabelWithIcon from "../../../common/ui/LabelWithIcon";
import { ReactComponent as AssetIcon } from '../../../common/icons/policy/assets-sm.svg';
import { ReactComponent as HealthIcon } from '../../../common/icons/policy/health-sm.svg';
import { ReactComponent as LifeIcon } from '../../../common/icons/policy/life-sm.svg';
import Separator from "../../../common/ui/Separator";
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import { useDispatch } from "react-redux";
import cryptoEncryptionDecryption from "../../../common/crypto";
import { setUser } from "../../Auth/auth.slice";
import { useEffect } from "react";
import { resetExistingPolicies } from "./addPolicy.slice";
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';


const AddPolicyInstructionScreen = () => {

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isFirstTimeLogin = location?.state?.isFirstTimeLogin;
    const handleSkip = () => {
        navigate(APP_ROUTES.DASHBOARD);
    };

    useEffect(() => {
        if (isFirstTimeLogin) {
            const payload: any = {
                Customer: {
                    eiaNo: location?.state?.userData?.eiaNo,
                    pan: location?.state?.userData?.pan,
                    mobileNo: location?.state?.userData?.mobileNo,
                    email: location?.state?.userData?.email,
                    firstTimeLoginFlag: 'N',
                    kycValidFlag: 'Y',
                    arAvailable: 'Y',
                    sessionId: null,
                },
            };
            dispatch(setUser(payload.Customer as any));
            sessionStorage.setItem('userInfo', cryptoEncryptionDecryption.Encrypt(payload));
        }

        dispatch(resetExistingPolicies())
    }, [dispatch])

    useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Add Policy Instruction Screen',
		});
	}, []);



    return (
        <Grid container justifyContent="center" style={{ backgroundColor: 'white' }}>
            <Box width={414} height={900} alignItems={'center'} style={{ backgroundColor: 'white' }}>
                {isFirstTimeLogin && <Grid
                    flexWrap="nowrap"
                    container
                    spacing={1}
                    justifyContent="end"
                    className="mt-10"
                >
                    <Grid item width="auto">
                        <Grid
                            columnSpacing={1}
                            container
                            alignItems="center"
                            onClick={handleSkip}
                            className="cursor-pointer"
                            flexWrap="nowrap"
                        >
                            <Grid item onClick={handleSkip}>
                                <Typography
                                    component="span"
                                    className="f-14"
                                    color={theme.palette.common.black}
                                >
                                    {t('SKIP_FOR_NOW')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <SvgConverter
                                    Icon={ArrowLeftIcon}
                                // className={'go-back-icon d-flex'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                <Grid xs={12} container textAlign={'center'} className="px-50 pt-0">
                    {isFirstTimeLogin && <Grid item xs={12}>
                        <Typography
                            className="f-28 fw-400 mt-10"
                            color={theme.palette.primary.main}
                        >
                            Welcome to
                        </Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <SvgConverter
                            Icon={BemaCentral}
                            width={350}
                            height={100}
                            className=" mb-10"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            className="f-20 fw-600 mt-10"
                            color={theme.palette.primary.main}
                        >
                            {isFirstTimeLogin ?   t('Let\'s setup your portolio.') :   t('POLICY_ADDITION_TOOL')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} container justifyContent={'space-between'} alignItems={'center'} className="mt-40 mb-10">
                        <Grid item>
                            <LabelWithIcon Icon={LifeIcon} label={t('LIFE')} labelProps={{ fontSize: 20, fontWeight: 400, color: theme.palette.primary.main }} /> </Grid>
                        <Grid item>
                            <Typography
                                className="f-20 fw-400"
                                color={theme.palette.secondary.main}
                            >
                                &
                            </Typography> </Grid>
                        <Grid item>
                            <LabelWithIcon Icon={HealthIcon} label={t('HEALTH')} labelProps={{ fontSize: 20, fontWeight: 400, color: theme.palette.primary.main }} /> </Grid>


                    </Grid>

                    <Grid container justifyContent="center" >
                        <Separator color={theme.palette.grey[400]} borderWidth={1} />
                    </Grid>


                    <Grid item xs={12} textAlign={'left'}>
                        <Grid item xs={12} >
                            <Typography
                                className="f-16 fw-600 mt-10"
                                color={theme.palette.primary.main}
                            >
                                {/* Add policies by: */}
                                {t('ADD_POLICIES_BY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className="f-16 fw-600 mt-20"
                            >
                                {/* 1. Uploading a pic or pdf */}
                                {t('UPLOADING_a_PIC')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className="f-14 fw-400"
                            >
                                {/* You would need - pdf of the policy or pics of actual policy paper. */}
                                {t('YOU_WOULD_NEED')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                className="f-16 fw-600 mt-20"
                            >
                                {/* 2. Policy Number */}
                                {t('2POLICY_NUMBER')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className="f-14 fw-400"
                            >
                                {/* You can simply enter the policy number and the insurer name. */}
                                {t('YOU_CAN_SIMPLY')}
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid item xs={9} container justifyContent={'start'} alignItems={'center'} className="mt-40 mb-10">
                        <Grid item>
                            <LabelWithIcon Icon={AssetIcon} label={t('ASSERT(MOTOR)')} labelProps={{ fontSize: 20, fontWeight: 400, color: theme.palette.primary.main }} /> </Grid>
                    </Grid>

                    <Grid container justifyContent="center" >
                        <Separator color={theme.palette.grey[400]} borderWidth={1} />
                    </Grid>

                    <Grid item xs={12} textAlign={'left'}>
                        <Grid item xs={12} >
                            <Typography
                                className="f-16 fw-600 mt-10"
                                color={theme.palette.primary.main}
                            >
                                {/* Add policies by: */}
                                {t('ADD_POLICIES_BY')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>

                            <Typography
                                className="f-16 fw-600 mt-20"
                            >
                                {/* 1. Policy Number */}
                                {t('1POLICY_NUMBER')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className="f-14 fw-400"
                            >
                                {/* You can simply enter the policy number and the insurer name. */}
                                {t('YOU_CAN_SIMPLY')}
                            </Typography>
                        </Grid>

                    </Grid>

                    {/* <Grid item xs={12}>
                        <Typography
                            className="f-28 fw-400 mt-40"
                            color={theme.palette.primary.main}
                        >
                            Step 1
                        </Typography>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <Typography
                            className="f-16 fw-600 "
                            color={theme.palette.primary.main}
                        >
                            Onboarding policies that are with you as a paper document or as a digital document.
                        </Typography>
                    </Grid> */}

                    {/* <Grid item xs={12}>
                        <Typography
                            className="f-16 fw-600 mt-40"
                            color={theme.palette.primary.main}
                        >
                            Available Options
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className="f-16 fw-600 mt-20"
                        >
                            1. Upload a pic or Upload pdf
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className="f-14 fw-400"
                        >
                            You woud need - pdf of policy or pics of actual policy paper.
                        </Typography>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <Typography
                            className="f-16 fw-600 mt-20"
                        >
                            2. Digilocker
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className="f-14 fw-400"
                        >
                            You can use this option if you already have policy pdf in the Digilocker.
                        </Typography>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <Typography
                            className="f-16 fw-600 mt-20"
                        >
                            2. Policy Number
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className="f-14 fw-400"
                        >
                            You can simply enter the policy number and the insurer name.
                        </Typography>
                    </Grid> */}
                    <Grid display="flex" item xs={12} container
                        justifyContent={isFirstTimeLogin ? 'center' : "space-between"}
                        spacing={1}
                        className="mt-40">
                        {!isFirstTimeLogin && <Grid>
                            <CustomButton
                                text={t('CANCEL')}
                                variant="text"
                                color="inherit"
                                className="fw-400 f-18"
                                textAlign="start"
                                showIcon={false}
                                onClick={() => {
                                    navigate(APP_ROUTES.POLICY)
                                }}
                            />
                        </Grid>}
                        <Grid>
                            <Link to={APP_ROUTES.ADD_POLICY_STEP_ONE} state={{ isFirstTimeLogin: isFirstTimeLogin }}>
                                <CustomButton
                                    text={t('PROCEED')}
                                    variant="text"
                                    color="primary"
                                    className="fw-600 f-18"
                                    showIcon
                                    textAlign="end"
                                />
                            </Link>
                        </Grid>
                    </Grid>


                </Grid>
            </Box >
        </Grid >
    )
}

export default AddPolicyInstructionScreen;