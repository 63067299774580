import { Box, Grid, Typography, useTheme } from "@mui/material";
import CustomButton from "../../../common/ui/CustomButton";
import { useTranslation } from "react-i18next";
import Separator from "../../../common/ui/Separator";
import PolicyListCard from "./PolicyListCard";

const PolicyFromIntegratedInsurer = ({ PolicyIntegrated, CheckOTPverifited }: any) => {

    const life = [
        { policyName: "Policy Name Policy Name", policyNo: "12123123123123123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        { policyName: "Policy Name Policy Name", policyNo: "12123121231233123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        { policyName: "Policy Name Policy Name", policyNo: "12123123222123123123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        { policyName: "Policy Name Policy Name", policyNo: "1212312223123123123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" }
    ]
    const motor = [
        { policyName: "Policy Name Policy Name", policyNo: "12123123123123123", Car: "MH 00 XX 1234", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        // { policyName: "Policy Name Policy Name", policyNo: "12123121231233123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        // { policyName: "Policy Name Policy Name", policyNo: "12123123222123123123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        // { policyName: "Policy Name Policy Name", policyNo: "1212312223123123123", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" }
    ]
    const health: any = [
        // { policyName: "Policy Name Policy Name", policyNo: "345345345345", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        // { policyName: "Policy Name Policy Name", policyNo: "678456456456", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        { policyName: "Policy Name Policy Name", policyNo: "834345645656", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" },
        // { policyName: "Policy Name Policy Name", policyNo: "823467567787", lifeAssured: "34234344", insurerLogo: "https://policygenie2.blob.core.windows.net/insurerlogo/icici_lombard_general_ins.jpg" }
    ]

    const list = [{ code: "L", list: life }, { code: "M", list: motor }, { code: "H", list: health }]

    const { t } = useTranslation();
    const theme = useTheme();

    return (<>


        <Grid >
            <Grid className="mb-20 p-20" style={{ backgroundColor: theme.palette.grey[100] }}>
                <PolicyListCard headingName={`${t('INTEGRATED_INSURERS')}:`} policyList={list} />
            </Grid>
            <Grid
                container
                justifyContent="space-between"
                className="mt-40"
                style={{ alignItems: 'center' }}
            >

                <Grid xs={6}>
                    <CustomButton
                        text={t('GO_BACK_ADD_INSURERS')}
                        showIcon={false}
                        color="inherit"
                        className="f-14 fw-400"
                        variant="text"
                        onClick={() => PolicyIntegrated({ "PolicyIntegrated": false })}
                    />
                </Grid>
                <Grid xs={6}>
                    <CustomButton
                        text={t('CONTINUE_TO_NEXT_SEARCH')}
                        variant="text"
                        showIcon={true}
                        className="f-16 fw-600"
                        type="submit"
                        onClick={() => CheckOTPverifited(true)}
                    />
                </Grid>
            </Grid>
        </Grid>



    </>)
}

export default PolicyFromIntegratedInsurer;