import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';

interface UploadSliceProps {
	isLoading: LoadingState;
	errors: any | null;
	familyUpload: { status: LoadingState; familyInfo: any; error: any };
	uploadProfilePhoto: {
		status: LoadingState;
		uploadInfo: any;
		error: any;
	};
	uploadSignaturePhoto: {
		status: LoadingState;
		signInfo: any;
		error: any;
	};
	uploadOtherPhoto: {
		status: LoadingState;
		otherInfo: any;
		error: any;
	};
}

const initialState: UploadSliceProps = {
	isLoading: 'idle',
	errors: null,
	familyUpload: {
		status: 'idle',
		familyInfo: null,
		error: null,
	},
	uploadProfilePhoto: {
		status: 'idle',
		uploadInfo: null,
		error: null,
	},
	uploadSignaturePhoto: {
		status: 'idle',
		signInfo: null,
		error: null,
	},
	uploadOtherPhoto: {
		status: 'idle',
		otherInfo: null,
		error: null,
	},
};

const UploadSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		familyUploadRequest: (state) => {
			state.familyUpload.status = 'loading';
			state.familyUpload.familyInfo = {};
			state.familyUpload.error = null;
		},
		familyUploadSuccess: (state, action) => {
			state.familyUpload.status = 'done';
			state.familyUpload.familyInfo = action.payload;
			state.familyUpload.error = null;
		},
		familyUploadError: (state, action) => {
			state.familyUpload.status = 'error';
			state.familyUpload.familyInfo = {};
			state.familyUpload.error = action.payload;
		},
		uploadProfilePhotoRequest: (state) => {
			state.uploadProfilePhoto.status = 'loading';
			state.uploadProfilePhoto.uploadInfo = {};
			state.uploadProfilePhoto.error = null;
		},
		uploadProfilePhotoSuccess: (state, action) => {
			state.uploadProfilePhoto.status = 'done';
			state.uploadProfilePhoto.uploadInfo = action.payload;
			state.uploadProfilePhoto.error = null;
		},
		uploadProfilePhotoError: (state, action) => {
			state.uploadProfilePhoto.status = 'done';
			state.uploadProfilePhoto.uploadInfo = {};
			state.uploadProfilePhoto.error = action.payload;
		},
		uploadSignaturePhotoRequest: (state) => {
			state.uploadSignaturePhoto.status = 'loading';
			state.uploadSignaturePhoto.signInfo = {};
			state.uploadSignaturePhoto.error = null;
		},
		uploadSignaturePhotoSuccess: (state, action) => {
			state.uploadSignaturePhoto.status = 'done';
			state.uploadSignaturePhoto.signInfo = action.payload;
			state.uploadSignaturePhoto.error = null;
		},
		uploadSignaturePhotoError: (state, action) => {
			state.uploadSignaturePhoto.status = 'done';
			state.uploadSignaturePhoto.signInfo = {};
			state.uploadSignaturePhoto.error = action.payload;
		},
		uploadOtherPhotoRequest: (state) => {
			state.uploadOtherPhoto.status = 'loading';
			state.uploadOtherPhoto.otherInfo = {};
			state.uploadOtherPhoto.error = null;
		},
		uploadOtherPhotoSuccess: (state, action) => {
			state.uploadOtherPhoto.status = 'done';
			state.uploadOtherPhoto.otherInfo = action.payload;
			state.uploadOtherPhoto.error = null;
		},
		uploadOtherPhotoError: (state, action) => {
			state.uploadOtherPhoto.status = 'done';
			state.uploadOtherPhoto.otherInfo = {};
			state.uploadOtherPhoto.error = action.payload;
		},
	},
});

export const familyUploadLoading = (state: RootState) =>
	state.upload.familyUpload.status === 'loading';
export const selectUploadImageIsLoading = (state: RootState) =>
	state.upload.uploadProfilePhoto.status === 'loading';
export const selectSignatureImageIsLoading = (state: RootState) =>
	state.upload.uploadSignaturePhoto.status === 'loading';

export const selectOthersImageIsLoading = (state: RootState) =>
	state.upload.uploadOtherPhoto.status === 'loading';

export const {
	familyUploadRequest,
	familyUploadSuccess,
	familyUploadError,
	uploadProfilePhotoRequest,
	uploadProfilePhotoSuccess,
	uploadProfilePhotoError,
	uploadSignaturePhotoRequest,
	uploadSignaturePhotoSuccess,
	uploadSignaturePhotoError,
	uploadOtherPhotoRequest,
	uploadOtherPhotoSuccess,
	uploadOtherPhotoError,
} = UploadSlice.actions;

export default UploadSlice.reducer;
