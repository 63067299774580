import React, { useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as cancel } from '../../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../../common/icons/LoanModule/right-arrow-icon.svg';
import { ReactComponent as rightArrow } from '../../../../common/icons/right_arrow-icon.svg';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import Separator from '../../../../common/ui/Separator';
// import LinkWithArrow from '../../LoanModule/LinkWithArrow';
import ProductCardPolicy from './ProductCardPolicy';
import SubmitLoanFinance from './SumbitLoanFinance';
import LoanAgainstHeader from './LoanAgainstHeader';
import { convertAmount } from '../../../../common/utils';
import {
	saveSelectedCombinationLoading,
	saveSelectedCombinationRequest,
} from '../../LoanModule/Loan.slice';
import CustomButton from '../../../../common/ui/CustomButton';
import { useTranslation } from 'react-i18next';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import BankDetails from '../../LoanModule/BankDetails';
import ApiModal from '../../../../common/ui/Modal/ApiModal';
import { setAlertInfo } from '../../Common/Common.slice';
// import LinkWithArrow from '../../LoanModule/LinkWithArrow';

const PolicyLoanSubmit = () => {
	const theme = useTheme();
	const location = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const loanDetails = location.state?.updatedLocationState;
	const isRequestLoading = useSelector(saveSelectedCombinationLoading);
	const currentIndex: number = Number(localStorage.getItem('currentIndex'));
	const esignLink = localStorage.getItem('esignLink');
	const eLink =
		esignLink &&
		JSON.parse(cryptoEncryptionDecryption.Decrypt(esignLink) as any);

	const updatedLoanSignDetails = eLink?.LoanSignDetails?.map(
		(detail: any, index: any) => {
			if (index === eLink?.LoanSignDetails.length - 1) {
				return {
					...detail,
					eSignLink: `${detail.eSignLink}&redirect_uri=${window.location.origin}${APP_ROUTES.Application_Progress}`,
				};
			} else {
				return {
					...detail,
					eSignLink: `${detail.eSignLink}&redirect_uri=${window.location.origin}${APP_ROUTES.Policy_Loan_Submit}`,
				};
			}
		}
	);

	const handleSuccess = (data: any) => {
		if (data && Array.isArray(data) && data.length > currentIndex) {
			window.location.href = data[currentIndex]?.eSignLink;
			const updatedIndex: number = currentIndex + 1;
			localStorage.setItem('currentIndex', String(updatedIndex));
		}
	};

	const locations =
		window.location.href ===
		`${window.location.origin}${APP_ROUTES.SUBMIT_LOAN_APPLICATION}`;

	useEffect(() => {
		if (eLink?.LoanSignDetails) {
			handleSuccess(updatedLoanSignDetails);
		} else if (eLink !== null && !eLink?.LoanSignDetails) {
			const alertInfo: any = {
				open: true,
				type: 'ERROR',
				description: `${eLink?.errorDescription}`,
				errorCode: '2205',
			};

			dispatch(setAlertInfo(alertInfo));
		}
	}, [eLink, locations]);
	const handleAddClick = (Combination: any) => {
		const payload: any = {
			modifiedData: Combination,
			requiredAmount: location.state?.requiredAmount,
			requiredDate: location.state?.requiredDate,
			redirectUrl: `${window.location.origin}${APP_ROUTES.Application_Progress}`,
			type: 'Policy',
		};

		dispatch(saveSelectedCombinationRequest(payload));
	};
	useEffect(() => {
		if (eLink?.LoanSignDetails) {
			handleSuccess(updatedLoanSignDetails);
		}
	}, [eLink]);

	return (
		<Grid container justifyContent="center">
			<LoanAgainstHeader />
			<Grid item className="need-money mt-25">
				<Grid item className="mb-10">
					<Typography
						component="span"
						className="f-14 fw-600"
						color={theme.palette.common.black}
					>
						{t('STEP')} 3
						<Typography
							component="span"
							className="f-14 fw-400"
							color={theme.palette.common.black}
						>
							/3
						</Typography>
					</Typography>
					<Typography
						className="f-16 fw-600"
						color={theme.palette.primary.main}
					>
						Submit Loan Application
					</Typography>
				</Grid>
				<Grid item className="mb-5">
					<Grid
						container
						spacing={2}
						justifyContent="space-between"
						className="mb-20 mt-2"
					>
						<Grid item>
							<Link to={APP_ROUTES.Policy_Witness_Details}>
								<LabelWithIcon
									className="f-14 fw-600"
									justifyContent="flex-start"
									Icon={correct}
									label={t('BACK')}
									labelProps={{
										fontSize: 14,
										color: 'primary',
										fontWeight: 600,
									}}
									svgClassName="submit-loan"
								/>
							</Link>
						</Grid>
						<Grid item>
							<Link to={APP_ROUTES.Get_Loan_Against_Policy}>
								<LabelWithIcon
									className="f-14"
									justifyContent="flex-start"
									Icon={cancel}
									label={t('CANCEL')}
									labelProps={{ fontSize: 14, color: 'primary' }}
								/>
							</Link>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography className="f-14" color={theme.palette.common.black}>
						{t('POLICY_DETAILS')}
					</Typography>
					<Separator color={theme.palette.grey[300]} />
				</Grid>
				{loanDetails?.Widgets.map((widget: any) => (
					<>
						{' '}
						<Grid item>
							<Grid
								container
								flexWrap="nowrap"
								justifyContent="space-between"
								spacing={1}
								className="p-10 "
							>
								<Grid item xs={12}>
									<ProductCardPolicy
										product={widget?.productType}
										lifeAssured={widget?.payerName}
										logoSrc={widget?.insurerLogo}
										maturityValue={widget?.maturityValue}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Typography className="f-14" color={theme.palette.common.black}>
								Loan Details
							</Typography>
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<Grid item>
							<Grid
								container
								flexWrap="nowrap"
								justifyContent="space-between"
								spacing={1}
								className="p-10"
							>
								<Grid item className="mt-4">
									<img
										src={widget?.Loan?.LenderDetails?.lenderLogo}
										alt="logo"
										className="bank_logo"
									/>
								</Grid>
								<Grid item>
									<SubmitLoanFinance
										product={widget?.Loan?.LenderDetails?.lenderName}
										loanAmount={convertAmount(
											widget?.Loan?.LenderDetails?.loanAmount
										)}
										lifeAssured={t('LOAN_AMOUNT')}
										charges={`${t('CHARGES')}:`}
										repaymentOptions={t('SELECT_REPAYMENT_OPTIONS')}
										chargesAmount={convertAmount(
											widget?.Loan?.LenderDetails?.charges
										)}
										rupeeTwo={widget?.Loan?.LenderDetails?.interestPercentage}
										decideLater={
											widget?.Loan?.LenderDetails?.repaymentOptionDescription
										}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item className="mt-30">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('BANK_DETAILS')}
							</Typography>
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<Grid item className="mb-25">
							<BankDetails
								bankName={widget?.BankDetails?.bankName}
								branch={widget?.BankDetails?.bankBranch}
								ifscCode={widget?.BankDetails?.bankIfscCode}
								accountNum={widget?.BankDetails?.bankAcNo}
								bankLogo={widget?.BankDetails?.bankLogo}
								accountHolder={widget?.BankDetails?.AcHolderName}
								notSelect={false}
							/>
						</Grid>
						<Grid item className="mt-30">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('WITNESS_DETAILS')}
							</Typography>
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<Grid item className="mb-25">
							<Grid item className="my-2">
								<Typography
									className="f-14 fw-600"
									color={theme.palette.primary.main}
								>
									{widget?.Loan?.Witness?.witnessFirstName || ''} {" "} {widget?.Loan?.Witness?.witnessLastName || ''}
								</Typography>
							</Grid>
							<Grid container spacing={0.5} justifyContent="space-between">
								<Grid item xs={5} flexWrap="nowrap">
									<Typography
										className="f-12"
										color={theme.palette.primary.main}
									>
										{t('PAN')}# {widget?.Loan?.Witness?.witnessPan}
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography
										className="f-12 "
										color={theme.palette.primary.main}
									>
										{widget?.Loan?.Witness?.witnessMobileNo}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={0.5} justifyContent="space-between">
								<Grid item xs={5}>
									<Typography
										className="f-12"
										color={theme.palette.primary.main}
										flexWrap="nowrap"
									>
										DOB: {widget?.Loan?.Witness?.witnessDOB}
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography
										className="f-12 "
										color={theme.palette.primary.main}
										style={{ overflowWrap: 'break-word' }}
									>
										{widget?.Loan?.Witness?.witnessEmailId}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</>
				))}
				<Grid item className="mb-15 mt-10">
					<Separator color={theme.palette.grey[300]} />
				</Grid>
				<Grid container spacing={1} justifyContent="flex-end">
					<CustomButton
						fontSize={16}
						fontWeight={600}
						text={t('E_SIGN_THE_APPLICATION')}
						showIcon
						loading={isRequestLoading}
						endIcon={rightArrow}
						variant="text"
						color="primary"
						onClick={() => {
							handleAddClick(loanDetails);
						}}
					/>
				</Grid>
				<Grid item className="mb-10 mt-10">
					<Separator color={theme.palette.grey[300]} />
				</Grid>
				{/* <LinkWithArrow
					to={APP_ROUTES.Application_Progress}
					text="E-SIGN THE APPLICATION"
					Icon={rightArrow}
					
				/> */}
			</Grid>
			<ApiModal />
		</Grid>
	);
};

export default PolicyLoanSubmit;
