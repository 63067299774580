import { axiosAuthInstance, axiosInstance } from '../../common/axiosInstance';
import ROUTES from '../../common/routes';

const axios = axiosInstance;
const axiosAuth = axiosAuthInstance;

const {
	loginRequest,
	accessTokenRequest,
	resendOtpRequest,
	verifyOtpRequest,
	setMpinRequest,
	verifyMpinRequest,
	signUp,
	initiateEkyc,
	saveEkycData,
	saveARData,
	copyEIAData,
	sendCommunication,
	logout,
	SavePortingDetails
} = ROUTES;

// TODO: uncomment when working on api
const getAccessToken = () =>
	axiosAuth.request({
		...accessTokenRequest,
		data: {
			// username: process.env.REACT_APP_OAUTH_USERNAME_NEW,
			// password: process.env.REACT_APP_OAUTH_PASSWORD_NEW,
			grant_type: process.env.REACT_APP_OAUTH_GRAND_TYPE,
		},
	});

const submitLoginRequestInfo = (data: any) =>
	axios.request({ ...loginRequest, data });
const logoutUser = (data: any) => axios.request({
	...logout
	, data
});

const fetchResendOtpRequest = (data: any) =>
	axios.request({ ...resendOtpRequest, data });

const fetchOtpVerifyRequest = (data: any) =>
	axios.request({ ...verifyOtpRequest, data });

const fetchSetMpinRequest = (data: any) =>
	axios.request({ ...setMpinRequest, data });

const fetchVerifyMpinRequest = (data: any) =>
	axios.request({ ...verifyMpinRequest, data });

const fetchSignUpRequest = (data: any) => axios.request({ ...signUp, data });
const initiateEkycRequest = (data: any) =>
	axios.request({ ...initiateEkyc, data });

const saveEkycRequest = (data: any) => axios.request({ ...saveEkycData, data });
const saveARRequest = (data: any) => axios.request({ ...saveARData, data });

const copyEIADataService = (data: any) =>
	axios.request({ ...copyEIAData, data });

const sendCommunicationService = (data: any) =>
	axios.request({ ...sendCommunication, data });

const fetchSavePortingDetails = (data: any) =>
	axios.request({ ...SavePortingDetails, data });


export {
	getAccessToken,
	submitLoginRequestInfo,
	fetchResendOtpRequest,
	fetchOtpVerifyRequest,
	fetchSetMpinRequest,
	fetchVerifyMpinRequest,
	fetchSignUpRequest,
	initiateEkycRequest,
	saveEkycRequest,
	saveARRequest,
	copyEIADataService,
	sendCommunicationService,
	logoutUser,
	fetchSavePortingDetails
};
