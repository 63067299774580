import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DashboardCardLayout from './common/DashboardCardLayout';
import PolicyCalendarCard from './common/PolicyCalendarCard';
import Spinner from '../../../common/ui/Spinner';

interface UserProps {
	calendarList?: any;
	calendarLoading?: any;
}
const PoliciesByCalendar: React.FC<UserProps> = ({
	calendarList,
	calendarLoading,
}) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [oldMonth, setmonthyearold] = useState('');
	const [newMonth, setmonthyearnew] = useState('');
	useEffect(() => {
		handleYear()
	}, []);

	const handleYear = () => {
		function addMonths(months: any) {
			let date = new Date()
			if (months == -6) {
				date.setMonth(date.getMonth() - 6);
				const month = date.toLocaleString('en-EN', { month: 'short' });
				const yaer = date.getFullYear();
				const m = month + " " + yaer;
				setmonthyearold(m)
			} else {
				date.setMonth(date.getMonth() + 6);
				const months = date.toLocaleString('en-EN', { month: 'short' });
				const yaers = date.getFullYear();
				const m = months + " " + yaers;
				setmonthyearnew(m)
			}
		}
		addMonths(-6);
		addMonths(+6);
	};
	return (
		<DashboardCardLayout>
			<div className="w-280px cal-res">
				<Typography
					fontSize={16}
					color="primary"
					fontWeight={600}
					className="mb-10"
				> 
				
				{t('CALENDER')} | {oldMonth} - {newMonth}
				
				{/* Calendar | {oldMonth} - {newMonth} */}
					{/* {t('YEAR_2023_CALENDAR')} */}
				</Typography>
				{calendarLoading ? (
					<Grid item display="flex" justifyContent="center">
						<Spinner />
					</Grid>
				) : (
					<div
						className="policy-calender-container"
						style={{ border: `1px solid ${theme.palette.grey[400]}` }}
					>
						{calendarList &&
							calendarList.map((data: any, key: any) => (
								<PolicyCalendarCard
									key={key}
									data={data}
									isLastItem={calendarList.length === key + 1}
								/>
							))}
					</div>
				)}
			</div>
		</DashboardCardLayout>
	);
};

export default PoliciesByCalendar;
