/* eslint-disable no-console */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react';
import { Camera, CameraType } from 'react-camera-pro';
import styled from 'styled-components';
import CommonModal from './CommonModal';

const Control = styled.div`
	position: fixed;
	display: flex;
	right: 0;
	width: 20%;
	min-width: 130px;
	min-height: 130px;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px;
	box-sizing: border-box;
	flex-direction: column-reverse;
	@media (max-aspect-ratio: 1/1) {
		flex-direction: row;
		bottom: 0;
		width: 100%;
		height: 20%;
	}
	@media (max-width: 400px) {
		padding: 10px;
	}
`;

const Button = styled.button`
	outline: none;
	color: white;
	opacity: 1;
	background: transparent;
	background-color: transparent;
	background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-image: none;
	padding: 0;
	text-shadow: 0px 0px 4px black;
	background-position: center center;
	background-repeat: no-repeat;
	pointer-events: auto;
	cursor: pointer;
	z-index: 2;
	filter: invert(100%);
	border: none;
	&:hover {
		opacity: 0.7;
	}
`;

const TakePhotoButton = styled(Button)`
	background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
	background-position: center;
	background-size: 50px;
	background-repeat: no-repeat;
	width: 80px;
	height: 80px;
	border: solid 4px black;
	border-radius: 50%;
	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
	}
`;

const CancelButton = styled(Button)`
	background: url('https://img.icons8.com/ios/50/000000/cancel.png');
	background-position: center;
	background-size: 50px;
	background-repeat: no-repeat;
	width: 80px;
	height: 80px;
`;

const ChangeFacingCameraButton = styled(Button)`
	background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
	background-position: center;
	background-size: 40px;
	background-repeat: no-repeat;
	width: 40px;
	height: 40px;
	padding: 40px;
	&:disabled {
		opacity: 0;
		cursor: default;
		padding: 60px;
	}
	@media (max-width: 400px) {
		padding: 40px 5px;
		&:disabled {
			padding: 40px 25px;
		}
	}
`;

const WebCamModal = ({ open, setOpen, onCapture }: any) => {
	const camera = useRef<CameraType>(null);
	const [numberOfCameras, setNumberOfCameras] = useState(0);
	const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
		undefined
	);
	const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
	useEffect(() => {
		(async () => {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const videoDevices = devices.filter((i) => i.kind == 'videoinput');
			setDevices(videoDevices);
		})();
	});
	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName="py-20 vw-70 vh-90 overflow-hidden"
		>
			<Camera
				ref={camera}
				aspectRatio="cover"
				numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
				videoSourceDeviceId={activeDeviceId}
				errorMessages={{
					noCameraAccessible:
						'No camera device accessible. Please connect your camera or try a different browser.',
					permissionDenied:
						'Permission denied. Please refresh and give camera permission.',
					switchCamera:
						'It is not possible to switch camera to different one because there is only one video device accessible.',
					canvas: 'Canvas is not supported.',
				}}
				videoReadyCallback={() => {
				}}
			/>
			<Control>
				<select
					onChange={(event) => {
						setActiveDeviceId(event.target.value);
					}}
				>
					{devices.map((d) => (
						<option key={d.deviceId} value={d.deviceId}>
							{d.label}
						</option>
					))}
				</select>
				{/*  Commenting for future purpose */}
				{/* <ImagePreview
          image={image}
          onClick={() => {
            setShowImage(!showImage);
          }}
        /> */}

				<CancelButton onClick={() => setOpen(false)} />

				<TakePhotoButton
					onClick={() => {
						if (camera.current) {
							onCapture(camera.current.takePhoto());
						}
					}}
				/>

				<ChangeFacingCameraButton
					disabled={numberOfCameras <= 1}
					onClick={() => {
						if (camera.current) {
							// eslint-disable-next-line no-unused-vars
							const result = camera.current.switchCamera();
							// console.log(result);
						}
					}}
				/>
			</Control>
		</CommonModal>
	);
};

export default WebCamModal;
