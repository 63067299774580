import React, { useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import DatePickerModal from '../../../common/ui/DatePickerModal';
import './LoanModule.scss';
import NeedMoneyHeader from './NeedMoneyHeader';
import CustomButton from '../../../common/ui/CustomButton';
// import data from '../../../common/constants/FormConstants.json';
import { parseMobileNo } from '../../../common/utils';
import { ReactComponent as RupeeIcon } from '../../../common/icons/Big-orange-rupee.svg';
import {
	payoutRequest,
	selectMyOptionIsLoading,
	payoutDetailsLoading,
} from './Loan.slice';
import Spinner from '../../../common/ui/Spinner';
import SvgConverter from '../../../common/SvgConverter';
import SelectNeedMoney from '../SurrenderModule/SelectNeedMoney';

const NeedMoney = () => {
	const theme = useTheme();
	const Location = useLocation();
	const dispatch = useDispatch();
	const loanIsLoading = useSelector(selectMyOptionIsLoading);
	const loading = useSelector(payoutDetailsLoading);
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		control,
	} = useForm();
	const navigate = useNavigate();
	const handleDateChange = (date: any) => {
		setValue('dob', date, { shouldValidate: true });
	};

	const DataMoneyType = Location.state;
	console.log(DataMoneyType);
	const removeLocalStorageValues = () => {
		const keysToRemove = [
			'lenderdata',
			'sessionId',
			'currentIndex',
			'esignLink',
			'sessionDate',
		];
		keysToRemove.forEach((key: any) => {
			localStorage.removeItem(key);
		});
	};
	const onSubmit = async (data: any) => {
		let needMoney;
		let formattedDate;
		if (DataMoneyType?.alternateCode === "G") {
			const dob = DataMoneyType.premiumDueDate.toString();
			formattedDate = dayjs(dob).format('DD-MM-YYYY');
			needMoney = parseInt(DataMoneyType.premiumAmount);
		} else {
			const dob = data.dob.toString();
			formattedDate = dayjs(dob).format('DD-MM-YYYY');
			needMoney = data?.money;
		}

		const payload: any = {
			requiredAmount: needMoney,
			requiredDate: formattedDate,
			navigate,
		};
		removeLocalStorageValues();
		dispatch(payoutRequest(payload));
	};
	const money = watch('money');
	// const mobileCountryCode = watch('mobileCountryCode');
	const moneyValueRegex = /^[0-9]{1,20}$/;
	const dobValue = watch('dob');
	useEffect(() => {
		if (money) {
			setValue('money', parseMobileNo(money));
		}
	}, [money]);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const datePicker = (e: any) => {
		setAnchorEl(e.currentTarget);
	};
	const items = {
		policyNo: '00431784',
		companyName: 'ICICI Prudential Life Insurance',
		planName: 'ICICI PRUDENTIAL LIFE',
		companyLogo:
			'https://policygenie2.blob.core.windows.net/insurerlogo/icici_prudential_life_ins.png',
	};
	return (
		<>
			{loanIsLoading || loading ? (
				<Grid container justifyContent="center" alignItems="center">
					<Spinner />
				</Grid>
			) : (

				<Grid
					container
					// className=' mt-30'
					justifyContent="center"
					alignItems="center"
				>
					{DataMoneyType?.alternateCode === "G" ? (
						<>
							<Grid container justifyContent="center"
								alignItems="center">
								<form onSubmit={handleSubmit(onSubmit)}>
									<Box
										marginBottom={5}
										width={414}
										display="flex"
										justifyContent="end"
										flexDirection="column"
									>
										<NeedMoneyHeader />
										<Grid item>
											<Typography className="f-18 fw-400 mt-30 " color="black">
												{t('SELECT_THE_UPCOMING')}
											</Typography>
										</Grid>
										<Grid container item xs={12}>
											<Grid item xs={3} className="ml-6 mt-30">
												<img src={items.companyLogo} className="bank-image" alt="bank" />
											</Grid>
											<Grid item xs={8} className="ml-6 mt-30">
												<Typography
													className="ml-0 f-16 fw-600 mb-0"
													color={theme.palette.primary.main}
												>
													{items.planName.length === 0 ? '-' : items.planName}
												</Typography>
												<Typography color="black" className="ml-10 f-14 fw-400 ">
													{t('ANNUAL_PREMIUM')}

													<Grid display="flex">
														<Grid className="ml-0 f-20 mt-15">
															<SvgConverter Icon={RupeeIcon} />
														</Grid>
														<Grid>
															<Typography
																color={theme.palette.primary.main}
																className="ml-10 mt-8 f-20 fw-600 align-items-center"
															>
																{t('10000')}
															</Typography>
														</Grid>
													</Grid>
												</Typography>
											</Grid>
										</Grid>
										<Grid item className="mt-35" display="flex" justifyContent="end">
											<CustomButton
												className="f-16 fw-600 "
												text={t('SEE_MY_OPTIONS')}
												variant="text"
												color="primary"
												type="submit"
											/>
										</Grid>
									</Box>
								</form>
							</Grid>
						</>
					) : (
						<form onSubmit={handleSubmit(onSubmit)}>
							<Grid item className="mb-50">
								<NeedMoneyHeader />
							</Grid>
							<Grid
								container
								// className=' mt-30'
								justifyContent="center"
								alignItems="center"
							>
								<Grid item className="need-money">
									<Grid item className="mb-5">
										<Typography className="f-18" color={theme.palette.common.black}>
											{t('HOW_MUCH_MONEY_DO_YOU_NEED')}?
										</Typography>
									</Grid>
									<Grid item className="mb-12">
										<FormInput
											name="money"
											placeholder={`${t('TYPE_HERE')}....`}
											hideCountButton
											className="form-input"
											control={control}
											rules={{
												required: `${t('MONEY_IS_MANDATORY')}`,

												pattern: {
													value: moneyValueRegex,
													message: `${t('INVALID AMOUNT')}`,
												},
												min: {
													value: 1,
													message: `${t('AMOUNT_must_BE_GRETER_THAN')} 0`,
												},
											}}
											errors={errors}
										/>
									</Grid>
									<Grid container item flexDirection="column">
										<Grid item className="my-13">
											<Typography
												className="f-18"
												color={theme.palette.common.black}
											>
												{t('BY_WHEN_DO_YOU_NEED_THE_MONEY')}?
											</Typography>
										</Grid>
										<Grid
											item
											container
											flexDirection="row"
											spacing={2}
											alignItems="center"
											justifyContent="space-between"
											flexWrap="nowrap"
										>
											<Grid item sm={3}>
												<FormInput
													inputProps={{
														style: { textAlign: 'center' },
														value: dobValue ? dobValue.format('DD') : '',
													}}
													placeholder="DD"
													className="form-input-day"
													onClick={datePicker}
												// onFocus={datePicker}
												/>
											</Grid>
											<Grid item sm={3}>
												<FormInput
													inputProps={{
														style: { textAlign: 'center' },
														value: dobValue ? dobValue.format('MM') : '',
													}}
													placeholder="MM"
													className="form-input-day"
													onClick={datePicker}
												// onFocus={datePicker}
												/>
											</Grid>
											<Grid item sm={4}>
												<FormInput
													inputProps={{
														style: { textAlign: 'center' },
														value: dobValue ? dobValue.format('YYYY') : '',
													}}
													placeholder="YYYY"
													className="form-input-year"
													onClick={datePicker}
												// onFocus={datePicker}
												/>
											</Grid>
											<Grid item>
												<DatePickerModal
													iconType="primary"
													disablePast
													disableToday
													onChange={handleDateChange}
													modalOpen={anchorEl}
													setModalOpen={setAnchorEl}
												/>
											</Grid>
										</Grid>
										<FormInput
											hidden
											name="dob"
											inputProps={{
												...register('dob', {
													required: {
														value: true,
														message: `${t('DATE_is_MANDATORY')}`,
													},
												}),
											}}
											errors={errors}
										/>
									</Grid>
									<Grid item className="mt-35" display="flex" justifyContent="end">
										<CustomButton
											className="f-16 fw-600 "
											text={t('SEE_MY_OPTIONS')}
											variant="text"
											color="primary"
											type="submit"
										/>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)}
				</Grid>
			)}
		</>
	);
};

export default NeedMoney;
