import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function range(count: any) {
	return Array(count)
		.fill(0)
		.map((_, idx) => idx);
}

const PDFViewer = ({ base64, width = 320 }: any) => {
	const [numPages, setNumPages] = React.useState<any>(0);
	const onDocumentLoadSuccess = (doc: any) => {
		setNumPages(doc.numPages);
	};

	return (
		<div className="pdf-viewer-container">
			<Document
				file={`data:application/pdf;base64,${base64}`}
				// eslint-disable-next-line no-console
				onLoadError={console.error}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				{range(numPages).map((pg) => (
					<>
						{pg !== numPages && <div style={{ margin: '1em' }} />}
						<Page pageNumber={pg + 1} width={width} />
					</>
				))}
			</Document>
		</div>
	);
};

export default PDFViewer;
