import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider, Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DashboardCardLayout from './common/DashboardCardLayout';
import PolicyCardHeader from './common/PolicyCardHeader';
import PolicyCardCost from './common/PolicyCardCost';
import LifeProgressBarWithValues from '../../../common/ui/LifeProgressBarWithValues';
import { convertAmount, getFormatDate } from '../../../common/utils';
import './Dashboard.scss';
import LifeProgressBarNon from '../../../common/ui/LifeProgressBarNon';
import Spinner from '../../../common/ui/Spinner';

interface UserProps {
	lifeDetails?: any;
	dashboardLoading?: any;
}
const LifeScreen: React.FC<UserProps> = ({ lifeDetails, dashboardLoading }) => {
	const theme = useTheme();

	// eslint-disable-next-line no-unsafe-optional-chaining
	const total =
		(parseInt(lifeDetails?.savings?.fundValueAmount) ?? 0) +
		(parseInt(lifeDetails?.savings?.surrenderValueAmount) ?? 0);
	const progressVal =
		(parseInt(lifeDetails?.savings?.guaranteedCurrentValue) ?? 1) /
		(parseInt(lifeDetails?.savings?.totalMaturityValueAmount) ?? 1); // Use 1 as the default value to avoid division by zero.
	const projectedProgressValue =
		(parseInt(lifeDetails?.savings?.nonGuaranteedCurrentValue) ?? 1) /
		(parseInt(lifeDetails?.savings?.maximumProjectedMaturityAmount) ?? 1);

	const calculatePolicyCounts = (counts: any) => {
		switch (counts.length) {
			case 0:
				return 0;
			default:
				return counts;
		}
	};
	const { t } = useTranslation();
	return (
		<DashboardCardLayout>
			<div className='mb-30 life-res'>
				<PolicyCardHeader type='life' />
			</div>

			<Grid>
				<Typography
					fontWeight={600}
					className='mb-15'
					fontSize={14}
					color={theme.palette.common.black}
				>
					{t('PROTECTION')}
				</Typography>
			</Grid>

			<Grid
				container
				flexDirection='row'
				spacing={3}
				justifyContent='space-between'
			>
				<Grid item className="mb-20">
					<div className="w-280px">
						{lifeDetails?.lifeCover.map((mapData: any, index: any) => <PolicyCardCost
							title={index === 0 ? "CURRENT_TOTAL_LIFE_COVER" : ""}
							value={
								mapData?.totalLifeCoverAmount
									? mapData?.totalLifeCoverAmount
									: 0
							}
							policyCounts={mapData?.countLifeCover}
							type="life"
							policyDetails={mapData}
						/>)}
					</div>
				</Grid>
				{/* {lifeDetails?.totalAccidentCoverAmount && ( */}
				<Grid item className="mb-20">
					<div className="w-280px">
						{lifeDetails?.lifeCover.map((mapData: any, index: any) => <PolicyCardCost
							title={index === 0 ? "CURRENT_TOTAL_ACCIDENT_COVER" : ""}
							value={
								mapData?.totalAccidentCoverAmount
									? mapData?.totalAccidentCoverAmount
									: 0
							}
							policyCounts={mapData?.countAccidentCover}
							type="life"
							policyDetails={mapData}
						/>)}
						{/* <PolicyCardCost
								title="CURRENT_TOTAL_ACCIDENT_COVER"
								value={
									lifeDetails?.totalAccidentCoverAmount
										? convertAmount(lifeDetails?.totalAccidentCoverAmount)
										: '0'
								}
								policyCounts={lifeDetails?.countAccidentCover}
								type="life"
							/> */}
					</div>
				</Grid>
				{/* )} */}
				{/* {lifeDetails?.totalDisabilityCoverAmount && ( */}
				<Grid item className="mb-20">
					<div className="w-280px">
						{lifeDetails?.lifeCover.map((mapData: any, index: any) => <PolicyCardCost
							title={index === 0 ? "CURRENT_TOTAL_DISABILITY_COVER" : ""}
							value={
								mapData?.totalDisabilityCoverAmount
									? mapData?.totalDisabilityCoverAmount
									: 0
							}
							policyCounts={mapData?.countDisabilityCover}
							type="life"
							policyDetails={mapData}
						/>)}
						{/* <PolicyCardCost
								title={t("CURRENT_TOTAL_DISABILITY_COVER")}
								value={
									lifeDetails?.totalDisabilityCoverAmount
										? convertAmount(lifeDetails?.totalDisabilityCoverAmount)
										: '0'
								}
								policyCounts={lifeDetails?.countDisabilityCover}
								type="life"
							/> */}
					</div>
				</Grid>
				{/* )} */}
			</Grid>

			<Grid
				className='progress-res'
				container
				flexDirection='row'
				justifyContent='space-between'
			>
				{dashboardLoading ? (
					<Grid item display='flex' justifyContent='center' className='py-10'>
						<Spinner />
					</Grid>
				) : (
					<>
						<Grid item>
							<Box className='dashboard-policy-cost-container mb-20'>
								<Typography
									fontWeight={600}
									className='pb-1 mb-15 mt-20'
									fontSize={14}
									color={theme.palette.common.black}
								>
									{t('SAVINGS')}
								</Typography>
								<Typography
									fontWeight={600}
									className='pb-1'
									fontSize={16}
									color={theme.palette.primary.main}
								>
									{t('GUARANTEED')}
								</Typography>
								<Typography
									fontWeight={400}
									className='pb-1'
									fontSize={16}
									color={theme.palette.primary.main}
								>
									[ {t('NON_PARTICIPATORY')} + {t('PARTICIPATORY')}]
								</Typography>
							</Box>
							{lifeDetails?.lifeCover.map((mapData: any) => (<Grid item className="mb-50" display="flex">
								<Divider
									orientation='vertical'
									sx={{
										height: '80px',
										backgroundColor: '#0051ba',
										width: '1px',
									}}
								/>
								<div className='progress_border_main' />

								<div className="w-280px pb-50">
									<PolicyCardCost.Normal
										titleFontWeight={400}
										title='TOTAL_MATURITY_VALUE'
										value={
											lifeDetails?.maturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)
												?.totalMaturityValueAmount
												?
												lifeDetails?.maturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)
													?.totalMaturityValueAmount

												: 0
										}
										policyCounts={lifeDetails?.maturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)
											?.countMaturityValue}
										policyDetails={mapData}
									/>
									<Grid>
										<Grid className='progress-width'>
											<LifeProgressBarWithValues
												currentValue={
													lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.guaranteedCurrentValue
												}
												totalMaturityValueAmount={
													lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.totalMaturityValueAmount
												}
												progressVal={(parseInt(lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.guaranteedCurrentValue) ?? 1) /
													(parseInt(lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.totalMaturityValueAmount) ?? 1) || 0}
												total={total || 0}
												updatedDate={
													lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.nonGuaranteedUpdatedDate
														? getFormatDate(
															lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.nonGuaranteedUpdatedDate,
															'YYYY-MM-DD'
														)
														: '-'
												}
												policyDetails={mapData}
											/>
										</Grid>
									</Grid>

									<Grid
										item
										container
										alignItems='center'
										spacing={2}
										justifyContent='flex-end'
									/>
									<Grid
										item
										container
										alignItems='center'
										spacing={2}
										justifyContent='flex-end'
									/>
								</div>
							</Grid>))}
						</Grid>
						<Grid className='mt-50 non_height newprogressStyle '>
							<Grid
								container
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								<Grid item>
									<Grid>
										<Typography
											fontWeight={600}
											className='pb-1 '
											fontSize={16}
											color={theme.palette.primary.main}
										>
											{t('NON_GUARANTEED')}
										</Typography>
										<Typography
											fontWeight={400}
											className='pb-1'
											fontSize={16}
											color={theme.palette.primary.main}
										>
											[ {t('PARTICIPATORY')}+ {t('MARKET_LINKED')}]
										</Typography>
									</Grid>
								</Grid>
								{lifeDetails?.projectedMaturityValue?.map((mapData: any) => (<Grid
									container
									item
									className="mt-18 ml-45 mb-50 resp-style"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Grid display='flex'>
										<div className='progress_three' />
										<div className='progress_two' />
										<div className='progress_one' />
									</Grid>
									<Grid item className="mb-50" display="flex">
										<Divider
											orientation='vertical'
											sx={{
												height: '202px',
												backgroundColor: '#0051ba',
												width: '1px',
												marginTop: 1,
											}}
										/>
										<div className="progress_border pb-15" />
										<div className="progress_border_box">
											<Grid style={{ position: 'relative' }}>
												<Grid className='progress_line'>
													<Divider
														orientation='vertical'
														sx={{
															height: '100px',
															backgroundColor: '#0051ba',
															width: '1px',
															marginTop: 1,
														}}
													/>
													<div className='progress_border1' />
												</Grid>
												<Grid className='nonguaranteedstyle'>
													<PolicyCardCost.NormalNonGuaranteed
														fontStyle='italic'
														titleFontWeight={400}
														title={`${t('PROJECTED_MATURITY')} @8%`}
														value={
															lifeDetails?.projectedMaturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.maximumProjectedMaturityAmount
																? lifeDetails?.projectedMaturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.maximumProjectedMaturityAmount
																: 0
														}
														policyCounts={
															lifeDetails?.projectedMaturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.countMaximumProjectedMaturity
																? lifeDetails?.projectedMaturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)?.countMaximumProjectedMaturity
																: 0
														}

														policyDetails={mapData}

													/>
												</Grid>
												<Grid className='second_content'>
													<PolicyCardCost.NormalNonGuaranteed
														fontStyle='italic'
														titleFontWeight={400}
														title={`${t('PROJECTED_MATURITY')} @4%`}
														value={
															lifeDetails?.projectedMaturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																?.minimumProjectedMaturityAmount
																? lifeDetails?.projectedMaturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																	?.minimumProjectedMaturityAmount
																: 0
														}
														policyCounts={
															lifeDetails?.projectedMaturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																?.countMinimumProjectedMaturity
																? lifeDetails?.projectedMaturityValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																	?.countMinimumProjectedMaturity
																: 0
														}
														policyDetails={mapData}
													/>
												</Grid>
											</Grid>

											<Grid>
												<Grid className='progress-width mb-20'>
													<LifeProgressBarNon
														currentValue={
															lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																?.nonGuaranteedCurrentValue
																? lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																	?.nonGuaranteedCurrentValue : 0
														}
														progressVal={(parseInt(lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)
															?.nonGuaranteedCurrentValue) ?? 1) /
															(parseInt(lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																?.maximumProjectedMaturityAmount) ?? 1) || 0}
														total={(parseInt(lifeDetails?.savings?.fundValueAmount) ?? 0) +
															(parseInt(lifeDetails?.savings?.surrenderValueAmount) ?? 0) || '0'}
														allValueText={`${t('ALL_FUND_VALUES')} + ${t(
															'BONUSES'
														)}`}
														updatedDate={
															lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																?.nonGuaranteedCurrentValue?.nonGuaranteedUpdatedDate
																? getFormatDate(
																	lifeDetails?.guaranteedValue.find((data: any) => data?.currencyType === mapData?.currencyType)
																		?.nonGuaranteedCurrentValue?.nonGuaranteedUpdatedDate,
																	'YYYY-MM-DD'
																)
																: '-'
														}
														policyDetails={mapData}
													/>
												</Grid>
											</Grid>

											<Grid
												item
												container
												alignItems='center'
												spacing={2}
												justifyContent='flex-end'
											/>
											<Grid
												item
												container
												alignItems='center'
												spacing={2}
												justifyContent='flex-end'
											/>
										</div>
									</Grid>
								</Grid>))}
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
		</DashboardCardLayout>
	);
};

export default LifeScreen;
