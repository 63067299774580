import React from 'react';

import { Grid } from '@mui/material';

import '../../Dashboard.scss';
import TrackerHeader from '../TrackerHeader';
import TrackerCard from '../TrackerCard';
import FinancierCard from '../FinancierCard';
import LoanProgressbar from './LoanProgressbar';
import Spinner from '../../../../../common/ui/Spinner';

interface UserProps {
	dashboardLoading?: any;
	LoanDetails?: any;
}
const LoanTrackerScreen: React.FC<UserProps> = ({
	dashboardLoading,
	LoanDetails,
}) => (
	<>
		{dashboardLoading ? (
			<Grid item display="flex" justifyContent="center" className="py-10">
				<Spinner />
			</Grid>
		) : (
			<>
				{LoanDetails?.LoanTracker?.map((loan: any) => (
					<>
						<Grid
							mb={2}
							style={{ border: '1px solid black', borderRadius: '8px' }}
						>
							<TrackerHeader
								type="Loan"
								startDate={loan?.loanStartDate}
								loan={loan}
							/>
							<TrackerCard loan={loan} />
							<LoanProgressbar loan={loan} />
							<FinancierCard loan={loan} />
						</Grid>
					</>
				))}
			</>
		)}
	</>
);

export default LoanTrackerScreen;
