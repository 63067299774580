import { Box, Grid, Typography } from '@mui/material';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import { useTranslation } from 'react-i18next';

const RemovePolicyModal = ({
    open,
    setOpen,
    submit,
}: any) => {
    const { t } = useTranslation();
    return (
        <CommonModal
            open={open}
            modalClassName="px-0 py-20"
            boxProps={{ width: 395 }}
        >
            <Grid container justifyContent="center">
                <Box

                    width={395}
                    display="flex"
                    justifyContent="end"
                    flexDirection="column"
                >
                    <Grid className="p-20" textAlign={'center'}>

                        <Typography className="f-16 fw-600">
                            {'Do you want to remove the policy?'}
                        </Typography>


                        <Grid
                            className="pt-20 px-40"
                            container
                            xs={12}
                            justifyContent={'space-between'}
                        >
                            <Grid item>
                                <CustomButton
                                    text={t('CANCEL')}
                                    variant="text"
                                    color="inherit"
                                    showIcon={false}
                                    className="f-16 fw-400"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <CustomButton
                                    text={t('REMOVE')}
                                    variant="text"
                                    type="submit"
                                    color="primary"
                                    className="f-16 fw-600"
                                    onClick={submit}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </CommonModal>
    );
};

export default RemovePolicyModal;
