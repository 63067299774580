import React from 'react';

import { ReactComponent as WithdrawalIcon } from '../../../../../../common/icons/LoanModule/withdrawalIcon.svg';

import TrackerCards from '../TrackerCards';
import { convertAmount } from '../../../../../../common/utils';
import { useTranslation } from 'react-i18next';

interface UserProps {
	step?: any;
	widgetNo?: any;
}
const StepTwo = ({ step, widgetNo }: UserProps) => {
	const { t } = useTranslation();
	return (
		<>
			<TrackerCards
				step={step}
				header="Partial Withdrawal"
				head={t('PARTIAL_WITHDRAWAL')}
				icon={WithdrawalIcon}
				productLogo={step?.insurerLogo}
				widgetNo={widgetNo}
				productName={step?.planName}
				label1={t('MATURITY_VALUE')}
				label2={t('CURRENT_FUND_VALUE')}
				label6={t('PARTIAL_WITHDRAWAL_OF')}
				label7={t('ELIGIBLE_WITHDRAWAL_VALUE')}
				selfName={step?.payerName}
				label1Value={convertAmount(step?.maturityValue)}
				label2Value={convertAmount(step?.fundValue)}
				lable6Value={convertAmount(step?.Withdrawal?.partialWithdrawalAmount)}
				label7Value={step?.Withdrawal?.partialWithdrawalPercentage}
				estimatedProcessingTimeHrs={step?.estimatedProcessingTimeHrs}
				elaspsedTimeHrsMins={step?.elaspsedTimeHrsMins}
			/>
		</>
	);
};

export default StepTwo;
