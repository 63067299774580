import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadGif from '../../../../common/icons/update-mobile/upload_gif.gif';
import CommonModal from '../../../../common/ui/Modal/CommonModal';

const UploadModal = ({ open }: any) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<CommonModal
			onClose={() => false}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			<Grid
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minHeight: '60vh',
				}}
			>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item alignItems="center" justifyContent="center">
						<Grid item className="">
							<img src={UploadGif} height={82} width={82} alt="upload" />
						</Grid>
						<Grid item className="mt-10">
							<Typography
								className="f-16 fw-600"
								color={theme.palette.primary.main}
							>
								{t('UPLOADING')}...
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default UploadModal;
