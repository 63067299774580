import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import './Address.scss';
import { groupBy, prop } from 'ramda';
import { useSelector } from 'react-redux';
import PermanentAddress from './common/PermanentAddress';
import CorrespondenceAddressHeader from './common/CorrespondenceAddressHeader';
import CorrespondenceAddress from './common/CorrespondenceAddress';
import UnassignedAddress from './common/UnassignedAddress';
import {
	selectGetAddressEkycUrlIsLoading,
	selectPoliciesForAddress,
	selectSaveAddressKYCData,
	selectSaveAddressKYCIsLoading,
} from './Address.slice';
import { EKYC_PROCESS_FLOW_TYPE } from '../../../common/Enums';
import UnassignedAddressPolicies from './UnassignedAddressPolicies';

const ManageAddressHeader = ({
	handleOnChangeAddressClick,
	handleAssignPolicies,
	setRemoveUnAssignedAddress,
	toggleRemoveNumberModal,
	toggleAddNumberModal,
	handleChangePermanentAddress,
	isEkycPopupOpen,
}: any) => {
	const { permanentAddress, addressBasedPolicies, unassignedAddresses, unAssignedPolicies }: any =
		useSelector(selectPoliciesForAddress);
	const isGetEkycURLLoading = useSelector(selectGetAddressEkycUrlIsLoading);
	const isSaveKYCIsLoading = useSelector(selectSaveAddressKYCIsLoading);
	const saveKYCData = useSelector(selectSaveAddressKYCData);

	const groupPoliciesByAddress = groupBy<any>(prop<any>('fullAddress'));
	const assignedPolicies: any = useMemo(() => {
		const data: any = groupPoliciesByAddress(addressBasedPolicies);
		return data;
	}, [addressBasedPolicies, groupPoliciesByAddress]);
	return (
		<>
			<Grid item>
				<Grid item className="mb-10">
					<PermanentAddress
						addressDetails={permanentAddress}
						onChange={handleChangePermanentAddress}
						changeLoading={
							isGetEkycURLLoading || isEkycPopupOpen || isSaveKYCIsLoading
						}
						message={
							saveKYCData?.data?.flag === EKYC_PROCESS_FLOW_TYPE.MANUAL
								? saveKYCData?.data?.message
								: false
						}
					/>
				</Grid>
				<Grid item>
					<CorrespondenceAddressHeader
						toggleAddNumberModal={toggleAddNumberModal}
					/>
				</Grid>
				<Grid item>
					<Grid container className="Manage-bank" spacing={6}>
						{Object.keys(assignedPolicies).map((fullAddress, index) => (
							<Grid item key={fullAddress} className="address-style">
								<CorrespondenceAddress
									addressDetails={assignedPolicies?.[fullAddress]?.[0]}
									index={index}
									policies={assignedPolicies?.[fullAddress]}
									handleOnChangeAddressClick={handleOnChangeAddressClick}
									handleAssignPolicies={handleAssignPolicies}
									toggleRemoveNumberModal={toggleRemoveNumberModal}
									fullAddress={fullAddress}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
				<Grid item marginTop={5}>
					<UnassignedAddress
						addressList={unassignedAddresses}
						handleAssignPolicies={handleAssignPolicies}
						setRemoveUnAssignedAddress={setRemoveUnAssignedAddress}
					/>
				</Grid>

				{unAssignedPolicies?.length > 0 && (
					<div className="mt-15">
						<UnassignedAddressPolicies
							policies={unAssignedPolicies}
							onChangeAddress={handleOnChangeAddressClick}
						/>
					</div>
				)}
			</Grid>
		</>
	);
};
export default ManageAddressHeader;
