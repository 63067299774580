import React, { useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import './LoanModule.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SelectFinancierDetails from './SelectFinancierDetails';
// import HdfcIcon from '../../../assets/images/HDFC.png';
// import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import Separator from '../../../common/ui/Separator';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as cancel } from '../../../common/icons/cancel-icon.svg';
// import { ReactComponent as correct } from '../../../common/icons/LoanModule/right-arrow-icon.svg';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import ProductDetails from './ProductDetails';
import { APP_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';
import {
	saveSelectedCombinationLoading,
	saveSelectedCombinationRequest,
	selectedCombinationLoading,
} from './Loan.slice';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/LoanModule/left_arrow_icon.svg';
import Spinner from '../../../common/ui/Spinner';
import { convertAmount } from '../../../common/utils';
import { useTranslation } from 'react-i18next';
import cryptoEncryptionDecryption from '../../../common/crypto';
import { setAlertInfo } from '../Common/Common.slice';
import BankDetails from './BankDetails';

const SubmitLoanApplication = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const modifiedData = localStorage.getItem('lenderdata');
	const retrievedObjectModifiedData = JSON.parse(
		cryptoEncryptionDecryption.Decrypt(modifiedData)
	);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const handleGoBack = () => {
		navigate(-1);
	};

	const isEditFromTrackTransaction = location.state?.isEditFromTrackTransaction;
	const loanIsLoading = useSelector(selectedCombinationLoading);
	const isRequestLoading = useSelector(saveSelectedCombinationLoading);
	const currentIndex: number = Number(localStorage.getItem('currentIndex'));
	const esignLink = localStorage.getItem('esignLink');

	const eLink =
		esignLink &&
		JSON.parse(cryptoEncryptionDecryption.Decrypt(esignLink) as any);
	const updatedLoanSignDetails = eLink?.LoanSignDetails?.map(
		(detail: any, index: any) => {
			if (index === eLink?.LoanSignDetails.length - 1) {
				return {
					...detail,
					eSignLink: `${detail.eSignLink}&redirect_uri=${window.location.origin}${APP_ROUTES.TRACK_TRANSACTION}`,
				};
			} else {
				return {
					...detail,
					eSignLink: `${detail.eSignLink}&redirect_uri=${window.location.origin}${APP_ROUTES.SUBMIT_LOAN_APPLICATION}`,
				};
			}
		}
	);
	const handleSuccess = (data: any) => {
		if (data && Array.isArray(data) && data.length > currentIndex) {
			window.location.href = data[currentIndex]?.eSignLink;
		}
	};

	const locations =
		window.location.href ===
		`${window.location.origin}${APP_ROUTES.SUBMIT_LOAN_APPLICATION}`;
	useEffect(() => {
		if (locations === true) {
			const updatedIndex: number = currentIndex + 1;
			localStorage.setItem('currentIndex', String(updatedIndex));
		}
	}, [handleSuccess]);

	useEffect(() => {
		if (eLink?.LoanSignDetails) {
			handleSuccess(updatedLoanSignDetails);
		} else if (eLink !== null && !eLink?.LoanSignDetails) {
			const alertInfo: any = {
				open: true,
				type: 'ERROR',
				description: `${eLink?.errorDescription}`,
				errorCode: '2205',
			};
			dispatch(setAlertInfo(alertInfo));
		}
	}, [currentIndex]);

	const handleAddClick = (Combination: any) => {
		const payload: any = {
			modifiedData: Combination,
			requiredAmount: retrievedObjectModifiedData?.requiredAmount,
			requiredDate: retrievedObjectModifiedData?.requiredDate,
			redirectUrl: `${window.location.origin}${APP_ROUTES.TRACK_TRANSACTION}`,
			dashboardSessionFlag: isEditFromTrackTransaction ? 'W' : null
		};
		dispatch(saveSelectedCombinationRequest(payload));
	};

	return (
		<>
			{loanIsLoading ? (
				<Grid justifyContent="center" alignItems="center">
					<Spinner />
				</Grid>
			) : (
				<Grid container justifyContent="center">
					<Grid item className="need-money-esign">
						<Grid item className="mb-10">
							<Typography
								component="span"
								className="f-14 fw-600"
								color={theme.palette.common.black}
							>
								{t('STEP')} 1
								<Typography
									component="span"
									className="f-14 fw-400"
									color={theme.palette.common.black}
								>
									/2
								</Typography>
							</Typography>
							<Typography
								className="f-16 fw-600"
								color={theme.palette.primary.main}
							>
								Submit Loan Application
							</Typography>
						</Grid>
						<Grid item className="mb-5">
							<Grid
								container
								spacing={2}
								justifyContent="space-between"
								className="mb-20 mt-2"
							>
								<Grid item>
									<Grid item className="mb-14">
										<CustomButton
											fontSize={14}
											fontWeight={600}
											text={t('GO_BACK')}
											showIcon={false}
											startIcon={ArrowLeftIcon}
											variant="text"
											color="primary"
											startIconClassName="go-back-icon"
											className="f-14 "
											onClick={() => {
												handleGoBack();
											}}
										/>
									</Grid>
								</Grid>
								<Grid item>
									<Link to={APP_ROUTES.Lender_Screen}>
										<LabelWithIcon
											className="f-14"
											justifyContent="flex-start"
											Icon={cancel}
											label={t('CANCEL')}
											labelProps={{ fontSize: 14, color: 'primary' }}
										/>
									</Link>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('POLICY_DETAILS')}
							</Typography>
							<Separator color={theme.palette.grey[300]} />
						</Grid>

						{retrievedObjectModifiedData?.Widgets.map(
							(widgets: any) =>
								widgets.widgetType === 'L' && (
									<>
										<Grid item>
											<Grid
												container
												flexWrap="nowrap"
												justifyContent="space-between"
												spacing={1}
												className="p-10 "
											>
												<Grid item className="mt-8">
													<img
														src={widgets?.insurerLogo}
														alt="logo"
														className="bank_logo"
													/>
												</Grid>
												<Grid item>
													<ProductDetails
														product={widgets?.productType}
														selfName={widgets?.payerName}
														productData={widgets}
													// rupeeOne="XXXXXXXX"
													// rupeeTwo="XXXXXXXX"
													// IconProps={{ Icon: RupeeIcon }}
													/>
												</Grid>
											</Grid>
										</Grid>
										{widgets?.widgetType === 'L' && (
											<>
												<Grid item>
													<Typography
														className="f-14"
														color={theme.palette.common.black}
													>
														{t('FINANCIER')}
													</Typography>
													<Separator color={theme.palette.grey[300]} />
												</Grid>
												<Grid item>
													<Grid
														container
														flexWrap="nowrap"
														justifyContent="space-between"
														spacing={1}
														className="p-10"
													>
														<Grid item className="mt-4">
															<img
																src={widgets?.Loan?.LenderDetails?.lenderLogo}
																alt="logo"
																className="bank_logo"
															/>
														</Grid>
														<Grid item>
															<SelectFinancierDetails
																product={
																	widgets?.Loan?.LenderDetails?.lenderName
																}
																chargeAmount={convertAmount(
																	widgets?.Loan?.LenderDetails?.charges
																)}
																interestPercentage={
																	widgets?.Loan?.LenderDetails
																		?.interestPercentage
																}
																loanAmount={convertAmount(
																	widgets?.Loan?.LenderDetails?.loanAmount
																)}
																repaymentOptions={t('SELECT_REPAYMENT_OPTIONS')}
																decideLater={
																	widgets?.Loan?.LenderDetails
																		?.repaymentOptionDescription
																}
															// IconProps={{ Icon: RupeeIcon }}
															/>
														</Grid>
													</Grid>
												</Grid>
												<Grid item className="mt-30">
													<Typography
														className="f-14"
														color={theme.palette.common.black}
													>
														{t('BANK_DETAILS')}
													</Typography>
													<Separator color={theme.palette.grey[300]} />
												</Grid>
												<Grid item className="mb-25">
													<BankDetails
														bankName={widgets?.BankDetails?.bankName}
														branch={widgets?.BankDetails?.bankBranch}
														ifscCode={widgets?.BankDetails?.bankIfscCode}
														accountNum={widgets?.BankDetails?.bankAcNo}
														bankLogo={widgets?.BankDetails?.bankLogo}
														accountHolder={widgets?.BankDetails?.AcHolderName}
														verifyBank={widgets?.BankDetails?.bankVerified}
													/>
												</Grid>

												<Grid item>
													<Typography
														className="f-14"
														color={theme.palette.common.black}
													>
														{t('WITNESS_DETAILS')}
													</Typography>
													<Separator color={theme.palette.grey[300]} />
												</Grid>
												<Grid item className="mb-25">
													<Grid item className="my-2">
														<Typography
															className="f-14 fw-600"
															color={theme.palette.primary.main}
														>
															{widgets?.Loan?.Witness?.witnessFirstName}{" "} {widgets?.Loan?.Witness?.witnessLastName}
														</Typography>
													</Grid>
													<Grid
														container
														spacing={0.5}
														justifyContent="space-between"
													>
														<Grid item xs={5} flexWrap="nowrap">
															<Typography
																className="f-12"
																color={theme.palette.primary.main}
															>
																{t('PAN')}# {widgets?.Loan?.Witness?.witnessPan}
															</Typography>
														</Grid>
														<Grid item xs={5}>
															<Typography
																className="f-12 "
																color={theme.palette.primary.main}
															>
																{widgets?.Loan?.Witness?.witnessMobileNo}
															</Typography>
														</Grid>
													</Grid>
													<Grid
														container
														spacing={0.5}
														justifyContent="space-between"
													>
														<Grid item xs={5}>
															<Typography
																className="f-12"
																color={theme.palette.primary.main}
																flexWrap="nowrap"
															>
																{widgets?.Loan?.Witness?.witnessDOB}
															</Typography>
														</Grid>
														<Grid item xs={5}>
															<Typography
																className="f-12 "
																color={theme.palette.primary.main}
																style={{ overflowWrap: 'normal' }}
															>
																{widgets?.Loan?.Witness?.witnessEmailId}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											</>
										)}
									</>
								)
						)}
						<Grid item className="mb-15 mt-10">
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<Grid container spacing={1} justifyContent="flex-end">
							{/* <Link to={APP_ROUTES.TRACK_TRANSACTION}> */}
							<CustomButton
								fontSize={16}
								fontWeight={600}
								text={t('E_SIGN_THE_APPLICATION')}
								showIcon
								endIcon={rightArrow}
								loading={isRequestLoading}
								variant="text"
								color="primary"
								onClick={() => {
									handleAddClick(retrievedObjectModifiedData);
								}}
							/>
							{/* </Link> */}
						</Grid>
						<Grid item className="mb-10 mt-10">
							<Separator color={theme.palette.grey[300]} />
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default SubmitLoanApplication;
