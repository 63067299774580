import React from 'react';

import { Route, Routes } from 'react-router-dom';
import PrivacyPolicyScreen from '../features/App/Legal/PrivacyPolicyScreen';
import PrivacyScreen from '../features/App/Legal/PrivacyScreen';
import DisclaimerScreen from '../features/App/Legal/DisclaimerScreen';
import DataProtectionScreen from '../features/App/Legal/DataProtectionScreen';
import SecurityTips from '../features/App/Legal/SecurityTips';
import { PUBLIC_ROUTES } from '../common/constants/Routes';

const PublicRoutes = () => (
	<Routes>
		<Route
			path={PUBLIC_ROUTES.PRIVACYPOLICY}
			element={<PrivacyPolicyScreen />}
		/>
		<Route
			path={PUBLIC_ROUTES.PRIVACYSCREEN}
			element={<PrivacyScreen />}
		/>
		<Route path={PUBLIC_ROUTES.DISCLAIMER} element={<DisclaimerScreen />} />
		<Route
			path={PUBLIC_ROUTES.DATAPROTECTIONPOLICY}
			element={<DataProtectionScreen />}
		/>
		<Route path={PUBLIC_ROUTES.SECURITYTIPS} element={<SecurityTips />} />
	</Routes>
);

export default PublicRoutes;
