import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as BankIcon } from '../../../common/icons/update-mobile/Bank_icon.svg';
import Separator from '../../../common/ui/Separator';

const BankHeader = ({ onAddBankClick, onClickDone }: any) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Grid item className="mb-5">
			<Grid container justifyContent="space-between">
				<Grid item>
					<Typography className="f-16 fw-600 " color="primary">
						{t('MANAGE_BANK_DETAILS')}
					</Typography>
				</Grid>
				<Grid item className="">
					<CustomButton
						text={t('DONE')}
						variant="text"
						color="primary"
						showIcon
						onClick={onClickDone}
						fontSize={14}
						endIcon={rightArrow}
					/>
				</Grid>
			</Grid>
			<Grid item>
				<CustomButton
					text={t('ADD_A_BANK_ACCOUNT')}
					variant="text"
					color="primary"
					showIcon={false}
					onClick={() => onAddBankClick()}
					fontSize={14}
					startIcon={BankIcon}
				/>
			</Grid>
			<Separator
				className="mt-10"
				color={theme.palette.primary.main}
				borderWidth={1}
			/>
		</Grid>
	);
};

export default BankHeader;
