import { Typography, useTheme } from '@mui/material';
import React from 'react';

const AddressLineGenerator = ({ value, showComma = true }: any) => {
	const theme = useTheme();
	return (
		<Typography
			className="f-16"
			color={theme.palette.common.black}
			style={{ wordWrap: 'break-word' }}
		>
			{value && (
				<>
					{value}
					{showComma && ','} <br />
				</>
			)}
		</Typography>
	);
};

export default AddressLineGenerator;
