import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RightArrowIcon } from '../../../../../../common/icons/right-arrow.svg';
import { DoughnutWithPercentage } from '../../../../../../common/ui/Charts/DoughnutWithPercentage';
import theme from '../../../../../../common/theme';
import SvgConverter from '../../../../../../common/SvgConverter';

interface FreeLookPeriodProps {
	freeLookPeriodDetails?: any;
}
const FreeLookPeriod: React.FC<FreeLookPeriodProps> = ({
	freeLookPeriodDetails,
}) => {
	const { t } = useTranslation();
	return (
		<Grid item container xs={12}>
			<Grid item xs={12} display="flex" flexDirection="row">
				<Grid item xs={4} lg={4} marginTop={10} marginLeft={2}>
					<Typography
						className="f-14 fw-600 text-wrap"
						color={theme.palette.primary.main}
						justifyContent="center"
						alignItems="center"
					>
						{t('FREELOOK_PERIOD')}
					</Typography>
				</Grid>

				<Grid item xs={1} lg={1} marginTop={10} marginLeft={3}>
					<SvgConverter Icon={RightArrowIcon} />
				</Grid>

				<DoughnutWithPercentage
					freelookPeriod={
						freeLookPeriodDetails?.freelookPeriod
							? freeLookPeriodDetails?.freelookPeriod
							: '-'
					}
				/>
			</Grid>
		</Grid>
	);
};

export default FreeLookPeriod;
