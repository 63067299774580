import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MobileIcon } from '../../../../common/icons/mobile-icon.svg';
import { ReactComponent as PanCardIcon } from '../../../../common/icons/pan_card_icon.svg';
import { ReactComponent as LabelCalendarIcon } from '../../../../common/icons/login_calendar_label-icon.svg';
import { ReactComponent as WitnessPerson } from '../../../../common/icons/LoanModule/witness-icon.svg';
import { ReactComponent as WitnessEmail } from '../../../../common/icons/LoanModule/witness_email_icon.svg';
import { ReactComponent as cancel } from '../../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../../common/icons/LoanModule/right-arrow-icon.svg';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import CustomButton from '../../../../common/ui/CustomButton';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import data from '../../../../common/constants/FormConstants.json';
import DatePickerModal from '../../../../common/ui/DatePickerModal';
// import LoanAgainstHeader from './LoanAgainstHeader';
import config from '../../../../common/config';
import { ageCalculator, countryCode } from '../../../../common/utils';
import LoanAgainstHeader from './LoanAgainstHeader';
import { useSelector } from 'react-redux';
import { bankStatus, myOptions } from '../../LoanModule/Loan.slice';
import BankDetails from '../../LoanModule/BankDetails';
import { selectIsUserLoggedIn } from '../../../Auth/auth.slice';

const PolicyWitnessDetails = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const bankDetail = useSelector(bankStatus);
	const myPolicyLoan = useSelector(myOptions);
	const [emailError, setEmailError] = useState({});
	const [mobileError, setMobileError] = useState({});
	const [nameError, setNameError] = useState({});
	const userData: any = useSelector(selectIsUserLoggedIn);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		control,
	} = useForm({
		defaultValues: {
			mobileCountryCode: '91',
			witnessPan: '',
			witnessMobileNo: '',
			dob: '',
			witnessEmailId: '',
			witnessFirstName: '',
			witnessLastName: '',
		},
	});
	const mobileNo = watch('witnessMobileNo');
	const email = watch('witnessEmailId');
	const firstName = watch('witnessFirstName');
	const lastName = watch('witnessLastName');
	useEffect(() => {
		if (((firstName.trim().concat(" ", lastName.trim()))).toLowerCase() === userData.customerName.trim().toLowerCase()) {
			setNameError({
				witnessFirstName: {
					type: 'required',
					message: 'Witness Name cannot be the same as policyholders',
				},
				witnessLastName: {
					type: 'required',
					message: 'Witness Name cannot be the same as policyholders',
				},
			});
		} else {
			setNameError(errors);
		}
	}, [firstName, lastName, errors]);

	function isEmpty(obj: any) {
		return Object.keys(obj).length === 0;
	}
	useEffect(() => {
		if (email === userData.email) {
			setEmailError({
				witnessEmailId: {
					type: 'required',
					message: 'Witness Email cannot be the same as policyholders',
				},
			});
		} else {
			setEmailError(errors);
		}
	}, [email, errors]);

	useEffect(() => {
		if (mobileNo === userData.mobileNo) {
			setMobileError({
				witnessMobileNo: {
					type: 'required',
					message: 'Witness Mobile number cannot be the same as policyholders',
				},
			});
		} else {
			setMobileError(errors);
		}
	}, [mobileNo, errors]);
	useEffect(() => {
		if (!myPolicyLoan) {
			navigate(APP_ROUTES.POLICY);
		}
	}, [myPolicyLoan]);
	const bankdetails = bankDetail?.[0]?.bankDetails;

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const datePicker = (e: any) => {
		setAnchorEl(e.currentTarget);
	};

	const handleDateChange = (date: any) => {
		setValue('dob', date?.format('DD/MM/YYYY'), { shouldValidate: true });
	};

	const dobValue = String(watch('dob')).split('/');
	const panCardRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
	// const countryCodeRegex = /^\+\d{1,3}$/;
	const mobileNumberRegex = /^[6789]\d{9,}$/;
	const isMinor: any = useMemo(
		() =>
			(ageCalculator(`${dobValue[1]}/${dobValue[0]}/${dobValue[2]}`) as any) <
			18,
		[dobValue]
	);

	const handlePanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const panValue = event.target.value
			.toUpperCase()
			.replace(/[^\w\s]/gi, '')
			.slice(0, 10);
		setValue('witnessPan', panValue, { shouldValidate: true });
	};
	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>, name: any) => {
		const nameValue = event.target.value
			.replace(/[^\w\s]/gi, '')
			.replace(/[^A-Za-z\s]/gi, '');
		setValue(name, nameValue, { shouldValidate: true });
	};
	const onSubmit = (data: any) => {
		const updatedWidgets = (location.state?.modifiedData?.Widgets || []).map(
			(widget: any) => {
				if (widget.widgetType === 'L' && widget.Loan) {
					const updatedLoan = {
						...widget.Loan,
						Witness: {
							witnessFirstName: data.witnessFirstName,
							witnessLastName: data.witnessLastName,
							witnessPan: data.witnessPan,
							witnessMobileNo: data.witnessMobileNo,
							witnessAddress: '',
							witnessEmailId: data.witnessEmailId,
							witnessDOB: data.dob,
						},
					};

					return {
						...widget,
						BankDetails: bankdetails,
						Loan: { ...updatedLoan },
					};
				}

				return { ...widget };
			}
		);

		const updatedLocationState: any = {
			updatedLocationState: {
				...(location.state?.modifiedData || {}),
				Widgets: updatedWidgets,
			},
			requiredAmount: location.state?.requiredAmount,
			requiredDate: location.state?.requiredDate,
		};
		navigate(APP_ROUTES.Policy_Loan_Submit, {
			state: {
				...updatedLocationState,
			},
		});
	};

	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);

		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}

		return '';
	};

	useEffect(() => {
		if (mobileNo) {
			setValue('witnessMobileNo', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const emailValue = event.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('witnessEmailId', emailValue, { shouldValidate: true });
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container justifyContent="center">
				<LoanAgainstHeader />
				<Grid item justifyContent="center" className="need-money mt-5">
					<Grid item container className="w-100">
						<Grid item className="mb-20">
							<Typography
								component="span"
								className="f-14 fw-600"
								color={theme.palette.common.black}
							>
								{t('STEP')} 2
								<Typography
									component="span"
									className="f-14 fw-400"
									color={theme.palette.common.black}
								>
									/3
								</Typography>
							</Typography>
							<Typography
								className="f-16 fw-600"
								color={theme.palette.primary.main}
							>
								{t('ADD_WITNESS_DETAILS')}
							</Typography>
						</Grid>
						<Grid item className="mb-5 need-money">
							<Grid
								container
								spacing={2}
								justifyContent="space-between"
								className="mb-14"
							>
								<Grid item>
									<Link to={APP_ROUTES.Get_Loan_Against_Policy}>
										<LabelWithIcon
											className="f-14"
											justifyContent="flex-start"
											Icon={cancel}
											label={t('CANCEL')}
											labelProps={{
												fontSize: 14,
												color: 'primary',
											}}
											iconProps={{ width: 18, height: 18 }}
										/>
									</Link>
								</Grid>
								<Grid item>
									<CustomButton
										className="f-14 fw-600 "
										text={t('NEXT')}
										variant="text"
										color="primary"
										showIcon={false}
										startIcon={correct}
										type="submit"
										disabled={
											(isMinor && dobValue?.length > 1) ||
											!isEmpty(nameError) ||
											!isEmpty(emailError) ||
											!isEmpty(mobileError)
										}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item className="mb-15 w-100">
							<LabelWithIcon
								Icon={WitnessPerson}
								label={t('NAME_OF_WITNESS')}
								justifyContent="flex-start"
								className="mb-6 ml-3"
							/>
							<FormInput
								name="witnessFirstName"
								type="text"
								placeholder="Type here.."
								inputProps={{
									className: 'text-left',
									maxLength: config.nameLength,
									...register('witnessFirstName', {
										required: {
											value: true,
											message: 'witness First Name is mandatory!',
										},
										validate: (value: any) => {
											if (!config.alphabetsOnlyRegex.test(value)) {
												return `${t(data.INVALID_NAME)}`;
											}
											return true;
										},
									}),
									onChange: (event: any) => handleNameChange(event, 'witnessFirstName'),
								}}
								errors={nameError}
							/>
						</Grid>
						<Grid item className="mb-15 w-100">
							<LabelWithIcon
								Icon={WitnessPerson}
								label={t('NAME_OF_WITNESS')}
								justifyContent="flex-start"
								className="mb-6 ml-3"
							/>
							<FormInput
								name="witnessLastName"
								type="text"
								placeholder="Type here.."
								inputProps={{
									className: 'text-left',
									maxLength: config.nameLength,
									...register('witnessLastName', {
										required: {
											value: true,
											message: 'witness Last Name is mandatory!',
										},
										validate: (value: any) => {
											if (!config.alphabetsOnlyRegex.test(value)) {
												return `${t(data.INVALID_NAME)}`;
											}
											return true;
										},
									}),
									onChange: (event: any) => handleNameChange(event, 'witnessLastName'),
								}}
								errors={nameError}
							/>
						</Grid>
						<Grid item className="mb-15 w-100">
							<LabelWithIcon
								Icon={PanCardIcon}
								label={t('WITNESS_PAN_NUMBER')}
								justifyContent="flex-start"
								className="mb-6 ml-3 "
							/>
							<FormInput
								type="text"
								className="pan-input"
								placeholder="ABCDF2340X"
								name="witnessPan"
								inputProps={{
									...register('witnessPan', {
										required: {
											value: true,
											message: `${t(data.PANCARD)}`,
										},
										minLength: {
											value: 10,
											message: `${t(data.INVALID_PAN)}`,
										},
										maxLength: {
											value: 10,
											message: `${t(data.INVALID_PAN)}`,
										},
										validate: (value: any) => {
											if (!panCardRegex.test(value)) {
												return `${t(data.INVALID_PAN)}`;
											}
											return true;
										},
										onChange: handlePanChange,
									}),
								}}
								errors={errors}
							/>
						</Grid>
						<Grid item className="mb-15 w-100">
							<LabelWithIcon
								Icon={MobileIcon}
								label={t('MOBILE_NUMBER_OF_WITNESS')}
								justifyContent="flex-start"
								className="mb-6 ml-3"
							/>
							<Grid container flexWrap="nowrap" spacing={1}>
								<Grid item xs={3}>
									<FormInput
										inputProps={{
											maxLength: 4,
											...register('mobileCountryCode', {
												required: {
													value: true,
													message: 'CountryCode is required',
												},
												// validate: (value: any) => {
												// 	if (
												// 		!countryCodeRegex.test(value) ||
												// 		parseInt(value) === 0
												// 	) {
												// 		return data.INVALID_VALUE;
												// 	}
												// 	return true;
												// },
												onChange: (e: any) => {
													setValue(
														'mobileCountryCode',
														countryCode(e.target.value)
													);
												},
											}),
										}}
										name="mobileCountryCode"
										placeholder="91"
										hideCountButton
										className="mobile-number"
										readOnly
										errors={errors}
									/>
								</Grid>
								<Grid item flex={1}>
									<FormInput
										name="witnessMobileNo"
										placeholder="1234567890"
										hideCountButton
										className="mobile-number"
										control={control}
										rules={{
											required: `${t(data.MOBILENUMBER)}`,
											pattern: {
												value: mobileNumberRegex,
												message: 'Invalid mobile number',
											},
											onChange: (e: any) => {
												setValue('witnessMobileNo', e.target.value, {
													shouldValidate: true,
												});
											},
										}}
										errors={mobileError}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item className="mb-15 w-100">
							<LabelWithIcon
								Icon={WitnessEmail}
								label={t('WITNESS_EMAIL')}
								justifyContent="flex-start"
								className="mb-6 ml-3"
							/>
							<FormInput
								type="text"
								placeholder="email@website.com"
								name="witnessEmailId"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Email is required',
									},
									pattern: {
										value: config.emailRegex,
										message: 'Please enter valid email',
									},
									onChange: handleEmailChange,
								}}
								errors={emailError}
								inputProps={{
									maxLength: config.emailLength,
								}}
							/>
						</Grid>
						<Grid container item xs={12} flexDirection="column">
							<Grid item className="my-3">
								<LabelWithIcon
									Icon={LabelCalendarIcon}
									label={t('WITNESS_DATE_OF_BIRTH')}
									iconProps={{ height: 33 }}
									justifyContent="flex-start"
									className="ml-3"
								/>
							</Grid>
							<Grid
								item
								container
								flexDirection="row"
								spacing={2}
								alignItems="center"
								justifyContent="space-between"
								flexWrap="nowrap"
							>
								<Grid item sm={3}>
									<FormInput
										name="date"
										inputProps={{
											maxLength: 2,
											style: { textAlign: 'center' },
											value: dobValue?.[0],
										}}
										onClick={datePicker}
										placeholder="DD"
										readOnly
										className="date-month-input"
									/>
								</Grid>
								<Grid item sm={3}>
									<FormInput
										name="month"
										inputProps={{
											maxLength: 2,
											style: { textAlign: 'center' },
											value: dobValue?.[1],
										}}
										onClick={datePicker}
										placeholder="MM"
										readOnly
										className="date-month-input"
									/>
								</Grid>
								<Grid item sm={4}>
									<FormInput
										name="year"
										inputProps={{
											maxLength: 4,
											style: { textAlign: 'center' },
											value: dobValue?.[2],
										}}
										onClick={datePicker}
										placeholder="YYYY"
										readOnly
										className="signup-year-input"
									/>
								</Grid>
								<Grid item>
									<DatePickerModal
										iconType="primary"
										disableFuture
										onChange={handleDateChange}
										value={
											dobValue.length === 3
												? `${dobValue[0]}/${dobValue[1]}/${dobValue[2]}`
												: false
										}
										modalOpen={anchorEl}
										setModalOpen={setAnchorEl}
									/>
								</Grid>
							</Grid>{' '}
							<FormInput
								hidden
								name="dob"
								inputProps={{
									...register('dob', {
										required: {
											value: true,
											message: `${t(data.DOB)}`,
										},
									}),
								}}
								errors={
									isMinor && dobValue?.length > 1
										? { dob: { message: 'Age is below 18 years' } }
										: errors
								}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};
export default PolicyWitnessDetails;
