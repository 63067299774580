import { Box, Grid } from '@mui/material';
import React from 'react';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import { ReactComponent as Life } from '../../../../common/icons/group-certified-small.svg';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';

interface UserProps {
	insurercode?: any;
	list?: any;
}

const PolicyList: React.FC<UserProps> = ({ insurercode, list }) => {
	const { t } = useTranslation();
	return (
		<Box className="add-policy ">
			<Grid item>
				<Grid item xs={12} md={6} sm={6} className="py-5 mb-10">
					<LabelWithIcon
						className=" f-16"
						svgClassName="mb-2"
						justifyContent="flex-start"
						Icon={Life}
						label={insurercode}
						labelProps={{
							color: theme.palette.primary.main,
							fontSize: 14,
						}}
					/>
				</Grid>

				{list &&
					list.length > 0 &&
					list?.map((mapvalue: any, index: any) => (
						<Grid container className="background-container mb-10" key={index}>
							<Grid item xs={12}>
								<Grid container columnSpacing={2}>
									<Grid item xs={1} className="mt-10 ">
										<Typography
											className="f-14"
											color={theme.palette.common.black}
										>
											{index + 1}.
										</Typography>
									</Grid>
									<Grid item xs={2} className="mt-10 ">
										{mapvalue?.insurerLogo && (
											<img
												src={mapvalue?.insurerLogo}
												className="policy-image"
												alt="logo"
											/>
										)}
									</Grid>
									<Grid item xs={9} className="mt-5">
										<Typography
											className="f-14"
											color={theme.palette.common.black}
										>
											{t('POLICY_NUMBER')}
										</Typography>
										<Typography
											className="f-16 fw-600"
											color={theme.palette.common.black}
										>
											{mapvalue?.policyNo}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					))}
			</Grid>
		</Box>
	);
};

export default PolicyList;
