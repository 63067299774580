import React, { useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import { ReactComponent as RupeeIcon1 } from '../../../common/icons/orange_rupee.svg';
import SvgConverter from '../../../common/SvgConverter';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import data from '../../../common/constants/FormConstants.json';
import { parseMobileNo } from '../../../common/utils';
import { useTranslation } from 'react-i18next';

interface Props {
	inputValue?: any;
	control?: any;
	error?: any;
	name?: any;
	type?: any;
	setValue?: any;
	eligibleType?: any;
	eligibleAmount?: any;
}
const moneyValueRegex = /^[0-9]{1,20}$/;
const FormInputWithRupee = ({
	inputValue,
	control,
	error,
	name,
	setValue,
	type,
	eligibleType,
	eligibleAmount,
}: Props) => {
	const { t } = useTranslation();
	const money = useWatch({
		control,
		name,
	});
	useEffect(() => {
		if (money) {
			setValue(name, parseMobileNo(money as any));
		}
	}, [money]);
	const theme = useTheme();
	return (
		<Grid container spacing={1}>
			<Grid item mt={2}>
				<SvgConverter Icon={RupeeIcon} />
			</Grid>

			<Grid item flex={1}>
				<FormInput
					name={name}
					placeholder={inputValue}
					rules={{
						required: data.MONEY,
						pattern: {
							value: moneyValueRegex,
							message: 'Invalid Amount',
						},
						max: {
							value: inputValue,
							message: `Enter the amount below ${inputValue}`,
						},
						// min :{
						// 	value: 1,
						// 	message: 'Amount must be greater than 0',
						// }
					}}
					defaultValue={inputValue}
					control={control}
					errors={error}
					className="form-input-loan"
				/>
				{type === 'Lender' ? (
					<Typography
						className="f-10"
						color={theme.palette.grey.A700}
						display="flex"
						alignItems="center"
					>
						[{' '}
						{eligibleType === 'Surrender'
							? 'Eligible Surrender limit'
							: eligibleType === 'loan'
							? t('ELIGIBLE_LOAN_LIMIT')
							: t('ELIGIBLE_WITHDRAWAL_LIMIT')}{' '}
						=&nbsp;
						<Grid item>
							<SvgConverter Icon={RupeeIcon1} />
						</Grid>
						&nbsp; {eligibleAmount}]
					</Typography>
				) : (
					<Typography
						className="f-10"
						color={theme.palette.grey.A700}
						display="flex"
						alignItems="center"
					>
						[Max % allowed:
						<Typography
							className="f-10 fw-600 ml-2"
							color={theme.palette.grey.A700}
							justifyContent="center"
						>
							XX
						</Typography>
						% of fund value]
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

export default FormInputWithRupee;
