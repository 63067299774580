import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import SvgConverter from '../../../../../common/SvgConverter';
import { ReactComponent as GreenTick } from '../../../../../common/icons/small-greentick.svg';
import { ReactComponent as InProgress } from '../../../../../common/icons/small-inprogress.svg';

interface UserProps {
	status?: any;
	classname?: any;
}
const VerificationStatus: React.FC<UserProps> = ({ status, classname }) => {
	const { t } = useTranslation();
	return (
		<Grid container className={classname}>
			{status === 'Y' ? (
				<Grid container justifyContent="center" className="mt-10">
					<SvgConverter
						Icon={GreenTick}
						className="d-flex align-items-end mb-4"
					/>
					<Typography fontSize={14} color="#63a103">
						{t('VERIFIED')}
					</Typography>
				</Grid>
			) : (
				<Grid container justifyContent="center" className="mt-10 px-10">
					<SvgConverter
						Icon={InProgress}
						className="d-flex align-items-end mb-4"
					/>
					<Typography fontSize={12} color={theme.palette.secondary.main}>
						{t('VERIFICATION_INPROGRESS')}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default VerificationStatus;
