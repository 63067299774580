import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import Separator from '../../../../common/ui/Separator';
import {
	fetchFamilyRelationShipList,
	fetchUserFamilyListAction,
	selectFamilyListIsLoading,
	selectFamilyRelationShipList,
	selectIsFamilyRelationShipLoading,
	selectUserFamilyList,
} from '../Profile.slice';
import Spinner from '../../../../common/ui/Spinner';
import CustomButton from '../../../../common/ui/CustomButton';
import CustomPopOver from '../../../../common/ui/CustomPopOver';
import './AddFamilyMember.scss';
import { setAlertInfo } from '../../Common/Common.slice';

const AddFamilyMemberContent = ({ open, anchorEle, handleClosed }: any) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchFamilyRelationShipList());
		dispatch(fetchUserFamilyListAction());
	}, []);

	const familyList = useSelector(selectUserFamilyList);
	const isProfileLoading = useSelector(selectFamilyListIsLoading);
	const isRelationShipLoadingList = useSelector(
		selectIsFamilyRelationShipLoading
	);
	const relationShipList = useSelector(selectFamilyRelationShipList);
	const handleAddMember = (data: any) => {
		const allowedRelationCodes = ['02', '03', '07', '06'];
		const isRelationCodeExists = familyList?.some(
			(item: any) => item?.relationCode === data?.relationCode
		);
		if (!isRelationCodeExists) {
			navigate(APP_ROUTES.ADD_FAMILY_SCREEN, { state: { data } });
			return;
		}
		if (allowedRelationCodes.includes(data?.relationCode)) {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('Already added'),
				})
			);
		} else {
			navigate(APP_ROUTES.ADD_FAMILY_SCREEN, { state: { data } });
		}
	};
	return (
		<CustomPopOver
			isOpen={open}
			anchorEl={anchorEle}
			onClose={handleClosed}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<Grid className="add-family-content">
				{isRelationShipLoadingList || isProfileLoading ? (
					<Grid
						item
						display="flex"
						alignItems="center"
						justifyContent="center"
						className="mt-5"
					>
						<Spinner />
					</Grid>
				) : (
					relationShipList &&
					relationShipList.map((data, index) => (
						<Grid item key={index}>
							<CustomButton
								text={`${data.relationship}`}
								variant="text"
								color="inherit"
								showIcon={false}
								className="f-16 fw-600 px-15 py-13 text-style"
								onClick={() => handleAddMember(data)}
							/>
							<Grid item className="w-100">
								{index !== relationShipList.length - 1 && (
									<Grid item className="w-100">
										<Separator color={theme.palette.primary.main} />
									</Grid>
								)}
							</Grid>
						</Grid>
					))
				)}
			</Grid>
		</CustomPopOver>
	);
};

export default AddFamilyMemberContent;
