import React, { useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import SvgConverter from '../../../common/SvgConverter';
// import HdfcIcon from '../../../assets/images/HDFC.png';
import './LoanModule.scss';
import { ReactComponent as RoundBlackicon } from '../../../common/icons/LoanModule/round_black_icon.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import SelectedRecommendProductDetails from './SelectedRecommendProductDetails';
import FormInputWithRupee from './FormInputWithRupee';
import { ReactComponent as PlusIcon } from '../../../common/icons/LoanModule/Plus-icon.svg';
import TotalFundAndLossCoverDetails from './TotalFundAndLossCoverDetails';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import Separator from '../../../common/ui/Separator';
import MaturityAndFundValue from './MaturityAndFundValue';
import FinancierNameAndLoanAmountContent from './FinancierNameAndLoanAmountContent';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/LoanModule/left_arrow_icon.svg';
import NeedMoneyHeader from './NeedMoneyHeader';
import { selectedCombination } from './Loan.slice';
import CustomButton from '../../../common/ui/CustomButton';
import { convertAmount } from '../../../common/utils';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';

const RecommendedCombinationSelected = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const handleGoBack = () => {
		navigate(APP_ROUTES.MY_OPTIONS);
	};
	const theme = useTheme();
	const loanOptionScreenData: any = useSelector(selectedCombination);
	const userData: any = useSelector(selectIsUserLoggedIn);
	const recommendedCombinationSelectedData = loanOptionScreenData?.Combination;
	const [selectedFinancier, setSelectedFinancier]: any = useState(null);
	const [selectedRepaymentOption, setSelectedRepaymentOption]: any =
		useState(null);
	const requiredAmount = loanOptionScreenData?.requiredAmount || '';
	const requiredDate = loanOptionScreenData?.requiredDate || '';

	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
	} = useForm();

	const onSubmit = (data: any) => {
		const modifiedData: any = {
			combinationName: recommendedCombinationSelectedData?.combinationName,
			totalCharges: recommendedCombinationSelectedData?.totalCharges,
			totalChargesDetails:
				recommendedCombinationSelectedData?.totalChargesDetails,
			totalFundsAvailed: recommendedCombinationSelectedData?.totalFundsAvailed,
			lossOfCover: recommendedCombinationSelectedData?.lossOfCover,
			Widgets: recommendedCombinationSelectedData?.Widgets.map(
				(widget: any) => {
					const commonProperties: any = {
						sessionId: userData?.sessionId,
						panNo: userData?.pan,
						camsRefNo: '',
						BankDetails: {
							bankAcNo: 'Y',
							bankName: 'SBI',
							bankBranch: 'TNagar Chennai',
							bankIfscCode: 'SBI00034',
							address: 'chn',
							bankState: 'TN',
							pincode: '455653',
						},
					};
					const clonedWidget: any = { ...widget, ...commonProperties };
					if (clonedWidget?.widgetType === 'W') {
						clonedWidget.Withdrawal = {
							...widget?.Withdrawal,
							partialWithdrawalAmount: data[widget.widgetNo],
						};
					}
					if (clonedWidget?.widgetType === 'L') {
						clonedWidget.Loan = {
							...widget?.Loan,
							loanAmount: data[widget.widgetNo],
						};
					}
					if (clonedWidget?.Loan) {
						const filteredLenderDetails =
							clonedWidget?.Loan?.LenderDetails?.filter(
								(lender: any) => lender?.lenderCode === selectedFinancier
							);
						clonedWidget.Loan = {
							...clonedWidget.Loan,
							LenderDetails: filteredLenderDetails[0],
						};
						const repaymentCodeToKeep = selectedRepaymentOption;
						if (
							clonedWidget?.Loan?.LenderDetails &&
							clonedWidget?.Loan?.LenderDetails?.RepaymentOptions
						) {
							const repaymentOptions =
								clonedWidget?.Loan?.LenderDetails?.RepaymentOptions;
							const filteredRepaymentOptions = repaymentOptions.filter(
								(repaymentOption: any) =>
									repaymentOption.repaymentCode === repaymentCodeToKeep
							);
							clonedWidget.Loan = {
								...clonedWidget.Loan,
								LenderDetails: {
									...clonedWidget.Loan.LenderDetails,
									repaymentOption:
										filteredRepaymentOptions[0]?.repaymentCode ?? '',
									repaymentOptionDescription:
										filteredRepaymentOptions[0]?.repaymentOptionDescription ??
										'',
									// loanAmount:data[widget.widgetNo] ??"",
								},
							};
							delete clonedWidget.Loan.LenderDetails.RepaymentOptions;
						}
					}
					return clonedWidget;
				}
			),
		};
		if (
			loanOptionScreenData?.Combination?.Widgets?.some(
				(widget: any) => widget.widgetType === 'L'
			)
		) {
			navigate(APP_ROUTES.ADD_WITNESS_DETAILS, {
				state: {
					modifiedData,
					requiredAmount,
					requiredDate,
					previousScreen: 'loanOptionscreen',
				},
			});
		} else {
			navigate(APP_ROUTES.SUBMIT_LOAN_APPLICATION, { state: modifiedData });
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid item className="mb-20">
					<NeedMoneyHeader />
				</Grid>
				<Grid container justifyContent="center">
					<Grid item>
						<Grid item sm={12}>
							<Typography
								className="f-16 fw-600"
								color={theme.palette.primary.main}
								flexWrap="nowrap"
							>
								{t('Recommended Combination selected')}
							</Typography>
						</Grid>
						<Grid item className="mb-14">
							<CustomButton
								fontSize={14}
								fontWeight={600}
								text={t('Go Back')}
								showIcon={false}
								startIcon={ArrowLeftIcon}
								variant="text"
								color="primary"
								startIconClassName="go-back-icon"
								className="f-14 "
								onClick={() => {
									handleGoBack();
								}}
							/>
						</Grid>
						<Grid item className="mb-20">
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<>
							{loanOptionScreenData?.Combination?.Widgets?.map(
								(widget: any, index: any) => (
									<>
										{(widget?.widgetType === 'W' ||
											widget?.widgetType === 'S') && (
											<Grid item className="recommend-select">
												<Grid item>
													<SelectedRecommendProductDetails
														Icon={RoundBlackicon}
														logoSrc={widget?.insurerLogo}
														product={widget?.productType}
														selfName={widget?.payerName}
														showValues={false}
													/>
												</Grid>
												{widget?.widgetType === 'S' && (
													<>
														<Typography
															component="span"
															className="recommend-icon-number two f-28"
															color={theme.palette.common.black}
														>
															{widget?.widgetNo}
														</Typography>

														<Grid
															item
															bgcolor="#f2f2f2"
															className="p-10 top-border"
														>
															<Grid container spacing={0.5}>
																<Grid item>
																	<Typography
																		className="f-14"
																		color={theme.palette.common.black}
																	>
																		{t('Payout of')}
																	</Typography>
																</Grid>
																<Grid item>
																	<LabelWithIcon
																		className="px-6"
																		svgClassName=""
																		justifyContent="flex-start"
																		Icon={RupeeIcon}
																		label={
																			widget?.Withdrawal
																				?.partialWithdrawalAmount
																		}
																		labelProps={{
																			color: theme.palette.primary.main,
																			fontSize: 16,
																			fontWeight: 600,
																		}}
																	/>
																</Grid>
															</Grid>
															<Typography className="f-14">
																{t('to be recieved on')} DDMMYYYY
															</Typography>
														</Grid>
													</>
												)}

												{widget?.widgetType === 'W' && (
													<>
														<Typography
															component="span"
															className="recommend-icon-number one f-28"
															color={theme.palette.common.black}
														>
															{widget?.widgetNo}
														</Typography>
														<Grid
															item
															bgcolor="#f2f2f2"
															className="p-10 top-border"
														>
															<MaturityAndFundValue maturityValue={widget} />
															<Grid item>
																<Grid container spacing={1} className="mt-4">
																	<Grid item>
																		<Typography
																			className="f-14"
																			color={theme.palette.common.black}
																		>
																			{t('Partial Withdrawal of')}
																		</Typography>
																	</Grid>

																	<Grid item>
																		<Typography
																			className="f-12"
																			color={theme.palette.common.black}
																			justifyContent="center"
																			display="flex"
																			alignItems="center"
																		>
																			[
																			<Typography
																				className="f-12 fw-600"
																				color="#f99117"
																				justifyContent="center"
																			>
																				{
																					widget?.Withdrawal
																						?.partialWithdrawalPercentage
																				}
																			</Typography>
																			{t('of fund value')}]
																		</Typography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid className="mt-4">
																<FormInputWithRupee
																	inputValue={
																		widget?.Withdrawal?.partialWithdrawalAmount
																	}
																	name={widget?.widgetNo}
																	control={control}
																	error={errors}
																	setValue={setValue}
																/>
															</Grid>
															<Typography className="f-14 mt-5">
																{t('to be initiated.')}
															</Typography>
														</Grid>
													</>
												)}
											</Grid>
										)}
										{widget?.widgetType === 'L' && (
											<>
												<Grid item key={widget?.widgetNo}>
													<FinancierNameAndLoanAmountContent
														financierData={widget}
														totalAmount={recommendedCombinationSelectedData}
														control={control}
														errors={errors}
														setValue={setValue}
														onFinancierSelect={(financierId: any) =>
															setSelectedFinancier(financierId)
														}
														onRepaymentOptionSelect={(optionCode: any) =>
															setSelectedRepaymentOption(optionCode)
														}
													/>
												</Grid>
											</>
										)}

										{index !==
											(recommendedCombinationSelectedData?.Widgets?.length ??
												0) -
												1 && (
											<Grid
												item
												container
												justifyContent="center"
												className="mt-20 mb-20"
											>
												<SvgConverter Icon={PlusIcon} />
											</Grid>
										)}
									</>
								)
							)}
						</>
						<Grid item className="my-10">
							<TotalFundAndLossCoverDetails
								TotalNo={`(${Array.from(
									{
										length: recommendedCombinationSelectedData?.Widgets?.length,
									},
									(_, i) => i + 1
								).join('+')})`}
								totalFunds={convertAmount(
									recommendedCombinationSelectedData?.totalFundsAvailed
								)}
								combination={recommendedCombinationSelectedData}
								lossOfCover={convertAmount(
									recommendedCombinationSelectedData?.lossOfCover
								)}
								approxShow={false}
							/>
						</Grid>
						<Grid item className="mb-15 mt-10">
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<Grid container spacing={1} justifyContent="flex-end">
							{/* <Link to={APP_ROUTES.ADD_WITNESS_DETAILS} state={modifiedData}>  */}
							<CustomButton
								fontSize={16}
								fontWeight={600}
								text={t('PROCEED')}
								showIcon
								endIcon={rightArrow}
								variant="text"
								color="primary"
								type="submit"
								disabled={
									(selectedFinancier && selectedRepaymentOption) === null
								}
							/>
							{/* </Link>  */}
						</Grid>
						<Grid item className="mb-10 mt-10">
							<Separator color={theme.palette.grey[300]} />
						</Grid>

						<Grid
							display="flex"
							alignItems="end"
							justifyContent="end"
							className="mb-50"
						>
							<Typography
								className="f-12 fw-400"
								color={theme.palette.common.black}
							>
								{t('Terms & Conditions Apply')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</>
	);
};

export default RecommendedCombinationSelected;
