import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';
import theme from '../../../common/theme';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as Add } from '../../../common/icons/add.svg';
import './Policy.scss';
import PolicyTab from './PolicyTab';
import CustomButton from '../../../common/ui/CustomButton';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import Separator from '../../../common/ui/Separator';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import { fetchPolicyDocumentDownload } from './Policies.slice';
import ApiModal from '../../../common/ui/Modal/ApiModal';
import PDFModal from '../../../common/ui/Modal/pdfModal';

const PolicyScreen = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const prevPath = location?.state;

	const { t } = useTranslation();

	const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);
	const downloadStatementOfHolding = () => {
		dispatch(
			fetchPolicyDocumentDownload({
				holdingStatement: 'Y',
				documentFlag: 'STATEMENT_OF_HOLDING',
				t,
			} as any)
		);
		setOpen(false);
	};
	const downloadAnnualAccountStatement = () => {
		dispatch(
			fetchPolicyDocumentDownload({
				annualAccountStatement: 'Y',
				documentFlag: 'ANNUAL_ACCOUNT_STATEMENT',
				t,
			} as any)
		);
		setOpen(false);
	};
	return (
		<>
			<Grid container>
				<Grid lg={12} md={12} sm={12} xs={12} className="policy-header mb-30">
					<Grid item container className="policy-arrow">
						<Grid item>
							<Link to={APP_ROUTES.DASHBOARD}>
								<IconButton>
									<SvgConverter
										Icon={ArrowLeftIcon}
										className="go-back-icon ml-15"
									/>
								</IconButton>
							</Link>
						</Grid>
						<Grid item>
							<Typography
								className="f-16 fw-600 mt-8"
								color={theme.palette.primary.main}
							>
								{t('POLICIES_MENU')}
							</Typography>
						</Grid>
					</Grid>
					<Grid item>
						<Grid
							container
							display="flex"
							flexDirection="row"
							alignItems="center"
						>
							<Grid item className="mt-10">
								<Link to={APP_ROUTES.ADD_POLICY_INSTRUCTION}>
									<LabelWithIcon
										Icon={Add}
										label={t('ADD_POLICY')}
										labelProps={{
											color: theme.palette.primary.main,
											fontSize: 14,
										}}
									/>
								</Link>
							</Grid>
							<Grid xs={2} className="i-class">
								<CustomButton
									text={<MoreVertIcon />}
									variant="text"
									fontSize={10}
									showIcon={false}
									onClick={() => setOpen(true)}
									color="warning"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} className="policy-tab">
					<PolicyTab prevPath={prevPath} />
				</Grid>
				<CommonModal
					onClose={handleClose}
					boxProps={{ height: 99, width: 320 }}
					open={open}
					modalClassName="modal-border"
				>
					<Grid container>
						<Grid item xs={12}>
							<CustomButton
								showIcon={false}
								text={t('STATEMENT_OF_HOLDING')}
								className="f-16 fw-600 px-12 py-10 download_button"
								variant="text"
								onClick={downloadStatementOfHolding}
							/>
							<Separator color={theme.palette.primary.main} />{' '}
							<CustomButton
								showIcon={false}
								text={t('ANNUAL_ACCOUNT_STATEMENT')}
								className="f-16 fw-600 px-12 py-10 download_button"
								variant="text"
								onClick={downloadAnnualAccountStatement}
							/>
						</Grid>
					</Grid>
				</CommonModal>
			</Grid>
			<ApiModal />
			<PDFModal/>
		</>
	);
};

export default PolicyScreen;
