import { Grid } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { ReactComponent as cancelIcon } from '../../../../../common/icons/cancel-icon.svg';
import { APP_ROUTES } from '../../../../../common/constants/Routes';
import CustomButton from '../../../../../common/ui/CustomButton';
import theme from '../../../../../common/theme';

const PolicyDetailsHeader = () => {
	const { t } = useTranslation();
	return (
		<Grid item container lg={12} md={12} xl={12} sm={12} mb={5}>
			<Grid
				item
				container
				lg={12}
				md={12}
				xl={12}
				sm={12}
				justifyContent="space-between"
				className="sticky-header "
			>
				<Grid item mt={2}>
					<Typography
						className="f-16 fw-600 ml-20"
						color={theme.palette.primary.main}
					>
						{t('POLICY_DETAILS')}
					</Typography>
				</Grid>
				<Grid item className="mr-45" mt={2}>
					<Link to={APP_ROUTES.POLICY}>
						<CustomButton
							className="f-14  "
							text={t('CLOSE')}
							variant="text"
							color="primary"
							endIcon={cancelIcon}
							type="submit"
						/>
					</Link>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PolicyDetailsHeader;
