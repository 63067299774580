import { Box, Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as rightArrow } from '../../../../common/icons/right_arrow-icon.svg';
import { CenterFocusStrong } from '@mui/icons-material';
import CustomButton from '../../../../common/ui/CustomButton';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import ImagePickerModal from '../../../../common/ui/Modal/ImagePickerModal';
import { ReactComponent as UploaderIcon } from '../../../../common/icons/update-mobile/Uploader_icon.svg';
import { ReactComponent as LineIcon } from '../../../../common/icons/normal_line.svg';
import CameraIcon from '../../../../common/icons/camera.png';
import UploadIcon from '../../../../common/icons/upload-img.png';
import DigilokerIcon from "../../../../common/icons/DigiLocker_Icon.png"
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as CloseIcon } from '../../../../common/icons/close.svg';
import { useEffect, useState } from 'react';
import UploadCard from '../../../../common/ui/UploadCard';
import { useDispatch } from 'react-redux';
import { requestInsSamadhanUploadDoc } from '../addPolicy.slice';



const AddPolicyModal = ({ open, setOpen, ClickAddPolicyViaNumber }: any) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const dispatch = useDispatch();

    const [capturedImage, setCapturedImage] = useState({ base64: null, fileName: null });
    const [fileUploadIMg, setFileUploadIMg] = useState({ base64: null, fileName: null });
    const clicked = null;
    const file = null;

    useEffect(() => {
        if (fileUploadIMg.base64 && fileUploadIMg.fileName) {
            dispatch(requestInsSamadhanUploadDoc({ documentBase64: fileUploadIMg?.base64, documentType: 'INSAPDF', 'requestType': 'INSA', fileName: fileUploadIMg.fileName, docExtensionType: String(fileUploadIMg.fileName).split(".").pop() }));
            setOpen(false);
            setFileUploadIMg({ base64: null, fileName: null });
        }
    }, [fileUploadIMg])

    useEffect(() => {
        if (capturedImage.base64 && capturedImage.fileName) {
            dispatch(requestInsSamadhanUploadDoc({ documentBase64: capturedImage?.base64, documentType: 'INSAIMAGE', 'requestType': 'INSA', fileName: capturedImage.fileName, docExtensionType: String(capturedImage.fileName).split(".").pop() }));
            setOpen(false);
            setCapturedImage({ base64: null, fileName: null });
        }
    }, [capturedImage])
    return (
        <CommonModal
            //onClose={() => setOpen(false)}
            open={open}
            modalClassName="px-10 py-20"
            boxProps={{ width: 335 }}
        >
            <Grid container justifyContent={'right'} style={{ position: 'absolute' }} className='pr-10' >
                <CustomButton
                    text={''}
                    onClick={() => setOpen(false)}
                    variant="text"
                    endIcon={CloseIcon}
                    color="inherit"
                    className="f-16 fw-400"
                />
            </Grid>
            <Grid container justifyContent={'center'} style={{ maxHeight: '95vh', overflowY: 'auto', marginTop: '25px' }}>
                <Box
                    marginBottom={5}
                    display="flex"
                    width={295}
                    justifyContent="center"
                    flexDirection="column"
                   

                >

                    <Grid container justifyContent={'center'} >

                        <Grid >
                            <Grid container justifyContent={'flex-start'}>
                                <Typography
                                    className="f-16 fw-600 mb-5"
                                    color={theme.palette.primary.main}
                                >
                                    {t('UPLOAD_POLICY_IMAGES')}
                                </Typography>
                            </Grid>

                            <Grid container columnSpacing={0.5}>

                                <Grid item>
                                    <Typography className="f-14 fw-400 ">
                                        {t('UPLOAD_ATLEAST')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="f-14 fw-600">
                                        {t('FIRST_PAGE')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className="f-14 fw-400">
                                        {`${t('OF_POLICY')}.`}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* <Grid container>

                                <Typography className="f-14 fw-400 mb-5" color="black">
                                    {`${t('UPLOAD_MULTIPLE_IMAGES')}.`}
                                </Typography>
                            </Grid> */}


                            <UploadCard uploadedIMG={capturedImage} setUpload={setCapturedImage} />


                            <Grid container justifyContent={'flex-start'}>
                                <Typography
                                    className="f-16 fw-600 mb-5 mt-20"
                                    color={theme.palette.primary.main}
                                >
                                    {t('UPLOAD_POLICY_PDF')}
                                </Typography>
                            </Grid>
                            
                            {/* <Grid container justifyContent={'flex-start'}>
                                <Typography className="f-14 fw-400 mb-5" color="black">
                                    {t('YOU_CAN_UPLOAD_MULTIPLE_PDF')}
                                </Typography>
                            </Grid> */}

                            <UploadCard isPDFUpload={true} uploadedIMG={fileUploadIMg} setUpload={setFileUploadIMg} />


                            {/* < Grid container justifyContent={'flex-start'} >
                                <Typography
                                    className="f-16 fw-600 mb-5 mt-20"
                                    color={theme.palette.primary.main}
                                >
                                    {t('UPLOAD_POLICY_DIGILOCKER')}
                                </Typography>
                            </Grid >
                            <Grid container justifyContent={'flex-start'} alignItems='center'>
                                <Grid item>
                                    <img src={DigilokerIcon} width={43} />
                                </Grid>

                                <Grid item style={{ color: '#0000FF' }}>
                                    <CustomButton
                                        text={t('TAP_TO_OPEN_DIGILOCKER')}

                                        variant="text"
                                        showIcon={false}
                                        color="inherit"
                                        className="f-16 fw-400"
                                    />
                                </Grid>
                            </Grid> */}
                        </Grid>

                    </Grid >

                    <Grid container justifyContent={'flex-start'} style={{ color: '#0000FF' }}>
                        <Typography
                            className="f-16 fw-600 mb-5 mt-20"
                            color={theme.palette.primary.main}
                        >
                            {t('ONBOARD_POLICY_USING_POLICY_NUMBER')}
                        </Typography>
                        <CustomButton
                            text={t('TAP_TO_ENTER_POLICY_NUMBER')}
                            onClick={ClickAddPolicyViaNumber}
                            variant="text"
                            showIcon={false}
                            color="inherit"
                            className="f-16 fw-400 mt-10"
                        />
                    </Grid>


                    <ImagePickerModal
                        open={false}
                        setOpen={setOpen}
                        handleImage={(data: any, file: any) => { }
                            // processImage(data, file, indexBy)
                        }
                        disableWebcam
                    />
                </Box >
            </Grid >
        </CommonModal >
    );
};

export default AddPolicyModal;
