import React, { useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddressSection from '../AddressSection';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import { fetchAddressFromPolicy, selectPoliciesForAddress } from '../Address.slice';
import { useSelector } from 'react-redux';

const UnassignedAddress = ({
	addressList,
	handleAssignPolicies,
	setRemoveUnAssignedAddress,
}: any) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const addressPolicies = useSelector(selectPoliciesForAddress)?.addressBasedPolicies;
	const unassignedPolicies = useSelector(selectPoliciesForAddress)?.unAssignedPolicies;

	const policiesForAddress = useMemo(() => [...addressPolicies || [], ...unassignedPolicies || []]
		, [addressPolicies, unassignedPolicies])

	// const policiesForAddress = useSelector(selectPoliciesForAddress)?.addressBasedPolicies ? [...useSelector(selectPoliciesForAddress)?.addressBasedPolicies, ...useSelector(selectPoliciesForAddress)?.unAssignedPolicies] : [];

	const anyIntegPolicies = useMemo(() =>
		policiesForAddress.some((item: any) => item.insIntegFlag === 'Y')
		, [policiesForAddress]);
	return (
		<>
			{addressList.length !== 0 && (
				<>
					<Grid item xs={12}>
						<Typography
							className="f-16 fw-600 my-10"
							color={theme.palette.error.main}
						>
							{t('UNASSIGNED_ADDRESSES')}
						</Typography>
					</Grid>
					<Grid item className="my-10">
						<Separator color={theme.palette.primary.main} borderWidth={1} />
					</Grid>
					<Grid container spacing={5}>
						{addressList?.map((address: any, index: number) => (
							<Grid item key={address?.fullAddress}>
								<Grid item className="Manage-bank">
									<Grid
										container
										justifyContent="space-between"
										alignItems="center"
										wrap="nowrap"
										className="mb-5"
									>
										<Grid item className="ml-n20" flex={1}>
											<Grid container spacing={1}>
												<Grid item>
													<Typography
														color="black"
														fontSize={20}
														fontWeight={600}
													>
														{index + 1}.
													</Typography>
												</Grid>
												<Grid item className="mt-3">
													<Typography
														color="primary"
														fontSize={16}
														fontWeight={600}
													>
														{address?.city} {t('ADDRESS')}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<CustomButton
												text={t('REMOVE')}
												variant="text"
												color="primary"
												fontSize={14}
												showIcon={false}
												fontWeight={400}
												onClick={() =>
													setRemoveUnAssignedAddress({
														...address,
														isUnaAssigned: true,
													})
												}
											/>
										</Grid>
									</Grid>
									<Grid item>
										<AddressSection data={address} />
									</Grid>
									<Grid item className="mt-3 ml-n3">
										<CustomButton
											text={t('ASSIGN_POLICIES')}
											variant="text"
											color="primary"
											fontSize={14}
											showIcon={false}
											fontWeight={400}
											onClick={() =>
												handleAssignPolicies({
													...fetchAddressFromPolicy(address),
													isUnaAssigned: true,
												})
											}
											disabled={!anyIntegPolicies}
										/>
									</Grid>
								</Grid>
							</Grid>
						))}
					</Grid>
				</>
			)}
		</>
	);
};

export default UnassignedAddress;
