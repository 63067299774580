import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../common/theme';
import '../../Policy.scss';

import SvgConverter from '../../../../../common/SvgConverter';
import Separator from '../../../../../common/ui/Separator';

import CustomButton from '../../../../../common/ui/CustomButton';

import { ReactComponent as BigOrangeRuppee } from '../../../../../common/icons/Big-orange-rupee.svg';
import { APP_ROUTES } from '../../../../../common/constants/Routes';

interface UserProps {
	items?: any;
}
const PayNow: React.FC<UserProps> = ({ items }) => {
	// const dateValue = items && items?.premuimDueDate;
	// const formattedDate = getFormatDate(dateValue, 'YYYY-MM-DD');

	const { t } = useTranslation();

	return (
		<>
			<Grid container className="py-5 p-5 px-5 premium-container">
				{/* <Grid item xs={1}>
					<SvgConverter Icon={Premium} className="ml-6 mt-5" />
				</Grid> */}
				{/* <Grid item xs={5}>
					<Typography className="f-14 px-6" color={theme.palette.primary.main}>
						{t('PREMIUM')}
					</Typography>
					<LabelWithIcon
						className="px-6"
						svgClassName="mb-2"
						justifyContent="flex-start"
						Icon={OrangeRuppee}
						label={
							items.premiumAmount &&
							items.premiumAmount !== '' &&
							items.premiumAmount !== '0'
								? items.premiumAmount
								: '-'
						}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 14,
							fontWeight: 600,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography className="f-14  mt-4" color={theme.palette.primary.main}>
						{t('DUE_BY')} {items?.premuimDueDate ? formattedDate : '-'}
					</Typography>
				</Grid> */}
				<Grid
					item
					container
					xs={12}
					className="py-10 px-10"
					justifyContent={items.financeMyPremium.toUpperCase() === 'Y' ? "space-between" : 'flex-end'}
				>
					{String(items?.paymentFlag).toUpperCase() === 'Y' ? (
						<>
							<Grid item xs={12}>
								<Separator color={theme.palette.primary.main} />
							</Grid>
							{items.financeMyPremium.toUpperCase() === 'Y' && <Grid
								container
								item
								xs={5}
								className="finance-border mt-10"
								alignItems="center"
							>
								<Grid item xs={2}>
									<SvgConverter Icon={BigOrangeRuppee} className="ml-6 mt-5" />
								</Grid>

								<Grid item xs={10}>
									{process.env.REACT_APP_MODE !== 'PROD' && (
										<Link to="">
											<Typography
												className="f-12 px-6 fw-600"
												color={theme.palette.primary.main}
											>
												{t('FINANCE_MY_PREMIUM')}
											</Typography>
										</Link>
									)}
								</Grid>
							</Grid>}
							<Grid item className="mt-17">
								<Link
									to={
										APP_ROUTES.RENEWAL_PAYMENT_SCREEN	
									}
									state={{ data: items }}
								>
									<CustomButton
										text={t('PAY_NOW')}
										variant="text"
										color="primary"
										fontSize={14}
										fontWeight={600}
										iconClassName="ml-4"
									/>
								</Link>
							</Grid>
						</>
					) : (
						<></>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default PayNow;
