import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Separator from '../../../common/ui/Separator';
// import SvgConverter from '../../../common/SvgConverter';
import { APP_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';
// import BankEntryModal from '../BankModule/BankModals/BankEntryModal';
import { appendBase64PrefixForImage } from '../../../common/utils';

interface BankDetailsInterface {
	profileResponse?: any;
}
const PersonalBankDetails = ({ profileResponse }: BankDetailsInterface) => {
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	const { t } = useTranslation();

	const handleClick = () => {
		setOpen(true);
	};
	const BankType = 'profile';
	useEffect(() => {
		setOpen(open);
	}, []);
	return (
		<Grid container>
			{/* <Grid container> */}
			{profileResponse && profileResponse.length > 0 ? (
				profileResponse.map((item: any, index: any) => (
					<Grid item xs={12} sm={6} lg={3} md={6} key={index}>
						<Grid display="flex">
							<Grid item className="profile-personal-address ">
								<Typography
									className="f-16 fw-600 personal-email"
									color="primary"
								>
									{item?.bankName.length === 0 ? '-' : item?.bankName}
								</Typography>
								<Typography
									className="f-16 personal-email"
									color={theme.palette.common.black}
								>
									{item?.accountHolderName.length === 0
										? '-'
										: item?.accountHolderName}
								</Typography>
								<Typography
									className="f-16 personal-email"
									color={theme.palette.common.black}
								>
									{item?.accountNo.length === 0 ? '-' : item?.accountNo}
								</Typography>
								<Typography
									className="f-16 personal-email"
									color={theme.palette.common.black}
								>
									{item?.bankName.length === 0 ? '-' : item?.bankName}
								</Typography>
								<Typography
									className="f-16 personal-email"
									color={theme.palette.common.black}
								>
									{item?.bankBranch.length === 0 ? '-' : item?.bankBranch}
								</Typography>
								<Typography
									className="f-16 personal-email"
									color={theme.palette.common.black}
								>
									{item?.ifscCode.length === 0 ? '-' : item?.ifscCode}
								</Typography>

								<Link to={APP_ROUTES.BANK}>
									<CustomButton
										text={t('SEE_ASSIGN_POLICIES')}
										variant="text"
										showIcon={false}
										color="primary"
										className="f-12 mt-25 mb-10 fw-400"
									/>
								</Link>
							</Grid>
							<Grid item className="mt-20">
								<Grid container flexDirection="column">
									<Grid item className="">
										<img
											src={item.bankLogo}
											alt="logo"
											width="73"
											height="77"
										/>
									</Grid>
									{item?.chqImg &&
										<Grid item className="ml-n18">
											<img
												src={appendBase64PrefixForImage(item?.chqImg)}
												alt="cheque"
												style={{
													width: '30%',
													height: 150,
													marginLeft: '20px',
													marginTop: '-50px',
													objectFit: 'contain',
												}}
											/>
										</Grid>
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				))
			) : (
				<>
					<Grid item xs={12} spacing={0.5}>
						<Grid item>
							<Grid item>
								<Typography
									className="f-16 fw-600"
									color="primary"
									flexWrap="nowrap"
								>
									{t('BANK')}
								</Typography>
							</Grid>
							<Grid item className="mt-10 ml-n3">
								<Link to={APP_ROUTES.BANK} state={BankType}>
									<CustomButton
										text={t('TAP_TO_ADD_BANK_ACCOUNT')}
										variant="text"
										color="primary"
										showIcon={false}
										onClick={handleClick}
									/>
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
			{/* </Grid> */}
			<Grid item xs={12} className="mt-14">
				<Separator color={theme.palette.primary.main} />
			</Grid>
		</Grid>
	);
};

export default PersonalBankDetails;
