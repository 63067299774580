import React from 'react';
import { Grid } from '@mui/material';
import '../Nominee.scss';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as Correct } from '../../../../common/icons/tick-icon.svg';
import { ReactComponent as Cancel } from '../../../../common/icons/Cancel-small.svg';
import { ReactComponent as Edit } from '../../../../common/icons/edit.svg';
import CommonModal from '../../../../common/ui/Modal/CommonModal';

const NomineeModal = ({ open, setOpen, FinalSubmitModal, isLoading }: any) => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	return (
		<CommonModal
			// onClose={() => setOpen(false)}
			open={open}
			modalClassName="nominee-modal px-20 py-20"
			boxProps={{ width: 354, height: 400 }}
		>
			<Grid container className="">
				<Grid item xs={12} textAlign="center" className="py-20">
					<Typography
						fontSize={18}
						fontWeight={600}
						color={theme.palette.primary.main}
						className="mb-2"
					>
						{t('CHANGES_IN NOMINEES')}
					</Typography>
					<Typography
						fontSize={18}
						fontWeight={600}
						color={theme.palette.primary.main}
						className="mt-15 "
						textAlign="center"
					>
						{t('CHANGES_FORWARDED_NOMINEE_DETAILS')}
					</Typography>
				</Grid>
				{isLoading ? (
					<Grid item xs={12} textAlign="center" className="mt-20">
						<CircularProgress />
					</Grid>
				) : (
					<>
						<Grid item xs={12} textAlign="center" className="mt-20">
							<CustomButton
								className="f-16 fw-600"
								text={t('SUBMIT_CHANGES')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Correct}
								onClick={FinalSubmitModal}
							/>
						</Grid>
						<Grid item xs={12} textAlign="center" className="mt-15">
							<CustomButton
								className="f-16 fw-400"
								text={t('CONTINUE_EDITING')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Edit}
								onClick={() => setOpen(false)}
							/>
						</Grid>
						<Grid item xs={12} textAlign="center" className="mt-16">
							<CustomButton
								className="f-16 fw-400"
								text={t('CANCEL_&_EXIT')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Cancel}
								onClick={() => navigate(-1)}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</CommonModal>
	);
};

export default NomineeModal;
