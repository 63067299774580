import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../common/store";
import { LoadingState } from "../../../common";

interface AddPolicyState {
    newPolicies: any;
    insurerList: any;
    existingPoliciesFromEIA: {
        status: LoadingState,
        data: any,
        error: any,
    },
    InsSamadhanUploadDoc: {
        status: LoadingState,
        data: any,
        error: any,
    },
    removePolicy: any
}

const initialState: AddPolicyState = {

    newPolicies: { "newManualPolicies": [{ code: "L", list: [] }, { code: "H", list: [] }, { code: "G", list: [] }], "newIntegratedPolicies": [] },
    insurerList: [],
    existingPoliciesFromEIA: {
        status: "idle",
        data: [],
        error: null
    },
    InsSamadhanUploadDoc: {
        status: "idle",
        data: [],
        error: null
    },
    removePolicy: null
}


const addPolicySlice = createSlice({
    name: 'addPolicies',
    initialState,
    reducers: {
        requestExistingPolicies: (state) => {
            state.existingPoliciesFromEIA.status = 'loading';
            state.existingPoliciesFromEIA.data = [];
            state.existingPoliciesFromEIA.error = null;
        },
        loadExistingPolicies: (state, action) => {
            state.existingPoliciesFromEIA.status = 'done';
            state.existingPoliciesFromEIA.data = action.payload;
            state.existingPoliciesFromEIA.error = null;
        },
        errorExistingPolicies: (state, action) => {
            state.existingPoliciesFromEIA.status = 'done';
            state.existingPoliciesFromEIA.error = action.payload;
            state.existingPoliciesFromEIA.data = [];
        },
        addManualPolicies: (state, action) => {
            state.InsSamadhanUploadDoc.status = 'done'
        },
        saveManualPolicies: (state, action) => {
            state.newPolicies.newManualPolicies = action.payload;
        },
        requestInsSamadhanUploadDoc(state, action) {
            state.InsSamadhanUploadDoc.status = 'loading';
            state.InsSamadhanUploadDoc.data = action.payload;
            state.InsSamadhanUploadDoc.error = null;

        },
        InsSamadhanUploadDocError(state, action) {
            state.InsSamadhanUploadDoc.status = 'done';
            state.InsSamadhanUploadDoc.data = action.payload;
            state.InsSamadhanUploadDoc.error = null;
        },
        addInsurer: (state, action) => {
            state.insurerList = action.payload
        },
        resetExistingPolicies: (state) => {
            return initialState;
        },
        removePolicy: (state, action) => {
            state.removePolicy = action.payload;
        }

    }
});

export const selectNewManualPolicies = (state: RootState) =>
    state.addPolicies.newPolicies?.newManualPolicies;

export const selectInsurer = (state: RootState) =>
    state.addPolicies?.insurerList;

export const selectExistingPoliciesFromEIA = (state: RootState) =>
    state.addPolicies?.existingPoliciesFromEIA?.data;

export const isLoadingExistingPoliciesFromEIA = (state: RootState) =>
    state.addPolicies?.existingPoliciesFromEIA?.status === "loading";

export const isLoadingInsSamadhanUploadDoc = (state: RootState) =>
    state.addPolicies.InsSamadhanUploadDoc.status === "loading";

export const {
    requestExistingPolicies,
    loadExistingPolicies,
    errorExistingPolicies,
    addManualPolicies,
    addInsurer,
    resetExistingPolicies,
    saveManualPolicies,
    requestInsSamadhanUploadDoc,
    InsSamadhanUploadDocError,
    removePolicy
} = addPolicySlice.actions;

export default addPolicySlice.reducer;