import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './legal.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const SecurityTips = () => {
	const { t } = useTranslation();

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Security Tips Screen',
		});
	}, []);

	return (
		<Grid container justifyContent="center">
			<Grid container justifyContent="center" className="mb-30 mt-30">
				<span className="arrow-span-security">
					<Link to={APP_ROUTES.DASHBOARD}>
						<SvgConverter Icon={ArrowLeftIcon} className="left-arrow-style" />
					</Link>
				</span>
				<Grid item xs={10} sm={5} className="content-merge">
					<Grid item>
						<Typography
							fontSize={18}
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 header-text-color"
						>
							{t('FOOTER_SECURITY_TIPS')}
						</Typography>
					</Grid>
					<Grid>
						<ul>
							<li>
								<Typography fontSize={15} className="text-color">
									{t('INVESTOR_BANK_ACCOUNT_DETAILS')}
								</Typography>
							</li>
							<li>
								<Typography fontSize={15} className="mt-10 text-color">
									{t('PAYMENT_INSTRUMENT_DETAILS_PROVIDED')}
								</Typography>
							</li>
							<li>
								<Typography fontSize={15} className="mt-10 text-color">
									{t('GENERATION_AND_STORING_PASSWORD')}
								</Typography>
							</li>
							<li>
								<Typography fontSize={15} className="mt-10 text-color">
									{t('BIOMETRIC_INFORMATION')}
								</Typography>
							</li>
							<li>
								<Typography fontSize={15} className="mt-10 text-color">
									{t('PHYSICAL_PHYSIOLOGICAL_AND_MENTAL_HEALTH')}
								</Typography>
							</li>
						</ul>
					</Grid>
				</Grid>
				<Grid item xs={10} sm={5} className="content-merge">
					<Grid container className="content-box p-20 mt-50">
						<Grid item>
							<Typography fontSize={15} className="tips-box-text">
								{t('CAMS_REPOSITORY_OR_ANY_OF_ITS_AUTHORIZED_REPRESENTATIVES')}{' '}
								<span className="email-text-color">
									Alert-phishing@camsrepository.com{' '}
								</span>
								{t('ON_ANY_SUCH_INCIDENT')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item className="mb-10" xs={10} sm={10}>
				<Grid container spacing={2}>
					<Grid item>
						<Typography
							fontSize={20}
							fontWeight={600}
							fontFamily="inherit"
							className="mb-5 header-text-color"
						>
							{t('ABOUT_PHISHING')}:
						</Typography>
					</Grid>
				</Grid>
				<Grid item>
					<Typography
						fontSize={18}
						fontWeight={500}
						fontFamily="inherit"
						className="mb-5 footer"
					>
						{t("IPHISHING'_IS_A_FORM_OF_INTERNET_PIRACY")}
					</Typography>
				</Grid>
				<Grid item>
					<Typography
						fontSize={18}
						fontWeight={500}
						fontFamily="inherit"
						className="mb-5 mt-10"
					>
						{t('AS_AN_ONLINE_USER,_USERS_SHOULD_BE_AWARE')}
					</Typography>
				</Grid>
				<Grid container>
					<ul>
						<li>
							<Typography fontSize={15} className="text-color">
								{t('PHISHING_ATTACKS_ARE_MEANT_FOR_STEALING')}
							</Typography>
						</li>
						<li>
							<Typography fontSize={15} className="mt-10 text-color">
								{t('POLICY_HOLDER_RECEIVES_AN_E-MAIL')}
							</Typography>
						</li>
						<li>
							<Typography fontSize={15} className="mt-10 text-color">
								{t('THE_EMAIL_MAY_CONTAIN_MESSAGE_TO_INVITE')}
							</Typography>
						</li>
						<li>
							<Typography fontSize={15} className="mt-10 text-color">
								{t('THE_POLICY_HOLDER_WILL_BE_DIRECTED')}
							</Typography>
						</li>
						<li>
							<Typography fontSize={15} className="mt-10 text-color">
								{t('THE_POLICY_HOLDER_MAY_BE_REQUIRED')}
							</Typography>
						</li>
						<li>
							<Typography fontSize={15} className="mt-10 text-color">
								{t('BY_PROVIDING_THE_ABOVE_DETAILS')}
							</Typography>
						</li>
					</ul>
				</Grid>
			</Grid>
			<Grid item xs={10} sm={10}>
				<Grid container className="box-line">
					<Grid item xs={10} sm={5} className="content-merge space">
						<Grid container>
							<Grid item alignItems="center" className="text-alignment">
								<CheckCircleIcon color="success" />
								<Typography
									fontSize={20}
									fontWeight={600}
									fontFamily="inherit"
									className="mb-5 header-text-color ml-5"
								>
									{t("DO'S")}
								</Typography>
							</Grid>
							<Grid item>
								<ul>
									<li>
										<Typography fontSize={15} className="text-color">
											{t('ALWAYS_LOGON_TO_A_SITE')}
										</Typography>
									</li>
									<li>
										<Typography fontSize={15} className="mt-10 text-color">
											{t('PROVIDE_YOUR_USER_ID')}
											https://www.camsrepository.com
										</Typography>
									</li>
									<li>
										<Typography fontSize={15} className="mt-10 text-color">
											{t('PLEASE_ENSURE_THAT_THE_URL')}
										</Typography>
									</li>
									<li>
										<Typography fontSize={15} className="mt-10 text-color">
											{t('ALWAYS_LOOK_FOR_THE_LOCK_SIGN')}
										</Typography>
									</li>
									<li>
										<Typography fontSize={15} className="mt-10 text-color">
											{t(
												'CAMS_REPOSITORY_OR_ANY_OF_ITS_AUTHORIZED_REPRESENTATIVES'
											)}
										</Typography>
									</li>
								</ul>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={10} sm={5} className="content-merge bod">
						<Grid className="space-left">
							<Grid item alignItems="center" className="text-alignment">
								<CancelIcon color="error" />
								<Typography
									fontSize={20}
									fontWeight={600}
									fontFamily="inherit"
									className="mb-5 header-text-color ml-5"
								>
									{t('DONTS')}
								</Typography>
							</Grid>
							<Grid item>
								<ul>
									<li>
										<Typography fontSize={15} className="text-color">
											{t('NEVER_CLICK_ON_ANY_HYPERLINK')}
										</Typography>
									</li>
									<li>
										<Typography fontSize={15} className="mt-10 text-color">
											{t('DON’T_PROVIDE_ANY_PERSONAL_INFORMATION')}
										</Typography>
									</li>
									<li>
										<Typography fontSize={15} className="mt-10 text-color">
											{t('PASSWORD_OR_PERSONAL_DETAILS')}
										</Typography>
									</li>
									<li>
										<Typography fontSize={15} className="mt-10 text-color">
											{t('PASSWORD_IS_STRICTLY_CONFIDENTIAL')}
										</Typography>
									</li>
								</ul>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={10} sm={10}>
				<Grid item xs={10} sm={5}>
					<Grid container>
						<Grid item>
							<Typography
								fontSize={20}
								fontWeight={600}
								fontFamily="inherit"
								className="mt-10"
							>
								{t('IF_YOU_ARE_A_VICTIM_OF_PHISHING,_PLEASE_DO_THE_FOLLOWING:')}
							</Typography>
						</Grid>
						<Grid item>
							<ul>
								<li>
									<Typography fontSize={15} className="text-color">
										{t('IMMEDIATELY_CHANGE_YOUR_PASSWORD.')}
									</Typography>
								</li>
								<li>
									<Typography fontSize={15} className="mt-10 text-color">
										{t('REPORT_PHISHING')}
									</Typography>
								</li>
								<li>
									<Typography fontSize={15} className="mt-10 text-color">
										{t('CHECK_YOUR_E-POLICY_STATEMENT')}
									</Typography>
								</li>
								<li>
									<Typography fontSize={15} className="mt-10 text-color">
										{t('REPORT_ANY_INCONSISTENCIES')}
									</Typography>
								</li>
							</ul>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default SecurityTips;
