import { Grid } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import ArchiveAssetsCard from '../ArchiveContent/ArchiveAssetsCard';

interface UserProps {
	filteredAssets?: any;
}
const ArchiveAssets: React.FC<UserProps> = ({ filteredAssets }) => (
	<Grid container justifyContent="row">
		{filteredAssets.map((data: any, index: any) => (
			<Grid item xs={12} sm={6} lg={4} key={index} className="mb-20">
				<ArchiveAssetsCard contentType="archive" items={data} />
			</Grid>
		))}
	</Grid>
);

export default ArchiveAssets;
