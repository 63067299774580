/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 16,
	border: '1px solid #797979',
	[`&.${linearProgressClasses.colorPrimary}`]: {
		borderRadius: 16,
		backgroundColor: 'white',
	},
	[`& .${linearProgressClasses.indeterminate}`]: {
		borderRadius: 16,
		backgroundColor: 'white',
	},
}));

// Inspired by the former Facebook spinners.

const LinearBar = React.forwardRef(
	(
		{
			height = 7,
			color = 'primary',
			progressVal,
			total,
			backgroundColor = 'white',
		}: {
			height?: number | undefined;
			color?: 'primary' | 'secondary' | 'error' | undefined;
			progressVal?: any;
			total?: any;
			backgroundColor?: 'white';
		},
		ref?: any
	) => {
		const clampProgress = Math.max(0, Math.min(1, progressVal));
		return (
			<Box sx={{ flexGrow: 1 }} ref={ref}>
				<BorderLinearProgress
					variant="determinate"
					value={clampProgress * 100}
					sx={{ height, backgroundColor }}
					color={color}
				/>
			</Box>
		);
	}
);

export default LinearBar;
