/* eslint-disable no-useless-escape */
const config = {
	camsIdKey: 'EncCoMMONdEC',
	camsIdIv: 'ComMonaesvectors',
	camsReqKey: 'Ld1FjdXen8fMV0tk',
	camsReqIv: 'camspayaesvector',
	camsDecKey: 'Ld1FjdXen8fMV0tk',
	camsChecksumKey:
		'9caa6a4e13d8c05c99a5e8612c90f2ecc2d87f9717d0624f4ad8bfd4e94f657c',
	camsMerchantId: '884233',
	camsSupplierId: '661429',
	// --------------- ETC --------------------------------------
	timeStampFormat: 'YYYY-MM-DDTHH:mm:ss.SSS',
	otpCount: 6,
	otpDuration: 30,
	mPinCount: 4,
	nameLength: 75,
	emailLength:50,
	// emailRegex:
	// 	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	emailRegex: /^[A-Za-z0-9._]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,

	addressRegex: /^[a-zA-Z0-9,.'-\/.:&()]+\s*(?:[a-zA-Z0-9,.'-\/.:&()]+\s*)*$/,
	PanRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
	mobileRegex: /^[0-9]{10}$/,
	eiaRegex: /^[0-9]{13}$/,
	alphabetsOnlyRegex: /^[a-zA-Z\s]+$/,
	ifscRegex: /^[A-Z]{4}0[A-Z0-9]{6}$/,

	numbersOnlyRegex: /^[0-9]+$/,
	pincode: /^\d{6}$/,
	mode: 'dev',
	vahanaCloudDomain: 'https://vahanahub-uat.vahanacloud.com',
	vahanacloudCred: {
		loginId: 'ramasubramaniyam.e@camsonline.com',
		orgid: 'CAMS-B7ERBOTJL0',
		appid: 'POLICYGENIE-9912XNWB10',
		clientsecret: 'bbba4bd5-5b46-49a5-bfd6-33169ccc5355',
		servicename: 'IFSC_DATA',
	},
};

export default config;
