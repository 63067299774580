import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as checked } from '../../../common/icons/profile/checked.svg';

const VerifiedNominee = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<Grid item container spacing={0.5} flexWrap="nowrap">
			<Grid item>
				<SvgConverter
					Icon={checked}
					width="11px"
					height="8px"
					className="authorized_tick_mark"
				/>
			</Grid>
			<Grid item>
				<Typography className="f-14" color={theme.palette.common.black}>
					{t('NOMINEE')}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default VerifiedNominee;
