import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import TrackerTransactionTimeline from './TrackerTransactionTimeLine';

interface UserProps {
	widget?: any;
	timeLine?: any;
	trackTransactionWholeData?: any;
}
const TimelineScreen = ({ widget, timeLine, trackTransactionWholeData }: UserProps) => {

	const loanFlag = widget?.Loan?.loanFlag
	const data = loanFlag === 'R' ? widget?.Loan?.ReAssignmentStatus : widget?.Loan?.AssignmentStatus;

	// const timelineData = [
	// 	{
	// 		title: 'Policyholder e-sign',
	// 		status: 'completed',
	// 		eta: 'DDMMYYYY, hh:mm',
	// 	},
	// 	{
	// 		title: 'Witness e-sign',
	// 		status: 'pending',
	// 	},
	// 	{
	// 		title: 'Financier e-sign',
	// 		status: 'yet-to-start',
	// 		eta: '2',
	// 	},
	// 	{
	// 		title: 'Insurer Process',
	// 		status: 'yet-to-start',
	// 		eta: '2',
	// 	},
	// 	{
	// 		title: 'Fund Disbursal',
	// 		status: 'yet-to-start',
	// 		eta: '1',
	// 	},
	// ];

	return (<Box
		className={
			timeLine
				? 'need-money-transaction-timeline-card'
				: 'transaction-timeline-card mt-20 '
		}
	>
		<Grid container item display="flex" justifyContent="center">
			<Grid item>
				<Timeline
					className="timeline"
					sx={{
						[`& .${timelineItemClasses.root}:before`]: {
							flex: 0,
							padding: 0,
						},
					}}
				>
					{data?.Customer && (
						<TrackerTransactionTimeline
							status={data?.Customer?.status}
							index={0}
							title="Policyholder e-sign"
							eta={data?.Customer?.signedDate}
						/>
					)}
					<>
						{data?.Witness && (
							<TrackerTransactionTimeline
								status={
									data?.Customer?.status === 'Pending'
										? ''
										: data?.Witness?.status
								}
								index={1}
								title="Witness e-sign"
								eta={data?.Witness?.signedDate}
								wholeData={trackTransactionWholeData}
								mobileNumber={data?.Witness?.witnessMobileNo}
								widget={widget}
							/>
						)}
					</>

					{(data?.Lender ||
						data?.LenderDetails) && (
							<TrackerTransactionTimeline
								status={
									data?.Witness?.status === 'Pending'
										? ''
										: data?.LenderDetails?.status
								}
								index={2}
								title="Financier e-sign"
								eta={
									data?.LenderDetails?.signedDate ||
									data?.LenderDetails?.eta
								}
							/>
						)}

					{data?.InsurerProcess && (
						<TrackerTransactionTimeline
							status={
								data?.LenderDetails?.status === 'Pending'
									? ''
									: data?.InsurerProcess?.status
							}
							index={3}
							title="Insurer Process"
							eta={
								data?.InsurerProcess?.completedDate ||
								data?.InsurerProcess?.eta
							}
							isEnd={Object.keys(data).length === 4}
						/>
					)}
					{data?.FundsDisbursal && (
						<TrackerTransactionTimeline
							status={
								data?.InsurerProcess?.status === 'Pending'
									? ''
									: data?.FundsDisbursal?.status
							}
							index={4}
							title="Fund Disbursal"
							eta={
								data?.FundsDisbursal?.completedDate ||
								data?.FundsDisbursal?.eta
							}
							isEnd={Object.keys(data).length === 5}
						/>
					)}
					{/* {timelineData.map((data:any, index:any) => (
							<TrackerTransactionTimeline
								status={data?.status}
								index={index}
								title={data?.title}
								eta={data?.eta}
							/>
						))} */}
				</Timeline>
			</Grid>
		</Grid>
	</Box>)
};
export default TimelineScreen;
