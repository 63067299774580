import React, { useEffect, useRef, useState } from 'react';
import { Divider, Grid, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import LinearBar from './ProgressBar/LinearBar';
import { ReactComponent as RefreshIcon } from '../icons/dashboard/circle_arrow-icon.svg';
import { ReactComponent as OrangeArrow } from '../icons/orange-arrow.svg';
import PolicyCardCost from '../../features/App/DashboardScreen/common/PolicyCardCost';
import SvgConverter from '../SvgConverter';
import { convertAmount } from '../utils';
import { dashboardListRequest } from '../../features/App/DashboardScreen/Dashboard.slice';

const LifeProgressBarWithValues = ({
	progressVal,
	total,
	updatedDate,
	currentValue,
	policyDetails
}: any) => {
	const dispatch = useDispatch();
	const [isFirstRender, setIsFirstRender] = useState(true);
	const firstCardRef = useRef<any>(null);
	const clampProgress = Math.max(0, Math.min(1, progressVal));
	const onClickRefresh = () => {
		dispatch(dashboardListRequest({}));
	};
	useEffect(() => {
		setIsFirstRender(false);
	}, []);
	return (
		<div className="" style={{ position: 'relative' }}>
			<div style={{ transform: 'rotateY(180deg)' }}>
				{!isFirstRender && (
					<LinearBar progressVal={progressVal} total={total} />
				)}
			</div>
			<Grid
				container
				flexWrap="nowrap"
				className="h-100 w-0"
				style={{
					position: 'absolute',
					right: `${clampProgress * 100}%`,

					marginRight:
						clampProgress * 100 > 50
							? (firstCardRef?.current?.clientWidth ?? 0) + 5
							: (firstCardRef?.current?.clientWidth ?? 0) + 100,
					transition: 'all .6s',
				}}
				justifyContent="start"
				// flexDirection={progressVal > 50 ? 'row-reverse' : 'row'}
				alignItems="stretch"
			>
				{clampProgress * 100 > 50 ? (
					<>
						<Grid item ref={firstCardRef} className="mt-35">
							<IconButton className="p-0" onClick={onClickRefresh}>
								<SvgConverter Icon={RefreshIcon} />
							</IconButton>
						</Grid>
						<Grid
							container
							alignItems="center"
							className="mx-5"
							justifyContent="space-around"
						>
							<Grid item>
								<SvgConverter Icon={OrangeArrow} />
							</Grid>
							<Grid item>
								<Divider
									orientation="vertical"
									sx={{
										height: '90px', // Set the height as per your requirement
										backgroundColor: '#d7d7d7', // Optional: Set the background color
										width: '1px',
									}}
								/>
							</Grid>
						</Grid>
						<Grid item>
							<PolicyCardCost.Normal
								titleFontWeight={400}
								title="CURRENT_VALUE"
								value={currentValue ? currentValue : '0'}
								policyCounts={3}
								hideLastUpdate={false}
								hidePolicyCounts
								allValueText1="Surrender Values of Non-Par  +"
								allValueText2="Guranteed Component of Participatory Policy"
								updatedDate={updatedDate}
								policyDetails={policyDetails}
							/>
						</Grid>
					</>
				) : (
					<>
						<Grid item>
							<PolicyCardCost.Normal
								title="CURRENT_VALUE"
								value={currentValue ? currentValue : '0'}
								policyCounts={3}
								hideLastUpdate={false}
								hidePolicyCounts
								allValueText1=""
								allValueText2=""
								updatedDate={updatedDate}
								policyDetails={policyDetails}
							/>
						</Grid>
						<Grid
							container
							alignItems="center"
							className="mx-5"
							justifyContent="space-around"
						>
							<Grid item>
								<SvgConverter Icon={OrangeArrow} />
							</Grid>
							<Grid item className='pl-4'>
								<Divider
									orientation="vertical"
									sx={{
										height: '90px',
										backgroundColor: '#d7d7d7',
										width: '1px',
									}}
								/>
							</Grid>
						</Grid>
						<Grid item ref={firstCardRef} className="mt-35">
							<IconButton className="p-0" onClick={onClickRefresh}>
								<SvgConverter Icon={RefreshIcon} />
							</IconButton>
						</Grid>
					</>
				)}

				<Grid item />
			</Grid>
		</div>
	);
};

export default LifeProgressBarWithValues;
