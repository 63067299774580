import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BankDetailsHeader from './common/BankDetailsHeader';
import { BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES } from '../../../common/Enums';
import Separator from '../../../common/ui/Separator';

const UnassignedBankAccountScreen = ({
	banks = [],
	handleRemoveBank,
	handleAssignPolicies,
}: any) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<>
			<Grid item xs={12}>
				<Typography
					className="f-16 fw-600 my-10"
					color={theme.palette.secondary.main}
				>
					{t('UNASSIGNED_BANK_ACCOUNTS')}
				</Typography>
			</Grid>
			<Grid item className="my-10">
				<Separator color={theme.palette.primary.main} borderWidth={1} />
			</Grid>
			<Grid container spacing={5}>
				{banks.map((data: any, index: any) => (
					<Grid item key={index} xs={12} sm={6} md={3}>
						<BankDetailsHeader
							unassigned
							data={data}
							index={index}
							onRemoveClick={() =>
								handleRemoveBank(
									data.accountNo,
									BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES.UNASSIGNED
								)
							}
							showAssignButton
							handleAssignPolicy={() => handleAssignPolicies(data)}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default UnassignedBankAccountScreen;
