import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import theme from '../../../common/theme';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as Verify } from '../../../common/icons/update-mobile/verify_orange.svg';
import { ReactComponent as Cancel } from '../../../common/icons/update-mobile/cancel_orange.svg';
import config from '../../../common/config';

const OtpEntryModal = ({
	open,
	setOpen,
	onSubmit,
	value,
	isEmail,
	Email,
	loading,
	resendLoading,
	onFinishDuration,
	duration,
	handleResendOtp,
}: any) => {
	const [otp, setOtp] = useState('');
	const [seconds, setSeconds] = useState(0);

	const { t } = useTranslation();

	useEffect(() => {
		if (!open) {
			setOtp('');
		}
	}, [open, setOtp]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				clearInterval(interval);
				if (duration !== 0) {
					onFinishDuration();
				}
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [onFinishDuration, seconds]);

	useEffect(() => {
		setSeconds(duration);
	}, [duration]);

	useEffect(() => {
		if (open) {
			setSeconds(duration);
		} else {
			setSeconds(0);
		}
	}, [duration, open]);

	useEffect(() => {
		if (!otp) {
			return;
		}
		if (String(otp).length === config.otpCount) {
			setOtp(otp);
		}
	}, [otp]);

	useEffect(() => {
		if (!open) {
			setOtp('');
		}
	}, [open]);
	const resetOtpField = () => {
		setOtp('');
	};

	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20 popup-word"
			boxProps={{ width: 350  }}
			
		
		>
			<Grid container spacing={0.5} flexDirection="column">
				<Grid item>
					<Typography
						textAlign="center"
						className="f-16 px-20 fw-600"
						color={theme.palette.primary.main}
					>
						{t('PLEASE_ENTER_6_DIGIT_OTP_SENT')} {t('TO_THE')} {' '}
						{isEmail ? t('EMAIL_ID') : t('MOBILE_NUMBER')} {value}
					</Typography>
				</Grid>

				<Grid display="flex" className="my-20 " justifyContent="center">
					<OtpInput
						numInputs={6}
						placeholder="XXXXXX"
						value={otp}
						onChange={setOtp}
						containerStyle="otp-input-container"
						isInputSecure
						isInputNum
					/>
				</Grid>
				{seconds > 0 ? (
					<Grid
						item
						container
						justifyContent="center"
						spacing={0.6}
						flexWrap="nowrap"
					>
						<Grid item>
							<Typography
								className="f-14 fw-400"
								color={theme.palette.primary.main}
							>
								{t('RESEND_OTP')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								className="f-13 fw-400"
								color={theme.palette.common.black}
							>
								{t('IN')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								className="f-13 fw-400"
								color={theme.palette.secondary.main}
							>
								{seconds} {t('SECONDS')}
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Grid
						item
						container
						justifyContent="center"
						spacing={0.6}
						flexWrap="nowrap"
					>
						<Grid item>
							<CustomButton
								text={t('RESEND_OTP')}
								variant="text"
								color="primary"
								showIcon={false}
								// startIcon={Cancel}
								onClick={() => {
									handleResendOtp();
									resetOtpField();
								}}
								loading={resendLoading}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>

			<Grid container textAlign="center" className="mt-25">
				<Grid item xs={6}>
					<CustomButton
						text={t('VERIFY')}
						variant="text"
						color="primary"
						showIcon={false}
						startIcon={Verify}
						onClick={() => {
							onSubmit(otp);
						}}
						disabled={otp?.length !== 6}
						loading={loading}
						className="f-16 fw-600"
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomButton
						text={t('CANCEL')}
						variant="text"
						color="primary"
						showIcon={false}
						startIcon={Cancel}
						onClick={() => {
							setOpen(false);
							if (Email) {
								Email(false);
							}
						}}
						className="f-16"
					/>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default OtpEntryModal;
