/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	Grid,
	IconButton,
	List,
	ListItemText,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../Layout.scss';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../common/icons/close.svg';
import SvgConverter from '../../common/SvgConverter';
import Separator from '../../common/ui/Separator';
import { logoutRequest, userSignOut } from '../../features/Auth/auth.slice';
import { ReactComponent as ArrowIcon } from '../../common/icons/dashboard/down_arrow_sidemenu.svg';
import { APP_ROUTES, PUBLIC_ROUTES } from '../../common/constants/Routes';
import NeedSupport from '../../features/Auth/common/NeedSupport';
import LanguageSelector from '../../features/Auth/Login/LanguageSelector';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const LeftMenuList = ({ setOpen }: { setOpen: Function }) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const handleLogout = () => {
		const data = () => {
			dispatch(userSignOut());
		};
		dispatch(logoutRequest(data));
	};
	const handleMenuItemClick = () => {
		setOpen(false);
	};

	const changeLanguage = () => {
		setShowLanguageModal(true);
		setOpen(false);
	};
	
	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Menu Screen',
		});
	}, []);

	
	return (
		<div className="notification-container">
			<Grid
				container
				justifyContent="space-between"
				className="notification-drawer-header"
				alignItems="center"
			>
				<Grid item>
					<Grid item>
						<IconButton onClick={() => setOpen(false)}>
							<SvgConverter Icon={CloseIcon} />
						</IconButton>
					</Grid>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<List className="left-menu-container">
								<Link to={APP_ROUTES.POLICY} onClick={handleMenuItemClick}>
									<ListItemText
										className="menu-item-container mt-30"
										primary={
											<Typography
												color={theme.palette.primary.main}
												fontWeight={600}
												fontSize={18}
											>
											{t('POLICIES_MENU')}
											</Typography>
										}
									/>
								</Link>
								<Link to={APP_ROUTES.PROFILE} onClick={handleMenuItemClick}>
									<ListItemText
										className="menu-item-container"
										primary={
											<Typography
												color={theme.palette.primary.main}
												fontWeight={600}
												fontSize={18}
											>
												{t('PROFILE')}
											</Typography>
										}
									/>
								</Link>
								<ListItemText
									className="menu-item-container d-flex manage-align mt-40"
									primary={
										<Typography
											color={theme.palette.common.black}
											fontWeight={600}
											fontSize={14}
										>
											{t('MANAGE')}
										</Typography>
									}
									secondary={
										<SvgConverter
											Icon={ArrowIcon}
											className="arrow-icon ml-5"
										/>
									}
								/>
								<Grid className="ml-40 mb-20">
									<Link
										to={APP_ROUTES.ADD_POLICY_INSTRUCTION}
										onClick={handleMenuItemClick}
									>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('ADD_POLICIESS')}
												</Typography>
											}
										/>
									</Link>
									<Link to={APP_ROUTES.NOMINEE} onClick={handleMenuItemClick}>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('ADD/EDIT_NOMINEES')}
												</Typography>
											}
										/>
									</Link>
									<Link
										to={APP_ROUTES.UPDATE_MOBILE}
										onClick={handleMenuItemClick}
									>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('MANAGE_MOBILE_NUMBERS')}
												</Typography>
											}
										/>
									</Link>
									<Link to={APP_ROUTES.EMAIL} onClick={handleMenuItemClick}>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('MANAGE_EMAILS')}
												</Typography>
											}
										/>
									</Link>
									<Link
										to={APP_ROUTES.MANAGE_ADDRESS}
										onClick={handleMenuItemClick}
									>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('MANAGE_ADDRESSES')}
												</Typography>
											}
										/>
									</Link>
									<Link to={APP_ROUTES.BANK} onClick={handleMenuItemClick}>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('MANAGE_BANK_DETAILS')}
												</Typography>
											}
										/>
									</Link>
								</Grid>
								<div className="mb-16">
									<Separator color={theme.palette.primary.main} />
								</div>

								<Link
									to={APP_ROUTES.SUPPORT}
									onClick={handleMenuItemClick}
									state="Support"
								>
									<ListItemText
										className="menu-item-container"
										primary={
											<Typography
												color={theme.palette.primary.main}
												fontWeight={600}
												fontSize={18}
											>
												{t('SUPPORT')}
											</Typography>
										}
									/>
								</Link>
								<Link
									to={APP_ROUTES.SUPPORT}
									onClick={handleMenuItemClick}
									state="Complaint"
								>
									<ListItemText
										className="menu-item-container"
										primary={
											<Typography
												color={theme.palette.primary.main}
												fontWeight={600}
												fontSize={18}
											>
												{t('COMPLAINTS')}
											</Typography>
										}
									/>
								</Link>
								<Grid>
									<NeedSupport type="black" page="dashboard" />
								</Grid>
								<ListItemText
									className="menu-item-container d-flex manage-align mt-30"
									primary={
										<Typography
											color={theme.palette.common.black}
											fontWeight={600}
											fontSize={14}
										>
											{t('LEGAL')}
										</Typography>
									}
									secondary={
										<SvgConverter
											Icon={ArrowIcon}
											className="arrow-icon ml-5"
										/>
									}
								/>
								<Grid className="ml-40 mb-20">
									<Link
										to={PUBLIC_ROUTES.PRIVACYSCREEN}
										onClick={handleMenuItemClick}
									>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('PRIVACY_POLICY_SMALL')}
												</Typography>
											}
										/>
									</Link>
									<Link
										to={PUBLIC_ROUTES.DISCLAIMER}
										onClick={handleMenuItemClick}
									>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('DISCLAIMER')}
												</Typography>
											}
										/>
									</Link>
									<Link
										to={PUBLIC_ROUTES.DATAPROTECTIONPOLICY}
										onClick={handleMenuItemClick}
									>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('DATA_PROTECTION_POLICY')}
												</Typography>
											}
										/>
									</Link>
									<Link
										to={PUBLIC_ROUTES.SECURITYTIPS}
										onClick={handleMenuItemClick}
									>
										<ListItemText
											className="menu-item-container"
											primary={
												<Typography
													color={theme.palette.primary.main}
													fontWeight={600}
													fontSize={18}
												>
													{t('SECURITY_TIPS')}
												</Typography>
											}
										/>
									</Link>
								</Grid>
								<div className="mb-16">
									<Separator color={theme.palette.primary.main} />
								</div>

								<ListItemText
									className="menu-item-container cursur-pointer"
									onClick={changeLanguage}
									primary={
										<Typography
											color={theme.palette.primary.main}
											fontWeight={600}
											fontSize={18}
										>
											{t('CHANGE_LANGUAGE')}
										</Typography>
									}
								/>
								<Link to="#" onClick={handleLogout}>
									<ListItemText
										className="menu-item-container"
										primary={
											<Typography
												color={theme.palette.primary.main}
												fontWeight={600}
												fontSize={18}
											>
												{t('LOG_OFF')}
											</Typography>
										}
									/>
								</Link>
							</List>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<LanguageSelector
				open={showLanguageModal}
				setOpen={setShowLanguageModal}
				// onSubmit={handleAddressChangesSubmit}
				// onCancel={handleCancelChanges}
				contentType="DASHBOARD"
			/>
		</div>
	);
};

export default LeftMenuList;
