import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Separator from '../../../common/ui/Separator';
import MobilePolicyHeader from './common/MobilePolicyHeader';

const UnassignedMobileNumber = ({
	mobileNoList = [],
	handleAssignPolicy,
	onRemove,
	togglePrimaryChangeNumberModal,
}: any) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<>
			<Grid item xs={12}>
				<Typography className="f-16  mt-30" color={theme.palette.error.main}>
					{t('UNASSIGNED_MOBILE_NUMBERS')}
				</Typography>
			</Grid>
			<Grid item className="my-10">
				<Separator color={theme.palette.primary.main} borderWidth={1} />
			</Grid>
			<Grid container spacing={5}>
				{mobileNoList.map((data: any, index: number) => (
					<MobilePolicyHeader
						index={index}
						mobileNo={data?.mobileNo}
						key={data?.mobileNo}
						verified={data.mobileNoVerified === 'Y'}
						primary={data.primaryFlag}
						showAssignPolicyButton
						handleAssignPolicy={() =>
							handleAssignPolicy({
								mobileNo: data?.mobileNo,
								mobileNoVerified: data.mobileNoVerified,
								primaryFlag: data.primaryFlag,
							})
						}
						onRemove={() => onRemove(data?.mobileNo, data?.primaryFlag)}
						onChangePrimary={() => {
							togglePrimaryChangeNumberModal({ mobileNo: data?.mobileNo });
						}}
						isEmail={false}
					/>
				))}
			</Grid>
		</>
	);
};

export default UnassignedMobileNumber;
