import { Grid, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as ArrowLeftIcon } from '../../../../common/icons/policy/sm_black_arrow.svg';
import { ReactComponent as DownloadIcon } from '../../../../common/icons/policy/download_icon.svg';

interface DownloadStatementProps {
	PremiumClick?: () => void;
	BonusClick?: () => void;
	FundClick?: () => void;
	LoanClick?: () => void;
}
const DownloadStatement: React.FC<DownloadStatementProps> = ({
	PremiumClick,
	BonusClick,
	FundClick,
	LoanClick,
}) => {
	const { t } = useTranslation();

	return (
		<Grid container>
			<Grid item xs={12}>
				<Grid display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('PREMIUM_RECIEPTS')}
					</Typography>
					<IconButton className="px-12" onClick={PremiumClick}>
						<SvgConverter Icon={ArrowLeftIcon} className="back-icon" />
					</IconButton>
				</Grid>
				<Separator color={theme.palette.primary.main} />
				<Grid display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('BONUS_STATEMENT')}
					</Typography>
					<Typography className="f-16 mr-8" color={theme.palette.common.black}>
						{t('IF_APPLICABLE')}
					</Typography>
					<IconButton className="px-12" onClick={BonusClick}>
						<SvgConverter Icon={ArrowLeftIcon} className="back-icon" />
					</IconButton>
				</Grid>
				<Separator color={theme.palette.primary.main} />
				<Grid display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('FUND_STATEMENT')}
					</Typography>
					<Typography className="f-16" color={theme.palette.common.black}>
						{`[${t('IF_APPLICABLE')}]`}
					</Typography>
					<IconButton className="px-12" onClick={FundClick}>
						<SvgConverter Icon={ArrowLeftIcon} className="back-icon" />
					</IconButton>
				</Grid>
				<Separator color={theme.palette.primary.main} />
				<Grid display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('LOAN_STATEMENT')}
					</Typography>
					<Typography className="f-16" color={theme.palette.common.black}>
						{`[${t('IF_APPLICABLE')}]`}
					</Typography>
					<IconButton className="px-9" onClick={LoanClick}>
						<SvgConverter Icon={DownloadIcon} />
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default DownloadStatement;
