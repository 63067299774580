import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import theme from '../../../../../../common/theme';
import SvgConverter from '../../../../../../common/SvgConverter';
import { ReactComponent as PDF } from '../../../../../../common/icons/pdf-icon.svg';
import { ReactComponent as Download } from '../../../../../../common/icons/policy/download.svg';
import CustomButton from '../../../../../../common/ui/CustomButton';
import { fetchPolicyDocumentDownload } from '../../../Policies.slice';

interface DownloadDocumentProps {
	downloadDocumentDetails?: any;
	downloadData?: any;
}
const DownloadDocument: React.FC<DownloadDocumentProps> = ({
	downloadDocumentDetails,
	downloadData,
}) => {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const downloadPolicyDocument = (data: any) => {
		dispatch(
			fetchPolicyDocumentDownload({
				policyDocument: 'Y',
				policyNumber: downloadData?.items?.policyNo,
				alternateCode: downloadData?.items?.alternateCode,
				insurerCode: downloadData?.items?.companyCode
					? downloadData?.items?.companyCode
					: downloadData?.items?.insurerCode,
				documentFlag: data?.documentFlag,
				t,
			} as any)
		);
	};
	const daFlag = downloadDocumentDetails?.daFlag;
	let downloadDetails = downloadDocumentDetails?.PolicyDocuments != undefined ? [...(downloadDocumentDetails?.PolicyDocuments)] : [];
	if (daFlag === 'A') {
		downloadDetails.push(
			{ documentName: 'Assignment', pdfName: '', documentFlag: 'ASSIGNMENT' });
	}
	else if (daFlag === 'R') {
		downloadDetails.push(
			{ documentName: 'No Due Cretificate', pdfName: '', documentFlag: 'NO_DUE_CERTIFICATE' });
		downloadDetails.push(
			{ documentName: 'ReAssignment', pdfName: '', documentFlag: 'REASSIGNMENT' })
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
					className="py-10"
				>
					{t('DOWNLOAN_POLICY_DOCUMENT')}
				</Typography>
				{downloadDetails &&
					downloadDetails.map((mapdata: any, index: any) => (
						<Grid item container xs={12} key={index} className="mb-20 ">
							<Grid item xs={1}>
								<SvgConverter Icon={PDF} />
							</Grid>
							<Grid item xs={7}>
								<Typography
									color={theme.palette.common.black}
									fontSize={18}
									className=" break-word px-5"
								>
									[{mapdata?.documentName}]
								</Typography>
							</Grid>
							<Grid item xs={2} className="">
								<CustomButton
									showIcon={false}
									text={<SvgConverter Icon={Download} />}
									className="f-14 fw-400"
									variant="text"
									onClick={() => {
										downloadPolicyDocument(mapdata);
									}}
								/>
							</Grid>
							<Typography
								color={theme.palette.secondary.main}
								fontSize={14}
								className=""
							>
								{`[${t('PDF')}]`}
							</Typography>
						</Grid>
					))}
			</Grid>
		</Grid>
	);
};

export default DownloadDocument;
