import React, { useEffect, useState } from 'react';
import { Grid, Typography, FormGroup } from '@mui/material';
// import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import LoanAgainstHeader from './LoanAgainstHeader';
import ProductCardPolicy from './ProductCardPolicy';
// import HdfcIcon from '../../../../assets/images/HDFC.png';
import '../Policy.scss';
import theme from '../../../../common/theme';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/small-orange-rupee.svg';
import { ReactComponent as rightArrow } from '../../../../common/icons/right_arrow-icon.svg';
import Separator from '../../../../common/ui/Separator';
import FormInputLoanAgainst from './FormInputLoanAgainst';
import CustomButton from '../../../../common/ui/CustomButton';
import FinancierCardLoanDetails from './FinancierCardLoanDetails';
import RadioButton from '../../LoanModule/RadioButtonDetails';
import { convertAmount } from '../../../../common/utils';
import {
	myOptions,
	selectedCombination,
	selectedCombinationLoading,
	selectedCombinationRequest,
} from '../../LoanModule/Loan.slice';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { selectIsUserLoggedIn } from '../../../Auth/auth.slice';
import Spinner from '../../../../common/ui/Spinner';
import { useTranslation } from 'react-i18next';
// import { useWatch } from 'react-hook-form';

const GetLoanAgainstPolicy = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [checkbox, setCheckbox] = useState(null);
	const [radio, setRadio] = useState(null);
	const [showOptions, setShowOptions] = useState(false);
	// const [widgetNo, setWidgetNo] = useState(null);
	const myPolicyLoan = useSelector(myOptions);
	useEffect(() => {
		if (!myPolicyLoan) {
			navigate(APP_ROUTES.POLICY);
		}
	}, [myPolicyLoan]);
	const LenderDetails = useSelector(selectedCombination);
	const lenderListLoading = useSelector(selectedCombinationLoading);
	const LenderDetail = LenderDetails?.Combination;
	const userData: any = useSelector(selectIsUserLoggedIn);

	const policyLoanDetails = myPolicyLoan?.Combinations[0];
	const generateRepaymentOptions = (repaymentOptions: any[]) =>
		repaymentOptions.map((option) => ({
			label: option.repaymentOptionDescription,
			value: option.repaymentCode,
		}));
	const handleSeeOptions = (data: any) => {
		const Combination: any = {
			combinationName: policyLoanDetails?.combinationName,
			totalCharges: policyLoanDetails?.totalCharges || '',
			totalChargesDetails: policyLoanDetails?.totalChargesDetails || '',
			totalFundsAvailed: policyLoanDetails?.totalFundsAvailed || '',
			lossOfCover: policyLoanDetails?.lossOfCover || '',

			Widgets: policyLoanDetails?.Widgets.map((widget: any) => {
				const commonProperties: any = {
					// temp sol
					sessionId: userData?.sessionId,
					panNo: userData?.pan,
					camsRefNo: '',
				};
				const clonedWidget: any = { ...widget, ...commonProperties };
				if (clonedWidget?.widgetType === 'L') {
					clonedWidget.Loan = {
						...widget?.Loan,
						loanAmount: data?.loan,
					};
				}
				return clonedWidget;
			}),
		};

		const requiredAmount: any = policyLoanDetails?.requiredAmount;
		const requiredDate: any = policyLoanDetails?.requiredDate;
		const type = 'Policy';
		dispatch(
			selectedCombinationRequest({
				Combination,
				requiredAmount,
				requiredDate,
				type,
			} as any)
		);
		setShowOptions(true);
	};

	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		watch,
	} = useForm();
	const loanAmount = watch('loan');
	const handleChange = (label: any) => {
		// const { name } = event.target;
		label === checkbox ? setCheckbox(null) : setCheckbox(label as any);
		setRadio(null);
	};
	const handleChangeRadio = (event: any) => {
		setRadio(event);
	};
	const onSubmit = () => {
		const requiredAmount: any = LenderDetails?.requiredAmount;
		const requiredDate: any = LenderDetails?.requiredDate;
		const widgetNo: any = LenderDetails?.Combination?.Widgets?.[0]?.widgetNo;
		const Lender: any = {
			modifiedData: {
				combinationName: LenderDetail?.combinationName,
				totalCharges: LenderDetail?.totalCharges,
				totalChargesDetails: LenderDetail?.totalChargesDetails,
				totalFundsAvailed: LenderDetail?.totalFundsAvailed,
				lossOfCover: LenderDetail?.lossOfCover,

				Widgets: LenderDetail?.Widgets.map((widget: any) => {
					const commonProperties: any = {
						sessionId: userData?.sessionId,
						panNo: userData?.pan,
						camsRefNo: '',
					};
					const clonedWidget: any = { ...widget, ...commonProperties };
					if (clonedWidget?.widgetType === 'L') {
						clonedWidget.Loan = {
							...widget?.Loan,
							loanAmount,
						};
					}
					if (clonedWidget?.Loan) {
						const filteredLenderDetails =
							clonedWidget?.Loan?.LenderDetails?.filter(
								(lender: any) => lender?.lenderName === checkbox
							);
						clonedWidget.Loan = {
							...clonedWidget.Loan,
							LenderDetails: filteredLenderDetails[0],
						};
						const repaymentCodeToKeep = radio;
						if (
							clonedWidget?.Loan?.LenderDetails &&
							clonedWidget?.Loan?.LenderDetails?.RepaymentOptions
						) {
							const repaymentOptions =
								clonedWidget?.Loan?.LenderDetails?.RepaymentOptions;
							const filteredRepaymentOptions = repaymentOptions.filter(
								(repaymentOption: any) =>
									repaymentOption.repaymentCode === repaymentCodeToKeep
							);
							clonedWidget.Loan = {
								...clonedWidget.Loan,
								LenderDetails: {
									...clonedWidget.Loan.LenderDetails,
									repaymentOption:
										filteredRepaymentOptions[0]?.repaymentCode ?? '',
									repaymentOptionDescription:
										filteredRepaymentOptions[0]?.repaymentOptionDescription ??
										'',
								},
							};
							delete clonedWidget.Loan.LenderDetails.RepaymentOptions;
						}
					}
					return clonedWidget;
				}),
			},
			requiredAmount,
			requiredDate,
		};
		navigate(APP_ROUTES.SELECTBANKDETAILS, { state: { Lender, widgetNo } });
	};

	return (
		<Grid container xs={12} justifyContent={'center'}>
			<LoanAgainstHeader />
			<div style={{ width: '300px' }}>
				{policyLoanDetails?.Widgets?.map((widget: any) => (
					<>
						<Grid container className="mb-20 mt-20">
							<ProductCardPolicy
								product={widget?.productType}
								lifeAssured={widget?.payerName}
								logoSrc={widget?.insurerLogo}
								maturityValue={widget?.maturityValue}
							/>
						</Grid>
						<Grid
							container
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Grid className="mt-10 option_card ">
								<Grid className="p-15">
									<Typography
										className="f-14"
										color={theme.palette.primary.main}
									>
										Current Surrender Value
									</Typography>
									<Grid item xs={12}>
										<Grid container spacing={0.5}>
											<LabelWithIcon
												className="px-6"
												svgClassName="mt-3"
												justifyContent="flex-start"
												Icon={RupeeIcon}
												label={convertAmount(widget?.fundValue)}
												labelProps={{
													color: theme.palette.common.black,
													fontSize: 18,
													fontWeight: 600,
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Separator color={theme.palette.grey[300]} className="mb-5" />
								<form onSubmit={handleSubmit(handleSeeOptions)}>
									<Grid className="p-15">
										<Typography
											className="f-16 fw-600"
											color={theme.palette.primary.main}
										>
											Loan Required
										</Typography>
										<Grid item>
											<Grid className="mt-10">
												<FormInputLoanAgainst
													control={control}
													setValue={setValue}
													error={errors}
													inputValue={widget?.Loan?.eligibleLoanValue}
													loanPercentage={widget?.Loan?.loanPercentage}
													name="loan"
												/>
											</Grid>
											<Grid item>
												<Grid container spacing={1}>
													<Grid item className="ml-18">
														<Typography
															className="f-14 mt-2"
															color={theme.palette.common.black}
															justifyContent="center"
															display="flex"
															alignItems="center"
														>
															<Typography
																className="f-14 fw-600"
																color="#f99117"
																justifyContent="center"
															>
																{loanAmount >= 0
																	? `${(
																		(loanAmount / widget?.fundValue) *
																		100
																	).toFixed(2)}%`
																	: widget?.Loan?.loanPercentage}
															</Typography>
															&nbsp; of surrender value
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Separator color={theme.palette.grey[300]} className="mb-5" />
									<Grid
										display="flex"
										alignItems="center"
										justifyContent="center"
										className="p-6"
									>
										<CustomButton
											text="SEE OPTIONS"
											variant="text"
											color="primary"
											showIcon
											fontSize={16}
											endIcon={rightArrow}
											className="fw-600"
											iconClassName="arrow_loan"
											type="submit"
										// onClick={handleSeeOptions}
										/>
									</Grid>
								</form>
							</Grid>
						</Grid>
					</>
				))}
				{lenderListLoading && (
					<Grid justifyContent={'center'} display={'flex'} mt={3}>
						<Spinner />
					</Grid>
				)}
				{showOptions && (
					<>
						{LenderDetail?.Widgets?.map((widgets: any) => (
							<Grid
								container
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Grid className="mt-10 ">
									<Separator
										color={theme.palette.grey[300]}
										className="mb-30 mt-20"
									/>
									<Typography
										className="f-14 fw-600"
										color={theme.palette.primary.main}
									>
										{t('SELECT_A_FINANCIER')}
									</Typography>

									<FormGroup>
										{widgets?.Loan?.LenderDetails?.map((widget: any) => (
											<>
												<Grid className="mt-20 mb-50">
													<FinancierCardLoanDetails
														financier={widget?.lenderName}
														lifeAssured={convertAmount(widget?.loanAmount)}
														logoSrc={widget?.lenderLogo}
														label={widget?.lenderName}
														onChange={handleChange}
														checked={checkbox}
														charges={convertAmount(widget?.charges)}
														interestPercentage={widget?.interestPercentage}
													/>

													<Grid item className="mt-n20 pl-10">
														<RadioButton
															options={generateRepaymentOptions(
																widget?.RepaymentOptions
															)}
															selectedCheckboxValue={checkbox}
															onValueChange={handleChangeRadio}
															disabled={widget?.lenderName !== checkbox}
														/>
													</Grid>
													{widget?.lenderName === checkbox && (
														<Grid
															display="flex"
															alignItems="center"
															justifyContent="end"
															className="p-6"
														>
															<CustomButton
																text={t('PROCEED')}
																variant="text"
																color="primary"
																showIcon
																fontSize={16}
																endIcon={rightArrow}
																className="fw-600"
																onClick={onSubmit}
																disabled={(checkbox && radio) === null}
															/>
														</Grid>
													)}
												</Grid>
											</>
										))}
									</FormGroup>
								</Grid>
							</Grid>
						))}
					</>
				)}
			</div>
		</Grid>
	);
};

export default GetLoanAgainstPolicy;
