import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import Separator from '../../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import { convertAmount, getFormatDate } from '../../../../../../common/utils';

interface AssetClaimProps {
	claimDetails?: any;
}
const AssetClaim: React.FC<AssetClaimProps> = ({ claimDetails }) => {
	const formattedIntimationDate = getFormatDate(
		claimDetails && claimDetails?.intimationDate
	);
	const formattedDateOfApproval = getFormatDate(
		claimDetails && claimDetails?.dateOfApproval
	);
	const formattedDateOfPayement = getFormatDate(
		claimDetails && claimDetails?.dateOfPayement
	);

	const { t } = useTranslation();

	

	return (
		<>
			{claimDetails &&
			claimDetails?.length !== 0 &&
			(claimDetails?.[0]?.admittedClaimAmount != '' ||
			claimDetails?.[0]?.approvedClaimSettlementAmount != '' ||
			claimDetails?.[0]?.claim != '' ||
			claimDetails?.[0]?.amountPreAuth != '' ||
			claimDetails?.[0]?.cashlessCaragesList != '' ||
			claimDetails?.[0]?.claimStatus != '' ||
			claimDetails?.[0]?.claimApprovalDate != '' ||
			claimDetails?.[0]?.cliamIntimation != '' ||
			claimDetails?.[0]?.dateOfApproval != '' ||
			claimDetails?.[0]?.dateOfPayement != '' ||
			claimDetails?.[0]?.grossClaimAmount != '' ||
			claimDetails?.[0]?.intimationDate != '' ||
			claimDetails?.[0]?.notificationDate != '' ||
			claimDetails?.[0]?.mode != '' ||
			claimDetails?.[0]?.netAmountPaid != '' ||
			claimDetails?.[0]?.paymentReference != '' ||
			claimDetails?.[0]?.primaryLoss != '' ||
				// claimDetails?.[0]?.preAuthorizationAmount != '' ||
				// claimDetails?.[0]?.preAuthorizationDate != '' ||
				claimDetails?.[0]?.typeOfClaim != '') &&
				claimDetails[0] ? (
				<Grid container className="py-10">
					<Grid item xs={12} className="mb-10">
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={600}
						>
							{t('CLAIM_HISTORY')}
						</Typography>
					</Grid>
					{claimDetails &&
						claimDetails.map((mapdata: any, index: any) => (
							<Grid item container xs={12} className="mb-30">
						<Grid item xs={0.5}>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{index + 1}
							</Typography>
						</Grid>
						<Grid item container xs={11}>
							<Grid item container xs={10} className="blue-box ">
								<Grid item xs={6} className="px-8">
									<Typography
										color={theme.palette.primary.main}
										fontSize={14}
										fontWeight={600}
										className="mt-10 text-wrap"
									>
										{t('CLAIM_INTIMATION')}#
									</Typography>
									<Typography
										color={theme.palette.common.black}
										fontSize={16}
										fontWeight={600}
									>
										{mapdata.cliamIntimation === ''
											? '-'
											: mapdata.cliamIntimation}
									</Typography>
								</Grid>

								<Grid item xs={6} className="px-8">
									<Typography
										color={theme.palette.primary.main}
										fontSize={14}
										fontWeight={600}
										className="mt-10"
									>
										{t('INTIMATION_DATE')}
									</Typography>

									<Typography color={theme.palette.common.black} fontSize={16}>
										{/* {formattedIntimationDate === 'Invalid Date'
											? '-'
											: formattedIntimationDate} */}
										{getFormatDate(mapdata && mapdata?.intimationDate) || '-'}
									</Typography>
								</Grid>

								<Grid item xs={12} className="px-8">
									<Typography
										color={theme.palette.primary.main}
										fontSize={14}
										fontWeight={600}
										className="mt-15"
									>
										{t('ADMITTED_CLAIM_AMOUNT')}
									</Typography>
									<LabelWithIcon
										className=""
										svgClassName="mb-2 "
										justifyContent="flex-start"
										Icon={OrangeRuppee}
										label={
											mapdata.admittedClaimAmount?.length === 0
												? '-'
												: convertAmount(mapdata.admittedClaimAmount)
										}
										labelProps={{
											color: theme.palette.common.black,
											fontSize: 16,
											fontWeight: 600,
										}}
									/>
								</Grid>
								<Grid item xs={12} className="px-8">
									<Typography
										color={theme.palette.primary.main}
										fontSize={14}
										fontWeight={600}
										className="mt-15"
									>
										{t('TYPE_OF_CLAIM')}({t('LOSS_TYPE')})
									</Typography>
									<Typography color={theme.palette.common.black} fontSize={16}>
										{mapdata.typeOfClaim?.length === 0
											? '-'
											: mapdata.typeOfClaim}
									</Typography>
								</Grid>
								<Grid item xs={12} className="py-10 ">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								<Grid item container xs={12} className="px-8">
									<Grid item xs={12} className="py-10">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('PRIMARY_NATURE_OF_LOSS')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={18}
											fontWeight={600}
										>
											{mapdata.primaryLoss?.length === 0
												? '-'
												: mapdata.primaryLoss}
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12} className="py-10 ">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								<Grid item container xs={12} className="px-8">
									<Grid item xs={12} className="py-10">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('APPROVED_CLAIM_SETTLEMENT_AMOUNT')}
										</Typography>
										<LabelWithIcon
											className=""
											svgClassName="mb-2 "
											justifyContent="flex-start"
											Icon={OrangeRuppee}
											label={
												mapdata.approvedClaimSettlementAmount?.length === 0
													? '-'
													: convertAmount(mapdata.approvedClaimSettlementAmount)
											}
											labelProps={{
												color: theme.palette.common.black,
												fontSize: 16,
												fontWeight: 600,
											}}
										/>
									</Grid>
									<Grid item xs={6} className="py-5">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('CLAIM_STATUS')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
										>
											{mapdata.claimStatus?.length === 0
												? '-'
												: mapdata.claimStatus}
										</Typography>
									</Grid>
									<Grid item xs={6} className="py-5">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('CLAIM')}#
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
										>
											{/* {mapdata.claim} */}
											{mapdata.claim?.length === 0 ? '-' : mapdata.claim}
										</Typography>
									</Grid>

									<Grid item xs={12} className="py-10">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('DATE_OF_APPROVAL')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
										>
											{/* {formattedDateOfApproval === 'Invalid Date'
												? '--p-----'
												: formattedDateOfApproval} */}
											{getFormatDate(mapdata && mapdata?.dateOfApproval) || '-'}
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12} className="py-10 ">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								<Grid item container xs={12}>
									<Grid item xs={12} className="px-8">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-5"
										>
											{t('GROSS_CLAIM_AMOUNT')}
										</Typography>
										<LabelWithIcon
											className=""
											svgClassName="mb-2 "
											justifyContent="flex-start"
											Icon={OrangeRuppee}
											label={
												mapdata.grossClaimAmount?.length === 0
													? '-'
													: convertAmount(mapdata.grossClaimAmount)
											}
											labelProps={{
												color: theme.palette.common.black,
												fontSize: 16,
												fontWeight: 600,
											}}
										/>
									</Grid>
									<Grid item xs={12} className="px-8">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-5"
										>
											{t('NET_AMOUNT_PAID')}
										</Typography>
										<LabelWithIcon
											className=""
											svgClassName="mb-2 "
											justifyContent="flex-start"
											Icon={OrangeRuppee}
											label={
												mapdata.netAmountPaid?.length === 0
													? '-'
													: convertAmount(mapdata.netAmountPaid)
											}
											labelProps={{
												color: theme.palette.common.black,
												fontSize: 16,
												fontWeight: 600,
											}}
										/>
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											className="mt-5"
										>
											{`[${t('GROSS_AMOUNT')} +${t(
												'LOSS_ADJUSTMENT_EXPENSES'
											)} +${t('TP_INTEREST_AMOUNT')} -${t('RECOVERABLES')} -${t(
												'DEDUCTIBLES'
											)} 
											
											]`}
										</Typography>
									</Grid>
								</Grid>

								<Grid item container xs={12} className="px-8 mb-10">
									<Grid item xs={6}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-15"
										>
											{t('DATE_OF_PAYMENT')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
										>
											{/* {formattedDateOfPayement === 'Invalid Date'
												? '-'
												: formattedDateOfPayement} */}
											{getFormatDate(mapdata && mapdata?.dateOfPayement) || '-'}
										</Typography>
									</Grid>

									<Grid item xs={6}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-15"
										>
											{t('MODE')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
										>
											{mapdata.mode?.length === 0 ? '-' : mapdata.mode}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-15"
										>
											{t('PAYMENT_REFERENCE')}#
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
										>
											{mapdata.paymentReference?.length === 0
												? '-'
												: mapdata.paymentReference}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
						))}
				</Grid>
			) : (
				<Grid item xs={12} className="mb-10 mt-20">
					<Grid item xs={12} className="mb-10">
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							fontWeight={600}
						>
							{t('CLAIM_HISTORY')}
						</Typography>
					</Grid>
					<Typography color="black" fontSize={14} fontWeight={600}>
						{t('NO_CLAIMS')}
					</Typography>
				</Grid>
			)}
		</>
	);

};


export default AssetClaim;
