import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/ui/CustomButton';
import theme from '../../../common/theme';
import { ReactComponent as Verify } from '../../../common/icons/update-mobile/verify_orange.svg';
import { ReactComponent as Cancel } from '../../../common/icons/update-mobile/cancel_orange.svg';
import { ReactComponent as Edit } from '../../../common/icons/edit.svg';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import Spinner from '../../../common/ui/Spinner';
import { useSelector } from 'react-redux';
import { selectMobileNoChangesList } from '../MobileNumber/MobileNumber.slice';
import { selectEmailChangesList } from '../Email/Emails.slice';
import { selectBankChangesList } from '../BankModule/Bank.slice';
import { selectAddressChangesList } from '../AddressModule/Address.slice';

interface UserProps {
	open?: any;
	setOpen?: any;
	contentType?: 'EMAIL' | 'MOBILE' | 'ADDRESS' | 'BANK' | 'POLICY';
	loading?: any;
	onSubmit?: any;
	onCancel?: any;
}

// const DESCRIPTIONS = {
// 	EMAIL: 'You have made changes in the Email ID assignments.',
// 	MOBILE: t{MOBILE_DESCRIPTION},
// 	ADDRESS: 'You have made changes in the Address assignments.',
// 	BANK: 'You have made changes in the Bank assignments.',
// 	POLICY: 'You have made changes in the Policy.',
// };

const DoneModal: React.FC<UserProps> = ({
	open,
	setOpen,
	contentType = 'ADDRESS',
	loading,
	onSubmit,
	onCancel,
}) => {
	const { t } = useTranslation();


	const mobile = useSelector(selectMobileNoChangesList);
	const email = useSelector(selectEmailChangesList);
	const bank = useSelector(selectBankChangesList);
	const address = useSelector(selectAddressChangesList);


	const mobileCheck = React.useMemo(() => mobile.some((item) => item?.Policies?.length), [mobile]);
	const emailCheck = React.useMemo(() => email.some((item) => item?.Policies?.length), [email]);
	const bankCheck = React.useMemo(() => bank.some((item) => item?.Policies?.length), [bank]);
	const addressCheck = React.useMemo(() => address.some((item) => item?.Policies?.length), [address]);

	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 400 }}
		>
			<Grid container>
				<Grid item xs={12}>
					{contentType === 'ADDRESS' && (
						<>
							<Typography
								className="f-16 fw-600 px-20"
								color={theme.palette.primary.main}
								textAlign="center"
								mb={2}
							>
								{t('ADDRESS_DESCRIPTION')}
							</Typography>
							{addressCheck && <Typography
								className="f-16 fw-600 px-20"
								color={theme.palette.primary.main}
								textAlign="center"
							>
								{t('FINAL_MESSAGE_ADDRESS')}
							</Typography>}
						</>
					)}
					{contentType === 'POLICY' && (
						<Typography
							className="f-16 fw-600 px-20"
							color={theme.palette.primary.main}
						>
							{t('FINAL_MESSAGE_POLICY')}
						</Typography>
					)}
					{contentType === 'MOBILE' && (
						<>
							<Typography
								className="f-16 fw-600 px-20"
								color={theme.palette.primary.main}
								textAlign="center"
								mb={2}
							>
								{t('MOBILE_DESCRIPTION')}
							</Typography>
							{mobileCheck && <Typography
								className="f-16 fw-600 px-20"
								color={theme.palette.primary.main}
								textAlign="center"
							>
								{t('FINAL_MESSAGE_MOBILE')}
							</Typography>}
						</>
					)}
					{contentType === 'EMAIL' && (
						<>
							<Typography
								className="f-16 fw-600 px-20"
								color={theme.palette.primary.main}
								textAlign="center"
								mb={2}
							>
								{t('EMAIL_DESCRIPTION')}
							</Typography>
							{emailCheck && <Typography
								className="f-16 fw-600 px-20"
								color={theme.palette.primary.main}
								textAlign="center"
							>
								{t('FINAL_MESSAGE_EMAIL')}
							</Typography>}
						</>
					)}
					{contentType === 'BANK' && (
						<>
							<Typography
								className="f-16 fw-600 px-20"
								color={theme.palette.primary.main}
								textAlign="center"
								mb={2}
							>
								{t('BANK_DESCRIPTION')}
							</Typography>
							{bankCheck && <Typography
								className="f-16 fw-600 px-20"
								color={theme.palette.primary.main}
								textAlign="center"
							>
								{t('FINAL_MESSAGE_BANK')}
							</Typography>}
						</>
					)}
					<Grid
						container
						textAlign="center"
						justifyContent="center"
						className="mt-10"
					>
						{loading ? (
							<Grid item>
								<Spinner />
							</Grid>
						) : (
							<>
								<Grid item xs={12} className="py-10 mt-10">
									<CustomButton
										text={t('SUBMIT_CHANGES')}
										variant="text"
										color="primary"
										showIcon={false}
										startIcon={Verify}
										className="fw-600 f-16"
										onClick={onSubmit}
									/>
								</Grid>
								<Grid item xs={12} className="py-8">
									<CustomButton
										text={t('CONTINUE_EDITING')}
										variant="text"
										color="primary"
										showIcon={false}
										startIcon={Edit}
										onClick={() => setOpen(false)}
										className="f-16"
									/>
								</Grid>
								<Grid item xs={12} className="py-8">
									<CustomButton
										text={t('CANCEL_&_EXIT')}
										variant="text"
										color="primary"
										showIcon={false}
										startIcon={Cancel}
										onClick={onCancel}
										className="f-16"
									/>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</CommonModal>
	);
};
export default DoneModal;
