import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import theme from '../../../../../../common/theme';
import SvgConverter from '../../../../../../common/SvgConverter';
import { ReactComponent as Download } from '../../../../../../common/icons/policy/download.svg';
import { fetchPolicyDocumentDownload } from '../../../Policies.slice';
import CustomButton from '../../../../../../common/ui/CustomButton';

interface DownloadStatementsProps {
	downloadStatementsDetails?: any;
	downloadData?: any;
}
const DownloadStatements: React.FC<DownloadStatementsProps> = ({
	downloadStatementsDetails,
	downloadData,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const downloadPolicyDocument = () => {
		dispatch(
			fetchPolicyDocumentDownload({
				policyDocument: 'Y',
				policyNumber: downloadData?.policyNo,
				alternateCode: downloadData?.alternateCode,
				insurerCode: downloadData?.companyCode
					? downloadData?.companyCode
					: downloadData?.insurerCode,
				t,
			} as any)
		);
	};
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('DOWNLOAD_STATEMENTS')}
				</Typography>
			</Grid>
			<Grid item xs={12} className="mt-10">
				{downloadStatementsDetails && downloadStatementsDetails.length > 0
					? downloadStatementsDetails.map((mapdata: any, index: any) => (
							<Grid item container xs={12} key={index} className="mb-10 ">
								<Grid item xs={12}>
									<Typography color={theme.palette.common.black} fontSize={18}>
										{mapdata?.statementName?.length === 0
											? '-'
											: mapdata?.statementName}
									</Typography>
								</Grid>
								<Grid item xs={1}>
									<CustomButton
										showIcon={false}
										text={
											<SvgConverter Icon={Download} className="mr-30 mb-10" />
										}
										className="f-14 fw-400"
										variant="text"
										onClick={downloadPolicyDocument}
									/>
								</Grid>
								<Grid item xs={1}>
									<Typography
										color={theme.palette.secondary.main}
										fontSize={14}
										className="mb-4 ml-3"
									>
										{`[${t('PDF')}]`}
									</Typography>
								</Grid>
								<Grid item xs={2} className="ml-10">
									<Typography
										color={theme.palette.secondary.main}
										fontSize={14}
										className="mb-4"
									>
										{`[${t('XML')}]`}
									</Typography>
								</Grid>
							</Grid>
					  ))
					: null}
			</Grid>
		</Grid>
	);
};

export default DownloadStatements;
