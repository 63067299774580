import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

const OverflowTip = ({ children }: any) => {
	const [isOverflowed, setIsOverflow] = useState(false);
	const textElementRef = useRef<any>();
	useEffect(() => {
		setIsOverflow(
			textElementRef.current.scrollWidth > textElementRef.current.clientWidth
		);
	}, []);
	return (
		<Tooltip title={children} disableHoverListener={!isOverflowed}>
			<div
				ref={textElementRef}
				style={{
					width: '100%',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				{children}
			</div>
		</Tooltip>
	);
};

export default OverflowTip;
