import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SvgConverter from '../../SvgConverter';
import { ReactComponent as ErrorIcon } from '../../icons/Red_Alert_icon.svg';
import { ReactComponent as TickIcon } from '../../icons/Big-Green-Tick.svg';
import CustomButton from '../CustomButton';
import {
	selectAlertInfo,
	setAlertInfo,
	dismissAlert
} from '../../../features/App/Common/Common.slice';
import Spinner from '../Spinner';
import CommonModal from './CommonModal';
import { logoutRequest, userSignOut } from '../../../features/Auth/auth.slice';
import { AUTH_ROUTES } from '../../constants/Routes';

const ICONS: any = {
	SUCCESS: TickIcon,
	FAILED: ErrorIcon,
	ERROR: ErrorIcon,
	INFO: ErrorIcon,
	PROGRESS: ErrorIcon,
};

const ApiModal = () => {

	const dispatch = useDispatch();
	const alertInfo: any = useSelector(selectAlertInfo);
	const navigate = useNavigate();
	 const [modalOpen, setModalOpen] = useState(false)

	 useEffect(()=> alertInfo.open ?  setModalOpen(true) : setModalOpen(false),[alertInfo])


	const handleClickOnOk = () => () => {
		dispatch(dismissAlert());
		dispatch(setAlertInfo({ open: false } as any));
		setModalOpen(false)

		if (alertInfo.errorCode === '2121') {
			dispatch(userSignOut());
			navigate(AUTH_ROUTES.LOGIN);
			window.location.reload()
			dispatch(logoutRequest(''));
		} else if (alertInfo.errorCode === '2205' || alertInfo.errorCode === '2061') {
			navigate('/dashboard');
		}
	};
	
	return (
		<CommonModal modalClassName="api-modal-container" open={modalOpen!==true?modalOpen:alertInfo.open} setModalOpen={setModalOpen} >
			<Box>
				{alertInfo.type === 'PROGRESS' ? (
					<Grid
						display="flex"
						justifyContent="center"
						flexDirection="column"
						alignItems="center"
					>
						<Spinner />
					</Grid>
				) : (
					<Grid container alignItems="center" justifyContent="center">
						<Grid item marginBottom={1}>
							<SvgConverter Icon={ICONS[alertInfo?.type]} />
						</Grid>
					</Grid>
				)}
				{typeof alertInfo.description !== 'object' ? (
					<Typography textAlign="center" className="f-14 mb-15 mt-8">
						{alertInfo.description}
					</Typography>
				) : (
					<div style={{ width: '100%', paddingHorizontal: 20 } as any}>
						{alertInfo.description?.map(
							({ primaryValue, errorValue, successValue }: any) => (
								<Grid
									container
									xs={12}
									style={{
										display: 'flex',
										flexDirection: 'row',
										width: '100%',
										marginBottom: 10,
										justifyContent: 'space-evenly',
									}}
								>
									<Grid item xs={3}>
										<Typography style={{ wordWrap: 'break-word' }}>
											{primaryValue}

										</Typography>
									</Grid>
									<Grid item xs={0.5} className='pl-5'>-</Grid>
									{/* <span style={{ margin: '0 8px' }}>-</span> */}
									<Grid item xs={8}>
										<Typography>{errorValue || successValue}</Typography>
									</Grid>
								</Grid>
							)
						)}
					</div>
				)}

				<Grid container alignItems="center" justifyContent="center">
					<Grid item>
						{alertInfo?.description !==
							'Please wait while document is downloading.' && (
								<CustomButton
									text="OK"
									showIcon={false}
									variant="outlined"
									color="success"
									onClick={handleClickOnOk()}
								/>
							)}
					</Grid>
				</Grid>
			</Box>
		</CommonModal>
	);
};

export default ApiModal;
