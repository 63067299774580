import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import SvgConverter from '../../../../../../common/SvgConverter';
import { convertAmount, getFormatDate } from '../../../../../../common/utils';
import Currency from '../../../../Common/Currency';

interface AnnuityProps {
	annuityDetails?: any;
	lifeDetails?: any;
}
const Annuity: React.FC<AnnuityProps> = ({ annuityDetails, lifeDetails }) => {
	const formattedAnnuityCommencementDate = getFormatDate(
		annuityDetails && annuityDetails?.annuityCommencementDate
	);

	const formattedFirstPaymentDate = getFormatDate(
		annuityDetails && annuityDetails?.firstPaymentDate
	);
	const { t } = useTranslation();
	return (
		<Grid container xs={12}>
			<Grid item xs={11} className="py-10 ml-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('ANNUITY_DETAILS')}
				</Typography>
			</Grid>{' '}
			<Grid
				item
				container
				xs={10}
				className="blue-box  ml-10  py-10"
			// style={{ border: '2px solid black' }}
			>
				<Typography
					color={theme.palette.primary.main}
					fontSize={18}
					fontWeight={600}
					className="ml-10 "
				>
					{t('ANNUITY_OPTION_CHOSEN')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={14}
					textAlign="left"
					className="mr-20 py-5 ml-10 "
				>
					{annuityDetails?.annuityOptionChosen?.length === 0
						? '-'
						: annuityDetails?.annuityOptionChosen}
				</Typography>{' '}
				<Grid container>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
						className="ml-10 "
					>
						{t('AMOUNT')}
					</Typography>
					<Grid item xs={12} container className="ml-10 ">
						{/* <Grid item xs={4}>
						<LabelWithIcon
							svgClassName="mb-2"
							justifyContent="flex-start"
							Icon={OrangeRuppee}
							label={
								annuityDetails?.amount?.length === 0
									? '-'
									: annuityDetails?.amount
							}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 18,
								fontWeight: 600,
							}}
						/>
					</Grid> */}
						<Grid
							container
							alignItems="center"
						>
							<Currency policyDetails={lifeDetails} amount={annuityDetails?.amount
								? annuityDetails?.amount
								: '0'} amountClass={"f-16 ml-5 fw-600"} />
						</Grid>
						{/* <Grid item xs={0.5} className="">
							<SvgConverter Icon={OrangeRuppee} className=" mt-10" />
						</Grid>
						<Grid item xs={3}>
							<Typography
								color={theme.palette.common.black}
								fontSize={18}
								fontWeight={600}
								className="ml-3"
							>
								{annuityDetails?.amount
									? convertAmount(annuityDetails?.amount)
									: '0'}
							</Typography>
						</Grid> */}
						<Grid item xs={6}>
							<Typography
								color={theme.palette.common.black}
								fontSize={18}
								className="ml-10 "
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container xs={12} className="ml-10 py-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('PURCHASE_PRICE')}
				</Typography>
				<Grid
					container
					alignItems="center"
				>
					<Currency policyDetails={lifeDetails} amount={annuityDetails?.purchasePrice} amountClass={"f-18 ml-5 fw-600"} />
				</Grid>
				{/* <LabelWithIcon
					svgClassName="mb-2"
					justifyContent="flex-start"
					Icon={OrangeRuppee}
					label={convertAmount(annuityDetails?.purchasePrice)}
					labelProps={{
						color: theme.palette.common.black,
						fontSize: 18,
					}}
				/> */}
				<Grid item xs={12}>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('RETURN_OF_PURCHASE_PRICE')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{annuityDetails?.returnOfPurchasePrice
							? annuityDetails?.returnOfPurchasePrice
							: '-'}
					</Typography>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('GURANTEE_PERIOD')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{annuityDetails?.guranteePeriod?.length === 0
							? '-'
							: annuityDetails?.guranteePeriod}
						{t('YEARS')}
					</Typography>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('ANNUITY_COMMENCEMENT_DATE')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{formattedAnnuityCommencementDate === 'Invalid Date'
							? '-'
							: formattedAnnuityCommencementDate}
					</Typography>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('FIRST_PAYMENT_DATE')}
					</Typography>
					<Typography
						color={theme.palette.common.black}
						fontSize={18}
						className="mb-10"
					>
						{formattedFirstPaymentDate === 'Invalid Date'
							? '-'
							: formattedFirstPaymentDate}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Annuity;
