import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';
import ApiModal from '../../../common/ui/Modal/ApiModal';

const NeedMoneyHeader = () => {
	const { t } = useTranslation();

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			className="mt-20"
		>
			<Grid item>
				<Typography className="f-16 fw-600" color="primary">
					{t('NEED_MONEY')}{' '}
				</Typography>
			</Grid>
			<Grid item>
				<Link to={APP_ROUTES.DASHBOARD}>
					<Grid container spacing={0.5}>
						<CustomButton
							className="f-14 fw-400 "
							text={t('CLOSE')}
							variant="text"
							color="primary"
							type="submit"
						/>
					</Grid>
				</Link>
			</Grid>
			<ApiModal />
		</Grid>
	);
};
export default NeedMoneyHeader;
