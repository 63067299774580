import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RupeeIcon } from '../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../common/utils';

interface ProductProps {
	product?: string;
	selfName?: string;
	showValues?: boolean;
	productData?: any;
}

const ProductDetails = ({
	product,
	selfName,
	showValues = true,
	productData,
}: ProductProps) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<Grid container spacing={0} className="mt-5 mb-5 ml-3">
			<Grid item xs={12}>
				<Typography className="f-14 fw-600" color="primary">
					{product}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className="f-12" color={theme.palette.primary.main}>
					{t('LIFE_ASSURED')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className="f-12 fw-600">{selfName}</Typography>
			</Grid>
			{showValues && (
				<>
					<Grid item xs={12}>
						<Typography className="f-12">{t('MATURITY_VALUE')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={0.5}>
							<LabelWithIcon
								className="px-6"
								svgClassName=""
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={convertAmount(productData?.maturityValue)}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 12,
								}}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} className="mt-n3 w-100">
						<Typography className="f-12">{t('Fund Value')}</Typography>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={0.5}>
							<LabelWithIcon
								className="px-6"
								svgClassName=""
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={convertAmount(productData?.fundValue)}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 12,
								}}
							/>
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default ProductDetails;
