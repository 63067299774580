/* eslint-disable react/require-default-props */
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import React from 'react';
import Spinner from './Spinner';

import DashboardProfileAvatar from './DashboardProfileAvatar';

const DashboardProfileCard = ({
	profileImg,
	customerName,
	eiaNum,
	profileReadiness,
	dashboardLoading,
}: {
	customerName?: string | any;
	eiaNum?: string;
	profileReadiness?: string;
	profileImg?: string;
	dashboardLoading?: any;
}) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Grid container alignItems="center" spacing={1}>
			<Grid item>
				<DashboardProfileAvatar
					profileImg={profileImg}
					profileReadiness={profileReadiness}
				/>
			</Grid>
			{dashboardLoading ? (
				<Grid item>
					<Spinner />
				</Grid>
			) : (
				<Grid item>
					<Typography
						textAlign="left"
						color="primary"
						fontSize={20}
						lineHeight="20px"
						className="mb-5"
					>
						{customerName}
					</Typography>
					<Typography
						textAlign="left"
						color={theme.palette.grey.A700}
						fontSize={14}
						lineHeight="16px"
						className="mb-3"
					>
						{`${t('EIA')}# `}: {eiaNum}
					</Typography>
					<Typography
						textAlign="left"
						color={theme.palette.grey.A700}
						fontSize={14}
						whiteSpace="nowrap"
					>
						{t('PROFILE_READINESS')}:&nbsp;
						<Typography
							color={theme.palette.secondary.main}
							fontSize={14}
							component="span"
						>
							{profileReadiness}
						</Typography>
					</Typography>{' '}
				</Grid>
			)}
		</Grid>
	);
};

export default DashboardProfileCard;
