import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './legal.scss';
import { Box, Grid, Typography, Hidden } from '@mui/material';

import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import PrivacyPolicyScreen from './PrivacyPolicyScreen';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const PrivacyScreen = () => {
	const { t } = useTranslation();

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Privacy Policy Screen',
		});
	}, []);
	return (
		<Grid container 
		marginBottom={5}
			
				display="flex"
				justifyContent="center"
				flexDirection="column"
				className="px-15"
				alignContent='center'>
			<Grid item>
							<span className="arrow-span">
								<Link to={APP_ROUTES.DASHBOARD}>
									<SvgConverter
										Icon={ArrowLeftIcon}
										className="left-arrow-style"
									/>
								</Link>
							</span>
						</Grid>
			<Grid item>
				<Typography
					fontSize={20}
					color="black"
					fontWeight={900}
					fontFamily="inherit"
					className="mb-5 mt-10"
				>
					Privacy Policy
				</Typography>
			</Grid>
			<Grid item>
				<PrivacyPolicyScreen />
			</Grid>
		</Grid>
	);
};
export default PrivacyScreen;
