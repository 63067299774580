import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import './Notification.scss';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../common/icons/close.svg';
import { ReactComponent as BellIcon } from '../../../common/icons/bell.svg';
import SvgConverter from '../../../common/SvgConverter';
import {
	fetchNotifications,
	selectLoadedNotifications,
} from './Notifications.slice';
import Separator from '../../../common/ui/Separator';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';




const NotificationList = ({
	setNotificationDrawer,
}: {
	setNotificationDrawer: Function;
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const notifications = useSelector(selectLoadedNotifications);
	useEffect(() => {
		dispatch(fetchNotifications());
	}, [dispatch]);
	const renderDate = (date: string) => {
		try {
			const dayStr = new Date(date);
			return dayjs(dayStr).format('DD-MMM HH:mm');
		} catch (e) {
			return '';
		}
	};

	const { t } = useTranslation();

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Notification  Screen',
		});
	}, []);


	return (
		<div className="notification-container">
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				className="px-10"
			>
				<Grid item>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
					>
						<Grid item>
							<SvgConverter Icon={BellIcon} />
						</Grid>
						<Grid item>
							<Typography
								className="f-18 fw-600"
								color={theme.palette.primary.main}
							>
								{t('NOTIFICATION')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<IconButton onClick={() => setNotificationDrawer(false)}>
						<SvgConverter Icon={CloseIcon} />
					</IconButton>
				</Grid>
			</Grid>
			{notifications.map((item, index) => (
				<Grid item key={index}>
					<Grid container className="p-12" justifyContent="space-between">
						<Grid item>
							<Typography
								fontSize={16}
								fontWeight={600}
								color={theme.palette.primary.main}
							>
								{item.title}
							</Typography>
						</Grid>
						<Grid item>
							<Typography fontSize={16} color={theme.palette.common.black}>
								{renderDate(item.createdDate)}
							</Typography>
						</Grid>
					</Grid>
					<Grid item className="p-12" flexWrap="nowrap">
						<Typography fontSize={14} color={theme.palette.common.black}>
							{item.notification}
						</Typography>
					</Grid>
					<Grid item className="w-100 p-10">
						<Grid item className="w-100">
							<Separator color={theme.palette.primary.main} />
						</Grid>
					</Grid>
				</Grid>
			))}
		</div>
	);
};

export default NotificationList;
