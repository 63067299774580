import React, { useState } from 'react';
import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import './Policy.scss';
import Box from '@mui/material/Box/Box';
import Tab from '@mui/material/Tab/Tab';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ArchiveScreen from './ArchiveScreen';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import { selectIsPolicyLoading } from './Policies.slice';
import ActiveScreen from './ActiveScreen';

interface UserProps {
	prevPath?: any;
}
const PolicyTab: React.FC<UserProps> = () => {
	const [value, setValue] = useState('Active');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};
	const policyLoading = useSelector(selectIsPolicyLoading);

	const { t } = useTranslation();

	return (
		<Box>
			<TabContext value={value}>
				<Box className="ml-20">
					<TabList
						onChange={handleChange}
						TabIndicatorProps={{
							style: {
								display: 'none',
							},
						}}
						textColor="inherit"
						variant="scrollable"
					>
						<Tab
							label={t('ACTIVE')}
							value="Active"
							sx={{
								fontWeight: value === 'Active' ? 600 : 400,
								fontSize: 18,
								textTransform: 'unset',
							}}
							className="mt-5"
						/>
						<Grid item width={10}>
							<Separator
								orientation="vertical"
								color={theme.palette.secondary.main}
								borderWidth={3}
							/>
						</Grid>

						<Tab
							label={t('ARCHIVE')}
							value="Archive"
							sx={{
								fontWeight: value === 'Archive' ? 600 : 400,
								fontSize: 18,
								textTransform: 'unset',
							}}
							className="mt-5"
						/>
					</TabList>
				</Box>
				<Grid item className="py-10">
					<Separator color={theme.palette.primary.main} />
				</Grid>
				<TabPanel value="Active">
					<ActiveScreen activeLoading={policyLoading} />
				</TabPanel>
				<TabPanel value="Archive">
					<ArchiveScreen archiveLoading={policyLoading} />
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export default PolicyTab;
