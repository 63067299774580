
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../common/store';

interface PdfState {
  open: boolean;
  pdfUrl?: string;
  loading?: boolean;
}

const initialState: PdfState = {
  open: false,
};

const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    openPdf: (state, { payload }: { payload: {
       pdfUrl: string 
} }) => {
      state.open = true;
      state.pdfUrl = payload.pdfUrl;
    //   state.loading = payload.loading ?? false;
    },
    closePdf: (state) => {
      state.open = false;
      state.pdfUrl = undefined;
    //   state.loading = false;
    },
    // setPdfLoading: (state, { payload }: { payload: boolean }) => {
    //   state.loading = payload;
    // },
  },
});

export const { openPdf, closePdf} = pdfSlice.actions;
export default pdfSlice.reducer;

export const selectPdfState = (state: RootState) => state.pdf;
