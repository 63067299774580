import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TotalFundAndLossCoverDetails from './TotalFundAndLossCoverDetails';
import LoanPayoutDetails from './LoanPayoutDetails';
import './LoanModule.scss';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RoundIcon } from '../../../common/icons/LoanModule/round_icon.svg';
import { ReactComponent as PlusIcon } from '../../../common/icons/LoanModule/Plus-icon.svg';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import Separator from '../../../common/ui/Separator';
import ProductDetails from './ProductDetails';
import CustomButton from '../../../common/ui/CustomButton';
import {
	saveSelectedCombinationRequest,
	selectedCombinationRequest,
} from './Loan.slice';
import { convertAmount } from '../../../common/utils';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';

interface RecommendedCombinationProps {
	combinationsData?: any;
	combinationNumber?: any;
	requiredAmount?: any;
	requiredDate?: any;
}

const RecommendedCombination = ({
	combinationsData,
	combinationNumber,
	requiredAmount,
	requiredDate,
}: RecommendedCombinationProps) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const userData: any = useSelector(selectIsUserLoggedIn);
	const handleSuccess = (data: any) => {
		if (data?.sessionId && data?.sessionDate) {
			navigate(APP_ROUTES.TRACK_TRANSACTION);
		}
	};
	const handleAddClick = (Combination: any) => {
		if (
			combinationsData?.Widgets?.some(
				(widget: any) => widget.widgetType === 'L'
			)
		) {
			const payload: any = {
				Combination,
				requiredAmount,
				requiredDate,
				navigate,
			};
			dispatch(selectedCombinationRequest(payload));
		} else {
			const modifiedData: any = {
				combinationName: Combination?.combinationName,
				totalCharges: '',
				totalChargesDetails: '',
				totalFundsAvailed: Combination?.totalFundsAvailable,
				lossOfCover: Combination?.lossOfCover,
				Widgets: Combination?.Widgets.map((widget: any) => {
					const commonProperties: any = {
						sessionId: userData?.sessionId,
						panNo: userData?.pan,
						camsRefNo: '',
						BankDetails: {
							bankAcNo: 'Y',
							bankName: 'SBI',
							bankBranch: 'TNagar Chennai',
							bankIfscCode: 'SBI00034',
							address: 'chn',
							bankState: 'TN',
							pincode: '455653',
						},
					};
					const clonedWidget: any = {
						...widget,
						...commonProperties,
					};
					return clonedWidget;
				}),
			};
			const payload: any = {
				modifiedData,
				requiredAmount,
				requiredDate,
				redirectUrl: '',
				handleSuccess,
			};
			dispatch(saveSelectedCombinationRequest(payload as any));
		}
	};

	return (
		<>
			<Grid className="need-money">
				<Grid item className="need-money" container alignItems="center">
					<Grid container>
						<Typography className="f-14 fw-400 mb-10 mr-5" color="black">
							{combinationNumber}
						</Typography>

						<Grid>
							<Grid item>
								<Typography className="f-14 fw-600 mb-10" color="primary">
									{combinationsData?.combinationName}
								</Typography>
							</Grid>
							{combinationsData?.combinationName === 'Combination3' && (
								<Grid item className="mb-5">
									<Typography
										component="span"
										className="f-14"
										color={theme.palette.error.main}
									>
										{`(${t('Not Recommended')})`}
									</Typography>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item className="need-money mb-30">
					<Separator color={theme.palette.grey[300]} />
				</Grid>
				{combinationsData?.Widgets?.map((widget: any, index: number) => (
					<>
						<Grid item className="my-options">
							<SvgConverter Icon={RoundIcon} className="round-icon" />
							<Typography
								component="span"
								className="icon-number f-16"
								color={theme.palette.common.black}
							>
								{widget?.widgetNo}
							</Typography>
							<Grid
								container
								spacing={1}
								display="flex"
								justifyContent="space-between"
							>
								<Grid item flexDirection="column" xs={5} className="ml-5">
									<ProductDetails
										product={widget?.productType}
										productData={widget}
										selfName={widget?.payerName}
									/>
								</Grid>

								<Grid item className="ml-30" alignItems="center">
									<Separator
										orientation="vertical"
										color={theme.palette.grey[300]}
										borderWidth={1}
									/>
								</Grid>

								{widget?.Surrender && (
									<Grid item flexDirection="column" xs={5}>
										<LoanPayoutDetails
											payDetail="surrender"
											date="2/09/2023"
											fundAmt={widget?.Surrender?.surrenderValue}
										/>
									</Grid>
								)}
								{widget?.Withdrawal && (
									<Grid item flexDirection="column" xs={5}>
										<LoanPayoutDetails
											payDetail="partial"
											fundAmt={widget?.Withdrawal?.partialWithdrawalAmount}
											fundValue={
												widget?.Withdrawal?.partialWithdrawalPercentage
											}
										/>
									</Grid>
								)}
								{widget?.Loan && (
									<Grid item flexDirection="column" xs={5}>
										<LoanPayoutDetails
											payDetail="loan"
											fundAmt={widget?.Loan?.loanAmount}
											fundValue={widget?.Loan?.loanPercentage}
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
						{widget?.Loan && (
							<Grid item>
								<Typography className="f-10" color={theme.palette.grey[400]}>
									{t('Recommended Values Shown. Click "Proceed" to Edit.')}{' '}
								</Typography>
							</Grid>
						)}
						{widget?.Withdrawal && (
							<Grid item>
								<Typography className="f-10" color={theme.palette.grey[400]}>
									{t('Recommended Values Shown. Click "Proceed" to Edit.')}{' '}
								</Typography>
							</Grid>
						)}
						{index !== combinationsData.Widgets.length - 1 && (
							<Grid
								item
								container
								justifyContent="center"
								className="mt-10 mb-20"
							>
								<SvgConverter Icon={PlusIcon} />
							</Grid>
						)}
					</>
				))}
				<Grid item className="my-10">
					<TotalFundAndLossCoverDetails
						TotalNo={`(${Array.from(
							{ length: combinationsData?.Widgets?.length },
							(_, i) => i + 1
						).join('+')})`}
						// rupee1="XXXXXXXX"(widget?.widgetNo)
						// rupee2="Nil"
						totalFunds={convertAmount(combinationsData?.totalFundsAvailable)}
						combination={combinationsData}
						lossOfCover={convertAmount(combinationsData?.lossOfCover)}
					/>
				</Grid>
			</Grid>
			<Grid item className="mb-15 mt-10">
				<Separator color={theme.palette.grey[300]} />
			</Grid>
			<Grid container spacing={1} justifyContent="flex-end">
				<CustomButton
					fontSize={16}
					fontWeight={600}
					text={t('PROCEED')}
					showIcon
					endIcon={rightArrow}
					variant="text"
					color="primary"
					onClick={() => {
						handleAddClick(combinationsData);
					}}
				/>
			</Grid>
			<Grid item className="mb-10 mt-10">
				<Separator color={theme.palette.grey[300]} />
			</Grid>
		</>
	);
};

export default RecommendedCombination;
