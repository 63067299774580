import { useSelector } from "react-redux";
import { selectInsurer } from "./addPolicy.slice";
import NonIntegratedOTPCard from "./NonIntegratedOTPCard";
import { Grid } from "@mui/material";
import CustomButton from "../../../common/ui/CustomButton";
import { useTranslation } from "react-i18next";

const PolicyFromNonIntegratedInsurer = () => {
    // const Insurer = useSelector(selectInsurer);

    const { t } = useTranslation();
    const Insurer = [
        {
            "Description": "Aegon Life Insurance Company Ltd - Life Insurance",
            "Logo": "https://policygenie2.blob.core.windows.net/insurerlogo/aegon_life_ins.png",
            "Code": "01"
        },
        {
            "Description": "Bajaj Allianz Life Insurance Co. Ltd - Life Insurance",
            "Logo": "https://policygenie2.blob.core.windows.net/insurerlogo/bajaj_allianz_life_ins.png",
            "Code": "03"
        }
    ]
    console.log(Insurer)
    return (<>

        {Insurer && Insurer.map((item: any) => (<NonIntegratedOTPCard item={item} />))}


        <Grid container spacing={1} justifyContent="center">
            <CustomButton
                text={t('PROCEED')}
                variant="text"
                textAlign={'right'}
                color="primary"
                // onClick={() => PolicyIntegrated({ "PolicyIntegrated": true, "insurer": selectedInsurer })}
                className="fw-600 f-20 mt-20"

            />

        </Grid>
    </>)

}


export default PolicyFromNonIntegratedInsurer;