import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';



interface TermsConditionProps {
	termsConditionDetails?: any;
}
const TermsCondition: React.FC<TermsConditionProps> = ({
	termsConditionDetails,
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Terms and Condition Screen',
		});
	}, []);

	
	return (
		<>
			{termsConditionDetails &&
				termsConditionDetails.map((mapdata: any, index: any) => (
					<>
						{mapdata.description?.length !== 0 && (
							<Grid container item xs={12}>
								<Grid item container xs={12}>
									<Grid item xs={6} className="">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('TERMS_&_CONDITIONS')}
										</Typography>
									</Grid>
								</Grid>

								<Grid item container xs={12}>
									<Grid item xs={0.5} className="">
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
											fontWeight={600}
										>
											{index + 1}.
										</Typography>
									</Grid>
									<Grid item xs={5.5} className="">
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
											fontWeight={600}
										>
											{mapdata.description?.length === 0
												? '-'
												: mapdata.description}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
					</>
				))}
		</>
	);
};

export default TermsCondition;
