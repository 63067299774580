import { axiosInstance } from '../../../common/axiosInstance';
import { AddPolicyRoutes } from '../../../common/routes';

const axios = axiosInstance;

const fetchExistingPolicies = (data: any) =>
    axios.request({ ...AddPolicyRoutes.InsuranceSamadhanPolicyDetail, data });

const fetchInsSamadhanUploadDoc = (data: any) =>
    axios.request({ ...AddPolicyRoutes.InsSamadhanUploadDoc, data });

export {
    fetchExistingPolicies,
    fetchInsSamadhanUploadDoc
};