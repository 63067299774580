import React from 'react';
import { Grid } from '@mui/material';
import '../../Dashboard.scss';
import TrackerHeader from '../TrackerHeader';
import SessionCard from './SessionCard';

interface UserProps {
	dashboardLoading?: any;
	sessionDetails?: any;
}
const SessionTrackerScreen: React.FC<UserProps> = ({
	dashboardLoading,
	sessionDetails,
}) => (
	<Grid
		container
		mb={3}
		style={{ border: '1px solid black', borderRadius: '8px' }}
	>
		<TrackerHeader
			type="Session"
			date={sessionDetails?.requiredDate}
			sessionAmount={sessionDetails?.requiredAmount}
			startDate={sessionDetails?.sessionStartDate}
			sessionId={sessionDetails?.sessionId}
		/>
		<SessionCard
			dashboardLoading={dashboardLoading}
			sessionDetails={sessionDetails}
		/>
	</Grid>
);

export default SessionTrackerScreen;
