import { Grid, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from '../../../i18n';

import CustomButton from '../../../common/ui/CustomButton';

import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as BemaCentralLogo } from '../../../assets/images/BemaCentralLogo.svg';
import { ReactComponent as languageLogo } from '../../../common/icons/Language.svg';
import LoginForm from './LoginForm';
import './Login.scss';
import NeedSupport from '../common/NeedSupport';
import Separator from '../../../common/ui/Separator';
import LanguageSelector from './LanguageSelector';

// const languages = [
// 	{ value: '', text: 'Options' },
// 	{ value: 'en', text: 'English' },
// 	{ value: 'hi', text: 'Hindi' },
// 	{ value: 'ta', text: 'Tamil' },
// 	{ value: 'tel', text: 'Telugu' },
// ];
const Login = () => {
	const { t } = useTranslation();

	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const [contentType, setContentType] = useState('');

	const theme = useTheme();
	// useEffect(() => {
	// 	i18next.changeLanguage('en');
	// 	localStorage.setItem('ln', 'en');
	// }, []);
	return (
		<>
			<Grid container justifyContent="center" minHeight="100vh">
				<Grid
					minHeight="70vh"
					item
					flex={1}
					container
					justifyContent="center"
					alignItems="center"
					xs={12}
					lg={6}
					md={6}
					sm={6}
					xl={6}
				>
					<Grid
						item
						xs={12}
						container
						// spacing={2}
						// flexDirection="row"
						justifyContent="center"
						display="flex"
						textAlign="center"
					>
						<Grid
							item
							xs={12}
							justifyContent="center"
							display="flex"
							className="mb-5"
						>
							<SvgConverter
								Icon={BemaCentralLogo}
								width={140}
								height={85}
								className="my-20 mt-40"
							/>
						</Grid>
						<Grid
							item
							container
							justifyContent="center"
							className="login-width"
						>
							<Grid item xs={10} lg={9} md={9} sm={10} xl={10}>
								<LoginForm />
							</Grid>
						</Grid>
					</Grid>

					<Grid>
						<Typography
							className=" f-12 fw-400 mb-8 language cursor-pointer"
							color="info"
							onClick={() => {
								setShowLanguageModal(true);
								setContentType('LOGIN');
							}}
						>
							{t('LANGUAGE')}
						</Typography>
					</Grid>

					<Grid>
						<Typography
							className="mb-8 ml-10"
							onClick={() => {
								setShowLanguageModal(true);
								setContentType('LOGIN');
							}}
						>
							<SvgConverter Icon={languageLogo} className="rotate" />
						</Typography>
					</Grid>

					{/* <CustomButton
						text={'Language'}
						variant="text"
						color="info"
						fontSize={12}
						fontWeight={400}
						endIcon={languageLogo}
						className="mb-8 language"
						onClick={() => {
							setShowLanguageModal(true);
							setContentType('LOGIN');
						}}
					/> */}
				</Grid>
				<Grid
					minHeight="30vh"
					item
					className="login-footer"
					xs={12}
					justifyContent="center"
					alignItems="center"
				>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						className="w-100 "
						flexDirection="column"
						xs={12}
					>
						<Grid item xs={12} className="mt-18">
							<Typography color={theme.palette.common.white} fontSize={14}>
								{t('NEW_USER')}
							</Typography>
						</Grid>
						{/* <Grid item xs={12}>
							<Link to={AUTH_ROUTES.SIGNUP}>
								<CustomButton
									text={`${t('SIGN_UP')}`}
									variant="text"
									size="large"
									iconClassName="right-arrow"
									color="secondary"
									className="f-16 mb-8"
								/>
							</Link>
						</Grid>{' '} */}
						<Grid item xs={12}>
							<CustomButton
								text={`${t('SIGN_UP')}`}
								variant="text"
								size="large"
								iconClassName="right-arrow"
								color="secondary"
								className="f-16 mb-8"
								onClick={() => {
									setShowLanguageModal(true);
									setContentType('SIGNUP');
								}}
							/>
						</Grid>{' '}
						<Grid
							item
							justifyContent="center"
							alignItems="center"
							display="flex"
							xs={12}
							className=" mt-3 mb-13"
						>
							<Separator className="login-seperator" />
						</Grid>
					</Grid>
					<Grid mb={1}>
						<NeedSupport type="white" />
						{/* <Grid justifyContent="center" display="flex" alignItems="center">
							<Grid className="pb-10">
								<select value={lang} onChange={(e) => handleClick(e)}>
									{languages.map((item: any) => (
										<option key={item.value} value={item.value}>
											{item.text}
										</option>
									))}
								</select>
							</Grid>
						</Grid> */}
					</Grid>

					<Typography
						fontSize={12}
						className="mb-8 text-center footer-text  footer-position"
					>
						{t('POWERED_BY_CAMS_INSURANCE_REPOSITORY')}
					</Typography>
					{process.env.REACT_APP_MODE !== 'PROD' && (
						<Typography
							fontSize={12}
							className="mb-8 text-center footer-text  footer-position"
						>
							{'UAT -0.1'}
						</Typography>
					)}
				</Grid>
			</Grid>
			<LanguageSelector
				open={showLanguageModal}
				setOpen={setShowLanguageModal}
				// onSubmit={handleAddressChangesSubmit}
				// onCancel={handleCancelChanges}
				contentType={contentType}
			/>
		</>
	);
};
export default Login;
