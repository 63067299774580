import { Grid, Typography } from "@mui/material";
import CommonModal from "../../../common/ui/Modal/CommonModal";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../common/ui/CustomButton";
import { useNavigate } from "react-router-dom";
import { AUTH_ROUTES } from "../../../common/constants/Routes";

const EiaPortingModal = ({ open, setOpen, otherRepoData }: any) => {

    const { t } = useTranslation();
    const navigate = useNavigate();


    const submit = () => {
        navigate(AUTH_ROUTES.LOGIN);

    }

    return (<CommonModal
        onClose={() => setOpen(false)}
        open={open}
        modalClassName="px-20 py-20"
        boxProps={{ width: 365 }}

    >
        <Grid container >
            <Typography
                className="f-16 fw-600"
                textAlign={'center'}
            >
                {t(otherRepoData?.alreadyInformed === 'Y' ? 'PORTING_INSTRUCTIONS_ALREADY' : 'PORTING_INSTRUCTIONS')} &nbsp;
            </Typography>
            <Typography
                className="f-16 fw-600 mt-20 px-20"
                textAlign={'center'}

            >
                {t('DE_ACTIVE_EIA_ACCOUNT')} &nbsp; {otherRepoData?.repositoryName}
            </Typography>

        </Grid>
        <Grid container justifyContent={'center'}>
            {/* <Link to={AUTH_ROUTES.LOGIN}> */}
            <CustomButton
                text={t('OK')}
                variant="text"
                color="primary"
                type="submit"
                className="f-16 fw-600 mt-10"
                textAlign="center"
                onClick={submit}
            />
            {/* </Link> */}
        </Grid>
    </CommonModal>)
};

export default EiaPortingModal;