import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
  fetchSurrender,
  reportLoadSurrender,
  reportLoadErrorSurrender,
  fetchInitiateSurrender,
  initiateSurrenderError,
  initiateSurrenderSuccess,
} from './Surrender.slice';
import { fetchSurrenderList, fetchInitiateSurrenderData } from './Surrender.service';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';

function* handleFetchSurrender({ payload }: any) {
  try {
    console.log(payload);
    const user: any = yield* select(selectIsUserLoggedIn);
    const requestPayload = {
      PolicyDetails: {
        eiaNo: user.eiaNo,
        companyCode: payload.companyCode,
        policyNo: payload.policyNo
      },
    };
    const { data }: any = yield* call(fetchSurrenderList, requestPayload);
    yield* put(reportLoadSurrender(data));
  } catch (e: any) {
    yield* put(reportLoadErrorSurrender(e.message));
  }
}

function* handleFetchInitiateSurrender({ payload }: any) {
  try {
    console.log(payload);
    const user: any = yield* select(selectIsUserLoggedIn);

    const requestPayload = {
      PolicyDetails: {
        eiaNo: user.eiaNo,
        "policyNo": payload?.policyNo,
        "companyCode": payload?.companyCode,
        "maturityValue": payload?.maturityValue,
        "currentSurrenderValue": payload?.currentSurrenderValue,
        "payeeAccNo": payload?.payeeAccNo,
        "payeeAccName": payload?.payeeAccName,
        "payeeIfscCode": payload?.payeeIfscCode,
        "bankName": payload?.bankName,
        "branchName": payload?.branchName,
        "payeeBankVerified": payload?.bankVerified
      },
    };
    const { data }: any = yield* call(fetchInitiateSurrenderData, requestPayload);
    yield* put(initiateSurrenderSuccess(data));
    if (payload?.handleSuccess) {
      payload?.handleSuccess()
    }
  } catch (e: any) {
    yield* put(initiateSurrenderError(e.message));
  }
}

function* watchSurrender() {
  yield* takeEvery(fetchSurrender.type, handleFetchSurrender);
  yield* takeEvery(fetchInitiateSurrender.type, handleFetchInitiateSurrender);
}

export { watchSurrender };
