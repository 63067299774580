import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as checked } from '../../../common/icons/profile/checked.svg';
import './profile.css';

const VerifiedAuthorisedPerson = () => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Grid item container spacing={0.5} flexWrap="nowrap">
			<Grid item>
				<SvgConverter
					Icon={checked}
					width="11px"
					height="8px"
					className="authorized_tick_mark"
				/>
			</Grid>
			<Grid item>
				<Typography className="f-14" color={theme.palette.common.black}>
					{t('AUTHORIZED_PERSON')}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default VerifiedAuthorisedPerson;
