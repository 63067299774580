import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useTheme } from '@mui/material';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import RupeeIcons, {
	ReactComponent as RupeeIcon,
} from '../../../../../common/icons/LoanModule/rupee-icon.svg';

import Separator from '../../../../../common/ui/Separator';
import BankDetails from '../../BankDetails';
import TimelineScreen from './TimeLineScreen';
import FinancierDetails from '../../FInancierDetails';
import WitnessDetails from '../../WitnessDetails';
import { convertAmount, getFormatDate } from '../../../../../common/utils';

interface UserProps {
	icon: any;
	header: any;
	productLogo?: any;
	widgetNo?: any;
	productName?: any;
	label1?: any;
	label2?: any;
	head: any;
	label6?: any;
	label7?: any;
	notSelect?: boolean;
	step?: any;
	trackTransactionWholeData?: any;
	loan?: boolean;
	selfName?: any;
	label1Value?: any;
	label2Value?: any;
	loanof?: any;
	witnessDetails?: any;
	lable6Value?: any;
	label7Value?: any;
	estimatedProcessingTimeHrs?: any;
	elaspsedTimeHrsMins?: any;
	label10?: any;
	lable10Value?: any;
}
const TrackerCards = ({
	icon,
	header,
	head,
	productLogo,
	widgetNo,
	productName,
	label1,
	label2,
	label6,
	label7,
	label10,
	notSelect,
	step,
	trackTransactionWholeData,
	selfName,
	loan = false,
	label1Value,
	label2Value,
	loanof,
	witnessDetails,
	lable6Value,
	label7Value,
	estimatedProcessingTimeHrs,
	elaspsedTimeHrsMins,
	lable10Value,
}: UserProps) => {
	const matchingLender = step?.Loan?.LenderDetails;
	const bankDetails = step?.BankDetails;

	const { t } = useTranslation();
	const theme = useTheme();
	return (
		<Grid
			className={loan ? 'recommended-cards mb-50' : 'recommended-card mb-50 '}
		>
			<Grid display="flex" justifyContent="center" mt={-2.4}>
				<Grid
					className="recommended-card-num"
					mr={0.3}
					justifyContent="center"
					display="flex"
				>
					<Typography justifyContent="center" display="flex">
						{widgetNo}
					</Typography>
				</Grid>
				<Grid
					className="recommended-card-text"
					ml={0.2}
					justifyContent="center"
					display="flex"
					alignItems="center"
				>
					<Grid item spacing={0.5}>
						<LabelWithIcon
							svgClassName="product-rupee-icon"
							justifyContent="flex-start"
							Icon={icon}
							label={head}
							labelProps={{
								color:
									header === 'Surrender'
										? theme.palette.error.dark
										: theme.palette.primary.dark,
								fontSize: 14,
								fontWeight: 600,
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				alignItems="center"
				className={
					loan ? 'recommended-card-products' : 'recommended-card-product'
				}
				py={2}
				pl={2}
			>
				<Grid mr={2}>
					<img src={productLogo} alt="logo" className="bank_logo" />
				</Grid>
				<Grid justifyContent="center">
					<Typography
						className="fw-600 f-14"
						color={theme.palette.primary.dark}
					>
						{productName}
					</Typography>
					<Typography className="f-14" color={theme.palette.primary.dark}>
						{t('LIFE_ASSURED')}
					</Typography>
					<Typography
						className="f-14 fw-600"
						color={theme.palette.common.black}
					>
						{selfName}
					</Typography>
				</Grid>
			</Grid>
			{loan === false && (
				<Grid>
					<Separator color={theme.palette.grey[300]} />
				</Grid>
			)}
			<Grid
				container
				alignItems="center"
				className={
					loan ? 'recommended-card-products' : 'recommended-card-product'
				}
				py={2}
				pl={2}
			>
				<Grid item xs={6}>
					<Typography
						className="f-12 fw-600"
						color={theme.palette.primary.dark}
					>
						{label1}
					</Typography>
					<Grid item spacing={0.5}>
						<LabelWithIcon
							svgClassName="product-rupee-icon"
							justifyContent="flex-start"
							Icon={RupeeIcon}
							label={label1Value}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
					</Grid>
				</Grid>
				{label2 && (
					<Grid item xs={6}>
						<Typography
							className="f-12 fw-600"
							color={theme.palette.primary.dark}
						>
							{label2}
						</Typography>
						<Grid item spacing={0.5}>
							<LabelWithIcon
								svgClassName="product-rupee-icon"
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={label2Value}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 12,
									fontWeight: 600,
								}}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid>
				<Separator color={theme.palette.grey[300]} />
			</Grid>
			{label10 && (
				<>
					<Grid container py={1}>
						{loan === false && (
							<Grid item pl={2}>
								<Typography
									className="f-14 mb-3 "
									color={theme.palette.error.dark}
								>
									{label10}
								</Typography>
								<Grid item spacing={0.5}>
									<LabelWithIcon
										svgClassName="product-rupee-icon"
										justifyContent="flex-start"
										Icon={RupeeIcon}
										label={`${lable10Value}  `}
										labelProps={{
											color: theme.palette.error.dark,
											fontSize: 15,
										}}
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Separator color={theme.palette.grey[300]} />
				</>
			)}

			<Grid
				container
				alignItems="center"
				className="recommended-card-product2 "
				py={loan ? 0 : 2}
				pb={2}
			>
				{loan === false && (
					<Grid item pl={2}>
						<>
							<Typography
								className="f-14 mb-3 "
								color={theme.palette.common.black}
							>
								{label6}
							</Typography>
							<Grid item spacing={0.5}>
								{label6 && (
									<LabelWithIcon
										svgClassName="product-rupee-icon"
										justifyContent="flex-start"
										Icon={RupeeIcon}
										label={`${lable6Value}  ${header === 'Surrender' ? '' : t('INITIATED')
											}. `}
										labelProps={{
											color: theme.palette.common.black,
											fontSize: 15,
										}}
									/>
								)}

								{label7 && (
									<Typography
										className="f-12 my-3"
										color={theme.palette.common.black}
										display="flex"
									>
										[&nbsp;{' '}
										<Typography className="f-12" color="orange">
											{' '}
											{label7Value}
										</Typography>
										&nbsp;of {label7} ]
									</Typography>
								)}

								<Typography
									className="f-12 mt-15"
									color={theme.palette.grey[600]}
									display="flex"
								>
									{t('ESTIMATED_PROCESSING_TIME')}: &nbsp;{' '}
									<Typography className="f-12" color="orange">
										{' '}
										{estimatedProcessingTimeHrs}
									</Typography>
									&nbsp;{' '}
									<Typography
										className="f-12"
										color={theme.palette.primary.main}
									>
										{' '}
										{t('HOURS')}
									</Typography>
								</Typography>
								<Typography
									className="f-14 fw-600"
									color={theme.palette.common.black}
									display="flex"
								>
									{t('TIME_ELAPSED')}: &nbsp;{' '}
									<Typography className="f-14" color="orange">
										{' '}
										{elaspsedTimeHrsMins}
									</Typography>
								</Typography>
								<Typography
									className=" f-12 fw-600 ml-1 mt-3  mb-3"
									color=" #aaaaaa"
									fontSize={14}
								>
									{t('TERMS_AND_CONDITIONS_APPLY')}
								</Typography>
							</Grid>
						</>
					</Grid>
				)}

				{loan === true && (
					<Grid mb={2}>
						<Grid className="recommended-card-product">
							<Grid alignItems="baseline" display="flex" py={2} ml={3}>
								<Typography className="f-14" color={theme.palette.primary.dark}>
									{t('LOAN_OF')} &nbsp;
								</Typography>
								<img
									src={RupeeIcons}
									style={{ marginRight: '4px', height: '10px', width: '5px' }}
									alt="rs"
								/>
								<Typography className="f-20 fw-600">{loanof}</Typography>
								<Typography
									className="f-14 "
									color={theme.palette.primary.dark}
								>
									&nbsp; {t('INITIATED')}.
								</Typography>
							</Grid>
							<Grid>
								<Separator color={theme.palette.grey[300]} />
							</Grid>

							<TimelineScreen widget={step} trackTransactionWholeData={trackTransactionWholeData} timeLine />
						</Grid>
						<Grid mb={3}>
							<Separator color={theme.palette.grey[300]} />
						</Grid>
						<Grid xs={10} ml={2}>
							<Grid xs={8} display="flex">
								<Typography className="f-14" color={theme.palette.common.black}>
									{t('FINANCIER')}
								</Typography>
							</Grid>
							<Grid xs={11}>
								<Separator color=" #d7d7d7" />
							</Grid>

							<FinancierDetails
								financierName={matchingLender?.lenderName}
								loanAmount={convertAmount(step?.Loan?.loanAmount)}
								interest={matchingLender?.interestPercentage}
								charges={convertAmount(matchingLender?.charges)}
								repayment={matchingLender?.repaymentOptionDescription}
								productLogo={matchingLender?.lenderLogo}
							/>
						</Grid>
					</Grid>
				)}
				{loan === false && <Separator color={theme.palette.grey[300]} />}

				<Grid pl={2} mt={2}>
					<Grid item xs={12} display="flex" justifyContent="space-between">
						<Typography className="f-14" color={theme.palette.common.black}>
							{t('BANK_DETAILS')}
						</Typography>
					</Grid>
					<Grid item xs={11}>
						<Separator color={theme.palette.grey[300]} />
					</Grid>

					<BankDetails
						verifyBank={bankDetails?.bankVerified}
						bankName={bankDetails?.bankName}
						branch={bankDetails?.bankBranch}
						ifscCode={bankDetails?.bankIfscCode}
						accountNum={bankDetails?.bankAcNo}
						bankLogo={bankDetails?.bankLogo}
						accountHolder={bankDetails?.AcHolderName}
						notSelect={notSelect}
						selectButton
						widgetNos={parseInt(widgetNo)}
					/>
					<Grid />
				</Grid>

				{loan === true && (
					<Grid mt={3} className="mt-20">
						<Grid xs={10} ml={2}>
							<Grid xs={8} display="flex">
								<Typography className="f-14" color={theme.palette.common.black}>
									{t('WITNESS_DETAILS')}
								</Typography>
							</Grid>
							<Grid xs={11}>
								<Separator color=" #d7d7d7" />
							</Grid>
							<WitnessDetails
								witnessDOB={getFormatDate(
									witnessDetails?.Witness?.witnessDob,
									'YYYY-MM-DD'
								)}
								witnessEmail={witnessDetails?.Witness?.emailID}
								witnessFirstName={witnessDetails?.Witness?.witnessFirstName}
								witnessLastName={witnessDetails?.Witness?.witnessLastName}
								witnessMobile={witnessDetails?.Witness?.witnessMobileNo}
								witnessPan={witnessDetails?.Witness?.pan}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default TrackerCards;
