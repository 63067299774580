import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Separator from '../../../common/ui/Separator';
import './PersonalProfileScreen.css';
import AddressDetails from './AddressDetails';
import { APP_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';

const PersonalAddress = ({
	PolicyAddress,
	PermanentAddress,
}: {
	PolicyAddress: any;
	PermanentAddress: any;
}) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Grid>
			<Grid item>
				<Grid container spacing={0.5} className="py-10">
					{PermanentAddress ? (
						<Grid
							item
							lg={3}
							xl={2}
							md={4}
							sm={6}
							xs={12}
							className=" profile-personal-address"
						>
							<Typography className="f-14 fw-600 mb-14" color="primary">
								{t('PERMANENT_ADDRESS')} [{t('AS_PER')}{' '}
								{PermanentAddress?.addressProofDoc}]
							</Typography>
							<Grid item className="mb-4">
								<AddressDetails address={PermanentAddress || null} />
							</Grid>
							{PermanentAddress?.address1 ||
							PermanentAddress?.address2 ||
							PermanentAddress?.address3 ||
							PermanentAddress?.city ||
							PermanentAddress?.district ||
							PermanentAddress?.state ||
							PermanentAddress?.country ||
							PermanentAddress?.pincode ? (
								<>
									<Link to={APP_ROUTES.MANAGE_ADDRESS}>
										<CustomButton
											text={t('SEE_ASSIGN_POLICIES')}
											variant="text"
											showIcon={false}
											color="primary"
											className="f-12 my-10 fw-400"
										/>
									</Link>
									<Link to={APP_ROUTES.MANAGE_ADDRESS}>
										<CustomButton
											text={t('CHANGE_ADDRESS')}
											variant="text"
											showIcon={false}
											color="primary"
											className="f-12 my-10 fw-400"
										/>
									</Link>
								</>
							) : (
								<Link to={APP_ROUTES.MANAGE_ADDRESS}>
									<CustomButton
										text={t('TAP_TO_ADD_ADDRESS')}
										variant="text"
										showIcon={false}
										color="primary"
										className="f-12 my-10 fw-400"
									/>
								</Link>
							)}
						</Grid>
					) : (
						<Grid
							item
							lg={3}
							xl={2}
							md={4}
							sm={6}
							xs={12}
							className=" profile-personal-address"
						>
							<Typography className="f-14 fw-600 mb-14" color="primary">
								{t('PERMANENT_ADDRESS')} {t('AS_PER_ID')}
							</Typography>

							<Link to={APP_ROUTES.MANAGE_ADDRESS}>
								<CustomButton
									text={t('TAP_TO_ADD_ADDRESS')}
									variant="text"
									showIcon={false}
									color="primary"
									className="f-12 my-10 fw-400"
								/>
							</Link>
						</Grid>
					)}

					{PolicyAddress &&
						PolicyAddress.map((address: any) => (
							<Grid item lg={3} xl={2} md={4} sm={6} xs={12}>
								<Typography
									className="f-14 fw-600 mb-10 profile-personal-address"
									color="primary"
								>
									{address.city ? `${address.city} Address` : null}
								</Typography>
								<Grid item className="mb-4">
									<AddressDetails address={address || null} />
								</Grid>
								{address?.address1 ||
								address?.address2 ||
								address?.address3 ||
								address?.city ||
								address?.state ||
								address?.country ||
								address?.pincode ? (
									<Link to={APP_ROUTES.MANAGE_ADDRESS}>
										<CustomButton
											text={t('SEE_ASSIGN_POLICIES')}
											variant="text"
											showIcon={false}
											color="primary"
											className="f-12 my-10 fw-400"
										/>
									</Link>
								) : null}
							</Grid>
						))}
					<Grid item className="mt-14 w-100">
						<Separator color={theme.palette.primary.main} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PersonalAddress;
