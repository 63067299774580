import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './LoanModule.scss';
import { useSelector } from 'react-redux';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import RecommendedCombination from './RecommendedCombination';
import LoanOptionHeader from './LoanOptionHeader';
import NeedMoneyHeader from './NeedMoneyHeader';
import { myOptions, selectMyOptionIsLoading } from './Loan.slice';
import Spinner from '../../../common/ui/Spinner';
import { convertAmount, getFormatDate } from '../../../common/utils';

// import MaxLoanCombination from './MaxLoanCombination';

// import MaxLoanCombination from './MaxLoanCombination';

const LoanOptionScreen = () => {
	const theme = useTheme();

	const { t } = useTranslation();

	// const dispatch = useDispatch();
	const loanOptionScreenData = useSelector(myOptions);
	const loanIsLoading = useSelector(selectMyOptionIsLoading);
	return (
		<>
			{loanIsLoading ? (
				<Grid container justifyContent="center" alignItems="center">
					<Spinner />
				</Grid>
			) : (
				<>
					<Grid item className="mb-40">
						<NeedMoneyHeader />
					</Grid>
					<Grid item className="my-8">
						<Grid container flexWrap="nowrap" spacing={1}>
							<Grid item>
								<Typography
									className="f-14 fw-600 mt-3"
									color={theme.palette.primary.main}
								>
									{t('Need')}
								</Typography>
							</Grid>
							<Grid item>
								<Grid container spacing={0.5}>
									<Grid item>
										<SvgConverter
											Icon={RupeeIcon}
											width={8}
											height={12}
											className="mt-8"
										/>
									</Grid>
									<Grid item>
										<Typography
											className="f-18 fw-600"
											color={theme.palette.common.black}
										>
											{convertAmount(loanOptionScreenData?.requiredAmount)}
										</Typography>
									</Grid>
								</Grid>
							</Grid>

							<Grid item>
								<Typography
									className="f-14 fw-600 mt-3"
									color={theme.palette.primary.main}
								>
									{t('By')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									className="f-18 fw-600"
									color={theme.palette.common.black}
								>
									{getFormatDate(
										loanOptionScreenData?.requiredDate,
										'DD-MM-YYYY'
									)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<LoanOptionHeader headerData={loanOptionScreenData} />
					</Grid>
					<Grid container className="mt-15" justifyContent="space-between">
						{loanOptionScreenData?.Combinations?.map(
							(combination: any, i: number) => (
								<Grid item>
									<RecommendedCombination
										combinationsData={combination}
										combinationNumber={i + 1}
										requiredAmount={loanOptionScreenData?.requiredAmount}
										requiredDate={loanOptionScreenData?.requiredDate}
									/>
								</Grid>
							)
						)}

						{/* <Grid item>
					<MaxLoanCombination
					// combinationsData={loanOptionScreenData.Combinations}
					/>
				</Grid>
				<Grid item>
					<MaxedOutCombination
					// combinationsData={loanOptionScreenData.Combinations}
					/>
				</Grid> */}
					</Grid>
				</>
			)}
		</>
	);
};

export default LoanOptionScreen;
