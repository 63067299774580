/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';

const SvgConverter = ({
	Icon = false,
	className = '',
	color = 'black',
	disabled = false,
	width = 0,
	height = 0,

	...rest
}: {
	Icon?: any;
	className?: string;
	color?: string;
	disabled?: boolean;
	width?: number | string;
	height?: number | string;
}) => (
	<>
		{Icon ? (
			<Icon
				style={{ display: 'flex' }}
				fill={color}
				className={`icon-converter ${className} color-${
					!disabled ? color : 'muted'
				}`}
				{...(height ? { height } : {})}
				{...(width ? { width } : {})}
				{...rest}
			/>
		) : (
			<></>
		)}
	</>
);

export default SvgConverter;
