import { Grid } from '@mui/material';
import React from 'react';
import ProfilePic from '../../../../common/icons/profile/profile-icon.svg';
import female from '../../../../common/icons/profile/female_icon.svg';
import female1 from '../../../../common/icons/profile/fe_profile.png';
import othersicon from '../../../../common/icons/profile/others.png'
import ProfileCam from '../../../../common/icons/profile/profile-camera.svg';
import '../profile.css';
import { appendBase64PrefixForImage } from '../../../../common/utils';
const AddFamilyProfileUploader = ({
	handleUpload,
	imageBase64,
	relationshipType,
}: {

	handleUpload?: any;
	imageBase64?: any;
	relationshipType?: any;
}) => {
	// console.log(relationshipType, 'ajsdtysdug');
	return (
		<div onClick={handleUpload} onKeyDown={() => { }} role="button" tabIndex={0}>
			<>
				{relationshipType === '04' ||
					relationshipType === '03' ||
					relationshipType === '08' || relationshipType === '07' ? (
					<Grid item>
						<img
							src={
								imageBase64 ? appendBase64PrefixForImage(imageBase64) : female1
							}
							width={'40px'}
							height={'40px'}
							className="profile-pic"
							alt="img"
						/>
						<img src={ProfileCam} className="profile-cam-personal" alt="icon" />
					</Grid>
				) : relationshipType === '99' || relationshipType === '10' ? (
					<Grid item>
						<img
							src={
								imageBase64 ? appendBase64PrefixForImage(imageBase64) : othersicon
							}
							width={'40px'}
							height={'40px'}
							className="profile-pic"
							alt="img"
						/>
						<img src={ProfileCam} className="profile-cam-personal" alt="icon" />
					</Grid>
				) : (
					<Grid item>
						<img
							src={
								imageBase64
									? appendBase64PrefixForImage(imageBase64)
									: ProfilePic
							}
							width={'40px'}
							height={'40px'}
							className="profile-pic"
							alt="img"
						/>
						<img src={ProfileCam} className="profile-cam-personal" alt="icon" />
					</Grid>
				)}
			</>

			{/* <Grid item>
				<img
					src={imageBase64 ? appendBase64PrefixForImage(imageBase64) : female1}
					width={'40px'}
					height={'40px'}
					className="profile-pic"
					alt="img"
				/>
				<img src={ProfileCam} className="profile-cam-personal" alt="icon" />
			</Grid>
			<Grid item>
				<img
					src={imageBase64 ? appendBase64PrefixForImage(imageBase64) : ProfilePic}
					width={'40px'}
					height={'40px'}
					className="profile-pic"
					alt="img"
				/>
				<img src={ProfileCam} className="profile-cam-personal" alt="icon" />
			</Grid> */}
		</div>
	);
};

export default AddFamilyProfileUploader;
