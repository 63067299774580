import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { fetchLoginRequest, selectIsLoginRequestLoading } from '../auth.slice';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as MobileIcon } from '../../../common/icons/login_mobile-icon.svg';
import { ReactComponent as PanIcon } from '../../../common/icons/login_PAN-icon.svg';
import { ReactComponent as EmailIcon } from '../../../common/icons/login_email-icon.svg';
import { ReactComponent as eIAIcon } from '../../../common/icons/eia_icon.svg';
import { ReactComponent as LabelCalendarIcon } from '../../../common/icons/login_calendar_label-icon.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import DatePickerModal from '../../../common/ui/DatePickerModal';
import data from '../../../common/constants/FormConstants.json';
import config from '../../../common/config';
import './Login.scss';
import { setAlertInfo } from '../../App/Common/Common.slice';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const LoginForm = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isRequestLoading = useSelector(selectIsLoginRequestLoading);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		control,
	} = useForm({
		defaultValues: {
			userName: '',
			dob: '',
			// userName: 'KIRPS0588A',
			// dob: '01/06/1995',
			// userName: '9200000080507', // Don't use for development only for testing
			// dob: '24/07/1992',
		},
	});
	const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value
			.toUpperCase()
			.replace(/[^\w\s@.]/gi, '');
		setValue('userName', newValue, { shouldValidate: true });
	};
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const datePicker = (e: any) => {
		setAnchorEl(e.target);
	};

	useEffect(() => {
		const analytics = getAnalytics();

		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Login Screen',
		});
	}, []);

	const getLoginTypeByValue: any = (value: string) => {
		if (config.emailRegex.test(value)) {
			return 'EMAIL';
		}
		if (config.PanRegex.test(value)) {
			return 'PAN';
		}
		if (config.mobileRegex.test(value)) {
			return 'MOBILE';
		}
		if (config.eiaRegex.test(value)) {
			return 'EIA';
		}
		return '';
	};

	const onSubmit = (data: any) => {
		if (getLoginTypeByValue) {
			const payload = {
				Customer: {
					value:
						getLoginTypeByValue(data?.userName) === 'PAN'
							? String(data?.userName).toUpperCase()
							: data?.userName,
					dob: data?.dob,
					flag: getLoginTypeByValue(data?.userName),
				},
				navigate,
			};
			dispatch(fetchLoginRequest(payload as any));
		} else {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('PLEASE_ENTER_VALID_PAN_EIA_MOBILE_EMAIL'),
				})
			);
		}
	};

	const dobValue = String(watch('dob')).split('/');
	const handleDateChange = (date: any) => {
		setValue('dob', date?.format('DD/MM/YYYY'), { shouldValidate: true });
		// setAnchorEl(null);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				display="flex"
				flexDirection="column"
				justifyContent="center"
				className="loginform-bigscreen"
			>
				<Grid
					item
					container
					flexDirection="column"
					display="flex"
					justifyContent="center"
				>
					<Grid
						item
						container
						spacing={1}
						alignItems="end"
						justifyContent="center"
						flexDirection="row"
						xs={12}
					>
						<Grid item>
							<LabelWithIcon
								flexDirection="column"
								Icon={MobileIcon}
								label={t('MOBILE')}
								iconProps={{ height: 20 }}
								labelProps={{
									color: theme.palette.common.white,
									fontSize: 12,
									marginTop: '10px',
								}}
							/>
						</Grid>
						<Grid item className="mb-8">
							<Typography color={theme.palette.common.white} fontSize={12}>
								/
							</Typography>
						</Grid>
						<Grid item>
							<LabelWithIcon
								flexDirection="column"
								Icon={PanIcon}
								label={t('PAN')}
								iconProps={{ height: 28 }}
								labelProps={{ color: theme.palette.common.white, fontSize: 12 }}
							/>
						</Grid>
						<Grid item className="mb-8">
							<Typography color={theme.palette.common.white} fontSize={12}>
								/
							</Typography>
						</Grid>
						<Grid item>
							<LabelWithIcon
								flexDirection="column"
								Icon={EmailIcon}
								label={t('EMAIL')}
								iconProps={{ height: 28 }}
								labelProps={{ color: theme.palette.common.white, fontSize: 12 }}
							/>
						</Grid>
						<Grid item className="mb-8">
							<Typography color={theme.palette.common.white} fontSize={12}>
								/
							</Typography>
						</Grid>
						<Grid item>
							<LabelWithIcon
								flexDirection="column"
								Icon={eIAIcon}
								label={`${t('EIA')}#`}
								iconProps={{ height: 28 }}
								labelProps={{ color: theme.palette.common.white, fontSize: 12 }}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						className=" login-style"
						display="flex"
						justifyContent="center"
					>
						<Grid item xs={12}>
							<FormInput
								placeholder={`${t('ENTER_HERE')}.........`}
								className="username-input common-login mt-5"
								invertBorder
								backgroundColor={theme.palette.primary.main}
								name="userName"
								control={control}
								rules={{
									required: {
										value: true,
										message: t('Please enter a Mobile/PAN/email/eIA#'),
									},
									validate: (value: any) => {
										if (
											!config.emailRegex.test(value) &&
											!config.mobileRegex.test(value) &&
											!config.PanRegex.test(value) &&
											!config.eiaRegex.test(value)
										) {
											return t('Invalid User');
										}
										return true;
									},
								}}
								inputProps={{
									className: 'text-center',
									...register('userName', {
										onChange: handleUserNameChange,
									}),
								}}
								errors={errors}
								errorTextClassName='text-center need-support-no'
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={12} flexDirection="column" alignItems="center">
					<Grid
						item
						className=" mt-15 mb-5"
						display="flex"
						justifyContent="center"
					>
						<LabelWithIcon
							Icon={LabelCalendarIcon}
							label={t('DATE_OF_BIRTH')}
							iconProps={{ height: 33 }}
							labelProps={{ color: theme.palette.common.white, fontSize: 12 }}
							className="dob-input"
						/>
					</Grid>
					<Grid
						item
						container
						flexDirection="row"
						spacing={1}
						alignItems="center"
						flexWrap="nowrap"
						display="flex"
						justifyContent="center"
						xs={12}
						xl={10}
						className="ml-5"
					>
						<Grid item sm={3} lg={2.5} md={2.5} xs={2.8} xl={3}>
							<FormInput
								className="day-month-input common-login "
								invertBorder
								backgroundColor={theme.palette.primary.main}
								inputProps={{
									className: 'text-center',
									value: dobValue?.[0] ? dobValue?.[0] : '',
								}}
								placeholder={t('DD')}
								onClick={datePicker}
							// disabled={true}
							/>
						</Grid>
						<Grid item sm={3.2} lg={2.7} md={2.5} xs={2.8} xl={3}>
							<FormInput
								className="day-month-input common-login"
								invertBorder
								backgroundColor={theme.palette.primary.main}
								inputProps={{
									className: 'text-center',
									value: dobValue?.[1] ? dobValue?.[1] : '',
								}}
								placeholder={t('MM')}
								onClick={datePicker}
							// disabled={true}
							/>
						</Grid>
						<Grid item sm={4} lg={4.2} md={4} xs={4} xl={4}>
							<FormInput
								className="year-input common-login "
								invertBorder
								backgroundColor={theme.palette.primary.main}
								inputProps={{
									className: 'text-center',
									value: dobValue?.[2] ? dobValue?.[2] : '',
								}}
								placeholder={t('YYYY')}
								onClick={datePicker}
							// disabled={true}
							/>
						</Grid>
						<Grid
							item
							sm={2}
							lg={3}
							md={3}
							xs={2}
							xl={2}
							display="flex"
							// justifyContent="flex-end"

							className="dob-icon ml-4"
						>
							<DatePickerModal
								disableFuture
								onChange={handleDateChange}
								value={
									dobValue.length === 3
										? `${dobValue[0]}/${dobValue[1]}/${dobValue[2]}`
										: false
								}
								modalOpen={anchorEl}
								setModalOpen={setAnchorEl}
							/>
						</Grid>
					</Grid>
					<FormInput
						hidden
						name="dob"
						inputProps={{
							...register('dob', {
								required: {
									value: true,
									message: t('MANDATORY_FIELD'),
								},
							}),
						}}
						errors={errors}
						errorTextClassName='text-center need-support-no'
					/>
				</Grid>

				<Grid container justifyContent="center" className="my-15">
					<Grid item>
						<CustomButton
							loading={isRequestLoading}
							text={t('GET_LOGIN_OTP')}
							variant="text"
							iconClassName="right-arrow"
							type="submit"
							color="secondary"
							className="f-16 mt-8 "
						/>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

export default LoginForm;
