import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import Separator from '../../../../common/ui/Separator';
import {
	fetchFamilyRelationShipList,
	fetchUserFamilyListAction,
	selectFamilyListIsLoading,
	selectFamilyRelationShipList,
	selectIsFamilyRelationShipLoading,
	selectUserFamilyList,
} from '../Profile.slice';
import Spinner from '../../../../common/ui/Spinner';
import CustomButton from '../../../../common/ui/CustomButton';
import CustomPopOver from '../../../../common/ui/CustomPopOver';
import './AddFamilyMember.scss';
import { setAlertInfo } from '../../Common/Common.slice';
import CommonModal from '../../../../common/ui/Modal/CommonModal';

interface addModal {
	disabled?: boolean | undefined;
}

const AddFamilyMemberModal = ({
	anchorEle,
	handleClosed,
	open,
	setOpen,
	disabled = true,
	isNoFilter,
	mergeModal,
	editMember
}: any) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	dispatch(fetchFamilyRelationShipList());
	// 	dispatch(fetchUserFamilyListAction());
	// }, []);

	const familyList = useSelector(selectUserFamilyList);
	const isProfileLoading = useSelector(selectFamilyListIsLoading);
	const isRelationShipLoadingList = useSelector(
		selectIsFamilyRelationShipLoading
	);
	const relationShipList = useSelector(selectFamilyRelationShipList);
	const allowedRelationCodes = ['02', '03', '07', '06'];
	function isRelationCodeExists(relationCode: any) {
		return familyList?.some(
			(item: any) => item?.relationCode === relationCode);
	}
	const handleAddMember = (data: any) => {

		const isRelationCode = isRelationCodeExists(data?.relationCode);

		if (!isRelationCode) {
			if (isNoFilter) {
				mergeModal(data) //editMember(data)
			}
			else {
				navigate(APP_ROUTES.ADD_FAMILY_SCREEN, { state: { data } });
			}

			return;
		}
		if (allowedRelationCodes.includes(data?.relationCode)) {
			mergeModal(data)
		} else {
			if (isNoFilter) {
				mergeModal(data) //editMember(data)
			}
			else {
				navigate(APP_ROUTES.ADD_FAMILY_SCREEN, { state: { data } });
			}

		}
	};
	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName="px-0 py-20"
			boxProps={{ width: 355 }}
		>
			<Grid className="add-family-content h-100"
				style={{ maxHeight: '100vh', overflowY: 'auto' }}>
				{isRelationShipLoadingList || isProfileLoading ? (
					<Grid
						item
						display="flex"
						alignItems="center"
						justifyContent="center"
						className="mt-5"
					>
						<Spinner />
					</Grid>
				) : (
					relationShipList &&
					relationShipList.map((data, index) => (
						<Grid item key={index}>
							<CustomButton
								text={`${data.relationship}`}
								variant="text"
								color="inherit"
								showIcon={false}
								className="f-16 fw-600 px-15 py-13 text-style"
								onClick={() => handleAddMember(data)}
								disabled={isNoFilter ? !isNoFilter : isRelationCodeExists(data.relationCode) && allowedRelationCodes.includes(data.relationCode)}
							/>
							<Grid item className="w-100">
								{index !== relationShipList.length - 1 && (
									<Grid item className="w-100">
										<Separator color={theme.palette.primary.main} />
									</Grid>
								)}
							</Grid>
						</Grid>
					))
				)}
			</Grid>
		</CommonModal>
	);
};

export default AddFamilyMemberModal;
