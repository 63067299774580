import { Grid, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as ArrowLeftIcon } from '../../../../common/icons/policy/sm_black_arrow.svg';
import { ReactComponent as DownloadIcon } from '../../../../common/icons/policy/download_icon.svg';

interface FundProps {
	FundBack?: () => void;
}
const FundStatement: React.FC<FundProps> = ({ FundBack }) => {
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid display="flex" alignItems="center">
				<IconButton className="py-9" onClick={FundBack}>
					<SvgConverter Icon={ArrowLeftIcon} />
				</IconButton>
				<Typography className="f-16 fw-600 py-13" color="primary">
					{t('BACK')}
				</Typography>
			</Grid>
			<Separator color={theme.palette.primary.main} />
			<Grid item xs={12}>
				<Grid display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('FUND_STATEMENT')} 2021
					</Typography>
					<IconButton className="px-9">
						<SvgConverter Icon={DownloadIcon} />
					</IconButton>
				</Grid>
				<Separator color={theme.palette.primary.main} />
				<Grid display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('FUND_STATEMENT')} 2022
					</Typography>
					<IconButton className="px-9">
						<SvgConverter Icon={DownloadIcon} />
					</IconButton>
				</Grid>
				<Separator color={theme.palette.primary.main} />
				<Grid display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('FUND_STATEMENT')} 2023
					</Typography>
					<IconButton className="px-9">
						<SvgConverter Icon={DownloadIcon} />
					</IconButton>
				</Grid>
				<Separator color={theme.palette.primary.main} />
				<Grid display="flex" alignItems="center" justifyContent="space-between">
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('FUND_STATEMENT')} 2024
					</Typography>
					<IconButton className="px-9">
						<SvgConverter Icon={DownloadIcon} />
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default FundStatement;
