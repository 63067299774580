import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import Separator from '../../../common/ui/Separator';

interface UserProps {
	header: any;
	icon: any;
	productLogo?: any;
	widgetNo?: any;
	productName?: any;
	label1?: any;
	label1Value?: any;
	label2?: any;
	label2Value?: any;
	label3?: any;
	label3Value?: any;
	label4?: any;
	label4Value?: any;
	label5?: any;
	label5Value?: any;
	payerName?: any;
	head?: any;
}
const RecommendedCard = ({
	head,
	header,
	icon,
	productLogo,
	widgetNo,
	productName,
	label1,
	label1Value,
	label2,
	label2Value,
	label3,
	label3Value,
	label4,
	label4Value,
	label5,
	label5Value,
	payerName,
}: UserProps) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<Grid className="recommended-card">
			<Grid display="flex" justifyContent="center" mt={-2.4}>
				<Grid
					className="recommended-card-num"
					mr={0.3}
					justifyContent="center"
					display="flex"
				>
					<Typography justifyContent="center" display="flex">
						{widgetNo}
					</Typography>
				</Grid>
				<Grid
					className="recommended-card-text"
					ml={0.2}
					justifyContent="center"
					display="flex"
					alignItems="center"
				>
					<Grid item spacing={0.5}>
						<LabelWithIcon
							svgClassName="product-rupee-icon"
							justifyContent="flex-start"
							Icon={icon}
							label={head}
							labelProps={{
								color:
									header === 'Surrender'
										? theme.palette.error.dark
										: theme.palette.primary.dark,
								fontSize: 14,
								fontWeight: 600,
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				alignItems="center"
				className="recommended-card-product"
				py={2}
				pl={2}
			>
				<Grid mr={2}>
					<img src={productLogo} alt="logo" className="bank_logo" />
				</Grid>
				<Grid justifyContent="center">
					<Typography
						className="fw-600 f-14"
						color={theme.palette.primary.dark}
					>
						{productName}
					</Typography>
					<Typography className="f-14" color={theme.palette.primary.dark}>
						{t('LIFE_ASSURED')}
					</Typography>
					<Typography
						className="f-14 fw-600"
						color={theme.palette.common.black}
					>
						{payerName}
					</Typography>
				</Grid>
			</Grid>
			<Grid>
				<Separator color={theme.palette.grey[300]} />
			</Grid>
			<Grid
				container
				alignItems="center"
				className="recommended-card-product"
				py={1}
				pl={2}
			>
				<Grid item xs={6}>
					<Typography
						className="f-12 fw-600"
						color={theme.palette.primary.dark}
					>
						{label1}
					</Typography>
					<Grid item spacing={0.5}>
						<LabelWithIcon
							svgClassName="product-rupee-icon"
							justifyContent="flex-start"
							Icon={RupeeIcon}
							label={label1Value}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
					</Grid>
				</Grid>
				{label2 && (
					<Grid item xs={6}>
						<Typography
							className="f-12 fw-600"
							color={theme.palette.primary.dark}
						>
							{label2}
						</Typography>
						<Grid item spacing={0.5}>
							<LabelWithIcon
								svgClassName="product-rupee-icon"
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={label2Value}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 12,
									fontWeight: 600,
								}}
							/>
						</Grid>
					</Grid>
				)}

				{label3 && (
					<Grid item mt={1}>
						<Typography
							className="f-12 fw-600"
							color={theme.palette.primary.dark}
						>
							{label3}
						</Typography>
						<Grid item spacing={0.5}>
							<LabelWithIcon
								svgClassName="product-rupee-icon"
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={label3Value}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 12,
									fontWeight: 600,
								}}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid>
				<Separator color={theme.palette.grey[300]} />
			</Grid>
			<Grid
				container
				alignItems="center"
				className={header === 'Surrender' ? '' : 'recommended-card-product2'}
				py={2}
				pl={2}
			>
				<Grid item>
					<Typography
						className={header === 'Surrender' ? 'f-12 ' : 'f-14 '}
						color={
							header === 'Surrender'
								? theme.palette.error.dark
								: theme.palette.common.black
						}
					>
						{label4}
					</Typography>
					<Grid item spacing={0.5}>
						<LabelWithIcon
							svgClassName="product-rupee-icon"
							justifyContent="flex-start"
							Icon={RupeeIcon}
							label={label4Value}
							labelProps={{
								color:
									header === 'Surrender'
										? theme.palette.error.dark
										: theme.palette.primary.dark,
								fontSize: 16,
								fontWeight: 600,
							}}
						/>
						{header === 'Surrender' ? null : (
							<Typography
								className="f-12 "
								color={theme.palette.common.black}
								display="flex"
							>
								[&nbsp;{' '}
								<Typography className="f-12 orange-color fw-600">
									{label5Value}
								</Typography>{' '}
								&nbsp; {label5} ]
							</Typography>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default RecommendedCard;
