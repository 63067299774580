import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Grid,
	Typography,
	Box,
	useTheme,
	Card,
	CardContent,
} from '@mui/material';
import CustomButton from '../../../../common/ui/CustomButton';

import { ReactComponent as rightArrow } from '../../../../common/icons/right_arrow-icon.svg';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as AlertIconRed } from '../../../../common/icons/warningRed.svg.svg';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { ReactComponent as warningGrey } from '../../../../common/icons/warningGrey.svg';

const MergeMemeberModal = ({ open, setOpen, memberData, view }: any) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName="px-0 py-20"
			boxProps={{ width: 395, height: 355 }}
		>
			<Grid container justifyContent="center">
				<Box
					marginBottom={5}
					width={395}
					display="flex"
					justifyContent="end"
					flexDirection="column"
				>

					<Grid className="mb-10 p-20">
						<Card
							sx={{ minWidth: 100 }}
							variant="outlined"
						>
							<CardContent>
								<Grid display="flex">
									<Typography
										sx={{ fontSize: 16 }}
										color={theme.palette.error.main}
										gutterBottom
										className="ml-10 mt-1 fw-600"
									>
										{`"${memberData?.relationName}"`}
									</Typography>
									<Typography
										sx={{ fontSize: 16 }}
										color={theme.palette.error.main}
										gutterBottom
										className="ml-4 mt-1 fw-400"
									>
										{t('RELATIONSHIP_ALREADY_ADDED')}
									</Typography>
								</Grid>
								<Grid display="flex">
									<Typography
										sx={{ fontSize: 16 }}
										color="black"
										gutterBottom
										className="ml-10 mt-10 fw-400"
									>
										{t('PLEASE_SELECT_OPTION')}
									</Typography>
								</Grid>

								<Grid container xs={12}>
									<Grid xs={2} />
									<Grid
										xs={10}
										item
										className="mt-10"
										style={{ margin: '20px', paddingLeft: "18px" }}
									>
										<Link
											to={APP_ROUTES.MERGE_INFORMATION}
											state={{ data: memberData, view }}
										>
											<CustomButton
												text={t('MERGE_THE_INFORMATION_TOGETHER')}
												variant="text"
												color="primary"
												className="fw-600 pl-10 f-16 "
												fontSize={14}
												textAlign="end"
												endIcon={rightArrow}
											/>
										</Link>
									</Grid>
								</Grid>
								{/* <Grid container xs={12}>
								<Grid xs={4} />
								<Grid xs={7} item className="mt-20">
									<CustomButton
										text={t('RAISE_A_REQUEST_FOR_ADDING')}
										variant="text"
										color="inherit"
										className="fw-600 f-16"
										fontSize={14}
										textAlign="end"
										endIcon={rightArrow}
									/>
								</Grid>
							</Grid> */}
								{/* <Grid container xs={12}>
									<Grid xs={2} />
									<Grid
										xs={12}
										item
										className="mt-20"
										color={theme.palette.grey[600]}
									>
										<CustomButton
											text={t('CANCEL_ADDING_RELATIONSHIP')}
											variant="text"
											onClick={() => setOpen(false)}
											color="inherit"
											className="fw-600 f-16 mr-10 grey sentence-straight"
											fontSize={14}
											textAlign="end"
											endIcon={rightArrow}
										/>

										<Grid display="flex" className="align-middle mt-5">
											<Grid>
											<SvgConverter
													width={15}
													height={15}
													Icon={warningGrey}
													disabled={true}
													className='ml-48'
												/>
											</Grid>
											<Grid>
												<Typography
													color={theme.palette.grey[600]}
													className="f-10 text-right mr-20"
													style={{ width: '185px' }}
												>
													{`[${t('THE_ERROR_MESSAGE')}]`}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid> */}
								<Grid container xs={12}>
									<Grid
										xs={12}
										item
										className="mt-20"
										color={theme.palette.grey[600]}
									>
										<CustomButton
											text={t('CANCEL_ADDING_RELATIONSHIP')}
											variant="text"
											onClick={() => setOpen(false)}
											color="inherit"
											className="fw-600 f-16 pl-1 grey sentence-straight"
											fontSize={14}
											textAlign="end"
											endIcon={rightArrow}
										/>

										<Grid display="flex" className="align-middle mt-5">
											<Grid>
												<SvgConverter
													width={15}
													height={15}
													Icon={warningGrey}
													disabled={true}
													className='ml-48'
												/>
											</Grid>
											<Grid>
												<Typography
													color={theme.palette.grey[600]}
													style={{ width: '185px' }}
													className="f-10 text-right fw-600"
												>
													{`[${t('THE_ERROR_MESSAGE')}]`}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Box>
			</Grid>
		</CommonModal>
	);
};

export default MergeMemeberModal;
