import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import React from 'react';
import theme from '../../../../../common/theme';
import '../../Policy.scss';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRupee } from '../../../../../common/icons/small-orange-rupee.svg';
import { convertAmount } from '../../../../../common/utils';
import VerificationStatus from './VerificationStatus';
import { ReactComponent as OrangeButton } from '../../../../../common/icons/policy/orange-button.svg';
import Currency from '../../../Common/Currency';

interface UserProps {
	contentType?: string;
	items?: any;
}
const AssetsHeader: React.FC<UserProps> = ({ contentType, items }) => {
	const { t } = useTranslation();

	return (
		<Grid container>
			<Grid container className="px-10 py-15" spacing={2}>
				<Grid item xs={3}>
					<img
						src={items.companyLogo}
						width="60px"
						height="60px"
						className="bank-image"
						alt="bank"
					/>
					<VerificationStatus status={items.verifiedFlag} classname="ml-5" />
				</Grid>
				<Grid item xs={9}>
					<Typography
						className="f-16 fw-600 "
						color={theme.palette.primary.main}
					>
						{items.planName ? items.planName : '-'}
					</Typography>
					<Typography
						className="f-14 fw-600 "
						color={theme.palette.primary.main}
					/>{' '}
					<Typography className="f-14 mt-4" color={theme.palette.primary.main}>
						{t('COVER_TYPE')}
					</Typography>
					<Typography
						className="f-16 fw-600 mb-3"
						color={
							contentType === 'active'
								? theme.palette.common.black
								: theme.palette.grey.A700
						}
					>
						{items.coverType ? items.coverType : '-'}
					</Typography>
					<Typography
						className="f-12"
						color={
							contentType === 'active'
								? theme.palette.common.black
								: theme.palette.grey.A700
						}
					>
						{items.PlanName}
					</Typography>
					<Grid item container xs={12}>
						<Grid item xs={6}>
							<Typography
								className="f-14 mt-8"
								color={theme.palette.primary.main}
							>
								{t('IDV')}
							</Typography>
							<Grid
								container
								alignItems="center"
							>
								<Currency policyDetails={items} amount={items?.idv.length === 0 ? '-' : items?.idv} amountClass={"f-14 ml-5 fw-600"} />
							</Grid>
							{/* <LabelWithIcon
								className=" f-16"
								svgClassName="mb-2"
								justifyContent="flex-start"
								Icon={OrangeRupee}
								label={convertAmount(
									items?.idv.length === 0 ? '-' : items?.idv
								)}
								labelProps={{
									color:
										contentType === 'active'
											? theme.palette.common.black
											: theme.palette.grey.A700,

									fontSize: 14,
									fontWeight: 600,
								}}
							/> */}
						</Grid>{' '}
						{items?.depreciation && (
							<Grid item xs={6}>
								<Typography
									className="f-14 mt-8"
									color={theme.palette.primary.main}
								>
									{t('DEPRECIATION')}
								</Typography>
								<Typography
									className="f-16"
									color={theme.palette.common.black}
									fontWeight={600}
								>
									@{' '}
									{items?.depreciation.length === 0 ? '0' : items?.depreciation}
									%
								</Typography>
							</Grid>
						)}
					</Grid>
					{items?.covers && (
						<Grid item container xs={12}>
							<Typography
								className="f-14 fw-400 "
								color={theme.palette.primary.main}
							>
								{t('COVERS')}
							</Typography>
							{items?.covers && (
								<LabelWithIcon
									svgClassName=""
									justifyContent="flex-start"
									Icon={OrangeButton}
									label={items?.covers ? `${items?.covers}` : '-'}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 14,
									}}
								/>
							)}
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
export default AssetsHeader;
