import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
	handleFetchUserFamilyListInformation,
	handleFetchUserProfileInformation,
} from '../Profile/Profile.saga';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { uploadFamilyService, uploadService } from './Upload.service';
import { setAlertInfo } from '../Common/Common.slice';
import {
	familyUploadError,
	familyUploadRequest,
	familyUploadSuccess,
} from '../Profile/Profile.slice';
import {
	uploadOtherPhotoError,
	uploadOtherPhotoRequest,
	uploadOtherPhotoSuccess,
	uploadProfilePhotoError,
	uploadProfilePhotoRequest,
	uploadProfilePhotoSuccess,
	uploadSignaturePhotoError,
	uploadSignaturePhotoRequest,
	uploadSignaturePhotoSuccess,
} from './Upload.Slice';
import { getAnalytics, logEvent } from '@firebase/analytics';

const dest = localStorage.getItem('language');

const uploadFileToServer = (uploadInfo: any) => ({
	type: uploadFileToServer.type,
	payload: uploadInfo,
});
uploadFileToServer.type = 'file/upload';

function* handleUploadImage({
	payload,
}: ReturnType<typeof uploadFileToServer>) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				...payload,
			},
		};
		const { data }: any = yield* call(uploadService, requestPayload);
		yield call(handleFetchUserProfileInformation);
		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
	} catch (e: any) {
		yield* put(setAlertInfo(e.message));
	}
}

const fileUploadToServer = (familyInfo: any) => ({
	type: fileUploadToServer.type,
	payload: familyInfo,
});
fileUploadToServer.type = 'upload/file';

function* handleImageUpload(payload: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			Person: {
				seqId: payload?.payload?.seqId,
				dob: payload?.payload?.dob,
				relationCode: payload?.payload?.relationCode,
				imageBase64: payload?.payload?.imageBase64,
				fileName: payload?.payload?.fileName,
				flag: payload?.payload?.flag,
			},
		};
		const { data }: any = yield* call(uploadFamilyService, requestPayload);
		yield call(handleFetchUserFamilyListInformation);
		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
	} catch (e: any) {
		yield* put(setAlertInfo(e.errorDescription));
	}
}
function* handleFamilyUpload(payload: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			...payload,
		};
		const data: any = yield* call(handleImageUpload, requestPayload);
		yield* put(familyUploadSuccess(data));
	} catch (e: any) {
		yield* put(familyUploadError(e.message));
	}
}
function* handleProfileImageUpload(payload: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			...payload,
		};
		const data: any = yield* call(handleUploadImage, requestPayload);
		yield* put(uploadProfilePhotoSuccess(data));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Profile_Upload_button_click', {
				button_name: 'Profile_Upload_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}

	} catch (e: any) {
		yield* put(uploadProfilePhotoError(e.message));
		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Profile_Upload_button_click', {
				button_name: 'Profile_Upload_button',
				Select_Languages: dest,
				Status: 'Failure',
				Devices_type: 'Web',
			});
		}
	}
}
function* handleSignatureImageUpload(payload: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			...payload,
		};
		const data: any = yield* call(handleUploadImage, requestPayload);
		yield* put(uploadSignaturePhotoSuccess(data));
	} catch (e: any) {
		yield* put(uploadSignaturePhotoError(e.message));
	}
}

function* handleOthersImageUpload(payload: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			...payload,
		};
		const data: any = yield* call(handleUploadImage, requestPayload);
		yield* put(uploadOtherPhotoSuccess(data));
	} catch (e: any) {
		yield* put(uploadOtherPhotoError(e.message));
	}
}
function* watchUpload() {
	yield* takeEvery(uploadFileToServer.type, handleUploadImage);
	yield* takeEvery(fileUploadToServer.type, handleImageUpload);
	yield* takeEvery(familyUploadRequest.type, handleFamilyUpload);
	yield* takeEvery(uploadProfilePhotoRequest.type, handleProfileImageUpload);
	yield* takeEvery(
		uploadSignaturePhotoRequest.type,
		handleSignatureImageUpload
	);
	yield* takeEvery(uploadOtherPhotoRequest.type, handleOthersImageUpload);
}

export {
	watchUpload,
	uploadFileToServer,
	fileUploadToServer,
	handleUploadImage,
	handleImageUpload,
};
