import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { APP_ROUTES, AUTH_ROUTES } from '../../../../common/constants/Routes';
import '../CaseScreens.scss';

import { setUser } from '../../auth.slice';

import ARForm from '../../../App/Common/ARScreens/ARForm';
import cryptoEncryptionDecryption, { generateUUID } from '../../../../../src/common/crypto';

const AuthorizedRepresentativeScreen = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const navigation = useNavigate();

	const handleLoginFlow = () => {
		// const payload: any = {
		// 	Customer: {
		// 		eiaNo: location?.state?.userData?.eiaNo,
		// 		pan: location?.state?.userData?.pan,
		// 		mobileNo: location?.state?.userData?.mobileNo,
		// 		email: location?.state?.userData?.email,
		// 		firstTimeLoginFlag: 'N',
		// 		kycValidFlag: 'Y',
		// 		arAvailable: 'Y',
		// 		sessionId: null,
		// 	},
		// };
		//dispatch(setUser(payload.Customer as any));
		//sessionStorage.setItem('userInfo', cryptoEncryptionDecryption.Encrypt(payload));
		//navigation(APP_ROUTES.DASHBOARD);
		navigation(AUTH_ROUTES.ADD_POLICY_INSTRUCTION, { state: { isFirstTimeLogin: true, userData: location?.state?.userData } });
		// sessionStorage.setItem('sessionId',cryptoEncryptionDecryption.Encrypt(payload?.Customer?.sessionId) );
		//navigation(APP_ROUTES.DASHBOARD);
	};

	return (
		<ARForm
			handleSkip={handleLoginFlow}
			handleARFlowSuccess={handleLoginFlow}
			eiaNo={location?.state?.userData?.eiaNo}
			PermanentAddress={location?.state?.userData?.PermanentAddress}
		/>
	);
};
export default AuthorizedRepresentativeScreen;
