/* eslint-disable prefer-const */
import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
	fetchPoliciesForEmail,
	generateOtpEmail,
	updateForEmail,
	verifyOtpEmail,
} from './Emails.service';
import {
	errorLoadingPoliciesForEmails,
	loadPoliciesForEmails,
	fetchPoliciesForEmails as fetchPoliciesForEmailsAction,
	generateOtpForEmailRequest,
	generateOtpForEmailSuccess,
	generateOtpForEmailError,
	verifyEmailRequest,
	verifyEmailSuccess,
	verifyEmailError,
	updateEmailChangesSuccess,
	updateEmailChangesError,
	updateEmailChangesRequest,
	selectEmailChangesList,
} from './Emails.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { RequestTypes, maskAadhar } from '../../../common/utils';
import { setAlertInfo } from '../Common/Common.slice';
import { fetchUserProfile } from '../Profile/Profile.slice';

function* handleFetchPoliciesForEmails() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		let {
			data: { EmailBasedPolicies, UnassignedEmails },
		}: any = yield* call(fetchPoliciesForEmail, requestPayload);
		EmailBasedPolicies = EmailBasedPolicies?.map((row: any) => ({
			...row,
			email: String(row.email).trim() === '' ? 'null' : row.email,
		}));
		yield* put(loadPoliciesForEmails({ EmailBasedPolicies, UnassignedEmails }));
	} catch (e: any) {
		yield* put(errorLoadingPoliciesForEmails(e.message));
	}
}

function* handleUpdateEmail({ payload: { handleSuccess } }: any) {
	try {
		const Actions = yield* select(selectEmailChangesList);
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Actions,
			Customer: {
				eiaNo: user.eiaNo,
			},

			flag: RequestTypes.EMAIL,
		};
		const { data }: any = yield* call(updateForEmail, requestPayload);
		yield* put(updateEmailChangesSuccess(data || []));
		if (handleSuccess) {
			handleSuccess();
		}
		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		yield* put(fetchUserProfile());
	} catch (e: any) {
		yield* put(updateEmailChangesError(e.message));
	}
}

function* handleGenerateOtpForEmail({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);

		const requestPayload = {
			Customer: {
				value: payload?.email,
				flag: 'EMAIL',
				otpPurpose: 'UPDATE_EMAIL',
				eiaNo: user?.eiaNo,
			},
		};
		const requestPayload2 = {
			Customer: {
				value: payload?.data?.email,
				flag: 'EMAIL',
				otpPurpose: 'PRIMARY_EMAIL',
				eiaNo: payload?.eia,
			},
		};

		if (payload.types === 'signUpEmail') {
			const { data }: any = yield* call(generateOtpEmail, requestPayload2);
			yield* put(generateOtpForEmailSuccess(data));

			if (payload?.data?.handleSuccess) {
				payload?.data?.handleSuccess(data);
			}
		} else {
			const { data }: any = yield* call(generateOtpEmail, requestPayload);
			yield* put(generateOtpForEmailSuccess(data));
			if (handleSuccess) {
				handleSuccess(data);
			}
		}
	} catch (e: any) {
		yield* put(generateOtpForEmailError(e.message));
	}
}

function* handleVerifyEmailOtp({
	payload: { handleSuccess, ...payload },
}: any) {
	const user: any = yield* select(selectIsUserLoggedIn);

	try {
		const requestPayload = {
			Customer: {
				value: payload.email,
				otp: payload.otpCode,
				flag: 'EMAIL',
				otpPurpose: 'UPDATE_EMAIL',
			},
		};
		const requestPayload2 = {
			Customer: {
				value: payload?.data?.email,
				otp: payload?.data?.otpCode,
				flag: 'EMAIL',
				otpPurpose: 'PRIMARY_EMAIL',
				eiaNo: payload?.eiaNo,
			},
		};
		const requestPayload3 = {
			Customer: {
				value: payload?.data?.Aadhaar
					? maskAadhar(payload?.data?.Aadhaar)
					: payload?.data?.PASSPORT,
				otp: '',
				flag: payload?.data?.Aadhaar ? 'AADHAR' : 'PASSPORT',
				otpPurpose: '',
				eiaNo: user?.eiaNo,
			},
		};

		if (payload.types === 'signUpEmail') {
			const { data }: any = yield* call(verifyOtpEmail, requestPayload2);
			yield* put(verifyEmailSuccess(data));

			payload?.data?.handleSuccess(data);

			yield* put(
				setAlertInfo({
					open: true,
					type: 'SUCCESS',
					description: data.displayMessage,
				})
			);
		} else if (payload.types === 'personalDetails') {
			const { data }: any = yield* call(verifyOtpEmail, requestPayload3);
			yield* put(verifyEmailSuccess(data));
			if (payload?.data?.handleSuccess) {
				payload?.data?.handleSuccess(data);
			}
			yield* put(
				setAlertInfo({
					open: true,
					type: 'SUCCESS',
					description: data.displayMessage,
				})
			);
		} else {
			const { data }: any = yield* call(verifyOtpEmail, requestPayload);
			yield* put(verifyEmailSuccess(data));
			if (handleSuccess) {
				handleSuccess(data);
			}
			yield* put(
				setAlertInfo({
					open: true,
					type: 'SUCCESS',
					description: data.displayMessage,
				})
			);
		}
	} catch (e: any) {
		yield* put(verifyEmailError(e.message));
	}
}

function* watchEmails() {
	yield* takeEvery(
		fetchPoliciesForEmailsAction.type,
		handleFetchPoliciesForEmails
	);
	yield* takeEvery(generateOtpForEmailRequest.type, handleGenerateOtpForEmail);
	yield* takeEvery(verifyEmailRequest.type, handleVerifyEmailOtp);
	yield* takeEvery(updateEmailChangesRequest.type, handleUpdateEmail);
}

// eslint-disable-next-line import/prefer-default-export
export { watchEmails };
