import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import './profile.css';
import { useDispatch, useSelector } from 'react-redux';
import FamilyDetailCard from './FamilyDetailsCard';
import {
	addAddressRequest,
	addFamilyMemberRequest,
	emailGenerateOtpLoading,
	emailVerifyOtpLoading,
	fetchFamilyRelationShipList,
	fetchUserFamilyListAction,
	mobileGenerateOtpLoading,
	mobileVerifyOtpLoading,
	saveFamilyEmailVerificationDetails,
	saveFamilyVerificationDetails,
	// saveFamilyVerificationDetailsError,
	selectFamilyListIsLoading,
	selectUserFamilyList,
	verifyGenerateOtpForEmailRequest,
	verifyGenerateOtpForMobileRequest,
	verifyOtpEmailRequest,
	verifyOtpMobileNoRequest,
} from './Profile.slice';
import Spinner from '../../../common/ui/Spinner';
import config from '../../../common/config';
import OtpEntryModal from '../Common/OtpEntryModal';
import EmailEntryModal from '../Common/EmailEntryModal';
import MobileEntryModal from '../Common/MobileEntryModal';
import AddressEntryModal from '../AddressModule/AddressModals/AddressEntryModal';
import ApiModal from '../../../common/ui/Modal/ApiModal';
import EditMemberModal from './EditMemberModal';
import AddFamilyMemberModal from './AddFamilyMember/AddFamilyMemberModal';
import MergeMemeberModal from './AddFamilyMember/MergeMemeberModal';
import { fetchOtpRequest, selectIsUserLoggedIn } from '../../Auth/auth.slice';

const FamilyScreen = () => {
	const dispatch = useDispatch();
	const isProfileLoading = useSelector(selectFamilyListIsLoading);
	const familyList = useSelector(selectUserFamilyList);
	const isLoadingEmail = useSelector(emailGenerateOtpLoading);
	const isVerifyEmailLoading = useSelector(emailVerifyOtpLoading);
	const isLoadingMobile = useSelector(mobileGenerateOtpLoading);
	const isVerifyMobileLoading = useSelector(mobileVerifyOtpLoading);

	const [showAddModal, setShowAddModal] = React.useState(false);
	const [showAddEmailModal, setShowAddEmailModal] = React.useState(false);
	const [showOtpModal, setShowOtpModal] = React.useState(false);
	const [verifyMobileDuration, setVerifyMobileDuration] = useState(0);
	const [verifyEmailDuration, setVerifyEmailDuration] = useState(0);
	const [emailshowOtpModal, setEmailShowOtpModal] = React.useState(false);
	const [showAddAddressModal, setShowAddAddressModal] = React.useState(false);
	const [addressData, setAddressData] = React.useState({});
	const [selectAddressSeqId, setSelectAddressSeqId] = React.useState({});
	const [editMember, setEditMember] = useState(false);
	const [view, setView] = useState('');

	const [currentMember, setCurrentMember] = useState<any>({});
	const [currentMergeMember, setCurrentMergeMember] = useState<any>({});
	const [mobile, setMobile] = useState<any>({});
	const [emailId, setEmailId] = useState<any>({});
	const [mobileData, setMobileData] = useState<any>({});
	const [emailData, setEmailData] = useState<any>({});
	const [tapAddress, setTapAddress] = useState<any>({});
	const [modal, setModal] = useState(false);
	const [mergeModal, setMergeModal] = useState(false);
	const userInfo = useSelector(selectIsUserLoggedIn);
	// const saveVerificationError = useSelector(saveFamilyVerificationDetailsError);
	// const mobileModal =
	// 	saveVerificationError.payload.profile.familyVerification.status;
	// const emailModal =
	// 	saveVerificationError.payload.profile.familyEmailVerification.status;

	useEffect(() => {
		if (!familyList.length) {
			dispatch(fetchUserFamilyListAction());
		}
		dispatch(fetchFamilyRelationShipList());
	}, [dispatch]);
	// useEffect(() => {
	// 	if (mobileModal === 'error') {
	// 		setShowOtpModal(true);
	// 	}
	// }, [mobileModal === 'error']);
	// useEffect(() => {
	// 	if (emailModal === 'error') {
	// 		setEmailShowOtpModal(true);
	// 	}
	// }, [emailModal === 'error']);
	const onAddMobileNo = (data: any) => {
		// setShowAddModal(true);
		// setMobileData(data);
		editFamily(data);
	};
	const onAddEmail = (data: any) => {
		// setShowAddEmailModal(true);
		// setEmailData(data);
		editFamily(data);
	};
	const onAddNewAddress = (data: any) => {
		// setTapAddress(data);
		// setShowAddAddressModal(true);
		// setSelectAddressSeqId(data?.seqId);
		editFamily(data);
	};
	const closeAllModals = () => {
		setShowAddModal(false);
		setShowAddEmailModal(false);
		setShowOtpModal(false);
		setShowAddAddressModal(false);
		setEmailShowOtpModal(false);
		setShowOtpModal(false);
	};
	const handleEmailResendotp = () => {
		setVerifyEmailDuration(config.otpDuration);	
		const handleSuccess = () => {
			setVerifyEmailDuration(config.otpDuration);
		};
		const data: any = {
			eiaNo: userInfo?.eiaNo,
			value: emailId,
			otpPurpose: 'ADD_EMAIL',
			flag: 'EMAIL',
		};
		dispatch(
			fetchOtpRequest({
				Customer: data,
				handleSuccess,
			} as any)
		);
	
	};

	const addMemberMissing = (data: any) => {
		setCurrentMember({ ...currentMember, relationName: data.relationship, relationCode: data.relationCode })
		setModal(false);
		//dispact addmember
		const handleSuccess = () => {
			dispatch(fetchUserFamilyListAction());
		}

		const payload = {
			FamilyMember: {
				flag: "CHANGE",
				eiaNoFamily: userInfo?.eiaNo,
				relationCode: data.relationCode,
				nomineeRelation: data.relationCode,
				firstName: currentMember?.firstName,
				middleName: currentMember.middleName ? currentMember.middleName : '',
				lastName: currentMember.lastName ? currentMember.lastName : '',
				imageBase64: currentMember.imageBase64 || '',
				email: currentMember.email,
				mobCountryCode: currentMember.mobileCountryCode,
				mobNumber: currentMember.mobileNumber || '',
				mobNoVerified: currentMember.mobNoVerified || '',
				dob: currentMember?.dob,
				address1: currentMember.address1,
				address2: currentMember.address2,
				address3: currentMember.address3,
				city: currentMember.city,
				state: currentMember.state,
				country: currentMember.country,
				pin: currentMember.pin,
				authorizedPerson: currentMember?.authorizedPerson || '',
				addressSameAsLifeAssured: currentMember.addressSameAsLifeAssured || '',
				seqId: currentMember.seqId,
				alternateCode: currentMember.alternateCode,
				familyFlag: currentMember?.flag || '',
				family: currentMember?.familyFlag || '',
				nominee: currentMember?.nomineeFlag || '',
				nomineeRef: currentMember?.nomineeRef || '',
				nomineeName: currentMember?.firstName || '' + " " + currentMember?.middleName || '' + " " + currentMember?.lastName || '',
				nomineeDob: currentMember?.dob,

			},
			handleSuccess
		};


		dispatch(addFamilyMemberRequest(payload as any));
	}

	const mergeMemeber = (data: any) => {
		setModal(false);
		setCurrentMember({ ...currentMember, originalRelationCode: currentMember.relationCode === '' ? '-1' : currentMember.relationCode, relationName: data.relationship, relationCode: data.relationCode })
		setEditMember(true);
	}

	const mergeInfo = (data: any, views: string, mergedata: any) => {
		setMergeModal(true);
		setModal(false);
		// debugger;
		setView(views)
		console.log(data)
		console.log(mergedata);
		console.log(currentMember)
		//setCurrentMember({ ...data, originalDob: currentMember.dob, originalName: ((currentMember?.firstName || '').concat("", (currentMember?.middleName || '')).concat("", (currentMember?.lastName || ''))).replaceAll(" ", '') })
		if (views == 'sisLogic') {
			setCurrentMergeMember({ ...currentMember, ...mergedata, ...data, originalDob: currentMember.dob, originalName: ((currentMember?.firstName || '').concat("", (currentMember?.middleName || '')).concat("", (currentMember?.lastName || ''))).replaceAll(" ", '') })
			// setCurrentMember([])
		} else {
			setCurrentMember({ ...currentMember, ...data, originalDob: currentMember.dob, originalName: ((currentMember?.firstName || '').concat("", (currentMember?.middleName || '')).concat("", (currentMember?.lastName || ''))).replaceAll(" ", '') })
			// setCurrentMergeMember([])
		}
	}

	const handleResendOtp = () => {
		const datas: any = {
			mobileNo: mobile,
			Type: 'FAMILY',
		};

		setVerifyMobileDuration(config.otpDuration);
		const handleSuccess = () => {
			setVerifyMobileDuration(config.otpDuration);
		};
		const data: any = {
			eiaNo: userInfo?.eiaNo,
			value: mobile,
			otpPurpose: 'ADD_MOBILE',
			flag: 'MOBILE',
		};
		dispatch(
			fetchOtpRequest({
				Customer: data,
				handleSuccess,
			} as any)
		);
	};
	const verifyMobileNo = (data: any) => {
		setMobileData(data);
		if (data) {
			const datas: any = {
				mobileNo: data?.mobNumber,
				Type: 'FAMILY',
			};
			const mobileNo = data?.mobNumber;
			dispatch(verifyGenerateOtpForMobileRequest(datas));
			setMobile(mobileNo);
			setEmailId('');
			setShowOtpModal(true);
			setVerifyMobileDuration(config.otpDuration);
		}
	};
	const verifyEmail = (data: any) => {
		setEmailData(data);

		if (data) {
			const datas: any = {
				email: data?.email,
				Type: 'FAMILY',
			};
			const emailId = data?.email;
			dispatch(verifyGenerateOtpForEmailRequest(datas));
			setEmailId(emailId);
			setMobile('');
			setEmailShowOtpModal(true);
			setVerifyEmailDuration(config.otpDuration);
		}
	};

	const handleSubmitAddress = (data: any) => {
		data.flag = tapAddress.flag;
		data.seqId = tapAddress.seqId;
		data.country = 'India';
		dispatch(addAddressRequest(data as any));
		if (data) {
			setShowAddAddressModal(false);
			setMobileData({});
			setEmailData({});
			setAddressData(data);
		}
	};

	const handleSubmitMobile = (datas: any) => {
		setShowOtpModal(false);
		if (datas) {
			setMobile(datas?.mobileNo);
			const data: any = {
				mobileNo: datas?.mobileNo,
				email: '',
				Type: 'FAMILY',
			};
			data.handleSuccess = () => {
				setShowAddModal(false);
				setShowOtpModal(true);
				setVerifyMobileDuration(config.otpDuration);
			};
			dispatch(verifyGenerateOtpForMobileRequest(data));
		}
	};
	const handleSubmitEmail = (datas: any) => {
		setEmailShowOtpModal(false);

		if (datas) {
			setEmailId(datas?.email);
			const data: any = {
				email: datas?.email,
				mobileNo: '',
				Type: 'FAMILY',
			};
			data.handleSuccess = () => {
				setShowAddEmailModal(false);
				setEmailShowOtpModal(true);
				setVerifyEmailDuration(config.otpDuration);
			};
			dispatch(verifyGenerateOtpForEmailRequest(data));
		}
	};

	// const handleMobileOtpVerify = (otpCode: any) => {
	// 	const data: any = {
	// 		otpCode,
	// 		mobileNo: mobile,
	// 		seqId: mobileData?.seqId,
	// 		mblNominee:
	// 			mobileData?.flag === 'NOMINEE'
	// 				? 'Y'
	// 				: mobileData?.flag === 'FAMILY'
	// 				? 'N'
	// 				: 'N',
	// 	};
	// 	data.handleSuccess = () => {
	// 		setShowOtpModal(false);
	// 		dispatch(saveFamilyVerificationDetails(data));
	// 	};
	// 	dispatch(verifyOtpMobileNoRequest(data));
	// };
	const handleMobileOtpVerify = (otpCode: any) => {
		const data: any = {
			otpCode,
			mobileNo: mobile,
			seqId: mobileData?.seqId,
			mblNominee: 'N',
			familyFlag: mobileData?.flag,
			authorizedPerson: mobileData?.authorizedPerson
		};

		if (mobileData?.flag === 'NOMINEE') {
			data.mblNominee = 'Y';
		} else if (mobileData?.flag === 'FAMILY') {
			data.mblNominee = 'N';
		}

		data.handleSuccess = () => {
			setShowOtpModal(false);
			dispatch(saveFamilyVerificationDetails(data));
		};

		dispatch(verifyOtpMobileNoRequest(data));
	};

	// const handleSaveVerificationEmail = () => {
	// 	const data: any = {
	// 		eiaNo: user?.eiaNo,
	// 		value: emailId,
	// 		seqId: emailData?.seqId,
	// 		flag: 'EMAIL',
	// 	};
	// 	data.handleSuccess = () => {
	// 		setEmailShowOtpModal(false);
	// 	};
	// 	dispatch(saveFamilyEmailVerificationDetails(data));
	// };
	const handleEmailOtpVerify = (otpCode: any) => {
		const data: any = {
			otpCode,
			email: emailId,
			seqId: emailData?.seqId,
			emailNominee: 'N',
			familyFlag: emailData.flag,
			authorizedPerson: emailData?.authorizedPerson
		};

		if (emailData?.flag === 'NOMINEE') {
			data.emailNominee = 'Y';
		} else if (emailData?.flag === 'FAMILY') {
			data.emailNominee = 'N';
		}

		data.handleSuccess = () => {
			// setShowOtpModal(false);
			// setEmailShowOtpModal(false);
			dispatch(saveFamilyEmailVerificationDetails(data));
			setEmailShowOtpModal(false);
		};

		dispatch(verifyOtpEmailRequest(data));
	};


	const editFamily = (data: any) => {
		setEditMember(true);
		setCurrentMember({ ...data, originalRelationCode: data.relationCode === '' ? '-1' : data.relationCode })
	}

	return (
		<>
			{isProfileLoading ? (
				<Grid item>
					<Spinner />
				</Grid>
			) : (
				<Grid container spacing={2}>
					{familyList.map((data, index) => (
						<Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
							<FamilyDetailCard
								profileData={data}
								profile={data}
								addNewMobile={() => onAddMobileNo(data)}
								addNewEmail={() => onAddEmail(data)}
								onVerifyEmail={() => verifyEmail(data)}
								onVerifyMobile={() => verifyMobileNo(data)}
								addNewAddress={() => onAddNewAddress(data)}
								addressData={addressData}
								selectAddressSeqId={selectAddressSeqId}
								editMember={() => editFamily(data)}
								selectRelationship={() => { setModal(true); setCurrentMember(data) }}
							/>
						</Grid>
					))}
				</Grid>
			)}

			<EmailEntryModal
				open={showAddEmailModal}
				setOpen={setShowAddEmailModal}
				onSubmit={handleSubmitEmail}
				loading={isLoadingEmail}
				value={emailId.email}
				handleCancel={closeAllModals}
			/>
			<MobileEntryModal
				open={showAddModal}
				setOpen={setShowAddModal}
				onSubmit={handleSubmitMobile}
				handleCancel={closeAllModals}
				loading={isLoadingMobile}
			/>
			<AddressEntryModal
				show={showAddAddressModal}
				setShow={setShowAddAddressModal}
				onSubmit={handleSubmitAddress}
				handleCancel={closeAllModals}
			/>
			<OtpEntryModal
				open={emailshowOtpModal}
				setOpen={setEmailShowOtpModal}
				loading={isVerifyEmailLoading}
				isEmail
				value={emailId}
				onSubmit={handleEmailOtpVerify}
				handleCancel={closeAllModals}
				onFinishDuration={() => setVerifyEmailDuration(0)}
				duration={verifyEmailDuration}
				handleResendOtp={handleEmailResendotp}
			/>
			<OtpEntryModal
				open={showOtpModal}
				setOpen={setShowOtpModal}
				loading={isVerifyMobileLoading}
				isEmail={false}
				value={mobile}
				duration={verifyMobileDuration}
				onFinishDuration={() => setVerifyMobileDuration(0)}
				onSubmit={handleMobileOtpVerify}
				handleCancel={closeAllModals}
				handleResendOtp={handleResendOtp}
			/>
			<ApiModal />
			<EditMemberModal open={editMember} setOpen={setEditMember} memberData={currentMember} familyListData={familyList} mergeInfo={mergeInfo} />
			<AddFamilyMemberModal open={modal} setOpen={setModal} isNoFilter={true} editMember={addMemberMissing} mergeModal={mergeMemeber} />
			<Grid>
				{view === 'fatherLogic' ?
					<MergeMemeberModal open={mergeModal} setOpen={setMergeModal} memberData={currentMember} view={view} />
					:
					<MergeMemeberModal open={mergeModal} setOpen={setMergeModal} memberData={currentMergeMember} view={view} />}
			</Grid>


		</>
	);
};
export default FamilyScreen;
