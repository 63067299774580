import { Grid, Typography } from "@mui/material"
import CommonModal from "../../../common/ui/Modal/CommonModal"
import { useTranslation } from "react-i18next";
import theme from "../../../common/theme";
import CustomButton from "../../../common/ui/CustomButton";
import { PUBLIC_ROUTES } from "../../../common/constants/Routes";
import { Link } from "react-router-dom";

export const TermsCondition = ({ open, setOpen, setIsChecked }: any) => {
    const { t } = useTranslation();
    return (<CommonModal
        onClose={() => setOpen(false)}
        open={open}
        modalClassName="px-20 py-20"
        boxProps={{ width: 315 }}

    >

        <Grid container >
            <Typography
                className="f-16 fw-600"

            >
                {t('PLEASE_ACCEPT_THE')}&nbsp;
            </Typography>
            <Link to={PUBLIC_ROUTES.PRIVACYSCREEN} target="_blank">
                <Typography
                    className="f-16 fw-600"
                    style={{ color: '#0000FF' }}
                >
                    {t('PRIVACY_POLICY_SMALL')}&nbsp;
                </Typography>
            </Link>
            <Typography
                className="f-16 fw-600"
            >
                {t('AND')} &nbsp;
            </Typography>
            <Link to={PUBLIC_ROUTES.DISCLAIMER} target="_blank">
                <Typography
                    className="f-16 fw-600"
                    style={{ color: '#0000FF' }}
                >
                    {t('TERMS_OF_USE_SMALL')}&nbsp;
                </Typography>
            </Link>
            <Typography
                className="f-16 fw-600"
            >
                {t('TO_PROCEED_AHEAD')} &nbsp;
            </Typography>
        </Grid>
        <Grid
            className="pt-30 pb-20"
            container
            xs={12}
            justifyContent={'space-between'}
        >
            <Grid item>
                <CustomButton
                    text={t('DECLINE')}
                    variant="text"
                    color="inherit"
                    showIcon={false}
                    className="f-16 fw-400"
                    onClick={() => { setIsChecked(false); setOpen(false) }}
                />
            </Grid>
            <Grid item>
                <CustomButton
                    text={t('ACCEPT')}
                    variant="text"
                    type="submit"
                    color="primary"
                    className="f-16 fw-600"
                    onClick={() => { setIsChecked(true); setOpen(false) }}
                />
            </Grid>
        </Grid>


    </CommonModal>)
}
