import { axiosInstance } from '../../../common/axiosInstance';
import { DashboardRoutes, SupportRoutes } from '../../../common/routes';

const axios = axiosInstance;

const fetchDashboardPoliciesList = (data: any) =>
	axios.request({ ...DashboardRoutes.policiesList, data });
const fetchSupportOptionsList = (data: any) =>
	axios.request({ ...SupportRoutes.supportOptionsList, data });

const submitSupportQuery = (data: any) =>
	axios.request({ ...SupportRoutes.raiseNewSupport, data });
const fetchMissingInfoList = (data: any) =>
	axios.request({ ...DashboardRoutes.missingInfo, data });
const fetchPolciyRejection = (data: any) =>
	axios.request({ ...DashboardRoutes.policyRejection, data });
// eslint-disable-next-line import/prefer-default-export
export {
	fetchDashboardPoliciesList,
	fetchSupportOptionsList,
	submitSupportQuery,
	fetchMissingInfoList,
	fetchPolciyRejection
};
