import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import './Bank.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import BankHeader from './BankHeader';
import ManageBankHeader from './ManageBankHeader';
import {
	changeBank,
	clearBankEntries,
	fetchPoliciesForBanks,
	getBankDetailsByIfscRequest,
	handleInstantBankFlow,
	removeBank,
	selectBankChangesList,
	selectGetBankDetails,
	selectIsPolicyBanksLoading,
	selectUploadCanceledChequeDetails,
	selectUploadCanceledChequeLoading,
	selectUploadCanceledChequePayloadDetails,
	uploadCanceledChequeRequest,
	removeBank as removeBankPolicies,
	updateBankChangesRequest,
	setBankChangesList,
	skipBank,
	selectUpdateBankChangesLoading,
	selectGetBankDetailsLoading,
	selectUnVerifiedBanks,
	selectUnassignedBanks,
	getUniqueBanks,
} from './Bank.slice';
import {
	CHEQUE_VERIFICATION_TYPES,
	SELECT_POLICY_TYPES_FOR_MODULES,
} from '../../../common/Enums';
import { APP_ROUTES } from '../../../common/constants/Routes';
import Spinner from '../../../common/ui/Spinner';
import ChangeBankModalDetails from './BankModals/ChangeBankModalDetails';
import RemoveBankModalDetails from './BankModals/RemoveBankModalDetails';
import BankEntryModal from './BankModals/BankEntryModal';
import UploadImageBankModal from './BankModals/UploadImageBankModal';
import UploadModal from './BankModals/UploadModal';
import DoneModal from '../Common/DoneModal';
import { setAlertInfo } from '../Common/Common.slice';
import ApiModal from '../../../common/ui/Modal/ApiModal';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';


const ManageBankScreen = () => {
	const unAssignedBanks = useSelector(selectUnassignedBanks);
	const unVerifiedBanks = useSelector(selectUnVerifiedBanks);
	const bankList = useSelector(getUniqueBanks);

	const { t } = useTranslation();
	const Location = useLocation();
	const [addAccount, setAddAccount] = useState(false);
	const [enableSkipbutton, setEnableSkipbutton] = useState(false);
	useEffect(() => {
		if (Location.state === 'profile') {
			setAddAccount(true);
			setEnableSkipbutton(true);
		} else {
			setAddAccount(false);
			setEnableSkipbutton(false);
		}
	}, [Location.state]);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [doneModal, setDoneModal] = useState(false);
	const [removeBankModal, setRemoveBankModal] = useState(false);
	const [changeBankModal, setChangeBankModal] = useState(false);
	const [purpose, setPurpose] = useState(false);

	const [verifyNow, setVerifyNow] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState('');
	const [selectedPolicy, setSelectedPolicy] = useState(null);
	const [newAccountData, setNewAccountData] = useState<any>(null);
	const [imagePickerModalVisible, setImagePickerModalVisible] = useState(false);
	// const [oldBankData, setOldBankData] = useState(false);
	const [instantAddAccountData, setInstantAddAccountData] = useState(false);

	const isPoliciesForBanksLoading = useSelector(selectIsPolicyBanksLoading);
	const isUploadCheckLoadingLoading = useSelector(
		selectUploadCanceledChequeLoading
	);
	const bankDetailsByIfsc = useSelector(selectGetBankDetails);
	const bankChangesList = useSelector(selectBankChangesList);
	const bankOCRDetails: any = useSelector(selectUploadCanceledChequeDetails);
	const uploadChequePayload = useSelector(
		selectUploadCanceledChequePayloadDetails
	);
	const isGetBankDetailsLoading = useSelector(selectGetBankDetailsLoading);
	useEffect(() => {
		if (bankChangesList?.length === 0) dispatch(fetchPoliciesForBanks());
	}, [dispatch, bankChangesList]);

	const handleRemoveBank = (acNo: string, type: any) => {
		if (type) {
			dispatch(removeBank({ targetBankAccountNo: acNo, type }));
		} else {
			setSelectedAccount(acNo);
			setRemoveBankModal(true);
		}
	};

	const handleUploadModal = (chequeImage: any) => {
		const payload: any = {
			payeeAccNo: newAccountData?.accountNumber,
			payeeAccName: newAccountData?.accountHolderName,
			payeeIfscCode: bankDetailsByIfsc?.IFSC,
			chequeImage,
		};

		payload.handleSuccess = (responseData: any) => {
			if (
				responseData?.uploadStatus === CHEQUE_VERIFICATION_TYPES.CHEQUE_VERIFIED
			) {
				if (instantAddAccountData) {
					dispatch(handleInstantBankFlow(instantAddAccountData));
					setVerifyNow(false);
				}
			} else if (
				responseData?.uploadStatus ===
				CHEQUE_VERIFICATION_TYPES.CHEQUE_MANUAL_VERIFICATION
			) {
				setVerifyNow(false);
				dispatch(
					setAlertInfo({
						open: true,
						type: 'FAILED',
						description: responseData?.displayMessage
							? responseData?.displayMessage
							: 'Problem with network,  Please try Again later.',
					})
				);
				const skipPayload: any = {
					isVerified: false,
					bankDetailsByIfsc,
					uploadChequePayload,
					bankOCRDetails,
					newAccountData,
					skipApiChanges: true,
				};
				dispatch(skipBank(skipPayload));
			}
			setImagePickerModalVisible(false);
		};
		dispatch(uploadCanceledChequeRequest(payload));
	};

	const handleChangeBank =
		(acNo: string, policy: any, purposeFromPolicies: any) => () => {
			setSelectedAccount(acNo);
			setSelectedPolicy(policy);
			setChangeBankModal(true);
			purposeFromPolicies ? setPurpose(true) : setPurpose(false);
		};

	const addBankAccount = (primaryValue: string, isChange: any) => {
		setInstantAddAccountData(false);
		if (primaryValue) {
			setInstantAddAccountData({
				[isChange ? 'selectedPolicyNo' : 'selectedBankAcNo']: primaryValue,
				isChange: Boolean(isChange),
			} as any);
		}

		dispatch(clearBankEntries);
		setAddAccount(true);
		setEnableSkipbutton(true);
		setRemoveBankModal(false);
		setChangeBankModal(false);
	};
	const handleSearch = (data: any) => {
		const accNoExistsInUnverifed = unVerifiedBanks.some(
			(bank) => bank?.accountNo === data?.accountNumber
		);
		const accNoExistsInUnassigned = unAssignedBanks.some(
			(bank) => bank?.accountNo === data?.accountNumber
		);
		const accNoExistsInassigned = bankList.some(
			(bank) => bank?.bankAcNo === data?.accountNumber
		);
		if (
			accNoExistsInUnverifed ||
			accNoExistsInUnassigned ||
			accNoExistsInassigned
		) {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: 'Bank details already exist.',
				})
			);
		} else {
			const payload: any = { ifsc: data?.ifscCode, t };

			payload.handleSuccess = () => {
				setNewAccountData(data);
				setAddAccount(false);
				setEnableSkipbutton(true);
				setVerifyNow(true);
			};
			dispatch(getBankDetailsByIfscRequest(payload));
		}
	};

	const onChangePolicy = (targetBank: any) => {
		dispatch(
			changeBank({
				selectedPolicy,
				targetBank,
				purpose: purpose ? 'ASSIGN_POLICIES' : '',
			})
		);
		setChangeBankModal(false);
		setSelectedAccount('');
		setSelectedPolicy(null);
	};

	const onRemovePolicy = (selectedValue: any) => {
		dispatch(
			removeBankPolicies({
				targetBankAccountNo: selectedValue,
				oldBankAccNo: selectedAccount,
			})
		);
		setRemoveBankModal(false);
		setSelectedAccount('');
		setSelectedPolicy(null);
	};

	const handleBankChangesSubmit = () => {
		const handleDone: any = () => {
			setDoneModal(false);
			navigate(-1);
		};
		dispatch(updateBankChangesRequest({ handleDone, t } as any));
	};

	const handleCancel = () => {
		navigate(-1);
		dispatch(setBankChangesList([]));
	};

	const isUpdateBankLoading = useSelector(selectUpdateBankChangesLoading);

	const handleOnclickDone = () => {
		if (bankChangesList?.length > 0) {
			setDoneModal(true);
		} else {
			navigate(-1);
		}
		return true;
	};

	const handleAssignPolicies = () => {
		setVerifyNow(false);
		navigate(APP_ROUTES.ASSIGN_POLICY, {
			state: {
				bankOCRDetails: {
					OCR: bankOCRDetails?.OCR,
					PennyDrop: bankOCRDetails?.PennyDrop,
					NameMatch: bankOCRDetails?.NameMatch,
				},
				MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.BANK,
			},
		});
	};

	const handleSkip = (isVerified: boolean) => {
		const payload: any = {
			isVerified,
			bankDetailsByIfsc,
			uploadChequePayload,
			bankOCRDetails,
			newAccountData,
		};
		payload.handleDone = () => {
			setVerifyNow(false);
		};
		dispatch(skipBank(payload));
	};

	const handleVerifyNow = (bankInfo: any) => {
		const payload: any = { ifsc: bankInfo?.ifscCode || bankInfo?.bankIfscCode, t };
		payload.handleSuccess = () => {
			setNewAccountData({
				accountHolderName: bankInfo?.accountHolderName,
				accountNumber: bankInfo?.accountNo,
				ifscCode: bankInfo?.ifscCode || bankInfo?.bankIfscCode,
				retypeAccountNo: bankInfo?.accountNo,
			} as any);
			setAddAccount(false);
			setEnableSkipbutton(false);
			// setOldBankData({ bankInfo, type } as any);
		};
		dispatch(getBankDetailsByIfscRequest(payload));
		setVerifyNow(true);
	};

	const handleAssignPoliciesForUnassignedBanks = (bank: any) => {
		navigate(APP_ROUTES.ASSIGN_POLICY, {
			state: {
				bankDetails: bank,
				isUnassignedBank: true,
				MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.BANK,
			},
		});
	};

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Manage Bank  Screen',
		});
	}, []);
	const handleAssignPoliciesForAssignedBanks = (bank: any) => {
		navigate(APP_ROUTES.ASSIGN_POLICY, {
			state: {
				bankDetails: bank,
				isAssignedBank: true,
				MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.BANK,
			},
		});
	};


	return (
		<>
			<Grid item className="ml-50 mr-50 bank-style">
				<Grid item xs={12} className="fixed-policy-header">
					<BankHeader
						onAddBankClick={addBankAccount}
						onClickDone={handleOnclickDone}
					/>
				</Grid>
				<div
					style={{
						marginTop: 75,
					}}
				/>
				{isPoliciesForBanksLoading ? (
					<Grid container justifyContent="center">
						<Grid item>
							<Spinner />
						</Grid>
					</Grid>
				) : (
					<ManageBankHeader
						handleChangeBank={handleChangeBank}
						handleRemoveBank={handleRemoveBank}
						handleVerifyNow={handleVerifyNow}
						handleAssignPolicies={handleAssignPoliciesForUnassignedBanks}
						handleAssignPoliciesForAssignedBanks={handleAssignPoliciesForAssignedBanks}
					/>
				)}
			</Grid>
			<ChangeBankModalDetails
				open={changeBankModal}
				setOpen={setChangeBankModal}
				onSubmit={onChangePolicy}
				addBankAccount={addBankAccount}
				selectedAccount={selectedAccount}
				selectedPolicy={selectedPolicy}
			/>
			<RemoveBankModalDetails
				open={removeBankModal}
				setOpen={setRemoveBankModal}
				onSubmit={onRemovePolicy}
				addBankAccount={addBankAccount}
				selectedAccount={selectedAccount}
			/>
			<BankEntryModal
				open={addAccount}
				setOpen={setAddAccount}
				onSubmit={handleSearch}
				loading={isGetBankDetailsLoading}
			/>
			{bankDetailsByIfsc !== false && bankDetailsByIfsc !== undefined && (
				<UploadImageBankModal
					skipforNowButton={enableSkipbutton}
					open={verifyNow}
					setOpen={setVerifyNow}
					bankDetails={bankDetailsByIfsc}
					accountDetails={newAccountData}
					handleUploadModal={handleUploadModal}
					uploadChequeIsDone={Boolean(uploadChequePayload?.chequeImage)}
					uploadChequeData={uploadChequePayload}
					handleSkip={handleSkip}
					imagePickerModalVisible={imagePickerModalVisible}
					setImagePickerModalVisible={setImagePickerModalVisible}
					handleAssignPolicies={handleAssignPolicies}
				/>
			)}

			<UploadModal open={isUploadCheckLoadingLoading} />
			<DoneModal
				open={doneModal}
				setOpen={setDoneModal}
				onSubmit={handleBankChangesSubmit}
				loading={isUpdateBankLoading}
				onCancel={handleCancel}
				contentType="BANK"
			/>
			<ApiModal />
		</>
	);
};

export default ManageBankScreen;
