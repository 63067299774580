/* eslint-disable no-constant-condition */
/* eslint-disable react/require-default-props */
import { Divider, useTheme } from '@mui/material';
import React from 'react';

const Separator = ({
	color,
	borderWidth = 1,
	orientation = 'horizontal',
	className,
}: {
	color?: string | undefined;
	borderWidth?: number | undefined;
	orientation?: 'horizontal' | 'vertical';
	className?: string | undefined;
}) => {
	const theme = useTheme();
	return (
		<Divider
			orientation={orientation}
			className={`${
				orientation === 'horizontal' || 'vertical' ? 'w-100' : 'h-100'
			} m-0 ${className}`}
			sx={{
				border: 0,
				...(orientation === 'horizontal'
					? {
							borderColor: color || theme.palette.common.black,
							borderBottomWidth: borderWidth,
					  }
					: {
							borderColor: color || theme.palette.common.black,
							borderRightWidth: borderWidth,
					  }),
			}}
		/>
	);
};

export default Separator;
