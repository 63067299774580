import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/ui/CustomButton';
import Separator from '../../../common/ui/Separator';
import { ReactComponent as rightArrow } from '../../../common/icons/cancel-icon.svg';
import theme from '../../../common/theme';
import { ReactComponent as GreenTick } from '../../../common/icons/Big-Green-Tick.svg';
import { ReactComponent as Circle } from '../../../common/icons/background-circle.svg';
import SvgConverter from '../../../common/SvgConverter';
import './support.scss';
import { APP_ROUTES } from '../../../common/constants/Routes';

const SupportSubmittedScreen = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const submitData = useMemo(() => location.state.data ?? {}, [location.state]);

	const supportType = location.state?.supportType;

	return (
		<Box marginBottom={5}>
			<Grid>
				<Grid container justifyContent="space-between" className="px-5">
					<Grid item>
						<Typography className="f-16 fw-600 " color="primary">
							{supportType === 'Support' ? t('SUPPORT') : t('COMPLAINTS')}
							{/* {t('SUPPORT')} */}
						</Typography>
					</Grid>
					<Grid item className="">
						<CustomButton
							text={t('CLOSE')}
							variant="text"
							color="primary"
							showIcon
							fontSize={14}
							onClick={() => navigate(APP_ROUTES.DASHBOARD, { replace: true })}
							endIcon={rightArrow}
							className="text_case fw-400"
						/>
					</Grid>
				</Grid>
				<Grid item className="mt-20">
					<Separator color="#d7d7d7" borderWidth={1} />
				</Grid>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<Grid item xs={3} width={279}>
						<Grid container justifyContent="center" alignItems="center">
							<Grid item xs={12} className="payment-card-success mt-30">
								<Grid container justifyContent="center">
									<Grid item style={{ position: 'relative' }}>
										<SvgConverter Icon={GreenTick} className="green-tick" />
										<SvgConverter Icon={Circle} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
							justifyContent="center"
							textAlign="center"
							className="mt-10"
						>
							<Grid item>
								<Typography fontSize={18} fontWeight={400} color="#63a103">
									{supportType === 'Support'
										? t('SUPPORT_REQUEST_SUBMITTED')
										: t('COMPLAINTS_REQUEST_SUBMITTED')}
									{/* {t('SUPPORT_REQUEST_SUBMITTED')}{' '} */}
								</Typography>
								<Typography
									fontSize={14}
									color={theme.palette.common.black}
									className="mt-25"
								>
									{t('REQUEST_ID')}
								</Typography>{' '}
								<Typography
									fontSize={14}
									fontWeight={600}
									color={theme.palette.common.black}
									className="mb-5"
								>
									{submitData?.requestId}
								</Typography>
								<Typography
									fontSize={16}
									color={theme.palette.primary.dark}
									className="mt-5 mb-40"
								>
									{t('OUR_TEAM_WILL_GET_BACK')}&nbsp;
									{submitData?.tatHours} {t('BUSINESS_HOURS')}.
								</Typography>
								<Grid item className="mb-10">
									<Separator color=" #d7d7d7" />
								</Grid>
								<CustomButton
									className="fw-600 f-16 mb-30"
									text={t('RAISE_ANOTHER_REQUEST')}
									variant="text"
									color="primary"
									onClick={() => navigate(-1)}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							justifyContent="left"
							textAlign="left"
							className="mt-40"
						>
							{/* <Grid item>
								<Typography fontSize={12} color={theme.palette.primary.main}>
									Terms & Conditions
								</Typography>
								<Typography fontSize={12} fontWeight={500} color="#7f7f7f">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
									lacus accumsan et viverra justo commodo. Proin sodales
									pulvinar sic tempor. Sociis natoque penatibus et magnis dis
									parturient montes, nascetur ridiculus mus.
								</Typography>
							</Grid> */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SupportSubmittedScreen;
