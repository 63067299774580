import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import theme from '../../../../../common/theme';
import { APP_ROUTES } from '../../../../../common/constants/Routes';
import CustomButton from '../../../../../common/ui/CustomButton';
import TransactionCard from './TransactionCard';
import { getFormatDate } from '../../../../../common/utils';
import { useDispatch } from 'react-redux';
import { saveSelectedCombinationRequest } from '../../Loan.slice';
import { useNavigate } from 'react-router-dom';

interface TrackTransactionLayouts {
	trackTransaction?: any;
	bankChange: any;
	sessionDate: any;
}
const TrackTransactionLayout = ({
	trackTransaction,
	bankChange,
	sessionDate,
}: TrackTransactionLayouts) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const removeLocalStorageValues = () => {
		if (bankChange) {
			const payload: any = {
				modifiedData: trackTransaction?.Combination,
				requiredAmount: trackTransaction?.Combination?.requiredAmount,
				requiredDate: trackTransaction?.Combination?.requiredDate,
				redirectUrl: ``,
				dashboardSessionFlag: 'Y',
			};
			dispatch(saveSelectedCombinationRequest(payload));
			// if (!loading) {
			// 	navigate(APP_ROUTES.DASHBOARD);
			// }
		} else {
			navigate(APP_ROUTES.DASHBOARD);
		}
		const keysToRemove = [
			'lenderdata',
			'sessionId',
			'currentIndex',
			'esignLink',
			'sessionDate',
		];
		keysToRemove.forEach((key: any) => {
			localStorage.removeItem(key);
		});
	};
	return (
		<Grid container item display="flex" justifyContent="center">
			<Grid item xs={12}>
				<Grid
					container
					item
					xs={12}
					justifyContent="space-between"
					alignItems="center"
					className="mb-20"
				>
					<Grid item>
						<Typography className="f-16 fw-600 " color="primary">
							{t('NEED_MONEY')}
						</Typography>
					</Grid>
					<Grid item>
						{/* <Link to={APP_ROUTES.DASHBOARD}> */}
						<CustomButton
							className="f-16  "
							text={t('CLOSE')}
							variant="text"
							color="primary"
							type="submit"
							onClick={() => {
								removeLocalStorageValues();
							}}
						/>
						{/* </Link> */}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography className="f-13 fw-600 " color={theme.palette.common.black}>
					{t('TRACK_TRANACTIONS')}
				</Typography>
				<Typography className="f-13 mb-15" color={theme.palette.common.black}>
					{t('SESSION_OF')} {getFormatDate(sessionDate, 'YYYY/MM/DD')}
				</Typography>
			</Grid>
			<Grid item xs={12} mt={5}>
				{trackTransaction?.Combination?.Widgets?.map(
					(trackTransactionData: any, index: any) => (
						<TransactionCard
							step={trackTransactionData}
							widgetNo={trackTransactionData?.widgetNo}
							trackTransactionWholeData={trackTransaction}
						/>
					)
				)}
				{/* <TransactionCard step="1" /> */}
				{/* <TransactionCard step="2" />
			<TransactionCard step="3" /> */}
			</Grid>
		</Grid>
	);
};
export default TrackTransactionLayout;
