import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import { APP_ROUTES } from '../../../../common/constants/Routes';

const NomineeHeader = () => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Grid item xs={12}>
			<Grid container justifyContent="space-between" className="mb-10">
				<Grid item>
					<Typography
						fontSize={16}
						fontWeight={600}
						color={theme.palette.primary.main}
					>
						{t('NOMINEES')}
					</Typography>
				</Grid>
				<Grid item>
					<Link to={APP_ROUTES.DASHBOARD}>
						<CustomButton
							text={t('DONE')}
							variant="text"
							color="primary"
							showIcon
							fontSize={14}
						/>
					</Link>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Separator color={theme.palette.primary.main} />
			</Grid>
		</Grid>
	);
};

export default NomineeHeader;
