import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';

import theme from '../../../common/theme';

interface UserProps {
	// icon: any;
	productLogo: any;
	financierName?: any;
	loanAmount?: any;
	interest?: any;
	charges?: any;
	repayment?: any;
}
const FinancierDetails = ({
	productLogo,
	financierName,
	loanAmount,
	interest,
	charges,
	repayment,
}: UserProps) => {
	const { t } = useTranslation();

	return (
		<Grid container className="mt-10">
			<Grid item xs={9.5} container>
				<Grid item xs={12}>
					<Typography
						fontSize={18}
						color={theme.palette.primary.main}
						fontWeight={600}
					>
						{financierName || 'Financier Name'}
					</Typography>
				</Grid>
				<Grid item xs={12} container className="change-financier-grid mt-15">
					<Grid item xs={12}>
						<Typography className="f-14" color={theme.palette.primary.main}>
							{t('LOAN_AMOUNT')}
						</Typography>
					</Grid>
					<Grid
						xs={12}
						item
						display="flex"
						alignItems="flex-start"
						mb={1}
						container
					>
						<Grid item xs={5.5}>
							<LabelWithIcon
								svgClassName="product-rupee-icon"
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={loanAmount}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 14,
									fontWeight: 600,
								}}
							/>
						</Grid>
						<Grid mt={-0.2} item xs={6.5}>
							<Typography className="f-14 " color={theme.palette.common.black}>
								@ <b>{interest}</b> {t('INTEREST')}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						spacing={0.5}
						display="flex"
						alignItems="flex-start"
						mb={2}
						xs={12}
						container
					>
						<Grid>
							<Typography
								className="f-14 ml-4 "
								color={theme.palette.primary.main}
							>
								{t('CHARGES')}:
							</Typography>
						</Grid>
						<Grid ml={1} mt={0.2}>
							<LabelWithIcon
								svgClassName="product-rupee-icon"
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={charges}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 12,
									fontWeight: 600,
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={2}>
				<img src={productLogo} alt="Logo" className="bank_logo" />
			</Grid>

			<Grid item xs={12}>
				<Typography className="f-14 " color={theme.palette.primary.main}>
					{t('REPAYMENT_OPTION')}
				</Typography>

				<Typography className="f-14 fw-600 " color={theme.palette.common.black}>
					{repayment}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default FinancierDetails;
