import React from 'react';
import { useNavigate } from 'react-router-dom';
import './profile.css';
import { useSelector } from 'react-redux';
import ARForm from '../Common/ARScreens/ARForm';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { APP_ROUTES } from '../../../common/constants/Routes';
import ApiModal from '../../../common/ui/Modal/ApiModal';

const ProfileARscreen = () => {
	const navigate = useNavigate();
	const loggedUser = useSelector(selectIsUserLoggedIn);

	const handleLoginFlow = () => {
		navigate(APP_ROUTES.PROFILE);
	};
	return (
		<>
		<ARForm
			handleSkip={handleLoginFlow}
			handleARFlowSuccess={handleLoginFlow}
			eiaNo={loggedUser?.eiaNo}
			showAlert={true}
		/>
		<ApiModal />
		</>
	);
};

export default ProfileARscreen;
