import SvgConverter from "../../../common/SvgConverter";
import { ReactComponent as smallINR } from '../../../common/icons/profile/orange_ruppee_icon.svg';
import { ReactComponent as INR } from '../../../common/icons/dashboard/rupee-icon.svg';
import { ReactComponent as USD } from '../../../common/icons/dollor.svg';
import { ReactComponent as EUR } from '../../../common/icons/euro.svg';
import { Grid, Typography } from "@mui/material";
import { convertAmount } from "../../../common/utils";
import theme from "../../../common/theme";
interface CurrencyProps {
    policyDetails?: any;
    className?: any;
    amount?: any;
    amountClass?: any
    isSmallRupee?: boolean;
}

const Currency: React.FC<CurrencyProps> = ({
    policyDetails,
    className,
    amount,
    amountClass,
    isSmallRupee = true
}) => {
    // console.log(policyDetails)
    return (<>

        <Grid item>
            <SvgConverter Icon={policyDetails?.currencyType === "USD" ? USD : policyDetails?.currencyType === "EUR" ? EUR : isSmallRupee ? smallINR : INR} className={className} />
            {/* <SvgConverter Icon={OrangeRuppee} className="mb-2 mr-5" /> */}
        </Grid>
        <Grid item>
            <Typography
                className={amountClass || "f-14 fw-600"}

                color={policyDetails?.currencyType === "" || policyDetails?.currencyType === "INR" ? theme.palette.common.black : "#8080FF"}
            >
                {convertAmount(amount)}
            </Typography>
        </Grid>

    </>)
}

export default Currency;