import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as CrossIcon } from '../../../../common/icons/cancel-icon.svg';
import '../Policy.scss';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import { APP_ROUTES } from '../../../../common/constants/Routes';

const LoanAgainstHeader = () => {
	const { t } = useTranslation();
	const removeLocalStorageValues = () => {
		const keysToRemove = [
			'lenderdata',
			'sessionId',
			'currentIndex',
			'esignLink',
			'sessionDate',
		];
		keysToRemove.forEach((key: any) => {
			localStorage.removeItem(key);
		});
	};
	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			className="mt-40"
		>
			<Grid item>
				<Typography className="f-16 fw-600" color="primary">
					{t('LOAN_AGAINST_POLICY')}
				</Typography>
			</Grid>
			<Grid item>
				<Grid container spacing={0.5}>
					<Link to={APP_ROUTES.POLICY}>
						<CustomButton
							className="f-14 fw-400 text_case"
							text={t('CLOSE')}
							variant="text"
							color="primary"
							type="submit"
							endIcon={CrossIcon}
							onClick={() => {
								removeLocalStorageValues();
							}}
						/>
					</Link>
				</Grid>
			</Grid>
			<Separator color={theme.palette.grey[300]} className="my-10" />
		</Grid>
	);
};

export default LoanAgainstHeader;
