/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/ui/CustomButton';
import ImagePickerModal from '../../../common/ui/Modal/ImagePickerModal';
import {
	selectOthersImageIsLoading,
	uploadOtherPhotoRequest,
	uploadProfilePhotoRequest,
	uploadSignaturePhotoRequest,
} from '../ImageUpload/Upload.Slice';
import { setAlertInfo } from '../Common/Common.slice';
import theme from '../../../common/theme';

const PersonalBankDocuments = ({ profileIds }: { profileIds: any }) => {
	const dispatch = useDispatch();
	const [uploadType, setUploadType] = React.useState<string | null>(null);
	const [otherUri, setOtherUri] = React.useState<string | any>(null);
	const [open, setOpen] = useState(false);
	const handleImageUploadMore = () => {
		setOpen(true);
		setUploadType('OTHER');
	};

	const { t } = useTranslation();

	const isUploadInProgress = useSelector(selectOthersImageIsLoading);

	const processImage = (data: any, file: any) => {
		try {
			const imgType = uploadType;
			const uri = data?.assets?.[0]?.uri;
			const imageBase64 = data;
			const fileName = file?.target?.files?.[0]?.name;

			const generateUniqueFileName = () => {
				const timestamp = Date.now();
				const randomId = Math.random().toString(36).substring(2, 8);
				return `image_${timestamp}_${randomId}.jpg`;
			};
			const fileNames = generateUniqueFileName();
			if (imageBase64 || fileName) {
				if (imgType === 'PROFILE') {
					dispatch(
						uploadProfilePhotoRequest({
							imgType,
							imageBase64,
							fileName: fileName || fileNames,
						} as any)
					);
				} else if (imgType === 'SIGNATURE') {
					dispatch(
						uploadSignaturePhotoRequest({
							imgType,
							imageBase64,
							fileName: fileName || fileNames,
						} as any)
					);
				} else if (imgType === 'OTHER') {
					const maxSize = 5 * 1024 * 1024;

					if (file?.target?.files?.[0]?.size > maxSize) {
						dispatch(
							setAlertInfo({
								open: true,
								type: 'FAILED',
								description: t('File size exceeds 5MB'),
							})
						);
					} else {
						dispatch(
							uploadOtherPhotoRequest({
								imgType,
								imageBase64,
								fileName: fileName || fileNames,
							} as any)
						);
					}

					setOtherUri(uri as string);
				}
				setOpen(false);
			}
		} catch (e) {
			// eslint-disable-next-line no-console
		}
	};
	return (
		<Grid>
			<Grid container justifyContent="space-between">
				<Grid item>
					<Typography className="f-14 fw-600 mb-14" color="primary">
						{t('ID_&_DOCS')}
					</Typography>
				</Grid>
				<Grid item>
					<CustomButton
						text={
							profileIds && profileIds.length === 0
								? t('UPLOAD_NOW')
								: t('UPLOAD_MORE')
						}
						variant="text"
						color="primary"
						className="f-14 mb-14 fw-400"
						onClick={handleImageUploadMore}
						loading={isUploadInProgress}
					/>
					<ImagePickerModal
						open={open}
						setOpen={setOpen}
						handleImage={processImage}
						disableWebcam
					/>
				</Grid>
			</Grid>
			{profileIds && profileIds.length === 0 && (
				<Typography
					className="f-14 fw-600 mb-14"
					color={theme.palette.common.black}
				>
					{t('ID_&_DOCS')} {t('NOT_AVAILABLE')}
				</Typography>
			)}
			{profileIds &&
				profileIds.map((item: any) => (
					<Grid container className="py-20">
						{item.imgData && item.imgType === 'M' ? (
							<Grid item xs={4}>
								<img
									src={`data:image/png;base64,${item.imgData}`}
									width={200}
								/>
							</Grid>
						) : item.imgData && item.imgType === 'L' ? (
							<Grid item xs={4}>
								<img
									src={`data:image/png;base64,${item.imgData}`}
									width={200}
								/>
							</Grid>
						) : otherUri && item.imgType === 'M' ? (
							<Grid item xs={4}>
								<img src={otherUri} />
							</Grid>
						) : otherUri && item.imgType === 'L' ? (
							<Grid item xs={4}>
								<img src={otherUri} />
							</Grid>
						) : (
							<Grid item xs={4}>
								<img src={otherUri} />
							</Grid>
						)}
					</Grid>
				))}
		</Grid>
	);
};

export default PersonalBankDocuments;
