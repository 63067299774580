import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
	fetchAddMoneyData,
	fetchDashboardTracker,
	fetchMyOptions,
	fetchPayoutList,
	fetchSaveSelectedCombination,
	fetchSelectedCombination,
	fetchSignatureReminder,
	fetchTrackTransaction,
} from './Loan.service';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import {
	addMoneyError,
	addMoneyRequest,
	addMoneySuccess,
	dashboardTrackerError,
	myOptionsError,
	myOptionsRequest,
	myOptionsSuccess,
	payoutError,
	payoutRequest,
	payoutSuccess,
	saveSelectedCombinationError,
	saveSelectedCombinationRequest,
	saveSelectedCombinationSuccess,
	selectedCombination,
	selectedCombinationError,
	selectedCombinationRequest,
	selectedCombinationSuccess,
	signatureReminderError,
	signatureReminderRequest,
	signatureReminderSuccess,
	trackTransactionError,
	trackTransactionRequest,
	trackTransactionSuccess,
} from './Loan.slice';
import {
	dashboardListRequest,
	dashboardListSuccess,
} from '../DashboardScreen/Dashboard.slice';
import { setAlertInfo } from '../Common/Common.slice';
import { APP_ROUTES } from '../../../common/constants/Routes';
import cryptoEncryptionDecryption from '../../../common/crypto';

function* handleAddMoneyInformation() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchAddMoneyData, requestPayload);
		yield* put(addMoneySuccess(data));
	} catch (e: any) {
		yield* put(addMoneyError(e.message));
	}
}

function* handlePayoutList({ payload: { navigate, ...payload } }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				requiredAmount: payload.requiredAmount,
				requiredDate: payload.requiredDate,
			},
		};
		const { data }: any = yield* call(fetchPayoutList, requestPayload);

		if (String(data?.payoutFlag).toUpperCase() === 'Y') {
			navigate(APP_ROUTES.TARGET_AMOUNT);
		} else if (String(data?.payoutFlag).toUpperCase() === 'N') {
			yield* put(myOptionsRequest((payload = { navigate, ...payload } as any)));
		} else {
			yield* put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: data?.errorDescription,
				})
			);
		}
		yield* put(payoutSuccess(data));
	} catch (e: any) {
		yield* put(payoutError(e.message));
	}
}

function* handleMyOptions({ payload: { navigate, ...payload } }: any) {
	try {
		let requestPayload;

		const user: any = yield* select(selectIsUserLoggedIn);
		if (payload.type === 'Policy') {
			requestPayload = {
				Customer: {
					eiaNo: user.eiaNo,
					requiredAmount: '',
					requiredDate: '',
					policyNo: payload?.policyNo,
					insurerCode: payload?.companyCode,
				},
			};
		} else {
			requestPayload = {
				Customer: {
					eiaNo: user.eiaNo,
					requiredAmount: payload.requiredAmount,
					requiredDate: payload.requiredDate,
				},
			};
		}
		const { data }: any = yield* call(fetchMyOptions, requestPayload);
		yield* put(myOptionsSuccess(data));
		if (data?.Combinations || data?.PolicyDetail) {
			// yield* put(
			// 	setAlertInfo({
			// 		open: true,
			// 		type: 'SUCCESS',
			// 		description: data?.errorDescription,
			// 	})
			// );
			if (payload.type === 'Policy') {
				navigate(APP_ROUTES.Get_Loan_Against_Policy);
			} else {
				navigate(APP_ROUTES.Best_Fit_Combinations, { state: payload });
			}
		} else {
			yield* put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: data?.errorDescription,
				})
			);
		}
	} catch (e: any) {
		yield* put(myOptionsError(e.message));
	}
}
function* handleSelectedCombination({
	payload: { navigate, ...payload },
}: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				requiredAmount: payload.requiredAmount || '',
				requiredDate: payload.requiredDate || '',
			},

			Combination: payload.Combination,
		};
		const { data }: any = yield* call(fetchSelectedCombination, requestPayload);
		yield* put(selectedCombinationSuccess(data));
		if (payload.type === 'Policy') {
			if (data?.Combination) {
				// yield* put(
				// 	setAlertInfo({
				// 		open: true,
				// 		type: 'SUCCESS',
				// 		description: data?.errorDescription,
				// 	})
				// );
				yield* put(selectedCombinationSuccess(data));
			} else {
				yield* put(
					setAlertInfo({
						open: true,
						type: 'FAILED',
						description: data?.errorDescription,
					})
				);
			}
		} else {
			if (data?.Combination) {
				// yield* put(
				// 	setAlertInfo({
				// 		open: true,
				// 		type: 'SUCCESS',
				// 		description: data?.errorDescription,
				// 	})
				// );
				yield* put(selectedCombinationSuccess(data));
			} else {
				yield* put(
					setAlertInfo({
						open: true,
						type: 'FAILED',
						description: data?.errorDescription,
					})
				);
			}
			navigate(APP_ROUTES.Lender_Screen);
		}
	} catch (e: any) {
		yield* put(selectedCombinationError(e.message));
	}
}

function* handleSaveSelectedCombination({ payload }: any) {
	try {
		const combinations: any = yield* select(selectedCombination);
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				requiredAmount:
					combinations?.requiredAmount || payload?.requiredAmount || '',
				requiredDate: combinations?.requiredDate || payload?.requiredDate || '',
				redirectUrl: payload?.redirectUrl,
				dashboardSessionFlag: payload?.dashboardSessionFlag === 'Y' ? 'Y' : payload?.dashboardSessionFlag === 'W' ? 'W' : 'N',
			},
			Combination: payload?.modifiedData,
		};
		const { data }: any = yield* call(
			fetchSaveSelectedCombination,
			requestPayload
		);

		if (data?.sessionDate && data?.sessionId) {
			// yield* put(
			// 	setAlertInfo({
			// 		open: true,
			// 		type: 'SUCCESS',
			// 		description: data?.errorDescription,
			// 	})
			// );
		} else {
			const alertInfo: any = {
				open: true,
				type: 'FAILED',
				description: data?.errorDescription,
				errorCode: '2205',
			};
			yield* put(setAlertInfo(alertInfo));
		}

		if (payload?.dashboardSessionFlag === 'Y') {
			const alertInfo: any = {
				open: true,
				type: `${data?.sessionDate && data?.sessionId ? 'SUCCCESS' : 'FAILED'}`,
				description: data?.displayMessage || data?.errorDescription,
				errorCode: '2205',
			};
			yield* put(setAlertInfo(alertInfo));
		} else {
			const jsonStringEsign = cryptoEncryptionDecryption.Encrypt(data);
			localStorage.setItem('esignLink', jsonStringEsign);
			localStorage.setItem('currentIndex', String(0));
			localStorage.setItem('sessionDate', data?.sessionDate);
			localStorage.setItem('sessionId', data?.sessionId);
		}

		yield* put(saveSelectedCombinationSuccess(data));
	} catch (e: any) {
		yield* put(saveSelectedCombinationError(e.message));
	}
}

function* handleTrackTransaction({ payload: { sessionDate, sessionId } }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				sessionDate,
				sessionId,
			},
		};

		const { data }: any = yield* call(fetchTrackTransaction, requestPayload);
		yield* put(trackTransactionSuccess(data));
		if (data?.Widgets) {
			// yield* put(
			// 	setAlertInfo({
			// 		open: true,
			// 		type: 'SUCCESS',
			// 		description: data?.errorDescription,
			// 	})
			// );
		} else {
			const alertInfo: any = {
				open: true,
				type: 'FAILED',
				description: data?.errorDescription,
				errorCode: '2205',
			};
			yield* put(setAlertInfo(alertInfo));
		}
	} catch (e: any) {
		yield* put(trackTransactionError(e.message));
	}
}
//getting 500 error . duplicate saga function
// function* handleDashboardTracker(payload: any) {

// 	console.log(payload)
// 	try {
// 		const user: any = yield* select(selectIsUserLoggedIn);

// 		const requestPayload = {
// 			Customer: {
// 				eiaNo: user.eiaNo,
// 				requiredAmount: '',
// 				requiredDate: '',
// 			},
// 		};
// 		const { data }: any = yield* call(fetchDashboardTracker, requestPayload);
// 		yield* put(dashboardListSuccess(data));
// 	} catch (e: any) {
// 		yield* put(dashboardTrackerError(e.message));
// 	}
// }

function* handleSignatureReminder({ payload: { sessionDate, sessionId, loanFlag } }: any) {
	try {

		const user: any = yield* select(selectIsUserLoggedIn);

		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				sessionDate,
				sessionId,
				loanFlag
			},
		};

		const { data }: any = yield* call(fetchSignatureReminder, requestPayload);

		yield* put(signatureReminderSuccess(data));

		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);

	} catch (e: any) {
		yield* put(signatureReminderError(e));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'FAILED',
				description: e?.errorDescription,
			})
		);
	}
}

function* watchLoan() {
	yield* takeEvery(addMoneyRequest.type, handleAddMoneyInformation);
	yield* takeEvery(myOptionsRequest.type, handleMyOptions);
	yield* takeEvery(selectedCombinationRequest.type, handleSelectedCombination);
	yield* takeEvery(trackTransactionRequest.type, handleTrackTransaction);
	// yield* takeEvery(dashboardListRequest.type, handleDashboardTracker);
	yield* takeEvery(
		saveSelectedCombinationRequest.type,
		handleSaveSelectedCombination
	);
	yield* takeEvery(payoutRequest.type, handlePayoutList);
	yield* takeEvery(signatureReminderRequest.type, handleSignatureReminder);
}
export { watchLoan }
