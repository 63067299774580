/* eslint-disable import/prefer-default-export */
/* eslint-disable react/require-default-props */
import { FC, ReactNode } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LabelWithIcon from '../LabelWithIcon';
import theme from '../../theme';

interface AccordionProps {
	children: ReactNode;
	accordionIcon?: any;
	accordionLabel?: string;
	expanded?: any;
}
export const CustomAccordion: FC<AccordionProps> = ({
	children,
	accordionIcon,
	accordionLabel,
	expanded,
}) => (
	<Accordion style={{ boxShadow: 'none' }} defaultExpanded={expanded}>
		<AccordionSummary expandIcon={<ExpandMore />}>
			<LabelWithIcon
				justifyContent="flex-start"
				Icon={accordionIcon}
				label={accordionLabel}
				labelProps={{
					color: theme.palette.primary.main,
					fontSize: 20,
				}}
			/>
		</AccordionSummary>
		<AccordionDetails>{children}</AccordionDetails>
	</Accordion>
);
