import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import theme from '../../../common/theme';
import CustomButton from '../../../common/ui/CustomButton';

const TapToSelectModel = ({
	setOpen,
	open,
	icon,
	handleNavigate,
	accountNum,
	bankName,
	branch,
	ifscCode,
	accountHolder,
}: any) => {
	const { t } = useTranslation();

	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName="px-20 py-25"
			boxProps={{ width: 350 }}
		>
			<Typography className="f-18 fw-600" color={theme.palette.primary.main}>
				{`${t('YOU_ARE_SELECTING')},`}
			</Typography>
			<Grid xs={12} display="flex" justifyContent="space-between">
				<Grid item>
					<Grid item xs={12}>
						<Typography
							fontSize={18}
							color={theme.palette.primary.main}
							fontWeight={600}
						>
							{bankName}
						</Typography>
					</Grid>
					<Grid item xs={12} className="mt-10">
						<Typography fontSize={16} color={theme.palette.common.black}>
							{accountHolder}
						</Typography>
						<Typography fontSize={16} color={theme.palette.common.black}>
							{`${t('ACC')}#`}
							{accountNum}
						</Typography>
						<Typography fontSize={16} color={theme.palette.common.black}>
							{bankName}
						</Typography>
						<Typography fontSize={16} color={theme.palette.common.black}>
							{branch}
						</Typography>
						<Typography fontSize={16} color={theme.palette.common.black}>
							{ifscCode}
						</Typography>
					</Grid>
				</Grid>
				<Grid item>
					<Grid>
						<img src={icon} alt="Logo" width={60} height={60} />
					</Grid>
				</Grid>
			</Grid>
			<Grid>
				<Grid display="flex" justifyContent="flex-end" className="mt-30">
					<CustomButton
						text={t('CONFIRM_SELECTION')}
						showIcon={false}
						variant="text"
						onClick={() => {
							handleNavigate();
						}}
						fontSize={18}
					/>
				</Grid>
				<Grid display="flex" justifyContent="flex-end" className="mt-25">
					<CustomButton
						text={t('SELECT_A_DIFFERENT_BANK')}
						showIcon={false}
						variant="text"
						onClick={() => {
							setOpen(false);
						}}
						fontSize={18}
					/>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default TapToSelectModel;
