/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import Separator from '../../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import { convertAmount, getFormatDate } from '../../../../../../common/utils';
import Currency from '../../../../Common/Currency';

interface FamilyMembersProps {
	familyMembersDetails?: any;
	healthPolicyDetails?: any;
}
const FamilyMembers: React.FC<FamilyMembersProps> = ({
	familyMembersDetails,
	healthPolicyDetails
}) => {
	const { t } = useTranslation();

	return (
		<Grid container className="py-10">
			<Grid item xs={12} className="mb-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('FAMILY_MEMBERS')}
				</Typography>
			</Grid>
			{familyMembersDetails &&
				familyMembersDetails &&
				familyMembersDetails?.map((mapdata: any, index: any) => (
					<Grid item container xs={12} className="mb-30">
						<Grid item xs={0.5}>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{index + 1}.
							</Typography>
						</Grid>
						<Grid item container xs={11} className="ml-10">
							<Grid item container xs={10} className="blue-box ">
								<Grid item xs={12} className="px-8">
									<Typography
										color={theme.palette.primary.main}
										fontSize={18}
										fontWeight={600}
										className="mt-15"
									>
										{mapdata && mapdata?.name?.length === 0
											? '-'
											: mapdata?.name}
									</Typography>

									{mapdata && mapdata?.gender?.length > 0 && (
										<>
											{mapdata && mapdata?.gender === '1' ? (
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
													fontWeight={600}
												>
													{t('MALE')}
												</Typography>
											) : mapdata && mapdata?.gender === '2' ? (
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
													fontWeight={600}
												>
													{t('FEMALE')}
												</Typography>
											) : mapdata && mapdata?.gender === '3' ? (
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
													fontWeight={600}
												>
													{t('OTHERS')}
												</Typography>
											) : (
												'-'
											)}
										</>
									)}

									{mapdata?.relation?.length > 0 && (
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											fontWeight={600}
										>
											{mapdata?.relation?.length === 0
												? '-'
												: mapdata?.relation}
										</Typography>
									)}

									{/* {mapdata?.relation.length !== 0 && (
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											fontWeight={600}
										>
											{mapdata?.relation.length === 0 ? '-' : mapdata?.relation}
										</Typography>
									)} */}
								</Grid>
								<Grid item xs={6} className="px-8">
									<Typography
										color={theme.palette.primary.main}
										fontSize={14}
										fontWeight={600}
										className="mt-15"
									>
										{t('D.O.B')}
									</Typography>
									<Typography color={theme.palette.common.black} fontSize={16}>
										{mapdata?.dob?.length === 0 ? (
											'-'
										) : (
											<>{getFormatDate(mapdata?.dob, 'YYYY-MM-DD')}</>
										)}
									</Typography>
								</Grid>{' '}
								<Grid item xs={6}>
									<Typography
										color={theme.palette.primary.main}
										fontSize={14}
										fontWeight={600}
										className="mt-15"
									>
										{t('OCCUPATION')}
									</Typography>
									<Typography color={theme.palette.common.black} fontSize={16}>
										{mapdata?.occupation?.length === 0
											? '-'
											: mapdata?.occupation}
									</Typography>
								</Grid>
								<Grid item xs={12} className="px-8">
									<Typography
										color={theme.palette.primary.main}
										fontSize={14}
										fontWeight={600}
										className="mt-15"
									>
										{t('SMOKING/CHEWING_TOBBACO')}
									</Typography>
									{/* <Typography color={theme.palette.common.black} fontSize={16}>
										{mapdata && mapdata?.smokingTobacco
											? '-'
											: mapdata?.smokingTobacco}
									</Typography> */}
									{mapdata && mapdata?.smokingTobacco === '1' ? (
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
										>
											{t('YES')}
										</Typography>
									) : (
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
										>
											{t('NO')}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12} className="py-10 ">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								<>
									<Grid item container xs={12} className="px-8 py-5">
										<Grid item xs={7.5}>
											<Typography
												color={theme.palette.primary.main}
												fontSize={14}
												fontWeight={600}
											// className="text-wrap"
											>
												{t('PRE-EXISTING_DISEASE')}
											</Typography>
										</Grid>
										{mapdata?.preExistingDisease?.length > 0 &&
											mapdata?.preExistingDisease?.[0] &&
											(mapdata?.preExistingDisease?.[0]?.diseaseName !== '' ||
												mapdata?.preExistingDisease?.[0]?.dateSince !== '') &&
											mapdata?.preExistingDisease?.[0]?.length !== 0 && (
												<Grid item xs={4}>
													<Typography
														color={theme.palette.primary.main}
														fontSize={14}
														fontWeight={600}
													>
														{t('DATE_SINCE')}
													</Typography>
												</Grid>
											)}
									</Grid>
									<></>
									{mapdata?.preExistingDisease?.length > 0 &&
										mapdata?.preExistingDisease?.length !== 0 &&
										(mapdata?.preExistingDisease?.[0]?.diseaseName !== '' ||
											mapdata?.preExistingDisease?.[0]?.dateSince !== '') &&
										mapdata?.preExistingDisease[0]?.length !== 0 ? (
										<Grid item container xs={12} className="px-8">
											{mapdata &&
												mapdata?.preExistingDisease?.length > 0 &&
												mapdata?.preExistingDisease.map(
													(existingdata: any, index: any) => (
														<Grid item container xs={12}>
															<Grid item xs={0.5}>
																<Typography
																	color={theme.palette.common.black}
																	fontSize={14}
																>
																	{index + 1}.
																</Typography>
															</Grid>
															<Grid item xs={7}>
																<Typography
																	color={theme.palette.common.black}
																	fontSize={14}
																>
																	{existingdata &&
																		existingdata.diseaseName?.length === 0
																		? '-'
																		: existingdata.diseaseName}
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography
																	color={theme.palette.common.black}
																	fontSize={14}
																>
																	{getFormatDate(
																		existingdata && existingdata?.dateSince
																	)}
																</Typography>
															</Grid>
														</Grid>
													)
												)}
											<Grid item xs={12} className="py-10">
												<Separator color={theme.palette.grey[400]} />
											</Grid>
										</Grid>
									) : (
										<Typography
											color={theme.palette.common.black}
											className="px-8"
											fontSize={14}
										>
											{t('NO_PREEXISTING_DISEASE')}
										</Typography>
									)}
								</>
								<Grid item container xs={12} className="px-8">
									<Grid item xs={12} className="py-10">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('ASSIGNEE_DETAILS')}
										</Typography>
										<Typography
											color={theme.palette.common.black}
											fontSize={16}
										>
											{mapdata && mapdata?.assigneeDetails?.length === 0
												? '-'
												: mapdata?.assigneeDetails}
										</Typography>
									</Grid>
									{mapdata && mapdata?.discountOffered !== ' ' && (
										<Grid item xs={12} className="py-10">
											<Typography
												color={theme.palette.primary.main}
												fontSize={14}
												fontWeight={600}
											>
												{t('DISCOUNT_OFFERED')}
											</Typography>
											<Grid
												container
												alignItems="center"
												item

											>
												<Currency policyDetails={healthPolicyDetails} amount={mapdata && mapdata?.discountOffered?.length === 0
													? 0
													: mapdata?.discountOffered} amountClass={"f-18 ml-5 fw-600"} />
											</Grid>
											{/* <LabelWithIcon
												className=""
												svgClassName="mb-2 "
												justifyContent="flex-start"
												Icon={OrangeRuppee}
												label={
													mapdata && mapdata?.discountOffered?.length === 0
														? '0'
														: convertAmount(mapdata?.discountOffered)
												}
												labelProps={{
													color: theme.palette.common.black,
													fontSize: 18,
													fontWeight: 600,
												}}
											/> */}
										</Grid>
									)}

									<Grid item xs={12} className="py-10">
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('CUMULATIVE_BONUS')}
										</Typography>
										<Grid
											container
											alignItems="center"
											item

										>
											<Currency policyDetails={healthPolicyDetails} amount={mapdata && mapdata?.cumulativeBonus?.length === 0
												? 0
												: mapdata?.cumulativeBonus} amountClass={"f-18 ml-5 fw-600"} />
										</Grid>
										{/* <LabelWithIcon
											className=""
											svgClassName="mb-2 "
											justifyContent="flex-start"
											Icon={OrangeRuppee}
											label={
												mapdata && mapdata?.cumulativeBonus?.length === 0
													? '-'
													: convertAmount(mapdata?.cumulativeBonus)
											}
											labelProps={{
												color: theme.palette.common.black,
												fontSize: 18,
												fontWeight: 600,
											}}
										/> */}
									</Grid>
									{mapdata && mapdata?.premiumLoading !== ' ' && (
										<Grid item xs={12} className="py-10">
											<Typography
												color={theme.palette.primary.main}
												fontSize={14}
												fontWeight={600}
											>
												{t('PREMIUM_LOADING')}
											</Typography>
											<Grid
												container
												alignItems="center"
												item

											>
												<Currency policyDetails={healthPolicyDetails} amount={mapdata && mapdata?.premiumLoading?.length === 0
													? 0
													: mapdata?.premiumLoading} amountClass={"f-18 ml-5 fw-600"} />
											</Grid>
											{/* <LabelWithIcon
												className=""
												svgClassName="mb-2 "
												justifyContent="flex-start"
												Icon={OrangeRuppee}
												label={
													mapdata && mapdata?.premiumLoading?.length === 0
														? '-'
														: convertAmount(mapdata?.premiumLoading)
												}
												labelProps={{
													color: theme.palette.common.black,
													fontSize: 18,
													fontWeight: 600,
												}}
											/> */}
										</Grid>
									)}

									{mapdata &&
										mapdata?.discountOfferedForVoluntaryDEDUCTIBLES !== ' ' && (
											<Grid item xs={12} className="py-10">
												<Typography
													color={theme.palette.primary.main}
													fontSize={14}
													fontWeight={600}
												>
													{t('DISCOUNT_OFFERED_FOR_VOLUNTARY_DEDUCTIBLES')}
												</Typography>
												<Grid
													container
													alignItems="center"
													item

												>
													<Currency policyDetails={healthPolicyDetails} amount={mapdata &&
														mapdata?.discountOfferedForVoluntaryDeductibles
															?.length === 0
														? '-'
														:
														mapdata?.discountOfferedForVoluntaryDeductibles
													} amountClass={"f-18 ml-5 fw-600"} />
												</Grid>
												{/* <LabelWithIcon
													className=""
													svgClassName="mb-2 "
													justifyContent="flex-start"
													Icon={OrangeRuppee}
													label={
														mapdata &&
															mapdata?.discountOfferedForVoluntaryDeductibles
																?.length === 0
															? '-'
															: convertAmount(
																mapdata?.discountOfferedForVoluntaryDeductibles
															)
													}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 18,
														fontWeight: 600,
													}}
												/> */}
											</Grid>
										)}

									{mapdata && mapdata?.coPayerWavier !== ' ' && (
										<Grid item xs={12} className="py-10">
											<Typography
												color={theme.palette.primary.main}
												fontSize={14}
												fontWeight={600}
											>
												{t('CO_PAY_WAIVER')}
											</Typography>
											<Typography
												color={theme.palette.common.black}
												fontSize={16}
											>
												{mapdata && mapdata?.coPayerWavier?.length === 0
													? '-'
													: mapdata?.coPayerWavier}
											</Typography>
											{/* {mapdata && mapdata?.coPayerWavier === '1' ? (
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
												>
													{t('YES')}
												</Typography>
											) : (
												<Typography
													color={theme.palette.common.black}
													fontSize={14}
												>
													{t('NO')}
												</Typography>
											)} */}
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				))}
		</Grid>
	);
};
export default FamilyMembers;
