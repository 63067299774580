import { Grid } from '@mui/material';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import AddPolicyForm from './AddPolicyForm';

import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as arrow } from '../../../../common/icons/right_arrow-icon.svg';
import SvgConverter from '../../../../common/SvgConverter';
import DoneModal from '../../Common/DoneModal';
import { addNewPolicy, addPolicyLoading } from '../Policies.slice';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';


const AddPolicyScreen = () => {
	const navigate = useNavigate();
	const isLoadingAddPolicy = useSelector(addPolicyLoading);
	const [showDoneModal, setShowDoneModal] = useState(false);
	const [lifeList, setLifeList] = useState<any[]>([]);
	const [healthList, setHealthList] = useState<any[]>([]);
	const [assetList, setAssetList] = useState<any[]>([]);
	const handleCancel = () => {
		navigate(-1);
	};
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const handleFinalSubmit = () => {
		const data = [...lifeList, ...healthList, ...assetList];
		dispatch(
			addNewPolicy({
				data,
				navigate,
			} as any)
		);
	};
	const donePopUp = () => {
		if (lifeList.length > 0 || healthList.length > 0 || assetList.length > 0) {
			setShowDoneModal(true);
		} else {
			navigate(-1);
		}
	};

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Onboardinig Screen',
		});
	}, []);


	return (
		<Grid xs={12} sm={12} lg={12} className="policy-style">
			<Grid
				item
				xs={12}
				sm={12}
				justifyContent="space-between"
				className="px-13 mb-20"
			>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography
							className="f-16 fw-600"
							color={theme.palette.primary.main}
						>
							{t('ADD_POLICIES')}
						</Typography>
					</Grid>
					<Grid item>
						{/* <Link to={APP_ROUTES.POLICY}> */}
						<Grid display="flex" alignItems="center">
							<CustomButton
								className="f-14 fw-400"
								text={t('DONE')}
								variant="text"
								color="primary"
								showIcon={false}
								type="submit"
								onClick={donePopUp}
							/>
							<SvgConverter Icon={arrow} className="right-arrow" />
						</Grid>
						{/* </Link> */}
					</Grid>
				</Grid>
			</Grid>

			<Grid>
				<Grid item>
					<AddPolicyForm
						lifeList={lifeList}
						healthList={healthList}
						assetList={assetList}
						setLifeList={setLifeList}
						setHealthList={setHealthList}
						setAssetList={setAssetList}
					/>
				</Grid>
			</Grid>
			<DoneModal
				open={showDoneModal}
				setOpen={setShowDoneModal}
				onSubmit={handleFinalSubmit}
				loading={isLoadingAddPolicy}
				onCancel={handleCancel}
				contentType="POLICY"
			/>
		</Grid>
	);
};

export default AddPolicyScreen;
