import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';

interface ExclusionProps {
	ExclusionDetails?: any;
}
const Exclusion: React.FC<ExclusionProps> = ({ ExclusionDetails }) => {
	const { t } = useTranslation();
	return (
		<Grid container className="py-15">
			<Grid item xs={12}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('EXCLUSIONS')}
				</Typography>
			</Grid>
			{ExclusionDetails &&
				ExclusionDetails.map((mapdata: any, index: any) => (
					<Grid item container xs={12} key={index} className="mb-20 mt-15">
						<Grid item xs={0.5}>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{index + 1}.
							</Typography>
						</Grid>
						<Grid item container xs={10} className="blue-box  ">
							<Grid item xs={12} className="ml-10 mr-2">
								<Typography
									color={theme.palette.primary.main}
									fontSize={18}
									fontWeight={600}
									className="py-5"
								>
									{mapdata?.exclusionTitle?.length === 0
										? '-'
										: mapdata?.exclusionTitle}
								</Typography>
								<Typography
									color={theme.palette.common.black}
									fontSize={14}
									textAlign="left"
									className="mr-20 py-10"
								>
									{mapdata?.exclusionDescription?.length === 0
										? '-'
										: mapdata?.exclusionDescription}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				))}
		</Grid>
	);
};

export default Exclusion;
