/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SvgConverter from '../../../../SvgConverter';
import { ReactComponent as correct } from '../../../../icons/tick-icon-blue.svg';
import { ReactComponent as uncheck } from '../../../../icons/uncheck-box.svg';

interface CheckboxProps {
	checked: boolean;
	label: string | any;
	CheckBoxClassName?: string | undefined;
	onClick?: any;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	size?: number;
	checkBoxPadding?: number;
	sx?: any;
	labelStyle?: any
}

const FormCheckBox: React.FC<CheckboxProps> = ({
	checked,
	label,
	onChange,
	onClick,
	CheckBoxClassName,
	size = 24,
	checkBoxPadding,
	sx,
	disabled,
	labelStyle
}) => {
	const checkboxStyle = {
		transform: `scale(${size / 24})`,
		padding: checkBoxPadding,
	};

	return (
		<FormControlLabel
			control={
				<Checkbox
					sx={sx}
					checked={checked}
					onChange={onChange}
					onClick={onClick}
					className={`${CheckBoxClassName}`}
					style={checkboxStyle}
					checkedIcon={<SvgConverter Icon={correct} />}
					icon={<SvgConverter Icon={uncheck} />}

				/>
			}
			label={label}
			disabled={disabled}
			style={labelStyle}
		/>
	);
};

export default FormCheckBox;
