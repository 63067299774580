import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';

const AuthorizedRepresentativeModalDetails = ({
	open,
	handleARSubmit,
	loading,
	arName,
}: any) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<CommonModal
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			<Grid item className="p-15">
				<Grid item>
					<Typography className="f-16" color={theme.palette.common.black}>
						{t('WOULD_YOU_LIKE_TO_INFORM')}{" ["}
						{arName}{"]"}?{`${t('ABOUT_THEIR_ASSIGNMENT_AS_AR')}?`}
					</Typography>
					<Typography className="f-12" color={theme.palette.grey[400]}>
						{t('YOU_CAN_ALWAYS_INFORM_AR')}
					</Typography>
				</Grid>
				{loading ? (
					<Grid item className="text-center mt-30">
						<CircularProgress color="primary" />
					</Grid>
				) : (
					<>
						<Grid item container justifyContent="flex-end" className="mt-30">
							<CustomButton
								text={t("SUBMIT_BUT_DON'T_INFORM_AR")}
								variant="text"
								color="primary"
								className="fw-600 f-16"
								onClick={handleARSubmit(false)}
							/>
						</Grid>
						<Grid item container justifyContent="flex-end" className="mt-20">
							<CustomButton
								text={t('SUBMIT_&_INFORM_AR')}
								variant="text"
								color="primary"
								className="fw-600 f-16"
								onClick={handleARSubmit(true)}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</CommonModal>
	);
};

export default AuthorizedRepresentativeModalDetails;
