import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as alert } from '../../../common/icons/profile/alert.svg';
import theme from '../../../common/theme';

const UnverifiedText = () => {
	const { t } = useTranslation();

	return (
		<Grid item>
			<Grid container spacing={1} alignItems="center" flexWrap="nowrap">
				<Grid item>
					<SvgConverter Icon={alert} />
				</Grid>
				<Grid item>
					<Typography
						className="f-12 mt-3 fw-600"
						color={theme.palette.error.main}
					>
						{t('NOT_VERIFIED')}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UnverifiedText;
