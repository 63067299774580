import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { KYC_TYPES } from '../../../common/Enums';
import { saveEkycRequest } from '../auth.slice';
import Spinner from '../../../common/ui/Spinner';
import cryptoEncryptionDecryption from '../../../common/crypto';
const SignupKycReturnComponent = () => {
	const location = useLocation();
	const navigation = useNavigate();
	const dispatch = useDispatch();
	const signupData_pan = window.sessionStorage.getItem('signupData_pan');
	const signupData_gender = window.sessionStorage.getItem('signupData_gender');
	const signupData_whatsappCountryCode = window.sessionStorage.getItem(
		'signupData_whatsappCountryCode'
	);
	const signupData_whatsappNumber = window.sessionStorage.getItem(
		'signupData_whatsappNumber'
	);
	const signupData_customerType = window.sessionStorage.getItem(
		'signupData_customerType'
	);
	const customerName = window.sessionStorage.getItem('customerName');
	const trimmedCustomerName = customerName
		? cryptoEncryptionDecryption
				.Decrypt(customerName)
				.replace(/^"(.*)"$/, '$1')
				.trimEnd()
		: '';
	useEffect(() => {
		const ekycId = location.search.split('=')[1];
		if (ekycId) {
			const payload = {
				kycId: ekycId,
				eiaNo: '',
				flag: KYC_TYPES.SIGNUP,
				pan: signupData_pan
					? cryptoEncryptionDecryption
							.Decrypt(signupData_pan)
							.replace(/^"(.*)"$/, '$1')
							.trimEnd()
					: '',
				gender: signupData_gender
					? cryptoEncryptionDecryption
							.Decrypt(signupData_gender)
							.replace(/^"(.*)"$/, '$1')
							.trimEnd()
					: '',
				whatsappCountryCode: signupData_whatsappCountryCode
					? cryptoEncryptionDecryption
							.Decrypt(signupData_whatsappCountryCode)
							.replace(/^"(.*)"$/, '$1')
							.trimEnd()
					: '',
				whatsappNumber: signupData_whatsappNumber
					? cryptoEncryptionDecryption
							.Decrypt(signupData_whatsappNumber)
							.replace(/^"(.*)"$/, '$1')
							.trimEnd()
					: '',
				customerType: signupData_customerType
					? cryptoEncryptionDecryption
							.Decrypt(signupData_customerType)
							.replace(/^"(.*)"$/, '$1')
							.trimEnd()
					: '',
				customerName: trimmedCustomerName,
				navigation,
			};
			dispatch(saveEkycRequest(payload as any));
		}
	}, [location]);
	return (
		<Grid container justifyContent="center" marginTop={10}>
			<Grid item>
				<Grid item display="flex" justifyContent="center" alignItems="center">
					<Spinner />
				</Grid>
				<Typography className="fw-500 f-18 pt-10">
					Please wait while we verify your details...
				</Typography>
			</Grid>
		</Grid>
	);
};
export default SignupKycReturnComponent;
