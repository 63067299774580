import { Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './Policy.scss';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy, prop } from 'ramda';
import { ReactComponent as GroupCertified } from '../../../common/icons/policy/life-sm.svg';
import { ReactComponent as Health } from '../../../common/icons/policy/health-sm.svg';
import { ReactComponent as Assets } from '../../../common/icons/policy/assets-sm.svg';
import { CustomAccordion } from '../../../common/ui/Accordion/CustomAccordion';
import ArchiveLife from './common/AccordionContent/ArchiveLife';
import ArchiveHealth from './common/AccordionContent/ArchiveHealth';
import ArchiveAssets from './common/AccordionContent/ArchiveAssets';
import { fetchArchivePolicy, selectArchivePolicies } from './Policies.slice';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import Spinner from '../../../common/ui/Spinner';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

interface ArchiveProps {
	archiveLoading?: any;
}
const ArchiveScreen: React.FC<ArchiveProps> = ({ archiveLoading }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const archivePolicies = useSelector(selectArchivePolicies);

	const policyList = useMemo(() => {
		const groupedPolicies = groupBy<any>(prop<string>('alternateCode'))(
			archivePolicies
		);
		return {
			lifeList: groupedPolicies.L || [],
			healthList: groupedPolicies.H || [],
			assetList: groupedPolicies.G || [],
		};
	}, [archivePolicies]);
	useEffect(() => {
		dispatch(fetchArchivePolicy());
	}, []);

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Archive Screen',
		});
	}, []);

	return (
		<Grid container>
			{archiveLoading ? (
				<Grid item display="flex" justifyContent="center">
					<Spinner />
				</Grid>
			) : (
				<Grid item xs={12}>
					<CustomAccordion
						accordionIcon={GroupCertified}
						accordionLabel={t('LIFE')}
					>
						<ArchiveLife filteredLife={policyList.lifeList} />
					</CustomAccordion>
					<Separator color={theme.palette.grey[300]} />
					<CustomAccordion accordionIcon={Health} accordionLabel={t('HEALTH')}>
						<ArchiveHealth filteredHealth={policyList.healthList} />
					</CustomAccordion>
					<Separator color={theme.palette.grey[300]} />
					<CustomAccordion accordionIcon={Assets} accordionLabel={t('ASSETS')}>
						<ArchiveAssets filteredAssets={policyList.assetList} />
					</CustomAccordion>
					<Separator color={theme.palette.grey[300]} />
				</Grid>
			)}
		</Grid>
	);
};

export default ArchiveScreen;
