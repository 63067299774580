import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import '../common/OtpScreen.css';
import { CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { KYC_TYPES, OTP_PURPOSE } from '../../../common/Enums';
import {
    fetchOtpRequest,
    initiateEkycRequest,
    selectInitiateEkyc,
    // saveEkycRequest,
    // selectInitiateEkyc,
    selectIsInitiateEkycLoading,
    selectIsOtpRequestLoading,
    selectIsSaveEkycLoading,
} from '../auth.slice';
import OtpDesign from './OtpDesign';
// import { createEkycPopup } from '../../../common/utils';
import config from '../../../common/config';
import { AUTH_ROUTES } from '../../../common/constants/Routes';
import cryptoEncryptionDecryption from '../../../common/crypto';
const EkycOtpScreen = () => {
    const { t } = useTranslation();
    const ekycResponse=useSelector(selectInitiateEkyc)
    const location = useLocation();
    const mobileNo = location.state?.mobileNo;
    const email = location.state?.email;
    const inputType = location.state?.inputType;
    const panType = 'NewPan';
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigation = useNavigate();
    const [otp, setOtp] = useState('');
    const isOtpRequestLoading = useSelector(selectIsOtpRequestLoading);
    const isInitiateEkycLoading = useSelector(selectIsInitiateEkycLoading);
    const isSaveEkycLoading = useSelector(selectIsSaveEkycLoading);
    const [seconds, setSeconds] = useState(config.otpDuration);
    // const kycData = useSelector(selectInitiateEkyc);
    // const handleEkyc = useCallback(
    //  (data: any) => {
    //      dispatch(
    //          saveEkycRequest({
    //              kycId: data?.id,
    //              eiaNo: '',
    //              flag: KYC_TYPES.SIGNUP,
    //              pan: location?.state?.signupData?.Customer?.pan,
    //              gender: location?.state?.signupData?.Customer?.gender,
    //              navigation,
    //          } as any)
    //      );
    //  },
    //  [kycData, location]
    // );

  
	useEffect(() => {
        const customerName = ekycResponse?.customerName ? cryptoEncryptionDecryption.Encrypt(ekycResponse?.customerName) : '';
        window.sessionStorage.setItem(
            'customerName',customerName
        );
    }, [ekycResponse]);
    
    const onVerifyOtpClick = () => {
        // eslint-disable-next-line no-unused-vars
        const handleSuccess = (data: any) => {
            window.sessionStorage.setItem(
                'signupData_pan',
                cryptoEncryptionDecryption.Encrypt(location?.state?.signupData?.Customer?.pan)
            );
            window.sessionStorage.setItem(
                'signupData_gender',
                cryptoEncryptionDecryption.Encrypt(location?.state?.signupData?.Customer?.gender)
            );
            window.sessionStorage.setItem(
                'signupData_whatsappCountryCode',
                cryptoEncryptionDecryption.Encrypt(location?.state?.signupData?.Customer?.whatsappCountryCode)
            );
            window.sessionStorage.setItem(
                'signupData_whatsappNumber',
                cryptoEncryptionDecryption.Encrypt(location?.state?.signupData?.Customer?.whatsappNumber)
            );
            window.sessionStorage.setItem(
                'signupData_customerType',
                cryptoEncryptionDecryption.Encrypt(location?.state?.signupData?.Customer?.flag)
            );
          
            window.location.href = data?.url;
            // createEkycPopup((), () =>
            //  handleEkyc(data)
            // );
        };
        dispatch(
            initiateEkycRequest({
                Customer: {
                    mobileNo,
                    otp,
                    email,
                    flag: KYC_TYPES.SIGNUP,
                    // return_url:`${window.location.origin}${AUTH_ROUTES.SIGNUP_EKYC_REDIRECT}?ekyId=977a05fc-22a5-48dc-b25f-3814d6d54fae`
                    return_url: `${window.location.origin}${AUTH_ROUTES.SIGNUP_KYC_RETURN_URL}`,
                    goback_url: `${window.location.origin}${AUTH_ROUTES.SIGNUP}`,
                },
                navigation,
                handleSuccess,
            } as any)
        );
    };
    const handleResendOtp = () => {
        const handleSuccess = () => {
            setSeconds(config.otpDuration);
        };
        dispatch(
            fetchOtpRequest({
                Customer: {
                    value: email || mobileNo,
                    otpPurpose: OTP_PURPOSE.SIGNUP,
                    flag: email ? 'EMAIL' : 'MOBILE',
                    eiaNo: '',
                },
                handleSuccess,
                navigation,
            } as any)
        );
    };
    return isSaveEkycLoading ? (
        <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingTop={10}
        >
            <Grid item>
                <CircularProgress />
            </Grid>
            <Grid item marginTop={2}>
                <Typography color={theme.palette.primary.main} component="h4">
                    {`${t('VALIDATING_KYC_PROCESS')}...`}
                </Typography>
            </Grid>
        </Grid>
    ) : (
        <OtpDesign
            handleResendOtp={handleResendOtp}
            onVerifyOtpClick={onVerifyOtpClick}
            isOtpRequestLoading={isOtpRequestLoading}
            isOtpValidationLoading={isInitiateEkycLoading}
            otp={otp}
            setOtp={setOtp}
            mobileNo={mobileNo}
            email={email}
            seconds={seconds}
            panType={panType}
            inputType={inputType}
            setSeconds={setSeconds}
        />
    );
};
export default EkycOtpScreen;
export {}; 