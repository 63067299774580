import React from 'react';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from '../../../../common/icons/right_arrow-icon.svg';
import SvgConverter from '../../../../common/SvgConverter';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/dashboard/calendar_orange_ruppee_icon.svg';
import { convertAmount, getFormatDate } from '../../../../common/utils';

const TrackerHeader = ({
	type = 'Loan',
	date,
	sessionAmount,
	loan,
	startDate,
	sessionId,
}: {
	type?: 'Loan' | 'Session' | undefined;
	date?: any;
	sessionAmount?: any;
	loan?: any;
	startDate?: any;
	sessionId?: any;
}) => {
	const theme = useTheme();

	const { t } = useTranslation();

	const Labels: any = {
		Loan: 'LOAN_TRACKER',
		Session: 'SESSION_TRACKER',
	};

	return (
		<Grid
			container
			justifyContent="space-between"
			spacing={1}
			flexWrap="nowrap"
			alignItems="baseline"
			className="dashboard-policy-card-header"
			px={2}
			pt={2}
		>
			<Grid
				item
				container
				spacing={2}
				flexWrap="nowrap"
				alignItems="flex-end"
				className="h-100"
			>
				<Grid item>
					{type === 'Session' && (
						<Typography fontSize={12} color={theme.palette.common.black}>
							{t('NEED_MONEY')}
						</Typography>
					)}
					<Typography
						className="pb-1"
						fontSize={16}
						color={theme.palette.primary.main}
						fontWeight={600}
					>
						{t(Labels[type])}
					</Typography>
					<Typography fontSize={12} color={theme.palette.grey[400]}>
						{type === 'Session'
							? `${t('SESSION_START_DATE')} ${getFormatDate(
									startDate,
									'DD-MM-YYYY'
							  )}`
							: `${t('LOAN_START_DATE')} ${getFormatDate(
									startDate,
									'YYYY-MM-DD'
							  )}`}
					</Typography>
					{type === 'Session' && (
						<>
							<Grid item xs={12} mt={1}>
								<LabelWithIcon
									svgClassName=""
									justifyContent="flex-start"
									Icon={RupeeIcon}
									label={convertAmount(sessionAmount)}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 16,
										fontWeight: 800,
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography
									fontSize={14}
									color={theme.palette.common.black}
									className="ml-4"
								>
									{t('BY')} {getFormatDate(date, 'YYYY-MM-DD')}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>

			<Grid item className="pb-5">
				<Link
					to={
						type === 'Session'
							? APP_ROUTES.TRACK_TRANSACTION
							: APP_ROUTES.POLICY_DETAILS_LIFE
					}
					onClick={() => {
						localStorage.setItem('sessionDate', startDate);
						localStorage.setItem('sessionId', sessionId);
					}}
					state={
						type === 'Session' ? { sessionId, sessionDate: startDate } : loan
					}
				>
					<IconButton className="p-0 ">
						<SvgConverter
							Icon={RightArrowIcon}
							className="d-flex align-items-end next-arrow"
						/>
					</IconButton>
				</Link>
			</Grid>
		</Grid>
	);
};

export default TrackerHeader;
