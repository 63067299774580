import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
	HandleAxiosRequest,
	HandleAxiosResponse,
	HandleAxiosResponseWithError,
} from './interceptors';
// TODO: add headers
const selectedLang = localStorage.getItem('i18nextLng');
const axiosAuthConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_AUTH_API_URL,
	headers: {
		Authorization: `Basic ${process.env.REACT_APP_OAUTH_BEARER_TOKEN}`,
		'Content-Type': 'application/json',
		'lang-id': selectedLang,
		'x-oauth-usage': 'STERLING',
	},
	method: 'POST',
};
const axiosConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_URL,
	method: 'POST',
};
const axiosAuthInstance: AxiosInstance = axios.create(axiosAuthConfig);
const axiosInstance: AxiosInstance = axios.create(axiosConfig);
axiosInstance.interceptors.request.use(HandleAxiosRequest, (error) =>
	// Do something with request error
	Promise.reject(error)
);
axiosInstance.interceptors.response.use(
	HandleAxiosResponse,
	HandleAxiosResponseWithError
);
export { axiosAuthInstance, axiosInstance };
