import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import Separator from '../../common/ui/Separator';
import { APP_ROUTES } from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import { ReactComponent as MenuIcon } from '../../common/icons/menu.svg';
import { ReactComponent as BellIcon } from '../../common/icons/bell.svg';
import { ReactComponent as plusIcon } from '../../common/icons/dashboard/add.svg';
import { ReactComponent as BemaCentral } from '../../assets/images/BimaLogo.svg';
import {
	DashboardList,
	selectIsDashboardLoading,
} from '../../features/App/DashboardScreen/Dashboard.slice';

import DashboardProfileCard from '../../common/ui/DashboardProfileCard';
import ProfilePic from '../../common/icons/profile/profile-icon.svg';
import { NavbarModal } from './NavbarModal';

const TopHeader = ({
	setDrawerOpen = () => { },
	setNotificationDrawer = () => { },
	contentHeight = 0,
}: {
	setDrawerOpen?: Function;
	setNotificationDrawer?: Function;
	contentHeight?: number | undefined;
}) => {
	const theme = useTheme();

	const dashboardListDatas = useSelector(DashboardList);
	const customerData = dashboardListDatas?.Customer;
	const dashboardLoading = useSelector(selectIsDashboardLoading);
	const profileReadinessVal =
		customerData?.profileReadiness &&
		customerData?.profileReadiness.replace(/\s/g, '');

	const [open, setOpen] = useState(false);

	return (
		<Grid container className="dashBoard-header-menu ">
			<Grid item className="mr-10 ml-15">
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={() => setDrawerOpen(true)}
					edge="start"
				>
					<SvgConverter Icon={MenuIcon} />
				</IconButton>
			</Grid>
			<Grid item flex={1} className="mr-30">
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item>
						<Grid container alignItems="center">
							<Grid item>
								{' '}
								<SvgConverter
									Icon={BemaCentral}
									width={155}
									height={27}
									className="mt-5 mb-10"
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item className="dashboard-notification-bell-icon1 ">
						<IconButton
							onClick={() => setOpen(true)}
						>
							<SvgConverter Icon={plusIcon} />
						</IconButton>
						<IconButton onClick={() => setNotificationDrawer(true)}>
							<SvgConverter Icon={BellIcon} />
						</IconButton>
					</Grid>
					<Separator
						color={theme.palette.primary.main}
						className="profile-res1 "
					/>
					<Grid item>
						<Grid container alignItems="center">
							<Grid item className="dashboard-notification-bell-icon">
								<IconButton
									onClick={() => setOpen(true)}
									className='mr-5'
								>
									<SvgConverter Icon={plusIcon} />
								</IconButton>
								<IconButton onClick={() => setNotificationDrawer(true)}>
									<SvgConverter Icon={BellIcon} />
								</IconButton>
							</Grid>
							<Grid
								item
								sx={{ height: contentHeight - 40 }}
								className="mx-16 profile-res"
							>
								<Separator
									color={theme.palette.secondary.main}
									orientation="vertical"
									borderWidth={1}
								/>
							</Grid>
							<Grid item>
								<div className="py-8">
									<Link to={APP_ROUTES.PROFILE}>
										<DashboardProfileCard
											profileImg={
												customerData?.profileImg
													? `data:image;base64,${customerData?.profileImg}`
													: ProfilePic
											}
											customerName={customerData?.customerName}
											eiaNum={customerData?.eiaNo}
											profileReadiness={
												customerData?.profileReadiness
													? profileReadinessVal
													: '-'
											}
											dashboardLoading={dashboardLoading}
										/>
									</Link>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Separator color={theme.palette.primary.main} />
			</Grid>
			<NavbarModal open={open} setOpen={setOpen} />
		</Grid>
	);
};

export default TopHeader;
