import React from 'react';
import { Grid, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../Dashboard.scss';
import theme from '../../../../../common/theme';
import LinearBar from '../../../../../common/ui/ProgressBar/LinearBar';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../../../common/icons/dashboard/calendar_orange_ruppee_icon.svg';
import { convertAmount } from '../../../../../common/utils';

interface UserProps {
	loan?: any;
}
const LoanProgressbar = ({ loan }: UserProps) => {
	// eslint-disable-next-line no-unsafe-optional-chaining

	const { t } = useTranslation();

	const progressVal =
		(loan?.Surrender?.surrenderValue ?? 1) / (loan?.maturityValue ?? 1);
	const clampProgress = Math.max(0, Math.min(1, progressVal));
	const surrenderMoving =
		clampProgress * 100 >= 40
			? `${clampProgress * 100 - 35}%`
			: `${clampProgress * 100 + 2}%`;
	const outstandingAmountProgressVal =
		(loan?.outstandingAmount ?? 1) / (loan?.totalRepaymentAmount ?? 1);
	const outStandingClamProgress = Math.max(
		0,
		Math.min(1, outstandingAmountProgressVal)
	);
	const outStandingMoving =
		outStandingClamProgress * 100 >= 50
			? `${outStandingClamProgress * 100 - 44}%`
			: `${outStandingClamProgress * 100 + 3}%`;
	return (
		<Grid container px={2} py={4} sx={{ background: 'rgb(242 242 242)' }}>
			<div className="w-280px">
				<Grid sx={{ position: 'relative' }}>
					<Grid
						sx={{
							position: 'absolute',
							bottom: '15px',
							left: surrenderMoving,
							textWrap: 'nowrap',
						}}
					>
						<Typography color={theme.palette.primary.main} className="f-12">
							{t('SURRENDER_VALUE')}
						</Typography>
						<LabelWithIcon
							svgClassName=""
							justifyContent="end"
							Icon={RupeeIcon}
							label={convertAmount(loan?.Surrender?.surrenderValue)}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
					</Grid>
					<Grid
						style={{
							position: 'absolute',
							left: `${clampProgress * 100}%`,
							bottom: '0',
						}}
					>
						<div
							style={{
								position: 'absolute',
								border: '1px solid rgba(0, 81, 186, 0.99)',
								background: ' rgba(0, 81, 186, 0.99)',
								height: '15px',
								width: '3px',
								left: '-3px',
							}}
						/>
						<Divider
							orientation="vertical"
							sx={{
								height: '50px',
								backgroundColor: '#0051ba',
								width: '1px',
							}}
						/>
					</Grid>
					<Grid
						sx={{
							position: 'absolute',
							bottom: '15px',
							left: '68%',
							textWrap: 'nowrap',
						}}
						// className="loan-tracker-maturity"
					>
						<Typography color={theme.palette.grey[500]} className="f-12">
							{t('MATURITY_VALUE')}
						</Typography>
						<LabelWithIcon
							svgClassName=""
							justifyContent="end"
							Icon={RupeeIcon}
							label={convertAmount(loan?.maturityValue)}
							labelProps={{
								color: theme.palette.grey[500],
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
					</Grid>

					<Grid style={{ position: 'absolute', left: `100%`, bottom: '0' }}>
						<div
							style={{
								position: 'absolute',
								background: '#9e9e9e',
								height: '15px',
								width: '3px',
								left: '-3px',
							}}
						/>
						<Divider
							color={theme.palette.grey[500]}
							orientation="vertical"
							sx={{
								height: '50px',
								width: '1px',
							}}
						/>
					</Grid>
					<Grid sx={{ marginTop: '65px' }}>
						<LinearBar progressVal={progressVal} />
					</Grid>
				</Grid>

				<Grid sx={{ position: 'relative' }}>
					<Grid sx={{ marginTop: '15px', marginBottom: '80px' }}>
						<LinearBar
							progressVal={outstandingAmountProgressVal}
							color="error"
						/>
					</Grid>
					<Grid
						sx={{
							position: 'absolute',
							top: '25px',
							left: outStandingMoving,
							textWrap: 'nowrap',
						}}
					>
						<Typography
							color={theme.palette.primary.main}
							sx={{ textWrap: 'wrap' }}
							className="f-12"
						>
							{t('OUTSTANDING_AMOUNT')}
						</Typography>
						<LabelWithIcon
							svgClassName=""
							justifyContent="flex-start"
							Icon={RupeeIcon}
							label={convertAmount(loan?.outstandingAmount)}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
						<Typography color={theme.palette.grey[500]} ml={1} className="f-12">
							{`[${t('LOAN')} + ${t('INTEREST')}]`}
						</Typography>
					</Grid>
					<Grid
						style={{
							position: 'absolute',
							top: '5px',
							left: `${outStandingClamProgress * 100}%`,
						}}
					>
						<Divider
							orientation="vertical"
							color={theme.palette.error.dark}
							sx={{
								height: '20px',
								width: '1px',
							}}
						/>
						<div
							style={{
								position: 'absolute',
								background: '#d53435',
								height: '18px',
								width: '4px',
							}}
						/>
					</Grid>
				</Grid>
			</div>
		</Grid>
	);
};
export default LoanProgressbar;
