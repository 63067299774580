/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { PolicyEmail } from '../Profile/types/Profile.model';
import { RootState } from '../../../common/store';
import { LoadingState } from '../../../common';
import { MOBILE_EMAIL_CHANGE_ACTIONS } from '../../../common/Enums';
import {
	removeDuplicatedPoliciesForAssign,
	swapToTop,
} from '../../../common/utils';

interface EmailsState {
	policies: any;
	isLoading: LoadingState;
	errors: any | null;
	isEmailsLoading: LoadingState;
	errorEmails: any;
	removeEmailChanges: any;
	generateOtpForEmail: { status: LoadingState; data: any; error: any };
	verifyEmail: { status: LoadingState; data: any; error: any };
	updateEmailChanges: { status: LoadingState; data: any; error: any };
	emailChangesList: any[];
}

const initialState: EmailsState = {
	isLoading: 'idle',
	policies: { emailBasedPolicies: [], unAssignedEmails: [] },
	errors: null,
	isEmailsLoading: 'idle',
	errorEmails: null,
	removeEmailChanges: null,
	generateOtpForEmail: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	verifyEmail: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	updateEmailChanges: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	emailChangesList: [],
};

const emailsSlice = createSlice({
	name: 'emails',
	initialState,
	reducers: {
		fetchPoliciesForEmails: (state) => ({
			...state,
			isLoading: 'loading',
			errors: null,
			policies: { emailBasedPolicies: [], unAssignedEmails: [] },
		}),
		loadPoliciesForEmails: (state, { payload }) => ({
			...state,
			isLoading: 'done',
			errors: null,
			policies: {
				emailBasedPolicies: payload.EmailBasedPolicies.map((policy: any) => ({
					...policy,
					email: policy.email.toLowerCase(),
				})),
				unAssignedEmails: payload.UnassignedEmails.map((emailObj: any) => ({
					...emailObj,
					email: emailObj.email.toLowerCase(),
				})),
			},
		}),
		errorLoadingPoliciesForEmails: (state, action) => ({
			...state,
			isLoading: 'done',
			errors: action.payload,
			policies: { emailBasedPolicies: [], unAssignedEmails: [] },
		}),
		generateOtpForEmailRequest: (state) => {
			state.generateOtpForEmail.status = 'loading';
			state.generateOtpForEmail.data = undefined;
			state.generateOtpForEmail.error = undefined;
		},
		generateOtpForEmailSuccess: (state, { payload }) => {
			state.generateOtpForEmail.status = 'done';
			state.generateOtpForEmail.data = payload;
			state.generateOtpForEmail.error = undefined;
		},
		generateOtpForEmailError: (state, { payload }) => {
			state.generateOtpForEmail.status = 'error';
			state.generateOtpForEmail.data = undefined;
			state.generateOtpForEmail.error = payload;
		},
		verifyEmailRequest: (state) => {
			state.verifyEmail.status = 'loading';
			state.verifyEmail.data = undefined;
			state.verifyEmail.error = undefined;
		},
		verifyEmailSuccess: (state, { payload }) => {
			state.verifyEmail.status = 'done';
			state.verifyEmail.data = payload;
			state.verifyEmail.error = undefined;
		},
		verifyEmailError: (state, { payload }) => {
			state.verifyEmail.status = 'error';
			state.verifyEmail.data = undefined;
			state.verifyEmail.error = payload;
		},
		updateEmailChangesRequest: (state) => {
			state.updateEmailChanges.status = 'loading';
			state.updateEmailChanges.data = undefined;
			state.updateEmailChanges.error = undefined;
		},
		updateEmailChangesSuccess: (state, { payload }) => {
			state.updateEmailChanges.status = 'done';
			state.updateEmailChanges.data = payload;
			state.updateEmailChanges.error = undefined;
			state.emailChangesList = [];
		},
		updateEmailChangesError: (state, { payload }) => {
			state.updateEmailChanges.status = 'error';
			state.updateEmailChanges.data = undefined;
			state.updateEmailChanges.error = payload;
		},
		changeEmail: (state, action) => {
			const { selectedPolicy, targetEmail: targetedEmail } = action.payload;
			if (!targetedEmail) {
				return;
			}
			const targetEmail = targetedEmail.toLowerCase();
			const targetedEmailPolicy = state.policies.emailBasedPolicies.find(
				(policy: any) => policy.email.toLowerCase() === targetEmail
			);
			let policies = [];
			let processedSelectedPolicy = null;
			let apiPolicyDetails = [];

			if (selectedPolicy.type === 'primaryEmailChange') {
				const otherPolicies = state.policies.emailBasedPolicies.filter(
					(policy: any) => policy.email.toLowerCase() !== targetEmail
				);
				const targetEmailBasedPolicies = state.policies.emailBasedPolicies
					.filter(({ email }: any) => email.toLowerCase() === targetEmail)
					.map((policy: any) => ({ ...policy, primaryFlag: 'Y' }));

				let unassignedUpdated = state.policies.unAssignedEmails.filter(
					({ email }: any) =>
						email.toLowerCase() !== selectedPolicy.email.toLowerCase()
				);

				if (!targetEmailBasedPolicies.length) {
					unassignedUpdated = unassignedUpdated.map((mobile: any): any => {
						if (mobile.email.toLowerCase() === targetEmail) {
							return {
								...mobile,
								primaryFlag: 'Y',
							};
						}
						return mobile;
					});
				}

				state.policies.unAssignedEmails = unassignedUpdated;
				policies = [...targetEmailBasedPolicies, ...otherPolicies];
				apiPolicyDetails = targetEmailBasedPolicies;
			} else {
				let primaryFlag = 'Z';
				if (!targetedEmailPolicy) {
					// if the target number have no policies it is unassigned.
					const unAssignedNumber = state.policies.unAssignedEmails.find(
						(unAssignedEmail: any) =>
							unAssignedEmail.email.toLowerCase() === targetEmail
					);
					primaryFlag = unAssignedNumber?.primaryFlag ?? 'Z';
				} else {
					primaryFlag = targetedEmailPolicy?.primaryFlag ?? 'Z';
				}

				processedSelectedPolicy = {
					...selectedPolicy,
					primaryFlag,
				};
				policies = changePolicyEmail(
					state.policies.emailBasedPolicies,
					processedSelectedPolicy,
					targetEmail
				);
				const { email } = processedSelectedPolicy;
				const isEmailHavePolicy = policies.some(
					(policy: any) => policy.email.toLowerCase() === email.toLowerCase()
				);
				if (
					!isEmailHavePolicy &&
					processedSelectedPolicy?.email.toLowerCase() != 'null' &&
					!state.policies.unAssignedEmails.find(
						(unAssignedEmail: any) =>
							unAssignedEmail.email.toLowerCase() === email.toLowerCase()
					)
				) {
					state.policies.unAssignedEmails = [
						...state.policies.unAssignedEmails,
						{
							email: email.toLowerCase(),
							emailVerified: 'Y',
							primaryFlag: selectedPolicy.primaryFlag,
						},
					];
				}
				apiPolicyDetails = [processedSelectedPolicy];
			}

			state.policies.emailBasedPolicies = policies;

			if (selectedPolicy?.type !== 'primaryEmailChange') {
				const changes = generateEmailChangesForAPI(
					apiPolicyDetails,
					targetEmail.toLowerCase(),
					selectedPolicy.email.toLowerCase(),
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
				);
				state.emailChangesList = [...state.emailChangesList, changes];
				state.policies.emailBasedPolicies = swapToTop(
					state.policies.emailBasedPolicies,
					changes.Policies.map(({ policyNo }) => policyNo),
					'policyNo'
				);
			} else {
				const removeChanges = generateEmailChangesForAPI(
					apiPolicyDetails,
					targetEmail,
					selectedPolicy.email,
					MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE,
					apiPolicyDetails.length ? undefined : 'Y'
				);
				state.emailChangesList = [...state.emailChangesList, removeChanges];
			}
		},
		changePrimaryEmail: (state, action) => {
			const {
				selectedEmail,
				targetedEmail,
				isNew = false,
				newEmail = false,
			} = action.payload;
			const isSelectedUnAssigned = state.policies.unAssignedEmails.find(
				({ email }: any) => email === selectedEmail
			);
			const isTargetUnAssigned = state.policies.unAssignedEmails.find(
				({ email }: any) => email === targetedEmail
			);
			const { unAssignedEmails } = state.policies;
			let changes: any = [];
			if (isNew && isSelectedUnAssigned) {
				changes = generateEmailChangesForAPI(
					[],
					targetedEmail,
					selectedEmail,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const unAssignedUpdated = state.policies.unAssignedEmails.map(
					(email: { email: any; primaryFlag: string }) => {
						if (email.email === selectedEmail) {
							email.primaryFlag = 'N';
						}
						return email;
					}
				);
				state.policies.unAssignedEmails = [
					...unAssignedUpdated,
					{
						email: targetedEmail,
						primaryFlag: 'Y',
						emailVerified: 'Y',
					},
				];
			}
			if (isNew && !isSelectedUnAssigned) {
				const selectedPolicies = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email === selectedEmail
				);
				const targetedPolices = selectedPolicies.map((policy: any) => ({
					...policy,
					primaryFlag: 'Y',
					email: targetedEmail,
				}));

				changes = generateEmailChangesForAPI(
					targetedPolices,
					targetedEmail,
					selectedEmail,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const otherPolicies = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email !== selectedEmail
				);
				state.policies.emailBasedPolicies = [
					...targetedPolices,
					...otherPolicies,
				];
				const unAssignedUpdated = state.policies.unAssignedEmails.filter(
					({ email }: any) => email !== targetedEmail
				);
				state.policies.unAssignedEmails = [
					...unAssignedUpdated,
					{
						email: selectedEmail,
						primaryFlag: newEmail ? 'Y' : 'N',
						emailVerified: 'Y',
					},
				];
			}
			if (isSelectedUnAssigned && isTargetUnAssigned && !isNew) {
				unAssignedEmails.map((policy: any) => {
					if (policy.email === targetedEmail) {
						policy.primaryFlag = 'Y';
					}
					if (policy.email === selectedEmail) {
						policy.primaryFlag = 'N';
					}
					return policy;
				});
				changes = generateEmailChangesForAPI(
					[],
					targetedEmail,
					selectedEmail,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const unAssignedUpdated = state.policies.unAssignedEmails.map(
					(email: any) => {
						if (email.email === targetedEmail) {
							email.primaryFlag = 'Y';
						}
						if (email.email === selectedEmail) {
							email.primaryFlag = 'N';
						}
						return email;
					}
				);
				state.policies.unAssignedEmails = unAssignedUpdated;
			}
			if (!isSelectedUnAssigned && isTargetUnAssigned && !isNew) {
				const selectedPolicies = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email === selectedEmail
				);
				const targetedPolices = selectedPolicies.map((policy: any) => ({
					...policy,
					primaryFlag: 'Y',
					email: targetedEmail,
				}));

				changes = generateEmailChangesForAPI(
					targetedPolices,
					targetedEmail,
					selectedEmail,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const otherPolicies = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email !== selectedEmail
				);
				state.policies.emailBasedPolicies = [
					...targetedPolices,
					...otherPolicies,
				];
				const unAssignedUpdated = state.policies.unAssignedEmails.filter(
					({ email }: any) => email !== targetedEmail
				);
				state.policies.unAssignedEmails = [
					...unAssignedUpdated,
					{
						email: selectedEmail,
						primaryFlag: 'N',
						emailVerified: 'Y',
					},
				];
			}
			if (isSelectedUnAssigned && !isTargetUnAssigned && !isNew) {
				const targetedNumberPolicies = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email === targetedEmail
				);

				const targetedPolicies = targetedNumberPolicies.map((policy: any) => ({
					...policy,
					primaryFlag: 'Y',
				}));
				changes = generateEmailChangesForAPI(
					targetedPolicies,
					targetedEmail,
					selectedEmail,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);
				const otherPolicies = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email !== targetedEmail
				);
				state.policies.emailBasedPolicies = [
					...targetedPolicies,
					...otherPolicies,
				];
				const unAssignedUpdated = state.policies.unAssignedEmails.map(
					(email: any) => {
						if (email.email === selectedEmail) {
							return {
								...email,
								primaryFlag: 'N',
							};
						}
						return email;
					}
				);
				state.policies.unAssignedEmails = unAssignedUpdated;
			}
			if (!isSelectedUnAssigned && !isTargetUnAssigned && !isNew) {
				const selectedPolicies = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email === selectedEmail
				);
				const targetedPolices = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email === targetedEmail
				);
				const totalTargetedPolicies = [
					...targetedPolices,
					...selectedPolicies,
				].map((policy) => ({
					...policy,
					primaryFlag: 'Y',
					email: targetedEmail,
				}));

				const otherPolices = state.policies.emailBasedPolicies.filter(
					({ email }: any) => ![selectedEmail, targetedEmail].includes(email)
				);
				state.policies.emailBasedPolicies = [
					...totalTargetedPolicies,
					...otherPolices,
				];
				changes = generateEmailChangesForAPI(
					selectedPolicies,
					targetedEmail,
					selectedEmail,
					MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE,
					'Y'
				);

				const { unAssignedEmails } = state.policies;
				state.policies.unAssignedEmails = [
					...unAssignedEmails,
					{
						email: selectedEmail,
						primaryFlag: 'N',
						emailVerified: 'Y',
					},
				];
			}
			state.emailChangesList = [...state.emailChangesList, changes];
		},
		removeEmail: (state, action) => {
			const { newValue, oldValue, handleDone, isUnAssignedEmail } =
				action.payload;
			let changes: any = [];
			if (!isUnAssignedEmail) {
				const selectedOldEmail = state.policies.emailBasedPolicies.find(
					({ email }: any) => email.toLowerCase() === oldValue.toLowerCase()
				);
				const affectedPolicies = state.policies.emailBasedPolicies.filter(
					({ email }: any) => email.toLowerCase() === oldValue.toLowerCase()
				);
				const isUnAssignedEmailSelected = state.policies.unAssignedEmails.find(
					({ email }: any) => email.toLowerCase() === newValue.toLowerCase()
				);

				let policies = [];

				if (!isUnAssignedEmailSelected) {
					policies = state.policies.emailBasedPolicies.map((policy: any) => {
						if (policy.email === oldValue) {
							policy.email = newValue.toLowerCase();
							policy.primaryFlag = selectedOldEmail?.primaryFlag ?? 'Z';
						}
						return policy;
					});
				} else {
					policies = state.policies.emailBasedPolicies.map((policy: any) => {
						if (policy.email.toLowerCase() === oldValue.toLowerCase()) {
							policy.email = newValue.toLowerCase();
							policy.primaryFlag =
								selectedOldEmail?.primaryFlag === 'Y'
									? 'Y'
									: isUnAssignedEmailSelected?.primaryFlag ?? 'Z';
						}
						return policy;
					});
				}

				state.policies.emailBasedPolicies = policies;
				changes = generateEmailChangesForAPI(
					affectedPolicies,
					newValue,
					oldValue,
					MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
				);
				state.policies.unAssignedEmails = [
					...state.policies.unAssignedEmails,
					{
						email: oldValue.toLowerCase(),
						emailVerified: 'Y',
						primaryFlag: 'Z'
					},
				];
			} else {
				changes = generateEmailChangesForAPI(
					[],
					newValue,
					oldValue,
					MOBILE_EMAIL_CHANGE_ACTIONS.REMOVE
				);
				state.policies.unAssignedEmails = removeEmailFromUnAssignedEmails(
					state.policies.unAssignedEmails,
					oldValue
				);
			}

			state.emailChangesList = [...state.emailChangesList, changes];
			if (handleDone) {
				handleDone();
			}
		},
		assignEmail: (state, action) => {
			const {
				newEmail,
				selectedPolicies,
				handleDone,
				isOld,
				primaryFlag,
				purpose,
			} = action.payload;
			let policies = [];
			if (selectedPolicies?.length > 0) {
				const getOldEmails = R.pipe(
					R.uniqBy(R.prop('email')),
					R.map(({ email, verifiedFlag }: any) => ({
						email: email.toLowerCase(),
						emailVerified: verifiedFlag,
					}))
				);
				const oldEmails = getOldEmails(
					selectedPolicies.filter((policy: any) => policy.email.toLowerCase())
				);
				const oldEmailPolicyInfo = oldEmails.map((emailPolicy) => {
					const existingPolicy = state.policies.emailBasedPolicies.find(
						(policy: any) =>
							policy.email.toLowerCase() === emailPolicy.email.toLowerCase()
					);

					const existingUnAssigned = state.policies.unAssignedEmails?.find(
						(emailSelected: any) =>
							emailSelected.email.toLowerCase() ===
							emailPolicy.email.toLowerCase()
					);
					return {
						...emailPolicy,
						primaryFlag: existingPolicy
							? existingPolicy.primaryFlag
							: existingUnAssigned?.primaryFlag ?? 'Z',
					};
				});
				policies = assignPolicyEmail(selectedPolicies, newEmail, primaryFlag);
				const duplicateRemovedCurrentList = removeDuplicatedPoliciesForAssign(
					state.policies.emailBasedPolicies,
					selectedPolicies
				);
				state.policies.emailBasedPolicies = [
					...duplicateRemovedCurrentList,
					...policies,
				];
				const unassignedEmails = oldEmailPolicyInfo
					.filter(
						(email) =>
							!state.policies.emailBasedPolicies.some(
								(policy: any) =>
									policy?.email.toLowerCase() === email.email.toLowerCase()
							)
					)
					.map((email) => ({ ...email }));

				if (unassignedEmails.length > 0) {
					state.policies.unAssignedEmails = R.pipe(R.uniqBy(R.prop('email')))([
						...state.policies.unAssignedEmails,
						...unassignedEmails,
					]);
				}
			} else if (!isOld) {
				state.policies.unAssignedEmails = [
					...state.policies.unAssignedEmails,
					{
						email: newEmail.toLowerCase(),
						emailVerified: 'Y',
						primaryFlag: "Z"
					},
				];
				state.policies.unAssignedEmails = swapToTop(
					state.policies.unAssignedEmails,
					[newEmail.toLowerCase()],
					'email'
				);
			} else {
				if (handleDone) {
					handleDone();
				}
				return state;
			}
			if (policies?.length !== 0) {
				const changes: any = [];
				policies.map((values) => {
					const policiesChange = generateEmailChangesForAPI(
						[values],
						newEmail.toLowerCase(),
						'null',
						MOBILE_EMAIL_CHANGE_ACTIONS.ADD,
						undefined,
						purpose
					);
					changes.push(policiesChange);
				});

				state.emailChangesList = [...state.emailChangesList, ...changes];
				state.policies.emailBasedPolicies = swapToTop(
					state.policies.emailBasedPolicies,
					changes.map(({ Policies }: any) => {
						return Policies[0].policyNo;
					}),
					'policyNo'
				);
			} else {
				const changes = generateEmailChangesForAPI(
					policies,
					newEmail.toLowerCase(),
					'null',
					MOBILE_EMAIL_CHANGE_ACTIONS.ADD,
					undefined
				);
				state.emailChangesList = [...state.emailChangesList, changes];
				state.policies.emailBasedPolicies = swapToTop(
					state.policies.emailBasedPolicies,
					changes.Policies.map(({ policyNo }) => policyNo),
					'policyNo'
				);
			}

			if (handleDone) {
				handleDone();
			}
		},
		setEmailChangesList: (state, { payload }) => {
			state.emailChangesList = payload || [];
		},
	},
});

export const {
	fetchPoliciesForEmails,
	loadPoliciesForEmails,
	errorLoadingPoliciesForEmails,
	generateOtpForEmailRequest,
	generateOtpForEmailSuccess,
	generateOtpForEmailError,
	verifyEmailRequest,
	verifyEmailSuccess,
	verifyEmailError,
	updateEmailChangesRequest,
	updateEmailChangesSuccess,
	updateEmailChangesError,
	changeEmail,
	removeEmail,
	assignEmail,
	setEmailChangesList,
	changePrimaryEmail,
} = emailsSlice.actions;

const changePolicyEmail = (
	policies: PolicyEmail[],
	selectedPolicy: any,
	targetEmail: string
) => {
	const selectedPolicyIndex = policies.findIndex(
		({ email, policyNo }: any) =>
			email === selectedPolicy.email && policyNo === selectedPolicy.policyNo
	);
	if (selectedPolicyIndex >= 0) {
		policies[selectedPolicyIndex].email = targetEmail;
		policies[selectedPolicyIndex].emailVerified = 'Y';
		policies[selectedPolicyIndex].primaryFlag = selectedPolicy.primaryFlag;
	}
	return policies;
};

// const mapAssignPolicy =
// 	(newValue: string, oldValue: string) => (policy: PolicyEmail) => {
// 		if (policy.email === oldValue) {
// 			policy.email = newValue;
// 		}
// 		return policy;
// 	};

// const assignPolicyToOtherEmail = (
// 	policies: PolicyEmail[],
// 	newValue: string,
// 	oldValue: string
// ) => policies.map(mapAssignPolicy(newValue, oldValue));

const generateEmailChangesForAPI = (
	policies: PolicyEmail[],
	newValue: string,
	oldValue: string,
	action: string,
	primaryFlag: undefined | 'Y' = undefined,
	purpose?: string
) => {
	const policiesForPayload: any[] = policies.map(
		({ companyCode, policyNo, alternateCode, oldEmail }: any) => ({
			insurerCode: companyCode,
			policyNo,
			alternateCode,
			oldEmail,
		})
	);

	return {
		action:
			oldValue == 'null'
				? policiesForPayload.length === 0
					? MOBILE_EMAIL_CHANGE_ACTIONS.ADD
					: policiesForPayload?.[0]?.oldEmail === '' ||
						policiesForPayload?.[0]?.oldEmail === undefined ||
						policiesForPayload?.[0]?.oldEmail === null
						? MOBILE_EMAIL_CHANGE_ACTIONS.ADD
						: MOBILE_EMAIL_CHANGE_ACTIONS.CHANGE
				: action,
		newValue: newValue || '',
		oldValue:
			oldValue === 'null'
				? policiesForPayload?.[0]?.oldEmail
					? policiesForPayload?.[0]?.oldEmail
					: ''
				: oldValue,
		Policies: policiesForPayload,
		primaryFlag: primaryFlag || 'Z',
		purpose: purpose || '',
	};
};

const removeEmailFromUnAssignedEmails = (
	policies: any[],
	targetEmail: string
) =>
	R.reject(
		({ email }) => email.toLowerCase() === targetEmail.toLowerCase(),
		policies
	);

const assignPolicyEmail = (
	selectedPolicies: any[],
	newEmail: string,
	primaryFlag = 'Z'
) => {
	selectedPolicies = selectedPolicies.map((row: any) => ({
		...row,
		email: newEmail,
		emailVerified: 'Y',
		primaryFlag,
		oldEmail: row?.email,
	}));

	return selectedPolicies;
};

export const selectIsPolicyEmailsLoading = (state: RootState) =>
	state.emails.isLoading === 'loading';

export const selectPoliciesForEmails = (state: RootState) =>
	state.emails.policies;

export const selectErrorLoadingPolicies = (state: RootState) =>
	state.emails.errors;

export const selectIsEmailsLoading = (state: RootState) =>
	state.emails.isLoading === 'loading';

export const selectErrorLoadingEmails = (state: RootState) =>
	state.emails.errorEmails;

export const isGenerateOtpForEmailLoading = (state: RootState) =>
	state.emails.generateOtpForEmail.status === 'loading';

export const isUpdateEmailLoading = (state: RootState) =>
	state.emails.updateEmailChanges.status === 'loading';

export const selectIsEmailVerifyLoading = (state: RootState) =>
	state.emails.verifyEmail.status === 'loading';

export const selectEmailChangesList = (state: RootState) =>
	state.emails.emailChangesList;

export default emailsSlice.reducer;

export const getEmailList = (
	selectedPolicyDetails: any,
	emailBasedPolicies: any[],
	unAssignedEmails: any[]
) => {
	let data: any[] = R.reject(
		({ email }: any) =>
			email === selectedPolicyDetails?.email || email == 'null',
		emailBasedPolicies
	);
	let datas: any[] = R.reject(
		({ email }: any) =>
			email === selectedPolicyDetails?.email || email == 'null',
		unAssignedEmails || []
	);
	data = [...data, ...datas];
	data = data ? data.map(({ email }) => email) : [];
	return R.uniq(data);
};
