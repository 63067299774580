/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import '../TrackTransaction.scss';

import StepTwo from './Box/StepTwo';
import StepThree from './Box/StepThree';
import StepOne from './Box/StepOne';

interface UserProps {
	step?: any;
	widgetNo?: any;
	trackTransactionWholeData?: any;
}

const TransactionCard: React.FC<UserProps> = ({ step, widgetNo, trackTransactionWholeData }) => (
	<>
		<Box>
			<Grid container>
				{step?.widgetType === 'S' && (
					<StepOne step={step} widgetNo={widgetNo} />
				)}
				{step?.widgetType === 'W' && (
					<StepTwo step={step} widgetNo={widgetNo} />
				)}
				{step?.widgetType === 'L' && (
					<StepThree step={step} widgetNo={widgetNo} trackTransactionWholeData={trackTransactionWholeData} />
				)}
			</Grid>
		</Box>
	</>
);

export default TransactionCard;
