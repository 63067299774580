import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PaymentSuccess from './PaymentSuccess';
import {
	selectIsUploadPaymentDataLoading,
	selectPaymentDetails,
	uploadPaymentDetailsRequest,
} from '../Payment.slice';
import { PAYMENT_GATEWAY_TYPES } from '../../../../common/Enums';
import PaymentFailed from './PaymentFailed';
import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';

const PaymentFeedbacks = () => {
	const theme = useTheme();
	const location = useLocation();
	const params = useParams();

	const { t } = useTranslation();

	const paymentDetails = useSelector(selectPaymentDetails);
	const dispatch = useDispatch();
	const isUploadingPaymentData = useSelector(selectIsUploadPaymentDataLoading);
	// eslint-disable-next-line no-unused-vars
	// const routePolicyData = location?.state?.paymentData?.policyData;
	const type = 'Payment';

	const paymentResponse = useMemo(
		() =>
			location?.state?.paymentResponse
				? location?.state?.paymentResponse
				: false,
		[location]
	);

	const handleRazorPayPaymentUpload = () => {
		const payload = {
			transactionId: paymentDetails?.transactionId,
			rzpOrderId: paymentResponse?.order_id,
			rzpPaymentId: paymentResponse?.razorpay_payment_id,
			rzpDigitalSign: paymentResponse?.razorpay_signature,
		};
		dispatch(uploadPaymentDetailsRequest(payload as any));
	};
	useEffect(() => {
		if (location?.state?.gateWayType === PAYMENT_GATEWAY_TYPES.RAZORPAY) {
			handleRazorPayPaymentUpload();
		}
	}, [location?.state?.gateWayType, location?.state?.status]);

	return (
		<>
			{isUploadingPaymentData ? (
				<Grid
					className="vh-95"
					container
					justifyContent="center"
					alignItems="center"
				>
					<Grid item className="text-center">
						<CircularProgress />
					</Grid>
				</Grid>
			) : (
				<Grid container justifyContent="center">
					<Grid item>
						<div style={{ width: 290 }}>
							<Grid item xs={12} className="mt-10">
								<Grid container justifyContent="space-between">
									<Typography
										fontSize={16}
										fontWeight={600}
										color={theme.palette.primary.main}
									>
										{t('PREMIUM_PAYMENT')}
									</Typography>
									<Link to="/" replace>
										<CustomButton
											className="fw-500 close-style f-14"
											text="Close"
											variant="text"
											color="primary"
											showIcon={false}
											startIcon={Cancel}
										/>
									</Link>
								</Grid>
							</Grid>
							{params?.paymentStatus === 'success' ? (
								<PaymentSuccess
									amount={location?.state?.paymentData?.amount}
									transactionId={location?.state?.paymentData?.transactionId}
									type={type}
									paymentResponse={paymentResponse}
								/>
							) : (
								<PaymentFailed
									amount={location?.state?.paymentData?.amount}
									transactionId={location?.state?.paymentData?.transactionId}
									paymentResponse={paymentResponse}
								/>
							)}
						</div>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default PaymentFeedbacks;
