import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as ArrowLeftIcon } from '../../../../common/icons/left_arrow-icon.svg';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import config from '../../../../common/config';
import data from '../../../../common/constants/FormConstants.json';
import {
	fetchFamilyRelationShipList,
	pinCodeRequest,
	pinMasterRequestLoading,
	selectFamilyRelationShipList,
	selectIsFamilyRelationShipLoading,
	selectUserProfile,
} from '../../Profile/Profile.slice';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import Spinner from '../../../../common/ui/Spinner';
import CustomButton from '../../../../common/ui/CustomButton';
import AuthorizedRepresentativeModalDetails from './AuthorizedRepresentativeModalDetails';
import {
	saveARRequest,
	saveArRequestLoading,
	selectSendCommunicationLoading,
	sendCommunicationRequest,
} from '../../../Auth/auth.slice';
import {
	SEND_COMMUNICATION_FLAG,
	SEND_COMMUNICATION_PURPOSE,
} from '../../../../common/Enums';
import { setAlertInfo } from '../Common.slice';
import {
	ageCalculator,
	countryCode,
	parsePinCode,
} from '../../../../common/utils';
import DatePickerModal from '../../../../common/ui/DatePickerModal';
import NeedSupport from '../../../Auth/common/NeedSupport';
import FormCheckBox from '../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import AddFamilyAddress from '../../Profile/AddFamilyMember/AddFamilyAddress';

const mobileNumberRegex = /^[6789]\d{9,}$/;
const address1Regex = /^[a-zA-Z0-9\s.,'\\/-]+$/;
const ARForm = ({
	handleSkip,
	handleARFlowSuccess,
	eiaNo,
	PermanentAddress,
	showAlert = false,
}: any) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const dispatch = useDispatch();
	const isRelationShipLoadingList = useSelector(
		selectIsFamilyRelationShipLoading
	);
	// const [pincode, setPincode] = useState('');
	const [submitModal, setSubmitModal] = useState(false);
	const relationShipList = useSelector(selectFamilyRelationShipList);
	const pinLoading = useSelector(pinMasterRequestLoading);
	const [selectedOption, setSelectedOption] = useState(null);
	const isRequestLoading = useSelector(saveArRequestLoading);
	const isSendCommunicationLoading = useSelector(
		selectSendCommunicationLoading
	);
	useEffect(() => {
		dispatch(fetchFamilyRelationShipList());
	}, []);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		trigger,
		control,
	} = useForm({
		defaultValues: {
			mobileCountryCode: '91',
			firstName: '',
			mobileNo: '',
			email: '',
			relationCode: '',
			address1: '',
			address2: '',
			address3: '',
			city: '',
			state: '',
			pincode: '',
			date: '',
			month: '',
			year: '',
			country: '',
			dob: '',
			permanentAddress: false,
		},
	});

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const datePicker = (e: any) => {
		setAnchorEl(e.currentTarget);
	};

	const permanentAddress = watch('permanentAddress');
	const relationName = watch('relationCode');
	const firstName = watch('firstName');
	const mobileCountryCode = watch('mobileCountryCode');
	const mobileNo = watch('mobileNo');
	const email = watch('email');
	// const date = watch('date');
	// const month = watch('month');
	// const year = watch('year');
	const address1 = watch('address1');
	// const address2 = watch('address2');
	const address2 = watch('address2');
	const address3 = watch('address3');
	const city = watch('city');
	const state = watch('state');
	const pin = watch('pincode');
	const dob = watch('dob');
	// const investHorizon = watch('investHorizon');
	const handlePinCodeMaster = (pinDetails: any) => {
		setValue('address3', pinDetails?.districtName);
		setValue('state', pinDetails?.stateName);
	};
	useEffect(() => {
		const matchingRelation =
			relationShipList &&
			relationShipList.find((item: any) => item.relationCode === relationName);
		if (matchingRelation) {
			setValue('relationCode', matchingRelation?.relationship);
		}
	}, [relationName, relationShipList, setValue]);
	useEffect(() => {
		if (pin.length === 6) {
			const payload = {
				Customer: {
					pincode: pin,
				},
				handlePinCodeMaster,
			};
			dispatch(pinCodeRequest(payload as any));
		} else if (pin.length !== 0) {
			setValue('address3', '');
			setValue('state', '');
		}
	}, [pin]);

	const handleOption = (value: any) => {
		setValue('relationCode', value, {
			shouldValidate: true,
		});
		setSelectedOption(value);
	};
	const userProfile = useSelector(selectUserProfile);
	const permanentEiaAddress: any = PermanentAddress
		? PermanentAddress
		: userProfile?.Customer?.PermanentAddress || {};
	const handleChange = () => {
		setValue('permanentAddress', !permanentAddress);

		if (!permanentAddress) {
			setValue('address1', permanentEiaAddress?.address1 || '', {
				shouldValidate: false,
			});
			setValue('address2', permanentEiaAddress?.address2 || '', {
				shouldValidate: false,
			});
			setValue('address3', permanentEiaAddress?.address3 || '', {
				shouldValidate: false,
			});
			setValue('city', permanentEiaAddress?.city || '', {
				shouldValidate: false,
			});
			setValue('country', permanentEiaAddress?.country || '', {
				shouldValidate: false,
			});
			setValue('pincode', permanentEiaAddress?.pincode || '', {
				shouldValidate: false,
			});
			setValue('state', permanentEiaAddress?.state || '', {
				shouldValidate: false,
			});
		} else {
			setValue('address1', '', { shouldValidate: true });
			setValue('address2', '', { shouldValidate: true });
			setValue('address3', '', { shouldValidate: true });
			setValue('city', '', { shouldValidate: true });
			setValue('country', '', { shouldValidate: true });
			setValue('pincode', '', { shouldValidate: true });
			setValue('state', '', { shouldValidate: true });
		}
	};

	// const validateDate = (value: any) => {
	// 	const dateValue = parseInt(value, 10);
	// 	if (!config.numbersOnlyRegex.test(value) || isNaN(dateValue)) {
	// 		return data.INVALID_VALUE;
	// 	}
	// 	if (dateValue < 1 || dateValue > 31) {
	// 		return 'Invalid Date';
	// 	}
	// 	return true;
	// };
	// const validateMonth = (value: any) => {
	// 	const monthValue = parseInt(value, 10);
	// 	if (!config.numbersOnlyRegex.test(value) || isNaN(monthValue)) {
	// 		return data.INVALID_VALUE;
	// 	}
	// 	if (monthValue < 1 || monthValue > 12) {
	// 		return 'Invalid Month';
	// 	}
	// 	return true;
	// };
	// const validateYear = (value: any) => {
	// 	const currentYear = new Date().getFullYear();
	// 	const yearValue = parseInt(value, 10);
	// 	if (!config.numbersOnlyRegex.test(value) || isNaN(yearValue)) {
	// 		return data.INVALID_VALUE;
	// 	}
	// 	if (yearValue < 1945 || yearValue > currentYear) {
	// 		return 'Invalid year ';
	// 	}
	// 	return true;
	// };
	const handleConfirm = () => {
		// const age: any = ageCalculator(`${month}/${date}/${year}`);
		// if (age < 18) {
		// 	dispatch(
		// 		setAlertInfo({
		// 			open: true,
		// 			type: 'FAILED',
		// 			description: 'Age is below 18 years',
		// 		})
		// 	);
		// } else {
		setSubmitModal(true);
		// }
	};
	const handleDateChange = (date: any) => {
		setValue('dob', date?.format('DD/MM/YYYY'), { shouldValidate: true });
	};

	// const handleDateChange = (dates: any) => {
	//     setValue('date', dates?.format('DD'), { shouldValidate: true });
	//     setValue('month', dates?.format('MM'), { shouldValidate: true });
	//     setValue('year', dates?.format('YYYY'), { shouldValidate: true });
	// };
	const handleARSubmit = (inform: boolean) => () => {
		const intimateAR = inform ? 'Y' : 'N';
		const handleSuccess = () => {
			if (inform) {
				dispatch(
					sendCommunicationRequest({
						handleSuccess: () => {
							handleARFlowSuccess();
						},
						Customer: {
							eiaNo,
							purpose: SEND_COMMUNICATION_PURPOSE.AR_INTIMATION,
							flag: SEND_COMMUNICATION_FLAG.SUCCESS,
							policyNo: '',
							alternateCode: '',
							insurerCode: '',
						},
					})
				);
			} else {
				handleARFlowSuccess();
			}
		};
		const payload: any = {
			Customer: {
				eiaNo,
				firstName,
				middleName: '',
				lastName: '',
				mobileCountryCode,
				mobileNo,
				email,
				dob,
				relationCode: selectedOption !== null ? selectedOption : '',
				address1,
				address2,
				address3,
				city,
				state,
				pin,
				country: pin !== '' ? 'India' : '',
				investHorizon: '',
				intimateAR,
				addressFlag: permanentAddress === true ? 'Y' : 'N',
			},
			handleSuccess,
		};
		dispatch(saveARRequest(payload as any));
	};
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('mobileNo', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);
	const dobValue = String(watch('dob')).split('/');
	const isMinor: any = useMemo(
		() =>
			(ageCalculator(`${dobValue[1]}/${dobValue[0]}/${dobValue[2]}`) as any) <
			18,
		[dobValue]
	);
	const [addressError, setaddressError] = useState<string | null>(null);
	const [pinError, setpinError] = useState<string | null>(null);
	const [cityError, setCityError] = useState<string | null>(null);
	const handleAddressChange = (e: any) => {
		const address = e.target.value;
		if (address) {
			if (!address1Regex.test(address)) {
				setaddressError('INVALID_ADDRESS_FORMAT');
			} else {
				setaddressError(null);
				setValue('address1', address1, { shouldValidate: true });
			}
		} else {
			setaddressError(null);
		}
	};

	const disable = () => {
		if (pin && address3 && state) {
			return false;
		}
		if (pin?.length === 0 && address3?.length === 0 && state?.length === 0) {
			return false;
		}
		return true;
	};
	useEffect(() => {
		if (disable()) {
			setpinError(data.INVALID_PINCODE);
		} else {
			setpinError(null);
		}
	}, [disable]);
	const handlePinChange = (e: any) => {
		const pin1 = parsePinCode(e.target.value);
		if (pin1) {
			if (!config.pincode.test(pin1)) {
				setpinError(data.INVALID_PINCODE);
				setValue('pincode', pin1, { shouldValidate: true });
			} else {
				setpinError(null);
				setValue('pincode', pin1, { shouldValidate: true });
			}
		} else {
			setpinError(null);
			setValue('address3', '');
			setValue('state', '');
			setValue('pincode', '');
		}
	};
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
	const handleCityChange = (e: any) => {
		const city = e.target.value;
		if (city) {
			if (!config.alphabetsOnlyRegex.test(city)) {
				setCityError('Invalid city format');
			} else {
				setCityError(null);
				setValue('city', city, { shouldValidate: true });
			}
		} else {
			setCityError(null);
		}
	};
	const handleDOBChange = (e: any) => {
		setValue('dob', e.target.value, { shouldValidate: false });
	};
	const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue('firstName', sanitizedValue, { shouldValidate: true });
	};

	return (
		<form>
			<Grid container justifyContent='center'>
				<Grid item container sm={9} xs={10} lg={7} md={9}>
					{!showAlert && (
						<Grid
							flexWrap='nowrap'
							container
							spacing={1}
							justifyContent='end'
							className='mt-10'
						>
							<Grid item width='auto'>
								<Grid
									columnSpacing={1}
									container
									alignItems='center'
									onClick={handleSkip}
									className='cursor-pointer'
									flexWrap='nowrap'
								>
									<Grid item onClick={handleSkip}>
										<Typography
											component='span'
											className='f-14'
											color={theme.palette.common.black}
										>
											{t('SKIP_FOR_NOW')}
										</Typography>
									</Grid>
									<Grid item>
										<SvgConverter
											Icon={ArrowLeftIcon}
											// className={'go-back-icon d-flex'}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)}
					<Grid item className='case1' xs={12} lg={12} md={12}>
						{!showAlert && (
							<Grid item className='mt-30'>
								<Typography
									component='span'
									className='f-14 fw-600 '
									color={theme.palette.common.black}
								>
									{t('STEP')} 2/3
								</Typography>
							</Grid>
						)}
						<Grid item className='mb-10' xs={12} lg={12} sm={12} xl={12}>
							<Typography
								className='f-20 fw-600 mt-20'
								color={theme.palette.primary.main}
								// whiteSpace="nowrap"
							>
								{' '}
								{t('PLEASE_ASSIGN_AN_AUTHORIZED_REPRESENTATIVE')}
							</Typography>
						</Grid>
						<Grid item container xs={12} lg={12} sm={12} md={12} xl={12}>
							<Grid container item spacing={1}>
								<Grid item>
									<Typography
										className='f-14 '
										color={theme.palette.common.black}
									>
										{t('WHO_IS_AN')}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										className='f-14 fw-600'
										color={theme.palette.common.black}
									>
										{`${t('"AUTHORIZED_REPRESENTATIVE"')}?`}{' '}
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Typography
									className='f-14   mb-5'
									color={theme.palette.common.black}
								>
									{t('A_POLICY_HOLDER_SHALL_APPOINT')}
									<span className='underlined_text'>
										{' '}
										{t('ENTITLED_TO_ACCESS_THE_ACCOUNT')}
									</span>{' '}
									{t('TO_OPERATE_THE_E_INSURANCE_ACCOUNT.')}
									<span className='block_text_ar'>
										{t('THE_AR_IS_ENTITLED_ONLY_TO_ACCESS_THE_EIA')}
									</span>
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							xs={12}
							lg={12}
							sm={12}
							xl={12}
							md={12}
							className=' mt-15'
							justifyContent='center'
							item
						>
							<Grid item xs={12} container columnSpacing={5}>
								<Grid item xs={12} lg={4} md={4} xl={4}>
									<Typography
										className='f-14 fw-600'
										color={theme.palette.secondary.main}
									>
										{`${t('AR_NAME_(MANDATORY)')}*`}
									</Typography>
									<FormInput
										type='text'
										placeholder={`${t('TYPE_HERE')}..`}
										name='firstName'
										rules={{
											required: {
												value: true,
												message: t('AR_NAME_REQUIRED'),
											},
											validate: (value: any) => {
												if (!config.alphabetsOnlyRegex.test(value)) {
													return t('INVALID_NAME');
												}
												const trimmedValue = value.trim();
												return (
													trimmedValue.length > 0 || t('AR_NAME_CANNOT_EMPTY')
												);
											},
										}}
										inputProps={{
											className: 'px-10',
											maxLength: config.nameLength,
											...register('firstName', {
												onChange: nameChange,
											}),
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12} lg={4} md={4} xl={4}>
									<Typography
										className='f-14 fw-600'
										color={theme.palette.secondary.main}
									>
										{`${t('MOBILE_(MANDATORY)')}*`}
									</Typography>
									<Grid
										container
										flexWrap='nowrap'
										className='mb-10'
										columnSpacing={1}
										item
										xs={12}
									>
										<Grid item sm={3} md={5} xs={4} xl={4} lg={5}>
											<FormInput
												placeholder='91'
												hideCountButton
												name='mobileCountryCode'
												control={control}
												rules={{
													required: 'Required',
													onChange: (e: any) => {
														setValue(
															'mobileCountryCode',
															countryCode(e.target.value)
														);
													},
												}}
												inputProps={{
													// className: 'px-10 ',
													style: { textAlign: 'center', whiteSpace: 'noWrap' },
												}}
												errors={errors}
												readOnly
											/>
										</Grid>
										<Grid item sm={9} md={10} xs={8} xl={10} lg={8}>
											{/* <FormInput
														type="number"
														placeholder="1234567890"
														hideCountButton
														name="mobileNo"
														control={control}
														rules={{
															required: data.MOBILENUMBER,
															maxLength: {
																value: 10,
																message: data.MOBILEDIGIT,
															},
															pattern: {
																value: mobileNumberRegex,
																message: 'Invalid mobile number',
															},
															onChange: (e:any) => {
											
																setValue('mobileNo', e.target.value, { shouldValidate: true });
															  },
														}}
														errors={errors}
													/> */}
											<FormInput
												// type="number"
												name='mobileNo'
												placeholder='1234567890'
												hideCountButton
												control={control}
												rules={{
													required: {
														value: true,
														message: t('MOBILE_NUMBER_IS_REQUIRED'),
													},
													// maxLength: {
													// 	value: 10,
													// 	message: data.MOBILEDIGIT,
													// },
													pattern: {
														value: mobileNumberRegex,
														message: t('INVALID_MOBILE_NUMBER'),
													},
													onChange: (e: any) => {
														setValue('mobileNo', e.target.value, {
															shouldValidate: true,
														});
													},
												}}
												errors={errors}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} lg={4} md={4} xl={4}>
									<Typography className='f-14 fw-600' color='primary'>
										{t('EMAIL')}
									</Typography>
									<FormInput
										type='email'
										placeholder={`${t('TYPE_HERE')}...`}
										name='email'
										inputProps={{
											className: 'px-10',

											...register('email', {
												onChange: handleEmailChange,
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													message: t('INVALID_EMAIL_ADDRESS'),
												},
											}),
										}}
										errors={errors}
									/>
									{/* {emailError && (
										<Typography color="error" variant="caption">
											{emailError}
										</Typography>
									)} */}
								</Grid>
							</Grid>
							<Grid item xs={12} container columnSpacing={5}>
								<Grid item xs={12} md={6} lg={6} xl={6}>
									<Grid item>
										<Typography
											className='f-14 fw-600'
											color={theme.palette.secondary.main}
										>
											{`${t('DATE_OF_BIRTH_(MANDATORY)')}*`}
										</Typography>
									</Grid>
									<Grid
										item
										container
										flexDirection='row'
										// spacing={0.4}
										// alignItems="center"
										justifyContent='space-between'
										flexWrap='nowrap'
										columnSpacing={1}
									>
										<Grid item flex={3}>
											<FormInput
												name='date'
												inputProps={{
													maxLength: 2,
													style: { textAlign: 'center' },
													value: dobValue?.[0],
												}}
												placeholder='DD'
												readOnly
												className='date-month-input'
												onClick={datePicker}
											/>
										</Grid>
										<Grid item flex={3}>
											<FormInput
												name='month'
												inputProps={{
													maxLength: 2,
													style: { textAlign: 'center' },
													value: dobValue?.[1],
												}}
												placeholder='MM'
												readOnly
												className='date-month-input'
												onClick={datePicker}
											/>
										</Grid>
										<Grid item flex={4}>
											<FormInput
												name='year'
												inputProps={{
													maxLength: 4,
													style: { textAlign: 'center' },
													value: dobValue?.[2],
												}}
												placeholder='YYYY'
												readOnly
												className='signup-year-input'
												onClick={datePicker}
											/>
										</Grid>
										<Grid className='px-10 py-10'>
											<DatePickerModal
												iconType='primary'
												disableFuture
												onChange={handleDateChange}
												value={
													dobValue.length === 3
														? `${dobValue[0]}/${dobValue[1]}/${dobValue[2]}`
														: false
												}
												modalOpen={anchorEl}
												setModalOpen={setAnchorEl}
											/>
										</Grid>
									</Grid>
									<FormInput
										hidden
										name='dob'
										inputProps={{
											...register('dob', {
												required: {
													value: true,
													message: t('DOB_IS_REQUIRED'),
												},
											}),
											onchange: handleDOBChange,
										}}
										errors={
											isMinor && dobValue?.length > 1
												? { dob: { message: t('AGE_IS_BELOW_18_YEARS') } }
												: errors
										}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={6} xl={6}>
									<Grid item>
										<Typography
											className='f-14 fw-600'
											color={theme.palette.secondary.main}
										>
											{`${t('RELATIONSHIP_(MANDATORY)')}*`}
										</Typography>
										{isRelationShipLoadingList ? (
											<Grid item>
												<Spinner />
											</Grid>
										) : (
											<CustomSelectBox
												ClassName='w-100 relationship-selectbox'
												options={
													relationShipList?.map((row: any) => ({
														Code: row.relationCode,
														Description: row.relationship,
														Category: row.relationship,
													})) || []
												}
												inputProps={register('relationCode', {
													required: {
														value: true,
														message: t('RELATIONSHIP_IS_REQUIRED'),
													},
												})}
												defaultValue={selectedOption}
												setSelectedOption={handleOption}
											/>
										)}
										{errors.relationCode ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{errors.relationCode?.message}
											</Typography>
										) : null}
									</Grid>
								</Grid>
							</Grid>
							<Grid xs={12} display='flex' justifyContent='space-between'>
								<Grid item>
									<Typography className='f-14' color='primary'>
										{t('ADDRESS')}
									</Typography>
								</Grid>
								<Grid item className='mt-n2 '>
									<FormCheckBox
										label={
											<Typography
												className='f-14'
												color={theme.palette.common.black}
											>
												{t('SAME_AS_EIA_HOLDER')}
											</Typography>
										}
										checked={permanentAddress}
										onClick={handleChange}
										size={16}
										checkBoxPadding={1}
										// {...register('permanentAddress')}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{/* {permanentAddress ? (
									<Typography
										className="f-12"
										color={theme.palette.common.black}
									>
										same as life Assured
									</Typography>
								) : ( */}

								<AddFamilyAddress
									setValue={setValue}
									register={register}
									errors={errors}
									PermanentAddress={permanentAddress}
									permanentEiaAddress={permanentEiaAddress}
									// {...watch()}
									watch={watch}
									isAR={true}
								/>
								{/* )} */}
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justifyContent={showAlert ? 'space-between' : 'center'}
						className='mt-30'
					>
						{showAlert && (
							<CustomButton
								text={t('CANCEL')}
								variant='text'
								color='inherit'
								showIcon={false}
								className='fw-600 f-20'
								onClick={handleSkip}
								disabled={
									isMinor && dobValue?.length > 1
									// disable()
								}
							/>
						)}

						<CustomButton
							text={t('SUBMIT')}
							variant='text'
							color='primary'
							className='fw-600 f-20'
							onClick={handleSubmit(handleConfirm)}
							disabled={
								isMinor && dobValue?.length > 1
								// disable()
							}
						/>
					</Grid>
					<AuthorizedRepresentativeModalDetails
						open={submitModal}
						setOpen={setSubmitModal}
						handleARSubmit={handleARSubmit}
						loading={isRequestLoading || isSendCommunicationLoading}
						arName={firstName}
					/>
					<Grid mt={10} mb={10} justifyContent='center' xs={12}>
						<NeedSupport type='black' />
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};
export default ARForm;
