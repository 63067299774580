import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from '../../../../common/icons/profile/home_icon.svg';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';

const CorrespondenceAddressHeader = ({ toggleAddNumberModal }: any) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Grid item>
			<Grid item className="mb-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={16}
					fontWeight={600}
				>
					{t('ALTERNATE_COMMUNICATION_ADDRESS')}
				</Typography>
			</Grid>

			<Grid item>
				<CustomButton
					text={t('ADD_AN_ADDRESS')}
					color="primary"
					startIcon={HomeIcon}
					variant="text"
					showIcon={false}
					onClick={toggleAddNumberModal}
					fontSize={14}
				/>
			</Grid>
			<Grid item className="my-10">
				<Separator color={theme.palette.primary.main} borderWidth={1} />
			</Grid>
		</Grid>
	);
};

export default CorrespondenceAddressHeader;
