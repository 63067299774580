import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NeedMoneyHeader from './NeedMoneyHeader';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/LoanModule/left_arrow_icon.svg';
import { APP_ROUTES } from '../../../common/constants/Routes';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { convertAmount, getFormatDate } from '../../../common/utils';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import { ReactComponent as RedRupeeIcon } from '../../../common/icons/LoanModule/redRupeeIcon.svg';
import Separator from '../../../common/ui/Separator';
import RecommendedCard from './RecommendedCard';
import { ReactComponent as WithdrawalIcon } from '../../../common/icons/LoanModule/withdrawalIcon.svg';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as PlusIcon } from '../../../common/icons/LoanModule/Plus-icon.svg';
// import HDFC from '../../../common/icons/LoanModule/hdfc.png';
import { ReactComponent as LoanIcon } from '../../../common/icons/LoanModule/loanIcon.svg';
import { ReactComponent as SurrenderIcon } from '../../../common/icons/LoanModule/surrender.svg';
import TotalAmountAvailable from './TotalAmountAvailable';
import {
	// currentEligibleValue,
	myOptions,
	selectedCombinationLoading,
	selectedCombinationRequest,
} from './Loan.slice';
import { useTranslation } from 'react-i18next';

const BestFitCombinations = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const Combination: any = useSelector(myOptions);
	const [twoCombinations, setTwoCombinations] = useState(false);
	const loading: any = useSelector(selectedCombinationLoading);
	useEffect(() => {
		if (!Combination) {
			navigate(APP_ROUTES.NEED_MONEY);
		}
		if (Combination?.Combinations?.length > 1) {
			setTwoCombinations(true);
		}
	}, [Combination]);
	const handleCombinations = (data: any) => {
		const payload: any = {
			Combination: data,
			requiredAmount: Combination?.requiredAmount,
			requiredDate: Combination?.requiredDate,
			navigate,
		};
		// const policyCount: any = {
		// 	currentWithdrawalPolicyCount: data?.currentWithdrawalPolicyCount,
		// 	currentLoanPolicyCount: data?.currentLoanPolicyCount,
		// };
		dispatch(selectedCombinationRequest(payload));
		// dispatch(currentEligibleValue(policyCount as any));
	};

	const handleEdit = () => {
		navigate(APP_ROUTES.NEED_MONEY);
	};

	return (
		<div>
			<Grid container alignItems="center" mb={5} className="best-fit-amount">
				<Grid container>
					<Grid container className="mb-30">
						<NeedMoneyHeader />
					</Grid>
					<Grid item className="mb-5" xs={12}>
						<CustomButton
							fontSize={14}
							fontWeight={600}
							text={t('EDIT')}
							showIcon={false}
							startIcon={ArrowLeftIcon}
							variant="text"
							color="primary"
							startIconClassName="go-back-icon"
							className="f-14 "
							onClick={() => {
								navigate(APP_ROUTES.NEED_MONEY);
							}}
						/>
					</Grid>
					<Grid container alignItems="center">
						<Typography
							color={theme.palette.primary.dark}
							whiteSpace="nowrap"
							display="flex"
							alignItems="center"
							className="fw-600 f-18 pr-1"
						>
							{t('TARGET_AMOUNT')}
						</Typography>
						<Typography ml={0.5}>
							<LabelWithIcon
								className="px-4"
								svgClassName=""
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={convertAmount(Combination?.requiredAmount)}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 28,
								}}
							/>
						</Typography>
					</Grid>
					<Grid container className="mb-10" alignItems="center">
						<Typography
							color={theme.palette.primary.dark}
							whiteSpace="nowrap"
							display="flex"
							alignItems="center"
							className="fw-600 f-18 "
						>
							{t('TARGET_DATE')}
						</Typography>
						<Typography
							color={theme.palette.common.black}
							ml={2}
							sx={{ fontSize: '28px' }}
						>
							&nbsp; {getFormatDate(Combination?.requiredDate, 'DD-MM-YYYY')}
						</Typography>
					</Grid>
					{Combination &&
						Combination?.Combinations &&
						Combination?.Combinations?.map((combinations: any) => (
							<>
								<Grid container alignItems="start">
									<Typography
										color={theme.palette.primary.dark}
										className="f-18 mt-20"
									>
										{combinations?.combinationName}
									</Typography>
								</Grid>
								<Grid container alignItems="start" my={2} mr={2}>
									<Typography
										color={theme.palette.common.black}
										whiteSpace="nowrap"
										display="flex"
										alignItems="center"
										className="fw-600 f-16 pr-1"
									>
										Available Amount =
									</Typography>
									<Typography ml={3}>
										<LabelWithIcon
											className="px-4"
											svgClassName=""
											justifyContent="flex-start"
											Icon={RupeeIcon}
											label={convertAmount(combinations?.totalFundsAvailable)}
											labelProps={{
												color: theme.palette.common.black,
												fontSize: 16,
											}}
										/>
									</Typography>
								</Grid>
								<Grid container alignItems="start">
									<Typography
										color="#aaaaaa"
										className="f-14"
										fontStyle={'italic'}
									>
										[{t('BASED_ON_LOWEST_COST')} ]
									</Typography>
								</Grid>

								{twoCombinations && (
									<Grid container alignItems="start">
										{combinations?.Widgets.some(
											(widget: any) => widget.widgetType === 'S'
										) ? (
											<Typography
												color={theme.palette.error.dark}
												className="f-16 my-15"
											>
												We DO NOT recommend the below combination because it is
												causing{' '}
												<span className="underline">loss of life cover </span>{' '}
												of:
												<LabelWithIcon
													svgClassName="product-rupee-icon"
													justifyContent="flex-start"
													Icon={RedRupeeIcon}
													label={convertAmount(combinations?.lossOfCover)}
													labelProps={{
														color: theme.palette.error.dark,
														fontSize: 16,
														fontWeight: 600,
													}}
												/>
											</Typography>
										) : (
											<Typography className="f-16 my-15 orange-color">
												We recommend the below combination even though the
												available amount is{' '}
												<span className="underline">slighlty less</span> than
												the requested amount because it prevents loss of life
												cover.
											</Typography>
										)}
									</Grid>
								)}

								<Grid container className="withdrawal-box" mt={3}>
									{combinations &&
										combinations?.Widgets &&
										combinations?.Widgets?.map((widgets: any, index: any) => (
											<>
												{widgets?.widgetType === 'W' && (
													<>
														<Grid item>
															<RecommendedCard
																head={t('PARTIAL_WITHDRAWAL')}
																header="Partial Withdrawal"
																icon={WithdrawalIcon}
																productLogo={widgets?.insurerLogo}
																widgetNo={widgets?.widgetNo}
																productName={
																	widgets?.policyType + ' ' + widgets?.planName
																}
																label1={t('MATURITY_VALUE')}
																label1Value={convertAmount(
																	widgets?.maturityValue
																)}
																label2={t('CURRENT_FUND_VALUE')}
																label2Value={convertAmount(widgets?.fundValue)}
																label3={t('ELIGIBLE_PARTIAL_WITHDRAWAL_LIMIT')}
																label3Value={convertAmount(
																	widgets?.Withdrawal
																		?.eligiblePartialWithdrawalValue
																)}
																label4={t('RECOMENDED_PARTIAL_WITHDRAWAL')}
																label4Value={convertAmount(
																	widgets?.Withdrawal?.partialWithdrawalAmount
																)}
																label5={t('PARTHIAL_WITHDEARL_LIMIT')}
																label5Value={
																	widgets?.Withdrawal
																		?.partialWithdrawalPercentage
																}
																payerName={widgets?.payerName}
															/>
														</Grid>
														<Grid mb={3} justifyContent="center" container>
															<Typography
																className="f-12"
																justifyContent="center"
																color={theme.palette.grey[400]}
															>
																{t('CLICK_EDIT_CUSTOMIZE')}
															</Typography>
														</Grid>
													</>
												)}
												{widgets?.widgetType === 'S' && (
													<>
														<Grid item>
															<RecommendedCard
																head="Surrender Policy"
																header="Surrender"
																icon={SurrenderIcon}
																productLogo={widgets?.insurerLogo}
																widgetNo={widgets?.widgetNo}
																productName={
																	widgets?.policyType + ' ' + widgets?.planName
																}
																label1={t('MATURITY_VALUE')}
																label1Value={convertAmount(
																	widgets?.maturityValue
																)}


																label4={t('SURRENDER_VALUE')}


																label4Value={convertAmount(
																	widgets?.Surrender?.surrenderValue
																)}
																payerName={widgets?.payerName}
															/>
														</Grid>
														<Grid mb={3} justifyContent="center" container>
															<Typography
																className="f-12"
																justifyContent="center"
																color={theme.palette.grey[400]}
															>
																{t('CLICK_EDIT_CUSTOMIZE')}
															</Typography>
														</Grid>
													</>
												)}
												{widgets?.widgetType === 'L' && (
													<>
														<Grid item>
															<RecommendedCard
																icon={LoanIcon}
																head={t('LOAN_AGAINST_POLICY')}
																header="Loan against Policy"
																productLogo={widgets?.insurerLogo}
																widgetNo={widgets?.widgetNo}
																productName={
																	widgets?.policyType + ' ' + widgets?.planName
																}
																label1={t('MATURITY_VALUE')}
																label1Value={convertAmount(
																	widgets?.maturityValue
																)}
																label2={
																	widgets?.policyType == "ULIP" ? "Fund Value" : t('SURRENDER_VALUE')
																}
																// label2={t('SURRENDER_VALUE')}
																label2Value={convertAmount(widgets?.fundValue)}
																label3={t('ELIGIBLE_LOAN_VALUE')}
																label3Value={convertAmount(
																	widgets?.Loan?.eligibleLoanValue
																)}
																label4={t('RECOMENDED_A_LOAN')}
																label4Value={convertAmount(
																	widgets?.Loan?.loanAmount
																)}
																label5={t('OF_ELIGIBLE_LOAN_VALUE')}
																label5Value={widgets?.Loan?.loanPercentage}
																payerName={widgets?.payerName}
															/>
														</Grid>
														<Grid mb={3} justifyContent="center" container>
															<Typography
																className="f-12"
																justifyContent="center"
																color={theme.palette.grey[400]}
															>
																{t('CLICK_EDIT_CUSTOMIZE')}
															</Typography>
														</Grid>
													</>
												)}
												{index !== combinations?.Widgets?.length - 1 && (
													<Grid mb={5} justifyContent="center" container>
														<SvgConverter Icon={PlusIcon} />
													</Grid>
												)}
											</>
										))}

									<Grid container className="mb-10 mt-10">
										<Separator color={theme.palette.grey[300]} />
									</Grid>
									<Grid mb={5} justifyContent="center" container>
										<Grid container>
											<TotalAmountAvailable
												twoCombinations={combinations?.Widgets.some(
													(widget: any) => widget.widgetType === 'S'
												)}
												TotalNo={`(${Array.from(
													{
														length: combinations?.Widgets?.length,
													},
													(_, i) => i + 1
												).join('+')})`}
												totalFunds={convertAmount(
													combinations?.totalFundsAvailable
												)}
												lossOfCover={convertAmount(combinations?.lossOfCover)}
												cost={convertAmount(Combination?.costs)}
												targetAmount={convertAmount(
													Combination?.requiredAmount
												)}
											/>
										</Grid>
										<Grid container className="mt-10">
											<Separator color={theme.palette.grey[300]} />
										</Grid>
										<Grid
											container
											className="mb-5 mt-5"
											display="flex"
											justifyContent="space-between"
										>
											<CustomButton
												fontSize={14}
												text={t('EDIT_TARGET_AMOUNT')}
												showIcon={false}
												variant="text"
												color="primary"
												type="submit"
												textAlign="right"
												onClick={handleEdit}
											/>
											<CustomButton
												fontSize={16}
												fontWeight={600}
												textAlign="right"
												text={t('PROCEED')}
												showIcon
												variant="text"
												color="primary"
												type="submit"
												onClick={() => {
													handleCombinations(combinations);
												}}
												loading={loading}
											/>
										</Grid>
										<Grid container className="mb-10">
											<Separator color={theme.palette.grey[300]} />
										</Grid>
									</Grid>
								</Grid>
							</>
						))}
				</Grid>
			</Grid>
		</div>
	);
};

export default BestFitCombinations;
