import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AUTH_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
import SignUp from '../features/Auth/SignUp/SignUp';
import OtpScreen from '../features/Auth/common/OtpScreen';
import AuthLayout from './AuthLayout';
import PageNotFound from '../common/ui/PageNotFound';
import EkycOtpScreen from '../features/Auth/SignUp/EkycOtpScreen';
import EkycinCamsOtpScreen from '../features/Auth/SignUp/EkycinCamsOtpScreen';
import SignupEkycDetailsOne from '../features/Auth/SignUp/SignupEkycDetailsOne';
import AuthorizedRepresentativeScreen from '../features/Auth/SignUp/AR/AuthorizedRepresentativeScreen';
import Login from '../features/Auth/Login/Login';
import CaseTwoScreen from '../features/Auth/SignUp/CaseTwoScreen';
import VerificationInProgressScreen from '../features/Auth/SignUp/VerficationInprogresScreen';
import SignupKycReturnComponent from '../features/Auth/SignUp/SignupKycReturnComponent';
import AddPolicyInstructionScreen from '../features/App/AddPolicyScreen/AddPolicyInstructionScreen';
import StepOneScreen from '../features/App/AddPolicyScreen/StepOneScreen';
import StepTwoInstructionScreen from '../features/App/AddPolicyScreen/StepTwoInstructionScreen';
import StepTwoScreen from '../features/App/AddPolicyScreen/StepTwoScreen';
import SubmissionPreviewScreen from '../features/App/AddPolicyScreen/SubmissionPreviewScreen';

const AuthRoutes = ({ isLogged }: any) => {
	const location = useLocation();

	return (
		<Routes>
			{isLogged === false ? (
				<Route path="/" element={<AuthLayout />}>
					<Route path={AUTH_ROUTES.LOGIN} element={<Login />} />
					<Route path={AUTH_ROUTES.LOGIN_OTP_VERIFY} element={<OtpScreen />} />
					<Route path={AUTH_ROUTES.SIGNUP} element={<SignUp />} />
					<Route path={AUTH_ROUTES.SIGNUP_OTP_VERIFY} element={<OtpScreen />} />
					<Route
						path={AUTH_ROUTES.EKYC_OTPSCREEN}
						element={<EkycOtpScreen />}
					/>
					<Route
						path={AUTH_ROUTES.EKYC_IN_CAMS_OTPSCREEN}
						element={<EkycinCamsOtpScreen />}
					/>
					<Route
						path={AUTH_ROUTES.SIGNUP_EKYC_DETAILS_ONE}
						element={<SignupEkycDetailsOne />}
					/>
					<Route
						path={AUTH_ROUTES.AUTHORISED_REPRESENTATIVE}
						element={<AuthorizedRepresentativeScreen />}
					/>
					<Route
						path={AUTH_ROUTES.SIGNUP_CASE_TWO}
						element={<CaseTwoScreen />}
					/>
					<Route
						path={AUTH_ROUTES.SIGNUP_VERIFICATION_STATUS}
						element={<VerificationInProgressScreen />}
					/>

					<Route
						path={AUTH_ROUTES.SIGNUP_KYC_RETURN_URL}
						element={<SignupKycReturnComponent />}
					/>

					{/* add policy routes */}

					<Route
						path={AUTH_ROUTES.ADD_POLICY_INSTRUCTION}
						element={<AddPolicyInstructionScreen />}
					/>

					<Route
						path={AUTH_ROUTES.ADD_POLICY_STEP_ONE}
						element={<StepOneScreen />}
					/>
					<Route
						path={AUTH_ROUTES.ADD_POLICY_STEP_TWO_INSTRUCTION}
						element={<StepTwoInstructionScreen />}
					/>
					<Route
						path={AUTH_ROUTES.ADD_POLICY_STEP_TWO}
						element={<StepTwoScreen />}
					/>
					<Route
						path={AUTH_ROUTES.ADD_POLICY_SUBMITTION_PREVIEW}
						element={<SubmissionPreviewScreen />}
					/>

					{!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
						<Route path="*" element={<PageNotFound />} />
					) : (
						<></>
					)}

					{/* <Route path={"*"} element={<Navigate to={APP_ROUTES.DASHBOARD} />} /> */}
				</Route>
			) : (
				<></>
			)}
		</Routes>
	);
};
export default AuthRoutes;
