/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import defaultAvatar from '../icons/default_avatar.svg';
import ProfileCam from '../icons/profile/profile-camera.svg';
import Spinner from './Spinner';

const ProfileAvatar = ({
	profileImg,
	profileReadiness,
	uploadProfile,
	profileLoading
}: {
	profileReadiness: any;
	profileImg: any;
	uploadProfile?: any;
	profileLoading?: any;
}) => {
	const profileReadinessValue = parseInt(profileReadiness, 10);

	return (
		<div className="user-avatar-container m-5" onClick={uploadProfile}>
			{profileLoading ? (
				<Spinner />
			) : (<><Avatar
				alt="user profile avatar"
				src={profileImg || defaultAvatar}
				sx={{ width: 52, height: 52 }}
			/>

				<CircularProgress
					variant="determinate"
					value={profileReadinessValue}
					size={58}
					className="avatar-progress-bar"
					color={
						profileReadinessValue <= 40
							? 'error'
							: profileReadinessValue <= 70
								? 'secondary'
								: 'success'
					}

				/>

				<img src={ProfileCam} className="profile-cam-icon" alt="profile image" />
			</>
			)}


			{/* <SvgConverter Icon={ProfileCam} className='profile-cam-personal ' /> */}
			{/* </FileUploader> */}
		</div>
	);
};

export default ProfileAvatar;
