import { Grid, Typography, useTheme } from "@mui/material";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchInsurers, selectInsurerOptions } from "../PolicyScreen/Policies.slice";
import { useEffect, useState } from "react";
import Separator from "../../../common/ui/Separator";
import CustomButton from "../../../common/ui/CustomButton";
import { selectInsurer } from "./addPolicy.slice";

const SearchInsurer = ({ PolicyIntegrated }: any) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const Insurer = useSelector(selectInsurer);


    const [selectedInsurer, setSelectedInsurer] = useState<string[]>([...Insurer]);

    useEffect(() => {
        dispatch(fetchInsurers());
    }, []);

    const insurerOptions = useSelector(selectInsurerOptions);

    const insurerOptionsList =
        insurerOptions &&
        insurerOptions.map((item: any) => ({
            Description: item.label,
            Logo: item.insurerLogo,
            Code: item.value,
        }));

    return (<>
        <Grid item>
            <Typography
                className="f-16 fw-600 mb-5 mt-10"
                color={theme.palette.primary.main}
            >
                {t('SELECT_INSURERS_SEARCH')}
            </Typography>

            <Select
                options={insurerOptionsList}
                className="mb-20"
                isSearchable
                required
                placeholder={t('SELECT_INSURER')}
                value={
                    null
                }
                getOptionLabel={(option: any) => option.Description}
                getOptionValue={(option: any) => option.Code}
                onChange={(selectedOption: any) => {
                    const isExistInsurer = selectedInsurer.some((val: any) => val.Code === selectedOption.Code);
                    if (!isExistInsurer) { setSelectedInsurer([...selectedInsurer, selectedOption]); }

                }}
                // onBlur={() => field.onBlur()}
                styles={{
                    control: (base: any) => ({
                        ...base,
                        height: '50px',
                        borderColor: '#0051ba',
                        '&:hover': {
                            borderColor: ' #f99117',
                        },
                    }),
                }}
            />


            {selectedInsurer.length > 0 &&
                (selectedInsurer.map((item: any) => (
                    <Grid container columnSpacing={4} alignItems='center'>
                        <Grid item xs={2} className="mt-10 ">
                            {item?.Logo && (
                                <img
                                    src={item?.Logo}
                                    className="policy-image"
                                    alt="logo"
                                />
                            )}
                        </Grid>
                        <Grid item xs={9} className="mt-10" style={{ alignItems: 'center' }}>
                            <Typography
                                className="f-14 fw-600"
                                color={theme.palette.common.black}

                            >
                                {item?.Description} {item?.isiIntegrated == undefined && (
                                    <Typography
                                        className="f-12 fw-400"
                                        color={theme.palette.secondary.main}

                                    >
                                        {`(${t('INTEGRATED')})`}
                                    </Typography>
                                )}
                            </Typography>

                        </Grid>


                    </Grid >)

                ))
            }

            {
                selectedInsurer.length > 0 && (
                    <Grid>
                        <Grid container justifyContent="center" className="mt-20">
                            <Separator color={theme.palette.grey[400]} borderWidth={1} />
                        </Grid>


                        <Grid container spacing={1} justifyContent="flex-end">
                            <CustomButton
                                text={t('PROCEED')}
                                variant="text"
                                textAlign={'right'}
                                color="primary"
                                onClick={() => PolicyIntegrated({ "PolicyIntegrated": true, "insurer": selectedInsurer })}
                                className="fw-600 f-20 mt-20"

                            />

                        </Grid>




                    </Grid>
                )
            }
        </Grid >
    </>)
}


export default SearchInsurer;