import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import NeedMoneyHeader from './NeedMoneyHeader';
import theme from '../../../common/theme';
import ProductDetail from './ProductDetail';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import CustomButton from '../../../common/ui/CustomButton';
import {
	myOptionsRequest,
	payoutDetails,
	selectMyOptionIsLoading,
} from './Loan.slice';
import { convertAmount, getFormatDate } from '../../../common/utils';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useTranslation } from 'react-i18next';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import { LoadingButton } from '@mui/lab';
// import { myOptionsRequest } from './Loan.slice';

const TargetAmount = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loadingAdjust, setLoadingAdjust] = useState(false);
	const [loadingNonAdjust, setLoadingNonAdjust] = useState(false);
	const [showDoNotProceed, setShowDoNotProceed] = useState(false);
	const getPayOut: any = useSelector(payoutDetails);
	const loading: any = useSelector(selectMyOptionIsLoading);
	const date = dayjs().format('DD/MM/YYYY');
	const totalPayoutAmount = getPayOut?.Payouts.reduce(
		(acc: any, payout: any) => acc + parseInt(payout?.payoutAmount, 10),
		0
	);
	const targetAmount = getPayOut?.requiredAmount;
	useEffect(() => {
		if (!getPayOut) {
			navigate(APP_ROUTES.NEED_MONEY);
		}
		if (totalPayoutAmount > parseInt(getPayOut?.requiredAmount)) {
			setShowDoNotProceed(true);
		}
	}, [getPayOut]);

	const handleTargetAmount = (data: any) => {
		if (data === 'EDIT') {
			const requiredAmount =
				parseInt(getPayOut?.requiredAmount) - totalPayoutAmount;

			const payload: any = {
				requiredAmount,
				requiredDate: getPayOut?.requiredDate,
				navigate,
			};
			dispatch(myOptionsRequest(payload));
			setLoadingAdjust(true);
		} else {
			const payload: any = {
				requiredAmount: getPayOut?.requiredAmount,
				requiredDate: getPayOut?.requiredDate,
				navigate,
			};
			dispatch(myOptionsRequest(payload));
			setLoadingNonAdjust(true);
		}
	};

	return (
		<div>
			<Grid container justifyContent="center" alignItems="center">
				<Grid className="target-amount" container>
					<Grid container className="mb-50">
						<NeedMoneyHeader />
					</Grid>
					<Grid container className="mb-20" alignItems="start">
						{showDoNotProceed ? (
							<Typography color={theme.palette.common.black}>
								You already have a total payout of
								<LabelWithIcon
									svgClassName="product-rupee-icon"
									justifyContent="flex-start"
									Icon={RupeeIcon}
									label={convertAmount(totalPayoutAmount)}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 16,
										fontWeight: 600,
									}}
								/>{' '}
								within the chosen date range.
							</Typography>
						) : (
							<>
								<Typography
									color={theme.palette.common.black}
									display={'flex'}
									whiteSpace={'nowrap'}
								>
									You have a total payout of{' '}
									<LabelWithIcon
										svgClassName="product-rupee-icon ml-10"
										justifyContent="flex-start"
										Icon={RupeeIcon}
										label={convertAmount(totalPayoutAmount)}
										labelProps={{
											color: theme.palette.common.black,
											fontSize: 16,
											fontWeight: 600,
										}}
									/>{' '}
								</Typography>

								<Typography color={theme.palette.common.black}>
									in the chosen date range.
								</Typography>
							</>
						)}
					</Grid>
					<Grid container alignItems="start">
						<Typography
							color={theme.palette.primary.dark}
							className="fw-800 "
							flexWrap="nowrap"
							display="flex"
						>
							{t('TODAY')} &nbsp;
							<Typography className="fw-500 f-13 " mt={0.3}>
								({date})
							</Typography>
							&nbsp; to {getFormatDate(getPayOut?.requiredDate, 'DD-MM-YYYY')}
						</Typography>
					</Grid>
					<Grid
						container
						className="all-product-box  product-box-color"
						height={'188px'}
					>
						<Grid item my={1} className="" display={'contents'}>
							{getPayOut?.Payouts?.map((widget: any) => (
								<ProductDetail
									amount={widget?.payoutAmount}
									annual={widget?.payoutType}
									planName={widget?.policyType + ' ' + widget?.planName}
									selfName={widget?.payerName}
									date={widget?.payoutDate}
								/>
							))}
						</Grid>
					</Grid>
					<Grid container py={2} className="target-amount-buttons">
						<Grid className="target-amount-buttons-grid">
							<Grid mb={1} justifyContent={'end'} display={'flex'}>
								<LoadingButton
									type="submit"
									variant="text"
									color="primary"
									endIcon={
										<SvgConverter
											Icon={rightArrow}
											className={`right-arrow `}
										/>
									}
									className={
										showDoNotProceed
											? 'target-amount-button-show f-18 fw-600 custom-button'
											: 'target-amount-button f-18 fw-600 custom-button'
									}
									sx={{ padding: 0, textAlign: 'right' }}
									loading={loading && loadingAdjust}
									onClick={() => {
										showDoNotProceed
											? navigate(APP_ROUTES.DASHBOARD)
											: handleTargetAmount('EDIT');
									}}
								>
									<Typography fontSize={18} fontWeight={600}>
										{showDoNotProceed ? (
											'EXIT "NEED MONEY" MODULE'
										) : (
											<>
												{t('ADJUST_THE_TARGET_AMOUNT')}
												<Typography
													fontSize={18}
													fontWeight={600}
													alignItems={'flex-end'}
													display={'flex'}
													justifyContent={'flex-end'}
												>
													&nbsp;
													{t('TO')}
													&nbsp;
													{loadingAdjust ? null : (
														<SvgConverter
															Icon={RupeeIcon}
															className="mb-6 mr-2"
														/>
													)}
													&nbsp;{targetAmount - totalPayoutAmount}
												</Typography>
											</>
										)}
									</Typography>
								</LoadingButton>
							</Grid>
							<Grid display={'flex'} justifyContent={'flex-end'}>
								<LoadingButton
									type="submit"
									variant="text"
									color="primary"
									endIcon={
										<SvgConverter
											Icon={rightArrow}
											className={`right-arrow `}
										/>
									}
									className=" f-18 fw-600 custom-button  target-amount-button"
									sx={{ padding: 0, textAlign: 'right' }}
									loading={loading && loadingNonAdjust}
									onClick={() => {
										handleTargetAmount('NOTEDIT');
									}}
								>
									<Typography fontSize={18} fontWeight={600}>
										{t('PROCEED_WITH_THE_TARGET')}

										<Typography
											fontSize={18}
											fontWeight={600}
											alignItems={'flex-end'}
											display={'flex'}
											justifyContent={'flex-end'}
										>
											&nbsp;
											{t('AMOUNT_OF')}&nbsp;
											{loadingNonAdjust ? null : (
												<SvgConverter Icon={RupeeIcon} className="mb-6 mr-2" />
											)}
											&nbsp; {targetAmount}
										</Typography>
									</Typography>
								</LoadingButton>
							</Grid>
							<Grid>
								<CustomButton
									fontSize={18}
									fontWeight={600}
									textAlign="left"
									text={`GO BACK TO EDIT THE TARGET AMOUNT`}
									showIcon={false}
									startIcon={rightArrow}
									variant="text"
									color="primary"
									className="target-amount-button  go-back-edit mt-20"
									onClick={() => {
										navigate(APP_ROUTES.NEED_MONEY);
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/* <Grid
						container
						// className=' mt-30'
						justifyContent="center"
						alignItems="center"
					>
						<Grid item className="need-money">
							<Grid item className="mb-50">
								<NeedMoneyHeader />
							</Grid>
            </Grid>
        </Gird> */}
		</div>
	);
};

export default TargetAmount;
