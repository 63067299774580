import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/dashboard/rupee-icon.svg';

import '../Dashboard.scss';
import theme from '../../../../common/theme';
import Currency from '../../Common/Currency';

const CurrentValueCard = ({
	title,
	value = 0,
	policyCounts = 0,
	hidePolicyCounts = false,
	hideLastUpdate = true,
	hideValue = false,
	titleClassName = '',
	titleFontWeight = 300,
	updatedDate,
	allValueText,
	fontStyle,
	color,
	policyDetails
}: {
	title?: string | undefined | any;
	value?: number | string | undefined | 0;
	policyCounts?: number | 0;
	hidePolicyCounts?: true | false;
	hideLastUpdate?: true | false;
	hideValue?: true | false;
	titleClassName?: string | undefined;
	titleFontWeight?: number | 300;
	updatedDate?: any;
	allValueText?: any;
	fontStyle?: any;
	color?: any;
	policyDetails?: any;
}) => {
	const { t } = useTranslation();
	return (
		<Grid item className="ml-7">
			<Grid
				item
				className="ml-7 mt-5 "
				justifyContent="flex-end"
				textAlign="right"
			>
				<Typography
					fontWeight={titleFontWeight}
					fontSize={14}
					color={color}
					fontStyle={fontStyle}
				>
					{t(title)}
				</Typography>
				{hideValue === false && (
					<Grid item container>
						<Grid item container alignItems="baseline" justifyContent="flex-end">
							<Currency amount={value} policyDetails={policyDetails} amountClass="f-28 fw-300 ml-6" isSmallRupee={false} />
							{/* <Grid item className="h-20 mt-10">
								<SvgConverter
									Icon={RupeeIcon}
									className="d-flex align-items-end mb-5"
								/>
							</Grid>
							<Grid item>
								<Typography
									fontWeight={300}
									fontSize={28}
									lineHeight={1}
									className={`ml-6 ${titleClassName}`}
								>
									{value}
								</Typography>
							</Grid> */}
							<Typography
								fontSize={10}
								color={theme.palette.grey[400]}
								className="text-wrap"
							>
								{allValueText}
							</Typography>
						</Grid>
					</Grid>
				)}

				{hidePolicyCounts === false && (
					<Grid item>
						<Typography fontSize={14}>
							{t('FROM')}
							<span
								className="fw-600"
								style={{ color: theme.palette.primary.main }}
							>
								{policyCounts}
							</span>{' '}
							<span style={{ color: theme.palette.primary.main }}>
								{policyCounts > 1 ? t('POLICIES') : t('POLICY')}
							</span>
						</Typography>
					</Grid>
				)}

				{hideLastUpdate === false && (
					<Grid item>
						<Typography
							fontSize={10}
							color={theme.palette.grey[400]}
							className="text-wrap"
						>
							{/* [Last Updated on {updatedDate}] */}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};
export default CurrentValueCard;
