import React from 'react';
import { Grid } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';
import ProductDetails from './ProductDetails';

interface Props {
	Icon?: any;
	logoSrc?: any;
	product?: string;
	selfName?: string;
	showValues?: any;
}

const SelectedRecommendProductDetails = ({
	Icon,
	logoSrc,
	product,
	selfName,
	showValues,
}: Props) => (
	<Grid item className="recommend-select-icon">
		<Grid
			container
			flexWrap="nowrap"
			alignItems="center"
			justifyContent="space-between"
			spacing={1}
			className="p-10"
		>
			<Grid item>
				<Grid container flexWrap="nowrap" spacing={1} alignItems="center">
					<Grid item>
						<img src={logoSrc} alt="logo" className="bank_logo" />
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<ProductDetails
					product={product}
					selfName={selfName}
					showValues={showValues}
				/>
			</Grid>
			<Grid item>
				<SvgConverter Icon={Icon} width={39} height={39} />
			</Grid>
		</Grid>
	</Grid>
);

export default SelectedRecommendProductDetails;
