import { Grid } from '@mui/material';
import React from 'react';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import {
	myOptionsRequest,
	selectMyOptionIsLoading,
} from '../../LoanModule/Loan.slice';

import Spinner from '../../../../common/ui/Spinner';

interface UserProps {
	item?: any;
}
const PolicyServices = ({ item }: UserProps) => {
	// const policyServicesData = [
	// 	'Surrender Policy',
	// 	'Update Address',
	// 	'Update Mobile',
	// 	'Update email',
	// 	'Update Bank Details',
	// 	'Add/Remove Nominees',
	// 	'Fund Switch/Allocation [if applicable]',
	// ];

	const { t } = useTranslation();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const myOptionLoading = useSelector(selectMyOptionIsLoading);
	const removeLocalStorageValues = () => {
		const keysToRemove = [
			'lenderdata',
			'sessionId',
			'currentIndex',
			'esignLink',
			'sessionDate',
		];
		keysToRemove.forEach((key: any) => {
			localStorage.removeItem(key);
		});
	};
	const handleLoanClick = () => {
		removeLocalStorageValues();
		const type = 'Policy';
		const payload: any = {
			policyNo: item?.policyNo,
			companyCode: item?.companyCode,
			// policyNo: "2K552069708",
			// companyCode: "20",
			navigate,
			type,
		};
		dispatch(myOptionsRequest(payload));
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				{process.env.REACT_APP_MODE !== 'PROD' && (
					// eslint-disable-next-line
					<Link to={APP_ROUTES.SURRENDER_POLICY} state={{ surrenderPolicy: item }}>
						<Typography
							className="f-16 fw-600 px-12 py-13"
							color={theme.palette.common.black}
						>
							{t('SURRENDER_POLICY')}
						</Typography>
						<Separator color={theme.palette.primary.main} />
					</Link>
				)}
				<Link to={APP_ROUTES.MANAGE_ADDRESS} className={(item?.insIntegFlag?.toUpperCase() !== 'Y' && item?.addressEndoFlag?.toUpperCase() !== 'Y') ? 'disabled-link' : ''}>
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('UPDATE_ADDRESS')}
					</Typography>
				</Link>
				<Separator color={theme.palette.primary.main} />
				<Link to={APP_ROUTES.UPDATE_MOBILE} className={(item?.insIntegFlag?.toUpperCase() !== 'Y' && item?.mobileEndoFlag?.toUpperCase() !== 'Y') ? 'disabled-link' : ''}>
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('UPDATE_MOBILE')}
					</Typography>
				</Link>
				<Separator color={theme.palette.primary.main} />
				<Link to={APP_ROUTES.EMAIL} className={(item?.insIntegFlag?.toUpperCase() !== 'Y' && item?.emailEndoFlag?.toUpperCase() !== 'Y') ? 'disabled-link' : ''}>
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('UPDATE_EMAIL')}
					</Typography>
				</Link>
				<Separator color={theme.palette.primary.main} />
				<Link to={APP_ROUTES.BANK} className={(item?.insIntegFlag?.toUpperCase() !== 'Y' && item?.bankEndoFlag?.toUpperCase() !== 'Y') ? 'disabled-link' : ''}>
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('UPDATE_BANK_DETAILS')}
					</Typography>
				</Link>
				<Separator color={theme.palette.primary.main} />
				<Link to={APP_ROUTES.NOMINEE} className={(item?.insIntegFlag?.toUpperCase() !== 'Y' && item?.nomineeEndoFlag?.toUpperCase() !== 'Y') ? 'disabled-link' : ''}>
					{' '}
					<Typography
						className="f-16 fw-600 px-12 py-13"
						color={theme.palette.common.black}
					>
						{t('ADD/REMOVE_NOMINEES')}
					</Typography>{' '}
				</Link>
				{process.env.REACT_APP_MODE !== 'PROD' && (
					<>
						<Separator color={theme.palette.primary.main} />
						<Typography
							className="f-16 fw-600 px-12 py-13"
							color={theme.palette.common.black}
						>
							{t('FUND_SWITCH/ALLOCATION')}
						</Typography>

						<Separator color={theme.palette.primary.main} />
						{
							<Link
								to=""
								state={item}
								onClick={() => {
									myOptionLoading ? null : handleLoanClick();
								}}
							>
								<Typography
									className="f-16 fw-600 px-12 py-13"
									color={
										myOptionLoading
											? theme.palette.grey[700]
											: theme.palette.common.black
									}
									display={'flex'}
								>
									{t('GET_LOAN_AGAINST_THE_POLICY')}
								</Typography>
							</Link>
						}
					</>
				)}
			</Grid>
		</Grid>
	);
};
export default PolicyServices;
