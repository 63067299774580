import React, { useMemo, useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../common/ui/Forms/FormInput/RadioButton/RadioGroup';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import { ReactComponent as HomeIcon } from '../../../../common/icons/profile/home_icon.svg';

import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';
import { ReactComponent as Verify } from '../../../../common/icons/update-mobile/verify_orange.svg';
import { getAddressLists, selectPoliciesForAddress } from '../Address.slice';

const ChangeAddressModal = ({
	open,
	setOpen,
	onSubmit,
	selectedPolicyDetails,
	toggleAddNumberModal,
}: any) => {
	const theme = useTheme();
	const [selectedOption, setSelectedOption] = React.useState<any>(false);
	const { addressBasedPolicies, unassignedAddresses } = useSelector(
		selectPoliciesForAddress
	);

	useEffect(() => {
		if (!open) setSelectedOption(false);
	}, [open]);

	const addressList = useMemo(
		() =>
			getAddressLists(
				addressBasedPolicies,
				unassignedAddresses,
				selectedPolicyDetails
			).map((val) => ({ value: val, label: val })),
		[addressBasedPolicies, unassignedAddresses, selectedPolicyDetails]
	);

	const handleOptionChange = (value: string) => {
		setSelectedOption(value);
	};
	const { t } = useTranslation();
	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			{addressList.length !== 0 && <>
				<Typography className="f-16 fw-600" color={theme.palette.primary.main}>
					{t('SELECT_CORRESPONDENCE_ADDRESS')}
				</Typography>
				<Grid item xs={12} className="my-25 custom-scroll-bar" height="40vh">
					<CustomRadioGroup
						name="RadioGroup"
						options={addressList}
						value={selectedOption}
						onChange={handleOptionChange}
						size="small"
						inputClassName="my-5"
					/>
				</Grid>
				<Grid item className="py-2">
					<Typography
						className="f-16 fw-600"
						color={theme.palette.primary.main}
					>
						{t('OR')}
					</Typography>
				</Grid>
			</>}
			<Grid container direction="column">

				<Grid item>
					<CustomButton
						text={t('ADD_AN_ADDRESS')}
						variant="text"
						color="primary"
						showIcon={false}
						onClick={() => toggleAddNumberModal(true, true)}
						startIcon={HomeIcon}
						className="py-15"
					/>
				</Grid>
			</Grid>
			<Grid item className="py-5">
				<Separator color={theme.palette.primary.main} />
			</Grid>
			<Grid container textAlign="center">
				<Grid item xs={12} className="py-10 ">
					<CustomButton
						text={t('CHANGE_ADDRESS')}
						variant="text"
						color="primary"
						showIcon={false}
						startIcon={Verify}
						onClick={() => onSubmit(selectedOption)}
						type="submit"
						fontWeight={600}
						fontSize={16}
						disabled={!selectedOption}
					/>
				</Grid>
				<Grid item xs={12} className="py-10">
					<CustomButton
						text={t('CANCEL')}
						variant="text"
						color="primary"
						showIcon={false}
						startIcon={Cancel}
						fontSize={16}
						onClick={() => setOpen(false)}
					/>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default ChangeAddressModal;
