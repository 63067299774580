import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/dashboard/calendar_orange_ruppee_icon.svg';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../../common/utils';

interface userProps {
	loan?: any;
}

const TrackerCard = ({ loan }: userProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Grid container spacing={1} className="py-25" mx={2}>
			<Grid item xs={12}>
				<Typography
					fontWeight={600}
					fontSize={14}
					color={theme.palette.common.black}
				>
					{t('LOAN_POLICY')}
				</Typography>
			</Grid>

			<Grid container item xs={12} md={8}>
				<Grid item xs={4} sm={2} md={2}>
					<img src={loan?.insurerLogo} className="bank_logo" alt="logo" />
				</Grid>
				<Grid item ml={1} xs={6}>
					<Typography
						fontWeight={600}
						fontSize={16}
						color={theme.palette.primary.main}
					>
						{loan?.productType}
					</Typography>
					<Typography fontSize={12} color={theme.palette.primary.main}>
						{t('LIFE_ASSURED')}
					</Typography>
					<Typography
						fontWeight={600}
						fontSize={14}
						color={theme.palette.common.black}
					>
						{loan?.payerName || `[${t('SELF_NAME')}]`}
					</Typography>
					<Typography fontSize={12} color={theme.palette.primary.main}>
						{t('PREMIUM')}
					</Typography>
					<Grid item container xs={12}>
						<Grid item xs={12}>
							<LabelWithIcon
								svgClassName=""
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={convertAmount(loan?.premium)}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 14,
									fontWeight: 600,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography
								fontSize={14}
								color={theme.palette.common.black}
								className="ml-4"
							>
								{t('ANNUAL')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TrackerCard;
