/* eslint-disable react/require-default-props */
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
const { defaults } = ChartJS;
defaults.plugins.legend.display = true;
defaults.plugins.legend.labels.boxWidth = 14;
defaults.plugins.legend.labels.boxHeight = 14;
defaults.plugins.legend.labels.useBorderRadius = true;
defaults.plugins.legend.labels.borderRadius = 14;

interface UserProps {
	freelookPeriod?: any;
}
export const DoughnutWithPercentage: React.FC<UserProps> = ({
	freelookPeriod,
}) => {
	const period2 = 100 - freelookPeriod;
	const period1 = freelookPeriod?.toString();

	const chartData = {
		datasets: [
			{
				data: [period2, freelookPeriod],
				backgroundColor: [' #f99117', 'rgba(215, 215, 215, 1)'],
				borderWidth: 1,
				cutout: '83%',
				radius: '70%',
			},
		],
		options: {
			plugins: {
				legend: {
					display: false, // Set to false to hide the legend
				},
				labels: {
					display: false, // Set to false to hide the labels
				},
			},
		},
	};

	const textCenter = {
		id: 'textCenter',
		beforeDatasetsDraw(chart: any) {
			const { ctx } = chart;
			ctx.save();
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.font = '18px Arial';

			const text1 = period1;
			const text2 = 'days left';

			const centerPoint = chart.getDatasetMeta(0).data[0];
			const centerX = centerPoint.x;
			const centerY = centerPoint.y;
			const lineHeight = 20;

			ctx.fillText(text1, centerX, centerY - lineHeight / 2);
			ctx.fillText(text2, centerX, centerY + lineHeight / 2);

			ctx.restore();
		},
	};

	return (
		<div style={{ position: 'relative', left: '-95px' }}>
			<Doughnut
				data={chartData}
				options={{ responsive: false }}
				plugins={[textCenter]}
			/>
		</div>
	);
};

export default DoughnutWithPercentage;
