import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import CustomButton from '../../../common/ui/CustomButton';
import NeedSupport from '../common/NeedSupport';
import { maskEmail, maskMobileNumber } from '../../../common/utils';

const OtpDesign = ({
	handleResendOtp,
	isOtpRequestLoading,
	onVerifyOtpClick,
	isOtpValidationLoading,
	otp,
	setOtp,
	mobileNo,
	seconds,
	setSeconds,
	email,
	inputType,
	panType,
	HideSupport,
}: any) => {
	// eslint-disable-next-line no-unused-vars
	const [enterOtp, setEnterOtp] = useState(false);
	const [isValid, setIsValid] = useState(false);
	// const [seconds, setSeconds] = useState(config.otpDuration);
	const navigation = useNavigate();
	const theme = useTheme();
	const isNumberEmpty = !mobileNo?.length;
	const isEmailEmpty = !email?.length;

	const { t } = useTranslation();

	const handleOtpChange = (otpValue: string) => {
		setEnterOtp(true);
		setOtp(otpValue);
		setIsValid(otpValue.length === 6);
	};
	// const getInputType = () => {
	// 	if (inputType === 'email') {
	// 		return 'Email ID';
	// 	}
	// 	return 'Mobile Number';
	// };
	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				clearInterval(interval);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds]);

	return (
		<>
			<Grid container className="px-30" flexWrap="nowrap">
				<Grid
					item
					lg={12}
					xl={12}
					md={12}
					sm={12}
					xs={12}
					alignItems="center"
					justifyContent="center"
					display="flex"
					className="mt-25"
				>
					<Grid
						item
						lg={7.5}
						xl={7}
						md={10}
						sm={7}
						xs={12}
						className="mt-30 mb-5"
						justifyContent="flex-start"
						display="flex"
					>
						{HideSupport == undefined && (
							<IconButton onClick={() => navigation(-1)} className="p-0">
								<SvgConverter Icon={ArrowLeftIcon} className="go-back-icon" />
							</IconButton>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				flexDirection="column"
				className="h-100 px-30"
				flexWrap="nowrap"
				alignItems="center"
			>
				<Grid item lg={10} className="mt-5">
					<Grid container item flex={1} alignItems="center" className="mt-20">
						<Grid item className="w-100">
							<Grid
								container
								spacing={0.5}
								flexDirection="column"
								className="mt-15"
							>
								{/* signup */}
								<Grid item>
									<Typography textAlign="center" className="f-16 px-20 ">
										{t('PLEASE_ENTER_6_DIGIT_OTP_SENT')}
									</Typography>
									<Typography textAlign="center" className="f-16 px-20">
										{t('TO_THE')}{' '}
										{panType !== 'NewPan' && <> {t('REGISTERED')} </>}
										{isNumberEmpty
											? 'Email ID'
											: isEmailEmpty
												? `${t('MOBILE_NUMBER')}`
												: `${t('MOBILE_NUMBER_EMAIL')}`}
										{/* {mobileNo && email ? getInputType() : 'Mobile Number'} */}
									</Typography>
									<Typography
										justifyContent="center"
										// textAlign="center"
										align="center"
										color={theme.palette.secondary.main}
										lineHeight="unset"
										fontSize={20}
										fontWeight={600}
										className="mt-10  px-50 word-break"
									>
										{/* {mobileNo && email
											? inputType === 'email'
												? maskEmail(email)
												: maskMobileNumber(mobileNo)
											: mobileNo
											? maskMobileNumber(mobileNo)
											: maskEmail(email)} */}
										{mobileNo
											? maskMobileNumber(mobileNo) : ''}
									</Typography>
									<Typography
										justifyContent="center"
										// textAlign="center"
										align="center"
										color={theme.palette.secondary.main}
										lineHeight="unset"
										fontSize={20}
										fontWeight={600}
										className="mt-10  px-50 word-break"
									>
										{/* {mobileNo && email
											? inputType === 'email'
												? maskEmail(email)
												: maskMobileNumber(mobileNo)
											: mobileNo
											? maskMobileNumber(mobileNo)
											: maskEmail(email)} */}
										{email
											? maskEmail(email) : ''}
									</Typography>
								</Grid>

								<Grid
									item
									className="my-21 mb-20"
									display={'flex'}
									justifyContent={'center'}
								>
									<OtpInput
										numInputs={6}
										placeholder="XXXXXX"
										value={otp}
										onChange={handleOtpChange}
										containerStyle="otp-input-container"
										isInputSecure
										isInputNum
									/>
								</Grid>
								{/* {isValid === false && enterOtp === true && (
									<Typography
										color="#d9001b"
										className="mt-0 "
										justifyContent="center"
										display="flex"
									>
										{t('ENTER_THE_VALID_OTP')}
									</Typography>
								)} */}

								{seconds > 0 ? (
									<Grid
										item
										container
										justifyContent="center"
										spacing={0.6}
										flexWrap="nowrap"
									>
										<Grid item>
											<Typography
												className="f-14 fw-400"
												color={theme.palette.primary.main}
											>
												{t('RESEND_OTP')}
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												className="f-13 fw-400"
												color={theme.palette.common.black}
											>
												{t('IN')}
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												className="f-13 fw-400"
												color={theme.palette.secondary.main}
											>
												{seconds} {t('SECONDS')}
											</Typography>
										</Grid>
									</Grid>
								) : (
									<Grid
										item
										container
										justifyContent="center"
										spacing={0.6}
										flexWrap="nowrap"
									>
										<Grid item>
											<CustomButton
												text={t('RESEND_OTP')}
												variant="text"
												color="primary"
												showIcon={false}
												// startIcon={Cancel}
												onClick={handleResendOtp}
												loading={isOtpRequestLoading}
											/>
										</Grid>
									</Grid>
								)}
								<Grid
									item
									container
									justifyContent="center"
									className="mt-50 mb-50"
								>
									<CustomButton
										onClick={onVerifyOtpClick}
										text={t('PROCEED')}
										variant="text"
										color="primary"
										className="fw-500 f-20 mt-30"
										loading={isOtpValidationLoading}
										disabled={otp?.length !== 6}
									/>
								</Grid>
								{HideSupport == undefined && <NeedSupport type="black" />}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default OtpDesign;
