/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../../../../common/utils';
import Currency from '../../../../Common/Currency';

interface InsuredPersonProps {
	insuredDetails?: any;
	detailType?: any;
}
const InsuredPerson: React.FC<InsuredPersonProps> = ({
	insuredDetails,
	detailType,
}) => {
	const { t } = useTranslation();

	return (
		<Grid container>
			<Grid item xs={11} className="mb-15">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('INSURED_PERSON')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={16}
					fontWeight={600}
				>
					{insuredDetails?.insuredPerson ? insuredDetails?.insuredPerson : '-'}
				</Typography>
				{/* {detailType === 'health' && (
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
						className="mt-10"
					>
						{t('PRODUCT_TYPE_&_COVERAGE')} 
					</Typography>
				)} */}
				{detailType === 'health' ? (
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
						className="mt-10"
					>
						{t('PRODUCT_TYPE_&_COVERAGE')}
					</Typography>
				) : (
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
						className="mt-10"
					>
						{t('TYPE_OF_COVERGAE')}
					</Typography>
				)}

				{detailType === 'health' && (
					<>
						<Typography
							color={theme.palette.common.black}
							fontSize={16}
							fontWeight={600}
						>
							{insuredDetails?.productType ? insuredDetails?.productType : '-'}
						</Typography>
					</>
				)}
				<Typography
					color={theme.palette.common.black}
					fontSize={16}
					fontWeight={400}
				>
					{insuredDetails?.typeOfCoverage
						? insuredDetails?.typeOfCoverage
						: '-'}
				</Typography>
			</Grid>
			{/* <Grid item xs={6} className="">
				
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('CAPITAL_SUM_INSURED')}
				</Typography>
				<LabelWithIcon
					className=""
					svgClassName="mb-2 "
					justifyContent="flex-start"
					Icon={OrangeRuppee}
					label={
						insuredDetails?.capitalSumInsured?.length === 0 ||
						insuredDetails?.sumAssured?.length === 0
							? '-'
							: convertAmount(insuredDetails?.capitalSumInsured)
					}
					labelProps={{
						color: theme.palette.common.black,
						fontSize: 16,
						fontWeight: 600,
					}}
				/>
			</Grid> */}

			<Grid item xs={6} className="">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('CAPITAL_SUM_INSURED')}
				</Typography>
				<Grid
					container
					alignItems="center"
				>
					<Currency policyDetails={insuredDetails} amount={
						insuredDetails?.alternateCode === 'H' &&
							insuredDetails?.capitalSumInsured?.length !== 0
							? insuredDetails?.capitalSumInsured
							: insuredDetails?.sumAssured?.length !== 0
								? insuredDetails?.sumAssured
								: '-'
					} amountClass={"f-16 ml-5 fw-600"} />
				</Grid>
				{/* <LabelWithIcon
					className=""
					svgClassName="mb-2 "
					justifyContent="flex-start"
					Icon={OrangeRuppee}
					label={
						insuredDetails?.alternateCode === 'H' &&
						insuredDetails?.capitalSumInsured?.length !== 0
							? convertAmount(insuredDetails?.capitalSumInsured)
							: insuredDetails?.sumAssured?.length !== 0
							? convertAmount(insuredDetails?.sumAssured)
							: '-'
					}
					labelProps={{
						color: theme.palette.common.black,
						fontSize: 16,
						fontWeight: 600,
					}}
				/> */}
			</Grid>

			{detailType === 'asset' ? null : (
				<Grid item xs={5} className="">
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('FAMILY_AMOUNT')}
					</Typography>
					<Grid
						container
						alignItems="center"
					>
						<Currency policyDetails={insuredDetails} amount={
							insuredDetails?.floaterAmount?.length === 0
								? '-'
								: insuredDetails?.floaterAmount
						} amountClass={"f-16 ml-5 fw-600"} />
					</Grid>
					{/* <LabelWithIcon
						className=""
						svgClassName="mb-2 "
						justifyContent="flex-start"
						Icon={OrangeRuppee}
						label={
							insuredDetails?.floaterAmount?.length === 0
								? '-'
								: convertAmount(insuredDetails?.floaterAmount)
						}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 16,
							fontWeight: 600,
						}}
					/> */}
				</Grid>
			)}
		</Grid>
	);
};

export default InsuredPerson;
