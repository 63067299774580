import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/dashboard/calendar_orange_ruppee_icon.svg';

import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import Separator from '../../../../common/ui/Separator';
import { convertAmount } from '../../../../common/utils';

interface userProps {
	loan?: any;
}
const FinancierCard = ({ loan }: userProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Grid container spacing={1} className="py-25" mx={1} mb={1}>
			<Grid item xs={12}>
				<Typography
					fontWeight={600}
					fontSize={14}
					color={theme.palette.common.black}
				>
					{t('FINANCIER')}
				</Typography>
				<Typography
					fontWeight={600}
					fontSize={16}
					color={theme.palette.primary.main}
				>
					{loan?.Lender?.lenderName}
				</Typography>
				<Typography fontSize={12} color={theme.palette.primary.main}>
					{t('LOAN_AMOUNT')}
				</Typography>
				<Grid item container xs={8} md={4}>
					<Grid item xs={6} md={4}>
						<LabelWithIcon
							svgClassName=""
							justifyContent="flex-start"
							Icon={RupeeIcon}
							label={convertAmount(loan?.Lender?.loanAmount)}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 14,
								fontWeight: 600,
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography
							fontSize={14}
							color={theme.palette.common.black}
							className="ml-4"
						>
							@ {loan?.Lender?.interestPercentage} {t('INTEREST')}
						</Typography>
					</Grid>
				</Grid>{' '}
				<Grid item xs={8} md={4} className="py-10">
					<Separator color={theme.palette.common.black} />
				</Grid>
				<Typography fontSize={12} color={theme.palette.primary.main}>
					{t('EMI')}
				</Typography>
				<Grid item container xs={8} md={4}>
					<Grid item xs={6} md={4}>
						<LabelWithIcon
							svgClassName=""
							justifyContent="flex-start"
							Icon={RupeeIcon}
							label={convertAmount(loan?.Lender?.eMIAmount)}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 14,
								fontWeight: 600,
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography
							fontSize={14}
							color={theme.palette.common.black}
							className="ml-4"
						>
							{t('PER_MONTH')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default FinancierCard;
