import React from 'react';
import { Grid, useTheme, Button, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PolicyCardHeader from './common/PolicyCardHeader';
import { APP_ROUTES } from '../../../common/constants/Routes';
import Separator from '../../../common/ui/Separator';
import CustomButton from '../../../common/ui/CustomButton';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RightArrowIcon } from '../../../common/icons/right_arrow-icon.svg';
import { ReactComponent as plusIcon } from '../../../common/icons/plus-icon.svg';
import HelpComponent from './HelpComponent';

interface user {
	type?: any;
}
const DashboradAddPolicyButton = ({ type }: user) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div>
			<>
				{type !== 'calender' && (
					<div className="mb-30 life-res">
						<PolicyCardHeader type={type} />
					</div>
				)}

				<Grid
					container
					flexDirection="column"
					spacing={3}
					justifyContent="space-between"
					className={
						type === 'calender' ? 'dashBoard_grid_calender' : 'dashBoard_grid'
					}
					bgcolor=""
				>
					<Typography
						fontWeight={700}
						className={
							type === 'calender' ? 'dashboard_text_calender' : 'dashboard_text'
						}
						fontSize={16}
						color={theme.palette.primary.dark}
					>
						{t('PLEASE_ADD_INSURANCE_POLICIES')}
					</Typography>

					<Button
						className="dashboard_addPolicy_button"
						onClick={() => navigate(APP_ROUTES.ADD_POLICY_INSTRUCTION)}
					>
						{t('ADD_POLICIES')}
					</Button>
				</Grid>
				{type === 'calender' && (
					<HelpComponent />
					// <Grid
					// 	container
					// 	flexDirection="column"
					// 	spacing={3}
					// 	justifyContent="space-between"
					// 	className="dashBoard_grid_calender_below"
					// >
					// 	<div className="">
					// 		<div className="my-20">
					// 			<Separator color={theme.palette.primary.main} />
					// 		</div>
					// 		<Typography
					// 			fontSize={16}
					// 			color="primary"
					// 			className="mb-25"
					// 			fontWeight={600}
					// 		>
					// 			{`${t('HOW_MAY_WE_HELP')}?`}
					// 		</Typography>
					// 		<div className="mb-10">
					// 			<Separator color={theme.palette.grey[300]} />
					// 		</div>
					// 		<Grid
					// 			display="flex"
					// 			justifyContent="space-between"
					// 			className="my-20"
					// 		>
					// 			<div>
					// 				<CustomButton
					// 					text={`${t('HOW_TO_ADD_POLICIES')}?`}
					// 					color="primary"
					// 					startIcon={plusIcon}
					// 					variant="text"
					// 					showIcon={false}
					// 					onClick={() => navigate(APP_ROUTES.ADD_POLICY_INSTRUCTION)}
					// 					fontSize={14}
					// 					className="fw-600 mb-5"
					// 				/>
					// 			</div>
					// 			<div>
					// 				<Grid className="dashboard-policy-card-header">
					// 					<IconButton
					// 						className="p-0"
					// 						onClick={() => navigate(APP_ROUTES.POLICY)}
					// 					>
					// 						<SvgConverter
					// 							Icon={RightArrowIcon}
					// 							className="d-flex align-items-end next-arrow"
					// 						/>
					// 					</IconButton>
					// 				</Grid>
					// 			</div>
					// 		</Grid>
					// 		<div className="my-5">
					// 			<Separator color={theme.palette.grey[300]} />
					// 		</div>
					// 	</div>
					// </Grid>
				)}
			</>
		</div>
	);
};

export default DashboradAddPolicyButton;
