import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid/Grid';
import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Link } from 'react-router-dom';
import { Box, Tab } from '@mui/material';
import './profile.css';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import PersonalProfileScreen from './PersonalProfileScreen';
import { ReactComponent as plusIcon } from '../../../common/icons/plus-icon.svg';
import CustomButton from '../../../common/ui/CustomButton';
import ModalDetailsCard from './AddFamilyMember/AddFamilyMemberContent';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import FamilyScreen from './FamilyScreen';
import SvgConverter from '../../../common/SvgConverter';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import AddFamilyMemberModal from './AddFamilyMember/AddFamilyMemberModal';
import ApiModal from '../../../common/ui/Modal/ApiModal';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const ProfileScreen = ({ values }: any) => {
	const [value, setValue] = useState('Personal');
	const { t } = useTranslation();
	const [modal, setModal] = useState(false);
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};
	useEffect(() => {
		if (values) {
			setValue('Family');
		} else {
			setValue('Personal');
		}
	}, [values]);
	const [anchorEle, setAnchorEle] = React.useState<HTMLButtonElement | null>(
		null
	);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEle(event.currentTarget);
	};
	const handleClosed = () => {
		setAnchorEle(null);
	};

	const open = Boolean(anchorEle);

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Profile Screen',
		});
	}, []);

	return (
		<>
			<Grid container justifyContent="space-between">
				<Link to={APP_ROUTES.DASHBOARD}>
					<Grid container>
						<Grid item className="mr-13">
							<SvgConverter
								Icon={ArrowLeftIcon}
								className="go-back-icon d-flex"
							/>
						</Grid>
						<Grid item>
							<Typography fontSize={16} color="primary" fontWeight={600}>
								{t("PROFILE")}
							</Typography>
						</Grid>
					</Grid>
				</Link>
				<Grid item>
					{value === 'Family' && <Grid container justifyContent="flex-end">
						<Grid item>
							<CustomButton
								text={t('ADD_A_MEMBER')}
								color="primary"
								startIcon={plusIcon}
								variant="text"
								showIcon={false}
								onClick={() => setModal(true)}
								fontSize={14}
								className="fw-400"
							/>

							{/* <ModalDetailsCard
								open={open}
								setAnchorEle={setAnchorEle}
								handleClosed={handleClosed}
							/> */}
							<AddFamilyMemberModal open={modal} setOpen={setModal} />
						</Grid>
					</Grid>}
				</Grid>
			</Grid>
			<Box>
				<TabContext value={value}>
					<Box className="ml-20">
						<TabList
							onChange={handleChange}
							TabIndicatorProps={{
								style: { display: 'none' },
							}}
							textColor="inherit"
							variant="scrollable"
						>
							<Tab
								label={t('PERSONAL')}
								value="Personal"
								sx={{
									fontWeight: value === 'Personal' ? 600 : 400,
									color: value === 'Personal' ? 'black' : 'black',
									textTransform: 'none',
									fontSize: 18,
								}}
								className="mt-20 mr-5"
							/>

							<Grid item xs={1} className="separator">
								<Separator
									orientation="vertical"
									color={theme.palette.secondary.main}
									borderWidth={3}
									className="h-55 mt-30"
								/>
							</Grid>

							<Tab
								label={t('FAMILY_OTHERS')}
								value="Family"
								sx={{
									fontWeight: value === 'Family' ? 600 : 400,
									color: value === 'Family' ? 'black' : 'black',
									textTransform: 'none',
									fontSize: 18,
								}}
								className="mt-20 mr-5"
							/>
						</TabList>
						<Grid className="ml-5 sep-width mt-3">
							<Separator color={theme.palette.primary.dark} />
						</Grid>
					</Box>
					<TabPanel value="Personal">
						<PersonalProfileScreen />
					</TabPanel>
					<TabPanel value="Family">
						<FamilyScreen />
					</TabPanel>
				</TabContext>
				<ApiModal />
			</Box>
		</>
	);
};

const Header = () => {
	const [anchorEle, setAnchorEle] = React.useState<HTMLButtonElement | null>(
		null
	);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEle(event.currentTarget);
	};
	const handleClosed = () => {
		setAnchorEle(null);
	};
	const { t } = useTranslation();
	const open = Boolean(anchorEle);

	return (
		<Grid container justifyContent="flex-end">
			<Grid item>
				<CustomButton
					text={t('ADD_A_MEMBER')}
					color="primary"
					startIcon={plusIcon}
					variant="text"
					showIcon={false}
					onClick={handleClick}
					fontSize={14}
					className="fw-400"
				/>

				<ModalDetailsCard
					open={open}
					setAnchorEle={setAnchorEle}
					handleClosed={handleClosed}
				/>
			</Grid>
		</Grid>
	);
};

ProfileScreen.Header = Header;

export default ProfileScreen;
