import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useTheme } from '@mui/material';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as AddMobileIcon } from '../../../common/icons/update-mobile/Add_Mobile.svg';
import Separator from '../../../common/ui/Separator';
import './Mobile.scss';

const MobileHeader = ({ toggleAddNumberModal, toggleDoneModal }: any) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Grid item>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item className="header-mob">
					<Typography className="f-16 fw-600 " color="primary">
						{t('MANAGE_MOBILE_NUMBERS')}
					</Typography>
				</Grid>
				<Grid item className="header-mob-button-done">
					<CustomButton
						text={t('DONE')}
						variant="text"
						color="primary"
						showIcon
						onClick={toggleDoneModal}
						fontSize={14}
						endIcon={rightArrow}
					/>
				</Grid>
			</Grid>
			<Grid item className="mt-10 mb-5  header-mob-button-add-number">
				<CustomButton
					text={t('ADD_MOBILE_NUMBER')}
					variant="text"
					color="primary"
					showIcon={false}
					onClick={toggleAddNumberModal}
					fontSize={14}
					startIcon={AddMobileIcon}
					className="fw-600"
				/>

				<Grid item className="mt-10">
					<Separator color={theme.palette.primary.main} borderWidth={1} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MobileHeader;
