import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PaymentSuccess from './PaymentSuccess';
import {
	selectIsUploadRenewalPaymentDataLoading,
	selectPolicyRenewal,
	uploadRenewalPaymentDetailsRequest,
} from '../Payment.slice';
import { PAYMENT_GATEWAY_TYPES } from '../../../../common/Enums';
import PaymentFailed from './PaymentFailed';
import CustomButton from '../../../../common/ui/CustomButton';
import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';
import { getFormatDate } from '../../../../common/utils';

const RenewalPaymentFeedback = () => {
	const theme = useTheme();
	const location = useLocation();
	const { t } = useTranslation();
	const params = useParams();
	const type = 'renewalPayment';

	const paymentDetails = useSelector(selectPolicyRenewal);
	const formattedDate = getFormatDate(paymentDetails?.timestamp);

	const dispatch = useDispatch();
	const isUploadingPaymentData = useSelector(
		selectIsUploadRenewalPaymentDataLoading
	);
	// eslint-disable-next-line no-unused-vars
	// const routePolicyData = location?.state?.paymentData?.policyData;

	const paymentResponse = useMemo(
		() =>
			location?.state?.paymentResponse
				? location?.state?.paymentResponse
				: false,
		[location]
	);

	const handleRazorPayPaymentUpload = () => {
		const payload = {
			policyDetail: {
				action: 'StorePayment',
				transactionId: paymentDetails?.transactionId,
				rzpOrderId: paymentResponse?.order_id ? paymentResponse?.order_id : 0,
				rzpPaymentId: paymentResponse?.razorpay_payment_id,
				rzpDigitalSign:
					paymentResponse?.razorpay_signature === undefined
						? 0
						: paymentResponse.razorpay_signature,
				policyNo: paymentDetails?.previousPolicyNo,
				insuranceCmpnyCd: paymentDetails?.insurerCode,
				alternateCode: paymentDetails?.alternateCode,
				premiumAmount: paymentDetails?.premiumAmount,
				responseTime: paymentDetails?.responseTime,
				mobile: paymentDetails?.mobile,
				policyStatus: paymentDetails?.policyStatus,
				productName: paymentDetails?.productName,
				customerName: paymentDetails?.customerName,
				annualGrossPremium: paymentDetails?.annualGrossPremium,
				sumInsured: paymentDetails?.sumInsured,
				renewalQuoteNumber: paymentDetails?.renewalQuoteNumber,
				renewalPremiumAmount: paymentDetails?.renewalPremiumAmount,
				renewalDueDate: paymentDetails?.renewalDueDate,
				startTime: paymentDetails?.startTime,

				insuranceCmpnyName: paymentDetails?.insurerCompanyName,
				trxnDate: formattedDate,
				trxnAmt: paymentDetails?.premiumAmount,
				previousPolicyNo: paymentDetails?.previousPolicyNo,
				email: paymentDetails?.email,
				paymentMode: "",
				cardType: ""
			},
			// policyDetail: {
			// 	eiaNo: '5000000000378',
			// 	policyNo: '0000000000358075',
			// 	action: 'StorePayment',
			// 	insuranceCmpnyCd: '117',
			// 	alternateCode: 'L',
			// 	premiumAmount: 2095,
			// 	responseTime: '21-Sep-2023 18:35:22:3522',
			// 	mobile: '4534455645',
			// 	policyStatus: 'Accept',
			// 	productName: 'Arogya Sanjeevani',
			// 	customerName: 'Anudeep  Deepa',
			// 	transactionId: 'FY110820231104389200000080507',
			// 	annualGrossPremium: '1775',
			// 	sumInsured: '100000',
			// 	renewalQuoteNumber: '0000000004971334',
			// 	renewalPremiumAmount: '2095',
			// 	renewalDueDate: '2023-10-05 23:59:00.0',
			// 	startTime: '2022-10-06 00:00:00.0',
			// 	rzpOrderId: 'order_MezmEnXlYkOzAY',
			// 	rzpPaymentId: 'pay_MOeFxhSO9NTSEx',
			// 	rzpDigitalSign:
			// 		'8c26d07abf482b7db78724a4601b7dec87fc208207319959bb1d547e8c133a86',
			// 	insuranceCmpnyName: 'SBI General Insurance Co. Ltd.',
			// 	trxnDate: '10/08/2023',
			// 	trxnAmt: '2095',
			// 	previousPolicyNo: '0000000000358075',
			// 	email: '',
			// },
		};

		dispatch(uploadRenewalPaymentDetailsRequest(payload as any));
	};
	useEffect(() => {
		if (location?.state?.gateWayType === PAYMENT_GATEWAY_TYPES.RAZORPAY) {
			handleRazorPayPaymentUpload();
		}
	}, [location?.state?.gateWayType, location?.state?.status]);

	return (
		<>
			{isUploadingPaymentData ? (
				<Grid
					className="vh-95"
					container
					justifyContent="center"
					alignItems="center"
				>
					<Grid item className="text-center">
						<CircularProgress />
					</Grid>
				</Grid>
			) : (
				<Grid container justifyContent="center">
					<Grid item>
						<div style={{ width: 290 }}>
							<Grid item xs={12} className="mt-10">
								<Grid container justifyContent="space-between">
									<Typography
										fontSize={16}
										fontWeight={600}
										color={theme.palette.primary.main}
									>
										{t('PREMIUM_PAYMENT')}
									</Typography>
									<Link to="/" replace>
										<CustomButton
											className="fw-500 close-style f-14"
											text={t('CLOSE')}
											variant="text"
											color="primary"
											endIcon={Cancel}
											showIcon
										/>
									</Link>
								</Grid>
							</Grid>
							{params?.paymentStatus === 'success' ? (
								<PaymentSuccess
									amount={location?.state?.paymentData?.amount}
									transactionId={location?.state?.paymentData?.transactionId}
									state={paymentDetails}
									type={type}
									paymentResponse={paymentResponse}
								/>
							) : (
								<PaymentFailed
									amount={location?.state?.paymentData?.amount}
									transactionId={location?.state?.paymentData?.transactionId}
									paymentResponse={paymentResponse}
									state={paymentDetails}
									type={type}
								/>
							)}
						</div>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default RenewalPaymentFeedback;
