import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import { ReactComponent as SmRupeeIcon } from '../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../common/utils';

interface MaturityAndFundValueProps {
	showValuesForThird?: boolean;
	maturityValue?: any;
}

const MaturityAndFundValue = ({
	showValuesForThird = false,
	maturityValue,
}: MaturityAndFundValueProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<>
			{!showValuesForThird ? (
				<Grid item>
					<Grid container spacing={0.5}>
						<Grid item xs={5}>
							<Typography className="f-14" color={theme.palette.primary.dark}>
								{t('Maturity Value')}
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Grid container spacing={0.5}>
								<LabelWithIcon
									className="px-6"
									justifyContent="flex-start"
									Icon={RupeeIcon}
									label={convertAmount(maturityValue?.maturityValue)}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 16,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={0.5}>
						<Grid item xs={5}>
							<Typography className="f-14" color={theme.palette.primary.dark}>
								{t('Fund Value')}
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Grid container spacing={0.5}>
								<LabelWithIcon
									className="px-6"
									svgClassName=""
									justifyContent="flex-start"
									Icon={RupeeIcon}
									label={convertAmount(maturityValue?.fundValue)}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 16,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid container className="py-5 px-12">
					<Grid className="mr-20">
						<Grid item>
							<Typography className="f-12" color={theme.palette.primary.dark}>
								{t('Maturity Value')}
							</Typography>
						</Grid>
						<Grid item>
							<Grid container spacing={0.5}>
								<LabelWithIcon
									className="px-6"
									justifyContent="flex-start"
									Icon={SmRupeeIcon}
									label={convertAmount(maturityValue?.maturityValue)}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 12,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid spacing={0.5}>
						<Grid item>
							<Typography className="f-12" color={theme.palette.primary.dark}>
								{t('Fund Value')}
							</Typography>
						</Grid>
						<Grid item>
							<Grid container spacing={0.5}>
								<LabelWithIcon
									className="px-6"
									svgClassName=""
									justifyContent="flex-start"
									Icon={SmRupeeIcon}
									label={convertAmount(maturityValue?.fundValue)}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 12,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default MaturityAndFundValue;
