const ROUTES = {
	accessTokenRequest: {
		url: '/api-security-service/v1/getAccessToken',
	},
	loginRequest: {
		url: 'LoginValidation',
	},
	logout: {
		url: 'LogoffValidation',
	},
	resendOtpRequest: {
		url: 'ResendOTP',
	},
	verifyOtpRequest: {
		url: 'OTPValidation',
	},
	setMpinRequest: {
		url: 'SaveMPIN',
	},
	verifyMpinRequest: {
		url: 'MPINValidation',
	},
	signUp: {
		url: 'SignUp',
	},
	initiateEkyc: {
		url: 'EkycInititate',
	},
	saveEkycData: {
		url: 'SaveKYCData',
	},
	saveARData: {
		url: 'UpdateARdetails',
	},

	// policies
	policy: {
		url: 'ListPolicy',
	},
	policyDocument: {
		url: 'DocumentDownloadByFlag',
	},
	premiumReceipts: {
		url: 'PremiumReceipts',
	},
	listInsurer: {
		url: 'ListInsurerWithLogo',
	},
	addPolicy: {
		url: 'AddNewPolicies',
	},
	viewPolicy: {
		url: 'ViewPolicyDetails',
	},
	copyEIAData: {
		url: 'CopyEIADataToPolicies',
	},
	sendCommunication: {
		url: 'SendCommunication',
	},
	SavePortingDetails: {
		url: 'SavePortingDetails',
	},
};

const ProfileRoutes = {
	userProfile: {
		url: 'ProfileData',
	},
	familyList: {
		url: 'ListFamilyMembers',
	},
	addFamilyMember: {
		url: 'AddFamilyMember',
	},
	familyRelationShip: {
		url: 'ListRelationships',
	},
	generateOtpForMobileEmail: {
		url: 'GenerateOTP',
	},
	verifyOtpForMobileEmail: {
		url: 'OTPValidation',
	},
	saveFamily: {
		url: 'SaveVerificationStatus',
	},
	updateAr: {
		url: 'UpdateARdetails',
	},
	pincode: {
		url: 'GetPincode',
	},
	addAddress: {
		url: 'AddAddressFamilyMember',
	},
};

const MobileNumberRoutes = {
	listAllMobileNumber: {
		url: 'ListMobileNo_Email',
	},
	listAllPolicies: {
		url: 'ListPoliciesForMobile',
	},
	updateMobileNumbers: {
		url: 'UpdatePolicyMobileEmail',
	},
	generateOtpForMobile: {
		url: 'GenerateOTP',
	},
	verifyOtpForMobile: {
		url: 'OTPValidation',
	},
};

const EmailRoutes = {
	listAllPoliciesForEmail: {
		url: 'ListPoliciesForEmail',
	},
	generateOtpForEmail: {
		url: 'GenerateOTP',
	},
	verifyOtpForEmail: {
		url: 'OTPValidation',
	},
	updateEmail: {
		url: 'UpdatePolicyMobileEmail',
	},
};

const AddressRoutes = {
	listAllAddress: {
		url: 'ListofAddress',
	},
	listAllPoliciesForAddress: {
		url: 'ListPoliciesForAddress',
	},
	finalSubmitForAddress: {
		url: 'UpdatePolicyAddress',
	},
};

const BankRoutes = {
	listAllBanks: {
		url: 'ListBank',
	},
	listAllPoliciesForBanks: {
		url: 'ListPoliciesForBank',
	},
	generateOtpForBank: {
		url: 'GenerateOTP',
	},
	verifyOtpForBank: {
		url: 'AddMobileEmailAddressBankInWorkflow',
	},
	updateForBank: {
		url: 'UpdatePolicyBank',
	},
	uploadCanceledCheque: {
		url: 'UploadCheque',
	},
};

const utils = {
	upload: {
		url: 'UploadImage',
	},
	uploadFamily: {
		url: 'UploadFamilyImage',
	},
};

const DashboardRoutes = {
	policiesList: {
		url: 'Dashboard',
	},
	missingInfo: {
		url: 'GetMissingInfo',
	},
	policyRejection: {
		url: 'UpdateDashboard',
	},
};

const NomineeRoutes = {
	nominesLists: {
		url: 'NomineeList',
	},
	editNominee: {
		url: 'UpdateNomineeList',
	},
	familiesList: {
		url: 'ListFamilyMembers',
	},
};

const NotificationsRoutes = {
	notificationsList: {
		url: 'ListNotifications',
	},
};
const SupportRoutes = {
	supportOptionsList: {
		url: 'ListSupportCategory',
	},
	raiseNewSupport: {
		url: 'CreateSupportRequest',
	},
};
const LoanRoutes = {
	addMoney: {
		url: '',
	},
	myOptions: { url: 'da/GetCombinationList' },
	selectedCombination: { url: 'da/GetLoanLenderList' },
	saveSelectedCombination: { url: 'da/SaveSelectedCombination' },
	trackTransaction: { url: 'da/getTrackTransaction' },
	fetchPayoutList: { url: 'da/FetchPayoutList' },
	dashboardTracker: { url: ' ' },
	signatureReminder: { url: 'da/SignatureRemainder' }
};
const PolicyRenewalRoutes = {
	PolicyRenewal: {
		url: 'insIntegration/PolicyRenewal',
	},
};

const AddPolicyRoutes = {
	InsuranceSamadhanPolicyDetail: {
		url: 'InsurerSamadhanPolicyDetail',
	},
	InsSamadhanUploadDoc: {
		url: "InsSamadhanUploadDoc"
	}
};

const SurrenderpolicyRoutes = {
	SurrenderDetails: {
		url: 'da/SurrenderDetails',
	},
	InitiatePolicySurrender: {
		url: 'da/InitiatePolicySurrender',
	},
};

export {
	DashboardRoutes,
	ProfileRoutes,
	MobileNumberRoutes,
	EmailRoutes,
	AddressRoutes,
	BankRoutes,
	utils,
	NomineeRoutes,
	NotificationsRoutes,
	SupportRoutes,
	LoanRoutes,
	PolicyRenewalRoutes,
	AddPolicyRoutes,
	SurrenderpolicyRoutes
};

export default ROUTES;
