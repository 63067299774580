import React, { useState, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CaseScreens.scss';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../../common/ui/CustomButton';
import Separator from '../../../common/ui/Separator';
import EiaModalDetails from './EiaModalDetails';
import theme from '../../../common/theme';
import {
	copyEIADataRequest,
	fetchOtpRequest,
	selectCopyEIADataLoading,
	setUser,
} from '../auth.slice';
import { getFormatDate } from '../../../common/utils';
import { ReactComponent as AlertIcon } from '../../../common/icons/ekyc_detailsone_alert_icon.svg';
import { ReactComponent as AlertIconBlue } from '../../../common/icons/warningBlue.svg.svg';
import { ReactComponent as AlertIconRed } from '../../../common/icons/warningRed.svg.svg';
import SvgConverter from '../../../common/SvgConverter';
import {
	APP_ROUTES,
	AUTH_ROUTES,
	PUBLIC_ROUTES,
} from '../../../common/constants/Routes';
import EmailEntryModal from '../../App/Common/EmailEntryModal';
import {
	generateOtpForEmailRequest,
	verifyEmailRequest,
} from '../../App/Email/Emails.slice';
import config from '../../../common/config';
import OtpEntryModal from '../../App/Common/OtpEntryModal';
import MobileEntryModal from '../../App/Common/MobileEntryModal';
import {
	generateOtpForMobileRequest,
	verifyMobileNoRequest,
} from '../../App/MobileNumber/MobileNumber.slice';
import cryptoEncryptionDecryption from '../../../common/crypto';
import FormCheckBox from '../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import { TermsCondition } from './TermsConditionModal';
import VerifiedText from '../../App/Profile/VerifiedText';
import { setAlertInfo } from '../../App/Common/Common.slice';
import TermModal from '../../App/Legal/termModal';
import PrivacyModal from '../../App/Legal/PrivacyModal';
// import EmailEntryModal from '../../App/Common/EmailEntryModal';

const SignupEkycDetailsOne = () => {
	const location = useLocation();

	const { t } = useTranslation();

	const userData = location.state?.userData;

	const dispatch = useDispatch();
	const navigation = useNavigate();
	const [showmobileAddModal, setshowmobileAddModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);

	const isCopyEIADataLoading = useSelector(selectCopyEIADataLoading);
	// const KYCResponse = useSelector(selectSaveEkycResponse);
	const [confirmModal, setConfirmModal] = useState(false);
	const [isCopyEIAChecked, setIsCopyEIAChecked] = useState(true);
	const [verifyEmailDuration, setVerifyEmailDuration] = useState(0);
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [newEmailData, setNewEmailData] = useState<any>({
		email: userData?.email,
	});
	const [newMobileNoData, setNewMobileNoData] = useState<any>({
		mobileNo: userData?.mobileNo,
	});
	const [verifyMobileDuration, setVerifyMobileDuration] = useState(0);
	const [emailStatus, setemailStatus] = useState(
		userData?.validEmail?.toUpperCase() === 'Y' ? true : false
	);
	const [mobileStatus, setmobileStatus] = useState(
		userData?.validMobile?.toUpperCase() === 'Y' ? true : false
	);
	const [isChecked, setIsChecked] = useState(false);
	const [termsCondition, setTermsCondition] = useState(false);
	// const [mobile, setmobile] = useState(userData?.mobileNo || '');
	// const [email, setemail] = useState(userData.email || '');
	const [isEmail, setisEmail] = useState(false);
	const [mobilePayload, setmobilePayload] = useState('');

	const [open, setOpen] = React.useState<any>(false);
	const [openprivacy, setOpenprivacy] = React.useState<any>(false);

	const handleCheckboxChange = (e: any) => {
		setIsCopyEIAChecked(e?.target?.checked);
		if (!e?.target?.checked) {
			setConfirmModal(true);
		}
	};

	const flowType = useMemo(() => location.state?.flowType, [location.state]);
	const type = 'signupEKYC';
	// const kycId = useMemo(
	//   () => (initiateEkycData?.id ? initiateEkycData?.id : {}),
	//   [initiateEkycData]
	// );

	// const kycData = useMemo(
	// 	() => (KYCResponse?.responseData ? KYCResponse?.responseData : {}),
	// 	[KYCResponse]
	// );
	// useEffect(() => {
	//   if (kycId) {
	//     saveEKyC();
	//   }
	// }, [kycId]);

	// const saveEKyC = async () => {
	//   dispatch(saveEkycRequest({ kycId } as any));
	// };

	const handleLoginFlow = () => {
		const payload: any = {
			Customer: {
				eiaNo: location?.state?.userData?.eiaNo,
				pan: location?.state?.userData?.pan,
				mobileNo: location?.state?.userData?.mobileNo,
				email: location?.state?.userData?.email,
				firstTimeLoginFlag: 'N',
				kycValidFlag: 'Y',
				arAvailable: location?.state?.userData?.arAvailable,
			},
		};
		dispatch(setUser(payload.Customer as any));
		sessionStorage.setItem(
			'userInfo',
			cryptoEncryptionDecryption.Encrypt(payload)
		);
		// sessionStorage.setItem('sessionId',cryptoEncryptionDecryption.Encrypt(payload?.Customer?.sessionId) );
		navigation(APP_ROUTES.DASHBOARD);
	};

	const handleProceed = () => {
		if (!isChecked) {
			setTermsCondition(true);
		} else if (!emailStatus || !mobileStatus) {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'INFO',
					description: 'Kindly verify your mobile number/ email id to proceed.',
				})
			);
		} else {
			const handleSuccess = () => {
				if (String(userData?.arAvailable).toUpperCase() === 'Y') {
					handleLoginFlow();
				} else {
					navigation(AUTH_ROUTES.AUTHORISED_REPRESENTATIVE, {
						state: {
							flowType,
							userData,
						},
					});
				}
			};
			if (isCopyEIAChecked) {
				dispatch(
					copyEIADataRequest({
						Customer: {
							eiaNo: userData?.eiaNo,
						},
						handleSuccess,
					})
				);
			} else {
				handleSuccess();
			}
		}
	};
	// const handleClose = () => {
	// 	setOpen(true);
	// };
	const handleModalOption = (option: string) => {
		if (option === 'unselect') {
			setIsCopyEIAChecked(false);
		} else if (option === 'select') {
			setIsCopyEIAChecked(true);
		}
		setConfirmModal(false);
	};
	const handleNewEmailSubmit = (data: any) => {
		setNewEmailData(data);
		//setemail(data?.email);
		data.handleSuccess = () => {
			setVerifyEmailDuration(config.otpDuration);
			setShowOtpModal(true);
			setShowAddModal(false);
			setisEmail(true);
		};
		const eia = userData?.eiaNo;
		const types = 'signUpEmail';

		dispatch(generateOtpForEmailRequest({ data, eia, types } as any));
	};
	const handleNewMobileSubmit = (data: any) => {
		setNewMobileNoData(data);
		//setmobile(data?.mobileNo)
		data.handleSuccess = () => {
			setShowAddModal(false);
			setshowmobileAddModal(false);
			setShowOtpModal(true);
			setVerifyMobileDuration(config.otpDuration);
		};
		const eia = userData?.eiaNo;
		const types = 'signUpEmail';
		dispatch(generateOtpForMobileRequest({ data, eia, types } as any));
	};
	const handleOtpVerify = (otpCode: any) => {
		const data: any = { otpCode, email: newEmailData?.email };

		const types = 'signUpEmail';
		const eiaNo = userData?.eiaNo;
		data.handleSuccess = () => {
			setShowOtpModal(false);
			setemailStatus(true);
			//setemail(newEmailData?.email);
			setisEmail(false);
		};
		dispatch(verifyEmailRequest({ data, types, eiaNo } as any));
	};
	const handleMobileOtpVerify = (otpCode: any) => {
		const { handleSuccess, ...newMobileNoDataLocal }: any = newMobileNoData;
		const data: any = { otpCode, mobileNo: newMobileNoDataLocal?.mobileNo };
		const types = 'signUpMobile';
		const eiaNo = userData?.eiaNo;
		data.handleSuccess = () => {
			setShowOtpModal(false);
			setmobileStatus(true);
			//setmobile(newMobileNoDataLocal?.mobileNo);
			console.log(emailStatus, mobileStatus);
		};
		dispatch(verifyMobileNoRequest({ data, types, eiaNo } as any));
	};
	const handleResendOtp = () => {
		const handleSuccess = () => {
			setVerifyEmailDuration(config.otpDuration);
		};
		const data: any = {
			eiaNo: userData?.eiaNo,
			value: newEmailData?.email,
			otpPurpose: 'PRIMARY_EMAIL',
			flag: 'EMAIL',
		};
		dispatch(
			fetchOtpRequest({
				Customer: data,
				handleSuccess,
			} as any)
		);
	};
	const handleResendOtpMobile = () => {
		const handleSuccess = () => {
			setVerifyMobileDuration(config.otpDuration);
		};
		const data: any = {
			eiaNo: userData?.eiaNo,
			value: newMobileNoData?.mobileNo,
			otpPurpose: 'PRIMARY_MOBILE',
			flag: 'MOBILE',
		};
		dispatch(
			fetchOtpRequest({
				Customer: data,
				handleSuccess,
			} as any)
		);
	};
	const handleAddMobile = () => {
		setshowmobileAddModal(true);
		setVerifyEmailDuration(config.otpDuration);
		setmobilePayload('mobile');
	};

	const handleAddEmail = () => {
		setShowAddModal(true);
		setVerifyEmailDuration(config.otpDuration);
		setmobilePayload('');
	};

	const verifyMobileNo = (code: string) => {
		code === 'M' ? handleAddMobile() : handleAddEmail();
		// code === 'M' ? setVerifyMobileDuration(config.otpDuration) : setVerifyEmailDuration(config.otpDuration);

		// code === 'M' ? handleNewMobileSubmit({ mobileNo: userData.mobileNo }) : handleNewEmailSubmit({ email: userData.email })
	};

	return (
		<Grid container justifyContent="center" mt={5}>
			<Grid item xs={11} sm={11} lg={6} md={9} xl={5}>
				<Grid item className="mb-10 mt-10">
					<Typography
						component="span"
						className="f-14 fw-600 "
						color={theme.palette.common.black}
					>
						{t('STEP')} 1/3
					</Typography>
				</Grid>

				<Grid item className="mt-15">
					<Typography
						className="f-16 fw-600"
						color={theme.palette.primary.main}
					>


						{t('E-INSURANCE_INFORMATION')}
					</Typography>
				</Grid>

				<Grid container justifyContent="space-between" className="mb-15">
					<Grid item>
						<Grid item container xs={12} className="mt-15" columnSpacing={1}>
							<Grid item xs={6}>
								<Typography
									className="f-14 fw-600"
									color={theme.palette.secondary.main}
								>
									{t('EIA_NUMBER')}
								</Typography>
								<Typography
									className="f-18 "
									color={theme.palette.common.black}
								>
									{userData?.eiaNo}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography
									className="f-14 fw-600"
									color={theme.palette.secondary.main}
								>
									{t('NAME')}
								</Typography>
								<Typography className="f-18" color={theme.palette.common.black}>
									{userData?.fullName
										? userData?.fullName
										: userData?.customerName
										? userData?.customerName
										: `${userData?.firstName}${userData?.lastName}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container xs={12} className="mt-15" columnSpacing={1}>
							<Grid item xs={6} flexWrap="nowrap">
								<Typography
									className="f-14 fw-600"
									color={theme.palette.secondary.main}
								>
									{t('DATE_OF_BIRTH')}
								</Typography>
								<Typography className="f-18" color={theme.palette.common.black}>
									{getFormatDate(userData?.dob, 'DD/MM/YYYY')}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography
									className="f-14 fw-600"
									color={theme.palette.secondary.main}
								>
									{t('PAN')}
								</Typography>
								<Typography className="f-18" color={theme.palette.common.black}>
									{userData?.pan}
								</Typography>
							</Grid>
						</Grid>

						<Grid item className="mt-15">
							<Typography
								className="f-14 fw-600"
								color={theme.palette.secondary.main}
							>
								{t('PERMANENT_ADDRESS')}
								{` [As per ${userData?.addressProofDoc || ''}]`}
							</Typography>
							<Typography className="f-18" color={theme.palette.common.black}>
								{userData?.PermanentAddress?.fullAddress}
							</Typography>
						</Grid>
						<Grid item className="mt-15">
							<Separator color={theme.palette.grey[300]} />
						</Grid>

						<Grid item className="mt-15">
							<Typography
								className="f-18 fw-600"
								color={theme.palette.primary.main}
							>
								{t('PRIMARY_CONTACT_INFORMATION')}
							</Typography>
						</Grid>
						<Grid item>
							<Grid
								container
								flexWrap="nowrap"
								// spacing={1}
								alignItems="center"
							>
								<Grid item className="mt-5" display="none">
									<div>
										<Checkbox
											checked={isCopyEIAChecked}
											onChange={handleCheckboxChange}
											color="primary"
										/>
									</div>
									<EiaModalDetails
										open={confirmModal}
										setOpen={() => handleModalOption('unselect')}
										unselectOption={() => handleModalOption('unselect')}
										optionSelected={() => handleModalOption('select')}
										type={type}
									/>
								</Grid>
								<Grid item>
									<Typography
										className="f-14"
										color={theme.palette.common.black}
									>
										{t('ALL_POLICY_UPDATE_INFORMATION')}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid container justifyContent="space-between">
							<Grid item className="mt-15" />
							{!isCopyEIAChecked ? (
								<Grid item className="mt-18">
									<SvgConverter Icon={AlertIcon} className="ml-15" />
									<Typography
										className="f-14 fw-600"
										color={theme.palette.secondary.main}
									>
										{t('WARNING')}
									</Typography>
									<CustomButton
										text={t('TAP_TO_SEE')}
										variant="text"
										className="f-14 ml-n15"
										color="primary"
										showIcon={false}
										onClick={() => setConfirmModal(true)}
									/>
								</Grid>
							) : (
								<></>
							)}
						</Grid>
						<Grid item container xs={12} className="mt-15" columnSpacing={2}>
							<Grid item xs={6} flexWrap="nowrap">
								<Grid container>
									<Typography
										className="f-14 fw-600"
										color={theme.palette.secondary.main}
									>
										{t('PRIMARY_MOBILE_NUMBER')} &nbsp;
									</Typography>
									{/* <Typography className="f-18" color={theme.palette.common.black}>
									{userData?.mobileNo}
								</Typography> */}

									{newMobileNoData?.mobileNo && (
										<Grid item className="mt-2">
											<VerifiedText
												isVerified={mobileStatus}
												label={
													mobileStatus
														? `[${t('VERIFIED')}]`
														: `[${t('NOT_VERIFIED')}]`
												}
											/>
										</Grid>
									)}
								</Grid>
								{mobileStatus && (
									<Typography
										className="f-18 "
										color={theme.palette.common.black}
									>
										{newMobileNoData?.mobileNo}
									</Typography>
								)}

								{!mobileStatus && newMobileNoData?.mobileNo && (
									<>
										<Grid item py={0.8}>
											<Separator orientation="vertical" className="mt-3" />
										</Grid>
										<Grid item flexDirection="row">
											<CustomButton
												text={t('TAP_HERE_TO_VERIFY')}
												variant="text"
												showIcon={false}
												color="primary"
												className="f-14  mt-n2 fw-400"
												onClick={() => verifyMobileNo('M')}
											/>
										</Grid>
									</>
								)}
								{!mobileStatus && (
									<Typography
										className="f-18 "
										color={
											newMobileNoData?.mobileNo &&
											newMobileNoData?.mobileNo !== 'null' &&
											newMobileNoData?.mobileNo !== '' &&
											newMobileNoData?.mobileNo !== 'undefined'
												? theme.palette.common.black
												: theme.palette.common.black
										}
									>
										{newMobileNoData?.mobileNo &&
										newMobileNoData?.mobileNo !== 'null' &&
										newMobileNoData?.mobileNo !== '' &&
										newMobileNoData?.mobileNo !== 'undefined' ? (
											newMobileNoData?.mobileNo
										) : (
											<>
												<Grid display="flex">
													<Grid>
														<Typography color="red">
															{t('NOT_AVAILABLE')}
														</Typography>
													</Grid>
													<Grid>
														<SvgConverter
															Icon={AlertIconRed}
															className="ml-15"
														/>
													</Grid>
												</Grid>
												<CustomButton
													text={t('TO_PROCEED_TAP_HERE_TO_ADD_MOBILE')}
													variant="text"
													showIcon={false}
													color="primary"
													className="fw-400 mt-20 sentence-straight_EKYC"
													onClick={handleAddMobile}
													startIcon={AlertIconBlue}
												/>
											
											</>
										)}
									</Typography>
								)}
							</Grid>
							<Grid item xs={6}>
								<Grid container>
									<Typography
										className="f-14 fw-600"
										color={theme.palette.secondary.main}
									>
										{t('PRIMARY_EMAIL')} &nbsp;
									</Typography>

									{newEmailData?.email && (
										<Grid item className="mt-2">
											<VerifiedText
												isVerified={emailStatus}
												label={
													emailStatus
														? `[${t('VERIFIED')}]`
														: `[${t('NOT_VERIFIED')}]`
												}
											/>
										</Grid>
									)}
								</Grid>

								{emailStatus && (
									<Typography
										className="f-18 "
										color={theme.palette.common.black}
									>
										{newEmailData?.email}
									</Typography>
								)}

								{!emailStatus && newEmailData?.email && (
									<>
										<Grid item py={0.8}>
											<Separator orientation="vertical" className="mt-3" />
										</Grid>
										<Grid item flexDirection="row">
											<CustomButton
												text={t('TAP_HERE_TO_VERIFY')}
												variant="text"
												showIcon={false}
												color="primary"
												className="f-14  mt-n2 fw-400"
												onClick={() => verifyMobileNo('E')}
											/>
										</Grid>
									</>
								)}
								{!emailStatus && (
									<>
										{' '}
										<Typography
											className="f-18 "
											color={
												!newEmailData?.email &&
												newEmailData?.email !== 'null' &&
												newEmailData?.email !== '' &&
												newEmailData?.email !== 'undefined'
													? theme.palette.common.black
													: theme.palette.common.black
											}
											style={{ wordWrap: 'break-word' }}
										>
											{newEmailData?.email &&
											newEmailData?.email !== 'null' &&
											newEmailData?.email !== '' &&
											newEmailData?.email !== 'undefined' ? (
												newEmailData?.email
											) : (
												<>
													<Grid display="flex">
														<Grid>
															<Typography>{t('NOT_AVAILABLE')}</Typography>
														</Grid>
														<Grid>
															<SvgConverter
																Icon={AlertIconRed}
																className="ml-15"
															/>
														</Grid>
													</Grid>
													<CustomButton
														text={t('TO_PROCEED_TAP_HERE_TO_ADD_EMAIL')}
														variant="text"
														showIcon={false}
														color="primary"
														className="fw-400 mt-20 sentence-straight_EKYC"
														onClick={handleAddEmail}
														startIcon={AlertIconBlue}
													/>
												</>
											)}
										</Typography>
									</>
								)}
							</Grid>
						</Grid>
						<Grid container justifyContent={'center'} className="my-30">
							<FormCheckBox
								label={
									<>
										<Grid
											container
											alignItems={'center'}
											onClick={(e: any) => {
												e.stopPropagation();
											}}
										>
											<Typography className="f-14 fw-400">
												&nbsp;{t('AGREE_TO')}&nbsp;
											</Typography>
											{/* <Link to={PUBLIC_ROUTES.PRIVACYPOLICY} target="_blank"> */}
											<Typography
												className="f-14 fw-600"
												style={{ color: '#0000FF' }}
												onClick={(e: any) => {
													setOpenprivacy(true);
													e.preventDefault();
												}}
											>
												{t('PRIVACY_POLICY_SMALL')}&nbsp;
											</Typography>
											{/* </Link> */}
											<Typography className="f-14 fw-400">
												{`&`}&nbsp;
											</Typography>
											{/* <Link to={PUBLIC_ROUTES.DISCLAIMER} target="_blank"> */}
											<Typography
												className="f-14 fw-600"
												style={{ color: '#0000FF' }}
												onClick={(e: any) => {
													setOpen(true);
													e.preventDefault();
												}}
											>
												{t('TERMS_OF_USE_SMALL')}
											</Typography>
											{/* </Link> */}
										</Grid>
									</>
								}
								checked={isChecked}
								onChange={() => {
									isChecked ? setIsChecked(false) : setIsChecked(true);
								}}
								size={20}
								checkBoxPadding={1}
							/>
						</Grid>

						<Grid item className="mt-15" />

						<Grid item container justifyContent="center" className="mt-30">
							<CustomButton
								text={t('PROCEED')}
								loading={isCopyEIADataLoading}
								variant="text"
								disabled={
									(!newEmailData?.email && !userData?.email) ||
									(!newMobileNoData?.mobileNo && !userData?.mobileNo)
								}
								color="primary"
								className="fw-600 f-20"
								onClick={handleProceed}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<EmailEntryModal
				open={showAddModal}
				setOpen={setShowAddModal}
				type="signup"
				onSubmit={handleNewEmailSubmit}
				email={newEmailData?.email}
				// loading={isGenerateOtpIsLoading}
			/>
			<OtpEntryModal
				isEmail={isEmail}
				value={
					mobilePayload === 'mobile'
						? newMobileNoData.mobileNo
						: newEmailData?.email
				}
				open={showOtpModal}
				setOpen={setShowOtpModal}
				Email={setisEmail}
				onSubmit={
					mobilePayload === 'mobile' ? handleMobileOtpVerify : handleOtpVerify
				}
				// loading={isVerifyEmailLoading}
				onFinishDuration={() =>
					mobilePayload === 'mobile'
						? setVerifyMobileDuration(0)
						: setVerifyEmailDuration(0)
				}
				duration={
					mobilePayload === 'mobile'
						? verifyMobileDuration
						: verifyEmailDuration
				}
				// resendLoading={isResendOtpLoading}
				handleResendOtp={
					mobilePayload === 'mobile' ? handleResendOtpMobile : handleResendOtp
				}
			/>
			<MobileEntryModal
				open={showmobileAddModal}
				setOpen={setshowmobileAddModal}
				onSubmit={handleNewMobileSubmit}
				type="signup"
				mobile={newMobileNoData?.mobileNo}
				// handleCancel={closeAllModals}
				// textLabel="Mobile"
				// loading={isMobileGenerateOtpLoading}
			/>
			<TermsCondition
				open={termsCondition}
				setOpen={setTermsCondition}
				setIsChecked={setIsChecked}
			/>

			<TermModal open={open} setOpen={setOpen} />

			<PrivacyModal open={openprivacy} setOpen={setOpenprivacy} />
		</Grid>
	);
};

export default SignupEkycDetailsOne;
