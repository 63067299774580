import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as checked } from '../../../common/icons/profile/checked.svg';
import SvgConverter from '../../../common/SvgConverter';

const VerifiedText = () => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Grid container spacing={0.5} alignItems="center" flexWrap="nowrap">
			<Grid item>
				<SvgConverter Icon={checked} width={12} height={9} />
			</Grid>
			<Grid item>
				<Typography className=" f-14 " color={theme.palette.success.main}>
					[ {t('VERIFIED')}]
				</Typography>
			</Grid>
		</Grid>
	);
};

export default VerifiedText;
