import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import theme from '../../../common/theme';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as Verify } from '../../../common/icons/update-mobile/verify_orange.svg';
import { ReactComponent as Cancel } from '../../../common/icons/update-mobile/cancel_orange.svg';
import { ReactComponent as EmailIcon } from '../../../common/icons/login_email-icon.svg';
import { ReactComponent as rightIcon } from '../../../common/icons/right_arrow-icon.svg';
import config from '../../../common/config';

const EmailEntryModal = ({ open, setOpen, onSubmit, loading, type, email }: any) => {
	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		setValue,
		trigger,
		register,
	} = useForm({ defaultValues: { email: '' } });

	const { t } = useTranslation();
	useEffect(() => {
		if (!open) {
			reset();
		}
	}, [open, reset]);
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
	useEffect(() => {
		reset({ email });
	}, [email])

	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 350 }}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Typography
					className="f-16 fw-6 px-20"
					color={theme.palette.primary.main}
					align="center"
				>
					{type !== 'signup' ? t('PLEASE_ENTER_EMAIL_ADDRESS') : ''}
				</Typography>

				<Grid container flexWrap="nowrap" spacing={1} className="py-10">
					<Grid item flex={1}>
						{type === 'signup' && (
							<CustomButton
								text={t('ENTER_EMAIL_ADDRESS')}
								variant="text"
								showIcon={false}
								// onClick={() => handleModalOptionEmail()}
								startIcon={EmailIcon}
								className="fw-400 email-popup-text ml-5 mb-5"
							/>
						)}

						<FormInput
							type="email"
							placeholder="email@website.com"
							name="email"
							control={control}
							inputProps={{
								className: 'px-10',
								maxLength: config.emailLength,
								...register('email', {
									required: true,
									onChange: handleEmailChange,
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
										message: t('INVALID_EMAIL'),
									},
								}),
							}}
							errors={errors}
						/>
					</Grid>
				</Grid>
				<Grid textAlign="center" className="mt-10">
					{type === 'signup' ? (
						<>
							<Grid display="flex" justifyContent="space-between">
								<Grid item>
									<CustomButton
										text={t('CLOSE')}
										variant="text"
										color="primary"
										showIcon={false}
										// startIcon={Verify}
										// type="submit"
										// loading={loading}
										onClick={() => setOpen(false)}
										className="f-16 fw-600"
									/>
								</Grid>
								<Grid item>
									<CustomButton
										text={t('ADD_EMAIL')}
										variant="text"
										color="primary"
										showIcon
										type="submit"
										// startIcon={Cancel}
										// onClick={() => setOpen(false)}
										endIcon={rightIcon}
										className="f-16"
									/>
								</Grid>
							</Grid>
						</>
					) : (
						<>
							<Grid item xs={6}>
								<CustomButton
									text={t('VERIFY')}
									variant="text"
									color="primary"
									showIcon={false}
									startIcon={Verify}
									type="submit"
									loading={loading}
									className="f-16 fw-600"
								/>
							</Grid>
							<Grid item xs={6}>
								<CustomButton
									text={t('CANCEL')}
									variant="text"
									color="primary"
									showIcon={false}
									startIcon={Cancel}
									onClick={() => setOpen(false)}
									className="f-16"
								/>
							</Grid>
						</>
					)}
				</Grid>
			</form>
		</CommonModal>
	);
};

export default EmailEntryModal;
