import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../common';
import { RootState } from '../../common/store';
import { AuthUser } from '../../common/utils';

interface AuthState {
	token?: string | null;
	user: AuthUser | null;
	authUserInfo: any;
	loginRequest: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};
	logOut: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	otpRequest: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	otpVerify: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	setMpin: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	verifyMpin: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	signUp: {
		status: LoadingState;
		responseInfo: any;
		error: any;
		payload: any;
	};
	initiateEkyc: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	saveEkyc: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	saveAR: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	copyEIAData: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	sendCommunication: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	savePortingDetails: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
}

const initialState: AuthState = {
	token: null,
	user: null,
	authUserInfo: null,
	loginRequest: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	},
	logOut: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	otpRequest: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	otpVerify: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	setMpin: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	verifyMpin: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	signUp: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
		payload: undefined,
	},
	initiateEkyc: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	saveEkyc: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	saveAR: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	copyEIAData: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	sendCommunication: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	savePortingDetails: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
};
const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		fetchLoginRequest: (state) => {
			state.loginRequest.status = 'loading';
			state.loginRequest.requestInfo = undefined;
			state.loginRequest.error = undefined;
		},
		reportLoginRequestSuccess: (state, { payload }) => {
			state.loginRequest.status = 'done';
			state.loginRequest.requestInfo = payload;
			state.loginRequest.error = undefined;
		},
		reportLoginRequestError: (state, { payload }) => {
			state.loginRequest.status = 'error';
			state.loginRequest.requestInfo = undefined;
			state.loginRequest.error = payload;
		},

		logoutRequest: (state, { payload }) => {
			state.logOut.status = 'loading';
			state.logOut.responseInfo = undefined;
			state.logOut.error = undefined;
		},
		logoutSuccess: (state, { payload }) => {
			state.logOut.status = 'done';
			state.logOut.responseInfo = payload;
			state.logOut.error = undefined;
			state.user = null;
		},
		logoutError: (state, { payload }) => {
			state.logOut.status = 'error';
			state.logOut.responseInfo = undefined;
			state.logOut.error = payload;
		},
		fetchOtpRequest: (state) => {
			state.token = 'token';
			state.otpRequest.status = 'loading';
			state.otpRequest.responseInfo = undefined;
			state.otpRequest.error = undefined;
		},
		reportOtpLoginSuccess: (state, { payload }) => {
			state.otpRequest.status = 'done';
			state.otpRequest.responseInfo = payload;
			state.otpRequest.error = undefined;
		},
		reportOtpLoginError: (state, { payload }) => {
			state.otpRequest.status = 'error';
			state.otpRequest.responseInfo = undefined;
			state.otpRequest.error = payload;
		},

		fetchOtpVerify: (state) => {
			state.otpVerify.status = 'loading';
			state.otpVerify.responseInfo = undefined;
			state.otpVerify.error = undefined;
		},
		reportOtpVerifySuccess: (state, { payload }) => {
			state.otpVerify.status = 'done';
			state.otpVerify.responseInfo = payload;
			state.otpVerify.error = undefined;
		},
		reportOtpVerifyError: (state, { payload }) => {
			state.otpVerify.status = 'error';
			state.otpVerify.responseInfo = undefined;
			state.otpVerify.error = payload;
		},

		setMpinRequest: (state) => {
			state.setMpin.status = 'loading';
			state.setMpin.responseInfo = undefined;
			state.setMpin.error = undefined;
		},
		setMpinSuccess: (state, { payload }) => {
			state.setMpin.status = 'done';
			state.setMpin.responseInfo = payload;
			state.setMpin.error = undefined;
		},
		setMpinError: (state, { payload }) => {
			state.setMpin.status = 'error';
			state.setMpin.responseInfo = undefined;
			state.setMpin.error = payload;
		},

		verifyMpinRequest: (state) => {
			state.verifyMpin.status = 'loading';
			state.verifyMpin.responseInfo = undefined;
			state.verifyMpin.error = undefined;
		},
		verifyMpinSuccess: (state, { payload }) => {
			state.verifyMpin.status = 'done';
			state.verifyMpin.responseInfo = payload;
			state.verifyMpin.error = undefined;
		},
		verifyMpinError: (state, { payload }) => {
			state.verifyMpin.status = 'error';
			state.verifyMpin.responseInfo = undefined;
			state.verifyMpin.error = payload;
		},

		signUpRequest: (state, { payload }) => {
			state.signUp.status = 'loading';
			state.signUp.responseInfo = undefined;
			state.signUp.error = undefined;
			state.signUp.payload = payload;
		},
		signUpSuccess: (state, { payload }) => {
			state.signUp.status = 'done';
			state.signUp.responseInfo = payload;
			state.signUp.error = undefined;
		},
		signUpError: (state, { payload }) => {
			state.signUp.status = 'error';
			state.signUp.responseInfo = undefined;
			state.signUp.error = payload;
		},

		initiateEkycRequest: (state) => {
			state.initiateEkyc.status = 'loading';
			state.initiateEkyc.responseInfo = undefined;
			state.initiateEkyc.error = undefined;
		},
		initiateEkycSuccess: (state, { payload }) => {
			state.initiateEkyc.status = 'done';
			state.initiateEkyc.responseInfo = payload;
			state.initiateEkyc.error = undefined;
		},
		initiateEkycError: (state, { payload }) => {
			state.initiateEkyc.status = 'error';
			state.initiateEkyc.responseInfo = undefined;
			state.initiateEkyc.error = payload;
		},

		saveEkycRequest: (state) => {
			state.saveEkyc.status = 'loading';
			state.saveEkyc.responseInfo = undefined;
			state.saveEkyc.error = undefined;
		},
		saveEkycSuccess: (state, { payload }) => {
			state.saveEkyc.status = 'done';
			state.saveEkyc.responseInfo = payload;
			state.saveEkyc.error = undefined;
		},
		saveEkycError: (state, { payload }) => {
			state.saveEkyc.status = 'error';
			state.saveEkyc.responseInfo = undefined;
			state.saveEkyc.error = payload;
		},
		saveARRequest: (state) => {
			state.saveAR.status = 'loading';
			state.saveAR.responseInfo = undefined;
			state.saveAR.error = undefined;
		},
		saveARSuccess: (state, { payload }) => {
			state.saveAR.status = 'done';
			state.saveAR.responseInfo = payload;
			state.saveAR.error = undefined;
		},
		saveARError: (state, { payload }) => {
			state.saveAR.status = 'error';
			state.saveAR.responseInfo = undefined;
			state.saveAR.error = payload;
		},

		setUser: (state, action) => {
			state.user = action.payload;
		},
		userSignOut: (state) => {
			state.user = null;
			sessionStorage.clear();
		},

		// eslint-disable-next-line no-unused-vars
		copyEIADataRequest: (state, { payload }) => {
			state.copyEIAData.status = 'loading';
			state.copyEIAData.responseInfo = undefined;
			state.copyEIAData.error = undefined;
		},
		copyEIADataSuccess: (state, { payload }) => {
			state.copyEIAData.status = 'done';
			state.copyEIAData.responseInfo = payload;
			state.copyEIAData.error = undefined;
		},
		copyEIADataError: (state, { payload }) => {
			state.copyEIAData.status = 'error';
			state.copyEIAData.responseInfo = undefined;
			state.copyEIAData.error = payload;
		},

		// eslint-disable-next-line no-unused-vars
		sendCommunicationRequest: (state, { payload }) => {
			state.sendCommunication.status = 'loading';
			state.sendCommunication.responseInfo = undefined;
			state.sendCommunication.error = undefined;
		},
		sendCommunicationSuccess: (state, { payload }) => {
			state.sendCommunication.status = 'done';
			state.sendCommunication.responseInfo = payload;
			state.sendCommunication.error = undefined;
		},
		sendCommunicationError: (state, { payload }) => {
			state.sendCommunication.status = 'error';
			state.sendCommunication.responseInfo = undefined;
			state.sendCommunication.error = payload;
		},
		savePortingDetailsRequest: (state, { payload }) => {
			state.savePortingDetails.status = 'loading';
			state.savePortingDetails.responseInfo = payload;
			state.savePortingDetails.error = undefined;
		},
		savePortingDetailsSuccess: (state, { payload }) => {
			state.savePortingDetails.status = 'done';
			state.savePortingDetails.responseInfo = payload;
			state.savePortingDetails.error = undefined;
		},
		savePortingDetailsError: (state, { payload }) => {
			state.savePortingDetails.status = 'error';
			state.savePortingDetails.responseInfo = undefined;
			state.savePortingDetails.error = payload;
		},
	},
});

export const {
	setUser,
	userSignOut,
	fetchLoginRequest,
	reportLoginRequestError,
	reportLoginRequestSuccess,
	reportOtpLoginSuccess,
	reportOtpLoginError,
	fetchOtpRequest,
	fetchOtpVerify,
	reportOtpVerifySuccess,
	reportOtpVerifyError,
	setMpinRequest,
	setMpinSuccess,
	setMpinError,
	verifyMpinRequest,
	verifyMpinSuccess,
	verifyMpinError,
	signUpRequest,
	signUpSuccess,
	signUpError,
	initiateEkycRequest,
	initiateEkycSuccess,
	initiateEkycError,
	saveEkycRequest,
	saveEkycSuccess,
	saveEkycError,
	saveARRequest,
	saveARSuccess,
	saveARError,
	copyEIADataRequest,
	copyEIADataSuccess,
	copyEIADataError,
	sendCommunicationRequest,
	sendCommunicationSuccess,
	sendCommunicationError,
	logoutError,
	logoutSuccess, logoutRequest,
	savePortingDetailsRequest,
	savePortingDetailsSuccess,
	savePortingDetailsError
} = authSlice.actions;
export default authSlice.reducer;

export const selectIsLoginRequestLoading = (state: RootState) =>
	state.auth.loginRequest.status === 'loading';

export const selectLoginRequestInfo = (state: RootState) =>
	state.auth.loginRequest.requestInfo;

export const selectLoginRequestInfoError = (state: RootState) =>
	state.auth.loginRequest.error;

export const selectIsOtpRequestLoading = (state: RootState) =>
	state.auth.otpRequest.status === 'loading';

export const selectOtpResponseInfo = (state: RootState) =>
	state.auth.otpRequest.responseInfo;

export const selectOtpLoginError = (state: RootState) =>
	state.auth.otpRequest.error;

export const selectIsUserLoggedIn = (state: RootState) => state.auth.user;

export const selectIsOtpValidationLoading = (state: RootState) =>
	state.auth.otpVerify.status === 'loading';

export const selectIsSetMpinLoading = (state: RootState) =>
	state.auth.setMpin.status === 'loading';

export const selectIsVerifyMpinLoading = (state: RootState) =>
	state.auth.verifyMpin.status === 'loading';

export const selectIsSignUpLoading = (state: RootState) =>
	state.auth.signUp.status === 'loading';
export const selectIsSignUpData = (state: RootState) =>
	state.auth.signUp.responseInfo;
export const selectSignUpPayload = (state: RootState) =>
	state.auth.signUp?.payload?.Customer;

export const selectIsInitiateEkycLoading = (state: RootState) =>
	state.auth.initiateEkyc.status === 'loading';
export const selectInitiateEkyc = (state: RootState) =>
	state.auth.initiateEkyc.responseInfo;

export const selectIsSaveEkycLoading = (state: RootState) =>
	state.auth.saveEkyc.status === 'loading';
export const selectSaveEkycResponse = (state: RootState) =>
	state.auth.saveEkyc.responseInfo;
export const selectLoginToken = (state: RootState) => state.auth.token;

export const selectCopyEIADataLoading = (state: RootState) =>
	state.auth.copyEIAData.status === 'loading';

export const selectSendCommunicationLoading = (state: RootState) =>
	state.auth.sendCommunication.status === 'loading';

export const saveArRequestLoading = (state: RootState) =>
	state.auth.saveAR.status === 'loading';
