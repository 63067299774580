import { Grid, Typography } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import theme from '../../../../../common/theme';
import ActiveHealthCard from '../ActiveContent/ActiveHealthCard';

interface UserProps {
	contentType?: string;
	filteredHealth?: any;
}
const ActiveHealth: React.FC<UserProps> = ({ filteredHealth, contentType }) => (
	<Grid container justifyContent="row">
		{filteredHealth.map((data: any, index: any) => (
			<Grid item container xs={12} sm={6} lg={4} justifyContent="space-between">
				<Grid item key={index}>
					<Typography
						className="f-12 fw-600 py-4 my-5"
						color={theme.palette.primary.main}
					>
						{/* {data?.productType}-{data?.productTypeOthers} */}
						{data?.productType}
						{data?.productTypeOthers.length !== 0
							? ` - ${data.productTypeOthers}`
							: ''}
					</Typography>

					<ActiveHealthCard contentType={contentType} items={data} />
				</Grid>
			</Grid>
		))}
	</Grid>
);

export default ActiveHealth;
