import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Grid, Typography, Box, useTheme } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as RupeeIcon1 } from '../../../common/icons/orange_rupee.svg';
// import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import { ReactComponent as CloseIcon } from '../../../common/icons/close.svg';
import './Surrenderpolicy.scss';
// import { APP_ROUTES } from '../../../common/constants/Routes';
// import { SELECT_POLICY_TYPES_FOR_MODULES } from '../../../common/Enums';
// import Spinner from '../../../common/ui/Spinner';

// import { createEkycPopup } from '../../../common/utils';
import Separator from '../../../common/ui/Separator';
// import ApiModal from '../../../common/ui/Modal/ApiModal';
// import cryptoEncryptionDecryption from '../../../common/crypto';
import HdfcIcon from '../../../assets/images/HDFC.png';

import { ReactComponent as AlertIconRed } from '../../../common/icons/warningRed.svg.svg';
import { ReactComponent as RupeeIcon } from '../../../common/icons/Big-orange-rupee.svg';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import { ReactComponent as Arrow } from '../../../common/icons/right-arrow.svg';
import { APP_ROUTES } from '../../../common/constants/Routes';
import ContinueSurrender from './ContinueSurrenderModal';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as OrangeRuppee } from '../../../common/icons/small-orange-rupee.svg';
import { useDispatch, useSelector } from 'react-redux';
import ContinueTacker from './ContinueTracker';
import { convertAmount } from '../../../common/utils';
import {
	fetchSurrender, selectLoadedSurrenders
} from '../SurrenderModule/Surrender.slice';

interface UserProps {
	contentType?: string;
	item?: any;
	step?: any;
	widgetNo?: any;
}

const ManageSurrenderPolicyScreen: React.FC<UserProps> = ({
	contentType,
	item,
	step,
	widgetNo,
}) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const surrender = location?.state?.surrenderPolicy;
	const bankDetails: any = location.state?.bankDetails?.bankDetails;
	const submitData: any = location.state?.submitData?.submitData;
	const [open, setOpen] = React.useState<any>(false);

	const handleMenuItemClick = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (bankDetails !== undefined) {
			setOpen(true);
		}
		if (bankDetails === true) {
			setOpen(false);
		}
	}, [bankDetails]);

	useEffect(() => {
		if (submitData === true) navigate(APP_ROUTES.SUPPORT_SUBMITTED);
	});

	return (
		<Grid container justifyContent="center">
			<Box
				marginBottom={5}
				width={414}
				display="flex"
				justifyContent="end"
				flexDirection="column"
			>
				<div className="fixed-policy-header">
					<Grid container justifyContent="space-between" className='p-8 '>
						<Grid item>
							<Typography className="f-16 fw-600 " color="primary">
								{t('POLICY_SURRENDER')}

							</Typography>
						</Grid>

						<Grid item className="">
							<Link to={APP_ROUTES.POLICY}>
								<CustomButton
									text={t('CLOSE')}
									variant="text"
									color="primary"
									showIcon
									fontSize={14}
									endIcon={CloseIcon}
								/>
							</Link>
						</Grid>
					</Grid>
					<Grid item className="mt-10">
						<Separator color={theme.palette.primary.main} borderWidth={1} />
					</Grid>
				</div>
				<div
					style={{
						marginTop: 60,
					}}
				/>

				<Grid container item xs={12}>
					{/* <Grid item xs={3}>
						<img src={HdfcIcon} className="bank-image" alt="bank" />
					</Grid> */}
					<Grid item xs={3}>
						<img
							src={surrender.companyLogo}
							className="bank-image"
							alt="bank"
						/>
					</Grid>
					<Grid item xs={8} className="ml-6 pt-10">
						<Typography
							className="ml-0 f-18 fw-600 mb-0"
							color={theme.palette.primary.main}
						>
							{surrender.planName.length === 0 ? '-' : surrender.planName}
						</Typography>
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							className="mb-20"
						>
							{surrender.policyNo.length === 0 ? '-' : surrender.policyNo}
						</Typography>
					</Grid>
				</Grid>
				<Grid display="flex" className="align-middle mt-15">
					<Grid>
						<SvgConverter Icon={AlertIconRed} />
					</Grid>
					<Grid>
						<Typography
							color={theme.palette.error.main}
							className="ml-10 fw-600 "
						>
							{t('ATTENTION')}
						</Typography>
					</Grid>
				</Grid>
				<Grid className="mb-10">
					<Typography
						color={theme.palette.error.main}
						className="mt-25 fw-600 f-18"
					>
						{t('POLICY_SURRENDER_WILL')}
					</Typography>

					<Typography
						color={theme.palette.error.main}
						className="mt-25 f-14 fw-600"
					>
						{t('YOU_WILL_LOOSE')}
					</Typography>

					<Grid display="flex">
						{/* <Grid className="ml-0 f-20 mt-15">
							<SvgConverter Icon={RupeeIcon} />
						</Grid> */}
						<Grid>
							<Typography
							// color={theme.palette.primary.main}
							// className="ml-10 mt-8 f-20 fw-600 align-items-center"
							>
								{/* {t('12345678')} */}
								<LabelWithIcon
									className="mr-10"
									svgClassName=""
									justifyContent="flex-start"
									Icon={OrangeRuppee}
									label={convertAmount(surrender?.sumAssured)}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 16,
										fontWeight: 600,
									}}
								/>
							</Typography>
						</Grid>
					</Grid>
					<Typography color="black" className=" fw-400 f-14">
						{t('SUM_ASSURED')}
					</Typography>

					<Typography color="black" className="f-14 fw-600 mt-20 mb-20">
						{t('SEE_OPTIONS')}
					</Typography>

					<Card
						sx={{ minWidth: 100, backgroundColor: theme.palette.grey[200] }}
						variant="outlined"
					>
						<CardContent>
							<Grid display="flex">
								<Grid className="ml-10 mt-6 align-top">
									<SvgConverter Icon={Arrow} />
								</Grid>
								<Grid>
									<Typography
										sx={{ fontSize: 18, fontStyle: 'oblique' }}
										color="text.secondary"
										className="ml-10 mt-1 align-top"
									>
										"I am in need of money."
									</Typography>
								</Grid>
							</Grid>

							<Grid container xs={12}>
								<Grid xs={4} />
								<Grid xs={7} item className="mt-10">
									<Link to={APP_ROUTES.NEED_MONEY}>
										<CustomButton
											text={t('TRY "NEED MONEY MODULE"')}
											variant="text"
											color="primary"
											className="fw-600 f-16 "
											showIcon
											textAlign="end"
											endIcon={rightArrow}
										/>
									</Link>
								</Grid>
							</Grid>

							<Grid display="flex">
								<Grid className="ml-10 mt-6">
									<SvgConverter Icon={Arrow} />
								</Grid>
								<Typography
									sx={{ fontSize: 18, fontStyle: 'oblique' }}
									color="text.secondary"
									className="ml-10 mt-1"
								>
									"Issue with the policy."
								</Typography>
							</Grid>

							<Grid container xs={12}>
								<Grid xs={4} />
								<Grid xs={7} item className="mt-10">
									<Link
										to={APP_ROUTES.SUPPORT}
										onClick={handleMenuItemClick}
										state="Complaint"
									>
										<CustomButton
											text={t(' USE "POLICY GRIEVANCE MODULE"')}
											variant="text"
											color="primary"
											className="fw-600 f-16"
											showIcon
											fontSize={14}
											textAlign="end"
											// onClick={handleDonePress}
											endIcon={rightArrow}
										/>
									</Link>
								</Grid>
							</Grid>

							<Grid container xs={12}>
								<Grid xs={4} />
								<Grid xs={7} item className="mt-10">
									<Link
										to={APP_ROUTES.SUPPORT}
										onClick={handleMenuItemClick}
										state="Surrender"
									>
										<CustomButton
											text={t(' RAISE A COMPLAINT TO THE INSURER')}
											variant="text"
											color="primary"
											className="fw-600 f-16"
											showIcon
											fontSize={14}
											textAlign="end"
											onClick={submitData}
											// onClick={handleDonePress}
											endIcon={rightArrow}
										/>
									</Link>
								</Grid>
							</Grid>
							<Grid display="flex">
								<Grid className="ml-10 mt-6">
									<SvgConverter Icon={Arrow} />
								</Grid>
								<Typography
									sx={{ fontSize: 18 }}
									color="text.secondary"
									gutterBottom
									className="ml-10 mt-1 fw-400"
								>
									Some other reason.
								</Typography>
							</Grid>
							<Grid container xs={12}>
								<Grid xs={4} />
								<Grid xs={7} item className="mt-10">
									<CustomButton
										text={t(' CONTINUE TO POLICY SURRENDER')}
										variant="text"
										color="inherit"
										className="fw-600 f-16"
										fontSize={14}
										textAlign="end"
										onClick={() => setOpen(true)}
										endIcon={rightArrow}
									/>
									<Typography
										color={theme.palette.error.main}
										className="f-14 text-right mr-20"
									>
										(Not Recommended)
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>

				</Grid>
			</Box>

			<ContinueSurrender
				open={open}
				setOpen={setOpen}
				// data={surrendervalues}
				policyDetails={surrender}
				bankDetails={bankDetails}
				onSubmit={() => { }}
			/>
		</Grid>
	);
};

export default ManageSurrenderPolicyScreen;

// function fetchSurrenderDetails(): any {
// 	throw new Error('Function not implemented.');
// }

// function dispatch(arg0: any) {
// 	throw new Error('Function not implemented.');
// }

