import { Grid } from '@mui/material';
import React from 'react';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import PolicyList from './PolicyList';

interface UserProps {
	lifeList?: any;
	healthList?: any;
	assetList?: any;
}

const AddPolicyList: React.FC<UserProps> = ({
	lifeList,
	healthList,
	assetList,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Grid container>
				<Grid item>
					{lifeList.length > 0 && (
						<Typography
							className="f-16 fw-600 mb-15"
							color={theme.palette.common.black}
						>
							{t('POLICY_LIST')}
						</Typography>
					)}
				</Grid>
				<Grid container item xs={12}>
					{lifeList.length > 0 && (
						<Grid item xs={12} sm={6} lg={4} xl={3}>
							<PolicyList insurercode={t('LIFE')} list={lifeList} />
						</Grid>
					)}
					{healthList.length > 0 && (
						<Grid item xs={12} sm={6} lg={4} xl={3}>
							<PolicyList insurercode={t('HEALTH')} list={healthList} />
						</Grid>
					)}
					{assetList.length > 0 && (
						<Grid item xs={12} sm={6} lg={4} xl={3}>
							<PolicyList insurercode={t('ASSET')} list={assetList} />
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default AddPolicyList;
