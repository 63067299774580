import { Grid, Typography } from "@mui/material";
import CommonModal from "../../common/ui/Modal/CommonModal";
import { APP_ROUTES } from "../../common/constants/Routes";
import { Link } from "react-router-dom";
import Separator from "../../common/ui/Separator";
import theme from "../../common/theme";
import { useTranslation } from "react-i18next";

export const NavbarModal = ({ open,
    setOpen }: any) => {

    const { t } = useTranslation();

    return (<CommonModal
        onClose={() => setOpen(false)}
        open={open}
        modalClassName=""
        boxProps={{ width: 354 }}
    >

        <Grid container>
            <Grid item xs={12}>
                <Link to={APP_ROUTES.ADD_POLICY_INSTRUCTION} >
                    <Typography
                        className="f-16 fw-600 px-12 py-13"
                        color={theme.palette.common.black}
                    >
                        {t('ADD_POLICY/POLICIES')}
                    </Typography>
                </Link>
                <Separator color={theme.palette.primary.main} />
                <Link to={APP_ROUTES.UPDATE_MOBILE} >
                    <Typography
                        className="f-16 fw-600 px-12 py-13"
                        color={theme.palette.common.black}
                    >
                        {t('ADD/EDIT_MOBILE')}
                    </Typography>
                </Link>
                <Separator color={theme.palette.primary.main} />
                <Link to={APP_ROUTES.EMAIL} >
                    <Typography
                        className="f-16 fw-600 px-12 py-13"
                        color={theme.palette.common.black}
                    >
                        {t('ADD/EDIT_EMAIL')}
                    </Typography>
                </Link>
                <Separator color={theme.palette.primary.main} />
                <Link to={APP_ROUTES.BANK} >
                    <Typography
                        className="f-16 fw-600 px-12 py-13"
                        color={theme.palette.common.black}
                    >
                        {t('ADD/EDIT_BANK_DETAILS')}
                    </Typography>
                </Link>
                <Separator color={theme.palette.primary.main} />
                <Link to={APP_ROUTES.MANAGE_ADDRESS} >
                    <Typography
                        className="f-16 fw-600 px-12 py-13"
                        color={theme.palette.common.black}
                    >
                        {t('ADD/EDIT_ADDRESS')}
                    </Typography>
                </Link>
                <Separator color={theme.palette.primary.main} />
                <Link to={APP_ROUTES.NOMINEE} >
                    <Typography
                        className="f-16 fw-600 px-12 py-13"
                        color={theme.palette.common.black}
                    >
                        {t('ADD/REMOVE_NOMINEESS')}
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    </CommonModal>);
}