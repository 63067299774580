import { Grid, Typography } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import theme from '../../../../../common/theme';
import ActiveAssetsCard from '../ActiveContent/ActiveAssetsCard';

interface UserProps {
	contentType?: string;
	filteredAssets?: any;
}
const ActiveAssets: React.FC<UserProps> = ({ filteredAssets, contentType }) => (
	<Grid container justifyContent="row">
		{filteredAssets.map((data: any, index: any) => (
			<Grid item xs={12} sm={6} lg={4} key={index}>
				<Typography
					className="f-12 fw-600 py-4"
					color={theme.palette.primary.main}
				>
					{(data?.vehicleClassCode?.length > 0 || data?.vehicleModel?.length > 0 || data?.vehicleNo?.length > 0) &&
						<>{data?.vehicleClassCode} - {data?.vehicleModel} - {data?.vehicleNo}</>}
				</Typography>
				<ActiveAssetsCard contentType={contentType} items={data} />
			</Grid>
		))}
	</Grid>
);

export default ActiveAssets;
