import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../../common/theme';
import CustomButton from '../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../common/constants/Routes';

const NeedSupport = ({ type, page }: any) => {
	const { t } = useTranslation();

	return (
		<Grid>
			{page === 'dashboard' || page === 'centerDashboard' ? (
				<Typography
					fontSize={14}
					color={
						type === 'black'
							? theme.palette.common.black
							: theme.palette.common.white
					}
					className={
						page === 'dashboard'
							? 'fw-600 need-support-text-dashboard'
							: 'fw-600 need-support-text'
					}
				>
					{`${t('YOU_CAN_ALSO')},`}
				</Typography>
			) : (
				<>
					<Typography
						fontSize={14}
						color={
							type === 'black'
								? theme.palette.common.black
								: theme.palette.common.white
						}
						className={
							page === 'dashboard'
								? 'fw-600 need-support-text-dashboard'
								: 'fw-600 need-support-text'
						}
					>
						{`${t('NEED_SUPPORT')}?`}
					</Typography>

					{page === 'dashboardMain' && (
						<>
							<Grid display="flex" justifyContent="center" mt={2} >
								<Link to={APP_ROUTES.SUPPORT} state="Support">
									<CustomButton
										text={t('CLICK_HERE')}
										variant="text"
										color="primary"
										showIcon
										className="f-16 fw-600"
										type="submit"
									/>
								</Link>

							</Grid>
							<Typography textAlign={'center'} className='pb-2 fw-600 f-14'>
								or
							</Typography>
						</>
					)}
				</>
			)}

			<Typography
				fontSize={14}
				color={
					type === 'black'
						? theme.palette.common.black
						: theme.palette.common.white
				}
				className={
					page === 'dashboard'
						? 'fw-400 need-support-text-dashboard'
						: 'fw-400 need-support-text'
				}
			>
				<Grid container flex={1} justifyContent={page === 'dashboard' ? 'left' : 'center'}>
					{t('TOLL_FREE') + ":"}
					<Typography fontSize={14} fontWeight={600} className="need-support-no pl-2">
						1800-200-7737
					</Typography>
				</Grid>
			</Typography>
			<Typography
				fontSize={14}
				color={
					type === 'black'
						? theme.palette.common.black
						: theme.palette.common.white
				}
				className={
					page === 'dashboard'
						? 'fw-400 need-support-text-dashboard'
						: 'fw-400 need-support-text'
				}
			>
				<Grid container flex={1} justifyContent={page === 'dashboard' ? 'left' : 'center'}>
					{t('EMAIL_US') + ":"}
					<Typography fontSize={14} fontWeight={600} className="mb-20 need-support-no pl-2">
						info@camsrepository.com
					</Typography>
				</Grid>
			</Typography>
		</Grid>
	);
};

export default NeedSupport;
