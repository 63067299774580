import React, { useState } from 'react';
import { ReactComponent as correct } from '../../../common/icons/LoanModule/tickIcon.svg';

import { Button, Grid, Typography, useTheme } from '@mui/material';
import SvgConverter from '../../../common/SvgConverter';
interface CheckboxProps {
	checked: boolean;
	onChange: () => void;
	disabled?: boolean;
	mx?: any;
}

const CustomCheckbox: React.FC<CheckboxProps> = ({ checked, onChange, disabled, mx = 2 }) => {
	const theme = useTheme();
	return (
		<Grid
			container
			xs={12}
			mx={mx}
			style={{ flexDirection: 'row', alignItems: 'center' }}
		>
			<Button
				onClick={onChange}
				sx={{
					minWidth: 18,
					width: 18,
					height: 18,
					borderWidth: 1,
					border: '2px solid #0051BA',
					borderRadius: '4px',
					backgroundColor: theme.palette.common.white,
					justifyContent: 'center',
					alignItems: 'center',
				}}
				disabled={disabled}
			>
				{checked && (
					<Typography fontSize={18} mb={0.2} ml={1}>
						<SvgConverter Icon={correct} height={20} />
					</Typography>
				)}
			</Button>
		</Grid>
	);
};
export default CustomCheckbox;
