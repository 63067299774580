import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Grid, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as RupeeIcon } from '../../../common/icons/orange_rupee.svg';
// import HDFC from '../../../common/icons/LIC.png';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import Separator from '../../../common/ui/Separator';
import RadioButton from './RadioButtonDetails';
import { APP_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as cancel } from '../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../common/icons/LoanModule/right-arrow-icon.svg';

import { getLoanData, selectedCombination } from './Loan.slice';
import { convertAmount } from '../../../common/utils';
import CustomCheckbox from './CustomCheckbox';

interface FinancierCardLoanDetailsProps {
	financier?: string;
}
const SelectFinanciers = ({ financier }: FinancierCardLoanDetailsProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const bankScreenNavigate = location.state?.data;
	const widgetNo = location.state?.widgetNo;
	const financierDetails = location.state?.lender;
	const [selectedRepaymentOption, setSelectedRepaymentOption]: any =
		useState(null);
	const [selectRadioButton, setSelectRadioButton]: any = useState(null);
	const [selectedFinancierCode, setSelectedFinancierCode] = useState<
		string | null
	>(null);

	const [selectedCheckValue, setSelectedCheckValue] = useState('');
	const generateRepaymentOptions = (repaymentOptions: any[]) =>
		repaymentOptions?.map((option) => ({
			label: option?.repaymentOptionDescription,
			value: option?.repaymentCode,
		}));

	const handleFinancierSelect = (lenderCode: any) => {
		if (lenderCode?.lenderCode === selectedFinancierCode) {
			setSelectedCheckValue('');
			setSelectedFinancierCode(null);
		}
		else {
			setSelectedCheckValue(lenderCode);
			setSelectedFinancierCode(lenderCode?.lenderCode);
		}
		setSelectedRepaymentOption(null);
	};
	const handleNavigate = () => {
		if (bankScreenNavigate) {
			navigate(APP_ROUTES.SELECTBANKDETAILS, {
				state: {
					widgetNo,
					lenderDetails: {
						widgetNo,
						lenderDetails: {
							repaymentCode: selectRadioButton,
							lenderCode: selectedCheckValue,
						},
					},
					bankScreenNavigate,
				},
			});
		} else {
			dispatch(
				getLoanData({
					lenderDetails: {
						widgetNo,
						lenderDetails: {
							repaymentCode: selectRadioButton,
							lenderCode: selectedCheckValue,
						},
					},
				})
			);
			navigate(APP_ROUTES.Lender_Screen);
		}
	};
	useEffect(() => {
		// Scroll to the top of the page when the route changes
		window.scrollTo(0, 0);
	}, []);
	const Combinations: any = useSelector(selectedCombination);
	useEffect(() => {
		if (!Combinations) {
			navigate(APP_ROUTES.NEED_MONEY);
		}
	}, [Combinations]);
	return (
		<Grid container spacing={0} className="select-financier">
			<Grid my={3}>
				{' '}
				<Typography className="f-16 fw-600" color={theme.palette.primary.dark}>
					{t('SELECT_A_FINANCIER')}
				</Typography>
			</Grid>

			<Grid container spacing={0}>
				<Grid
					container
					spacing={2}
					justifyContent="space-between"
					className="mb-14"
				>
					<Grid item>
						<Link to={APP_ROUTES.Lender_Screen}>
							<LabelWithIcon
								className="f-14"
								justifyContent="flex-start"
								Icon={cancel}
								label={t('CANCEL')}
								labelProps={{
									fontSize: 14,
									color: 'primary',
								}}
								iconProps={{ width: 18, height: 18 }}
							/>
						</Link>
					</Grid>
					<Grid item>
						<CustomButton
							iconClassName="done-button"
							className="f-14 fw-600 "
							text={t('PROCEED')}
							variant="text"
							color="primary"
							showIcon={false}
							startIcon={correct}
							disabled={selectedRepaymentOption === null}
							onClick={handleNavigate}
						/>
					</Grid>
				</Grid>
			</Grid>
			{financierDetails?.map((widget: any) => (
				<Grid
					item
					container
					// xs={12}
					// sm={6}
					// md={4}
					// xl={3}
					className="mt-5 mb-30 "
				>
					<Grid className="mt-3">
						<CustomCheckbox
							checked={widget?.lenderCode === selectedFinancierCode}
							onChange={() => handleFinancierSelect(widget)}
						/>
					</Grid>
					<Grid container xs={10}>
						<Grid>
							<img src={widget?.lenderLogo} alt="logo" className="bank_logo" />
						</Grid>
						<Grid className="pl-15">
							<Grid item xs={12} mb={2}>
								<Typography className="f-16 fw-600" color="primary">
									{widget?.lenderName}
								</Typography>
							</Grid>
							<Grid item xs={12} className="mt-5" mb={0.5}>
								<Typography className="f-14" color={theme.palette.primary.main}>
									{t('LOAN_AMOUNT')}
								</Typography>
							</Grid>
							<Grid container xs={12} mb={0.4}>
								<Grid spacing={0.5}>
									<LabelWithIcon
										className="pr-6"
										svgClassName=""
										justifyContent="flex-start"
										Icon={RupeeIcon}
										label={convertAmount(widget?.loanAmount)}
										labelProps={{
											color: theme.palette.common.black,
											fontSize: 14,
											fontWeight: 600,
										}}
									/>
								</Grid>

								<Typography
									className="f-14 finance_text"
									color={theme.palette.common.black}
								>
									@&nbsp;
									<Typography
										className="f-14 fw-600"
										color={theme.palette.common.black}
									>
										{widget?.interestPercentage}
									</Typography>
									{t('INTEREST')}
								</Typography>
							</Grid>
							<Grid display="flex">
								<Typography
									className="f-14"
									mr={0.5}
									color={theme.palette.primary.dark}
								>
									{t('CHARGES')}:{' '}
								</Typography>
								<LabelWithIcon
									Icon={RupeeIcon}
									label={convertAmount(widget?.charges)}
									spacing={0.5}
									justifyContent="flex-start"
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 14,
										fontWeight: 600,
									}}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Separator
								color={theme.palette.grey[300]}
								className="mb-8 mt-15"
							/>

							<Typography className="f-14" color={theme.palette.primary.main}>
								{t('SELECT_REPAYMENT_OPTIONS')}
							</Typography>
							<Grid container>
								<RadioButton
									className="select-repayment-option"
									options={generateRepaymentOptions(widget?.RepaymentOptions)}
									onValueChange={(optionCode: any) => {
										const repayment: any = widget?.RepaymentOptions?.find(
											(repayment: any) => repayment.repaymentCode === optionCode
										);
										setSelectRadioButton(repayment);
										setSelectedRepaymentOption(optionCode);
									}}
									disabled={widget?.lenderCode !== selectedFinancierCode}
									selectedCheckboxValue={selectedFinancierCode}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
};

export default SelectFinanciers;
