import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Separator from '../../../common/ui/Separator';
import {
	assetPolicies,
	healthPolicies,
	lifePolicies,
} from '../BankModule/Bank.slice';
import { ReactComponent as LifeIcon } from '../../../common/icons/profile/Life_icon.svg';
import { ReactComponent as AssetIcon } from '../../../common/icons/profile/asset_icon.svg';
import { ReactComponent as HealthIcon } from '../../../common/icons/profile/health_icon.svg';
import UnAssignedPoliciesForBank from '../BankModule/common/UnAssignedPoliciesForBank';
import SvgConverter from '../../../common/SvgConverter';

const UnassignedEmailPolicies = ({ policies, onChangeEmail }: any) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const policiesForLife = lifePolicies(policies);
	const policiesForHealth = healthPolicies(policies);
	const policiesForAsset = assetPolicies(policies);
	const shouldDisplay =
		policiesForLife.length > 0 ||
		policiesForHealth.length > 0 ||
		policiesForAsset.length > 0;

	return shouldDisplay ? (
		<>
			<Grid item xs={12}>
				<Typography className="f-16  mt-10" color={theme.palette.error.main}>
					{t('UNASSIGNED_POLICIES')}
				</Typography>
			</Grid>
			<Grid item className="mt-3 mb-15">
				<Separator color={theme.palette.primary.main} borderWidth={1} />
			</Grid>
			<Grid container justifyContent="column">
				{policiesForLife.length !== 0 && (
					<Grid item className="mb-10  mx-10">
						<Grid container spacing={1.5} alignItems="center" className="mb-5">
							<Grid item>
								<SvgConverter Icon={LifeIcon} width={20} height={21} />
							</Grid>
							<Grid item>
								<Typography color={theme.palette.primary.main} fontSize={14}>
									{t('LIFE')}
								</Typography>
							</Grid>
						</Grid>
						<Grid item className="mb-10">
							<Separator
								color={theme.palette.primary.main}
								borderWidth={1}
								className="w-10"
							/>
						</Grid>
						<Grid item className="Manage-bank">
							{policiesForLife.map((policy: any) => (
								<UnAssignedPoliciesForBank
									assignButtonText="EMAIL"
									data={policy}
									key={policy?.policyNo}
									onAssignClick={() => onChangeEmail(policy)}
								/>
							))}
						</Grid>
					</Grid>
				)}
				{policiesForHealth.length !== 0 && (
					<Grid item className="mx-10">
						<Grid container spacing={1.5} alignItems="center" className="mb-5">
							<Grid item>
								<SvgConverter Icon={HealthIcon} width={20} height={21} />
							</Grid>
							<Grid item>
								<Typography color={theme.palette.primary.main} fontSize={14}>
									{t('HEALTH')}
								</Typography>
							</Grid>
						</Grid>
						<Grid item className="mb-10">
							<Separator color={theme.palette.primary.main} borderWidth={1} />
						</Grid>
						<Grid item className="Manage-bank">
							{policiesForHealth.map((policy: any) => (
								<UnAssignedPoliciesForBank
									assignButtonText="EMAIL"
									data={policy}
									key={policy?.policyNo}
									onAssignClick={() => onChangeEmail(policy)}
								/>
							))}
						</Grid>
					</Grid>
				)}
				{policiesForAsset.length !== 0 && (
					<Grid item className="mx-10">
						<Grid container spacing={1.5} alignItems="center" className="mb-5">
							<Grid item>
								<SvgConverter Icon={AssetIcon} width={20} height={21} />
							</Grid>
							<Grid item>
								<Typography color={theme.palette.primary.main} fontSize={14}>
									{t('ASSET')}
								</Typography>
							</Grid>
						</Grid>
						<Grid item className="mb-10">
							<Separator color={theme.palette.primary.main} borderWidth={1} />
						</Grid>
						<Grid item className="Manage-bank">
							{policiesForAsset.map((policy: any) => (
								<UnAssignedPoliciesForBank
									assignButtonText="EMAIL"
									data={policy}
									key={policy?.policyNo}
									onAssignClick={() => onChangeEmail(policy)}
								/>
							))}
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	) : null;
};

export default UnassignedEmailPolicies;
