import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import { ReactComponent as TickIcon } from '../../icons/tick-icon.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import CustomButton from '../CustomButton';
import CommonModal from './CommonModal';

const ConfirmationModal = ({ show, description, onConfirm, onCancel }: any) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<CommonModal
			boxProps={{ width: 360 }}
			modalClassName="api-modal-container"
			open={show}
			onClose={onCancel}
		>
			<Grid container spacing={1} alignItems="center" direction="column">
				<Grid item marginBottom={1}>
					<Typography
						textAlign="center"
						className="f-14 mb-15"
						color={theme.palette.info.main}
					>
						{description}
					</Typography>
				</Grid>
				<Grid item>
					<CustomButton
						text={t('REMOVE')}
						variant="text"
						color="primary"
						showIcon={false}
						fontSize={14}
						onClick={onConfirm}
						startIcon={TickIcon}
					/>
				</Grid>
				<Grid item>
					<CustomButton
						text={t('CANCEL')}
						variant="text"
						color="primary"
						showIcon={false}
						fontSize={14}
						onClick={onCancel}
						startIcon={CloseIcon}
					/>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default ConfirmationModal;
