import { Grid } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import ArchiveHealthCard from '../ArchiveContent/ArchiveHealthCard';

interface UserProps {
	filteredHealth?: any;
}
const ArchiveHealth: React.FC<UserProps> = ({ filteredHealth }) => (
	<Grid container justifyContent="row">
		{filteredHealth.map((data: any, index: any) => (
			<Grid item key={index} xs={12} sm={6} lg={4} className="mb-20">
				<ArchiveHealthCard contentType="archive" items={data} />
			</Grid>
		))}
	</Grid>
);

export default ArchiveHealth;
