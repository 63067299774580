import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const DisclaimerScreen = ({ popup }: any) => {
	const { t } = useTranslation();
	
	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Disclaimer Screen',
		});
	}, []);

	return (
		<Grid
			item
			// xs={10}
			// sm={10}
			container
			justifyContent="center"
			className="px-15"
		>
			<Box
				marginBottom={5}
				width={800}
				display="flex"
				justifyContent="end"
				flexDirection="column"
			>
				<Grid item container className="mt-5">

					{!popup &&
				<Grid item>
						<span className="arrow-span">
							<Link to={APP_ROUTES.DASHBOARD}>
								<SvgConverter
									Icon={ArrowLeftIcon}
									className="left-arrow-style"
								/>
							</Link>
						</span>
					</Grid> 
				}
					<Grid>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={900}
							fontFamily="inherit"
							className="mb-5 "
						>
							Bima Central - Terms of Use
						</Typography>
					</Grid>
				</Grid>
				{/* <span className="arrow-span">
					<Link to={APP_ROUTES.DASHBOARD}>
						<SvgConverter Icon={ArrowLeftIcon} className="left-arrow-style" />
					</Link>
				</span>
				 */}
				{/* <Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Bima Central - Terms of Use
						</Typography>
					</Grid> */}
				<Grid item>
					<Typography fontSize={14}>
						This login screen is intended for valid and authorized users of this
						application only and at their own discretion. Illegal or
						unauthorized access could lead to legal action.{' '}
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The Terms of Use (“Terms”) herein sets out the terms and conditions
						for availing the Services of Unified Platform by You.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							General
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						By using/installing the Unified Platform (as defined below) at your
						own discretion, you (hereinafter referred to as “the User”) agree to
						be legally bound by the terms and conditions given herein below.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						You are requested to read the following terms and conditions
						carefully before installation of the Unified Platform or accessing
						the Platform and/or acceptance of the following terms and
						conditions.
					</Typography>

					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Definition
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						The “Platform” (hereinafter referred to as Bima Central or
						“App/platform”), shall mean the common platform developed by CAMSRep
						namely M/s. Computer Age Management Services Private Limited
						(“CAMS”) may decide from time to time, pertaining to financial and
						other service transactions relating to Insurance serviced by above
						referred CAMSRep, to be used through designated desktops/laptops, on
						Apple iOS (Apple’s mobile operating system) and Android OS (Android
						operating system) devices and any upgrades from time to time and any
						other software or documentation which enables the use of the
						platform
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Collection of User Data:
						</Typography>
					</Grid>

					<Typography className="mt-10" fontSize={14}>
						We collect the user’s personal information through our
						website/through the app/platform. The data collected includes Name,
						Date of Birth, Address, Mobile, Email, Age, phone Nos., Bank Account
						details, Income Tax, PAN, etc. These details are primary meant to be
						used for the purpose of creating e-Insurance Account, policy
						conversion, policy servicing, etc subject to changes from time to
						time by the user through the app/platform at his/her own discretion
						and for communication on such transactions or any other activities
						associated with such transactions including reminders, intimation of
						any regulatory/statutory requirements, intimation of specific
						events, etc.{' '}
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Usage of information collected:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						The information collected may also be used for the following
						purposes:
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						<ul>
							<li className="mt-10">
								Personalize your experience and improvise our responses to match
								your requirements, wherever possible.
							</li>
							<li className="mt-10">
								Improve our app/platform based on the information/details about
								investor profiles, servicing patterns and their exact
								requirements, etc.
							</li>
							<li className="mt-10">
								To improve customer service based on the feedbacks provided by
								the user.
							</li>
							<li className="mt-10">
								Use analytics to track the traffic patterns, sources of traffic,
								user preferences, referrals etc. and use such analytics to
								enhance overall services.
							</li>
							<li className="mt-10">
								To send periodic emails/SMS to registered email IDs / mobile
								numbers. Further, these email IDs / mobile number may be used to
								send you information and updates pertaining to your order or
								request, in addition to receiving periodic news / updates /
								promotions / related product or services provided by CAMSRep and
								its group companies / subsidiaries and informative and educative
								material(s) that we may deem appropriate.
							</li>
							<li className="mt-10">
								We commit and promise that the User’s email IDs, mobile numbers,
								and other personal information shared with us, shall be kept
								confidential and safe with the respective CAMSRep.
							</li>
						</ul>
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							In-app/platform Disclosure
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						The terms and conditions and the privacy policy contained in this
						app/platform constitutes complete disclosure and the users are not
						required to navigate further outside the app/platform. These terms
						and conditions describe in detail the type of data being collected
						and how the data will be used apart from other details.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Request for consent
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						The consent for the collection of data which may include personal or
						sensitive data and for the subsequent use of the data is being
						sought from the user by clicking on the app/platform appropriate
						acceptance boxes contained in the app/platform. This affirmative
						action by the user is a prerequisite for the use of the app/platform
						further. The acceptance needs to be done at the specified space in
						an affirmative manner as stated above and no other action of the
						user will be considered as consent. Collection of the data referred
						above will commence post receipt of the consent.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Privacy Shield
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						This app/platform does not intend to use personal information made
						available by Google or any such website/app that directly or
						indirectly identifies an individual and that originated in the
						European Union or Switzerland, or any other countries added from
						time to time. However, the app/platform can be used by users located
						in these regions at their own discretion subject to compliance with
						the relevant regulations associated in those regions.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Malicious Behavior
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						Bima Central doesn’t allow any other app/platforms to steal data,
						secretly monitor or harm users, or are otherwise give access to any
						other platform which are malicious in nature. The following are
						expressly prohibited:
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						<ul>
							<li className="mt-10">
								Viruses, trojan horses, malware, spyware, or any other malicious
								software;
							</li>
							<li className="mt-10">
								App/platforms that link to or facilitate the distribution or
								installation of malicious software;
							</li>
							<li className="mt-10">
								App/platforms or Software Development Kit “SDKs” that download
								executable code, such as dex files or native code, from a source
								other than Google Play;
							</li>
							<li className="mt-10">
								App/platforms that introduce or exploit security
								vulnerabilities;
							</li>
							<li className="mt-10">
								App/platforms that steal a user’s authentication information
								(such as usernames or passwords) or that mimic other
								app/platforms or websites to trick users into disclosing
								personal or authentication information;
							</li>
							<li className="mt-10">
								App/platforms that install other app/platforms on a device
								without the user’s prior consent;
							</li>
							<li className="mt-10">
								App/platforms designed to secretly collect device usage, such as
								commercial spyware app/platforms.
							</li>
						</ul>
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Intellectual Property Rights:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						All trademarks, copyright, database rights and other intellectual
						property rights associated with the app/platform together with the
						underlying software code are owned directly & jointly by CAMSRep
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						Any unauthorized reproduction, republication, distribution, display,
						transmission, sale, or any other use and/or duplication of any
						material available on App/platform, without express and written
						approval of CAMSRep shall be violation of the relevant intellectual
						property laws.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Eligibility for Registration & Services for Bima Central:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						Existing e-Insurance Account holders serviced by CAMSRep shall be
						authorized to register for Bima Central/App/Platform and to avail
						the services provided only upon fulfilling the following
						requirements -
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						<ul>
							<li className="mt-10">
								shall be a Resident Indian Individual and who is Know Your
								Client (“KYC”) compliant; NRI
							</li>
							<li className="mt-10">
								shall have mode of operation / holding of the existing
								e-Insurance Account as "single" or as "anyone or survivor" and
								having an email ID registered designated as Authorized
								Representative
							</li>
						</ul>
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						Depending upon the requirements, CAMSRep may add / modify the
						eligibility conditions from time to time basis, at its own
						discretion.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							System Requirements:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						In order to use the App/platform, you are required to have a
						compatible desktop / laptop / mobile telephone or any other
						compatible device, internet access and the necessary minimum
						specifications.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Software Requirements:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						Bima Central, shall on a best effort basis support the mobile
						operating systems such as Android, App/platform, Apple iOS and
						Windows. Platform does not guarantee full compatibility for each
						type of systems/mobile phone/device display pertaining to size,
						pixels, and its mobile operating system in current or later versions
						due to the multitude of operating systems and their different
						functioning caused by specific features. CAMSRep shall inform about
						any such updates to the App/platform through any communication
						channel as decided by CAMSRep.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Registration:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						To submit transactions for all insurance policies serviced by
						CAMSRep in Bima Central, relevant App/platform registration is
						required, and it is allowed based on the email ID/DOB/PAN
						Number/mobile number registered / available at CAMSRep. The
						App/platform shall be downloaded from mobile store / through{' '}
						<span className="footer">
							<u>app.bimacentral.in </u>
						</span>
						logged-in with the provided login credentials subject to the
						condition that the login credentials are changed upon first login
						and kept secure by the investors. The user may also be required to
						enter the One Time Password(OTP) sent to his registered mobile No.
						to proceed with registration.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						CAMSRep shall endeavor to provide to the User through Bima Central,
						such services as CAMSRep may decide from time to time. CAMSRep
						reserves its right to decide the type of services, which a category
						of User may be offered on each account and may differ from category
						to category.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						CAMSRep at its sole discretion shall add /delete the services
						offered to the User through Bima Central.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						Access to Bima Central app/platform shall be restricted -
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						<ul>
							<li className="mt-10">
								if the user allows any other person to have access to Bima
								Central App/platform other than the registered user or/and;
							</li>
							<li className="mt-10">
								if the user attempts or permits to attempt accessing the account
								information stored in the mobile through any unauthorized means.
							</li>
						</ul>
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User of Bima Central grants express irrevocable authority to
						CAMSRep to act upon any instruction given by the User through Bima
						Central, as may be permitted through the app/platform from time to
						time and until valid termination of Bima Central app/platform
						service by the User. CAMSRep shall have no obligation to verify the
						authenticity of any transaction(s) received through Bima Central or
						purporting to have been sent by the User via Bima Central other than
						by means of verification of the login credentials.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The display shown at the time of operation of Bima Central in the
						mobile phone/device is a proof of record that the operation was made
						with the use of mobile phone/device and shall not be construed as
						final record of the transaction. The records maintained by CAMSRep
						at its end through computer systems will be deemed as final and
						conclusive, which will be the binding evidence for all purposes.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							CAMSRep Portfolio View:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						This is to confirm my/our acceptance and app/platform approval to
						request for and to download the data as available in the
						Consolidated Annual Account Statement (an initiative by Insurance
						Repository to provide one view of holdings of an investor), across
						all Insurance Policies. I am / We are also providing my/our
						app/platform approval for Bima Central to store such data for my/our
						convenience of getting one consolidated view and CAMSRep will not be
						held liable for any consequences, whatsoever, due to this action and
						I/We shall keep CAMSRep Indemnified against any claims /
						litigations"
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Usage by minors:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						Bima Central is not intended for use by minors. The user should
						ensure that only the guardian of the Minor shall avail this service.
						In the event, any loss arises as a result of transaction entered by
						the minor, the CAMSRep shall not be held liable for any such
						loss/damages.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Responsibility & Obligation of the User
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						The User of Bima Central App/platform accepts to have read and
						understood the {/* <span className="footer"> */}
						<u>terms of the use of the Bima Central App/platform.</u>
						{/* </span> */}
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User hereby agrees and accepts that the User is well acquainted
						with the terms and conditions of the respective Insurance Company’s
						policy documents and terms and conditions of processing of various
						insurance transactions of the respective Insurance Company.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User acknowledges that the terms of agreement with your
						respective mobile phone/device network provider will continue to
						apply when using the App/platform. As a result, the user may be
						charged by the mobile phone/device network provider for access to
						network connection services for the duration of the connection while
						accessing the App/platform or any such third-party charges as may
						arise. The User accepts responsibility for any such charges that
						arise.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User accepts that transaction instructions entered on
						non-business days will be processed on the following business day.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User accepts that the information provided to the User through
						Bima Central may not be updated on a real-time basis. Consequently,
						any information supplied to the User will pertain to the date and
						time when it was last updated. CAMSRep shall not be liable for any
						loss that the User may suffer by relying on or acting on such
						information.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User accepts that their use of Bima Central shall be subject to
						the terms and conditions of any applicable agreements between
						CAMSRep, Service Providers and /or the User.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User understands that the payment for the transaction request
						shall be through a payment gateway of the respective Insurance
						Company and CAMSRep shall not be held responsible for any failures
						in the link or for any fraud (either at the payment gateway's end
						and/or the bank's end) that could take place at the time of making
						payment.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The policy holder can appoint his family member, nominee of any
						policy, trusted friend, etc as Authorized Representative through the
						Bima Central portal. Upon the demise of the policy holder, the
						authorised representative entitle to have access to Bima Central by
						submitting the proof of the demise of the policy holders.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User who access the Bima Central portal in capacity of the
						authorised representative to operate the eIA of the deceased policy
						holder shall have the below facilities alone –
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						<ol style={{ listStyleType: 'lower-alpha' }}>
							<li className="mt-10">
								To have read only access to the eIA of the deceased policy
								holders.
							</li>
							<li className="mt-10">
								To identify the insurance policies of the deceased policy holder
							</li>
							<li className="mt-10">
								To assist the nominee or legal heirs of the policy holders to
								claim the proceeds of the insurance policy (ies).
							</li>
							<li className="mt-10">
								The Authorized Representative cannot entitle to claim the
								benefits of the insurance policies of the deceased policy
								holders.
							</li>
						</ol>
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Limitation of Liability
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						In no event, shall CAMSRep or its Sponsors or their associates or
						any person connected with it be liable for any direct, indirect,
						special, consequential or other damages (including, without
						limitation, any loss of profits, goodwill, business interruption)
						that are related to the User’s use or access to the App/platform,
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Indemnity
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						The User hereby accepts the sole responsibility for maintenance of
						the password of Bima Central, and that CAMSRep and any person
						connected with it shall not be held liable for any loss suffered by
						the User due to disclosure of the password and other personal
						information to a third party.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User understands that the User's mobile phone/device can be
						misused in the event of loss of the mobile phone/device, or it is
						falling in the wrong hands. The User shall not hold CAMSRep, its
						Sponsors, their associates, and any person connected with it,
						responsible for any loss that the User or any third party may suffer
						due to such circumstances.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User shall at all times indemnify CAMSRep, its Sponsors, their
						associates and any person connected with it, from and against any
						and all actions, proceedings, claims and demands whatsoever for or
						on account of or in relation to any use / misuse of the Bima Central
						by the User or any third party using the User's login credentials,
						and from and against any and all damages, costs, liabilities,
						charges and expenses or other loss relating to the use of Bima
						Central in any manner, including without limitation any action of
						User which is in violation of these terms and conditions and against
						any applicable law.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User agrees that any transaction/ information that is entered
						through Bima Central with the provided login credentials, whether
						initiated by the User or not, shall be deemed to have originated
						from the User. The User shall be held liable for all transactions
						and any losses from unauthorized transactions in the User's
						portfolio through Bima Central.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User hereby accepts that in case of any discrepancy or any error
						in the information in Bima Central, the User shall inform CAMSRep
						immediately. CAMSRep will endeavor to correct the error promptly
						wherever possible on a best error basis.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Termination & Suspension of Bima Central Service
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						CAMSRep may terminate use of the App/platform at any time by giving
						notice of termination to the User for such reason as CAMSRep may, in
						its sole and absolute discretion deem proper.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						CAMSRep may change, suspend or discontinue any aspect of the Bima
						Central, including the availability of any feature(s) of the, at any
						time by giving notice to the User for such changes.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User can request for termination of the Bima Central Services at
						any time by giving written notice to CAMSRep.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The User agrees that the User shall remain responsible for any
						transactions made on Bima Central until the services are terminated.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The CAMSRep shall not be liable for any costs, expenses, or damages
						as a result of the termination of these Terms.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Effective Date:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						These T&Cs shall come into effect the moment you “Agree” the check
						box provided at the end of Bima Central Confidentiality and Security
						Protocol Terms and Conditions.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Confidentiality:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						You hereby acknowledge that all information accessed by you with the
						usage of various existing app/platform solutions or any future
						app/platform that may be developed by CAMSRep from time to time is
						confidential information (“Confidential Information”).You hereby
						agree that during the term of your usage of the Bima Central, and
						thereafter for so long as the Confidential Information is not in the
						public domain, You shall: (a) take all reasonable care and
						precautions to prevent third parties/other parties from gaining
						unauthorized access to the Confidential Information;(b) shall share
						it with employees, agents and authorised representatives who ‘need
						to know’ the Confidential Information for the sole purpose of
						availing services of Bima Central Platform; (c) ensure that you,
						your employees, agents, authorized representatives etc. maintain the
						Confidential Information in strictest confidence and in accordance
						with the confidentiality obligations hereunder; and (d) shall only
						use the Confidential Information for the sole purpose of availing
						the services of Bima Central Platform and not otherwise.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The foregoing obligations of confidentiality shall not apply if the
						Confidential Information (i) is already in your knowledge prior to
						the accessing the confidential information; (ii) is or becomes
						publicly known through no wrongful act or breach of your obligations
						hereunder; (iii) is rightfully received from third party other than
						by reason of a breach of any obligation owed to CAMSRep hereunder;
						(iv) is independently developed by you without reference to
						Confidential Information provided by CAMSRep; (v) is approved for
						release by written authorization of CAMSRep; (vi) is disclosed
						pursuant to the order of a regulatory agency or court of law,
						provided you shall give CAMSRep reasonable advance notice to contest
						the disclosure and you shall comply with any applicable protective
						order or equivalent obtained by CAMSRep;
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						Data accessed shall be used only for the purpose for which it was
						authorized or obtained by you and shall not be used for any other
						purposes/ any unlawful purpose or in violation of any applicable
						laws.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The receipt of any Confidential Information by you does not confer
						any intellectual property rights in the said Confidential
						Information.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						You shall at all times comply with all the applicable laws
						pertaining to Confidentiality and Data Protection.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The data shall also be purged by you, once the requirement is
						completed.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Security Protocols:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						<ul>
							<li className="mt-10">
								Your network shall be protected with Firewall, Intrusion
								Detection and Prevention System and other means of Access
								Control, which in the opinion of CAMSRep are adequate and
								capable of protecting the network against unauthorized access.
								You shall be entitled to add any other additional security
								features as you may consider necessary;
							</li>
							<li className="mt-10">
								You shall have adequate access control measures and the measures
								shall always remain active and shall not be removed or
								reconfigured which could create or enhance the risk of
								unauthorized access to the CAMSRep network;
							</li>
							<li className="mt-10">
								You shall also ensure that the access to Bima Central platform
								is adequately controlled in such a way that unauthorized access
								is prevented;
							</li>
							<li className="mt-10">
								You shall always have adequate Virus Detection and Control
								measures in your network;
							</li>
							<li className="mt-10">
								You shall immediately notify any Cyber incidents to CAMSRep such
								as unauthorized access or any type of cyber-attack taking place
								into your network;
							</li>
							<li className="mt-10">
								You hereby agree that you shall keep confidentiality of all
								information about the App/platform, hardware, data, the network
								diagram, the IP addresses, the Network Architecture, the Router
								Configurations, and other technical details if they were
								disclosed by CAMSRep to you;
							</li>
							<li className="mt-10">
								You hereby agree that, in the interest of data security, should
								CAMSRep observe or apprehend a risk of unauthorized access,
								CAMSRep will, at its sole discretion; terminate access at
								CAMSRep' end with or without a reasonable notice to you;
							</li>
							<li className="mt-10">
								You hereby acknowledge that the Intellectual Property Rights of
								CAMSRep of its various App/platform solutions including the
								App/platform in the Software Systems, Procedures, Operating,
								Quality, Control, Audit and User Manuals, Database Design,
								Screens, Source and Object Code, Reports etc. designed by
								CAMSRep are made available to you. You hereby agree to keep them
								confidential, disclose only to employees, auditors or other
								third-party agents on a need-to-know basis, and not to attempt
								to reverse engineer or otherwise duplicate these designs
								directly or indirectly. You hereby also agree that such
								information shall not be disclosed under any circumstances to
								individuals or entities who / that may have a business interest
								competitive to that of CAMSRep.
							</li>
							<li className="mt-10">
								You hereby represent and warrant that the entities or
								individuals authorized by you shall access the Bima Central
								appropriately and legally. Further entities or individuals
								authorized by you shall be explained that their illegal usage of
								CAMSRep App/platforms and Data access would not only render
								immediate termination of the access to the respective
								App/platform but also constrain you and the concerned entities
								or individuals either jointly or severally to indemnify CAMSRep
								for any loss incurred by CAMSRep by virtue of the illegal use.
							</li>
							<li className="mt-10">
								You hereby acknowledge that the existing App/platform Solutions
								being provided by CAMSRep, or any other solutions will be made
								available to you from time to time are subject to the directions
								issued and access controls made by respective regulatory
								authority from time to time. CAMSRep shall not be held liable
								for any direct or indirect consequences under this context.
							</li>
							<li className="mt-10">
								You hereby acknowledge that you and entities or individuals
								authorized by you representing you shall use the CAMSRep
								App/platforms in compliance with these terms and conditions.
							</li>
						</ul>
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Governing Law and Jurisdiction:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						In case of any dispute, claim or controversy arising out of or
						relating to these Terms or the breach, termination, enforcement,
						interpretation or validity thereof or the use of the Bima Central
						(collectively “Disputes”), CAMSRep and you shall attempt to settle
						the same amicably, through negotiation and consultation at such
						offices of the CAMSRep as the Company may designate.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						In the event the dispute remains unresolved for 15 (fifteen) days,
						either party may refer such dispute to arbitration according to the
						provisions of the Arbitration and Conciliation Act of 1996, amended
						from time to time, to a sole arbitrator appointed mutually by the
						parties.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The language of the arbitration shall be English and the arbitral
						award shall be binding on both the parties.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The seat and venue of the arbitration shall be Chennai, State of
						Tamil Nadu, unless otherwise mutually agreed by the CAMSRep and you.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						The court of law at Chennai, State of Tamil Nadu shall have the
						exclusive jurisdiction over any disputes arising under this
						arrangement.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Changes:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						CAMSRep reserves the rights, at its sole discretion, to change,
						modify, add or remove any portion of the Terms and Conditions of
						Terms of Use in whole or in part, at any time. Changes in these
						Terms of Use will be effective when notice of such change is posted.
						User’s continued use of the Bima Central after any changes are
						posted will be considered acceptance of those changes.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Survival:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						You hereby agree that the clauses on Confidentiality, Liability &
						Indemnity and Intellectual Property shall survive even after you
						cease to use the Bima Central /App/platform.
					</Typography>
					<Grid item>
						<Typography
							fontSize={18}
							color="black"
							fontWeight={800}
							fontFamily="sans-serif"
							className="mb-5 mt-10"
						>
							Miscellaneous:
						</Typography>
					</Grid>
					<Typography className="mt-10" fontSize={14}>
						The headings in this document are for convenience only and are not
						intended to have any legal effect nor purport to be complete or
						accurate descriptions of the content thereof and shall not be used
						to interpret the provisions of this document.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						In the event, any of the aforesaid terms and conditions shall be
						rendered/held to be unenforceable due to any regulatory changes, the
						remaining terms and conditions shall be unimpaired, and the
						unenforceable term or condition shall be replaced by such
						enforceable term or condition as may come closest to the intention
						underlying the unenforceable term or condition.
					</Typography>
					<Typography className="mt-10" fontSize={14}>
						No waiver of any breach or default of the Agreement shall be deemed
						to be a waiver of any preceding or subsequent breach or default.
					</Typography>
				</Grid>
			</Box>
		</Grid>
	);
};

export default DisclaimerScreen;
