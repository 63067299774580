import { Box, Grid, Link, Typography } from '@mui/material';
import DisclaimerScreen from './DisclaimerScreen';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import { ReactComponent as cancel } from '../../../common/icons/cancel-icon.svg';
import CustomButton from '../../../common/ui/CustomButton';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';
import PrivacyPolicyScreen from './PrivacyPolicyScreen';
import PrivacyScreen from './PrivacyScreen';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
// import { ReactComponent as cancelIcon } from '../../../../../common/icons/cancel-icon.svg';
import { ReactComponent as cancelIcon } from '../../../common/icons/cancel-icon.svg';

const PrivacyModal = ({ open, setOpen }: any) => {
	const { t } = useTranslation();

	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName="px-20 py-30 mt-10"
			boxProps={{ width: 355 }}
		>
			<Grid style={{ maxHeight: '100vh', overflowY: 'auto' }}>
				<Grid container justifyContent="space-between" className="p-8 ">
					<Grid item>
						<Typography
							fontSize={16}
							fontWeight={600}
							fontFamily="inherit"
							className="mb-5 mt-15"
							color="primary"
						>
							Privacy Policy
						</Typography>
					</Grid>

					<Grid item>
						<CustomButton
							className="f-13 fw-600 ml-8 mt-15"
							text="Close"
							variant="text"
							color="primary"
							// showIcon={false}
							endIcon={cancel}
							type="submit"
							onClick={() => setOpen(false)}
						/>
					</Grid>
				</Grid>
				<Grid item className="mt-10">
					<Separator
						color={theme.palette.grey[300]}
						borderWidth={1}
						className="mb-15"
					/>
				</Grid>

				<PrivacyPolicyScreen />
			</Grid>
		</CommonModal>
	);
};

export default PrivacyModal;
