import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import { ReactComponent as OrangeRuppee } from '../../../../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../../../../common/utils';
import Separator from '../../../../../../common/ui/Separator';

interface AddonProps {
	addonDetails?: any;
	detailType?: any;
}

const Addon: React.FC<AddonProps> = ({ addonDetails, detailType }) => {
	const { t } = useTranslation();

	return (
		<Grid container className="mb-20">
			{addonDetails?.[0] &&
				((addonDetails?.[0]?.description?.length !== 0 &&
					addonDetails?.[0]?.description !== undefined) ||
					addonDetails?.[0]?.coverAmount?.length !== 0) && (
					<Grid item container xs={12} className="mt-15">
						{detailType !== 'G' && (
							<Grid item xs={6}>
								<Typography
									color={theme.palette.primary.main}
									fontSize={14}
									fontWeight={600}
								>
									{t('ADD_ON')}
								</Typography>
							</Grid>
						)}

						<Grid item xs={6} className="">
							<Typography
								color={theme.palette.primary.main}
								fontSize={14}
								fontWeight={600}
							>
								{t('COVER_AMOUNT')}
							</Typography>
						</Grid>
					</Grid>
				)}

			{addonDetails &&
				addonDetails?.length > 0 &&
				addonDetails.map((mapdata: any, index: any) => (
					<>
						{(mapdata?.description?.length !== 0 ||
							mapdata?.coverAmount?.length !== 0) && (
							<>
								<Grid item container xs={12}>
									{addonDetails?.[0]?.coverAmount?.length > 0 &&
										detailType !== 'G' && (
											<Grid item xs={0.5} className="">
												<Typography
													color={theme.palette.common.black}
													fontSize={16}
													fontWeight={600}
												>
													{index + 1}.
												</Typography>
											</Grid>
										)}
									<>
										{addonDetails?.[0]?.coverAmount?.length > 0 &&
											detailType !== 'G' && (
												<Grid item xs={5.5} className="">
													<>
														{/* {detailType && ( */}
														<Typography
															color={theme.palette.common.black}
															fontSize={16}
															fontWeight={600}
														>
															{mapdata && mapdata?.description?.length > 0
																? mapdata.description
																: '-'}
														</Typography>
														{/* )} */}
													</>
												</Grid>
											)}
									</>
									{addonDetails?.[0]?.coverAmount?.length > 0 && (
										<Grid item xs={5} className="">
											<Typography
												color={theme.palette.common.black}
												fontSize={16}
												className="fw-600"
											>
												{mapdata?.coverAmount?.length > 0
													? mapdata.coverAmount
													: '-'}
											</Typography>
										</Grid>
									)}
								</Grid>
								<Grid container item xs={12} className="py-10">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
							</>
						)}
					</>
				))}
		</Grid>
	);
};
export default Addon;
