/* eslint-disable no-console */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-async-promise-executor */
import crypto from '../../crypto-custom';
import config from './config';
const algorithm = 'aes-256-cbc';
const encrypt = (input, key, iven) =>
	new Promise(async (resolve, reject) => {
		try {
			const hash = crypto.createHash('sha256').update(key).digest();
			let hashstring = hash.toString('hex');
			hashstring = hashstring.substring(0, 32);
			const cipher = crypto.createCipheriv(algorithm, hashstring, iven);
			let encrypted =
				cipher.update(input, 'utf8', 'base64') + cipher.final('base64');
			encrypted = encrypted.split('+').join('-');
			encrypted = encrypted.split('/').join('_');
			if (process.env.REACT_APP_MODE !== 'PROD') {
				console.log('encrypted-->', encrypted);
			}
			resolve(encrypted);
		} catch (e) {
			reject(e);
		}
	});
const decrypt = async (encryptedInput, key, iven) =>
	new Promise(async (resolve, reject) => {
		try {
			const hash = crypto.createHash('sha256').update(key).digest();
			let hashstring = hash.toString('hex');
			hashstring = hashstring.substring(0, 32);
			// let decryptedInput = encryptedInput.split('-').join('+');
			// decryptedInput = decryptedInput.split('_').join('/');
			const decipher = crypto.createDecipheriv(algorithm, hashstring, iven);
			const decrypted =
				decipher.update(JSON.stringify(encryptedInput), 'base64', 'utf8') +
				decipher.final('utf8');
			if (process.env.REACT_APP_MODE !== 'PROD') {
				console.log('decrypted-->', decrypted);
			}
			resolve(decrypted);
		} catch (e) {
			reject(e);
		}
	});
const generateChecksum = async (input, key) =>
	new Promise(async (resolve, reject) => {
		try {
			resolve(crypto.createHmac('sha256', key).update(input).digest('hex'));
		} catch (e) {
			reject(e);
		}
	});
// export {encryptRequest, decryptResponse, generateRandomUUID};
export const EncryptCamsPayRequest = (data) =>
	new Promise(async (resolve, reject) => {
		try {
			// console.log("Cams Payload -->", data);
			const idPayload = {
				merchantid: config.camsMerchantId,
				subbillerid: config.camsSupplierId,
				paytype: 'ALL',
			};
			const id = await encrypt(
				JSON.stringify(idPayload),
				config.camsIdKey,
				config.camsIdIv
			);
			const req = await encrypt(
				JSON.stringify(data),
				config.camsReqKey,
				config.camsReqIv
			);
			const checksum = await generateChecksum(
				JSON.stringify(data),
				config.camsChecksumKey
			);
			resolve({ id, req, checksum });
		} catch (error) {
			if (process.env.REACT_APP_MODE !== 'PROD') {
				console.log(error);
			}
			reject(false);
		}
	});
export const DecryptCamsPayRequest = (encryptedText) =>
	new Promise(async (resolve, reject) => {
		try {
			const decryptedText = await decrypt(
				encryptedText,
				config.camsDecKey,
				config.camsReqIv
			);
			if (process.env.REACT_APP_MODE !== 'PROD') {
				console.log('dec==>', JSON.parse(decryptedText));
			}
			resolve(JSON.parse(decryptedText));
		} catch (error) {
			if (process.env.REACT_APP_MODE !== 'PROD') {
				console.log(error);
			}
			reject(false);
		}
	});
