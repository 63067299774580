import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
	fetchMobileNumbers,
	fetchPoliciesForMobileNumber,
	generateOtpForMobile,
	updateMobileNumbers,
	verifyOtpForMobile,
} from './MobileNumber.service';
import {
	errorLoadingPoliciesForMobileNumber,
	loadPoliciesForMobileNumber,
	fetchPoliciesForMobileNumber as fetchPoliciesForMobileNumberAction,
	fetchMobileNumbers as fetchMobileNumbersAction,
	loadMobileNumbers,
	errorLoadingMobileNumbers,
	updateMobileNoRequest,
	updateMobileNoSuccess,
	updateMobileNoError,
	generateOtpForMobileRequest,
	generateOtpForMobileSuccess,
	generateOtpForMobileError,
	verifyMobileNoRequest,
	verifyMobileNoSuccess,
	verifyMobileNoError,
	selectMobileNoChangesList,
} from './MobileNumber.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { RequestTypes } from '../../../common/utils';
import { setAlertInfo } from '../Common/Common.slice';
import { fetchUserProfile } from '../Profile/Profile.slice';

function* handleFetchPoliciesForMobileNumber() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(
			fetchPoliciesForMobileNumber,
			requestPayload
		);
		data.MobileBasedPolicies = data.MobileBasedPolicies?.map((row: any) => ({
			...row,
			mobileNo: String(row.mobileNo).trim() === '' ? 'null' : row.mobileNo,
		}));
		yield* put(loadPoliciesForMobileNumber(data || {}));
	} catch (e: any) {
		yield* put(errorLoadingPoliciesForMobileNumber(e.message));
	}
}

function* handleFetchMobileNumbers({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				value: payload,
				flag: RequestTypes.MOBILE,
			},
		};
		const { data }: any = yield* call(fetchMobileNumbers, requestPayload);
		yield* put(loadMobileNumbers(data.mobileNoList || []));
	} catch (e: any) {
		yield* put(errorLoadingMobileNumbers(e.message));
	}
}

function* handleUpdateMobileNumbers({ payload: { handleSuccess, t } }: any) {
	try {
		const Actions = yield* select(selectMobileNoChangesList);
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},

			flag: RequestTypes.MOBILE,
			Actions,
		};
		const { data }: any = yield* call(updateMobileNumbers, requestPayload);
		yield* put(updateMobileNoSuccess(data.mobileNoList || []));
		if (handleSuccess) {
			handleSuccess();
		}
		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		yield* put(fetchUserProfile());
	} catch (e: any) {
		yield* put(updateMobileNoError(e.message));
	}
}

function* handleGenerateOtpForMobile({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				value: payload?.mobileNo,
				flag: 'MOBILE',
				otpPurpose: 'ADD_MOBILE',
				eiaNo: user?.eiaNo,
			},
		};
		const requestPayload2 = {
			Customer: {
				value: payload?.data?.mobileNo,
				flag: 'MOBILE',
				otpPurpose: 'PRIMARY_MOBILE',
				eiaNo: payload?.eia,
			},
		};
		if (payload.types === 'signUpEmail') {
			const { data }: any = yield* call(generateOtpForMobile, requestPayload2);
			yield* put(generateOtpForMobileSuccess(data));

			payload.data.handleSuccess(data);
		} else {
			const { data }: any = yield* call(generateOtpForMobile, requestPayload);
			yield* put(generateOtpForMobileSuccess(data));
			if (handleSuccess) {
				handleSuccess(data);
			}
		}
	} catch (e: any) {
		yield* put(generateOtpForMobileError(e.message));
	}
}

function* handleVerifyOtp({ payload: { handleSuccess, ...payload } }: any) {
	try {
		const requestPayload = {
			Customer: {
				value: payload?.mobileNo,
				otp: payload?.otpCode,
				flag: 'MOBILE',
				otpPurpose: 'UPDATE_MOBILE',
			},
		};
		const requestPayload2 = {
			Customer: {
				value: payload?.data?.mobileNo,
				otp: payload?.data?.otpCode,
				flag: 'MOBILE',
				otpPurpose: 'PRIMARY_MOBILE',
				eiaNo: payload?.eiaNo,
			},
		};
		if (payload.types === 'signUpMobile') {
			const { data }: any = yield* call(verifyOtpForMobile, requestPayload2);
			yield* put(verifyMobileNoSuccess(data));

			payload?.data?.handleSuccess(data);

			yield* put(
				setAlertInfo({
					open: true,
					type: 'SUCCESS',
					description: data.displayMessage,
				})
			);
		} else {
			const { data }: any = yield* call(verifyOtpForMobile, requestPayload);
			yield* put(verifyMobileNoSuccess(data));
			if (handleSuccess) {
				handleSuccess(data);
			}

			yield* put(
				setAlertInfo({
					open: true,
					type: 'SUCCESS',
					description: data.displayMessage,
				})
			);
		}
	} catch (e: any) {
		yield* put(verifyMobileNoError(e.message));
	}
}

function* watchMobileNumbers() {
	yield* takeEvery(
		fetchPoliciesForMobileNumberAction.type,
		handleFetchPoliciesForMobileNumber
	);
	yield* takeEvery(fetchMobileNumbersAction.type, handleFetchMobileNumbers);
	yield* takeEvery(updateMobileNoRequest.type, handleUpdateMobileNumbers);
	yield* takeEvery(
		generateOtpForMobileRequest.type,
		handleGenerateOtpForMobile
	);
	yield* takeEvery(verifyMobileNoRequest.type, handleVerifyOtp);
}

// eslint-disable-next-line import/prefer-default-export
export { watchMobileNumbers };
