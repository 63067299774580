import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../common/OtpScreen.css';
import { OTP_PURPOSE } from '../../../common/Enums';
import {
	fetchOtpRequest,
	fetchOtpVerify,
	selectIsInitiateEkycLoading,
	selectIsOtpRequestLoading,
	selectIsOtpValidationLoading,
	selectIsSignUpData,
	selectSignUpPayload,
} from '../auth.slice';
import { AUTH_ROUTES } from '../../../common/constants/Routes';
import OtpDesign from './OtpDesign';
import config from '../../../common/config';

const EkycinCamsOtpScreen = () => {
	const location = useLocation();
	// eslint-disable-next-line no-unused-vars
	const mobileNo = location?.state?.mobileNo;
	const inputType = location?.state?.inputType;
	const email = location?.state?.email;
	const otpVerifyLoading = useSelector(selectIsOtpValidationLoading);
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const [otp, setOtp] = useState('');
	const [seconds, setSeconds] = useState(config.otpDuration);

	const isOtpRequestLoading = useSelector(selectIsOtpRequestLoading);
	// const isInitiateEkycLoading = useSelector(selectIsInitiateEkycLoading);
	const signUpDetails = useSelector(selectIsSignUpData);
	const signUpPayload = useSelector(selectSignUpPayload);
	const onVerifyOtpClick = () => {
		const handleSuccess = () => {
			navigation(AUTH_ROUTES.SIGNUP_EKYC_DETAILS_ONE, {
				state: {
					flowType: signUpDetails?.eiaStatus,
					userData: { ...signUpPayload, ...signUpDetails?.Customer },
				},
			});
		};
		dispatch(
			fetchOtpVerify({
				Customer: {
					value:
						signUpDetails?.Customer?.email && signUpDetails?.Customer?.mobileNo
							? inputType === 'email'
								? signUpDetails?.Customer?.email
								: signUpDetails?.Customer?.mobileNo
							: signUpDetails?.Customer?.email
							? signUpDetails?.Customer?.email
							: signUpDetails?.Customer?.mobileNo,
					otp,
					otpPurpose: OTP_PURPOSE.SIGNIN,
					flag:
						signUpDetails?.Customer?.email && signUpDetails?.Customer?.mobileNo
							? inputType === 'email'
								? 'EMAIL'
								: 'MOBILE'
							: signUpDetails?.Customer?.mobileNo
							? 'MOBILE'
							: 'EMAIL',
					eiaNo: signUpDetails?.Customer?.eiaNo,
				},
				navigation,
				handleSuccess,
			} as any)
		);
	};
	const handleResendOtp = () => {
		const handleSuccess = () => {
			setSeconds(config.otpDuration);
		};
		const data: any = {
			eiaNo: signUpDetails?.Customer?.eiaNo,
			value:
				signUpDetails?.Customer?.email && signUpDetails?.Customer?.mobileNo
					? inputType === 'email'
						? signUpDetails?.Customer?.email
						: signUpDetails?.Customer?.mobileNo
					: signUpDetails?.Customer?.email
					? signUpDetails?.Customer?.email
					: signUpDetails?.Customer?.mobileNo,
			otpPurpose: OTP_PURPOSE.SIGNIN,
			flag:
				signUpDetails?.Customer?.email && signUpDetails?.Customer?.mobileNo
					? inputType === 'email'
						? 'EMAIL'
						: 'MOBILE'
					: signUpDetails?.Customer?.mobileNo
					? 'MOBILE'
					: 'EMAIL',
		};
		dispatch(
			fetchOtpRequest({
				Customer: data,
				handleSuccess,
				navigation,
			} as any)
		);
	};

	return (
		<OtpDesign
			handleResendOtp={handleResendOtp}
			onVerifyOtpClick={onVerifyOtpClick}
			isOtpRequestLoading={isOtpRequestLoading}
			isOtpValidationLoading={otpVerifyLoading}
			otp={otp}
			setOtp={setOtp}
			inputType={inputType}
			mobileNo={mobileNo}
			email={email}
			seconds={seconds}
			setSeconds={setSeconds}
		/>
	);
};

export default EkycinCamsOtpScreen;
