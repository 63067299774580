import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RupeeIconSmall } from '../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';

interface ProductProps {
	product?: string;
	repaymentOptions?: string;
	decideLater?: string;
	chargeAmount?: any;
	interestPercentage?: any;
	loanAmount?: any;
}

const SelectFinancierDetails = ({
	product,
	repaymentOptions,
	decideLater,
	chargeAmount,
	interestPercentage,
	loanAmount,
}: ProductProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography className="f-14 fw-600" color="primary">
					{product}
				</Typography>
			</Grid>
			<Grid item xs={12} color={theme.palette.primary.main}>
				<Typography className="f-12">{t('LOAN_AMOUNT')}</Typography>
			</Grid>

			<Grid item xs={12}>
				<Grid container>
					<Grid item>
						<LabelWithIcon
							className="px-3"
							svgClassName=""
							justifyContent="flex-start"
							Icon={RupeeIconSmall}
							label={loanAmount}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
					</Grid>

					<Grid item>
						<Typography className="f-12" color={theme.palette.grey.A700}>
							@ &nbsp;
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							className="f-12 fw-600"
							color={theme.palette.common.black}
						>
							{interestPercentage}
						</Typography>
					</Grid>
					<Grid item>
						<Typography className="f-12" color={theme.palette.grey.A700}>
							{t('INTEREST')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container flexWrap="nowrap">
					<Grid item>
						<Typography className="f-12" color={theme.palette.primary.main}>
							{t('CHARGES')}:
						</Typography>
					</Grid>
					<Grid item>
						<LabelWithIcon
							className="px-6"
							svgClassName=""
							justifyContent="flex-start"
							Icon={RupeeIconSmall}
							label={chargeAmount}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
								fontWeight: 600,
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography className="f-12" color={theme.palette.primary.main}>
					{repaymentOptions}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className="f-12 fw-600">{decideLater}</Typography>
			</Grid>
		</Grid>
	);
};

export default SelectFinancierDetails;
