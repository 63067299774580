import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../common/utils';

interface annualPayoutProps {
	payDetail: string | number;
	date?: any;
	fundValue?: any;
	fundAmt?: any;
}
const LoanPayoutDetails = ({
	payDetail,
	date,
	fundValue,
	fundAmt = 'XXXXX',
}: annualPayoutProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			className="h-100"
		>
			<Grid item>
				{payDetail === 'annual' && (
					<Typography className="f-14" color={theme.palette.common.black}>
						{t('Annual Payout on')}
						{date}
					</Typography>
				)}
				{payDetail === 'surrender' && (
					<Typography
						className="f-14"
						color={theme.palette.common.black}
						alignItems="right"
					>
						{t('Surrender Value')}
					</Typography>
				)}
				{payDetail === 'partial' && (
					<Typography
						className="f-14"
						color={theme.palette.common.black}
						style={{ display: 'inline' }}
					>
						{t('Partial Withdrawal')} [
						<Typography
							className="f-14"
							color={theme.palette.secondary.main}
							style={{ display: 'inline' }}
						>
							{fundValue}
						</Typography>
						{t('of fund value')}]
					</Typography>
				)}
				{payDetail === 'loan' && (
					<Typography className="f-14" color={theme.palette.common.black}>
						{t('Loan against fund value')}
					</Typography>
				)}

				<Typography className="f-14" color={theme.palette.common.black} />
			</Grid>
			<Grid item>
				<Grid container spacing={0.5}>
					<LabelWithIcon
						className="px-6"
						svgClassName=""
						justifyContent="flex-start"
						Icon={RupeeIcon}
						label={convertAmount(fundAmt)}
						labelProps={{
							color: theme.palette.primary.main,
							fontSize: 16,
							fontWeight: 600,
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LoanPayoutDetails;
