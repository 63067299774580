import React, { useState, useRef, createRef } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cropper, { ReactCropperElement } from 'react-cropper';
import CustomButton from '../CustomButton';
import Separator from '../Separator';
import WebCamModal from './WebCamModal';
import { setAlertInfo } from '../../../features/App/Common/Common.slice';
import { ReactComponent as Cancel } from '../../../../src/common/icons/update-mobile/cancel_orange.svg';
import { convertToBase64, stripBase64Prefix } from '../../utils';
import CommonModal from './CommonModal';
import 'cropperjs/dist/cropper.css';
import './Modal.scss';

const ImagePickerModal = ({
	open,
	setOpen,
	handleImage,
	removePrefix = true,
	enableCropping = false,
	disableWebcam = false,
	isPdf = false
}: any) => {
	const canvasRef = React.createRef();
	const cropperRef = createRef<ReactCropperElement>();
	const [webCamModal, setWebCamModal] = useState<any>(false);
	const [cropperModal, setCropperModal] = useState<any>(false);
	const [image, setImage] = useState<any>();
	const [imageEvent, setImageEvent] = useState<any>();
	const theme = useTheme();
	const dispatch = useDispatch();
	const fileInputRef = useRef<any>(null);

	const { t } = useTranslation();

	const onCapture = (imageBase64: any) => {
		if (imageBase64) {
			setWebCamModal(false);
			setOpen(false);
			if (enableCropping) {
				setImage(imageBase64);
				setCropperModal(true);
			} else {
				if (removePrefix) {
					imageBase64 = stripBase64Prefix(imageBase64);
				}
				handleImage(imageBase64);
			}
		}
	};

	const handleChooseFileClick = () => {
		try {
			if (fileInputRef?.current) {
				fileInputRef.current.type = 'text';
				fileInputRef.current.type = 'file';
				fileInputRef?.current?.click();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleImagePicked = async (e: any) => {
		try {
			if (!isPdf && !String(e?.target?.files[0].type).includes('image')) {
				// eslint-disable-next-line no-throw-literal
				throw 'Invalid file type';
			}
			if (isPdf && !String(e?.target?.files[0].type).includes('application')) {
				throw 'Invalid file type';
			}
			if (!isPdf) {
				let file_img = e?.target?.files[0];
				let base64Image: any = await convertToBase64(e?.target?.files[0]);
				const d = resizeImage({
					file: file_img,
					maxSize: 500
				}).then(function (resizedImage: any) {
					console.log(resizedImage, "resizedImage");
					setOpen(false);
					if (enableCropping) {
						setImage(resizedImage);
						setImageEvent(e);
						setCropperModal(true);
					} else {
						if (removePrefix) {
							base64Image = stripBase64Prefix(resizedImage);
						}
						handleImage(base64Image, e);
					}
				}).catch(function (err) {
					console.error(err);
				});
			}

			if (isPdf) {
				let base64Image: any = await convertToBase64(e?.target?.files[0]);
				setOpen(false);
				if (enableCropping) {
					setImage(base64Image);
					setImageEvent(e);
					setCropperModal(true);
				} else {
					if (removePrefix) {
						base64Image = stripBase64Prefix(base64Image);
					}
					handleImage(base64Image, e);
				}
			}
		} catch (error) {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: `Invalid File Type! Please upload valid policy ${isPdf ? 'PDF' : 'Image'} proceed further. `,
				})
			);
		}
	};

	const handleCrop = () => {
		if (typeof cropperRef.current?.cropper !== 'undefined') {
			let base64Image: any = cropperRef.current?.cropper
				.getCroppedCanvas()
				.toDataURL();
			if (removePrefix) {
				base64Image = stripBase64Prefix(base64Image);
			}
			handleImage(base64Image, imageEvent);
			setImage(false);
			setImageEvent(false);
			setCropperModal(false);
		}
	};
	const cancelCrop = () => {
		setCropperModal(false);
	};
	const handleCancel = () => {
		setOpen(false)
	}

	interface IResizeImageOptions {
		maxSize: number;
		file: File;
	}
	const resizeImage = (settings: IResizeImageOptions) => {
		const file = settings.file;
		console.log(file);
		const maxSize = settings.maxSize;
		const reader = new FileReader();
		const image = new Image();
		const canvas = document.createElement('canvas');
		// const dataURItoBlob = (dataURI: string) => {
		// 	console.log(dataURI, "dataURI")
		// 	const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
		// 		atob(dataURI.split(',')[1]) :
		// 		unescape(dataURI.split(',')[1]);
		// 	const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
		// 	const max = bytes.length;
		// 	const ia = new Uint8Array(max);
		// 	for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
		// 	return new Blob([ia], { type: mime });
		// };
		const resize = () => {
			let width = image.width;
			let height = image.height;

			if (width > height) {
				if (width > maxSize) {
					height *= maxSize / width;
					width = maxSize;
				}
			} else {
				if (height > maxSize) {
					width *= maxSize / height;
					height = maxSize;
				}
			}

			canvas.width = width;
			canvas.height = height;
			let ctx2d: any = canvas.getContext('2d');
			ctx2d.drawImage(image, 0, 0, width, height);
			let dataUrl = canvas.toDataURL('image/jpeg');
			return dataUrl;
		};

		return new Promise((ok, no) => {
			if (!file.type.match(/image.*/)) {
				no(new Error("Not an image"));
				return;
			}

			reader.onload = (readerEvent: any) => {
				image.onload = () => ok(resize());
				image.src = readerEvent.target.result;
			};
			reader.readAsDataURL(file);
		})
	};
	return (
		<>
			<WebCamModal
				open={webCamModal}
				setOpen={setWebCamModal}
				onCapture={onCapture}
			/>
			<input
				ref={fileInputRef}
				className="display-none"
				type="file"
				// accept="image/x-png,image/jpeg"
				onChange={handleImagePicked}
			/>
			<CommonModal
				onClose={() => setOpen(false)}
				open={open}
				modalClassName="py-20"
				boxProps={{ width: 354 }}
			>
				<Grid display={"flex"} justifyContent={"space-between"} style={{ marginLeft: "120px", marginBottom: "10px" }} width={250}>
					<Grid >
						<Typography color={theme.palette.info.main} fontWeight={600}>
							{isPdf ? 'Select Pdf' : t('SELECT_PHOTO')}
						</Typography>


					</Grid>

					<Grid  >
						<CustomButton
							text={""}
							variant="text"
							color="primary"
							showIcon={false}
							startIcon={Cancel}
							onClick={handleCancel}
							fontSize={14}

						/>
					</Grid>
				</Grid>

				<Grid container justifyContent="center" >


					<Grid justifyContent="center" className="mx-5">
						<Typography
							color={theme.palette.common.black}
							fontWeight={600}
							fontSize={10}
						>
							{isPdf ? 'Please upload the pdf and size should not exceed 5 MB' : t('JPEG_SIZE_SHOULD_NOT_EXCEED_5MB')}
						</Typography>
					</Grid>
				</Grid>
				<Separator color={theme.palette.info.main} className="my-10" />
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					flexWrap="nowrap"
				>
					<Grid  >
						<CustomButton
							text={t('CHOOSE_FILE')}
							variant="text"
							color="primary"
							showIcon={false}
							onClick={handleChooseFileClick}
							type="submit"
							fontWeight={600}
							fontSize={14}
						/>

					</Grid>
					{!disableWebcam && !isPdf && (
						<>
							<Grid>
								<div
									className="mx-10"
									style={{
										width: 1,
										height: 30,
										borderRight: `1px solid ${theme.palette.info.main}`,
									}}
								/>
							</Grid>
							<Grid>
								<CustomButton
									text={t('OPEN_WEBCAM')}
									variant="text"
									color="primary"
									showIcon={false}
									onClick={() => setWebCamModal(true)}
									type="submit"
									fontWeight={600}
									fontSize={14}
								/>
							</Grid>
						</>
					)}
				</Grid>
			</CommonModal>
			<CommonModal
				onClose={() => setCropperModal(false)}
				open={cropperModal}
				modalClassName="py-20"
				boxProps={{ maxHeight: '500vh' } as any}
			>
				<Grid container direction="column">
					<Grid item flex={1}>
						<Cropper
							// ref={cropperRef}
							// style={{ height: 400, width: '100%' }}
							// zoomTo={0.5}
							// initialAspectRatio={1}
							// aspectRatio={1}
							// preview=".img-preview"
							// src={image}
							// viewMode={1}
							// minCropBoxHeight={10}
							// minCropBoxWidth={10}
							// background
							// responsive={true}
							// autoCropArea={1}
							// checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
							// guides={true}
							// // draggable
							// // dragMode="move"
							style={{ height: 400, width: '100%' }}
							initialAspectRatio={1}
							preview=".img-preview"
							src={image}
							ref={cropperRef}
							viewMode={1}
							guides
							minCropBoxHeight={10}
							minCropBoxWidth={10}
							background={false}
							responsive
							checkOrientation={false}
						/>
					</Grid>
					<Grid item display="flex" justifyContent="center">
						<CustomButton
							text={t('CANCEL')}
							variant="text"
							color="primary"
							showIcon={false}
							onClick={() => cancelCrop()}
							type="submit"
							fontWeight={600}
							fontSize={14}
							className="mt-20"
						/>
						<CustomButton
							text={t('DONE')}
							variant="text"
							color="primary"
							showIcon={false}
							onClick={() => handleCrop()}
							type="submit"
							fontWeight={600}
							fontSize={14}
							className="mt-20"
						/>
					</Grid>
				</Grid>
			</CommonModal>
		</>
	);
};

export default ImagePickerModal;
