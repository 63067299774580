import React from 'react';

import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DashboardCardLayout from './common/DashboardCardLayout';
import PolicyCardHeader from './common/PolicyCardHeader';
import PolicyCardCost from './common/PolicyCardCost';
import { convertAmount } from '../../../common/utils';
import theme from '../../../common/theme';

interface UserProps {
	healthDetails?: any;
}
const HealthScreen: React.FC<UserProps> = ({ healthDetails }) => {
	const { t } = useTranslation();
	return (
		<DashboardCardLayout>
			<div className="mb-30">
				<PolicyCardHeader type="health" />
			</div>
			<Grid
				container
				flexDirection="row"
				spacing={3}
				justifyContent="space-between"
			>
				<Grid item className="mb-20">
					<Typography
						fontWeight={600}
						className="pb-1"
						fontSize={16}
						color={theme.palette.primary.main}
					>
						{t('CURRENT_TOTAL_HOSPITALIZATION_COVER')}
					</Typography>
					<div className="w-280px">
						<>
							{healthDetails?.noFamilyFloater &&
								Object.keys(healthDetails?.noFamilyFloater)?.length > 0 && (
									healthDetails?.noFamilyFloater?.map((mapData: any) =>
										<PolicyCardCost
											value={
												mapData?.totalHospitalizationCoverAmount
													?
													mapData
														?.totalHospitalizationCoverAmount

													: '0'
											}
											policyCounts={
												mapData?.countHospitalizationCover
													? mapData?.countHospitalizationCover
													: '0'
											}
											type="health"
											beneficiary={mapData?.floaterType}
											policyDetails={mapData}
										/>)
								)}
						</>
						<>
							{healthDetails?.IndividualFloater &&
								Object.keys(healthDetails?.IndividualFloater)?.length > 0 && (
									healthDetails?.IndividualFloater?.map((mapData: any) =>
										<PolicyCardCost
											value={
												mapData?.totalHospitalizationCoverAmount
													?
													mapData
														?.totalHospitalizationCoverAmount

													: '0'
											}
											policyCounts={
												mapData?.countHospitalizationCover
													? mapData?.countHospitalizationCover
													: '0'
											}
											type="health"
											beneficiary={mapData?.floaterType}
											policyDetails={mapData}
										/>)

								)}
						</>
						<>
							{healthDetails?.Individual &&
								Object.keys(healthDetails?.Individual)?.length > 0 && (
									healthDetails?.Individual?.map((mapData: any) =>
										<PolicyCardCost
											value={
												mapData?.totalHospitalizationCoverAmount
													?
													mapData
														?.totalHospitalizationCoverAmount

													: '0'
											}
											policyCounts={
												mapData?.countHospitalizationCover
													? mapData?.countHospitalizationCover
													: '0'
											}
											type="health"
											beneficiary={mapData?.floaterType}
											policyDetails={mapData}
										/>)
								)}
						</>
						<>
							{healthDetails?.GroupFloater &&
								Object.keys(healthDetails?.GroupFloater)?.length > 0 && (
									healthDetails?.GroupFloater?.map((mapData: any) =>
										<PolicyCardCost
											value={
												mapData?.totalHospitalizationCoverAmount
													?
													mapData
														?.totalHospitalizationCoverAmount

													: '0'
											}
											policyCounts={
												mapData?.countHospitalizationCover
													? mapData?.countHospitalizationCover
													: '0'
											}
											type="health"
											beneficiary={mapData?.floaterType}
											policyDetails={mapData}
										/>)

								)}
						</>
						<div className="mt-16">
							<Typography fontSize={28} fontWeight={300} color="secondary">
								{healthDetails?.countHospitalizationCover}
							</Typography>
							{/* <Typography fontSize={14} color="primary">
								{t('NETWORK_HOSPITALS_IN_CHENNAI_AREA')}
							</Typography> */}
						</div>
					</div>
				</Grid>
				<Grid item className="mb-20">
					<div className="w-280px">
						{healthDetails?.criticalCareCover?.length > 0 && (
							<Typography
								fontWeight={600}
								className="pb-1"
								fontSize={16}
								color={theme.palette.primary.main}
							>
								{t('CURRENT_CRITICAL_CARE_COVER')}
							</Typography>
						)}
						{healthDetails?.criticalCareCover?.map((mapdata: any) => (
							<>
								<PolicyCardCost
									value={
										mapdata?.totalCriticalCareCoverAmount
											? mapdata?.totalCriticalCareCoverAmount
											: '0'
									}
									policyCounts={
										mapdata?.countCriticalCareCover
											? mapdata?.countCriticalCareCover
											: '0'
									}
									type="health"
									beneficiary={mapdata?.CriticalCareCoverRelationType}
									policyDetails={mapdata}
								/>
								<div className="mt-16">
									<Typography fontSize={28} fontWeight={300} color="secondary">
										{mapdata?.countIllnessesCovered
											? mapdata?.countIllnessesCovered
											: '0'}
									</Typography>
									<Typography fontSize={14} color="primary">
										{t('ILLNESSES_COVERED')}
									</Typography>
								</div>
							</>
						))}
					</div>
				</Grid>
			</Grid>{' '}
		</DashboardCardLayout>
	);
};
export default HealthScreen;
