import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useTheme } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as AlertIcon } from '../../../common/icons/Red_Alert_icon.svg';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import './SignUp.css';

const EiaModalDetails = ({
	open,
	setOpen,

	unselectOption,
	optionSelected,
	type,
	onContinueWithEmail,
	onContinueWithMobile,
	click,
	mobile,
	email,
}: any) => {
	const theme = useTheme();

	const { t } = useTranslation();

	const handleContinueWithEmail = () => {
		if (onContinueWithEmail) {
			onContinueWithEmail();
		}
	};
	const handleContinueWithMobile = () => {
		if (onContinueWithMobile) {
			onContinueWithMobile();
		}
	};

	return (
		<CommonModal
			onClose={setOpen}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			<Grid item className="px-20 py-20">
				<Grid item className="mb-10">
					{type === 'Email' ? (
						<LabelWithIcon
							className="f-20"
							justifyContent="flex-start"
							Icon={AlertIcon}
							label={t('ATTENTION')}
							labelProps={{ fontSize: 20, color: 'error', fontWeight: 700 }}
							iconProps={{ width: 8, height: 25 }}
						/>
					) : (
						<LabelWithIcon
							className="f-20"
							justifyContent="flex-start"
							Icon={AlertIcon}
							label={t('WARNING')}
							labelProps={{ fontSize: 20, color: 'error', fontWeight: 700 }}
							iconProps={{ width: 8, height: 25 }}
						/>
					)}
				</Grid>
				<Grid item className="mb-10">
					{/* {type === 'Email' && click === 'email' ? (
						<Typography
							className="fw-700 f-20"
							color={theme.palette.error.main}
						>
							The phone number [9876543210] has already been used for 3 other
							eIA accounts.
						</Typography>
					) : (
						<Typography
							className="fw-700 f-20"
							color={theme.palette.error.main}
						>
							Outdated contact information may cause delays/rejections in
							processing claims
						</Typography>
					)} */}
					{type === 'Email' && click === 'email' && (
						<Typography
							className="fw-700 f-20"
							color={theme.palette.error.main}
						>
							{t('THE_EMAIL')} {email}{' '}
							{t('HAS_ALREADY_BEEN_USED')}
						</Typography>
					)}
					{type === 'Email' && click === 'mobile' && (
						<Typography
							className="fw-700 f-20"
							color={theme.palette.error.main}
						>
							{t('THE_PHONE_NUMBER')} {mobile}{' '}
							{t('HAS_ALREADY_BEEN_USED')}
						</Typography>
					)}
					{type === 'signupEKYC' && (
						<Typography
							className="fw-700 f-20"
							color={theme.palette.error.main}
						>
							{t('OUTDATED_CONTACT_INFORMATION')}
						</Typography>
					)}
				</Grid>
				<Grid item className="mb-10">
					{/* {type === 'Email' ? (
						<Typography
							className="f-16 fw-600"
							color={theme.palette.common.black}
						>
							Use a new mobile number or use an email for sign up
						</Typography>
					) : (
						<Typography
							className="f-16 fw-600"
							color={theme.palette.common.black}
						>
							It is strongly recommended that all the policies in the portfolio
							should have the latest contact information
						</Typography>
					)} */}
					{type === 'Email' && click === 'email' && (
						<Typography
							className="f-16 fw-600"
							color={theme.palette.common.black}
						>
							{t(
								'USE_A_NEW_EMAIL_OR_USE_INDIAN_MOBILE'
							)}
						</Typography>
					)}
					{type === 'Email' && click === 'mobile' && (
						<Typography
							className="f-16 fw-600"
							color={theme.palette.common.black}
						>
							{t('USE_A_NEW_MOBILE_NUMBER_OR_USE_EMAIL')}
						</Typography>
					)}
					{type !== 'Email' && (
						<Grid item className="mb-10">
							<Typography
								className="f-16 fw-600"
								color={theme.palette.common.black}
							>
								{t(
									'STRONGLY_RECOMMENDED_THAT_ALL_POLICIES'
								)}
							</Typography>
						</Grid>
					)}
					{type === 'signupEKYC' && (
						<Typography className="f-16" color={theme.palette.common.black}>
							{t('WISH_TO_ASSIGN_DIFFERENT_CONTACT')}{' '}
							<span className="text_manage">{t('"MANAGE"')}</span>{' '}
							{t('OPTION_IN_THE_MENU_ON_APP')}
						</Typography>
					)}
				</Grid>

				{type === 'Email' && click === 'email' && (
					<>
						<Grid item textAlign="right" className="mt-30 ml-50">
							<CustomButton
								text={t('CONTINUE_WITH_SIGN_UP_WITH_OTHER_EMAIL')}
								variant="text"
								className="fw-600 f-16 continue_button"
								textAlign="right"
								onClick={() => handleContinueWithEmail()}
							/>
						</Grid>
						<Grid item className="mt-30 ml-50">
							<CustomButton
								text={t('USE_MOBILE_NUMBER_FOR_SIGN_UP')}
								variant="text"
								color="primary"
								className="fw-600 f-16"
								textAlign="right"
								onClick={() => handleContinueWithMobile()}
							/>
						</Grid>
					</>
				)}
				{type === 'Email' && click === 'mobile' && (
					<>
						<Grid item className="mt-30 ml-50">
							<CustomButton
								text={t('CONTINUE_SIGN_UP_WITH_A_DIFFERENT_MOBILE_NUMBER')}
								variant="text"
								className="fw-600 f-16 continue_button"
								textAlign="right"
								onClick={() => handleContinueWithMobile()}
							/>
						</Grid>
						<Grid item container justifyContent="flex-end" className="mt-20">
							<CustomButton
								text={t('USE_EMAIL_FOR_SIGN_UP')}
								variant="text"
								color="primary"
								className="fw-600 f-16"
								onClick={() => handleContinueWithEmail()}
							/>
						</Grid>
					</>
				)}
				{type !== 'Email' && (
					<>
						<Grid item className="mt-30 ml-50">
							<CustomButton
								text={t('CONTINUE_WITH_UNSELECTING_THE_OPTION')}
								variant="text"
								className="fw-600 f-16 continue_button"
								textAlign="end"
								onClick={unselectOption}
							/>
						</Grid>
						<Grid item container justifyContent="flex-end" className="mt-20">
							<CustomButton
								text={t('KEEP_THE_OPTION_SELECTED')}
								variant="text"
								color="primary"
								className="fw-600 f-16"
								onClick={optionSelected}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</CommonModal>
	);
};

export default EiaModalDetails;
