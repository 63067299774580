import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './generator.scss';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { store } from './common/store';
import theme from './common/theme';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from '@firebase/analytics';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const Firebase = () => {
	const firebaseConfig = {
		apiKey: 'AIzaSyBn-0uBl-yLlSXclH6jLXUDg9qnrpECL4I',
		authDomain: 'bima-central-6676b.firebaseapp.com',
		projectId: 'bima-central-6676b',
		storageBucket: 'bima-central-6676b.appspot.com',
		messagingSenderId: '28323443535',
		appId: '1:28323443535:web:9a13e885d6421d62e30b3a',
		measurementId: 'G-84FXNZ82R5',
	};

	const app = initializeApp(firebaseConfig);
	const analytics = getAnalytics(app);

	
}
root.render(
	<>
		{/* <React.StrictMode> */}
		<Suspense>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<App />
				</Provider>
			</ThemeProvider>
		</Suspense>
		{/* </React.StrictMode> */}
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
