import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';

interface AssetFooterProps {
	assertFooterDetails?: any;
}
const AssetFooter: React.FC<AssetFooterProps> = ({ assertFooterDetails }) => {
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid item xs={12} className="py-15">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('TYPE_OF_INTERMEDIARY')}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{assertFooterDetails?.typeOfintermediary?.length === 0
						? '-'
						: assertFooterDetails?.typeOfintermediary}
				</Typography>
			</Grid>
			<Grid item xs={12} className="py-15">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('NAME_OF_INTERMEDIARY')}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{assertFooterDetails?.nameOfIntermediary?.length === 0
						? '-'
						: assertFooterDetails?.nameOfIntermediary}
				</Typography>
			</Grid>{' '}
			<Grid item xs={12} className="py-15">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('LICENSE_NUMBER_ISSUED_BY_IRDAI')}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{assertFooterDetails?.licenseIssuedByIRDAI?.length === 0
						? '-'
						: assertFooterDetails?.licenseIssuedByIRDAI}
				</Typography>
			</Grid>{' '}
			<Grid item xs={12} className="py-15">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('CONTACT_NUMBER')}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{assertFooterDetails?.contactNumber?.length === 0
						? '-'
						: assertFooterDetails?.contactNumber}
				</Typography>
			</Grid>
		</Grid>
	);
};
export default AssetFooter;
