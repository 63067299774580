import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProfilePic from '../../../common/icons/profile/profile-icon.svg';
import ProfileCam from '../../../common/icons/profile/profile-camera.svg';
import othersicon from '../../../common/icons/profile/others.png';
import './profile.css';
import Spinner from '../../../common/ui/Spinner';
import female1 from '../../../common/icons/profile/female_icon.svg';
import { appendBase64PrefixForImage } from '../../../common/utils';

interface ProfileUploaderProps {
	handleUpload: any;
	profile: any;
	uploadImageBase64: any;
	isImageLoading: any;
	selectedSeqId: any;
	relationshipType: any;
	imageBase64: any;
}
const ProfileUploader: React.FC<ProfileUploaderProps> = ({
	handleUpload,
	profile,
	uploadImageBase64,
	isImageLoading,
	selectedSeqId,
	relationshipType,
	imageBase64,
}) => {
	let profileImage =
		uploadImageBase64 ||
		(profile.imageBase64
			? `data:image/png;base64,${profile.imageBase64}`
			: (['Sister', 'Wife', 'Daughter', 'Mother'].includes(relationshipType) ? female1 : ProfilePic));

	const [isImageBase64Url, setIsImageBase64Url] = useState<boolean | null>(
		null
	);

	useEffect(() => {
		const images = async function isBase64UrlImage(base64String: string) {
			let image = new Image();
			image.src = base64String;

			return await new Promise<boolean>((resolve) => {
				image.onload = function () {
					if (image.height === 0 || image.width === 0) {
						resolve(false);
						return;
					}
					resolve(true);
				};
				image.onerror = () => {
					resolve(false);
				};
			});
		};

		images(profileImage)
			.then((result) => {
				setIsImageBase64Url(result);
			})
			.catch((error) => {
				console.error('Error checking base64 image:', error);
				setIsImageBase64Url(null);
			});
	}, [profileImage]);

	// console.log(relationshipType, 'ponni');

	return (
		<div onClick={handleUpload} onKeyDown={() => { }} role="button" tabIndex={0}>
			{isImageLoading && selectedSeqId === profile.seqId ? (
				<Grid item>
					<Spinner />
				</Grid>
			) : (

				<Grid item>
					{isImageBase64Url === false ? (
						<>
							<img
								alt="img"
								key={selectedSeqId}
								src={ProfilePic}
								width="52px"
								height="52px"
								className="profile-pic"
							/>
							<img src={ProfileCam} alt="icon" className="profile-cam-personal" /></>

					) : (

						<>
							<img
								alt="img"
								key={selectedSeqId}
								src={profileImage}
								width="52px"
								height="52px"
								className="profile-pic"
							/>
							<img src={ProfileCam} alt="icon" className="profile-cam-personal" />
						</>
					)}
				</Grid>
			)}

			{/* {isImageLoading && selectedSeqId === profile.seqId ? (
				<Grid item>
					<Spinner />
				</Grid>
			) : (
				<Grid item>
					{relationshipType === 'Sister' ||
						relationshipType === 'Wife' ||
						relationshipType === 'Daughter' ? (
						<>
							<img
								alt="img"
								key={selectedSeqId}
								src={profileImage ? profileImage : female1}
								width="52px"
								height="52px"
								className="profile-pic"
							/>
							<img
								src={ProfileCam}
								className="profile-cam-personal"
								alt="icon"
							/>
						</>
					) : relationshipType === 'Other' || relationshipType === 'In laws' ? (
						<>
							<img
								alt="img"
								key={selectedSeqId}
								src={profileImage ? profileImage : othersicon}
								width="52px"
								height="52px"
								className="profile-pic"
							/>
							<img
								src={ProfileCam}
								alt="icon"
								className="profile-cam-personal"
							/>
						</>
					) : isImageBase64Url === false ? (
						<>
							<img
								alt="img"
								key={selectedSeqId}
								src={ProfilePic}
								width="52px"
								height="52px"
								className="profile-pic"
							/>
							<img
								src={ProfileCam}
								alt="icon"
								className="profile-cam-personal"
							/>
						</>
					) : (
						<>
							<img
								alt="img"
								key={selectedSeqId}
								src={profileImage}
								width="52px"
								height="52px"
								className="profile-pic"
							/>
							<img
								src={ProfileCam}
								alt="icon"
								className="profile-cam-personal"
							/>
						</>
					)}
				</Grid>
			)} */}
		</div>
	);
};

export default ProfileUploader;
