import { Box, Grid, Typography, useTheme } from "@mui/material";
import SearchInsurer from "./SearchInsurer";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../common/ui/CustomButton";
import Separator from "../../../common/ui/Separator";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../common/constants/Routes";
import PolicyFromIntegratedInsurer from "./PolicyFromIntegratedInsurer";
import { useState } from "react";
import FindPolicyOTPScreen from "./FindPolicyOTPScreen";
import { useDispatch } from "react-redux";
import { addInsurer } from "./addPolicy.slice";
import PolicyFromNonIntegratedInsurer from "./PolicyFromNonIntegratedInsurer";

const StepTwoScreen = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [policyIntegrated, setPolicyIntegrated] = useState(false);
    const [OTPVerified, setOTPVerified] = useState(false);
    const [policyNonIntegrated, setPolicyNonIntegrated] = useState(false);


    const PolicyIntegrated = (open: any) => {
        if (open?.PolicyIntegrated) {
            dispatch(addInsurer(open?.insurer))
        }
        setOTPVerified(false);
        setPolicyIntegrated(open?.PolicyIntegrated);

    }
    const CheckOTPverifited = (open: any) => {

        setPolicyIntegrated(false);
        setOTPVerified(open);


    }

    const PolicyNonIntegrated = (open: any) => {
        setPolicyIntegrated(false);
        setOTPVerified(false);
        setPolicyNonIntegrated(open)
    }

    return (<>
        <Grid container justifyContent="center">
            <Box width={414} >
                <Grid className="m-50" width={300}>

                    <Grid item className="mb-15">
                        <Grid container justifyContent="space-between" className="mb-20">
                            <Typography
                                className="f-16 fw-600"
                                color={theme.palette.primary.main}
                            >
                                {t('STEP')} {2 + ": "} {t('FIND_POLICIES')}
                            </Typography>


                            <Link to={APP_ROUTES.ADD_POLICY_SUBMITTION_PREVIEW} >
                                <Typography
                                    className="f-16 fw-400"
                                    color={theme.palette.common.black}
                                >
                                    {t('CANCEL')}
                                </Typography>

                            </Link>
                        </Grid>

                        <Grid container justifyContent="center">
                            <Separator color={theme.palette.grey[400]} borderWidth={1} />
                        </Grid>

                        {!policyIntegrated && !OTPVerified && !policyNonIntegrated && (<>

                            <SearchInsurer PolicyIntegrated={PolicyIntegrated} /></>)}


                        {policyIntegrated && <PolicyFromIntegratedInsurer PolicyIntegrated={PolicyIntegrated} CheckOTPverifited={CheckOTPverifited} />}

                        {OTPVerified && <FindPolicyOTPScreen PolicyNonIntegrated={PolicyNonIntegrated} />}

                        {policyNonIntegrated && <PolicyFromNonIntegratedInsurer />}

                    </Grid>



                </Grid>
            </Box>
        </Grid>








    </>)
}
export default StepTwoScreen;