import { axiosInstance } from '../../../common/axiosInstance';
import { MobileNumberRoutes } from '../../../common/routes';

const axios = axiosInstance;

const fetchMobileNumbers = (data: any) =>
	axios.request({ ...MobileNumberRoutes.listAllMobileNumber, data });

const fetchPoliciesForMobileNumber = (data: any) =>
	axios.request({ ...MobileNumberRoutes.listAllPolicies, data });

const updateMobileNumbers = (data: any) =>
	axios.request({ ...MobileNumberRoutes.updateMobileNumbers, data });

const generateOtpForMobile = (data: any) =>
	axios.request({ ...MobileNumberRoutes.generateOtpForMobile, data });

const verifyOtpForMobile = (data: any) =>
	axios.request({ ...MobileNumberRoutes.verifyOtpForMobile, data });

export {
	fetchMobileNumbers,
	fetchPoliciesForMobileNumber,
	updateMobileNumbers,
	generateOtpForMobile,
	verifyOtpForMobile,
};
