import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';

interface Surrender {
	title: string;
	notification: string;
	createdDate: string;
}


interface SurrenderState {
	isLoading: LoadingState;
	surrenderValue: any
	errors: any;
}

const initialState: SurrenderState = {
	isLoading: 'idle',
	surrenderValue: {},
	errors: undefined,
};

const SurrenderSlice = createSlice({
	name: 'Surrender',
	initialState,
	reducers: {
		fetchSurrender: (state) => {
			state.isLoading = 'loading';
			state.surrenderValue = undefined;
			state.errors = undefined;
		},
		reportLoadSurrender: (state, { payload }) => {
			state.isLoading = 'done';
			state.surrenderValue = payload;
			state.errors = undefined;
		},
		reportLoadErrorSurrender: (state, { payload }) => {
			state.isLoading = 'error';
			state.surrenderValue = undefined;
			state.errors = payload;
		},

		fetchInitiateSurrender: (state) => {
			state.isLoading = 'loading';
			state.surrenderValue = undefined;
			state.errors = undefined;
		},
		initiateSurrenderSuccess: (state, { payload }) => {
			state.isLoading = 'done';
			state.surrenderValue = payload;
			state.errors = undefined;
		},
		initiateSurrenderError: (state, { payload }) => {
			state.isLoading = 'error';
			state.surrenderValue = undefined;
			state.errors = payload;
		},
	},
});

export const selectIsSurrenderLoading = (state: RootState) =>
	state.Surrender.isLoading === 'loading';

export const selectLoadedSurrenders = (state: RootState) =>
	state.Surrender.surrenderValue;

export const selectLoadedIntiatedSurrenders = (state: RootState) =>
	state.Surrender.surrenderValue;

export const {
	fetchSurrender,
	reportLoadSurrender,
	reportLoadErrorSurrender,
	fetchInitiateSurrender,
	initiateSurrenderSuccess,
	initiateSurrenderError
} = SurrenderSlice.actions;


export default SurrenderSlice.reducer;
