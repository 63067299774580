import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import CustomButton from '../../../../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../../../../common/constants/Routes';

interface PayorProps {
	lifeDetails?: any;
}
const Payor: React.FC<PayorProps> = ({ lifeDetails }) => {
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid item xs={11} className="mt-5">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
					className="py-5 mt-5"
				>
					{t('PAYOR')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={18}
					className="mb-10"
				>
					{lifeDetails?.payorName}
				</Typography>
			</Grid>
			<Grid item xs={11}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('RELATION_TO_LIFE_ASSURED')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={18}
					className="mb-10"
				>
					{lifeDetails && lifeDetails?.relationToLifeAssured?.length === 0
						? '-'
						: lifeDetails?.relationToLifeAssured}
				</Typography>
			</Grid>
			<Grid item xs={11} container>
				<Grid item xs={12}>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('BANK')} [eNACH]
					</Typography>
				</Grid>
				<Grid
					item
					container
					xs={12}
					display="flex"
					justifyContent="space-between"
					className="py-5"
				>
					<Grid item xs={6}>
						{lifeDetails && lifeDetails?.bankName ? (
							<Typography
								color={theme.palette.primary.main}
								fontSize={18}
								fontWeight={600}
								className="mb-5"
							>
								{lifeDetails?.bankName}
							</Typography>
						) : (
							'-'
						)}

						{lifeDetails && lifeDetails?.accountHolderName ? (
							<Typography color={theme.palette.common.black} fontSize={16}>
								{lifeDetails?.accountHolderName}
							</Typography>
						) : null}
						{lifeDetails && lifeDetails?.bankAcNo ? (
							<Typography color={theme.palette.common.black} fontSize={16}>
								{lifeDetails?.bankAcNo}
							</Typography>
						) : null}
						{lifeDetails && lifeDetails?.bankName ? (
							<Typography color={theme.palette.common.black} fontSize={16}>
								{lifeDetails?.bankName}
							</Typography>
						) : null}
						{lifeDetails && lifeDetails?.bankBranch ? (
							<Typography color={theme.palette.common.black} fontSize={16}>
								{lifeDetails?.bankBranch}
							</Typography>
						) : null}
						{lifeDetails && lifeDetails?.bankIfscCode ? (
							<Typography color={theme.palette.common.black} fontSize={16}>
								{lifeDetails?.bankIfscCode}
							</Typography>
						) : null}
						{process.env.REACT_APP_MODE !== 'PROD' && (
							<Link to={APP_ROUTES.BANK}>
								<CustomButton
									text={t('CHANGE_BANK')}
									variant="text"
									color="primary"
									showIcon={false}
									fontSize={12}
								/>
							</Link>
						)}
					</Grid>
					<Grid item container xs={6}>
						<Grid item xs={12} display="flex" justifyContent="flex-end">
							{lifeDetails?.bankLogo && (
								<img
									src={lifeDetails?.bankLogo}
									width={73}
									height={77}
									alt="bank"
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Payor;
