/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { store } from './store';
import cryptoEncryptionDecryption, { generateUUID } from './crypto';
import { getAccessToken } from '../features/Auth/auth.service';
import { setAlertInfo } from '../features/App/Common/Common.slice';
import axios from 'axios';

export const HandleAxiosRequest = async (config: any) => {
	try {
		const currentTimeStamp = dayjs(new Date()).valueOf();
		let access_token: any = sessionStorage.getItem('access_token');
		const token_expires_at: number | string | null =
			sessionStorage.getItem('token_expires_at');
		if (!access_token || currentTimeStamp >= Number(token_expires_at)) {
			const { data } = await getAccessToken();
			sessionStorage.setItem('access_token', data.access_token);
			sessionStorage.setItem(
				'token_expires_at',
				String(data.access_token * data.expires_in + currentTimeStamp)
			);
			access_token = data.access_token;
		}
		const timestamp = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS');
		config.data = {
			...config.data,
			timestamp,
			txnId: generateUUID(),
			Source: {
				appType: 'REACT',
				osVersion: '11',
				deviceId: '',
				deviceName: 'DESKTOP',
				deviceOS: 'WINDOWS',
				appVersion: '2.0',
				clientIp: '0.0.0.0',
				origin: 'RI',
			},
		};
		if (process.env.REACT_APP_MODE !== 'PROD') {
			console.log(`Payload---> ${config?.url} ---->`, config.data);
		}
		const sessionId: any = sessionStorage.getItem('sessionId');
		const sessionIdDecrypted = sessionId
			? cryptoEncryptionDecryption.Decrypt(sessionId)
			: '';
		if (sessionIdDecrypted && sessionIdDecrypted !== 'null') {
			config.headers['session-id'] = sessionIdDecrypted;
		} else {
			config.headers['session-id'] = '';
		}

		config.headers['Content-Type'] = 'text/plain';
		// config.headers['User-Agent'] = "BIMA-CENTRAL"; Safari/ Firefox browser not supporting user agent
		config.headers.Authorization = `Bearer ${access_token}`;
		config.data = cryptoEncryptionDecryption.Encrypt(config.data);
		const i18nextLng = localStorage.getItem('i18nextLng');

		if (i18nextLng) {
			const languageCode = i18nextLng.split('-')[0];

			config.headers['lang-id'] = languageCode;
		} else {
			config.headers['lang-id'] = 'en';
		}

		return config;
	} catch (error) {
		if (process.env.REACT_APP_MODE !== 'PROD') {
			console.log(error);
		}
	}
};
export const HandleAxiosResponse = async (response: any) => {
	try {
		const requestedData: any = JSON.parse(
			cryptoEncryptionDecryption.Decrypt(response.config.data)
		);
		response.data = JSON.parse(
			cryptoEncryptionDecryption.Decrypt(response.data)
		);
		if (process.env.REACT_APP_MODE !== 'PROD') {
			console.log(
				`Decrypted ---> ${response.config.url} ----> `,
				response.data
			);
		}
		if (
			requestedData?.txnId !== response?.data?.txnId ||
			!response?.data?.txnId
		) {
			// eslint-disable-next-line no-alert
			alert('Unauthorized access. Please contact administrator.');
			sessionStorage.clear();
			window.location.replace('/');
		} else {
			return response;
		}
	} catch (error) {
		if (process.env.REACT_APP_MODE !== 'PROD') {
			console.log(error);
		}
	}
};
export const HandleAxiosResponseWithError = async (error: any) => {
	try {
		if (!error?.response || !error?.response?.data) {
			const alertInfo = {
				open: true,
				type: 'ERROR',
				description: 'Failed to connect with network,  Please try Again later.',
			};
			store.dispatch(setAlertInfo(alertInfo as any));
		} else {
			error.response.data = JSON.parse(
				cryptoEncryptionDecryption.Decrypt(error?.response?.data)
			);
			if (process.env.REACT_APP_MODE !== 'PROD') {
				console.log(
					`Decrypt Data (Error)---> ${error?.response?.config?.url} ---->`,
					error?.response?.data
				);
			}
			const alertInfo = {
				open: true,
				type: 'ERROR',
				description: error?.response?.data?.errorDescription
					? error?.response?.data?.errorDescription
					: 'We are facing some technical issue. Please try again later.',
				errorCode: error?.response?.data?.errorCode,
			};
			store.dispatch(setAlertInfo(alertInfo as any));
		}

		return Promise.reject(error);
	} catch (e) {
		if (error?.response?.status === 401) {
			const originalRequest = error.config;
			originalRequest._retry = true;
			const currentTimeStamp = dayjs(new Date()).valueOf();
			const { data } = await getAccessToken();
			sessionStorage.setItem('access_token', data.access_token);
			sessionStorage.setItem(
				'token_expires_at',
				String(data.access_token * data.expires_in + currentTimeStamp)
			);
			// location.reload();
			originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
			const response = await axios.request(originalRequest);
			HandleAxiosResponse(response);
			return response;
		} else {
			// if (process.env.REACT_APP_MODE !== 'PROD') {
			// 	console.log(e);
			// } else {
			const alertInfo = {
				open: true,
				type: 'ERROR',
				description: 'We are facing some technical issue. Please try again later.',
				// errorCode: 500,
			};
			store.dispatch(setAlertInfo(alertInfo as any));
			// }
		}

		return Promise.reject(error);
	}
};
