import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import { ReactComponent as OrangeAlert } from '../../../common/icons/ekyc_detailsone_alert_icon.svg';
import theme from '../../../common/theme';
import FormInputWithRupee from './FormInputWithRupee';
import CustomButton from '../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SvgConverter from '../../../common/SvgConverter';
import Separator from '../../../common/ui/Separator';
import BankDetails from './BankDetails';
import FinancierDetails from './FInancierDetails';
import WitnessDetails from './WitnessDetails';
import { convertAmount } from '../../../common/utils';

interface UserProps {
	// icon: any;
	productLogo?: any;
	productName?: any;
	productNum?: any;
	lenderType?: any;
	lender?: any;
	control?: any;
	errors?: any;
	setValue?: any;
	PartialWithdrawal?: any;
	bankDetailsData?: any;
	witnessDetails?: any;
	header?: any;
	icon?: any;
	financierDetails?: any;
	bankDetails?: any;
	inputValue?: any;
	maturityValue?: any;
	payerName?: any;
	currentFundValue?: any;
	eligibleType?: any;
	eligibleAmount?: any;
	head: any;
}
const LenderDetailsCard = ({
	productLogo,
	productName,
	productNum,
	lenderType,
	lender,
	control,
	errors,
	setValue,
	PartialWithdrawal,
	bankDetailsData,
	header,
	icon,
	witnessDetails,
	financierDetails,
	bankDetails,
	inputValue,
	maturityValue,
	payerName,
	currentFundValue,
	eligibleType,
	eligibleAmount,
	head,
}: UserProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const handleFinancierClick = (data?: any) => {
		navigate(APP_ROUTES.Select_Financier, {
			state: { widgetNo: productNum, lender, data },
		});
	};
	const matchingLender = financierDetails?.lenderDetails?.lenderCode;
	const repayment = financierDetails?.lenderDetails?.repaymentCode;
	return (
		<Grid
			className={
				lenderType === 'Financier'
					? 'recommended-card-financier'
					: 'recommended-card'
			}
		>
			<Grid display="flex" justifyContent="center" mt={-2.4}>
				<Grid
					className="recommended-card-num"
					mr={0.3}
					justifyContent="center"
					display="flex"
				>
					<Typography justifyContent="center" display="flex">
						{productNum}
					</Typography>
				</Grid>
				<Grid
					className="recommended-card-text"
					ml={0.2}
					justifyContent="center"
					display="flex"
					alignItems="center"
				>
					<Grid item spacing={0.5}>
						<LabelWithIcon
							svgClassName="product-rupee-icon"
							justifyContent="flex-start"
							Icon={icon}
							label={head}
							labelProps={{
								color:
									header === 'Surrender'
										? theme.palette.error.dark
										: theme.palette.primary.dark,
								fontSize: 14,
								fontWeight: 600,
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				alignItems="center"
				className={
					lenderType === 'Financier'
						? 'recommended-card-product2'
						: 'recommended-card-product'
				}
				py={2}
				pl={2}
			>
				<Grid mr={2}>
					<img src={productLogo} alt="logo" className="bank_logo" />
				</Grid>
				<Grid justifyContent="center">
					<Typography
						className="fw-600 f-14"
						color={theme.palette.primary.dark}
					>
						{productName}
					</Typography>
					<Typography className="f-14" color={theme.palette.primary.dark}>
						{t('LIFE_ASSURED')}
					</Typography>
					<Typography
						className="f-14 fw-600"
						color={theme.palette.common.black}
					>
						{payerName}
					</Typography>
				</Grid>
			</Grid>
			{lenderType !== 'Financier' && (
				<Grid item xs={12}>
					<Separator color=" #d7d7d7" />
				</Grid>
			)}
			<Grid
				container
				justifyContent={currentFundValue ? 'space-around' : 'flex-start'}
				alignItems="center"
				className={
					lenderType === 'Financier'
						? 'recommended-card-product2'
						: 'recommended-card-product'
				}
				py={1}
			>
				<Grid item ml={currentFundValue ? 0 : 2}>
					<Typography className="f-12 " color={theme.palette.primary.main}>
						{t('MATURITY_VALUE')}
					</Typography>
					<Grid item spacing={0.5}>
						<LabelWithIcon
							svgClassName="product-rupee-icon"
							justifyContent="flex-start"
							Icon={RupeeIcon}
							label={maturityValue}
							labelProps={{
								color: theme.palette.common.black,
								fontSize: 12,
							}}
						/>
					</Grid>
				</Grid>
				{currentFundValue && (
					<Grid item>
						<Typography className="f-12 " color={theme.palette.primary.main}>
							{header === 'Loan against Policy'
								? 'Current Surrender Value'
								: t('CURRENT_FUND_VALUE')}
						</Typography>
						<Grid item spacing={0.5}>
							<LabelWithIcon
								svgClassName="product-rupee-icon"
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={currentFundValue}
								labelProps={{
									color: theme.palette.common.black,
									fontSize: 12,
								}}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			{lenderType === 'Surrender' ? (
				<>
					<Grid item xs={12}>
						<Separator color=" #d7d7d7" />
					</Grid>
					<Grid
						item
						alignItems="center"
						className="recommended-card-product-financier"
						py={2}
						px={3}
						justifyContent="center"
					>
						<Grid item>
							<Typography className="f-14" color={theme.palette.error.dark}>
								{t('SURRENDER_VALUE')}
							</Typography>
							<Grid item spacing={0.5}>
								<LabelWithIcon
									svgClassName="product-rupee-icon"
									justifyContent="flex-start"
									Icon={RupeeIcon}
									label={eligibleAmount}
									labelProps={{
										color: theme.palette.error.dark,
										fontSize: 16,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</>
			) : (
				<Grid
					item
					alignItems="center"
					className={
						lenderType === 'Partial Withdrawal'
							? 'recommended-card-product2'
							: 'recommended-card-product-financier'
					}
					py={2}
					px={3}
					justifyContent="center"
				>
					<Grid item>
						<Typography className="f-14 " color={theme.palette.common.black}>
							{lenderType === 'Financier'
								? t('INITIAL_A_LOAN_OF')
								: t('INITIAL_PARTIAL_WITHDRAWAL_OF')}{' '}
						</Typography>
						<Grid my={1} pr={2}>
							<FormInputWithRupee
								inputValue={inputValue}
								name={productNum}
								control={control}
								error={errors}
								setValue={setValue}
								type="Lender"
								eligibleAmount={eligibleAmount}
								eligibleType={eligibleType}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}

			<Grid item xs={12}>
				<Separator color=" #d7d7d7" />
			</Grid>
			<Grid
				item
				alignItems="center"
				py={1}
				px={2}
				className="change-financier-grid"
			>
				{PartialWithdrawal ? (
					<>
						{' '}
						{PartialWithdrawal === 'true' && bankDetailsData ? (
							<Grid item xs={12} container>
								<Grid
									item
									xs={12}
									display="flex"
									justifyContent="space-between"
									mt={1}
								>
									<Typography
										className="f-14"
										color={theme.palette.common.black}
									>
										{t('BANK_DETAILS')}
									</Typography>
									<Link
										to={APP_ROUTES.SELECTBANKDETAILS}
										state={{ widgetNo: productNum }}
									>
										<CustomButton
											text={t('CHANGE_BANK')}
											variant="text"
											color="primary"
											showIcon={false}
											fontSize={14}
										/>
									</Link>
								</Grid>
								<Grid item xs={12}>
									<Separator color=" #d7d7d7" />
								</Grid>
								<Grid item xs={12}>
									<BankDetails
										verifyBank={bankDetailsData?.bankDetails?.bankVerified}
										bankName={bankDetailsData?.bankDetails?.bankName}
										branch={bankDetailsData?.bankDetails?.bankBranch}
										ifscCode={bankDetailsData?.bankDetails?.bankIfscCode}
										accountNum={bankDetailsData?.bankDetails?.bankAcNo}
										bankLogo={bankDetailsData?.bankDetails?.bankLogo}
										accountHolder={
											bankDetailsData?.bankDetails?.accountHolderName
										}
									/>
								</Grid>
							</Grid>
						) : (
							<Grid
								item
								xs={12}
								container
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								py={1}
							>
								<SvgConverter Icon={OrangeAlert} />
								<Link
									to={APP_ROUTES.SELECTBANKDETAILS}
									state={{ widgetNo: productNum }}
								>
									<CustomButton
										text={t('SELECT_A_BANK')}
										variant="text"
										color="primary"
										showIcon
										fontSize={16}
										fontWeight={600}
									/>
								</Link>
							</Grid>
						)}
					</>
				) : (
					<>
						{!financierDetails && !bankDetails && !witnessDetails ? (
							<Grid
								item
								xs={12}
								container
								display="flex"
								justifyContent="space-between"
								mt={1}
							>
								<Grid item xs={5.5} container>
									<Grid item xs={2.9} mt={0.8}>
										<SvgConverter Icon={OrangeAlert} />
									</Grid>
									<Grid item xs={9}>
										<Typography fontSize={14}>
											{t('LOAN_SETUP_PROCESS')},
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={6.5}>
									<Typography fontSize={14}>
										1.{t('SELECT_FINANCIER')}
									</Typography>
									<Typography fontSize={14}>2. {t('SELECT_BANK')}</Typography>
									<Typography fontSize={14}>
										3. {t('ADD_WITNESS_DETAILS')}
									</Typography>
									<CustomButton
										text={t('SETUP_LOAN')}
										variant="text"
										color="primary"
										showIcon
										fontSize={16}
										fontWeight={600}
										onClick={() => {
											handleFinancierClick('LOANSETUP');
										}}
									/>
								</Grid>
							</Grid>
						) : (
							<Grid item xs={12} container className="change-financier-grid">
								{financierDetails && (
									<>
										<Grid
											item
											xs={12}
											display="flex"
											justifyContent="space-between"
											mt={1}
										>
											<Typography
												className="f-14"
												color={theme.palette.common.black}
											>
												{t('FINANCIER')}
											</Typography>
											<CustomButton
												text={t('CHANGE_FINANCIER')}
												variant="text"
												color="primary"
												showIcon={false}
												onClick={() => {
													handleFinancierClick();
												}}
												fontSize={14}
											/>
										</Grid>
										<Grid item xs={12}>
											<Separator color=" #d7d7d7" />
										</Grid>

										<FinancierDetails
											productLogo={matchingLender?.lenderLogo}
											financierName={matchingLender?.lenderName}
											loanAmount={convertAmount(matchingLender?.loanAmount)}
											interest={matchingLender?.interestPercentage}
											charges={convertAmount(matchingLender?.charges)}
											repayment={repayment?.repaymentOptionDescription}
										/>
									</>
								)}
								{bankDetails && (
									<Grid item xs={12} container className="mt-30">
										<Grid
											item
											xs={12}
											display="flex"
											justifyContent="space-between"
											className="mt-10"
										>
											<Typography
												className="f-14"
												color={theme.palette.common.black}
											>
												{t('BANK_DETAILS')}
											</Typography>
											<Link
												to={APP_ROUTES.SELECTBANKDETAILS}
												state={{ widgetNo: productNum }}
											>
												<CustomButton
													text={t('CHANGE_BANK')}
													variant="text"
													color="primary"
													showIcon={false}
													fontSize={14}
												/>
											</Link>
										</Grid>
										<Grid item xs={12}>
											<Separator color=" #d7d7d7" />
										</Grid>

										<BankDetails
											verifyBank={bankDetails?.bankDetails?.bankVerified}
											bankName={bankDetails?.bankDetails?.bankName}
											branch={bankDetails?.bankDetails?.bankBranch}
											ifscCode={bankDetails?.bankDetails?.bankIfscCode}
											accountNum={bankDetails?.bankDetails?.bankAcNo}
											bankLogo={bankDetails?.bankDetails?.bankLogo}
											accountHolder={bankDetails.bankDetails?.accountHolderName}
										/>
									</Grid>
								)}
								{witnessDetails && (
									<Grid item xs={12} container className="mt-30">
										<Grid
											item
											xs={12}
											display="flex"
											justifyContent="space-between"
											className="mt-10"
										>
											<Typography
												className="f-14"
												color={theme.palette.common.black}
											>
												{t('WITNESS_DETAILS')}
											</Typography>
											<Link
												to={APP_ROUTES.ADD_WITNESS_DETAILS}
												state={{ widgetNo: productNum }}
											>
												<CustomButton
													text={t('CHANGE_WITHNESS')}
													variant="text"
													color="primary"
													showIcon={false}
													fontSize={14}
												/>
											</Link>
										</Grid>
										<Grid item xs={12}>
											<Separator color=" #d7d7d7" />
										</Grid>
										<WitnessDetails
											witnessDOB={witnessDetails?.witness?.dob}
											witnessEmail={witnessDetails?.witness?.witnessEmailId}
											witnessFirstName={witnessDetails?.witness?.witnessFirstName}
											witnessLastName={witnessDetails?.witness?.witnessLastName}
											witnessMobile={witnessDetails?.witness?.witnessMobileNo}
											witnessPan={witnessDetails?.witness?.witnessPan}
										/>
									</Grid>
								)}
							</Grid>
						)}
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default LenderDetailsCard;
