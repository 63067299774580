import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import theme from '../../../../../common/theme';
import '../TrackTransaction.scss';
import { ReactComponent as OrangeRupee } from '../../../../../common/icons/orange_rupee.svg';
import LabelWithIcon from '../../../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../../../common/utils';
import { useTranslation } from 'react-i18next';

interface TrackTransactionLayout {
	trackTransaction?: any;
}
const TrackTransactionFooter = ({
	trackTransaction,
}: TrackTransactionLayout) => {
	const { t } = useTranslation();
	return (
		<Box className="footer-card py-15">
			<Grid container rowSpacing={2}>
				<Grid item xs={8}>
					<Typography className="f-12" color={theme.palette.common.black}>
						{t('TOTAL_CHARGES')}
					</Typography>
					{/* <Typography className="f-12" color={theme.palette.primary.main}>
						[{t('SEE_DETAILS')}]
					</Typography> */}
				</Grid>
				<Grid item xs={4}>
					<LabelWithIcon
						svgClassName="mb-2 ml-8"
						justifyContent="flex-start"
						Icon={OrangeRupee}
						label={`${convertAmount(trackTransaction?.totalCharges)} (-)`}
						labelProps={{
							color: theme.palette.grey.A700,
							fontSize: 14,
							fontWeight: 600,
						}}
					/>
				</Grid>
				<Grid item xs={8}>
					<Typography
						className="f-14 fw-600"
						color={theme.palette.common.black}
					>
						{t('TOTAL_FUNDS_AVAILED')}
					</Typography>
					<Typography
						className="f-14 fw-600"
						color={theme.palette.common.black}
					>
						{`(${Array.from(
							{
								length: trackTransaction?.Widgets?.length,
							},
							(_, i) => i + 1
						).join('+')})`}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<LabelWithIcon
						svgClassName="mb-2 ml-10"
						justifyContent="flex-start"
						Icon={OrangeRupee}
						label={convertAmount(trackTransaction?.totalFundsAvailable)}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 16,
							fontWeight: 600,
						}}
					/>
				</Grid>
				<Grid item xs={8}>
					<Typography
						className="f-14 fw-600"
						color={theme.palette.common.black}
					>
						{t('LOSS_OF_COVER')}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<LabelWithIcon
						svgClassName="mb-2 ml-10"
						justifyContent="flex-start"
						Icon={OrangeRupee}
						label={
							trackTransaction?.lossOfCover === 'Nil'
								? 'Nil'
								: convertAmount(trackTransaction?.lossOfCover)
						}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 16,
							fontWeight: 600,
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
export default TrackTransactionFooter;
