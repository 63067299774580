/* eslint-disable camelcase */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import './Policy.scss';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy, prop } from 'ramda';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Group_certified } from '../../../common/icons/policy/life-sm.svg';
import { ReactComponent as Health } from '../../../common/icons/policy/health-sm.svg';
import { ReactComponent as Assets } from '../../../common/icons/policy/assets-sm.svg';
import { CustomAccordion } from '../../../common/ui/Accordion/CustomAccordion';
import { fetchActivePolicy, selectActivePolicies } from './Policies.slice';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import Spinner from '../../../common/ui/Spinner';
import ActiveLife from './common/AccordionContent/ActiveLife';
import ActiveHealth from './common/AccordionContent/ActiveHealth';
import ActiveAssets from './common/AccordionContent/ActiveAssets';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';



interface UserProps {
	activeLoading?: any;
}
const ActiveScreen: React.FC<UserProps> = ({ activeLoading }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const activePolicies = useSelector(selectActivePolicies);


	const policy = activePolicies?.filter(
		(policy: any) => policy?.verifiedFlag !== 'D'
	);


	const policyList = useMemo(() => {
		const groupedPolicies = groupBy<any>(prop<string>('alternateCode'))(
			policy
		);
		return {
			lifeList: groupedPolicies.L || [],
			healthList: groupedPolicies.H || [],
			assetList: groupedPolicies.G || [],
		};
	}, [activePolicies]);
	useEffect(() => {
		dispatch(fetchActivePolicy());
	}, []);
	const location = useLocation();
	const AccordionState = location.state;

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Active Screen',
		});
	}, []);


	return (
		<Grid container>
			{activeLoading ? (
				<Grid display="flex" justifyContent="center">
					<Spinner />
				</Grid>
			) : (
				<Grid item xs={12}>
					<CustomAccordion
						accordionIcon={Group_certified}
						accordionLabel={t('LIFE')}
						expanded={AccordionState === 'life'}
					>
						<ActiveLife
							filteredLife={policyList?.lifeList}
							contentType="active"
						/>
					</CustomAccordion>

					<Separator color={theme.palette.grey[300]} />
					<CustomAccordion
						accordionIcon={Health}
						accordionLabel={t('HEALTH')}
						expanded={AccordionState === 'health'}
					>
						<ActiveHealth
							filteredHealth={policyList?.healthList}
							contentType="active"
						/>
					</CustomAccordion>
					<Separator color={theme.palette.grey[300]} />
					<CustomAccordion
						accordionIcon={Assets}
						accordionLabel={t('ASSETS')}
						expanded={AccordionState === 'assets'}
					>
						<ActiveAssets
							filteredAssets={policyList?.assetList}
							contentType="active"
						/>
					</CustomAccordion>
					<Separator color={theme.palette.grey[300]} />
				</Grid>
			)}
		</Grid>
	);
};

export default ActiveScreen;
