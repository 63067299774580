import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as ArrowIcon } from '../../../common/icons/LoanModule/arrow-icon.svg';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import Separator from '../../../common/ui/Separator';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { convertAmount } from '../../../common/utils';

interface LoanOptionHeaderProps {
	headerData?: any;
}

const LoanOptionHeader = ({ headerData }: LoanOptionHeaderProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<>
			<Grid container spacing={1} className="mb-10" alignItems="center">
				<Grid item>
					<Link to={APP_ROUTES.NEED_MONEY}>
						<SvgConverter
							Icon={ArrowIcon}
							width={14}
							height={14}
							className="go-back-icon"
						/>
					</Link>
				</Grid>
				<Grid item className="mt-4">
					<Typography className="f-14" color={theme.palette.primary.main}>
						{t('EDIT')}
					</Typography>
				</Grid>
			</Grid>
			<Grid item className="my-5 w-100px">
				<Separator color={theme.palette.primary.main} />
			</Grid>
			<Grid container justifyContent="space-between" className="mb-20">
				<Grid item>
					<Grid item>
						<Typography className="f-14" color={theme.palette.common.black}>
							{t('Total current fund value')}
						</Typography>
						<Typography className="f-10" color={theme.palette.grey.A700}>
							{t('as per total value of the savings policies')}
						</Typography>
					</Grid>
					<Grid item className="mt-2" xs={12}>
						<Grid container spacing={1}>
							<Grid item>
								<SvgConverter
									Icon={RupeeIcon}
									width={8}
									height={12}
									className="mt-8"
								/>
							</Grid>
							<Grid item>
								<Typography className="f-18" color={theme.palette.common.black}>
									{convertAmount(headerData?.totalCurrentFundValue)}
								</Typography>
								{}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid item>
						<Typography className="f-14" color={theme.palette.common.black}>
							{t('Total funds available without surrender ')}
						</Typography>
						<Typography className="f-10" color={theme.palette.grey.A700}>
							{' '}
							{t('as per partial withdrawal & loan eligibility criteria')}
						</Typography>
					</Grid>
					<Grid item className="mt-2" xs={12}>
						<Grid container spacing={1}>
							<Grid item>
								<SvgConverter
									Icon={RupeeIcon}
									width={8}
									height={12}
									className="mt-8"
								/>
							</Grid>
							<Grid item>
								<Typography className="f-18" color={theme.palette.common.black}>
									{convertAmount(
										headerData?.totalFundsAvailableWithoutSurrender
									)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item className="my-5">
				<Typography className="f-16" color={theme.palette.primary.main}>
					{t('My Options')}
				</Typography>
			</Grid>
			<Grid item className="my-5 w-100px">
				<Separator color={theme.palette.primary.main} />
			</Grid>
		</>
	);
};

export default LoanOptionHeader;
