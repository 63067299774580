import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
	fetchLoginRequest,
	fetchOtpRequest,
	fetchOtpVerify,
	initiateEkycError,
	initiateEkycRequest as initiateEkycRequestAction,
	initiateEkycSuccess,
	reportLoginRequestError,
	reportLoginRequestSuccess,
	reportOtpLoginError,
	reportOtpLoginSuccess,
	reportOtpVerifyError,
	reportOtpVerifySuccess,
	saveEkycError,
	saveEkycSuccess,
	selectLoginRequestInfo,
	setMpinError,
	setMpinRequest,
	setMpinSuccess,
	setUser,
	signUpError,
	signUpRequest,
	signUpSuccess,
	verifyMpinError,
	verifyMpinRequest,
	verifyMpinSuccess,
	saveEkycRequest as saveEkycRequestAction,
	saveARRequest as saveARRequestAction,
	saveARSuccess,
	saveARError,
	copyEIADataRequest,
	copyEIADataSuccess,
	copyEIADataError,
	sendCommunicationRequest,
	sendCommunicationSuccess,
	sendCommunicationError,
	selectIsUserLoggedIn,
	logoutSuccess,
	logoutError,
	logoutRequest,
	savePortingDetailsRequest,
	savePortingDetailsSuccess,
	savePortingDetailsError,
} from './auth.slice';
import {
	copyEIADataService,
	fetchOtpVerifyRequest,
	fetchResendOtpRequest,
	fetchSavePortingDetails,
	fetchSetMpinRequest,
	fetchSignUpRequest,
	fetchVerifyMpinRequest,
	initiateEkycRequest,
	logoutUser,
	saveARRequest,
	saveEkycRequest,
	sendCommunicationService,
	submitLoginRequestInfo,
} from './auth.service';
// import { AuthScreens } from '../../../common/routes/auth/auth.screens';
import { generatePermanentAddressFromEkyc } from '../../common/utils';
import { EKYC_PROCESS_FLOW_TYPE, SIGNUP_EIA_STATUS } from '../../common/Enums';
import { AUTH_ROUTES } from '../../common/constants/Routes';
import { setAlertInfo } from '../App/Common/Common.slice';
import { handleFetchUserProfileInformation } from '../App/Profile/Profile.saga';
import cryptoEncryptionDecryption from '../../common/crypto';
import {
	fetchUserFamilyListAction,
	fetchUserProfile,
} from '../App/Profile/Profile.slice';
import Analytics from '../../Layout/components/analytics';
import { logEvent } from '@firebase/analytics';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { env } from 'process';

const dest = localStorage.getItem('language');

// console.log('ponni', process.env.REACT_APP_MODE);

// if (process.env.REACT_APP_MODE === 'PROD') {
// 	// console.log('encrypted-->', encrypted);
// }

function* handleLoginRequest({
	payload,
}: ReturnType<typeof fetchLoginRequest>) {
	const { navigate, ...requestPayload }: any = payload;
	try {
		const { data }: any = yield* call(submitLoginRequestInfo, requestPayload);
		// sessionStorage.setItem(
		//   LOCAL_VARIABLES.SESSION_ID,
		//   data?.Customer?.sessionId
		// );
		yield* put(reportLoginRequestSuccess(data));
		if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_INPROGRESS) {
			const screenflag="LOGIN"
			navigate(AUTH_ROUTES.SIGNUP_VERIFICATION_STATUS, {
				state: {
					responseData: data,
					screenflag
				},
			});
		} else {
			sessionStorage.setItem(
				'sessionId',
				cryptoEncryptionDecryption.Encrypt(data?.Customer?.sessionId)
			); // Temp solution for live we have to remove it
			navigate(AUTH_ROUTES.LOGIN_OTP_VERIFY, { state: { requestPayload } });
		}
	} catch (e: any) {
		yield* put(reportLoginRequestError(e.message));
	}
}

function* handleOtpRequest({ payload }: ReturnType<typeof fetchOtpRequest>) {
	const { handleSuccess, ...requestPayload }: any = payload;
	try {
		const { data }: any = yield* call(fetchResendOtpRequest, requestPayload);
		yield* put(reportOtpLoginSuccess(data));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Otp_button_click', {
				button_name: 'Otp_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}

		if (handleSuccess) {
			handleSuccess();
		}
	} catch (e: any) {
		yield* put(reportOtpLoginError(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Otp_button_click', {
				button_name: 'Otp_button',
				Select_Languages: dest,
				Status: 'Failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* handleOtpVerify({
	payload: { handleSuccess, isLoginFlow, ...payload },
}: any) {
	const { navigation, ...requestPayload } = payload;
	try {
		const { data }: any = yield* call(fetchOtpVerifyRequest, requestPayload);
		yield* put(reportOtpVerifySuccess(data));

		if (handleSuccess) {
			handleSuccess(data);
		}
		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Otp_button_click', {
				button_name: 'Otp_button',
				Select_Languages: dest,
				Status: 'succes',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(reportOtpVerifyError(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Otp_button_click', {
				button_name: 'Otp_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* setMpin({ payload }: ReturnType<typeof fetchOtpRequest>) {
	const { navigation, ...requestPayload }: any = payload;
	try {
		const { data }: any = yield* call(fetchSetMpinRequest, requestPayload);
		const authUserInfo = yield* select(selectLoginRequestInfo);
		// yield AsyncStorage.setItem(LOCAL_VARIABLES.MPIN_FLAG, '1');
		// yield AsyncStorage.setItem('QUICK_LOGIN_TYPES', QUICK_LOGIN_TYPES.MPIN);
		// yield AsyncStorage.setItem(
		//   LOCAL_VARIABLES.AUTH_USER_INFO,
		//   JSON.stringify(authUserInfo)
		// );
		yield* put(setMpinSuccess(data));
		yield* put(setUser(authUserInfo.Customer));
		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'SetMpin_button_click', {
				button_name: 'SetMpin_button',
				Select_Languages: dest,
				Status: 'succes',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(setMpinError(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'SetMpin_button_click', {
				button_name: 'SetMpin_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* verifyMpin({ payload }: ReturnType<typeof fetchOtpRequest>) {
	try {
		const { data }: any = yield* call(fetchVerifyMpinRequest, payload);
		yield* put(verifyMpinSuccess(data));
		const authUserInfo = yield* select(selectLoginRequestInfo);
		yield* put(setUser(authUserInfo.Customer));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Mpin_button_click', {
				button_name: 'Mpin_button',
				Select_Languages: dest,
				Status: 'success',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(verifyMpinError(e.message));
		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'Mpin_button_click', {
				button_name: 'Mpin_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* signUpRequestWatcher({ payload }: any) {
	const {
		inputType,
		setClick,
		setConfirmModal,
		navigation,
		...requestPayload
	}: any = payload;
	try {
		const { data }: any = yield* call(fetchSignUpRequest, requestPayload);
		yield* put(signUpSuccess(data));
		if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_INPROGRESS) {
			const screenflag="SIGNUP"
			navigation(AUTH_ROUTES.SIGNUP_VERIFICATION_STATUS, {
				state: {
					responseData: data,
					screenflag
				},
			});
		} else if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_NA) {
			navigation(AUTH_ROUTES.EKYC_OTPSCREEN, {
				state: {
					mobileNo: requestPayload?.Customer?.mobileNo,
					email: requestPayload?.Customer?.email,
					kycId: data?.id,
					signupData: requestPayload,
					inputType,
				},
			});
		} else if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_CAMS) {
			navigation(AUTH_ROUTES.EKYC_IN_CAMS_OTPSCREEN, {
				state: {
					mobileNo: data?.Customer?.mobileNo,
					email: data?.Customer?.email,
					signupData: requestPayload,
					inputType,
				},
			});
			sessionStorage.setItem(
				'sessionId',
				cryptoEncryptionDecryption.Encrypt(data?.userSessionId)
			);
		} else if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_OTHERS) {
			navigation(AUTH_ROUTES.SIGNUP_CASE_TWO, {
				state: {
					mobileNo: requestPayload?.Customer?.mobileNo,
					email: requestPayload?.Customer?.email,
					signupData: requestPayload,
					othersData: data,
					inputType,
				},
			});
		} else if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_MOBILE_EXCEED) {
			navigation(AUTH_ROUTES.SIGNUP, {
				state: {
					mobileNo: requestPayload?.Customer?.mobileNo,
					email: requestPayload?.Customer?.email,
					signupData: requestPayload,
					othersData: data,
					type: 'mobile',
					inputType,
				},
			});
			setConfirmModal(true);
			setClick('mobile');
		} else if (data.eiaStatus === SIGNUP_EIA_STATUS.EIA_EMAIL_EXCEED) {
			navigation(AUTH_ROUTES.SIGNUP, {
				state: {
					mobileNo: requestPayload?.Customer?.mobileNo,
					email: requestPayload?.Customer?.email,
					signupData: requestPayload,
					othersData: data,
					type: 'email',
					inputType,
				},
			});
			setConfirmModal(true);
			setClick('email');
		}
	} catch (e: any) {
		yield* put(signUpError(e.message));
	}
}

function* initiateEkycWatcher({ payload }: ReturnType<typeof fetchOtpRequest>) {
	const { handleSuccess, ...requestPayload }: any = payload;
	try {
		const { data }: any = yield* call(initiateEkycRequest, requestPayload);
		yield* put(initiateEkycSuccess(data));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'KYC_button_click', {
				button_name: 'KYC_button',
				Select_Languages: dest,
				Status: 'success',
				Devices_type: 'Web',
			});
		}

		if (handleSuccess) {
			handleSuccess(data);
		}
		// navigation(AUTH_ROUTES.SIGNUP_EKYC_DETAILS_ONE, {
		//   id: '3b25a08c-c355-4d36-82a9-3cbba26d0870',
		//   url: data.url,
		// });
	} catch (e: any) {
		yield* put(initiateEkycError(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'KYC_button_click', {
				button_name: 'KYC_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* saveEkycWatcher({ payload: { navigation, ...payload } }: any) {
	try {
		const { data }: any = yield* call(saveEkycRequest, {
			...payload,
		});

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'SaveEkyc_button_click', {
				button_name: 'SaveEkyc_button',
				Select_Languages: dest,
				Status: 'success',
				Devices_type: 'Web',
			});
		}

		yield* put(
			saveEkycSuccess({
				formattedAddress: generatePermanentAddressFromEkyc(data),
				responseData: data,
			})
		);

		if (data?.flag === EKYC_PROCESS_FLOW_TYPE.MANUAL) {
			const screenflag="EYC"
			navigation(AUTH_ROUTES.SIGNUP_VERIFICATION_STATUS, {
				state: {
					eiaData: data,
					screenflag
				},
			});
		} else if (data?.flag === EKYC_PROCESS_FLOW_TYPE.AUTO) {
			navigation(AUTH_ROUTES.SIGNUP_EKYC_DETAILS_ONE, {
				state: {
					userData: data?.Customer,
				},
			});
			if (data?.userSessionId) {
				sessionStorage.setItem(
					'sessionId',
					cryptoEncryptionDecryption.Encrypt(data?.userSessionId)
				);
			}
		} else {
			const screenflag="EYC"
			navigation(AUTH_ROUTES.SIGNUP_VERIFICATION_STATUS, {
				state: {
					eiaData: data,
					screenflag
				},
			});
		}
	} catch (e: any) {
		yield* put(saveEkycError(e.message));
		yield put(
			setAlertInfo({
				open: true,
				type: 'FAILED',
				description: e?.response?.data?.errorDescription
					? e.response.data.errorDescription
					: 'EKYC failed, please try again later',
			})
		);
		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'SaveEkyc_button_click', {
				button_name: 'SaveEkyc_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}

		navigation(AUTH_ROUTES.SIGNUP);
	}
}
function* saveARWatcher({ payload: { handleSuccess, ...payload } }: any) {
	try {
		const { data }: any = yield* call(saveARRequest, payload);

		yield* put(saveARSuccess(data));
		if (handleSuccess) {
			handleSuccess();
		}
		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		yield* put(fetchUserFamilyListAction());
		yield* put(fetchUserProfile());

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'saveAR_button_click', {
				button_name: 'saveAR_button',
				Select_Languages: dest,
				Status: 'success',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(saveARError(e.message));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'saveAR_button_click', {
				button_name: 'saveAR_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web', 
			});
		}
	}
}
function* copyEIADataWatcher({ payload: { handleSuccess, ...payload } }: any) {
	try {
		const { data }: any = yield* call(copyEIADataService, payload);
		yield* put(copyEIADataSuccess(data));
		if (handleSuccess) {
			handleSuccess();
		}
	} catch (e: any) {
		yield* put(copyEIADataError(e.message));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'FAILED',
				description: e?.response?.data?.errorDescription
					? e.response.data.errorDescription
					: 'Failed to copy EIA data.',
			})
		);
	}
}
function* logOutUser(payload: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				deviceToken: '',
			},
		};
		const { data }: any = yield* call(logoutUser, requestPayload);
		yield* put(logoutSuccess(data));
		if (payload?.payload) {
			payload?.payload();
		}

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'logOut_button_click', {
				button_name: 'logOut_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}
	} catch (e) {
		yield* put(logoutError(e));

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'logOut_button_click', {
				button_name: 'logOut_button',
				Select_Languages: dest,
				Status: 'failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* sendCommunicationWatcher({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const { data }: any = yield* call(sendCommunicationService, payload);

		yield* put(sendCommunicationSuccess(data));

		if (handleSuccess) {
			handleSuccess();
		}
		yield call(handleFetchUserProfileInformation);

		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
	} catch (e: any) {
		yield* put(sendCommunicationError(e.message));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: e.message,
			})
		);
	}
}

function* saveportingDetailsWatcher({ payload }: any) {
	try {
		const { data }: any = yield* call(fetchSavePortingDetails, payload);
		yield* put(savePortingDetailsSuccess(data));
	} catch (e) {
		yield* put(savePortingDetailsError(e));
		// yield* put(logoutError(e));
	}
}

function* watchAuth() {
	yield* takeEvery(fetchLoginRequest.type, handleLoginRequest);
	yield* takeEvery(fetchOtpRequest.type, handleOtpRequest);
	yield* takeEvery(fetchOtpVerify.type, handleOtpVerify);
	yield* takeEvery(setMpinRequest.type, setMpin);
	yield* takeEvery(verifyMpinRequest.type, verifyMpin);
	yield* takeEvery(signUpRequest.type, signUpRequestWatcher);
	yield* takeEvery(initiateEkycRequestAction.type, initiateEkycWatcher);
	yield* takeEvery(saveEkycRequestAction.type, saveEkycWatcher);
	yield* takeEvery(saveARRequestAction.type, saveARWatcher);
	yield* takeEvery(copyEIADataRequest.type, copyEIADataWatcher);
	yield* takeEvery(sendCommunicationRequest.type, sendCommunicationWatcher);
	yield* takeEvery(logoutRequest.type, logOutUser);
	yield* takeEvery(savePortingDetailsRequest.type, saveportingDetailsWatcher);
}

// eslint-disable-next-line import/prefer-default-export
export { watchAuth };
