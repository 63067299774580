import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import { ReactComponent as Cancel } from '../../../../common/icons/cancel-icon.svg';
import { ReactComponent as Correct } from '../../../../common/icons/tick-icon.svg';
import CustomButton from '../../../../common/ui/CustomButton';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import data from '../../../../common/constants/FormConstants.json';
import { parsePinCode } from '../../../../common/utils';
import {
	pinCodeRequest,
	pinMasterRequestLoading,
} from '../../Profile/Profile.slice';
import Spinner from '../../../../common/ui/Spinner';
import config from '../../../../common/config';

const AddressEntryModal = ({ show, setShow, onSubmit, handleCancel }: any) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [pincode, setPincode] = useState('');
	const pinLoading = useSelector(pinMasterRequestLoading);

	const {
		register,
		formState: { errors },
		control,
		reset,
		handleSubmit,
		watch,
		setValue,
	} = useForm({
		defaultValues: {
			address1: '',
			address2: '',
			address3: '',
			city: '',
			state: '',
			pincode: '',
		},
	});

	const handlePinCodeMaster = (pinDetails: any) => {
		setValue('address3', pinDetails?.districtName);
		setValue('state', pinDetails?.stateName);
	};
	useEffect(() => {
		if (pincode.length === 6) {
			const payload = {
				Customer: {
					pincode,
				},
				handlePinCodeMaster,
			};
			dispatch(pinCodeRequest(payload as any));
		} else if (pincode.length !== 6) {
			setValue('address3', '');
			setValue('state', '');
		}
	}, [pincode]);
	React.useEffect(() => {
		if (!show) {
			reset();
		}
	}, [show]);
	const pinCode = watch('pincode');

	useEffect(() => {
		if (pinCode) {
			setValue('pincode', parsePinCode(pinCode));
		}
	}, [pinCode]);
	const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue('city', sanitizedValue, { shouldValidate: true });
	};
	const addressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space

		// const sanitizedValue = inputValue.replace(/^[a-zA-Z0-9\s\/\-,]+$/i, '')
		setValue('address1', inputValue, { shouldValidate: true });
	};

	return (
		<CommonModal
			onClose={() => setShow(false)}
			open={show}
			modalClassName="px-30 py-30"
			boxProps={{ width: 354 }}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid item className="container-family" justifyContent="center">
					<Grid item className=" ">
						<Typography
							className="f-16 fw-600"
							color={theme.palette.primary.main}
							textAlign="center"
						>
							{t('PLEASE_ENTER_CORRESPONDENCE_ADDRESS_DETAILS.')}
						</Typography>
					</Grid>
					<Grid item className="mt-30 mb-25">
						{/* <FormInput
							type="text"
							placeholder="House #,Street #"
							// rules={{
							// 	required: {
							// 		value: true,
							// 		message: 'Address 1 is required',
							// 	},
							// 	pattern: {
							// 		value: config.addressRegex,
							// 		message: 'Invalid address format',
							// 	},
							// }}
							rules={{
								required: {
									value: true,
									message: 'Address is required',
								},
								validate: (value: any) => {
									const trimmedValue = value.trim();
									return (
										trimmedValue.length > 0 ||
										'Address cannot be empty or contain only spaces'
									);
								},
							}}
							control={control}
							name="address1"
							errors={errors}
						/> */}
						<FormInput
							type="text"
							placeholder={`${t('HOUSE')} #, ${t('STREET')} #`}
							name="address1"
							rules={{
								required: {
									value: true,
									message: `${t('ADDRESS_IS_REQUIRED')}`,
								},
								pattern: {
									value: config.addressRegex,
									message: 'Invalid address format',
								},

							}}
							inputProps={{
								className: 'px-10',
								...register('address1', {
									onChange: addressChange,
								}),
							}}
							control={control}
							errors={errors}
						/>
					</Grid>

					<Grid item className="mb-25">
						<FormInput
							type="text"
							placeholder={t('LOCALITY,AREA')}
							rules={{
								required: {
									value: true,
									message: 'Address 2 is required',
								},
								pattern: {
									value: config.addressRegex,
									message: 'Invalid address format',
								},
							}}
							control={control}
							name="address2"
							errors={errors}
						/>
					</Grid>
					<Grid container spacing={3} className="mb-25">
						<Grid item xs={6} sm={6}>
							<FormInput
								type="text"
								placeholder={t('CITY')}
								rules={{
									required: {
										value: true,
										message: `${t('CITY_IS_REQUIRED')}`,
									},
									validate: (value: any) => {
										const trimmedValue = value.trim();
										return (
											trimmedValue.length > 0 || `${t('CITY_CANNOT_BE_EMPTY')}`
										);
									},
								}}
								inputProps={{
									className: 'px-10',
									validate: (value: any) => {
										if (!config.alphabetsOnlyRegex.test(value)) {
											return t(data.INVALID_NAME);
										}
										return true;
									},

									...register('city', {
										onChange: handleCityChange,
									}),
								}}
								control={control}
								name="city"
								errors={errors}
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<FormInput
								type="text"
								placeholder={t('PIN')}
								rules={{
									required: {
										value: true,
										message: `${t('PIN_IS_REQUIRED')}`,
									},
									minLength: {
										value: 6,
										message: `${t('PINCODE_SHOULD_BE_IN_SIX_DIGITS')}`,
									},
									maxLength: {
										value: 6,
										message: `${t('PINCODE_SHOULD_BE_IN_SIX_DIGITS')}`,
									},
								}}
								inputProps={{
									onChange: (event: any) => setPincode(event.target.value),
								}}
								control={control}
								name="pincode"
								errors={errors}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3} className="mb-25">
						<Grid item xs={6} sm={6}>
							{pinLoading ? (
								<Grid display="flex" justifyContent="center" className="mt-10">
									<Spinner />
								</Grid>
							) : (
								<FormInput
									type="text"
									placeholder={t('DISTRICT')}
									readOnly
									// rules={{
									// 	required: {
									// 		value: true,
									// 		message: 'District is required',
									// 	},
									// }}
									inputProps={{
										className: 'px-10',
										...register('address3', {
											required: {
												value: true,
												message: t(data.DISTRICT),
											},
											validate: (value: any) => {
												if (!config.alphabetsOnlyRegex.test(value)) {
													return t(data.INVALID_NAME);
												}
												return true;
											},
										}),
									}}
									// control={control}
									name="address3"
								// errors={errors}
								/>
							)}
							{errors?.address3?.ref?.value.length > 0
								? null
								: errors.address3 && (
									<Typography
										color={theme.palette.error.main}
										fontSize={12}
										className="mt-5"
									>
										{t('DISTRICT_IS_REQUIRED')}
									</Typography>
								)}
						</Grid>
						<Grid item xs={6} sm={6}>
							{pinLoading ? (
								<Grid display="flex" justifyContent="center" className="mt-10">
									<Spinner />
								</Grid>
							) : (
								<FormInput
									type="text"
									placeholder={t('STATE')}
									readOnly
									// rules={{
									// 	required: {
									// 		value: true,
									// 		message: 'State is required',
									// 	},
									// }}
									// control={control}
									name="state"
									inputProps={{
										className: 'px-10',
										...register('state', {
											required: {
												value: true,
												message: t(data.STATE),
											},
										}),
									}}
								// errors={errors}
								/>
							)}
							{errors?.state?.ref?.value.length > 0
								? null
								: errors.state && (
									<Typography
										color={theme.palette.error.main}
										fontSize={12}
										className="mt-5"
									>
										{t('STATE_IS_REQUIRED')}
									</Typography>
								)}
						</Grid>
					</Grid>
					<Grid
						container
						justifyContent="space-around"
						spacing={1}
						className="mt-40"
					>
						<Grid item>
							<CustomButton
								text={t('ADD')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Correct}
								className="f-16 fw-600"
								type="submit"
							/>
						</Grid>
						<Grid item>
							<CustomButton
								text={t('CANCEL')}
								startIcon={Cancel}
								showIcon={false}
								color="primary"
								className="f-16 fw-400"
								variant="text"
								onClick={handleCancel}
							/>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</CommonModal>
	);
};

export default AddressEntryModal;
