import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './legal.scss';
import { Box, Grid, Typography, Hidden } from '@mui/material';

import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';

const PrivacyPolicyScreen = ({ privacypopup }: any) => {
	const { t } = useTranslation();

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Privacy Policy Screen',
		});
	}, []);

	return (
			<Grid container justifyContent="center">
			<Box
				marginBottom={5}
				width={800}
				display="flex"
				justifyContent="center"
				flexDirection="column"
				className="px-15"
			>
				{/* {!privacypopup && ( */}
					{/* <Grid item container className="mt-15">
						<Grid item>
							<span className="arrow-span">
								<Link to={APP_ROUTES.DASHBOARD}>
									<SvgConverter
										Icon={ArrowLeftIcon}
										className="left-arrow-style"
									/>
								</Link>
							</span>
						</Grid> */}

						{/* <Grid item>
							<Typography
								fontSize={20}
								color="black"
								fontWeight={900}
								fontFamily="inherit"
								className="mb-5 "
							>
								Privacy Policy
							</Typography>
							
						</Grid> */}
					{/* </Grid> */}
				{/* )} */}
				{/* <span className="arrow-span">
					<Link to={APP_ROUTES.DASHBOARD}>
						<SvgConverter Icon={ArrowLeftIcon} className="left-arrow-style" />
					</Link>
				</span> */}
				{/* <Grid className="content-merge"> */}
				
				<Grid item>
					<Typography fontSize={15} className="text-color">
						CAMSRep respects your privacy and has accordingly formulated a
						privacy policy, in compliance with the applicable laws (the “Privacy
						Policy”).Privacy Policy of this app/platform is associated with the
						Privacy Policy of the CAMSRep and it confirms CAMSRep’s commitment
						to privacy and demonstrates the ways we ensure that data privacy is
						protected by offering you an effective, safe and secure online
						experience in compliance with Information Technology (Reasonable
						Security Practices and Procedure and Sensitive Personal Data or
						Information) Rules, 2011, Digital Personal Data Protection Act 2023,
						and The Aadhaar Act, 2016 and IRDAI regulations.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						CAMSRep’s Privacy Policy applies to the Sensitive Personal Data or
						Information (“SPDI”) of all our Customers / Investors and Clients
						that is in our possession and control. We request Investors /
						Clients / Customers to kindly read this Policy on Privacy carefully.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						CAMSRep’s either directly or through third party service providers
						collects, receives, possesses, stores, deals, or handles information
						received from investors, client, customers whether existing or
						prospective in order to provide Insurance Repository Services, and
						do not use such information for any other purpose except as set out
						herein, or as otherwise required or permitted by applicable law.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						The information can be personal and sensitive in nature as given
						below in the indicative list:
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						<ul>
							<li className="mt-10">
								Bank Account details, change of bank account details or
								registration of multiple bank accounts etc. through forms;
							</li>
							<li className="mt-10">
								Payment instrument details at the time of submitting forms at
								the time of investment or as proof for carrying out change of
								bank account or any other details of debit card obtained (if
								any);
							</li>
							<li className="mt-10">Biometric information obtained, if any;</li>
							<li className="mt-10">
								Demat account details like beneficiary account no., etc{' '}
							</li>
							<li className="mt-10">Annual Income and savings profile; and</li>
							<li className="mt-10">
								Other information as may be considered as sensitive and
								personal.
							</li>
						</ul>
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						The aforesaid information is being collected by CAMSRep or
						third-party service providers to service you better and to be in
						complaint with Know Your Client (“KYC”) norms under the Prevention
						of Money Laundering Act 2001, (“PMLA”), the rules issued thereunder,
						and guidelines and circular on Anti-Money Laundering issued by RBI
						and allied RBI related Regulations, as amended till date.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						You hereby agree that you may be requested to provide your express
						consent by clicking on the ‘I ACCEPT’ button at the time of
						submission of your Personal Information on this application, to
						collect, process, store, share and purge the information submitted
						by you, failing which you will be unable to avail the Services
						offered on the application.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						This Privacy Policy covers the below mentioned topics:
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						<ul>
							<li className="mt-10">How is Personal Information collected?</li>
							<li className="mt-10">How is Personal Information used?</li>
							<li className="mt-10">Sharing of Personal Information;</li>
							<li className="mt-10">
								How access to Customer Information is limited to employees?
							</li>
							<li className="mt-10">Information Security;</li>
							<li className="mt-10">Data Retention & Erasure;</li>
							<li className="mt-10">Incident Management.</li>
						</ul>
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							How is Personal Information Collected?
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						The Personal Information that you share with us would be entitled to
						privacy and kept strictly confidential. The Personal Information
						along with all supporting documents submitted by you shall be used
						for the limited purpose: Personal Information is collected online
						through our website and mobile app/platforms. We gather information
						that you provide to us when you:
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						<ul>
							<li className="mt-10">
								Purchase products or services through CAMSRep’s Insurance
								Service Centres (“ISCs”), through respective insurers, or
								directly approaching Bima Central’s app & portal to avail the
								services;{' '}
							</li>
							<li className="mt-10">
								Make an inquiry, provide feedback, submit correspondence, or
								make a complaint over the phone, by email, on our website or by
								post;
							</li>
							<li className="mt-10">
								Register for, and update an online account with us;
							</li>
							<li className="mt-10">
								Enter into a separate Agreement/contract/understanding with us;
							</li>
							<li className="mt-10">
								Certain information and/or data may be automatically collected
								through the use of ‘cookies’.
							</li>
						</ul>
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							How Personal information is Used ?
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						CAMSRep uses information to comply with applicable laws, guidelines
						and regulations in order to detect, prevent illegal activities,
						fight spam, and protect the rights and property of clients. CAMSRep
						may use the information shared by Clients regarding the products and
						services to enhance the service-related improvements. Reports are
						generated to protect and defend the rights, interests of Clients
						products and services.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						We also monitor Internet Protocol (IP) addresses, browser supplied
						information and site session information may be recorded as part of
						the normal operations and for security monitoring purposes.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						Some of our products and services collect information about system
						and product data. We use this information to manage and administer
						our products and services, to issue updates and new versions,
						testing and monitoring services that we provide, enhancing our
						products and services, and improving and targeting our
						communications with you.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Sharing of Personal Information:
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						CAMSRep shall reveal only such information to external authorities
						to comply with applicable laws including cyber laws, based on
						relevance to comply with applicable laws and to insurers,
						Third-party service providers including technology partners,
						loan-lending agencies, regulators to ensure smooth functioning of
						the activities.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Access to customer information is limited to employees and Third
							Parties :
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						CAMSRep limits access to personally Identifiable Information (“PII”)
						to those employees with a business reason for knowing such
						information about investors. We regularly educate our employees on
						their responsibility to protect the confidentiality of investors’
						information. We ensure to enter into suitable terms with third party
						service providers to protect client confidentiality and accordingly
						execute Non-Disclosure Agreement (“NDA”) with them.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						Every person who has or is provided with an access to such data,
						undergoes periodical training with regards to data handling, data
						protection and is also informed about all the consequences in case
						of any violation privacy terms.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Information Security:
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						To protect Personal Information from unauthorized access and use,
						CAMSRep uses security measures and controls that comply with
						applicable laws and regulations and is a certified ISO 9001:2015
						ISMS Company. These measures may include device safeguards and
						secured files and buildings as well as oversight of their
						third-party service providers to ensure information remains
						confidential and secure.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						We have adopted all the suitable measures to diagnose any problems
						with its server and to administer the application including by
						blocking certain addresses that it feels are inappropriately using
						its application. In the event of any breach or attempted breach by
						any third party, in compliance with the applicable information
						technology laws, it will try to rectify the problems as soon as
						possible.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Data Retention & Erasure:
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						We ensure that we meet the ‘data minimization’ and ‘storage
						limitation’ principles and that personal information is stored,
						archived, and destroyed compliantly and ethically. We have dedicated
						erasure procedures in place to meet the new ‘Right to Erasure’
						obligation and are aware of when this and other data subject’s
						rights apply, along with any exemptions, response timeframes and
						notification responsibilities. CAMSRep may retain the data as may be
						provided by the client till such time as may be provided under the
						law and as required to efficiently provide service to the clients.
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						Any data destroyed shall be disposed of in a manner that protects
						the privacy of the Personal Information received by us from the user
						in an appropriate manner as per the industry standard practices and
						norms.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Incident Management:
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						Our Incident Reporting procedures ensure that we have safeguards and
						controls in place to identify, assess, investigate, and report any
						personal data breach at the earliest possibility. Our procedures are
						robust and have been disseminated to all employees, who are aware of
						the reporting lines and steps to follow.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Liability:
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						CAMSRep shall in no manner be liable for any violation of data, if
						the data,
					</Typography>
					<Typography fontSize={15} className="mt-10 text-color">
						<ol style={{ listStyleType: 'lower-alpha' }}>
							<li className="mt-10">is in public domain; or</li>
							<li className="mt-10">
								is a result of any illegal and unlawful misuse;
							</li>
						</ol>
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Public Data:
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						Data that is available in public domain shall not be governed by
						this Policy. CAMSRep shall not be responsible in any manner of
						whatsoever nature for any violation or misuse of such Public Data.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Changes to this Policy
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						CAMSRep reserves the right to change this Policy at any time by
						revising it. This privacy policy is not intended to and does not
						create any contractual or other legal rights in or on behalf of any
						party. By accessing Bima Central’s website portal & app, you
						acknowledge and fully understand CAMSRep’s Privacy Policy and freely
						consent to the information collection and use practices described in
						this Policy. We encourage you to periodically review this privacy
						policy to stay informed about how we are helping to protect the
						personal information we collect. Your continued use of the service
						constitutes your agreement to this privacy policy and any updates
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Link to other sites
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						For your convenience, this page may contain certain hyperlinks to
						other sites/Platform. While we try to link only to sites/Platform
						that share our high standards and respect for privacy, we are not
						responsible for the content or the privacy practices employed by
						other sites/Platform. We recommend that you check the policy of each
						site/Platform you visit and contact the owner or operator of such
						website if you have any concerns or questions.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Consent
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						By accessing this web site and any of its pages you are agreeing to
						the Privacy Policy. Clients shall not be covered by this Policy, if
						they access other websites using links from our website.
					</Typography>
					<Grid item>
						<Typography
							fontSize={20}
							color="black"
							fontWeight={800}
							fontFamily="inherit"
							className="mb-5 mt-10"
						>
							Grievance Redressal
						</Typography>
					</Grid>
					<Typography fontSize={15} className="mt-10 text-color">
						If Clients / Customer / Investors have any concerns relating to
						their personal and sensitive information they may be addressed to{' '}
						<span className="footer">
							<u>privacy@camsonline.com.</u>
						</span>
					</Typography>
				</Grid>
				{/* </Grid> */}
			</Box>
		</Grid>
	);
};
export default PrivacyPolicyScreen;
