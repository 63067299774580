import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	
	Grid,
	Typography,
	useTheme,
	
} from '@mui/material';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/small-orange-rupee.svg';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import '../Policy.scss';
import Separator from '../../../../common/ui/Separator';
import CustomCheckbox from '../../LoanModule/CustomCheckbox';

interface FinancierCardLoanDetailsProps {
	financier?: string;
	lifeAssured?: string;
	logoSrc?: any;
	label?: any;
	onChange?: any;
	checked?: any;
	charges?: any;
	interestPercentage?: any;
}

const FinancierCardLoanDetails = ({
	financier,
	lifeAssured,
	logoSrc,
	label,
	onChange,
	checked,
	charges,
	interestPercentage,
}: FinancierCardLoanDetailsProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Grid container spacing={0} className="mt-5 mb-5">
			<Grid item container>
				<Grid className="mt-3 mr-10" xs={1.5}>
					<CustomCheckbox 
				
					onChange={()=>onChange(label)}
						checked={label === checked}
						/>
					
				</Grid>
				<Grid container xs={10}>
					<Grid item xs={2} className="mr-15">
						<img src={logoSrc} alt="logo" className="bank_logo" />
					</Grid>
					<Grid xs={9}>
						<Grid item xs={12}>
							<Typography className="f-18 fw-600" color="primary">
								{financier}
							</Typography>
						</Grid>
						<Grid item xs={12} className="mt-5">
							<Typography className="f-14" color={theme.palette.primary.main}>
								{t('LOAN_AMOUNT')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={0.5}>
								<LabelWithIcon
									className="px-6"
									svgClassName=""
									justifyContent="flex-start"
									Icon={RupeeIcon}
									label={lifeAssured}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 18,
										fontWeight: 600,
									}}
								/>
							</Grid>

							<Typography
								className="f-14 finance_text"
								color={theme.palette.common.black}
							>
								@&nbsp;
								<Typography
									className="f-14 fw-600"
									color={theme.palette.common.black}
								>
									{interestPercentage}
								</Typography>
								&nbsp; {t('INTEREST')}
							</Typography>
							<Grid display="flex">
								<Typography className="f-14" color={theme.palette.primary.dark}>
									{t('CHARGES')}:{' '}
								</Typography>
								<LabelWithIcon
									className="mr-50"
									Icon={RupeeIcon}
									label={charges}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 14,
										fontWeight: 600,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Separator color={theme.palette.grey[300]} className="mb-8 mt-15" />
					<Typography className="f-14" color={theme.palette.primary.main}>
						{t('SELECT_REPAYMENT_OPTIONS')}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default FinancierCardLoanDetails;
