import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import './LoanModule.scss';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { convertAmount, getFormatDate } from '../../../common/utils';

interface UserProps {
	amount?: any;
	annual?: any;
	planName?: any;
	selfName?: any;
	date?: any;
}
const ProductDetail = ({
	amount,
	annual,
	planName,
	selfName,
	date,
}: UserProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Grid py={2} className="product-name-box">
			<Typography className="fw-600 f-14" pb={1}>
				{getFormatDate(date, 'YYYY-MM-DD HH:mm:ss.S')}
			</Typography>

			<Grid py={2} px={1} className="product-details">
				<Grid container xs={12} alignItems="end">
					<Grid item>
						<Grid item spacing={0.5}>
							<LabelWithIcon
								svgClassName="product-rupee-icon"
								justifyContent="flex-start"
								Icon={RupeeIcon}
								label={convertAmount(amount)}
								labelProps={{
									color: theme.palette.primary.dark,
									fontSize: 18,
									fontWeight: 600,
								}}
							/>
						</Grid>
					</Grid>

					<Grid item ml={1} mb={0.5}>
						<Typography className="f-14" color={theme.palette.common.black}>
							{annual}{' '}
						</Typography>
					</Grid>
				</Grid>
				<Grid my={0.5}>
					<Typography
						className="f-14 fw-600"
						color={theme.palette.primary.dark}
					>
						{planName}
					</Typography>
				</Grid>
				<Grid>
					<Typography className="f-12" color={theme.palette.primary.dark}>
						{t('LIFE_ASSURED')}
					</Typography>
				</Grid>
				<Grid>
					<Typography
						className="f-12 fw-600"
						color={theme.palette.common.black}
					>
						{selfName}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProductDetail;
