import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import config from '../../../../common/config';
// import data from '../../../../common/constants/FormConstants.json';
import { pinCodeRequest, pinMasterRequestLoading } from '../Profile.slice';
import theme from '../../../../common/theme';
import Spinner from '../../../../common/ui/Spinner';
import { alphabetsOnly, parsePinCode } from '../../../../common/utils';

const AddFamilyAddress = ({
	setValue,
	register,
	errors,
	permanentEiaAddress,
	PermanentAddress,
	type,
	// data,
	index,
	watch = () => { },
	control,
	isAR
}: {
	setValue: any;
	register: any;
	errors?: any;
	permanentEiaAddress?: any;
	PermanentAddress?: boolean;
	type?: any;
	// data?: any;
	index?: number;
	watch?: any;
	control?: any;
	pincode?: any;
	isAR?: any;
}) => {
	const { t } = useTranslation();

	const [pincode, setPincode] = useState('');
	const pinLoading = useSelector(pinMasterRequestLoading);
	const dispatch = useDispatch();
	const handlePinCodeMaster = (pinDetails: any) => {
		setValue('address3', pinDetails?.districtName);
		setValue('state', pinDetails?.stateName);
	};
	useEffect(() => {
		if (pincode?.length === 6) {
			const payload = {
				Customer: {
					pincode,
				},
				handlePinCodeMaster,
			};
			dispatch(pinCodeRequest(payload as any));
		}
	}, [pincode]);
	useEffect(() => {
		if (!watch('permanentAddress') && isAR) {
			setPincode('');
			setValue('pin', '');
		}
		if (!permanentEiaAddress || !PermanentAddress) return;
		setValue('address1', permanentEiaAddress.address1, {
			shouldValidate: true,
		});
		setValue('address2', permanentEiaAddress.address2, {
			shouldValidate: true,
		});
		setValue('address3', permanentEiaAddress.address3, {
			shouldValidate: true,
		});
		setValue('city', permanentEiaAddress.city, { shouldValidate: true });
		setValue('state', permanentEiaAddress.state, { shouldValidate: true });
		setValue('pin', permanentEiaAddress.pincode, { shouldValidate: true });
	}, [PermanentAddress]);
	const city = watch('city');
	const pin = watch('pin');

	useEffect(() => {
		if (city) {
			setValue('city', alphabetsOnly(city));
		}
	}, [city]);

	return (
		<Grid item>
			{type === 'NOMINEE' ? (
				<Grid item>
					<Grid item className="mb-5">
						<FormInput
							type="text"
							placeholder={t('LOCALITY,AREA')}
							name="address2"
							inputProps={{
								className: 'px-10',
								pattern: {
									value: config.addressRegex,
									message: 'Invalid address format',
								},
								...register(`nomineeData.${index}.address2`, {
									// required: {
									// 	value: true,
									// 	message: data.AREA,
									// },

								}),
							}}
							errors={errors}
							disabled={watch('permanentAddress')}
						/>
					</Grid>
					<Grid container spacing={3} className="mb-5">
						<Grid item xs={6} sm={6}>
							<FormInput
								type="text"
								placeholder={t('City')}
								name="city"
								inputProps={{
									className: 'px-10',
									...register(`nomineeData.${index}.city`, {
										// required: {
										// 	value: true,
										// 	message: data.CITY,
										// },
									}),
								}}
								errors={errors}
								disabled={watch('permanentAddress')}
								control={control}
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<FormInput
								type="number"
								placeholder={t('PIN')}
								name="pincode"
								inputProps={{
									maxLength: 6,
									className: 'px-10',
									...register(`nomineeData.${index}.pincode`, {
										onChange: (event: any) =>
											setPincode(parsePinCode(event.target.value)),
										// required: {
										// 	value: true,
										// 	message: data.PIN,
										// },
										// validate: (value: any) => {
										// 	if (!config.pincode.test(value)) {
										// 		return data.INVALID_PINCODE;
										// 	}
										// 	return true;
										// },
									}),
								}}
								errors={errors}
								disabled={watch('permanentAddress')}
								control={control}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3} className="mb-5">
						<Grid item xs={6} sm={6}>
							<FormInput
								type="text"
								placeholder={t('DISTRICT')}
								name="address3"
								inputProps={{
									className: 'px-10',
									...register(`nomineeData.${index}.address3`, {
										// required: {
										// 	value: true,
										// 	message: data.DISTRICT,
										// },
									}),
								}}
								errors={errors}
								disabled={watch('permanentAddress')}
								control={control}
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<FormInput
								type="text"
								placeholder={t('STATE')}
								name="state"
								inputProps={{
									className: 'px-10',
									...register(`nomineeData.${index}.state`, {
										// required: {
										// 	value: true,
										// 	message: data.STATE,
										// },
									}),
								}}
								errors={errors}
								disabled={watch('permanentAddress')}
								control={control}
							/>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid item>
					<Grid item className="mb-5">
						<FormInput
							type="text"
							placeholder={`${t('HOUSE')} #, ${t('STREET')} #`}
							name="address1"
							disabled={watch('permanentAddress')}
							inputProps={{
								className: '',
								...register('address1', {
									// required: {
									// 	value: true,
									// 	message: 'Street is required ',
									// },
									pattern: {
										value: config.addressRegex,
										message: `${t('INVALID_ADDRESS_FORMAT')}`,
									},
								}),
							}}
							errors={errors}
							control={control}
						/>
					</Grid>

					<Grid item className="mb-5">
						<FormInput
							type="text"
							placeholder={t('LOCALITY,AREA')}
							name="address2"
							disabled={watch('permanentAddress')}
							inputProps={{
								...register('address2', {
									// required: {
									// 	value: true,
									// 	message: 'Area is required ',
									// },
									pattern: {
										value: config.addressRegex,
										message: 'Invalid address format',
									},

									// validate: (value: any) => {
									// 	if (!config.alphabetsOnlyRegex.test(value)) {
									// 		return data.INVALID_NAME;
									// 	}
									// 	return true;
									// },
								}),
							}}
							errors={errors}
							control={control}
						/>
					</Grid>
					<Grid container rowSpacing={3} columnSpacing={2} className="mb-5">
						<Grid item xs={6} sm={6}>
							<FormInput
								type="text"
								placeholder={t('CITY')}
								name="city"
								disabled={watch('permanentAddress')}
								inputProps={{
									value: city,
									...register('city', {
										// required: {
										// 	value: true,
										// 	message: 'city is required ',
										// },
										// validate: (value: any) => {
										// 	if (!config.alphabetsOnlyRegex.test(value)) {
										// 		return data.INVALID_NAME;
										// 	}
										// 	return true;
										// },
									}),
								}}
								errors={errors}
								control={control}
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<FormInput
								type="text"
								placeholder={t('PIN')}
								name="pin"
								disabled={watch('permanentAddress')}
								inputProps={{
									value: pin,
									maxLength: 6,
									className: '',
									...register('pin', {
										onChange: (event: any) => {
											setPincode(parsePinCode(event.target.value));
											setValue('pin', parsePinCode(event.target.value));
											if (event.target.value?.length !== 6) {
												setValue('address3', '');
												setValue('state', '');
											}
										},
										// required: {
										// 	value: true,
										// 	message: 'pincode is required ',
										// },
										// validate: (value: any) => {
										// 	setPincode(value);
										// 	if (!config.pincode.test(value)) {
										// 		return data.INVALID_PINCODE;
										// 	}
										// 	return true;
										// },
									}),
								}}
								errors={errors}
								control={control}
							/>
						</Grid>
					</Grid>
					<Grid container rowSpacing={3} columnSpacing={2} className="mb-5">
						<Grid item xs={6} sm={6}>
							{pinLoading ? (
								<Grid display="flex" justifyContent="center" className="mt-10">
									<Spinner />
								</Grid>
							) : (
								<FormInput
									type="text"
									placeholder={t('DISTRICT')}
									name="address3"
									disabled={watch('permanentAddress')}
									control={control}
									readOnly
									inputProps={{
										...register('address3', {
											// required: {
											// 	value: true,
											// 	message: 'District is required ',
											// },
											pattern: {
												value: config.addressRegex,
												message: 'Invalid address format',
											},
											// 	return true;
											// },
										}),
									}}
								/>
							)}
							{errors?.address3?.ref?.value.length > 0
								? null
								: errors.address3 && (
									<Typography
										color={theme.palette.error.main}
										fontSize={12}
										className="mt-5"
									>
										{t('DISTRICT_IS_REQUIRED')}
									</Typography>
								)}
						</Grid>
						<Grid item xs={6} sm={6}>
							{pinLoading ? (
								<Grid display="flex" justifyContent="center" className="mt-10">
									<Spinner />
								</Grid>
							) : (
								<FormInput
									type="text"
									placeholder={t('STATE')}
									name="state"
									control={control}
									disabled={watch('permanentAddress')}
									readOnly
									inputProps={{
										...register('state', {
											// required: {
											// 	value: true,
											// 	message: 'state is required ',
											// },
											// validate: (value: any) => {
											// 	if (!config.alphabetsOnlyRegex.test(value)) {
											// 		return data.INVALID_NAME;
											// 	}
											// 	return true;
											// },
										}),
									}}
								/>
							)}
							{errors?.state?.ref?.value.length > 0
								? null
								: errors.state && (
									<Typography
										color={theme.palette.error.main}
										fontSize={12}
										className="mt-5"
									>
										{t('STATE_IS_REQUIRED')}
									</Typography>
								)}
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

export default AddFamilyAddress;
