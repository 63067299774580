import { Box, Card, Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { ReactComponent as RupeeIcon1 } from '../../../../../../common/icons/orange_rupee.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GreenTick } from '../../../../../../common/icons/small-greentick.svg';
import { ReactComponent as Alert } from '../../../../../../common/icons/profile/alert.svg';
import theme from '../../../../../../common/theme';
import Separator from '../../../../../../common/ui/Separator';
import LabelWithIcon from '../../../../../../common/ui/LabelWithIcon';
import SvgConverter from '../../../../../../common/SvgConverter';
import ContinueTacker from '../../../../SurrenderModule/ContinueTracker';
import TrackerTransactionTimeline from '../../../../LoanModule/TrackTransaction/TrackerTansactionLayout/TrackerTransactionTimeLine';
import CustomButton from '../../../../../../common/ui/CustomButton';
import BankDetails from '../../../../LoanModule/BankDetails';
import { ReactComponent as WithdrawalIcon } from '../../../../../../common/icons/LoanModule/withdrawalIcon.svg';

interface PolicyDetailsDataProps {
	policyDetails?: any;
	detailType?: any;
}
const PolicyDetailsData: React.FC<PolicyDetailsDataProps> = ({
	policyDetails,
	detailType,
}) => {
	const { t } = useTranslation();

	return (
		<Grid item container xs={12} mt={1}>
			<Grid item xs={12} className=" header-details mt-50">
				<Grid item xs={12} container className="ml-20 ">
					<Grid item xs={3} className="ml-10 ">
						{policyDetails &&
							policyDetails?.insurerLogo &&
							policyDetails?.insurerLogo.length > 0 && (
								<img src={policyDetails?.insurerLogo} width="60px" alt="logo" />
							)}

						{policyDetails?.verified === 'Y' ? (
							<LabelWithIcon
								className=" mt-20 mr-10"
								svgClassName="mb-2 "
								justifyContent="flex-start"
								Icon={GreenTick}
								label={t('VERIFIED')}
								labelProps={{
									color: '#63a103',
									fontSize: 12,
									fontWeight: 600,
								}}
							/>
						) : (
							<Grid
								item
								className=""
								textAlign="center"
								justifyContent="center"
							>
								<SvgConverter Icon={Alert} className="ml-40" />
								<Typography
									className="f-12 fw-600 "
									color={theme.palette.secondary.main}
								>
									{t('VERIFICATION_INPROGRESS')}
								</Typography>
							</Grid>
						)}
					</Grid>

					<Grid item xs={6} className="ml-10">
						<Typography
							className="f-18 fw-600 "
							color={theme.palette.primary.main}
						>
							{policyDetails?.productName ? policyDetails?.productName : '-'}
						</Typography>
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							className="policy-name"
						>
							{t('POLICY')} #:
							{policyDetails?.policyNo ? policyDetails?.policyNo : '-'}
						</Typography>

						{detailType === 'asset' || detailType === 'health'
							? policyDetails?.endorsement !== '' && (
									<Typography
										color={theme.palette.primary.main}
										fontSize={14}
										className="policy-name"
									>
										{t('Endorsement')} #:
										{policyDetails?.endorsement !== undefined
											? policyDetails?.endorsement
											: '-'}{' '}
									</Typography>
							  )
							: null}
						<Typography
							color={theme.palette.primary.main}
							fontSize={14}
							className="policy-name fw-600"
						>
							{t('PRODUCT_CODE')}:
							{policyDetails?.companyProductCode
								? policyDetails?.companyProductCode
								: '-'}
						</Typography>
						<Typography color={theme.palette.primary.main} fontSize={14}>
							{t('IRDAI')}:
							{policyDetails?.irdaiCode ? policyDetails?.irdaiCode : '-'}
						</Typography>
						<Typography color={theme.palette.primary.main} fontSize={14}>
							{t('COMPANY')}:
							{policyDetails?.company ? policyDetails?.company : '-'}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} container className="mt-20">
					<Separator color={theme.palette.grey[400]} />
					<Grid item container xs={12} className="py-10">
						<Grid item xs={4}>
							<Typography
								className="f-14 ml-10"
								color={theme.palette.common.black}
							>
								{t('POLICY_STATUS')}:
							</Typography>
						</Grid>
						<Grid item xs={5} justifyContent="start">
							<Typography
								className="f-16 fw-600 "
								color={theme.palette.primary.main}
							>
								{policyDetails?.policyStatus
									? policyDetails?.policyStatus
									: '-'}
							</Typography>
						</Grid>
					</Grid>
					<Separator color={theme.palette.grey[400]} />

					{detailType === 'asset' || detailType === 'health' ? (
						<Typography
							className="f-16 fw-600 "
							color={theme.palette.primary.main}
						></Typography>
					) : (
						<>
							<Typography>
								{policyDetails?.PolicySurrender && (
									<>
										{/* <Typography className='surrender-custom-details'>
										Policy Surrender
									</Typography> */}

										<Card
											sx={{
												width: 300,
												backgroundColor: theme.palette.grey[100],
											}}
											variant="outlined"
											className="mt-20"
										>
											<Grid display="flex" justifyContent="center">
												<Grid
													className="surrender-custom-details"
													ml={0}
													justifyContent="center"
													display="flex"
													alignItems="center"
												>
													<Grid item spacing={0.5}>
														<LabelWithIcon
															svgClassName="product-rupee-icon"
															justifyContent="flex-start"
															Icon={WithdrawalIcon}
															label={'Policy Surrender'}
															labelProps={{
																color: theme.palette.error.dark,
																fontSize: 14,
																fontWeight: 600,
															}}
														/>
													</Grid>
												</Grid>
											</Grid>

											{/* <Typography
										className="ml-20 f-16 fw-600 mb-0 pl-10"
										justifyContent="center"
										color={theme.palette.error.main}
									>
										Policy Surrender
									</Typography> */}
											<Separator
												className="mt-0 ml-0 mr-0 "
												color={theme.palette.grey[300]}
											/>
											<Typography
												className="mt-15 f-14 fw-600 ml-20 mb-0 mt-0 pl-10"
												color={theme.palette.primary.main}
											>
												{t('Maturity Value')}
											</Typography>
											<Grid display="flex" className="ml-20">
												<Grid className="ml-0 f-20 mt-0 pl-10 pt-5">
													<SvgConverter Icon={RupeeIcon1} />
												</Grid>
												<Grid>
													<Typography
														color="black"
														className="ml-5 mb-0  f-16 fw-400 align-items-center"
													>
														{policyDetails?.PolicySurrender?.maturityValue}
														{/* {notifications} */}
													</Typography>
												</Grid>
											</Grid>
											<Separator color={theme.palette.grey[300]} />
											<Grid className="ml-20  pl-10">
												<Typography
													className="f-14 fw-600 mb-0 pt-10"
													color={theme.palette.error.main}
												>
													Surrender Value
												</Typography>

												<Grid display="flex" className="">
													<Grid className=" f-20 mt-15">
														<SvgConverter Icon={RupeeIcon1} />
													</Grid>
													<Grid>
														<Typography
															color={theme.palette.error.main}
															className="f-24 fw-400 align-items-center"
														>
															{
																policyDetails?.PolicySurrender
																	?.currentSurrenderValue
															}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
											<Separator color={theme.palette.grey[300]} />
											<Box className="">
												<Grid
													container
													item
													display="flex"
													justifyContent="center"
												>
													<Grid
														item
														className="mt-30 align-left custom-timeline"
													>
														{/* <Timeline
        className="timeline"
        sx={{
            [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
            },
        }}
    /> */}

														<TrackerTransactionTimeline
															status={'completed'}
															// {widget?.Loan?.LoanStatus?.Customer && (
															index={0}
															title="Surrender Request Initiated"
															eta={
																policyDetails?.PolicySurrender
																	?.surrenderInitatedDate
															}
														/>

														<TrackerTransactionTimeline
															status={
																policyDetails?.PolicySurrender
																	?.insurerProcessing?.status === 'P'
																	? 'pending'
																	: policyDetails?.PolicySurrender
																			?.insurerProcessing?.status
															}
															index={1}
															title="Insurer Processing"
															eta={'As Per Actual'}
														/>
														<TrackerTransactionTimeline
															// status={'Pending'}
															index={2}
															title="Funds Disbursal"
															eta={'10'}
															isEnd={true}
														/>
													</Grid>
												</Grid>
											</Box>
											{/* <Separator color={theme.palette.grey[300]} /> */}
											<Grid className="pl-20 grid-custom-clr">
												<Typography
													className="ml-0 f-16 mb-0 pt-10"
													color="black"
												>
													Bank Details
												</Typography>
												<Separator color={theme.palette.grey[300]} />
												<Grid item xs={12} className="f-20 mt-15">
													<BankDetails
														verifyBank={
															policyDetails?.PolicySurrender?.payeeBankVerified
														}
														bankName={policyDetails?.PolicySurrender?.bankName}
														branch={policyDetails?.PolicySurrender?.branchName}
														ifscCode={
															policyDetails?.PolicySurrender?.payeeIfscCode
														}
														accountNum={
															policyDetails?.PolicySurrender?.payeeAccNo
														}
														bankLogo={policyDetails?.PolicySurrender?.bankLogo}
														accountHolder={
															policyDetails?.PolicySurrender?.payeeAccName
														}
													/>
												</Grid>
											</Grid>
										</Card>
									</>
								)}
							</Typography>
						</>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
export default PolicyDetailsData;
