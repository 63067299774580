import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DashboardCardLayout from './common/DashboardCardLayout';
import PolicyCardHeader from './common/PolicyCardHeader';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../common/icons/dashboard/rupee-icon.svg';
import { convertAmount } from '../../../common/utils';
import './Dashboard.scss';
import Currency from '../Common/Currency';

interface UserProps {
	assetsDetails?: any;
}
const AssetsScreen: React.FC<UserProps> = ({ assetsDetails }) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<DashboardCardLayout>
			<div className="mb-30">
				<PolicyCardHeader type="assets" />
			</div>
			{assetsDetails &&
				assetsDetails.length > 0 &&
				assetsDetails.map((assetsData: any) => (
					<Grid
						container
						flexDirection="row"
						spacing={3}
						justifyContent="space-between"
					>
						<Grid item className="mb-20">
							<div className="w-280px">
								<div className="dashboard-policy-cost-container">
									<Typography
										fontWeight={600}
										className="pb-1 "
										fontSize={14}
										color={theme.palette.primary.main}
									>
										{assetsData?.type}:{' '}
										<span className="fw-400">{assetsData?.registrationNo}</span>
									</Typography>
								</div>

								<Grid
									container
									flexWrap="nowrap"
									className="mt-0"
									alignItems="center"
									flexDirection="row"
									spacing={0.5}
								>
									<Typography
										fontWeight={600}
										className=" mr-1 "
										fontSize={14}
										color={theme.palette.primary.main}
									>
										{t('IDV')}
									</Typography>
									<Grid
										item
										container
										flexWrap="nowrap"
										alignItems="center"
										flexDirection="row"
										sm={6}
									>
										<Currency policyDetails={assetsData} amount={assetsData?.IDV ? assetsData?.IDV : 0} amountClass={"f-28 ml-4"} className="mb-2 " />
										{/* <Grid item className="h-100">
											<SvgConverter
												Icon={RupeeIcon}
												className="d-flex align-items-end mb-4"
											/>
										</Grid>
										<Grid item>
											<Typography
												fontWeight={300}
												fontSize={28}
												lineHeight={1}
												className="ml-4"
											>
												{assetsData?.IDV ? convertAmount(assetsData?.IDV) : '0'}
											</Typography>
										</Grid> */}
									</Grid>
									<Grid item sm={6} className="asset-res ">
										<Typography
											fontSize={14}
											className="ml-5 w-280px"
											color="primary"
										>
											@
											<span style={{ fontWeight: 500 }}>
												{assetsData?.depreciation}
											</span>
											% {t('DEPRECIATION')}
										</Typography>
									</Grid>
								</Grid>
								<Typography
									fontWeight={400}
									fontSize={14}
									lineHeight={1}
									className="ml-26"
								>
									{' '}
									<Typography component="span" fontSize={14} color="primary">
										{assetsData?.terms}
									</Typography>
								</Typography>
								<div className="mt-16">
									<>
										{assetsData?.countCashlessGarages.length !== 0 && (
											<Typography
												fontSize={28}
												fontWeight={300}
												color="secondary"
											>
												{assetsData?.countCashlessGarages}
												<Typography
													fontWeight={400}
													className=" mr-1 "
													fontSize={14}
													color="secondary"
												>
													{t('CASHLESS_GARAGES')}
												</Typography>
											</Typography>
										)}
									</>
									{assetsData?.areaCashlessGarages.length !== 0 && (
										<Typography fontSize={14} color="primary">
											{t('CASHLESS_GARAGES_IN')}{' '}
											{assetsData?.areaCashlessGarages.length === 0
												? '-'
												: assetsData?.areaCashlessGarages}{' '}
											{t('AREA')}
										</Typography>
									)}
								</div>
							</div>
						</Grid>
					</Grid>
				))}
		</DashboardCardLayout>
	);
};

export default AssetsScreen;
