import { FormHelperText, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../common/ui/CustomButton';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { ReactComponent as MobileIcon } from '../../../common/icons/mobile-icon.svg';
import { ReactComponent as PanCardIcon } from '../../../common/icons/pan_card_icon.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';
import { ReactComponent as LabelCalendarIcon } from '../../../common/icons/login_calendar_label-icon.svg';
import { ReactComponent as EmailIcon } from '../../../common/icons/login_email-icon.svg';
import { ReactComponent as GenderIcon } from '../../../common/icons/Signup_gender_icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import DatePickerModal from '../../../common/ui/DatePickerModal';
import data from '../../../common/constants/FormConstants.json';
import FormCheckBox from '../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import theme from '../../../common/theme';
import { selectIsSignUpLoading, signUpRequest } from '../auth.slice';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import './SignUp.css';
import config from '../../../common/config';
import { ageCalculator, countryCode } from '../../../common/utils';
import EiaModalDetails from './EiaModalDetails';
import NeedSupport from '../common/NeedSupport';
import { TermsCondition } from './TermsConditionModal';
import { Link } from 'react-router-dom';
import { APP_ROUTES, PUBLIC_ROUTES } from '../../../common/constants/Routes';
import TermModal from '../../App/Legal/termModal';
import PrivacyModal from '../../App/Legal/PrivacyModal';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';
import i18next from 'i18next';

const SignUp = () => {
	const dispatch = useDispatch();
	// const location = useLocation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		control,
		trigger,
	} = useForm({
		defaultValues: {
			mobileCountryCode: '+91',
			mobileCountryCodeRi: '+91',
			pan: '',
			mobileNo: '',
			dob: '',
			email: '',
			gender: '',
			whatsappNumber: '',
			whatsappCountryCode: '',
		},
	});

	const { t } = useTranslation();
	const GenderList = [
		{ Description: `${t('MALE')}`, Code: 'M' },
		{ Description: `${t('FEMALE')}`, Code: 'F' },
		{ Description: `${t('OTHERS')}`, Code: 'O' },
	];

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const datePicker = (e: any) => {
		setAnchorEl(e.currentTarget);
	};
	const isRequestLoading = useSelector(selectIsSignUpLoading);
	const [isCheckedIndian, setIsCheckedIndian] = useState(true);
	const [isCheckedNRI, setIsCheckedNRI] = useState(false);
	const [showEmailInput, setShowEmailInput] = useState(isCheckedNRI);
	const [showMobileInput, setShowMobileInput] = useState(!isCheckedNRI);
	const [confirmModal, setConfirmModal] = useState(false);
	const [inputType, setinputType] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [termsCondition, setTermsCondition] = useState(false);
	// const [mobilePin, setMobilePin] = useState('counterCode' as any);
	const [click, setClick] = useState('');
	const type = 'Email';

	const [open, setOpen] = React.useState<any>(false);
	const [openprivacy, setOpenprivacy] = React.useState<any>(false);

	// const handleModalOptionMobile = () => {
	// 	setConfirmModal(true);
	// 	setClick('mobile');
	// };
	// const handleModalOptionEmail = () => {
	// 	setConfirmModal(true);
	// 	setClick('email');
	// };
	useEffect(() => {
		setShowEmailInput(isCheckedNRI);
		setShowMobileInput(!isCheckedNRI);
	}, [isCheckedNRI]);
	const handleChangeIndian = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsCheckedIndian(event.target.checked);
		setIsCheckedNRI(false);
	};
	const handleChangeNRI = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsCheckedNRI(event.target.checked);
		setIsCheckedIndian(false);
	};

	const navigation = useNavigate();
	const onSubmit = (data: any) => {
		const {
			pan,
			dob,
			mobileNo,
			email,
			gender,
			whatsappCountryCode,
			whatsappNumber,
			// mobileCountryCode,
			// mobileCountryCodeRi,
		} = data;
		// const NRIData: any = isCheckedIndian
		// 	? {}
		// 	: { email, whatsappNumber, whatsappCountryCode };
		// const emails: any = {
		// 	email: '',
		// 	// counterCode: isCheckedNRI ? mobileCountryCode : mobileCountryCodeRi,
		// };

		if (!isChecked) {
			setTermsCondition(true);
		} else {
			dispatch(
				signUpRequest({
					Customer: {
						pan: pan.toUpperCase(),
						dob: `${dob}`,
						mobileNo: `${mobileNo}`,
						flag: isCheckedNRI ? 'NRI' : 'ResidentIndian',
						// countryCode: emails[mobilePin],
						country: isCheckedNRI ? 'NRI' : 'RI',
						lang: i18next.language,

						gender,
						email,

						whatsappNumber,
						whatsappCountryCode,
					},
					navigation,
					setClick,
					setConfirmModal,
					inputType,
				})
			);
		}
	};
	const handlePanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value
			.toUpperCase()
			.replace(/[^\w\s]/gi, '')
			.slice(0, 10);
		setValue('pan', newValue, { shouldValidate: true });
	};
	// const pan = watch('pan');
	const mobileNo = watch('mobileNo');
	const email = watch('email');
	const whatsappNumber = watch('whatsappNumber');
	// const mobileCountryCode = watch('mobileCountryCode');
	const mobileNumberRegex = /^[6789]\d{9,}$/;
	const dobValue = String(watch('dob')).split('/');
	const handleDateChange = (date: any) => {
		setValue('dob', date?.format('DD/MM/YYYY'), { shouldValidate: true });
	};
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('mobileNo', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);

	useEffect(() => {
		if (whatsappNumber) {
			setValue('whatsappNumber', parseMobileNo(whatsappNumber));
		}
	}, [whatsappNumber]);
	const panCardRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
	// const [selectedOption, setSelectedOption] = useState(null);
	const countryCodeRegex = /^\+\d{1,3}$/;
	const isMinor: any = useMemo(
		() =>
			(ageCalculator(`${dobValue[1]}/${dobValue[0]}/${dobValue[2]}`) as any) <
			18,
		[dobValue]
	);
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
	const handleContinueWithEmail = () => {
		// Set state to hide mobile input and show email input
		setShowEmailInput(true);
		setShowMobileInput(false);
		setConfirmModal(false);
		setValue('mobileNo', '');
		// setMobilePin('email');
	};
	const handleContinueWithMobile = () => {
		// Set state to hide mobile input and show email input
		setShowEmailInput(false);
		setShowMobileInput(true);
		setConfirmModal(false);
		setValue('email', '');
		// setMobilePin('counterCode');
	};
	const handleUseEmailClick = () => {
		setShowEmailInput(true);
		setShowMobileInput(false);
		setValue('mobileNo', '');
		setinputType('email');

		// setMobilePin('email');
	};
	const handleUseMobileClick = () => {
		setShowEmailInput(false);
		setShowMobileInput(true);
		setValue('email', '');

		setinputType('mobile');

		// setMobilePin('counterCode');
	};

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'SignUP Screen',
		});
	}, []);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				flexDirection="column"
				className="h-100 signup-form"
				flexWrap="nowrap"
				alignItems="center"
				justifyContent="center"
			>
				<Grid item className="mt-30" xs={10} sm={6} md={9} lg={7} xl={6}>
					<Grid item className="mt-30 mb-5">
						<IconButton onClick={() => navigation('/')} className="p-0">
							<SvgConverter Icon={ArrowLeftIcon} className="go-back-icon" />
						</IconButton>
					</Grid>
					<Grid container item flex={1} alignItems="center">
						<Grid item container className="w-100 mt-30">
							<Grid item xs={12} className="">
								<Typography className="f-20 fw-600">
									{t('ENTER_DETAILS_FOR_SIGN_UP')}
								</Typography>
							</Grid>
							<Grid item xs={12} className="py-10 mb-10">
								<Grid container className="ml-6">
									<Grid item xs={6}>
										<FormCheckBox
											sx={{
												color: 'primary.main',
												'&.Mui-checked': {
													color: 'primary',
												},
											}}
											label={
												<Typography
													className="f-14  "
													color={theme.palette.common.black}
												>
													{t('RESIDENT_INDIAN')}
												</Typography>
											}
											checked={isCheckedIndian}
											onChange={handleChangeIndian}
											size={16}
											checkBoxPadding={1}
											CheckBoxClassName="checkbox"
										/>
									</Grid>
									<Grid item xs={6} pl={5}>
										<FormCheckBox
											label={
												<Typography
													className="f-14  "
													color={theme.palette.common.black}
												>
													{t('NRI')}
												</Typography>
											}
											sx={{
												color: 'primary.main',
												'&.Mui-checked': {
													color: 'primary',
												},
											}}
											checked={isCheckedNRI}
											onChange={handleChangeNRI}
											size={16}
											checkBoxPadding={1}
											CheckBoxClassName="checkbox"
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								container
								xs={12}
								sm={12}
								lg={12}
								xl={12}
								md={12}
								columnSpacing={10}
							>
								<Grid
									item
									xs={12}
									sm={12}
									lg={6}
									xl={6}
									md={6}
									className="  mt-30 w-100"
								>
									<LabelWithIcon
										Icon={PanCardIcon}
										label={t('PAN_NUMBER')}
										justifyContent="flex-start"
										className="mb-5 f-16 "
									/>
									<FormInput
										type="text"
										className="mt-4"
										placeholder="ABCDF2340X"
										name="pan"
										inputProps={{
											...register('pan', {
												required: {
													value: true,
													message: t(data.PANCARD),
												},
												validate: (value: any) => {
													if (!panCardRegex.test(value)) {
														return t(data.INVALID_PAN);
													}
													return true;
												},
												minLength: {
													value: 10,
													message: t(data.INVALID_PAN),
												},
												maxLength: {
													value: 10,
													message: t(data.INVALID_PAN),
												},
												onChange: handlePanChange,
											}),
										}}
										errors={errors}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									lg={6}
									xl={6}
									md={6}
									className=" mt-20 w-100"
								>
									{showMobileInput && (
										<Grid item className="mb-20 w-100">
											{!isCheckedNRI && (
												<Grid
													display="flex"
													justifyContent="space-between"
													alignItems="center"
												>
													<Grid>
														<CustomButton
															text={t('MOBILE_NUMBER')}
															variant="text"
															showIcon={false}
															// onClick={() => handleModalOptionMobile()}
															startIcon={MobileIcon}
															className="fw-400 f-14 mb-5 mobile-signup-button"
														/>
													</Grid>
													<Grid>
														<CustomButton
															text={t('USE_EMAIL')}
															variant="text"
															showIcon={false}
															onClick={() => handleUseEmailClick()}
															className="f-14"
														/>
													</Grid>
												</Grid>
											)}
											{isCheckedNRI && (
												<Grid
													display="flex"
													justifyContent="space-between"
													alignItems="center"
												>
													<Grid>
														<CustomButton
															text={t('USE_INDIAN_MOBILE')}
															variant="text"
															showIcon={false}
															// onClick={() => setShowAddModal(true)}
															// onClick={() => handleModalOptionMobile()}
															startIcon={MobileIcon}
															className="fw-400 mobile-signup-button mb-5 no-wrap"
														/>
													</Grid>
													<Grid>
														<CustomButton
															text={t('USE_EMAIL')}
															variant="text"
															showIcon={false}
															onClick={() => handleUseEmailClick()}
															className="f-14 no-wrap px-20"
														/>
													</Grid>
												</Grid>
											)}
											<Grid columnSpacing={1} container flexWrap="nowrap">
												{isCheckedNRI ? (
													<>
														<Grid item>
															<FormInput
																inputProps={{
																	maxLength: 4,
																	...register('mobileCountryCode', {
																		required: {
																			value: true,
																			message: t('COUNTRY_CODE_IS_REQUIRED'),
																		},
																		validate: (value: any) => {
																			if (
																				!countryCodeRegex.test(value) ||
																				parseInt(value) === 0
																			) {
																				return t(data.INVALID_VALUE);
																			}
																			return true;
																		},
																	}),
																}}
																name="mobileCountryCode"
																placeholder="+91"
																hideCountButton
																className="mobile-pin"
																errors={errors}
																readOnly
															/>
														</Grid>
													</>
												) : (
													<Grid item>
														<FormInput
															inputProps={{
																maxLength: 4,
																...register('mobileCountryCodeRi', {
																	required: {
																		value: true,
																		message: t('COUNTRY_CODE_IS_REQUIRED'),
																	},
																	validate: (value: any) => {
																		if (
																			!countryCodeRegex.test(value) ||
																			parseInt(value) === 0
																		) {
																			return t(data.INVALID_VALUE);
																		}
																		return true;
																	},
																}),
															}}
															name="mobileCountryCodeRi"
															placeholder="+91"
															hideCountButton
															className="mobile-pin"
															readOnly
															errors={errors}
														/>
													</Grid>
												)}
												<Grid item flex={1}>
													<FormInput
														// type="number"
														name="mobileNo"
														placeholder="1234567890"
														hideCountButton
														control={control}
														rules={{
															required: t(data.MOBILENUMBER),
															// maxLength: {
															// 	value: 10,
															// 	message: data.MOBILEDIGIT,
															// },
															pattern: {
																value: mobileNumberRegex,
																message: t('INVALID_MOBILE_NUMBER'),
															},
															onChange: (e: any) => {
																setValue('mobileNo', e.target.value, {
																	shouldValidate: true,
																});
															},
														}}
														errors={errors}
													/>
												</Grid>
											</Grid>
										</Grid>
									)}
									{showEmailInput && (
										<>
											<Grid
												item
												xs={12}
												className="mt-5 ml-5"
												display="flex"
												justifyContent="space-between"
											>
												<Grid>
													<CustomButton
														text={t('EMAIL')}
														variant="text"
														showIcon={false}
														// onClick={() => handleModalOptionEmail()}
														startIcon={EmailIcon}
														className="fw-400 email-sign-up-button-text"
													/>
												</Grid>
												<Grid>
													<CustomButton
														text={`${t('USE_MOBILE')} #`}
														variant="text"
														showIcon={false}
														onClick={() => handleUseMobileClick()}
														className="f-14"
													/>
												</Grid>
											</Grid>
											<Grid
												container
												flexWrap="nowrap"
												spacing={1}
												className="py-10"
											>
												<Grid item flex={1}>
													<FormInput
														type="email"
														placeholder="email@website.com"
														name="email"
														control={control}
														inputProps={{
															maxLength: config.emailLength,
															className: 'px-10',
															...register('email', {
																required: true,
																onChange: handleEmailChange,
																pattern: {
																	value:
																		/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
																	message: t('INVALID_EMAIL_ADDRESS'),
																},
															}),
														}}
														errors={errors}
													/>
												</Grid>
											</Grid>
										</>
									)}
								</Grid>
							</Grid>

							{isCheckedNRI && (
								<Grid item className="mb-0 w-100" container>
									<Grid item xs={12} display="flex">
										<LabelWithIcon
											label={t('WHATSAPP_NUMBER')}
											// onClick={() => setShowAddModal(true)}
											Icon={MobileIcon}
											className="f-14 fw-400 mb-5 "
											justifyContent="flex-start"
											optional={`${t('OPTIONAL')},`}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										columnSpacing={1}
										container
										flexWrap="nowrap"
									>
										<Grid item>
											<FormInput
												inputProps={{
													maxLength: 4,
													...register('whatsappCountryCode', {
														// validate: (value: any) => {
														// 	if (
														// 		!countryCodeRegex.test(value) ||
														// 		parseInt(value) === 0
														// 	) {
														// 		return data.INVALID_VALUE;
														// 	}
														// 	return true;
														// },
													}),
													onChange: (e: any) => {
														setValue(
															'whatsappCountryCode',
															countryCode(e.target.value)
														);
													},
												}}
												rules={{
													pattern: {
														value: countryCodeRegex,
														message: t('INVALID_COUNTRY_CODE'),
													},
												}}
												name="whatsappCountryCode"
												placeholder="+00"
												hideCountButton
												className="mobile-pin"
												errors={errors}
											/>
										</Grid>
										<Grid item flex={1}>
											<FormInput
												// type="number"
												name="whatsappNumber"
												placeholder="1234567890"
												hideCountButton
												control={control}
												rules={{
													// required: data.MOBILENUMBER,
													// maxLength: {
													// 	value: 10,
													// 	message: data.MOBILEDIGIT,
													// },
													pattern: {
														value: mobileNumberRegex,
														message: t('INVALID_MOBILE_NUMBER'),
													},
													onChange: (e: any) => {
														setValue('whatsappNumber', e.target.value, {
															shouldValidate: true,
														});
													},
												}}
												errors={errors}
											/>
											<Grid item xs={12}>
												<Typography color="#aaaaaa">
													{`...${t('BUT_HIGHLY_RECOMMENDED')}..`}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)}
							<Grid item container xs={12} columnSpacing={10}>
								<Grid
									item
									xs={12}
									sm={12}
									lg={6}
									xl={6}
									md={6}
									className=" mt-30 w-100"
								>
									<Grid container item xs={12} flexDirection="column">
										<Grid item className="my-3">
											<LabelWithIcon
												Icon={LabelCalendarIcon}
												label={t('DATE_OF_BIRTH')}
												iconProps={{ height: 33 }}
												justifyContent="flex-start"
											/>
										</Grid>
										<Grid
											item
											container
											flexDirection="row"
											alignItems="center"
											flexWrap="nowrap"
											columnSpacing={1}
											justifyContent="space-between"
										>
											<Grid item flex={3}>
												<FormInput
													name="date"
													inputProps={{
														maxLength: 2,
														style: { textAlign: 'center' },
														value: dobValue?.[0],
													}}
													placeholder={t('DD')}
													readOnly
													className="date-month-input"
													onClick={datePicker}
												/>
											</Grid>
											<Grid item flex={3}>
												<FormInput
													name="month"
													inputProps={{
														maxLength: 2,
														style: { textAlign: 'center' },
														value: dobValue?.[1],
													}}
													placeholder={t('MM')}
													readOnly
													className="date-month-input"
													onClick={datePicker}
												/>
											</Grid>
											<Grid item flex={4}>
												<FormInput
													name="year"
													inputProps={{
														maxLength: 4,
														style: { textAlign: 'center' },
														value: dobValue?.[2],
													}}
													placeholder={t('YYYY')}
													readOnly
													className="signup-year-input"
													onClick={datePicker}
												/>
											</Grid>
											<Grid item>
												<DatePickerModal
													iconType="primary"
													disableFuture
													onChange={handleDateChange}
													value={
														dobValue.length === 3
															? `${dobValue[0]}/${dobValue[1]}/${dobValue[2]}`
															: false
													}
													modalOpen={anchorEl}
													setModalOpen={setAnchorEl}
												/>
											</Grid>
										</Grid>
										<FormInput
											hidden
											name="dob"
											inputProps={{
												...register('dob', {
													required: {
														value: true,
														message: t(data.DOB),
													},
												}),
											}}
											errors={
												isMinor && dobValue?.length > 1
													? { dob: { message: t('AGE_IS_BELOW_18_YEARS') } }
													: errors
											}
										/>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									lg={6}
									xl={6}
									md={6}
									className="  mt-10 w-100"
								>
									<Grid item className="my-20 w-100">
										<LabelWithIcon
											Icon={GenderIcon}
											label={t('GENDER')}
											justifyContent="flex-start"
											className="mb-5"
										/>
										<Controller
											name="gender"
											control={control}
											rules={{
												required: {
													value: true,
													message: t('PLEASE_SELECT_A_GENDER'),
												},
											}}
											render={({ field: { onChange, value } }: any) => (
												<CustomSelectBox
													ClassName="w-100 relationship-selectbox"
													options={GenderList}
													setSelectedOption={(gender: any) => onChange(gender)}
													defaultValue={value}
												/>
											)}
										/>
										{errors?.gender?.message && (
											<FormHelperText
												className="error-text"
												error={false}
												sx={{ color: theme.palette.error.dark }}
											>
												{errors?.gender?.message}
											</FormHelperText>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container justifyContent={'center'} className="my-30">
							<FormCheckBox
								label={
									<>
										<Grid
											container
											alignItems={'center'}
											onClick={(e: any) => {
												e.stopPropagation();
											}}
										>
											<Typography className="f-14 fw-400">
											&nbsp;{t('AGREE_TO')}&nbsp;
											</Typography>
											{/* <Link to={PUBLIC_ROUTES.PRIVACYPOLICY} target="_blank"> */}
											<Typography
												className="f-14 fw-600"
												style={{
													color: '#0000FF'
												}}
												onClick={(e: any) => { setOpenprivacy(true); e.preventDefault(); }}
											>
												{t('PRIVACY_POLICY_SMALL')}
											</Typography>
											{/* </Link> */}
											&nbsp;
											<Typography className="f-14 fw-400">
												{`&`}&nbsp;
											</Typography>
											{/* <Link to={PUBLIC_ROUTES.TERM_MODAL} target="_blank"  > */}
											<Typography
												className="f-14 fw-600 "
												style={{
													color: '#0000FF'
												}}
												onClick={(e: any) => { setOpen(true); e.preventDefault(); }}
											>
												{t('TERMS_OF_USE_SMALL')}
											</Typography>
											{/* </Link> */}
										</Grid>
									</>
								}
								checked={isChecked}
								onChange={() => {
									isChecked ? setIsChecked(false) : setIsChecked(true);
								}}
								size={20}
								checkBoxPadding={1}
							/>
						</Grid>

						<Grid item container justifyContent="center" className="my-50">
							<CustomButton
								text={t('PROCEED')}
								variant="text"
								color="primary"
								type="submit"
								fontSize={20}
								loading={isRequestLoading}
								fontWeight={600}
								disabled={isMinor && dobValue?.length > 1}
							/>
						</Grid>
					</Grid>
					<NeedSupport type="black" />
				</Grid>
			</Grid>
			<EiaModalDetails
				open={confirmModal}
				type={type}
				onContinueWithEmail={handleContinueWithEmail}
				onContinueWithMobile={handleContinueWithMobile}
				mobile={mobileNo}
				email={email}
				click={click}
			/>
			<TermsCondition
				open={termsCondition}
				setOpen={setTermsCondition}
				setIsChecked={setIsChecked}
			/>

			<TermModal open={open} setOpen={setOpen} />

			<PrivacyModal open={openprivacy} setOpen={setOpenprivacy} />
		</form>
	);
};
export default SignUp;
