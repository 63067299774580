import { Grid } from '@mui/material';
import React from 'react';
import '../../Policy.scss';
import ArchiveLifeCard from '../ArchiveContent/ArchiveLifeCard';

interface UserProps {
	filteredLife?: any;
}
const ArchiveLife: React.FC<UserProps> = ({ filteredLife }) => (
	<Grid container justifyContent="row">
		{filteredLife.map((data: any, index: any) => (
			<Grid item xs={12} sm={6} lg={4} key={index} className="mb-20">
				<ArchiveLifeCard contentType="archive" items={data} />
			</Grid>
		))}
	</Grid>
);

export default ArchiveLife;
