import React from 'react';
import '../../TrackTransaction.scss';
import { useTranslation } from 'react-i18next';
import { convertAmount } from '../../../../../../common/utils';
import TrackerCards from '../TrackerCards';
import { ReactComponent as WithdrawalIcon } from '../../../../../../common/icons/LoanModule/withdrawalIcon.svg';

interface UserProps {
	step?: any;
	widgetNo?: any;
}

const StepOne = ({ step, widgetNo }: UserProps) => {
	const { t } = useTranslation();

	return (
		<TrackerCards
			step={step}
			header="Surrender"
			head="Surrender Policy"
			icon={WithdrawalIcon}
			productLogo={step?.insurerLogo}
			widgetNo={widgetNo}
			productName={step?.planName}
			label1={t('MATURITY_VALUE')}
			label10={t('SURRENDER_VALUE')}
			selfName={step?.payerName}
			label1Value={convertAmount(step?.maturityValue)}
			lable10Value={convertAmount(step?.Surrender?.surrenderValue)}
			estimatedProcessingTimeHrs={step?.estimatedProcessingTimeHrs}
			elaspsedTimeHrsMins={step?.elaspsedTimeHrsMins}
		/>
	);
};

export default StepOne;
