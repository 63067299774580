import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Grid, Typography, useTheme } from '@mui/material';
import { ReactComponent as RupeeIcon } from '../../../../common/icons/LoanModule/rupee-icon.svg';
import SvgConverter from '../../../../common/SvgConverter';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import '../Policy.scss';
import { parseMobileNo } from '../../../../common/utils';

import data from '../../../../common/constants/FormConstants.json';

interface Props {
	inputValue?: any;
	control?: any;
	error?: any;
	name?: any;
	setValue?: any;
	loanPercentage?:any;
}
const FormInputLoanAgainst = ({
	inputValue,
	control,
	error,
	name,
	setValue,
	loanPercentage,
}: Props) => {
	const theme = useTheme();
	const moneyValueRegex = /^[0-9]{1,20}$/;
	const money = useWatch({
		control,
		name,
	});
	useEffect(() => {
		if (money) {
			setValue(name, parseMobileNo(money as any));
		}
	}, [money]);
	return (
		<Grid container alignItems="center" spacing={1}>
			<Grid item className="mt-7">
				<SvgConverter Icon={RupeeIcon} />
			</Grid>

			<Grid item>
				<Typography
					className="f-10 mb-2"
					color={theme.palette.grey.A700}
					display="flex"
					alignItems="center"
				>
					[Max % allowed:
					<Typography
						className="f-10 fw-600 ml-2"
						color={theme.palette.grey.A700}
						justifyContent="center"
					>
						{loanPercentage}
					</Typography>
					&nbsp; of fund value]
				</Typography>
				<FormInput
					name={name}
					placeholder={inputValue}
					rules={{
						required: data.MONEY,
						pattern: {
							value: moneyValueRegex,
							message: 'Invalid Amount',
						},
						max: {
							value: inputValue,
							message: `Enter the amount below ${inputValue}`,
						},
						// min :{
						// 	value: 1,
						// 	message: 'Amount must be greater than 0',
						// },
						onChange: (e: any) => {
							setValue(name, e.target.value, { shouldValidate: true });
						},
					}}
					defaultValue={inputValue}
					control={control}
					errors={error}
					className="form-input-loan-policy"
				/>
				{/* <FormInput
					type="text"
					placeholder="XXXXXX"
					className="form-input-loan-policy"
				/> */}
			</Grid>
		</Grid>
	);
};

export default FormInputLoanAgainst;
