import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import data from '../../../../common/constants/FormConstants.json';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import { ReactComponent as cancel } from '../../../../common/icons/cancel-icon.svg';
import { ReactComponent as correct } from '../../../../common/icons/tick-icon.svg';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import config from '../../../../common/config';

const BankEntryModal = ({ open, setOpen, onSubmit, loading }: any) => {
	const theme = useTheme();
	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		watch,
		setValue,
		trigger,
		register,
	} = useForm({
		defaultValues: {
			accountNumber: '',
			retypeAccountNo: '',
			accountHolderName: '',
			ifscCode: '',
		},
		// defaultValues: {
		//  accountNumber: '50100291498474',
		//  retypeAccountNo: '50100291498474',
		//  accountHolderName: 'S HEMA KUMAR',
		//  ifscCode: 'HDFC0003631',
		// },
	});
	// const [star, setStar] = useState('' as any);
	// const [isActive, setIsActive] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const { t } = useTranslation();
	const validateRetypeAccountNo = (value: any) => {
		const accountNo = watch('accountNumber');
		return value === accountNo || t('ACCOUNT_NUMBER_DONOT_MATCH');
	};
	const handleRetypeAccountNumberChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const accountNumberValue = e.target.value
			.replace(/[^\d]/g, '')
			.slice(0, 20);
		setValue('retypeAccountNo', accountNumberValue, { shouldValidate: true });
		trigger('retypeAccountNo');
	};

	const handleAccountNumberChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const accountNumberValue = e.target.value
			.replace(/[^\d]/g, '')
			.slice(0, 20);
		// const stars = Array(accountNumberValue.length).fill('*').join('');
		// setStar(stars);
		// setIsActive(accountNumberValue.length !== 0);
		setValue('accountNumber', accountNumberValue, { shouldValidate: true });
		setShowPassword(true);
		trigger('accountNumber');
		setTimeout(() => {
			setShowPassword(false);
		}, 1000);
	};
	// const handleAccountHolderNameChange = (
	//  e: React.ChangeEvent<HTMLInputElement>
	// ) => {
	//  const accountHolderNameValue = e.target.value.replace(/[^a-zA-Z]/g, '');
	//  setValue('accountHolderName', accountHolderNameValue, {
	//      shouldValidate: true,
	//  });
	//  trigger('accountHolderName');
	// };
	const handleCopyPastCut = (e: any) => {
		e.preventDefault();
		return false;
	};
	const handleIFSCCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const ifscCodeValue = e.target.value
			.toUpperCase()
			.replace(/[^A-Z0-9]/g, '');
		setValue('ifscCode', ifscCodeValue, { shouldValidate: true });
		trigger('ifscCode');
	};
	useEffect(() => {
		if (!open) {
			reset();
		}
	}, [open]);
	const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let inputValue = e.target.value;
		// Allow only alphabets and spaces
		inputValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		// Ensure the first character is an alphabet
		inputValue = inputValue.replace(/^[^a-zA-Z]*/, '');
		// Replace multiple spaces with a single space
		inputValue = inputValue.replace(/\s{2,}/g, ' ');
		setValue('accountHolderName', inputValue, { shouldValidate: true });
	};

	return (
		<CommonModal
	     onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container justifyContent="center">
					<Grid item className="assign-policy">
						<Grid item className="mb-25">
							<Typography
								className="f-16 fw-600"
								color={theme.palette.primary.main}
								textAlign="center"
							>
								{t('ENTER_BANK_ACCOUNT_DETAILS')}
							</Typography>
						</Grid>
						<Grid item className="mt-10 mb-5">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('ACCOUNT_HOLDER_NAME')}
							</Typography>
						</Grid>
						<Grid item className="mb-15">
							{/* <FormInput
                                type="text"
                                placeholder="Account Holder Name"
                                rules={{
                                    required: {
                                        value: true,
                                        message: ' Account Holder Name is required',
                                    },
                                    validate: (value: any) => {
                                        if (!config.alphabetsOnlyRegex.test(value)) {
                                            return data.INVALID_NAME;
                                        }
                                        return true;
                                    },
                                    onChange: handleAccountHolderNameChange,
                                }}
                                errors={errors}
                                control={control}
                                name="accountHolderName"
                            /> */}
							<FormInput
								type="text"
								placeholder={t('ACCOUNT_HOLDER_NAME')}
								name="accountHolderName"
								rules={{
									required: {
										value: true,
										message: `${t('ACCOUNT_HOLDER_NAME_REQUIRED')}`,
									},

									validate: (value: any) => {
										if (!config.alphabetsOnlyRegex.test(value)) {
											return t(data.INVALID_NAME);
										}
										const trimmedValue = value.trim();
										return (
											trimmedValue.length > 0 || t('ACCOUNT_HOLDER_EMPTY_SPACE')
										);
									},
								}}
								inputProps={{
									className: 'px-10',
									...register('accountHolderName', {
										onChange: nameChange,
									}),
								}}
								control={control}
								errors={errors}
							/>
							<span />
						</Grid>
						<Grid item className="mt-10 mb-5">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('ACCOUNT_NUMBER')}
							</Typography>
						</Grid>
						<Grid item className="mb-15" style={{ position: 'relative' }}>
							<FormInput
								// style={{
								//  opacity: isActive ? '0.5' : '',
								//  letterSpacing: '3.1px',
								// }}

								type={showPassword ? 'text' : 'password'}
								placeholder={t('ACCOUNT_NUMBER')}
								rules={{
									required: {
										value: true,
										message: t('ACCOUNT_NUMBER_IS_REQUIRED'),
									},
									minLength: {
										value: 6,
										message: t('INVALID_ACCOUNT_NUMBER'),
									},

									onChange: handleAccountNumberChange,
								}}
								errors={errors}
								control={control}
								name="accountNumber"
								handleCopy={handleCopyPastCut}
								handlePast={handleCopyPastCut}
								handleCut={handleCopyPastCut}
								autocomplete="new-password"
							/>
							{/* <span
                                style={{
                                    position: 'absolute',
                                    top: '12px',
                                    left: '14px',
                                    overflow: 'hidden',
                                }}
                                className="f-24 fw-600"
                            >
                                {star}
                            </span> */}
						</Grid>
						<Grid item className="mt-10 mb-5">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('RE-ENTER_ACCOUNT_NUMBER')}
							</Typography>
						</Grid>
						<Grid item className="mb-15">
							<FormInput
								type="text"
								placeholder={t('RE-ENTER_ACCOUNT_NUMBER')}
								rules={{
									required: {
										value: true,
										message: t('RE-ENTER_ACCOUNT_NUMBER_REQUIRED'),
									},
									validate: validateRetypeAccountNo,
									onChange: handleRetypeAccountNumberChange,
								}}
								errors={errors}
								control={control}
								name="retypeAccountNo"
								handleCopy={handleCopyPastCut}
								handlePast={handleCopyPastCut}
								handleCut={handleCopyPastCut}
							/>
						</Grid>
						<Grid item className="mt-10 mb-5">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('IFSC_CODE')}
							</Typography>
						</Grid>
						<Grid item className="mb-15">
							<FormInput
								type="text"
								placeholder={t('IFSC_CODE')}
								inputProps={{ maxLength: 11 }}
								rules={{
									required: {
										value: true,
										message: t('IFSC_CODE_REQUIRED'),
									},
									validate: (value: any) => {
										if (!config.ifscRegex.test(value)) {
											return t(data.INVALID_IFSC);
										}
										return true;
									},
									onChange: handleIFSCCodeChange,
								}}
								errors={errors}
								control={control}
								name="ifscCode"
							/>
						</Grid>
						<Grid
							container
							justifyContent="space-around"
							spacing={1}
							className="mt-30"
						>
							<Grid item>
								<CustomButton
									type="submit"
									className="f-14"
									startIcon={correct}
									showIcon={false}
									text={t('SEARCH')}
									color="primary"
									variant="text"
									fontSize={16}
									fontWeight={600}
									loading={loading}
								/>
							</Grid>
							<Grid item>
								<CustomButton
									text={t('CANCEL')}
									variant="text"
									color="primary"
									startIcon={cancel}
									showIcon={false}
									fontWeight={400}
									fontSize={14}
									onClick={() => setOpen(false)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</CommonModal>
	);
};
export default BankEntryModal;
