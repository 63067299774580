import React, { useMemo, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from '../../../common/icons/left_arrow-icon.svg';

import SvgConverter from '../../../common/SvgConverter';
import CustomButton from '../../../common/ui/CustomButton';
import EiaPortingModal from './EiaPortingModal';
import Separator from '../../../common/ui/Separator';
import theme from '../../../common/theme';
import { savePortingDetailsRequest } from '../auth.slice';
import { useDispatch } from 'react-redux';

const CaseTwoScreen = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const otherRepoData = useMemo(
		() => location?.state?.othersData,
		[location?.state]
	);


	const submit = () => {
		const data = {
			Customer: {
				flag: "RI",
				gender: otherRepoData?.gender,
				dob: otherRepoData?.dob,
				mobileNo: otherRepoData?.mobileNo,
				pan: otherRepoData?.pan,
				customerName: otherRepoData?.customerName,
				email: otherRepoData?.email,
				repoName: otherRepoData?.repoName,
				repoCode: otherRepoData?.repoCode,
				eiaNo: otherRepoData?.eiaNo
			}
		}
		dispatch(savePortingDetailsRequest(data))
		setOpen(true)
	}

	return (
		<Grid container justifyContent="center">
			<Grid
				item
				container
				style={{
					width: '415px',
				}}
				className="p-10"
			>
				<Grid item container xs={10}>
					<Grid item xs={12} className=" mt-30 mb-5">
						<IconButton className="p-0" onClick={() => navigate(-1)}>
							<SvgConverter Icon={ArrowLeftIcon} className="go-back-icon" />
						</IconButton>
					</Grid>
					<Grid item xs={12} className="mt-10 ml-30">
						<Grid item className="mt-30">
							<Typography className="f-18 fw-600">
								{t('YOUR_EIA_ACCOUNT_IS_WITH')}
							</Typography>
							<Typography className="f-18 fw-600">
								{otherRepoData?.repositoryName}.
							</Typography>
						</Grid>
						<Grid item className="mt-20 mb-30 ">
							<Typography className="f-18 fw-600 ">
								{t('TO_CONTINUE_WITH_BIMA_CENTRAL')}{' '}
								{otherRepoData?.repositoryName} {t('TO_CAMS_REPOSITORY')}
							</Typography>
						</Grid>
						<Grid container justifyContent="center" className="mt-20">
							<Separator color={theme.palette.grey[300]} borderWidth={1} />
						</Grid>
						<Grid
							item
							className="mt-30  ml-30"
							display="flex"
							justifyContent="end"
							justifyItems="end"
						>
							<CustomButton
								text={t('INITIATE_PORTING_OF_eIA_ACCOUNT')}
								variant="text"
								color="primary"
								type="submit"
								className="f-16 fw-600"
								textAlign="right"
								onClick={submit}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<EiaPortingModal open={open} setOpen={setOpen} otherRepoData={otherRepoData} />
		</Grid>
	);
};

export default CaseTwoScreen;
