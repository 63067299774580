import { Grid } from '@mui/material';
import AddFamilyMember from './AddFamilyMember/AddFamilyMember';
import CommonModal from '../../../common/ui/Modal/CommonModal';

const EditMemberModal = ({ open, setOpen, memberData, familyListData, mergeInfo }: any) => {
    return (
        <>
            <CommonModal
                // onClose={() => setOpen(false)}
                open={open}
                modalClassName="px-5 py-20"
                boxProps={{ width: 400 }}
            >
                <Grid
                    container
                    justifyContent="center"
                    style={{ maxHeight: '100vh', overflowY: 'auto' }}

                >
                    <AddFamilyMember isEdit={true} memberData={memberData} familyListData={familyListData} closeMember={setOpen} mergeInfo={mergeInfo} />
                </Grid>
            </CommonModal>
        </>
    );
};

export default EditMemberModal;