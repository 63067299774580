import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import Separator from '../../../../../../common/ui/Separator';
import { getFormatDate } from '../../../../../../common/utils';

interface PaymentProps {
	lifeDetails?: any;
}
const Payment: React.FC<PaymentProps> = ({ lifeDetails }) => {
	const formattedPremiumDate =
		lifeDetails && lifeDetails?.lastPremiumDate
			? getFormatDate(lifeDetails && lifeDetails?.lastPremiumDate, 'YYYY-MM-DD')
			: '-';
	const formattedPreviousPremiumDate =
		lifeDetails && lifeDetails?.previousPremiumPaidOn
			? getFormatDate(
					lifeDetails && lifeDetails?.previousPremiumPaidOn,
					'YYYY-MM-DD'
			  )
			: '-';
	const nextPremiumDate =
		lifeDetails && lifeDetails?.nextPremiumDueDate
			? getFormatDate(
					lifeDetails && lifeDetails?.nextPremiumDueDate,
					'YYYY-MM-DD'
			  )
			: '-';
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid item xs={4.5}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('PAYMENT_TERM')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={18}
					className="mb-10"
				>
					{lifeDetails?.paymentTerm?.length === 0
						? '-'
						: lifeDetails?.paymentTerm}
					&nbsp;{t('YEARS')}
				</Typography>
			</Grid>
			<Grid item xs={5}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('LAST_PREMIUM_DATE')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={18}
					className="mb-10"
				>
					{formattedPremiumDate === 'Invalid Date' ? '-' : formattedPremiumDate}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('MODE')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={18}
					className="mb-10"
				>
					{lifeDetails?.mode?.length === 0 ? '-' : lifeDetails?.mode}
				</Typography>
			</Grid>{' '}
			<Grid container item xs={12} className="py-10 ">
				<Separator color={theme.palette.grey[400]} />
			</Grid>{' '}
			<Grid item xs={12}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('NEXT_PREMIUM_DUE_DATE')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={18}
					className="mb-10"
				>
					{nextPremiumDate === 'Invalid Date' ? '-' : nextPremiumDate}
				</Typography>
			</Grid>{' '}
			<Grid item xs={12}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('PREVIOUS_PREMIUM_PAID_ON')}
				</Typography>
				<Typography
					color={theme.palette.common.black}
					fontSize={18}
					className="mb-10"
				>
					{formattedPreviousPremiumDate === 'Invalid Date'
						? '-'
						: formattedPreviousPremiumDate}
				</Typography>
			</Grid>{' '}
			<Grid container item xs={12} className="py-10  separator-large ">
				<Separator color={theme.palette.grey[400]} />
			</Grid>
		</Grid>
	);
};

export default Payment;
