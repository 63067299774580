import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Grid,
	Typography,
	Box,
	useTheme,
	Card,
	CardContent,
} from '@mui/material';
import CustomButton from '../../../../common/ui/CustomButton';

import { ReactComponent as rightArrow } from '../../../../common/icons/right_arrow-icon.svg';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as AlertIconRed } from '../../../../common/icons/warningRed.svg.svg';
import FormCheckBox from '../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import { ReactComponent as AlertIcon } from '../../../../common/icons/ekyc_detailsone_alert_icon.svg';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import {
	addFamilyMemberRequest,
	fetchUserFamilyListAction,
	selectFamilyListIsLoading,
	selectUserFamilyList,
} from '../Profile.slice';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../common/ui/Spinner';
import CustomCheckbox from '../../LoanModule/CustomCheckbox';
import { selectIsUserLoggedIn } from '../../../Auth/auth.slice';
import dayjs from 'dayjs';
import { ReactComponent as warningGrey } from '../../../../common/icons/warningGrey.svg';

const MergeInformation = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const dispatch = useDispatch();
	const familyList = useSelector(selectUserFamilyList);
	const isProfileLoading = useSelector(selectFamilyListIsLoading);
	const userInfo = useSelector(selectIsUserLoggedIn);
	const newData = location.state.data;
	const view = location.state.view;
	const mName = newData?.firstName + newData?.middleName + newData?.lastName;
	const mDate = dayjs(newData?.dob, 'DD/MM/YYYY').format('DD-MM-YYYY');
	// console.log(view)
	const currentData = useMemo(() => {
		return view === 'fatherLogic'
			? familyList?.filter(
					(item: any) =>
						item?.relationCode === newData?.relationCode &&
						item.seqId != newData.seqId
			  )[0]
			: familyList?.filter(
					(d: any) =>
						d.relationCode === newData.relationCode &&
						(d.firstName + d.middleName + d.lastName)
							.replace(/\s/g, '')
							.toLowerCase() === mName.replace(/\s/g, '').toLowerCase() &&
						d.dob === mDate
			  )[0];
	}, [familyList]);
	// console.log(newData,familyList)
	// console.log(currentData);
	// const filterMemberData = familyListData.filter((d: any) => d.relationCode == memberData.relationCode && d.seqId != memberData.seqId);

	const navigation = useNavigate();
	const [checkState, setCheckState] = useState({
		name: '',
		mobileno: '',
		email: '',
		address: '',
	});

	useEffect(() => {
		if (familyList.length <= 0) {
			dispatch(fetchUserFamilyListAction());
		}
	}, []);

	const currentState = useMemo(() => {
		return {
			name: `${currentData?.firstName} ${currentData?.middleName} ${currentData?.lastName}`,
			mobileno: currentData?.mobNumber,
			email: currentData?.email,
			address: `${currentData?.address1}, ${currentData?.address2}, ${currentData?.city}, ${currentData?.address3}, ${currentData?.state}-${currentData?.pin}`,
		};
	}, [currentData]);

	const newState = {
		name: `${newData?.firstName} ${newData?.middleName} ${newData?.lastName}`,
		mobileno: newData?.mobNumber,
		email: newData?.email,
		address: `${newData?.address1}, ${newData?.address2}, ${newData?.city}, ${newData?.address3}, ${newData?.state}-${newData?.pin}`,
	};

	useEffect(() => {
		setCheckState({ name: '', mobileno: '', email: '', address: '' });
		const isEmptyName =
			currentState?.name === '' && newState?.name === ''
				? { name: 'both' }
				: {};
		const isEmptyMobile =
			currentState?.mobileno === '' && newState?.mobileno === ''
				? { mobileno: 'both' }
				: {};
		const isEmptyEmail =
			currentState?.email === '' && newState?.email === ''
				? { email: 'both' }
				: {};
		const isEmptyAddress =
			currentState.address.length <= 9 && newState.address.length <= 9
				? { address: 'both' }
				: {};

		setCheckState({
			...checkState,
			...isEmptyName,
			...isEmptyMobile,
			...isEmptyEmail,
			...isEmptyAddress,
		});
	}, [currentState]);

	const onSubmit = () => {
		const handleSuccess = () => {
			navigation(APP_ROUTES.FAMILYDETAILS);
		};

		const payload = {
			FamilyMember: {
				flag: 'CHANGE',
				relationCode: newData.relationCode,
				eiaNoFamily: userInfo?.eiaNo,
				firstName:
					checkState?.name === 'current'
						? currentData?.firstName
						: checkState?.name === 'new'
						? newData?.firstName
						: '',
				middleName:
					checkState?.name === 'current'
						? currentData?.middleName
						: checkState?.name === 'new'
						? newData?.middleName
						: '',
				lastName:
					checkState?.name === 'current'
						? currentData?.lastName
						: checkState?.name === 'new'
						? newData?.lastName
						: '',
				imageBase64: newData?.imageBase64 || '',
				email:
					checkState.email === 'current'
						? currentData?.email
						: checkState?.email === 'new'
						? newData?.email
						: '',
				mobCountryCode: newData.mobCountryCode || '',
				mobNumber:
					checkState.mobileno === 'current'
						? currentData?.mobNumber
						: checkState?.mobileno === 'new'
						? newData?.mobNumber
						: '',
				mobNoVerified: 'N',
				emailVerified: 'N',
				dob: dayjs(newData.dob, 'DD/MM/YYYY').format('DD-MM-YYYY'),
				address1:
					checkState.address === 'current'
						? currentData?.address1
						: checkState?.address === 'new'
						? newData?.address1
						: '',
				address2:
					checkState.address === 'current'
						? currentData?.address2
						: checkState?.address === 'new'
						? newData?.address2
						: '',
				address3:
					checkState.address === 'current'
						? currentData?.address3
						: checkState?.address === 'new'
						? newData?.address3
						: '',
				city:
					checkState.address === 'current'
						? currentData?.city
						: checkState?.address === 'new'
						? newData?.city
						: '',
				state:
					checkState.address === 'current'
						? currentData?.state
						: checkState?.address === 'new'
						? newData?.state
						: '',
				country:
					checkState.address === 'current'
						? currentData?.country
						: checkState?.address === 'new'
						? newData?.country
						: '',

				pin:
					checkState.address === 'current'
						? currentData?.pin
						: checkState?.address === 'new'
						? newData?.pin
						: '',
				authorizedPerson: newData?.authorizedPerson,
				addressSameAsLifeAssured: newData?.addressSameAsLifeAssured,
				seqId: newData?.seqId,
				alternateCode: newData?.alternateCode,
				familyFlag: newData?.flag || '',
				family: newData?.familyFlag || '',
				nominee: newData?.nomineeFlag || '',
				nomineeRef: newData?.nomineeRef || '',
				nomineeName: newData?.originalName,
				nomineeDob:
					newData?.originalDob !== ''
						? dayjs(newData?.originalDob, 'DD/MM/YYYY').format('DD-MM-YYYY')
						: '',
				nomineeRelation:
					newData?.originalRelationCode === '-1'
						? ''
						: newData?.originalRelationCode,
				mergeRelationshipFlag: 'Y',
			},
			handleSuccess,
		};

		dispatch(addFamilyMemberRequest(payload as any));
	};

	return (
		<Grid container justifyContent="center">
			<Box
				marginBottom={5}
				width={414}
				display="flex"
				justifyContent="end"
				flexDirection="column"
			>
				<Grid className="mb-10">
					<Card sx={{ minWidth: 100 }} variant="outlined">
						<CardContent>
							<Grid display="flex">
								<Typography
									sx={{ fontSize: 16 }}
									color="black"
									gutterBottom
									className="ml-10 mt-1 fw-600"
								>
									{t('PLEASE_SELECT_THE_CORRECT')}
								</Typography>
							</Grid>
							<Grid
								display="flex"
								color="secondary"
								className="align-middle mt-15"
							>
								<Grid>
									<SvgConverter Icon={AlertIcon} />
								</Grid>
								<Grid>
									<Typography
										color="secondary"
										className="ml-10 fw-600 f-12 "

										// className="ml-10 fw-600 f-12 "
									>
										{t('PLEASE_MAKE_SURE_THAT_THE_INFORMATION')}
									</Typography>

									<Typography color="secondary" className="ml-10 fw-600 f-12 ">
										{t('INCORRECT_INFORMATION_MAY')}
									</Typography>
								</Grid>
							</Grid>

							{isProfileLoading ? (
								<Grid container justifyContent={'center'}>
									<Spinner />
								</Grid>
							) : (
								<>
									<Grid item container xs={12} className="px-1 py-5 mt-20">
										<Grid item xs={6}>
											<Typography
												color={theme.palette.primary.main}
												fontSize={12}
												fontWeight={400}
												// className="text-wrap"
											>
												{t('CURRENT_PROCEED')}
											</Typography>
										</Grid>

										<Grid item xs={6}>
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
												fontWeight={400}
											>
												{t('NEW_PROCEED')}
											</Typography>
										</Grid>
									</Grid>
									<Grid item xs={4}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-15"
										>
											{t('NAME')}
										</Typography>
									</Grid>

									<Grid item container xs={12} className=" py-5 mt-10">
										<Grid container xs={6} alignItems={'center'}>
											<Grid item xs={1}>
												<CustomCheckbox
													mx={0}
													checked={checkState?.name === 'current'}
													disabled={currentState?.name?.length === 0}
													onChange={() => {
														checkState?.name !== 'current'
															? setCheckState({
																	...checkState,
																	name: 'current',
															  })
															: setCheckState({ ...checkState, name: '' });
													}}
												/>
											</Grid>

											<Grid item xs={10}>
												<Typography
													className="f-16 fw-400 px-5"
													style={{ wordWrap: 'break-word' }}
												>
													&nbsp;
													{currentState?.name?.length === 0
														? 'No Info'
														: currentState.name}
												</Typography>
											</Grid>
										</Grid>

										<Grid container xs={6} alignItems={'center'}>
											<Grid item xs={1}>
												<CustomCheckbox
													mx={0}
													checked={checkState.name === 'new'}
													disabled={newState.name.length === 0}
													onChange={() => {
														checkState.name !== 'new'
															? setCheckState({ ...checkState, name: 'new' })
															: setCheckState({ ...checkState, name: '' });
													}}
												/>
											</Grid>

											<Grid item xs={10}>
												<Typography
													className="f-16 fw-400 px-5"
													style={{ wordWrap: 'break-word' }}
												>
													&nbsp;
													{newState.name.length === 0
														? 'No Info'
														: newState.name}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={4}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-15"
										>
											{t('MOBILE_NUMBER')}
										</Typography>
									</Grid>
									<Grid
										item
										container
										xs={12}
										className=" py-5 mt-10"
										alignItems={'start'}
									>
										<Grid container xs={6} alignItems={'center'}>
											<Grid item xs={1}>
												<CustomCheckbox
													mx={0}
													checked={checkState.mobileno === 'current'}
													disabled={currentState?.mobileno?.length === 0}
													onChange={() => {
														checkState.mobileno !== 'current'
															? setCheckState({
																	...checkState,
																	mobileno: 'current',
															  })
															: setCheckState({ ...checkState, mobileno: '' });
													}}
												/>
											</Grid>

											<Grid item xs={10}>
												<Typography
													className="f-16 fw-400 px-5"
													style={{ wordWrap: 'break-word' }}
												>
													&nbsp;
													{currentState?.mobileno?.length === 0
														? 'No Info'
														: currentState.mobileno}
												</Typography>
											</Grid>
										</Grid>

										<Grid container xs={6} alignItems={'center'}>
											<Grid item xs={1}>
												<CustomCheckbox
													mx={0}
													checked={checkState.mobileno === 'new'}
													disabled={newState?.mobileno?.length === 0}
													onChange={() => {
														checkState.mobileno !== 'new'
															? setCheckState({
																	...checkState,
																	mobileno: 'new',
															  })
															: setCheckState({ ...checkState, mobileno: '' });
													}}
												/>
											</Grid>

											<Grid item xs={10}>
												<Typography
													className="f-16 fw-400 px-5"
													color={theme.palette.error.main}
													style={{ wordWrap: 'break-word' }}
												>
													&nbsp;
													{newState?.mobileno?.length === 0
														? 'No Info'
														: newState.mobileno}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={4}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-15"
										>
											{t('EMAIL')}
										</Typography>
									</Grid>
									<Grid
										item
										container
										xs={12}
										className="py-5 mt-10"
										alignItems={'start'}
									>
										<Grid container xs={6} alignItems={'center'}>
											<Grid item xs={1}>
												<CustomCheckbox
													mx={0}
													checked={checkState.email === 'current'}
													disabled={currentState.email?.length === 0}
													onChange={() => {
														checkState.email !== 'current'
															? setCheckState({
																	...checkState,
																	email: 'current',
															  })
															: setCheckState({ ...checkState, email: '' });
													}}
												/>
											</Grid>

											<Grid item xs={10}>
												<Typography
													className="f-16 fw-400 px-5"
													style={{ wordWrap: 'break-word' }}
												>
													&nbsp;
													{currentState.email?.length === 0
														? 'No Info'
														: currentState.email}
												</Typography>
											</Grid>
										</Grid>
										<Grid container xs={6} alignItems={'center'}>
											<Grid item xs={1}>
												<CustomCheckbox
													mx={0}
													checked={checkState?.email === 'new'}
													disabled={newState?.email?.length === 0}
													onChange={() => {
														checkState?.email !== 'new'
															? setCheckState({ ...checkState, email: 'new' })
															: setCheckState({ ...checkState, email: '' });
													}}
												/>
											</Grid>

											<Grid item xs={10}>
												<Typography
													className="f-16 fw-400 px-5"
													color={theme.palette.error.main}
													style={{ wordWrap: 'break-word' }}
												>
													&nbsp;
													{newState?.email?.length === 0
														? 'No Info'
														: newState?.email}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={4}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											className="mt-15"
										>
											{t('ADDRESS')}
										</Typography>
									</Grid>
									<Grid
										item
										container
										xs={12}
										className="px-0 py-5 mt-10"
										alignItems={'start'}
									>
										<Grid container xs={6}>
											<Grid item xs={1}>
												<CustomCheckbox
													mx={0}
													checked={checkState.address === 'current'}
													disabled={currentState.address.length <= 9}
													onChange={() => {
														checkState.address !== 'current'
															? setCheckState({
																	...checkState,
																	address: 'current',
															  })
															: setCheckState({ ...checkState, address: '' });
													}}
												/>
											</Grid>

											<Grid item xs={10}>
												<Typography
													className="f-16 fw-400 px-5"
													style={{ wordWrap: 'break-word' }}
												>
													&nbsp;
													{currentState.address.length <= 9
														? 'No Info'
														: currentState.address}
												</Typography>
											</Grid>
										</Grid>
										<Grid container xs={6}>
											<Grid item xs={1}>
												<CustomCheckbox
													mx={0}
													checked={checkState.address === 'new'}
													disabled={newState.address.length <= 9}
													onChange={() => {
														checkState.address !== 'new'
															? setCheckState({ ...checkState, address: 'new' })
															: setCheckState({ ...checkState, address: '' });
													}}
												/>
											</Grid>
											<Grid item container xs={10}>
												<Typography
													className="f-16 fw-400 px-5"
													color={theme.palette.error.main}
													style={{ wordWrap: 'break-word' }}
												>
													&nbsp;
													{newState.address.length <= 9
														? 'No Info'
														: newState.address}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid container xs={12} justifyContent={'flex-end '}>
										<Grid xs={8} item className="mt-30 py-30 wrap-Proceed ">
											<CustomButton
												text={t('PROCEED_TO_MERGE')}
												variant="text"
												color="primary"
												className="fw-600 f-16 ml-25 wrap-Proceed"
												textAlign="end"
												onClick={onSubmit}
												endIcon={rightArrow}
												disabled={
													!(
														checkState.name !== '' &&
														checkState.email !== '' &&
														checkState.address !== '' &&
														checkState.mobileno !== ''
													)
												}
											/>
										</Grid>
									</Grid>
									{/* <Grid container xs={12} color={theme.palette.grey[600]} justifyContent={'flex-end '}>

										<Grid xs={7} item className="mt-5">
											<Link to={APP_ROUTES.FAMILYDETAILS} style={{ color: theme.palette.grey[600] }}>
												<CustomButton
													text={t('CANCEL_ADDING_RELATIONSHIP')}
													variant="text"
													color="inherit"
													className="fw-600 f-16 grey"

													textAlign="end"
													endIcon={rightArrow}
												/>
											</Link>

											<Grid display="flex" className="align-middle mt-5">
												<Grid>
													<SvgConverter Icon={AlertIconRed} />
												</Grid>
												<Grid>
													<Typography
														color={theme.palette.grey[600]}
														className="f-10 text-right mr-20"
													>
														{t('THE_ERROR_MESSAGE')}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid> */}
									<Grid container xs={12}>
										<Grid
											xs={12}
											item
											className="mt-20"
											color={theme.palette.grey[600]}
											style={{ marginLeft: '68px' }}
										>
											<Link
												to={APP_ROUTES.FAMILYDETAILS}
												style={{ color: theme.palette.grey[600] }}
											>
												<CustomButton
													text={t('CANCEL_ADDING_RELATIONSHIP')}
													variant="text"
													color="inherit"
													className="fw-600 f-16 mr-10 grey sentence_straight"
													fontSize={14}
													textAlign="end"
													endIcon={rightArrow}
												/>
											</Link>

											<Grid display="flex" className="align-middle mt-5">
												<Grid>
													<SvgConverter
														width={15}
														height={15}
														Icon={warningGrey}
														disabled={true}
														className="ml-48"
													/>
												</Grid>
												<Grid>
													<Typography
														color={theme.palette.grey[600]}
														style={{ width: '185px' }}
														className="f-10 text-right fw-600"
													>
														{`[${t('THE_ERROR_MESSAGE')}]`}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</>
							)}
						</CardContent>
					</Card>
				</Grid>
			</Box>
		</Grid>
	);
};

export default MergeInformation;
