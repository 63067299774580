import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { saveAddressEkycRequest } from './Address.slice';
import Spinner from '../../../common/ui/Spinner';
import cryptoEncryptionDecryption from '../../../common/crypto';

const AddressChangeEKYCReturnComponent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const location = useLocation();
	const customerName = window.sessionStorage.getItem('customerName');
	const trimmedCustomerName = customerName
		? cryptoEncryptionDecryption
				.Decrypt(customerName)
				.replace(/^"(.*)"$/, '$1')
				.trimEnd()
		: '';

	useEffect(() => {
		const ekycId = location?.search.split('=')[1];
		if (ekycId) {
			const payload = { ekycId, navigate, customerName: trimmedCustomerName };
			dispatch(saveAddressEkycRequest(payload as any));
		}
	}, [location]);

	return (
		<Grid container justifyContent="center" marginTop={10}>
			<Grid item>
				<Grid item display="flex" justifyContent="center" alignItems="center">
					<Spinner />
				</Grid>
				<Typography className="fw-500 f-18 pt-10">
					Please wait while we verify your details...
				</Typography>
			</Grid>
		</Grid>
	);
};

export default AddressChangeEKYCReturnComponent;
