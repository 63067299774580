/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/system';
import { selectIsUserLoggedIn, setUser } from '../features/Auth/auth.slice';
import AuthRoutes from '../Layout/AuthRoutes';
import AppRoutes from '../Layout/AppRoutes';
import { changeCssColors } from '../common/theme';
import './colors.css';
import './App.scss';
import { AuthUser } from '../common/utils';
import ApiModal from '../common/ui/Modal/ApiModal';
import PDFModal from '../common/ui/Modal/pdfModal';
import PublicRoutes from '../Layout/PublicRoutes';
import cryptoEncryptionDecryption from '../common/crypto';


import { getAnalytics, logEvent } from 'firebase/analytics';
import firebase, { initializeApp } from 'firebase/app';
import 'firebase/analytics';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const App = () => {
	const userInfo = useSelector(selectIsUserLoggedIn);
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			try {
				const access_token = sessionStorage.getItem('access_token');
				let userInfo: any = sessionStorage.getItem('userInfo');

				userInfo = JSON.parse(
					cryptoEncryptionDecryption.Decrypt(userInfo) as string
				);

				if (access_token && userInfo?.Customer?.eiaNo) {
					dispatch(setUser(userInfo.Customer as AuthUser));
				}
			} catch (error) {}
		})();
	}, []);

	const theme = useTheme();
	useEffect(() => {
		changeCssColors(theme);
	}, [theme]);

	const isLogged = useMemo(() => !!userInfo?.eiaNo, [userInfo?.eiaNo]);

	
		const firebaseConfig = {
			apiKey: 'AIzaSyBn-0uBl-yLlSXclH6jLXUDg9qnrpECL4I',
			authDomain: 'bima-central-6676b.firebaseapp.com',
			projectId: 'bima-central-6676b',
			storageBucket: 'bima-central-6676b.appspot.com',
			messagingSenderId: '28323443535',
			appId: '1:28323443535:web:9a13e885d6421d62e30b3a',
			measurementId: 'G-84FXNZ82R5',
		};

		const app = initializeApp(firebaseConfig);
           const analytics = getAnalytics(app);

	
	

	return (
		<>
			<Router>
				<AuthRoutes isLogged={isLogged} />
				<AppRoutes isLogged={isLogged} />
				<PublicRoutes />
				<ApiModal />
				<PDFModal />
			</Router>
		</>
	);
};

export default App;
