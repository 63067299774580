import { utils } from '../../../common/routes';
import { axiosInstance } from '../../../common/axiosInstance';

const axios = axiosInstance;

const uploadService = (data: any) => axios.request({ ...utils.upload, data });
const uploadFamilyService = (data: any) =>
  axios.request({ ...utils.uploadFamily, data });

export { uploadService, uploadFamilyService };
