import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import CustomButton from "../../../common/ui/CustomButton";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../common/constants/Routes";

const StepTwoInstructionScreen = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Grid container display="flex" justifyContent="center">
            <Box
                marginBottom={5}
                width={414}
                display="flex"
                justifyContent="end"
                flexDirection="column"
            >
                <Grid item>
                    <Typography
                        fontSize={28}
                        color={theme.palette.primary.main}
                        fontWeight={400}
                        fontFamily="sans-serif"
                        className="mb-5 mt-30 py-15"
                        textAlign="center"
                    >
                        {t('STEP')} {2}
                    </Typography>

                    <Typography
                        color={theme.palette.primary.main}
                        fontSize={16}
                        className="mt-20 mb-20 fw-600 pl-40 "
                    >
                        {t('ONBOARDING_POLICIES')}
                    </Typography>
                    <ol>
                        <li className="fw-600  py-5">
                            <Typography className="mt-20 fw-600" fontSize={16}>
                                {t('SEARCH_WITH_INTEGRATED_INSURERS')}
                            </Typography>
                        </li>
                        <Typography className="mt-10 fw-400" fontSize={14}>
                            {t('WE_WOULD_SEARCH')}
                        </Typography>
                        <li className="fw-600">
                            <Typography className="mt-30 fw-600 py-5" fontSize={16}>
                                {t('SEARCH_NOT_INTEGRATED_INSURERS')}
                            </Typography>
                        </li>
                        <Typography className="mt-10 fw-400" fontSize={14}>
                            {t('WE_WOULD_USE_ACCOUNT')}
                        </Typography>
                    </ol>
                    <Typography
                        color={theme.palette.secondary.main}
                        className="mt-20 fw-600 need-support-text "
                        fontSize={14}
                        fontFamily="sans-serif"
                        textAlign="center"
                    >
                        {t('DISCLAIMER')}
                    </Typography>

                    <Typography
                        color={theme.palette.secondary.main}
                        className="mt-1 fw-400 pl-40 "
                        fontSize={14}
                        fontFamily="sans-serif"
                        textAlign="center"
                    >
                        {t('NOT_ALL_INSURANCE_COMPANIES')}
                    </Typography>

                    <Grid display="flex" justifyContent="center">
                        <Link to={APP_ROUTES.ADD_POLICY_STEP_TWO} >
                            <CustomButton
                                text={t('PROCEED')}
                                variant="text"
                                color="primary"
                                // textAlign="center"
                                textAlign="right"
                                showIcon
                                fontSize={20}
                                endIcon={rightArrow}
                                className="fw-600 mt-20"
                            />
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}


export default StepTwoInstructionScreen;