import React, { memo, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import data from '../../../../common/constants/FormConstants.json';
import config from '../../../../common/config';
import { pinCodeRequest } from '../../Profile/Profile.slice';
import { parsePinCode } from '../../../../common/utils';
import { Controller } from 'react-hook-form';
import theme from '../../../../common/theme';

const NomineeAddress = ({
	index,
	item,
	register,
	errors,
	submit,
	pincode,
	setPincode,
	setValue,
	control,
	watch,
	isChecked

}: {
	index: number;
	item: any;
	register: any;
	errors: any;
	submit: any;
	pincode: any;
	setPincode: any;
	setValue: any;
	control: any;
	watch: any;
	isChecked: boolean;

}) => {
	const stateRegex = /^[A-Za-z0-9_]+$/;





	const { t } = useTranslation();
	const dispatch = useDispatch();
	const handlePinCodeMaster = (pinDetails: any) => {

		setValue(`nomineeData[${index}].address3`, pinDetails?.districtName, { shouldValidate: true });
		setValue(`nomineeData[${index}].state`, pinDetails?.stateName, { shouldValidate: true });

		// reset(`nomineeData[${index}].address3`, pinDetails?.districtName)
		// reset(`nomineeData[${index}].state`, pinDetails?.stateName)
	};
	useEffect(() => {
		if (pincode.length === 6) {
			const payload = {
				Customer: {
					pincode,
				},
				handlePinCodeMaster,
			};
			dispatch(pinCodeRequest(payload as any));
		}
		if (pincode.length != 0) {
			if (pincode.length !== 6) {
				setValue(`nomineeData[${index}].address3`, '');
				setValue(`nomineeData[${index}].state`, '');
			}
		}
	}, [pincode]);
	const address3 = watch(`nomineeData[${index}].address3`)
	const state = watch(`nomineeData[${index}].state`)

	return (
		<form onSubmit={submit}>
			{/* {config.addressRegex} */}
			<Grid item key={item.id}>
				<Grid item className="mb-5">
					<FormInput
						type="text"
						placeholder={`${t('HOUSE')} #, ${t('STREET')} #`}
						disabled={isChecked}
						control={control}

						name={`nomineeData.${index}.address1`}
						inputProps={{
							className: 'px-10',

						}}
						rules={{
							// 	required: {
							// 		value: true,
							// 		message: t(data.STREET),
							// 	},
							pattern: {
								value: config.addressRegex,
								message: 'Invalid address format',
							}
						}}
						errors={errors}
					/>
					{errors?.address1?.message ? (
						<Typography
							color={theme.palette.error.main}
							fontSize={12}
						>
							{errors?.address1?.message}
						</Typography>
					) : null}




				</Grid>

				<Grid item className="mb-5">
					<FormInput
						type="text"
						placeholder={t('LOCALITY,AREA')}
						disabled={isChecked}
						control={control}
						name={`nomineeData.${index}.address2`}
						inputProps={{
							className: 'px-10',

						}}
						rules={{
							// 	required: {
							// 		value: true,
							// 		message: data.AREA,
							// 	},
							pattern: {
								value: config.addressRegex,
								message: 'Invalid address format',
							},
						}}
						errors={errors}
					/>
					{errors?.address2?.message ? (
						<Typography
							color={theme.palette.error.main}
							fontSize={12}
						>
							{errors?.address2?.message}
						</Typography>
					) : null}
				</Grid>
				<Grid container spacing={3} className="mb-5">
					<Grid item xs={6} sm={6}>
						<FormInput
							type="text"
							disabled={isChecked}
							control={control}
							placeholder={t('CITY')}
							name={`nomineeData.${index}.city`}
							inputProps={{
								className: 'px-10',

							}}
							rules={{
								// 	required: {
								// 		value: true,
								// 		message: t(data.CITY),
								// 	},
								validate: (value: any) => {
									if (value != '' && !config.alphabetsOnlyRegex.test(value)) {
										return t(data.INVALID_NAME);
									}
									return true;
								},
							}}
							errors={errors}

						/>
						{errors?.city?.message ? (
							<Typography
								color={theme.palette.error.main}
								fontSize={12}
							>
								{errors?.city?.message}
							</Typography>
						) : null}
					</Grid>
					<Grid item xs={6} sm={6}>
						<Controller
							name={`nomineeData.${index}.pincode`}
							control={control}

							render={({ field }: any) => (
								<FormInput
									type="number"
									name={`nomineeData.${index}.pincode`}
									disabled={isChecked}
									placeholder={t('PIN')}
									inputProps={{
										maxLength: 6,
										className: 'px-10',
										...field,

										onChange: (e: any) => {
											field.onChange(e);
											setPincode(parsePinCode(e.target.value));
										},
									}}
									errors={errors}
								/>
							)}
							rules={{
								// 	required: {
								// 		value: true,
								// 		message: t(data.PIN),
								// 	},
								validate: (value) => {
									if (value != '' && !config.pincode.test(value)) {
										return t(data.INVALID_PINCODE);
									}
									return true;
								},
							}}
						/>
						{errors?.pincode?.message ? (
							<Typography
								color={theme.palette.error.main}
								fontSize={12}
							>
								{errors?.pincode?.message}
							</Typography>
						) : null}
					</Grid>
				</Grid>
				<Grid container spacing={3} className="mb-5">
					<Grid item xs={6} sm={6}>

						<Controller

							name={`nomineeData.${index}.address3`}
							control={control}
							defaultValue={address3}

							render={({ field }) => (
								<input placeholder={t('DISTRICT')}
									{...field} value={address3} readOnly className='form-input address-color w-100 form-border'
									disabled={isChecked} />
								// <FormInput
								// type="text"
								// placeholder={t('DISTRICT')}
								// name={`nomineeData.${index}.address3`}
								// readOnly

								// inputProps={{

								// 	className: 'px-10',
								// 	// ...register(`nomineeData.${index}.address3`, {
								// 	//   required: {
								// 	// 	value: true,
								// 	// 	message: t(data.STREET),
								// 	//   },
								// 	//   // other validation rules...
								// 	// }),
								// }}

								// errors={errors}
								// />
							)}
							rules={{
								// 	required: {
								// 		value: true,
								// 		message: t(data.DISTRICT),
								// 	},
								validate: (value: any) => {
									if (value != '' && !config.alphabetsOnlyRegex.test(value)) {
										return t(data.INVALID_NAME);
									}
									return true;
								},
							}}
						/>
					</Grid>
					<Grid item xs={6} sm={6}>
						<Controller
							name={`nomineeData.${index}.state`}
							control={control}
							

							render={({ field }) => (
								<input placeholder={"State"} {...field} value={state} readOnly name={`nomineeData.${index}.state`} className='form-input address-color w-100 normal-border form-border '
								disabled={isChecked}
								 />
								// <FormInput
								//   type="text"
								//   placeholder={t('STATE')}
								//   name={`nomineeData.${index}.state`}
								//   readOnly
								//   inputProps={{
								// 	className: 'px-10',
								// 	// ...register(`nomineeData.${index}.state`, {
								// 	//   required: {
								// 	// 	value: true,
								// 	// 	message: t(data.STREET),
								// 	//   },

								// 	// }),
								//   }}
								//   defaultValue={state}
								//   errors={errors}
								// />
							)}

						// rules={{
						// 	required: {
						// 		value: true,
						// 		message: t(data.STATE),
						// 	},

						// }}
						/>

						{/* {errors} */}


					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

export default memo(NomineeAddress);
