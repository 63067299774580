import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import '../Policy.scss';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import { ReactComponent as Add } from '../../../../common/icons/add.svg';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import {
	fetchInsurers,
	selectInsurerOptions,
	selectIsInsurerLoading,
} from '../Policies.slice';
import CustomButton from '../../../../common/ui/CustomButton';
import Spinner from '../../../../common/ui/Spinner';
import AddPolicyList from './AddpolicyList';

interface UserProps {
	lifeList?: any;
	healthList?: any;
	assetList?: any;
	setLifeList?: any;
	setHealthList?: any;
	setAssetList?: any;
}
const AddPolicyForm: React.FC<UserProps> = ({
	lifeList,
	healthList,
	assetList,
	setLifeList,
	setHealthList,
	setAssetList,
}) => {
	const [selectedOption, setSelectedOption] = useState('');
	const [selectedInsurence, setSelectedInsurence] = useState('');
	const insurerOptions = useSelector(selectInsurerOptions);
	const isInsurersLoading = useSelector(selectIsInsurerLoading);

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm();
	const insurerOptionsList =
		insurerOptions &&
		insurerOptions.map((item: any) => ({
			Description: item.label,
			Logo: item.insurerLogo,
			Code: item.value,
		}));
	const insurerenceOptionsList = [
		{ Description: 'Health', Category: 'H', Code: 'H' },
		{ Description: 'Motor', Category: 'G', Code: 'G' },
	];
	const selectedInsurer = insurerOptions.find(
		(option: any) => option.value === selectedOption
	);

	const alternateCode = selectedInsurer ? selectedInsurer.alternateCode : '';
	const dispatch = useDispatch();
	const onSubmit = (data: any) => {
		const insurerLogo = insurerOptions.find(
			(insurer: any) => insurer.value === data?.insurerCode
		);
		if (insurerLogo) {
			data.insurerLogo = insurerLogo.insurerLogo;
			data.alternateCode = insurerLogo.alternateCode;
		}
		const isPolicyNoExists = (list: any[], policyNo: string) =>
			list.some((item) => item.policyNo === policyNo);
		if (
			data.alternateCode === 'L' &&
			!isPolicyNoExists(lifeList, data.policyNo)
		) {
			setLifeList((prevLifeList: any) => [...prevLifeList, data]);
		} else if (
			data.insuranceCode === 'H' &&
			!isPolicyNoExists(healthList, data.policyNo)
		) {
			setHealthList((prevHealthList: any) => [...prevHealthList, data]);
		} else if (
			data.insuranceCode === 'G' &&
			!isPolicyNoExists(assetList, data.policyNo)
		) {
			setAssetList((prevAssetList: any) => [...prevAssetList, data]);
		}
		setSelectedOption('');
		reset({ policyNo: '', insurerCode: '', insuranceCode: '' });
		setSelectedInsurence('');
	};
	useEffect(() => {
		dispatch(fetchInsurers());
	}, []);
	useEffect(() => {
		setSelectedInsurence('');
	}, [selectedOption]);
	// const menuProps = {
	// 	PaperProps: {
	// 		style: {
	// 			maxHeight: '500px',
	// 			marginTop: '120px',
	// 		},
	// 	},
	// };
	const handlePolicyNoInput = (event: any) => {
		const currentValue = event.target.value;

		if (currentValue.length === 1 && !/^[a-zA-Z0-9-_/\\]$/.test(currentValue)) {
			event.target.value = '';
		} else {
			const newValue = currentValue.replace(/[^a-zA-Z0-9-_/\\]/g, '');
			event.target.value = newValue;
		}
	};

	// useEffect(() => {
	//  setSelectedInsurence('');
	// }, [setSelectedInsurence]);

	return (
		<Grid>
			<Box className="add-policy">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container>
						<Grid item xs={12} className="p-5">
							<Typography
								className="f-14 fw-600 display-inline"
								color={theme.palette.primary.main}
							>
								{t('ENTER_POLICY_NUMBER')}
							</Typography>
							<FormInput
								type="text"
								placeholder="ABCDE0100X"
								name="policyNo"
								hideCountButton
								control={control}
								errors={errors}
								inputProps={{
									className: '',
									onInput: handlePolicyNoInput,
									maxLength: 40,
									...register('policyNo', {
										required: {
											value: true,
											message: t('MANDATORY_FIELD'),
										},
									}),
								}}
							/>
						</Grid>
						{isInsurersLoading ? (
							<Grid className="ml-50 mt-20">
								<Spinner />
							</Grid>
						) : (
							<Grid item xs={12} className="p-5 ">
								<Typography
									className="f-14 fw-600 display-inline"
									color={theme.palette.primary.main}
								>
									{t('SELECT_INSURER')}
								</Typography>
								{/* <CustomSelectBox
									options={insurerOptionsList}
									value={selectedOption}
									setSelectedOption={setSelectedOption}
									ClassName="w-100 insurer-selectbox"
									menuProps={menuProps}
									label="Select insurer"
									inputProps={register('insurerCode', {
										required: true,
									})}
								/> */}
								<Controller
									control={control}
									name="insurerCode"
									render={({ field }) => (
										<Select
											options={insurerOptionsList}
											isSearchable
											required
											placeholder={t('SELECT_INSURER')}
											value={
												selectedOption
													? insurerOptionsList.find(
														(option) => option.Code === selectedOption
													)
													: null
											}
											getOptionLabel={(option) => option.Description}
											getOptionValue={(option) => option.Code}
											onChange={(selectedOption) => {
												setSelectedOption(selectedOption?.Code || '');
												field.onChange(selectedOption?.Code || '');
											}}
											onBlur={() => field.onBlur()}
											styles={{
												control: (base) => ({
													...base,
													height: '50px',
													borderColor: '#0051ba',
													'&:hover': {
														borderColor: ' #f99117',
													},
												}),
											}}
										/>
									)}
								/>

								{errors.insurerCode && (
									<Typography
										color={theme.palette.error.main}
										fontSize={12}
										className="mt-5"
									>
										{t('MANDATORY_FIELD')}
									</Typography>
								)}
							</Grid>
						)}
						{alternateCode === 'G' || alternateCode === 'H' ? (
							<Grid item xs={12} className="p-5 ">
								<Typography
									className="f-14 fw-600 display-inline"
									color={theme.palette.primary.main}
								>
									{t('SELECT_YOUR_INSURANCE')}
								</Typography>
								<CustomSelectBox
									options={insurerenceOptionsList}
									defaultValue={selectedInsurence}
									setSelectedOption={setSelectedInsurence}
									ClassName="w-100 selectbox-border"
									// label={t('SELECT_YOUR_INSURANCE')}
									inputProps={register('insuranceCode', {
										required: true,
									})}
								/>
								{errors.insuranceCode && (
									<Typography color={theme.palette.error.main} fontSize={12}>
										{t('MANDATORY_FIELD')}
									</Typography>
								)}
							</Grid>
						) : (
							<></>
						)}
					</Grid>
					<Grid
						item
						container
						xs={12}
						className="px-10"
						flexDirection="row-reverse"
					>
						<Grid item>
							<CustomButton
								text={t('ADD_POLICY')}
								variant="text"
								type="submit"
								color="primary"
								className="f-16 fw-600 mt-8 mb-10"
								startIcon={Add}
								showIcon={false}
							// onClick={() => setSelectedOption('')}
							/>
						</Grid>
					</Grid>
				</form>
			</Box>
			<Grid item>
				<AddPolicyList
					lifeList={lifeList}
					healthList={healthList}
					assetList={assetList}
				/>
			</Grid>
		</Grid>
	);
};
export default AddPolicyForm;
