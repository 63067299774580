import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TrackTransactionLayout from './TrackerTansactionLayout/TrackerTransactionLayout';
// import TimelineScreen from './TrackerTansactionLayout/TimeLineScreen';
import {
	bankStatus,
	saveSelectedCombinationLoading,
	selectTrackTransactionLoading,
	trackTransactionRequest,
	trackTransactions,
} from '../Loan.slice';
import TrackTransactionFooter from './TrackerTansactionLayout/TrackerTransactionFooter';
import Spinner from '../../../../common/ui/Spinner';
import ApiModal from '../../../../common/ui/Modal/ApiModal';

const TrackTransactionScreen = () => {
	const dispatch = useDispatch();
	const bankDetail = useSelector(bankStatus);
	const loading: any = useSelector(saveSelectedCombinationLoading);
	const sessionId = localStorage.getItem('sessionId');
	const sessionDate = localStorage.getItem('sessionDate');
	useEffect(() => {
		if (sessionId && sessionDate) {
			const payload: any = {
				sessionDate: sessionDate,
				sessionId: sessionId,
			};
			dispatch(trackTransactionRequest(payload));
		}
	}, [sessionId && sessionDate]);
	const trackTransactionsResponse: any = useSelector(trackTransactions);
	const loanIsLoading = useSelector(selectTrackTransactionLoading);

	const trackTransaction = {
		Combination: {
			// ...trackTransactionsResponse,
			requiredAmount: trackTransactionsResponse?.requiredAmount,
			requiredDate: trackTransactionsResponse?.requiredDate,
			totalCharges: trackTransactionsResponse?.totalCharges || '',
			totalChargesDetails: trackTransactionsResponse?.totalChargesDetails || '',
			totalFundsAvailed: trackTransactionsResponse?.totalFundsAvailable || '',
			lossOfCover: trackTransactionsResponse?.lossOfCover || '',
			combinationName: trackTransactionsResponse?.combinationName || '',
			eligiblePolicyCount: trackTransactionsResponse?.eligiblePolicyCount || '',
			CurrentlyUsingPolicyCount:
				trackTransactionsResponse?.CurrentlyUsingPolicyCount || '',
			Widgets: trackTransactionsResponse?.Widgets?.map((widget: any) => {
				const bankDetails: any = bankDetail?.find((data: any) => {
					if (parseInt(data?.widgetNo) === parseInt(widget?.widgetNo)) {
						return data?.bankDetails;
					}
				});

				const clonedWidget: any = {
					...widget,
					sessionId: trackTransactionsResponse?.sessionId,
					BankDetails: {
						...widget.BankDetails,
						...bankDetails?.bankDetails,
					},
				};

				return clonedWidget;
			}),
		},
	};

	return (
		<>
			{loading || loanIsLoading ? (
				<Grid justifyContent="center" alignItems="center">
					<Spinner />
				</Grid>
			) : (
				<Grid container display="flex" justifyContent="center">
					<Grid item className="track-transaction">
						<TrackTransactionLayout
							sessionDate={trackTransactionsResponse?.sessionDate}
							trackTransaction={trackTransaction}
							bankChange={bankDetail?.length !== 0}
						/>
						<TrackTransactionFooter
							trackTransaction={trackTransactionsResponse}
						/>
					</Grid>
					<ApiModal />
				</Grid>
			)}
		</>
	);
};

export default TrackTransactionScreen;
