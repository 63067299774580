import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import PolicyList from "./PolicyList";

interface PolicyListCardProps {
    policyList?: any;
    headingName?: string
}

const PolicyListCard: React.FC<PolicyListCardProps> = ({ headingName, policyList }) => {

    const theme = useTheme();
    const { t } = useTranslation();

    return (

        <Grid item justifyContent="center">
            <Typography
                className="f-16 fw-400 mb-10"
                color={theme.palette.primary.main}
            >
                {headingName}
            </Typography>
            {(policyList &&
                policyList?.length > 0 && (policyList?.[0]?.list?.length > 0 || policyList?.[1]?.list?.length > 0 || policyList?.[2]?.list?.length > 0)) ? policyList.map((policyArray: any) => (
                    <PolicyList title={policyArray?.code === 'H' ? t('HEALTH_INSURANCE') : policyArray.code === 'L' ? t('LIFE_INSURANCE') : t('MOTOR_INSURANCE')} insurerCode={policyArray.code} list={policyArray?.list} />
                )) : (<Typography
                    className="f-14 fw-400 mb-30"
                    color={theme.palette.grey[300]}
                >

                    {t('NO_POLICIES')}
                </Typography>)}
        </Grid>

    )
}

export default PolicyListCard;