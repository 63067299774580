import React, { useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectedRecommendProductDetails from './SelectedRecommendProductDetails';
import MaturityAndFundValue from './MaturityAndFundValue';
import { ReactComponent as RoundBlackicon } from '../../../common/icons/LoanModule/round_black_icon.svg';
import { ReactComponent as RupeeIconMedium } from '../../../common/icons/medium_rupee_icon.svg';
// import HdfcIcon from '../../../assets/images/HDFC.png';
import SvgConverter from '../../../common/SvgConverter';
import RadioButton from './RadioButtonDetails';
import FormInputWithRupee from './FormInputWithRupee';
import { ReactComponent as divider } from '../../../common/icons/LoanModule/divider.svg';
import Separator from '../../../common/ui/Separator';
import FinancierBankContent from './FinancierBankContent';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { convertAmount } from '../../../common/utils';

interface FinancierNameAndLoanAmountContentProps {
	financierData?: any;
	totalAmount?: any;
	onFinancierSelect?: any;
	onRepaymentOptionSelect?: any;
	control?: any;
	errors?: any;
	setValue?: any;
}

const FinancierNameAndLoanAmountContent = ({
	financierData,
	totalAmount,
	onFinancierSelect,
	onRepaymentOptionSelect,
	control,
	errors,
	setValue,
}: FinancierNameAndLoanAmountContentProps) => {
	const { t } = useTranslation();

	const theme = useTheme();
	const [selectedFinancierCode, setSelectedFinancierCode] = useState<
		string | null
	>(null);
	const handleFinancierSelect = (lenderCode: string) => {
		setSelectedFinancierCode(lenderCode);
		onFinancierSelect(lenderCode);
		onRepaymentOptionSelect(null);
	};
	const generateRepaymentOptions = (repaymentOptions: any[]) =>
		repaymentOptions?.map((option) => ({
			label: option?.repaymentOptionDescription,
			value: option?.repaymentCode,
		}));
	return (
		<>
			<Grid item className="recommend-select">
				<Grid item>
					<SelectedRecommendProductDetails
						Icon={RoundBlackicon}
						logoSrc={financierData?.insurerLogo}
						product={financierData?.productType}
						selfName="[Self Name]"
						showValues={false}
					/>
				</Grid>

				<Typography
					component="span"
					className="recommend-icon-number  three f-28"
					color={theme.palette.common.black}
				>
					{financierData?.widgetNo}
				</Typography>
				<MaturityAndFundValue
					showValuesForThird
					maturityValue={financierData}
				/>
				<Grid item bgcolor="#f2f2f2" className="p-10 top-border bottom-border">
					<Grid item>
						<Typography
							className="f-14 fw-600"
							color={theme.palette.primary.main}
						>
							{t('Loan against fund value')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid container spacing={1} className="mt-4">
							<Grid item className="ml-18">
								<Typography
									className="f-12 mt-2"
									color={theme.palette.common.black}
									justifyContent="center"
									display="flex"
									alignItems="center"
								>
									[
									<Typography
										className="f-12 fw-600"
										color="#f99117"
										justifyContent="center"
									>
										{financierData?.Loan?.loanPercentage?.length === 0
											? '0'
											: financierData?.Loan?.loanPercentage}
									</Typography>
									{t('of fund value')}]
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid className="mt-4">
						<FormInputWithRupee
							inputValue={financierData?.Loan?.loanAmount}
							name={financierData?.widgetNo}
							control={control}
							error={errors}
							setValue={setValue}
						/>
					</Grid>
				</Grid>
				<Grid item>
					<Grid item className="mt-8 ml-15">
						<Typography
							className="f-14 fw-600"
							color={theme.palette.primary.main}
						>
							{t('Select a Financier')}
						</Typography>
					</Grid>
					{financierData?.Loan?.LenderDetails.map((mapdata: any) => (
						<>
							<Grid item>
								<FinancierBankContent
									financierDetails={mapdata}
									isSelected={mapdata?.lenderCode === selectedFinancierCode}
									onSelect={() => handleFinancierSelect(mapdata?.lenderCode)}
								/>
							</Grid>

							<Grid item className="80 ml-50 ">
								<SvgConverter Icon={divider} />
							</Grid>
							<Grid item>
								<Typography
									className="f-12 pl-48 mt-3"
									color={theme.palette.primary.main}
								>
									{t('Select Repayment Options')}
								</Typography>
							</Grid>
							<Grid item className="mt-n20 pl-10">
								<RadioButton
									options={generateRepaymentOptions(mapdata?.RepaymentOptions)}
									onValueChange={onRepaymentOptionSelect}
									disabled={mapdata?.lenderCode !== selectedFinancierCode}
									selectedCheckboxValue={selectedFinancierCode}
								/>
							</Grid>
						</>
					))}
				</Grid>
				{/* <Grid item>
					<FinancierBankContent />
					<Grid item className="ml-50">
						<SvgConverter Icon={divider} />
					</Grid>
					<Grid item>
						<Typography
							className="f-12 pl-48"
							color={theme.palette.primary.main}
						>
							Select Repayment Options
						</Typography>
					</Grid>
					<Grid item className="mt-n20 pl-10">
						<RadioButton options={optionTwo} />
					</Grid>
				</Grid>
				<Grid item>
					<FinancierBankContent />
					<Grid item className=" ml-50">
						<SvgConverter Icon={divider} />
					</Grid>
					<Grid item>
						<Typography
							className="f-12 pl-48"
							color={theme.palette.primary.main}
						>
							Select Repayment Options
						</Typography>
					</Grid>
					<Grid item className="mt-n20 pl-10">
						<RadioButton options={optionOne} />
					</Grid>
				</Grid>
				<Grid item>
					<FinancierBankContent />
					<Grid item className=" ml-50">
						<SvgConverter Icon={divider} />
					</Grid>
					<Grid item>
						<Typography
							className="f-12 pl-48"
							color={theme.palette.primary.main}
						>
							Select Repayment Options
						</Typography>
					</Grid>
					<Grid item className="mt-n20 pl-10">
						<RadioButton options={optionOne} />
					</Grid>
				</Grid>
				<Grid item>
					<FinancierBankContent />
					<Grid item className=" ml-50">
						<SvgConverter Icon={divider} />
					</Grid>
					<Grid item>
						<Typography
							className="f-12 pl-48"
							color={theme.palette.primary.main}
						>
							Select Repayment Options
						</Typography>
					</Grid>
					<Grid item className="mt-n20 pl-10">
						<RadioButton options={optionOne} />
					</Grid>
				</Grid> */}
			</Grid>
			<Grid item className="my-10">
				<Separator color={theme.palette.grey[300]} />
			</Grid>
			<Grid item className="my-10">
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography className="f-12" color={theme.palette.common.black}>
							{t('Total Charges')}
						</Typography>
						<Grid item>
							<Typography className="f-12" color={theme.palette.primary.main}>
								{`[${t('SEE DETAILS')}]`}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={5}>
						<Grid container>
							<Grid item>
								<LabelWithIcon
									svgClassName=""
									justifyContent="flex-start"
									Icon={RupeeIconMedium}
									label={`${convertAmount(
										totalAmount?.totalCharges?.length === 0
											? 0
											: totalAmount?.totalCharges
									)} (-)`}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 12,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default FinancierNameAndLoanAmountContent;
