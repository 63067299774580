import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Spinner = ({ size = 30, color = 'info' }: any) => (
	<Box sx={{ display: 'flex' }}>
		<CircularProgress size={size} color={color} />
	</Box>
);

export default Spinner;
