import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RupeeIcon } from '../../../common/icons/LoanModule/rupee-icon.svg';
import { useTranslation } from 'react-i18next';

interface labelProps {
	color?: string | undefined;
	fontWeight?: number | undefined;
}
interface fundAndcoverLoss {
	TotalNo?: string | number;
	totalFunds?: any;
	lossOfCover?: any;
	lender?: any;
	labelProps?: labelProps | undefined;
	approxShow?: boolean;
	cost?: any;
	charges?: any;
	targetAmount?: any;
	twoCombinations?: any;
}
const TotalAmountAvailable = ({
	twoCombinations,
	TotalNo,
	totalFunds,
	lossOfCover,
	labelProps,
	approxShow = true,
	cost,
	lender,
	charges,
	targetAmount,
}: fundAndcoverLoss) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<>
			{lender === 'lender' ? (
				<Grid container justifyContent="space-between" className="mb-10">
					<Grid item xs={8}>
						<Typography
							className="f-14 fw-400"
							color={theme.palette.common.black}
						>
							{t('TOTAL_CHARGES')}
						</Typography>
						{/* <Grid item>
							<Typography
								className="f-14 mt-2"
								color={theme.palette.primary.dark}
							>
								[{t('SEE_DETAILS')}]
							</Typography>
						</Grid> */}
					</Grid>
					<Grid item xs={4}>
						<Grid>
							<Grid container alignItems="center">
								<Grid item className="mr-5">
									<SvgConverter Icon={RupeeIcon} width={8} height={12} />
								</Grid>
								<Grid item>
									<Typography
										className="f-14 fw-300"
										color={theme.palette.common.black}
									>
										{charges}(-)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			) : (
				''
			)}

			<Grid container justifyContent="space-between" className="mb-10">
				<Grid item xs={8}>
					<Typography
						className={lender === 'lender' ? 'f-14 fw-600' : 'f-16 fw-600'}
						color={theme.palette.common.black}
					>
						{lender === 'lender'
							? t('TOTAL_FUNDS_AVAILABLE')
							: t('TOTAL_AMOUNT_AVAILABLE')}
					</Typography>
					<Grid item>
						<Typography
							className="f-14  fw-600 mt-n2"
							color={theme.palette.common.black}
						>
							{TotalNo}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					<Grid>
						<Grid
							container
							alignItems="center"
							justifyContent={lender === 'lender' ? 'flex-start' : 'flex-end'}
						>
							<Grid item className="mr-5">
								<SvgConverter Icon={RupeeIcon} width={8} height={12} />
							</Grid>
							<Grid item>
								<Typography
									className={
										lender === 'lender' ? 'f-14 fw-600' : 'f-16 fw-600'
									}
									color={theme.palette.common.black}
								>
									{totalFunds && totalFunds?.length === 0 ? '0' : totalFunds}
								</Typography>
							</Grid>
						</Grid>
						{approxShow && (
							<Grid item xs textAlign="right">
								<Typography className="f-12" color={theme.palette.common.black}>
									(approx)
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
			{lender === 'lender' ? (
				''
			) : (
				<Grid container className="mb-20">
					<Typography
						className="f-14"
						color="#7f7f7f"
						display="flex"
						alignItems="baseline"
					>
						[{t('TARGET_AMOUNT')} &nbsp;{' '}
						<SvgConverter Icon={RupeeIcon} width={8} height={12} /> &nbsp;
						<Typography className="f-14 fw-600">
							{targetAmount}
						</Typography>]{' '}
					</Typography>
				</Grid>
			)}
			<Grid container justifyContent="space-between" className="mb-5">
				<Grid item xs={8}>
					<Typography
						color={
							twoCombinations
								? theme.palette.error.dark
								: theme.palette.common.black
						}
						className={lender === 'lender' ? 'f-14 fw-600' : 'f-16 fw-600'}
					>
						{t('LOSS_OF_COVER')}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Grid
						container
						spacing={0.5}
						alignItems="center"
						justifyContent={lender === 'lender' ? 'flex-start' : 'flex-end'}
					>
						{lossOfCover === 'NaN' ? (
							<Grid item>
								<Typography
									className={
										lender === 'lender' ? 'f-14 fw-600' : 'f-16 fw-600'
									}
									color={
										labelProps?.color || twoCombinations
											? theme.palette.error.dark
											: theme.palette.common.black
									}
									fontWeight={labelProps?.fontWeight}
								>
									Nil
								</Typography>
							</Grid>
						) : (
							<>
								<Grid item>
									<SvgConverter Icon={RupeeIcon} width={8} height={12} />
								</Grid>
								<Grid item>
									<Typography
										className={
											lender === 'lender' ? 'f-14 fw-600' : 'f-16 fw-600'
										}
										color={
											labelProps?.color || twoCombinations
												? theme.palette.error.dark
												: theme.palette.common.black
										}
										fontWeight={labelProps?.fontWeight}
									>
										{lossOfCover && lossOfCover.length === 0
											? '0'
											: lossOfCover}
									</Typography>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
			{lender === 'lender' ? (
				''
			) : (
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography
							className="f-16 fw-600"
							color={theme.palette.common.black}
						>
							{t('COSTS')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid container spacing={0.5} alignItems="center">
							<Grid item>
								<SvgConverter Icon={RupeeIcon} width={8} height={12} />
							</Grid>
							<Grid item>
								<Typography
									className="f-16 fw-600"
									color={labelProps?.color || theme.palette.common.black}
									fontWeight={labelProps?.fontWeight}
								>
									{cost && cost.length === 0 ? '0' : cost}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default TotalAmountAvailable;
