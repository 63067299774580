import React from 'react';
import { Grid, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import theme from '../../../common/theme';

interface UserProps {
	// icon: any;
	witnessFirstName?: any;
	witnessLastName?: any;
	witnessPan?: any;
	witnessMobile?: any;
	witnessEmail?: any;
	witnessDOB?: any;
}
const WitnessDetails = ({
	witnessFirstName,
	witnessLastName,
	witnessPan,
	witnessMobile,
	witnessEmail,
	witnessDOB,
}: UserProps) => {
	const { t } = useTranslation();
	return (
		<Grid container className="mt-10 " style={{ wordWrap: 'break-word' }}>
			<Grid item xs={12} container>
				<Grid item xs={12}>
					<Typography
						fontSize={14}
						color={theme.palette.primary.main}
						fontWeight={600}
						mb={2}
					>
						{(witnessFirstName || '') + " " + (witnessLastName || '')}
					</Typography>
				</Grid>
				<Grid item xs={6} className="mt-3">
					<Typography fontSize={12} color={theme.palette.primary.main}>
						{t('PAN')}# {witnessPan || 'ABCDE0123X'}
					</Typography>
				</Grid>
				<Grid item xs={6} className="mt-3">
					<Typography fontSize={12} color={theme.palette.primary.main}>
						{witnessMobile || '9876543210'}
					</Typography>
				</Grid>
				<Grid item xs={6} className="mt-3">
					<Typography fontSize={12} color={theme.palette.primary.main}>
						DOB:{witnessDOB || ' DD MM YYYY'}
					</Typography>
				</Grid>
				<Grid item xs={6} className="mt-3">
					<Typography
						fontSize={12}
						color={theme.palette.primary.main}
					// style={{ overflowWrap: 'break-word' }}
					>
						{witnessEmail || 'email@website.com'}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default WitnessDetails;
