import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BankDetailsHeader from './common/BankDetailsHeader';
import { BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES } from '../../../common/Enums';
import Separator from '../../../common/ui/Separator';

const UnverifiedBankScreen = ({
	bankList,
	handleRemoveBank,
	handleVerifyNow,
}: any) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<>
			<Grid item xs={12} className="my-10">
				<Grid item>
					<Typography
						className="f-16 fw-600 mb-10"
						color={theme.palette.error.main}
					>
						{t('UNVERIFIED_BANK_ACCOUNTS')}
					</Typography>
				</Grid>
			</Grid>
			<Grid item className="my-10">
				<Separator color={theme.palette.primary.main} borderWidth={1} />
			</Grid>
			<Grid container spacing={5}>
				{bankList.map((data: any, index: number) => (
					<Grid item key={index} xs={12} sm={6} md={3}>
						<BankDetailsHeader
							data={data}
							index={index}
							onRemoveClick={() =>
								handleRemoveBank(
									data.accountNo,
									BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES.UNVERIFIED
								)
							}
							isUnVerified
							onVerifyNow={() =>
								handleVerifyNow(
									data,
									BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES.UNVERIFIED
								)
							}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default UnverifiedBankScreen;
